import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import theme from '../../styles/theme'

const classes = {
  category: css`
    cursor: pointer;
    border: none;
    padding: ${theme.spacing(2)}px;
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.roundedBorders.all};
    display: flex;
    align-items: center;
    text-align: left;
    .MuiTypography-root {
      margin-left: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4)}px;
      align-items: center;
      flex-direction: column;
      text-align: center;
      .MuiTypography-root {
        margin-left: 0;
        margin-top: ${theme.spacing(2)}px;
      }
      &:hover {
        box-shadow: ${commonStyling.boxShadowDark};
      }
    }
  `,
  imageWrapper: css`
    img {
      max-height: 50px;
    }
  `,
  list: css`
    padding: ${theme.spacing(3, 0, 4)};
    display: grid;
    row-gap: ${theme.spacing(1)}px;
    ${theme.breakpoints.up('md')} {
      max-width: ${commonStyling.breakpoints.desktopLarge};
      margin: 0 auto;
      padding: ${theme.spacing(6, 0, 5)};
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      column-gap: ${theme.spacing(5)}px;
      row-gap: ${theme.spacing(2)}px;
    }
  `,
}

export default classes
