import {
  COLORS,
  GENERAL,
} from '../../styles/theme'
import {
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import { ProductProps } from './Product'

export const ProductStyles = (theme: Theme) =>
  createStyles({
    layout: {
      background: COLORS.white,
      boxSizing: 'border-box',
      flexDirection: 'column',
      gridTemplateAreas: `
        'heading cta'
        'bullets cta'
        'info info'
      `,
      gridTemplateRows: 'auto 1fr',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        display: 'flex',
        gridTemplateColumns: '1fr',
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        flexGrow: 1,
        gridTemplateColumns: '1fr auto',
        padding: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(5, 11, 5, 7),
      },
    },
    product: ({ selected }: ProductProps) => ({
      borderColor: selected ? `${COLORS.secondary}` : `${COLORS.gray2}`,
      borderWidth: '2px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'hidden',
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        maxWidth: GENERAL.addOnWidthMobile,
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        maxWidth: '100%',
      },
    }),
    curved: {
      height: '100%',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translate(0, -50%)',
      width: '20px',
      zIndex: 2,
      '& path': {
        fill: COLORS.white,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 0,
        height: '20px',
        left: '50%',
        right: 'auto',
        top: 'auto',
        transform: 'translate(-50%, 0)',
        width: '100%',
      },
    },
    productImage: {
      background: COLORS.white,
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      '& [class*=mediaWrapper]': {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      },
      [theme.breakpoints.up('xs')]: {
        minHeight: '300px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 'auto',
        minWidth: '240px',
        flexBasis: '240px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '290px',
        flexBasis: '290px',
      },
    },
    selected: {
      '&[class*=MuiBox-root]': {
        boxShadow: `0 0 0 1px ${COLORS.secondary}`,
        borderColor: `${COLORS.secondary}`,
      },
    },
    heading: {
      alignItems: 'center',
      display: 'flex',
      gridArea: 'heading',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        textAlign: 'left',
      },
    },
    cta: {
      gridArea: 'cta',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
        paddingLeft: theme.spacing(5),
      },
    },
  })
