import React, {
  useEffect, useState,
} from 'react'
import {
  Box,
} from '@material-ui/core'
import classnames from 'classnames'
import classes from './ResidentialPricingPackage.styles'
import BannerFront from './BannerCard/BannerFront'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { PackageProps } from '../Packages/Package'
import { getPackages } from '../../api/Packages'
import bannerClasses from './ResidentialPackageBanner.styles'
import { cx } from 'linaria'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { ChannelType } from '../../utils/commonEnums'
import { useAuth } from '../../hooks/auth'
import { addToCartOptions } from '../Packages/Helpers'
import { ProductProps } from '../Products/Product'
import createCRMApiClient from '../../api/CRMApi'

export interface ResidentialPackageBannerProps {
  readonly meta: {
    id: number;
  };
  product_id: string;
  title: string;
  subtitle: string;
  on_border_text?: string;
  speed: string;
  no_spacing?: boolean;
  button: {
    text: string;
    url: string;
    modal: boolean;
  };
  orderFlowButton: {
    text: string;
    url: string;
    modal: boolean;
  };
  selectedProductId?: string;
  selectedProductButtonText?: string;
  onClickPackage?: (product: PackageProps) => void;
  package_broadband?: PackageProps[];
  hide_strikethrough?: boolean;
  free_set_up_copy?: string;
}

export interface ProductBannerProps extends ResidentialPackageBannerProps {
  product?: PackageProps[];
}

const ResidentialPackageBanner = ({
  meta,
  title,
  subtitle,
  on_border_text,
  no_spacing,
  button,
  orderFlowButton,
  speed,
  package_broadband,
  hide_strikethrough,
  free_set_up_copy,
}: ProductBannerProps) => {
  const { isAuthenticated } = useAuth()
  const client = createCRMApiClient()

  const [
    broadbandPackage,
    setBroadbandPackage,
  ] = useState<PackageProps>()

  const {
    options, setOptions,
  } = useOrderContext()

  useEffect(() => {
    isAuthenticated && (async () => {
      const packageArray = [package_broadband].flat() as PackageProps[]
      const filteredPackage = await getPackages(ChannelType.DIGITAL, packageArray, 'RESIDENTIAL') as PackageProps[]
      setBroadbandPackage(filteredPackage[0])
    })()
  }, [
    package_broadband,
    isAuthenticated,
  ])

  if (!broadbandPackage || Object.keys(broadbandPackage).length === 0) {
    return null
  }

  const flipCard = cx(classes.flipcard.inner)
  const extraSpacing = no_spacing ? '' : bannerClasses.wrapperSpacing

  const setProduct = async (product: ProductProps | PackageProps, channel: string, hide_strikethrough: boolean) => {
    const productOptions = await addToCartOptions({
      product,
      options,
      client,
      channel,
      hide_strikethrough,
    })
    marketingEvents.engageWithCart('add_to_cart', product, options)
    setOptions(productOptions)
  }

  const cardDetails = {
    product: broadbandPackage!,
    title,
    subtitle,
    on_border_text,
    speed,
    buttonUrl: orderFlowButton ? orderFlowButton.url : button?.url,
    buttonText: orderFlowButton ? orderFlowButton.text : button.text,
    isModal: orderFlowButton ? orderFlowButton.modal : button.modal,
    hide_strikethrough,
  }

  return (
    <ContainerWithPadding id={`ResidentialPackageBanner_${meta.id}`}>
      <Box
        my={no_spacing ? 0 : 4}
        mx={no_spacing ? 0 : 0}
        className={broadbandPackage.hide ? classnames(bannerClasses.hide) : classnames(extraSpacing)}
      >
        <div className={cx(flipCard)}>
          <BannerFront
            discount={null}
            onClickPackage={setProduct}
            free_set_up_copy={free_set_up_copy}
            {...cardDetails}
          />
        </div>
      </Box>
    </ContainerWithPadding>
  )
}

export default ResidentialPackageBanner
