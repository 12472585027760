import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  container: css`
    box-sizing: border-box;
    color: ${COLORS.white};
    padding: ${theme.spacing(1.5)}px;
    width: 100%;
    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1101;
    }
  `,
  countdownNumber: css`
    &.MuiTypography-body1 {
      line-height: 1.2;
    }
  `,
  countdownLabel: css`
    &.MuiTypography-root {
      font-size: ${fontSizes.smallText};
    }
  `,
}

export default classes
