import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  parent: css`
    color: #fff;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    position: relative;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `,
  imageContainer: css`
    width: 50vw;
    position: relative;
    z-index: 1;
    picture {
      height: 100%;
      left: 0;
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      width: 100%;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    ${theme.breakpoints.down('sm')} {
      height: 50vh;
      width: 100%;
    }
    ${theme.breakpoints.down('xs')} {
      height: 40vh;
    }
  `,
  content: css`
    background-color: ${theme.palette.primary.main};
    width: 50vw;
    ${theme.breakpoints.down('sm')} {
      .MuiGrid-item {
        margin-left: auto;
        margin-right: auto;
      }
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      [class*=DesktopHeroWithInputs] {
        padding-top: 0;
      }
    }
  `,
  canvas: css`
    overflow: hidden;
    width: 100vw;
    ${theme.breakpoints.down('sm')} {
      h1 {
        font-size: ${fontSizes.h2};
      }
    }
  `,
  wrapper: css`
    ${theme.breakpoints.down('sm')} {
      &, &[class^=MuiBox] {
        padding-top: 0;
      }
    }
  `,
  curve: css`
    content: "";
    background-color: ${theme.palette.primary.main};
    border-radius: 0 100% 100% 0;
    height: 120%;
    left: 0%;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    width: ${theme.spacing(15)}px;
    z-index: 10;
    ${theme.breakpoints.down('md')} {
      width: ${theme.spacing(10)}px;
    }
    ${theme.breakpoints.down('sm')} {
      border-radius: 100% 100% 0 0;
      height: ${theme.spacing(10)}px;
      left: 50%;
      margin-top: 1px;
      transform: translate(-50%, -100%);
      top: 100%;
      width: 120%;
    }
  `,
  MuiFormControl: css`
    display: flex;
    justify-content: center;
    flex-direction: row;
    &.MuiInputBase-root {
      input{
        padding-left: ${theme.spacing(2)}px;
      }
      input::placeholder {
        color: ${theme.palette.grey[900]};
        font-weight: 500;
        font-style: normal;
        font-size: ${fontSizes.body1};
      }
      line-height: 1;
      background-color: #fff;
      border-radius: 25px;
      padding: 6px 5px;
    }
    &.MuiButtonBase-root {
      width: 100%;
    }
    &.MuiTypography-root {
      justify-content: center;
      font-weight: lighter;
      a {
        color: #fff;
      }
      p {
        margin: 0;
      }
    }
    &.MuiTypography-h2 {
      font-weight: normal;
    }
    &.MuiTypography-h3 {
      font-weight: normal;
      ${theme.breakpoints.up('md')} {
        margin-top: ${theme.spacing(5)}px;
      }
    }
    &.MuiTypography-h4 {
      font-weight: normal;
    }
    &.MuiTypography-body1 {
      font-weight: lighter;
      text-decoration: none;
      margin-bottom: ${theme.spacing(3)}px;
    }
    &.MuiTypography-body2 {
      font-weight: lighter;
      font-size: ${fontSizes.body3};
      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(3)}px 0;
      }
    }
    &.MuiList-root {
      max-height: ${38 * 12}px;
      overflow: scroll;
      background-color: ${theme.palette.grey[50]};
      margin: 0;
      margin-top: 2px;
      z-index: ${theme.zIndex.appBar};
      position: absolute;
      max-width: 100%;
      width: 410px;
      border-radius: 4px;
    }
    &.MuiListItem-root {
      padding-left: ${theme.spacing(1)}px;
      cursor: pointer;
      &:hover {
        background-color: ${theme.palette.grey[200]};
      }
    }
  `,
  inputUrl: css`
    cursor: pointer;
  `,
  errorMessage: css`
    padding-bottom: ${theme.spacing(1)}px;
    color: ${COLORS.secondaryContrast};
    margin-top: ${theme.spacing(3)}px !important;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(1)}px !important;
    }
  `,
  links: css`
    display: flex;
    flex-direction: row;
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      > * + * {
        margin-top: ${theme.spacing(2)}px;
      }
    }
    ${theme.breakpoints.down('sm')} {
      flex-direction: row;
      > * + * {
        margin-top: 0;
      }
    }
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      > * + * {
        margin-top: ${theme.spacing(2)}px;
      }
    }
  `,
  linkedText: css`
    a {
      color: #fff;
    }
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    width: 100%;
  `,
  highlightedTitle: css`
    color: ${theme.palette.secondary.main};
  `,
}

export default classes
