import React, { useState } from 'react'
import {
  Box, Link, Typography,
} from '@material-ui/core'
import {
  COLORS,
  GRADIENTS, general,
} from '../../styles/theme'
import classes from './OfferBanner.styles'
import TypographyCMS, { TypographyProps } from '../Typography/Typography'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import ModalBox from '../ModalBox/ModalBox'

export interface OfferBannerProps {
  anchor_id?: string;
  background_color?: string;
  width?: string;
  border_radius? : number;
  media_image?: MediaImageProps;
  copy?: TypographyProps[];
  link_to_modal?: TypographyProps;
  more_info_modal?: any;
}

export default function OfferBanner({
  anchor_id,
  background_color,
  border_radius,
  width,
  media_image,
  copy,
  link_to_modal,
  more_info_modal,
}: OfferBannerProps) {
  const [
    modalVisible,
    setModalVisible,
  ] = useState<boolean>(false)

  const openModal = () => {
    if (modalVisible === false) {
      return setModalVisible(true)
    }

    return setModalVisible(false)
  }

  return (
    <Box
      display="flex" flexDirection="row" align-items="center"
      justifyContent="center" id={anchor_id}
    >
      <Box
        display="flex" justifyContent="flex-end" position="relative"
        px={{
          xs: 0,
          md: width === '700' ? 16 : 0,
        }}
        borderRadius={border_radius}
        border={`2px solid ${COLORS.gray2}`}
        style={{
          background: background_color ? background_color : GRADIENTS.primaryPale,
          maxWidth: width === '700' ? '700px' : '611px',
        }}
        className={classes.banner}
      >
        {media_image?.src &&
        <Box
          position="absolute" mt={-5} mr={{
            sx: 1,
            md: -2,
          }}
        >
          <MediaImage
            src={media_image.src}
            width={media_image.width || 136}
            height={media_image.height || 117}
            alt={media_image.alt}
            mobile_src={media_image.mobile_src}
            mobile_width={media_image.mobile_width}
            mobile_height={media_image.mobile_height}
          />
        </Box>}
        <Box
          m="auto"
          py={{
            xs: 2,
            sm: 3,
          }} pl={{
            xs: 2,
            sm: 4,
          }} pr={{
            xs: 2,
            sm: media_image?.src ? 10 : 2,
            md: media_image?.src ? 14.5 : 2,
          }}
        >
          {copy?.map((item, index) => (
            <TypographyCMS
              key={index} text={item.text} wrap_on_mobile={item.wrap_on_mobile}
              variant={item.variant} variant_mobile={item.variant_mobile}
              color={item.color} weight={item.weight} align={item.align}
              margin_bottom={item.margin_bottom} margin_top={item.margin_top}
            />
          ))}
          {link_to_modal &&
          <>
            <Link
              onClick={openModal}
              underline="always"
              color={link_to_modal.color}
              style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
              className={general.font.weight.bold}
            >
              {link_to_modal.text}
            </Link>
            <ModalBox
              visible={modalVisible === true}
              sync={openModal}
            >
              {more_info_modal?.image && Object.keys(more_info_modal?.image).length > 0 &&
              <Box mb={2}>
                <MediaImage
                  src={more_info_modal?.image.src}
                  width={more_info_modal?.image.width || 90}
                  height={more_info_modal?.image.height || 90}
                  alt={more_info_modal?.image.alt}
                  mobile_src={more_info_modal?.image.mobile_src}
                  mobile_width={more_info_modal?.image.mobile_width}
                  mobile_height={more_info_modal?.image.mobile_height}
                  center
                />
              </Box>}
              {more_info_modal?.title &&
                <Typography color="primary" variant="h3" align="center">{more_info_modal.title}</Typography>}
              {more_info_modal?.subtitle &&
              <Box mt={3}>
                <Typography color="primary" variant="h5">{more_info_modal.subtitle}</Typography>
              </Box>}
              {more_info_modal?.body &&
                <Box mt={3}>
                  <Wysiwyg
                    body={more_info_modal.body} variant="body2"
                  />
                </Box>}
              {more_info_modal?.more_link &&
                <Box textAlign="center" mt={2} pb={4}>
                  <Link color="primary" variant="body1" href={more_info_modal?.more_link?.href} underline="always">{more_info_modal?.more_link?.label}</Link>
                </Box>}
              {more_info_modal?.footnote &&
              <Typography variant="caption">{more_info_modal?.footnote}</Typography>}
            </ModalBox>
          </>}
        </Box>
      </Box>
    </Box>
  )
}
