import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function WifiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          fill={COLORS.primary} d="M4.8,24h14.5c2,0,3.6-1.6,3.6-3.6V18c0-2-1.6-3.6-3.6-3.6h-6V9.6h-2.4v4.8h-6c-2,0-3.6,1.6-3.6,3.6v2.5
          C1.2,22.4,2.8,24,4.8,24z M3.6,18c0-0.6,0.5-1.2,1.2-1.2h14.5c0.6,0,1.2,0.5,1.2,1.2v2.5c0,0.6-0.5,1.2-1.2,1.2H4.8
          c-0.6,0-1.2-0.5-1.2-1.2V18z"
        />
        <path
          fill={COLORS.secondary} d="M8.6,7.4C9.5,6.6,10.7,6,12,6s2.5,0.5,3.4,1.4l1.7-1.7C15.8,4.4,14,3.6,12,3.6S8.2,4.4,6.9,5.7L8.6,7.4L8.6,7.4
          z"
        />
        <path
          fill={COLORS.secondary} d="M12,2.4c2.3,0,4.4,0.9,6,2.5l1.7-1.7C17.7,1.2,15,0,12,0S6.3,1.2,4.4,3.2l1.7,1.7C7.6,3.3,9.7,2.4,12,2.4
          L12,2.4z"
        />
        <path fill={COLORS.secondary} d="M6,20.4c0.7,0,1.2-0.5,1.2-1.2S6.7,18,6,18s-1.2,0.5-1.2,1.2S5.3,20.4,6,20.4z"/>
      </svg>
    </SvgIcon>
  )
}
