import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  container: css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 10px;

    .MuiTypography-root {
      padding: 0 2px;
      margin-top: 0;
    }

    button {
      font-family: ${theme.typography.fontFamily!};
      font-weight: ${theme.typography.fontWeightMedium!};
      font-size: ${fontSizes.body2};
      line-height: 1.4;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.palette.primary.main};
      min-width: 187px;
      min-height: 53px;
      border-radius: ${commonStyling.roundedBorders.top};
      box-shadow: 0px -20px 40px #00000014;
      background-color: white;
      text-decoration: none;
      padding-bottom: 0 !important;
      outline: none;

      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.body1};
        line-height: 1.5;
        min-width: 240px;
      }

      img {
        margin-right: 5px;

        ${theme.breakpoints.up('sm')} {
          margin-right: 10px;
        }
      }

      :hover {
        text-decoration: none;
        background-color: white;
      }
    }
  `,
  notActive: css`
    background-color: transparent !important;
    box-shadow: none !important;
    font-weight: ${theme.typography.fontWeightRegular!} !important;
  `,
}

export default classes
