import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import theme from '../../styles/theme'

const classes = {
  stickyContainer: css`
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(0, 0, 2)}
      z-index: 2;
    }
  `,
  contentContainer: css`
    display: flex;
    align-items: center;
    text-align: center;
    background: white;
    color: ${theme.palette.primary.main};
    padding: ${theme.spacing(2, 3)};
    ${theme.breakpoints.up('md')} {
      flex-direction: column;
    }
    &:focus {
      outline: none;
      border: none;
    }
    > .MuiTypography-body1:first-child {
      padding-right: ${theme.spacing(3)}px;
    }
    .MuiButtonBase-root {
      min-width: 100%;
      img{
        margin-left: ${theme.spacing(1)}px;
      }
      &.animatedIconButton{
        ${theme.breakpoints.up('md')} {
          position: relative;
          padding-right: ${theme.spacing(3)}px !important;
        }
        .MuiTypography-body1{
          ${theme.breakpoints.up('md')} {
            padding-right: ${theme.spacing(1)}px;
          }
        }
        img{
          animation: tilt-icon .4s infinite alternate;
        }
        @keyframes tilt-icon {
          from {
            transform: translateX(4px);
          }
          to {
            transform: translateX(-2px);
          }
        }
      }
    }
    ${theme.breakpoints.up('md')} {
      min-width: 650px;
      max-width: fit-content;
      border-radius: ${commonStyling.roundedBorders.all};
      border: 1px solid ${theme.palette.grey[300]};
      flex-direction: row;
      .MuiButtonBase-root {
        min-width: auto !important;
        max-width: 100%;
      }
    }
  `,
  primaryButton: css`
    position: relative;
  `,
  secondaryButton: css`
    &.hasPrimary{
      margin-right: ${theme.spacing(2)}px;
    }
  `,
  searchInSticky: css`
    padding: 0;
    width: 100%;
    ${theme.breakpoints.up('md')} {
    width: 400px;
    }
    .MuiAutocomplete-popper {
    background-color: transparent;
    width: 118% !important;
    bottom: 45px;
    text-align: left;
  
    }
  `,
}

export default classes
