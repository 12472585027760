import {
  css,
} from 'linaria'
import fontSizes from '../../../../styles/fontSizes'
import theme from '../../../../styles/theme'
import commonStyling from '../../../../styles/commonStyling'

export const classes = {
  container: css`
    min-height: 250px;
    background-color: ${theme.palette.primary.main};
    background-repeat: repeat-x;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    padding: ${theme.spacing(4)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px;
  `,
  options: css`
    position: relative;
    top: -120px;
  `,
  card: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(3, 2)};
    width: 100%;
    z-index:100;
    margin-bottom: ${theme.spacing(2)}px;
    transition: all 0.4s ease-in-out;

    &.MuiPaper-root {
      box-shadow: ${commonStyling.boxShadowLight};
    }

    &:hover {
      cursor: pointer;
      z-index:100;
      transform: scale(1.03);
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4)}px;
    }
  `,
  cardTitle: css`
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightRegular!}
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h4};
      }
    }
  `,
  menuInfo: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  icon: css`
    margin-right: ${theme.spacing(2)}px;
    ${theme.breakpoints.down('sm')} {
      width: 50px
      }
  `,
  pageTitle: css`
    &.MuiTypography-root {
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h3};
      }
    }
  `,
}
