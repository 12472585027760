import {
  Box, Grid, Link, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'
import React from 'react'
import classes from './ContactUs.styles'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { ContactUsProps } from './ContactUs'
import MediaImage from '../Media/MediaImage'
import { general } from '../../styles/theme'

export default function ContactUsInner({
  help_text, phone_icon, phone_number, mobile,
}: ContactUsProps) {
  return (
    <Grid container className={cx(classes.container, mobile && classes.mobileWrapper)} alignItems="center" justify="center">
      <div className={classes.helpText}>
        <Typography variant="body1" color="primary">{help_text}</Typography>
      </div>
      <Box display="flex" ml={1}>
        <div className={classes.phoneWrapper}>
          {phone_icon &&
            <MediaImage
              className={classes.icon}
              src={phone_icon}
              width={18}
              height={16}
              alt="phone icon"
            />}
          <Link
            className={classes.phone}
            href={`tel:${phone_number}`}
            onClick={marketingEvents.clickedOnTelesalesNumber}
          >
            <Typography variant="body1" color="secondary" className={general.font.weight.semibold}>
              {phone_number}
            </Typography>
          </Link>
        </div>
      </Box>
    </Grid>
  )
}
