
/* eslint complexity: ["error", 100] */
import React, {
  useEffect, useState,
} from 'react'
import createCRMApiClient from '../../../api/CRMApi'
import { useOrderContext } from '../OrderContext'
import LoginForm, { LoginFormValues } from './LoginForm.tsx/LoginForm'
import OrderMenu, { OrderManagementProps } from './OrderMenu/OrderMenu'
import marketingEvents from '../../../utils/marketing/marketingEvents'
import { IWorkorderProps } from '../../../api/WorkOrder'
import { PremiseDetail } from '../../../api/Addresses'
import { ScheduleType } from '../../../api/BookingCalendar'

interface OrderFlowComponents {
  data: {
    components: {[key: string]: any}[];
  };
}

export type MenuOptions = 'installation' | 'port-number'
const OrderManagement = ({ data: { components } }: OrderFlowComponents) => {
  const {
    options, setOptions,
  } = useOrderContext()

  const [
    hobsOrderInfo,
    setHobsOrderInfo,
  ] = useState<any | null>(null)

  const [
    workOrder,
    setWorkOrder,
  ] = useState<IWorkorderProps | null>(null)

  const [
    fullPremise,
    setFullPremise,
  ] = useState<PremiseDetail | null>(null)

  const [
    error,
    setError,
  ] = useState('')

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false)

  const [
    menuSelection,
    setMenuSelection,
  ] = useState<null | MenuOptions>(null)

  const [
    scheduleType,
    setScheduleType,
  ] = useState<ScheduleType>(ScheduleType.INSTALL)

  const [
    orderManagementProps,
    setOrderManagementProps,
  ] = useState<OrderManagementProps>()

  const apiErrorMsg = 'We’re sorry, but something went wrong on our end, please try again later.'
  const errorMsg = 'We can’t find this account number. If you have just placed an order please wait one hour before trying to book or reschedule. Otherwise, check you have the correct number and try again.'

  useEffect(() => {
    const orderManagementCompProps = components.find((item) => item.type === 'order_management') as OrderManagementProps

    if (orderManagementCompProps) {
      setOrderManagementProps(orderManagementCompProps)
    }

    setOptions({
      ...options,
      hasPortedNumber: false,
    })
  }, [])

  useEffect(() => {
    if (error.length > 0) {
      setLoading(false)
    }
  }, [error])

  const handleMenuSelectionChange = (selection: MenuOptions) => {
    setMenuSelection(selection)
  }

  const checkWorkOrderStatus = (workOrder: IWorkorderProps, scheduleType: ScheduleType) => {
    const currentTime = new Date()
      .getTime()
    const timeLimit = currentTime + 86400000
    const appointmentTime = new Date(workOrder.workOrderTaskAppointmentWindowStartDate)
      .getTime()
    const lessThan24Hours = timeLimit >= appointmentTime
    const alreadyInstalled = currentTime >= appointmentTime
    let error = false

    if (lessThan24Hours) {
      const errorMsg = scheduleType === ScheduleType.INSTALL ?
        'This installation appointment is less than 24 hours from now so you cannot book or reschedule.' :
        'This engineering visit appointment is less than 24 hours from now so you cannot book or reschedule.'
      marketingEvents.error(options, 'ERR-ORDER-MANAGEMENT-2', 'Order management error', errorMsg)
      setError(errorMsg)
      error = true
    }

    if (alreadyInstalled) {
      const errorMsg = scheduleType === ScheduleType.INSTALL ?
        'This installation has already took place. Please check the account number is correct.' :
        'Engineering visit has already took place. Please check the account number is correct.'
      marketingEvents.error(options, 'ERR-ORDER-MANAGEMENT-2', 'Order management error', errorMsg)
      setError(errorMsg)
      error = true
    }

    return error
  }

  const getAccountDetails = async (accountId: string) => {
    setLoading(true)
    setError('')

    const client = createCRMApiClient()
    const hobsOrder = await client.hobsOrderInfo.getId(accountId)

    if (hobsOrder && ('erorr' in hobsOrder || hobsOrder.code === 'SUPPLIER_FAULT')) {
      setError(apiErrorMsg)
      return
    }

    if (!hobsOrder || hobsOrder.length === 0) {
      setError(errorMsg)
      return
    }

    const hobsOrderInfo = await client.hobsOrderInfo.get(hobsOrder.provisioning?.order_id)

    if (hobsOrderInfo && 'error' in hobsOrderInfo) {
      setError(apiErrorMsg)
      return
    }

    if (!hobsOrderInfo || hobsOrderInfo.length === 0) {
      setError(errorMsg)
      return
    }

    const premise = await client.addresses.get(Number(`${hobsOrderInfo.ViewOrderDetailsResponseMessage.order.CommonInformation.Customer.CustomerContact.ContactMedium.Address.externalAddressRefID}`))
    const premiseCoverage = await client.addresses.getPremiseCoverage(Number(`${hobsOrderInfo.ViewOrderDetailsResponseMessage.order.CommonInformation.Customer.CustomerContact.ContactMedium.Address.externalAddressRefID}`))

    if (!premise || 'error' in premise || !premiseCoverage || 'error' in premiseCoverage) {
      setError(apiErrorMsg)
      return
    }

    // DCMS
    const areas = orderManagementProps?.dcms_challenge_area?.toLowerCase().replace(/\s/g, '').split('|')
    const dcmsChallengeArea = Boolean(
       premise?.box && premise.box.dcms_grant_flag === 'Y' && premise.box.dcms_eligibility === 'I' && areas?.includes(premise.box.project_name.toLowerCase()))
    const dcmsEligibility = Boolean(
       premise?.box && ((premise.box.dcms_grant_flag === 'Y' && premise.box.dcms_eligibility === 'E') || dcmsChallengeArea))

    if (dcmsEligibility) {
      setError('Your address is part of the Government Gigabit Broadband Voucher Scheme.')
      return
    }
    // /DCMS

    const fullPremise = {
      ...premise,
      ...premiseCoverage,
    }
    setFullPremise(fullPremise)

    const workOrder = await client.workOrder.getWorkOrder(hobsOrderInfo.ViewOrderDetailsResponseMessage.order.OrderLines.SubscriberOrderItem.Subscriber.CustomerAccountID)
    if ('error' in workOrder || 'fault' in workOrder) {
      marketingEvents.error(options, 'ERR-ORDER-MANAGEMENT-2', 'Order management error', apiErrorMsg)
      setError(apiErrorMsg)
      return
    }

    const serviceTypeWorkOrder = workOrder.data.data.find((item: any) => item.scheduleType === 'SERVICE')
    const installTypeWorkOrder = workOrder.data.data.find((item: any) => item.scheduleType === 'INSTALL')

    if (serviceTypeWorkOrder) {
      if (checkWorkOrderStatus(serviceTypeWorkOrder, ScheduleType.SERVICE)) {
        return
      }

      setScheduleType(ScheduleType.SERVICE)
      setWorkOrder(serviceTypeWorkOrder)
    } else if (installTypeWorkOrder) {
      if (checkWorkOrderStatus(installTypeWorkOrder, ScheduleType.INSTALL)) {
        return
      }

      setScheduleType(ScheduleType.INSTALL)
      setWorkOrder(installTypeWorkOrder)
    }

    setError('')
    setHobsOrderInfo(hobsOrderInfo)
  }

  const handleSubmit = async ({ accountId }: LoginFormValues) => {
    setOptions({
      ...options,
      accountId,
    })
    await getAccountDetails(accountId)
  }

  const OrderManagementRender = () => {
    if (!hobsOrderInfo || !fullPremise) {
      return (<LoginForm error={error} onSubmit={handleSubmit} loading={loading}/>)
    }

    if (!orderManagementProps) {
      return null
    }

    return (
      <OrderMenu
        orderMenuProps={orderManagementProps}
        hobsOrderInfo={hobsOrderInfo}
        workOrder={workOrder}
        fullPremise={fullPremise}
        menuSelection={menuSelection}
        onMenuSelectionChange={handleMenuSelectionChange}
        scheduleType={scheduleType}
      />
    )
  }

  return OrderManagementRender()
}

export default OrderManagement
