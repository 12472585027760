import { css } from 'linaria'
import theme from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'

const classes = {
  modal: css`
    position: relative;
    div[aria-hidden] {
      background-color: transparent !important;
    }
  `,
  body: css`
    outline: none;
    height: 100%;
    .close-button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      color: white;
      position: absolute;
      right: 5px;
      top: 20px;
      .MuiSvgIcon-root {
        font-size: ${commonStyling.svgFontSize};
      }
      .MuiTypography-body1 {
        font-weight: ${theme.typography.fontWeightLight!};
        margin-bottom: 3px;
      }
      ${theme.breakpoints.up('md')} {
        right: 25px;
      }
    }
  `,
  backDrop: css`
    height: 65px;
    background-color: rgba(0, 0, 0, 0.5);
  `,
  checker: css`
    color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    background-color: white;
    height: 100%;
    .MuiButtonBase-root {
      width: 315px;
    }
  `,
  text: css`
    .MuiTypography-body1 {
      margin: 0 auto;
      max-width: 465px;
      margin-top: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
}

export default classes
