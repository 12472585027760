import React from 'react'
import {
  ButterCMSComponent, getButterComponentByType,
} from '../../butter'

interface Props {
  data: {
    components: ButterCMSComponent[];
  };
  children?: React.ReactNode;
}

const OrderComponents = ({
  data, children,
}: Props) => {
  return (
    <div>
      {data && data.components.map(({
        type, ...rest
      }, i) => {
        const C: any = getButterComponentByType(type)

        if (!C) {
          return null
        }

        const isHeaderComponent = type === 'menu'
        if (isHeaderComponent) {
          return (
            <React.Fragment key={i}>
              <C {...rest}/>
              {children}
            </React.Fragment>
          )
        }

        return (
          <C key={i} {...rest}/>
        )
      })}
    </div>
  )
}

export default OrderComponents
