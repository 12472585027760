import React from 'react'
import {
  Typography, Box,
} from '@material-ui/core'
import classes from './RegisterInterestCTA.styles'
import CheckIcon from '@material-ui/icons/Check'

export interface RegisterInterestCTAPhrases {
    readonly meta: {
        id: number;
    };
    readonly text: string;
    readonly img_src: string;
  }
export interface RegisterInterestCTAProps {
    readonly title: string;
    readonly second_title?: string;
    readonly body1?: string;
    readonly body2?: string;
    readonly register_interest_cta_phrases?: RegisterInterestCTAPhrases[];
  }

export default function RegisterInterestCTA({
  title,
  second_title,
  body1,
  body2,
  register_interest_cta_phrases,
}: RegisterInterestCTAProps) {
  return (
    <div className={classes.wrapper}>
      <Typography variant="h1" color="primary">{title}<br/>{second_title}</Typography>
      <Box className={classes.body}>
        <Typography variant="body1" color="primary">{body1}</Typography>
        {body2 &&
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: body2 }}/>}
      </Box>
      {register_interest_cta_phrases && Object.keys(register_interest_cta_phrases).length > 0 &&
        <ul className={classes.phrasesWrapper}>
          {register_interest_cta_phrases?.length > 0 && register_interest_cta_phrases.map(phrase => (
            <li key={phrase.meta.id} className={classes.phrase}>
              <CheckIcon/>{phrase.text}
            </li>
          ))}
        </ul>}
    </div>
  )
}
