import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'

export default function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 16" fontSize="small">
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="2.82353" fill="#370050"/>
        <rect y="6.58826" width="20" height="2.82353" fill="#370050"/>
        <rect y="13.1765" width="20" height="2.82353" fill="#370050"/>
      </svg>
    </SvgIcon>
  )
}
