import { AxiosInstance } from 'axios'

export interface ReferralValidateResponse {
  data: {
    code: string;
    message: string;
    messages: string[];
  };
  status: number;
}

export default function HobsReferral(client: AxiosInstance) {
  async function referralValidateCode({
    referral_code,
  }: { referral_code: string }) {
    try {
      const { data } = await client.get<ReferralValidateResponse>('referral/v1/0.0/validate?referralCode=' + referral_code)
      return data
    } catch (err) {
      return err.response
    }
  }

  return {
    referralValidateCode,
  }
}
