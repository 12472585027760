import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  closeIcon: css`
    &.MuiSvgIcon-root {
      color: white;
      position: absolute;
      right: 0;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  `,
  dialog: css`
    .MuiDialog-paper {
      margin: 0;

      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }
    }

    .MuiDialog-paperWidthMd {
      max-width: 1312px;
    }

    .MuiDialog-paperScrollPaper {
      max-height: 100%;
    }
  `,
  dialogSuccess: css`
    .MuiDialog-paperScrollPaper {
      height: 100%;
      display: flex;
      justify-content: center;

      ${theme.breakpoints.up('md')} {
        height: 70%;
      }
    }
  `,
  imageContainer: css`
    order: 0;

    ${theme.breakpoints.up('md')} {
      order: 1;
    }
  `,
  imageClass: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  title: css`
    &.MuiTypography-root {
      text-align: center;

      ${theme.breakpoints.up('md')} {
        text-align: left;
        font-size: ${fontSizes.h2};
        padding-bottom: ${theme.spacing(3)}px;
      }
    }
  `,
  contentContainer: css`
    padding: ${theme.spacing(3)}px;

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(6)}px;
    }
    [class*='fieldContainer'] {
      margin-top: ${theme.spacing(2)}px;
    }
  `,
  option: css`
    border: none;
    outline: none;

    &.MuiListItem-root{
      padding-top: 0px;
      padding-bottom: 0px;
      font: normal normal normal 15px/21px Poppins;

      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(1)}px;
        padding-bottom: ${theme.spacing(1)}px;
      }
    }

    &.MuiMenuItem-root:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
      color: white;
      background-color: ${COLORS.primary};
    }
 `,
  errorLabel: css`
    color: ${COLORS.error} !important;
  `,
  errorInput: css`
    border: 1px solid ${COLORS.error} !important;
  `,
  selectClass: css`
    &.MuiInput-root {
      background-color: #F8F8F8;
      border-radius: 50px;
      overflow: hidden;
      width: 100%;

      .MuiSelect-select.MuiSelect-select {
        padding-left: ${theme.spacing(3)}px;
        padding-top: 12px;
        padding-bottom: 14px;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
  `,
  selectContainer: css`
    &.MuiGrid-root {
      margin-bottom: ${theme.spacing(2)}px;

      ${theme.breakpoints.up('md')} {
        margin-bottom: ${theme.spacing(-1)}px;
      }
    }
  `,
  activeSelect: css`
    &.MuiInput-root {
      background: ${COLORS.smoke};
      color: ${COLORS.primary};
      .MuiSelect-select.MuiSelect-select {
        color: ${COLORS.primary};
      } 
    }
  `,
  icon: css`
    padding-right: ${theme.spacing(1)}px;
    &.MuiSelect-icon {
      color: ${COLORS.primary};
    }
  `,
  activeIcon: css`
    &.MuiSelect-icon {
      color: ${COLORS.primary};;
    }
  `,
  successMessage: css`
    &.MuiTypography-root {
      text-align: center;
    }
  `,
  buttonWrapper: css`
    &.MuiGrid-justify-xs-center {
      ${theme.breakpoints.up('md')} {
        justify-content: flex-start;
      }
    }
  `,
  buttonClass: css`
    &.MuiButton-root {
      padding: 0 81px !important;
    }
  `,
  loading: css`
    position: absolute;
    right:  ${theme.spacing(4.53)}px;;
  `,
  inputLabel: css`
    &.MuiTypography-root {
      margin: ${theme.spacing(3, 1)};
      font-size: ${fontSizes.h5};
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary};
    }
  `,
}

export default classes
