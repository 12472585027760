import React from 'react'
import classes from './NavigationTabs.styles'
import {
  Tab, Tabs,
} from '@material-ui/core'
import router from 'next/router'
import { cx } from 'linaria'

interface NavigationTab {
  title: string;
  url?: string;
}

interface NavigationTabsProps {
  className?: string;
  tabs?: NavigationTab[];
  activeTab: number;
  handleClick?: (i: number, goToPage?: boolean) => void;
  tabsVariant?: 'standard' | 'scrollable' | 'fullWidth';
}

const NavigationTabs = ({
  className, tabs, activeTab, handleClick, tabsVariant = 'scrollable',
}: NavigationTabsProps) => {
  if (!tabs) {
    return null
  }

  const handleChange = (_event: React.ChangeEvent<{}>, newActiveIndex: number) => {
    if (handleClick) {
      return handleClick(newActiveIndex, true)
    }

    const selectedTab = tabs[Number(newActiveIndex)]

    if (selectedTab?.url) {
      return router.push(selectedTab.url)
    }
  }

  return (
    <Tabs
      className={cx(classes.listContainer, className)}
      value={activeTab}
      aria-label="Navigation tabs"
      variant={tabsVariant}
      onChange={handleChange}
    >
      {tabs.map((tab: NavigationTab, i: number) => (
        <Tab
          key={i}
          label={tab.title}
        />
      ))}
    </Tabs>
  )
}

export default NavigationTabs
