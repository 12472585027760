import React, { ChangeEvent } from 'react'
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Grid,
  Paper,
  Hidden,
} from '@material-ui/core'

import Indicators from '../Indicators/Indicators'
import theme from '../../styles/theme'
import classes from './TabbedCopies.styles'
import { Container } from 'next/app'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'

interface Tab {
  title: string;
  text: string;
  img?: string;
  mobile_img?: string;
  media_image?: MediaImageProps;
}

interface HeaderTabsProps {
  tabs: Tab[];
  activeIndex: number;
  handleChange: ((event: ChangeEvent<{}>, value: number) => void) | undefined;
}

function HeaderTabs({
  tabs, activeIndex, handleChange,
}: HeaderTabsProps) {
  return (
    <Paper elevation={10} className={classes.tabs}>
      <Tabs
        value={activeIndex}
        aria-label="Tabbed Copies"
        onChange={handleChange}
      >
        {tabs.map(({ title }, index) =>
          <Tab label={title} key={title} value={index} className={classes.tab}/>,
        )}
      </Tabs>
    </Paper>
  )
}

interface TabPanelProps {
  children: React.ReactNode;
  activeIndex: number;
  index: number;
}

function TabPanel({
  children, activeIndex, index, ...other
}: TabPanelProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={activeIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {activeIndex === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

export interface TabbedCopiesProps {
  title: string;
  anchor_ref?: string;
  tabs: Tab[];
}

export default function TabbedCopies({
  title, anchor_ref, tabs,
}: TabbedCopiesProps) {
  const [
    activeIndex,
    setActiveIndex,
  ] = React.useState(0)

  const handleChange = (_: ChangeEvent<{}>, newValue: number): void => {
    setActiveIndex(newValue)
  }

  const handleIndicatorChange = (newValue: number): void => {
    setActiveIndex(newValue)
  }

  const idProp = anchor_ref ? { id: anchor_ref } : {}

  return (
    <section {...idProp}>
      <Typography variant="h1" color="primary" className={classes.title}>{title}</Typography>
      <Paper elevation={10} square className={classes.panel}>
        <Hidden smDown implementation="css">
          <HeaderTabs tabs={tabs} activeIndex={activeIndex} handleChange={handleChange}/>
        </Hidden>

        {tabs.map(({
          title, text, img, mobile_img, media_image,
        }, index) => (
          <TabPanel key={index} activeIndex={activeIndex} index={index}>
            <Grid container justify="center">
              <Grid item md={7} lg={8}>
                <section>
                  <Typography variant="h1" color="secondary" className={classes.tabHeading}>{title}</Typography>
                  <Typography variant="subtitle1" className={classes.text}>{text}</Typography>
                </section>
              </Grid>
              <Grid container item md={5} lg={4} justify="center">
                <Container maxWidth="xs">
                  {img &&
                  <>
                    <Hidden smDown implementation="css">
                      <img src={img} alt={title} width="400" className={classes.img}/>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                      <img src={mobile_img || img} alt={title} width="400" className={classes.img}/>
                    </Hidden>
                  </> }
                  {media_image?.src &&
                  <MediaImage
                    src={media_image.src}
                    width={media_image.width || 400}
                    height={media_image.height || 469}
                    alt={media_image.alt}
                    mobile_src={media_image.mobile_src}
                    mobile_width={media_image.mobile_width}
                    mobile_height={media_image.mobile_height}
                  />}
                </Container>
              </Grid>
            </Grid>
          </TabPanel>
        ))}

        <Hidden mdUp implementation="css">
          <Indicators
            length={tabs.length}
            activeIndex={activeIndex}
            handleClick={handleIndicatorChange}
            color={theme.palette.primary.main}
            className={classes.indicators}
          />
        </Hidden>
      </Paper>
    </section>
  )
}
