import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

export const imageSizes = Object.freeze({
  extrasmall: {
    desktop: {
      width: 38,
      height: 38,
    },
    tablet: {
      width: 38,
      height: 38,
    },
    mobile: {
      width: 38,
      height: 38,
    },
  },
  small: {
    desktop: {
      width: 64,
      height: 64,
    },
    tablet: {
      width: 64,
      height: 64,
    },
    mobile: {
      width: 64,
      height: 64,
    },
  },
  medium: {
    desktop: {
      width: 136,
      height: 152,
    },
    tablet: {
      width: 136,
      height: 152,
    },
    mobile: {
      width: 136,
      height: 152,
    },
  },
  large: {
    desktop: {
      width: 248,
      height: 176,
    },
    tablet: {
      width: 248,
      height: 176,
    },
    mobile: {
      width: 248,
      height: 176,
    },
  },
})

const classes = {
  section: css`
    display: flex;
    justify-content: center;
  `,
  wrapper: css`
    max-width: 800px;
    padding: ${theme.spacing(0, 2)};

    img {
      padding: 0px !important;
      object-fit: contain;
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(0, 0)};
      max-width: 980px;
    }
  `,
  fullWidth: css`
    width: 100%;
  `,
  paddings: css`
    padding: ${theme.spacing(5, 0, 5)};
  `,
  greyGradientBackground: css`
    background-image: linear-gradient(180deg, white 0%, ${theme.palette.warning.light} 100%);
  `,
  primaryGradientBackground: css`
    background-image: linear-gradient(180deg, #380450 0%, #30071A 100%);
  `,
  whiteText: css`
    color: white;

    .MuiTypography-root {
      color: white;
    }
    [class*="itemTitle"], [class*="itemText"] p {
      color: white !important;
    }
  `,
  titleWrapper: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: ${COLORS.bodyTextPrimary};
    .MuiTypography-body1 {
      margin-top: ${theme.spacing(4)}px;
      font-weight: ${theme.typography.fontWeightLight!};
      ${theme.breakpoints.up('md')} {
        max-width: ${commonStyling.breakpoints.desktopSmall};
      }
    }
  `,
  title: css`
    color: ${theme.palette.primary.main};
    max-width: 760px;

    ${theme.breakpoints.up('md')} {
      font-size: ${fontSizes.h2} !important;
    }
  `,
  largeTitle: css`
    max-width: 660px;

    ${theme.breakpoints.up('md')} {
      font-size: ${fontSizes.h1} !important;
    }
  `,
  itemContainer: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    .nextImageWrapper{
      margin-bottom:  ${theme.spacing(2)}px;
    }
  `,
  mediaWrapper: css`
    margin-bottom:  ${theme.spacing(2)}px;
    ${theme.breakpoints.down('xs')} {
      max-width: ${theme.spacing(16)}px;;
    }
  `,
  itemContainerGrid: css`
    &.MuiGrid-container {
      display: grid;
      gap: ${theme.spacing(4)}px;
      grid-template-columns: repeat(3, 1fr);
      [class*=itemContainer] {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        width: 100%;
        [class*=iconTextWrapper]:last-child {
          margin-bottom: 0;
        }
      }
      ${theme.breakpoints.down('sm')} {
        gap: ${theme.spacing(2)}px;
      }
      ${theme.breakpoints.down('xs')} {
        gap: ${theme.spacing(6)}px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `,
  itemContainerNoPadding: css`
    padding-top: 0;
  `,
  centerText: css`
    text-align: center;
    align-items: flex-start;
    justify-content: center;

    a {
      max-width: 100%;
      margin: auto;
    }
  `,
  iconTextWrapper: css`
    ${theme.breakpoints.up('md')} {
      text-align: center;
    }
  `,
  bottomArrowBtn: css`
    min-width: 272px !important;
    font-size: ${fontSizes.h5} !important;
    &:hover {
      background-color: transparent !important;
    }
    .MuiButton-label {
      &:hover {
        text-decoration: underline;
      }
    }
    svg{
      margin-left: 10px;
      margin-top: 3px;
    }
  `,
  itemTitle: css`
    padding-bottom: ${theme.spacing(1)}px;
    &.MuiTypography-root {
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  itemText: css`
    ${theme.breakpoints.up('md')} {
      text-align: center;
    }

    p {
      margin-top: ${theme.spacing(1)}px;
      margin: 0;
      font-weight: ${theme.typography.fontWeightLight!};
      line-height: 27px;
      color: ${COLORS.bodyTextPrimary};
    }

    a {
      color: ${COLORS.secondaryContrast};
      font-weight: ${theme.typography.fontWeightBold!}
    }
  `,
  buttonDiv: css`
    margin-top: ${theme.spacing(7)}px;
    text-align: center;
  `,
  hiddenExpandButton: css`
    margin-bottom: ${theme.spacing(7)}px;
  `,
  slider: css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing(7)}px;
    text-align: center;
    ${theme.breakpoints.down('md')} {
      margin-top: ${theme.spacing(4)}px;
      }
    img {
      margin: auto;
      max-width: 70vw;
      ${theme.breakpoints.down('md')} {
        margin-bottom: ${theme.spacing(2)}px;
        width: 100%;
      }
    }
    .MuiTypography-root {
      padding: 0 ${theme.spacing(3)}px;
    }
    .slick-list {
      margin: auto;
      min-width: 100% !important;
      overflow: visible !important;
    }
    .slick-arrow {
      position: absolute;
      z-index: 10;
      top: calc(50% - 30px);
      margin: 0 ${theme.spacing(2)}px;
      width: 48px;
      height: 48px;
      :before {
        border: solid ${theme.palette.primary.main};
        border-width: 0 2px 2px 0;
        display: inline-block;
        font-size: 0.45rem;
        opacity: 1 !important;
      }
    }
    .slick-prev:before {
      position: relative;
      left: 2px;
      transform: rotate(135deg);
    }
    .slick-next:before {
      position: relative;
      right: 2px;
      transform: rotate(-45deg);
    }
  `,
}

export default classes
