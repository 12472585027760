import React from 'react'
import {
  Box, Link, Typography,
} from '@material-ui/core'
import {
  BOX, general,
} from '../../../../../styles/theme'
import ModalBox from '../../../../ModalBox/ModalBox'
import Logo from '../../../../../../public/building-digital-uk.svg'
import { cx } from 'linaria'

const InstallationSectionDCMS = () => {
  const [
    modal,
    setModal,
  ] = React.useState(false)

  const openModal = () => {
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  return (
    <Box
      p={3}
      my={4}
      {...BOX}
    >
      <Box mb={3}>
        <img
          src={Logo}
          alt="Build Digital UK"
          width={160}
          height={130}
        />
      </Box>
      <Typography variant="h4" color="primary" className={general.font.margin.bottom.md}>Your address is part of the Government Gigabit Broadband Voucher Scheme</Typography>
      <Typography
        component="p"
        variant="body1"
        color="textPrimary"
        className={cx(general.font.margin.bottom.md, general.font.weight.book)}
      >
        This means, you’ll now be able to get our fast and reliable full fibre broadband installed straight to your home, part funded by the government, which would have otherwise been unavailable in your area.
      </Typography>
      <Typography
        component="p"
        variant="body1"
        color="textPrimary"
        className={general.font.weight.book}
      >
        Once your order is complete, you’ll receive two emails that will require your action to get your full fibre broadband connected, one from us and another from <Link href="mailto:funding.platform@notifications.service.gov.uk" className={general.font.wrap.breakWord}>funding.platform@notifications.service.gov.uk</Link>.&nbsp;
        <Link
          onClick={openModal}
          color="secondary"
          className={general.font.link.primary}
        >
          Learn more
        </Link>
      </Typography>
      <ModalBox
        visible={modal}
        sync={closeModal}
        aria-labelledby="modal-dcms"
        aria-describedby="dcms-description"
        py={{
          xs: 6,
          md: 6,
        }}
      >
        <Typography variant="h2" color="primary" className={general.font.margin.bottom.md}>Government Gigabit Broadband Voucher Scheme</Typography>
        <Typography
          component="p"
          variant="body1"
          color="textPrimary"
          className={cx(general.font.weight.book, general.font.margin.bottom.md)}
        >
          The government is committed to delivering fast and reliable gigabit-capable broadband to everyone in the UK as soon as possible, working hard with broadband providers to accelerate their commercial development plans.
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={general.font.weight.book}
        >
          For hard-to-reach areas where broadband is more costly to build, the government is investing £5 billion to ensure these places are not left behind.
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          className={cx(general.font.margin.bottom.md, general.font.margin.top.lg, general.font.weight.bold)}
        >
          What is a gigabit connection?
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={general.font.weight.book}
        >
          Gigabit-capable broadband means download speeds of at least 1 gigabit-per-second (Gbps), which is the same as 1,000 megabits per second (Mbps). Gigabit-capable broadband supports symmetrical connections, meaning your upload and download speeds will be the same. For the fastest and most reliable broadband, capable of delivering gigabit speeds, a full fibre connection is necessary.
        </Typography>
        <Box textAlign="center" mt={4}>
          <Typography
            variant="body1"
          >
            <Link
              color="secondary"
              className={general.font.link.primary}
            >
              Find out more
            </Link>
          </Typography>
        </Box>
      </ModalBox>
    </Box>
  )
}

export default InstallationSectionDCMS
