import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  spinner: css`
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 29px;
      height: 29px;
      margin: 0px;
      border: 4px solid ${theme.palette.secondary.main};
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${theme.palette.secondary.main} transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
}

export default classes
