import React from 'react'
import {
  Typography, Grid, Box,
} from '@material-ui/core'
import { cx } from 'linaria'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { MenuItem } from '../Header'
import classes from './DesktopHeader.styles'
import { ExpandMore } from '@material-ui/icons'
import DesktopSubHeader from './DesktopSubHeader'
import commonStyling from '../../../styles/commonStyling'

interface DesktopMainHeaderItemProps {
  item: MenuItem;
  index: number;
  setMenuIndex: Function;
  menuIndex: number | null;
  openSubMenu: boolean;
  setOpenSubMenu: (open: boolean) => void;
}

export default function DesktopMainHeaderItem({
  item,
  index,
  setMenuIndex,
  menuIndex,
  openSubMenu,
  setOpenSubMenu,
}: DesktopMainHeaderItemProps) {
  const router = useRouter()
  const currentPath = router.asPath

  const isMainActive = item.url === currentPath
  const isSubActive = item.sub_menu_items?.some(sub => sub.url === currentPath)
  const hasOpenSubMenu = menuIndex === index && openSubMenu
  const activeItemClass = cx(classes.menuItem, isSubActive || isMainActive || hasOpenSubMenu ? classes.activeItem : '')

  const hasSubMenuItems = Boolean(item?.sub_menu_items?.length)

  return (
    <Grid
      component="li"
      item
      key={index}
      onMouseOver={() => {
        setMenuIndex(index)
        setOpenSubMenu(true)
      }}
      className={classes.mainHeaderItem}
    >
      <Link href={item.url ?? ''}>
        <a
          className={activeItemClass}
          onFocus={() => {
            setMenuIndex(index)
            if (hasSubMenuItems) {
              return setOpenSubMenu(!openSubMenu)
            }

            return setOpenSubMenu(false)
          }}
        >
          <Box display="flex" whiteSpace="nowrap">
            <Typography variant="body2" className={commonStyling.differentFontWeightOnHover} data-text={item.title}>{item.title}</Typography>
            {hasSubMenuItems &&
            <div className={cx(classes.arrowIcon, hasOpenSubMenu ? classes.arrowExpandMoreIcon : classes.arrowExpandLessIcon)}>
              <ExpandMore/>
            </div>}
          </Box>
        </a>
      </Link>
      {item && hasSubMenuItems && hasOpenSubMenu &&
      <DesktopSubHeader
        subMenuItems={item.sub_menu_items}
      />}
    </Grid>
  )
}
