import { AxiosInstance } from 'axios'
import moment from 'moment'

export default function HobsLeads(
  client: {
  axiosInstanceCreateHobsUser: AxiosInstance;
  axiosInstanceRegisterInterest: AxiosInstance;
  },
) {
  async function register(email: string, firstName: string, lastName: string, phone: string, authMarketing: boolean, sprn: string) {
    try {
      const dateInteraction = new Date()
      const sprnNumber = sprn
      const params = {
        email,
        nickname: firstName,
        given_name: firstName,
        family_name: lastName,
        user_metadata: {
          marketing_preferences: {
            authPrivacy: true,
            authTerms: true,
            authMarketingEmail: authMarketing,
            authMarketingPhone: authMarketing,
            authMarketingSMS: authMarketing,
            authMarketingPost: authMarketing,
            authCreatedAt: moment.utc(dateInteraction)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          },
          phone_number: phone,
          sprn: sprnNumber,
        },
      }
      const { data } = await client.axiosInstanceCreateHobsUser.post<{status: 'Lead Processed'}>('/user/v1/0.0/create-user', params)

      return data
    } catch (err) {
      return {
        error: err,
        message: 'failed to submit lead',
      }
    }
  }

  async function registerInterest(params: any) {
    const {
      address, firstName,
      lastName,
      contractEndDate,
      provider,
      email, phone,
    } = params
    try {
      const params = {
        serviceType: ['VPN_DATA'],
        title: 'Mr',
        gender: 'MALE',
        addressVo: {
          searchPinCode: address.pinCode,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          town: address.town,
          city: address.city,
          stateOrProvince: address.stateOrProvince,
          countryCode: 'ENG',
          pinCode: address.pinCode,
          totalAddress: address.totalAddress,
          addrType: 'Communication',
          externalAddressRefID: address.sprn,
        },
        leadSource: 'Telesales Inbound Calls',
        firstName,
        lastName,
        dateOfBirth: '1901-01-21T18:30:00.000Z',
        mobileNum: phone,
        emailAddress: email,
        customerCategory: 'RES',
        customerSegment: 'RESIDENTIAL',
        customerType: 'NO',
        customerSubType: 'NOT_APPLICABLE',
        createWorkItemOverride: 'N',
        assignToGrp: 'CPQ_Group_1',
        assignToUser: 'CFLSALESHEAD',
        usingAnyServiceProvider: 'Yes',
        contractEndDate,
        extension: {
          usingBroadbandServices: 'Yes',
          broadbandServices: provider,
        },
        userField1: '',
        userField2: '',
      }

      const { data } = await client.axiosInstanceRegisterInterest.post<any>('leads/v1/0.0/register-interest', params)
      return data
    } catch (err) {
      return {
        error: err,
        message: 'failed to submit lead',
      }
    }
  }

  return {
    register,
    registerInterest,
  }
}

