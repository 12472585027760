import React, {
  useEffect, useState,
} from 'react'
import { useRouter } from 'next/router'
import classes from './IframeHolder.styles'

export interface IframeHolderProps {
  readonly url: string;
  readonly title: string;
}

export default function IframeHolder({
  url, title,
}: IframeHolderProps) {
  const { query: { sprn } } = useRouter()
  const urlObj = new URL(url)
  const urlParams = urlObj.searchParams
  const [
    src,
    setSrc,
  ] = useState(url)
  useEffect(() => {
    if (sprn) {
      urlParams.append('sprn', sprn.toString())
      setSrc(urlObj.toString())
    }
  }, [
    urlObj,
    urlParams,
    sprn,
  ])
  return (
    <iframe title={title} frameBorder="0" className={classes.iframe} src={src}/>
  )
}
