import React from 'react'
import {
  Grid, Typography, Hidden,
} from '@material-ui/core'
import TimelineItem, { TimelineItemProps } from './TimelineItem/TimelineItem'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import classes from './Timeline.styles'

function timelineClassName(index: number): string {
  const timelinePosition = index % 3

  if (timelinePosition === 1) {
    return classes.justifyCenter
  }

  if (timelinePosition === 2) {
    return classes.justifyEnd
  }

  return ''
}

export interface TimelineProps {
  title: string;
  mobile_line?: string;
  desktop_line?: string;
  timeline_items: TimelineItemProps[];
  anchor_ref: string;
}

const Timeline = ({
  title,
  mobile_line,
  desktop_line,
  timeline_items,
  anchor_ref,
}: TimelineProps) => {
  return (
    <SectionWithMargin id={anchor_ref} className={classes.sectionClass}>
      <Typography variant="h3" color="primary" className={classes.title}>{title}</Typography>
      <Hidden xsDown>
        <Grid container spacing={3} className={classes.itemsWrapper}>
          {timeline_items?.length && timeline_items.map((timeline, index) => (
            <Grid key={index} item sm={4}>
              <TimelineItem key={index} timeline={timeline} className={timelineClassName(index)}/>
            </Grid>
          ))}
        </Grid>
        <div style={{ backgroundImage: `url(${desktop_line})` }} className={classes.backgroundImage}/>
      </Hidden>
      <Hidden smUp>
        <Grid container spacing={2} className={classes.itemsWrapper}>
          {timeline_items?.length && timeline_items.map((timeline, index) => {
            if (index >= 9) {
              return null
            }

            return (
              <Grid key={index} item xs={6}>
                <TimelineItem key={index} timeline={timeline} className={index % 2 === 1 ? classes.justifyEnd : ''}/>
              </Grid>
            )
          })}
        </Grid>
        <div style={{ backgroundImage: `url(${mobile_line})` }} className={classes.backgroundImage}/>
      </Hidden>
    </SectionWithMargin>
  )
}

export default Timeline
