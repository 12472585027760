import { CtaProps } from '../../utils/commonProps'

interface Dictionary<T> {
  [key: string]: T;
}
export interface PolygonMapProps {
  readonly title: string;
  readonly anchor_ref?: string;
  readonly availability_text?: string;
  readonly unavailable_text?: string;
  readonly show_premise_heatmap?: boolean;
  readonly info_card_title?: string;
  readonly info_card_subtitle?: string;
  readonly info_card_button?: CtaProps;
  readonly map_id?: string;
}

export enum MapType {
  borough = 'borough',
  heatmap = 'heatmap'
}

export interface BoroughData {
  code: string;
  borough_name: string;
  borough_available: boolean;
  coordinates: Array<Coordinate[]>;
  borough_center?: google.maps.LatLng;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export type BoroughDictionary<T> = Dictionary<T> & { heatmapPoints: Array<number[]> }
