import { AxiosInstance } from 'axios'
import moment from 'moment'

export default function ContactUs(client: AxiosInstance) {
  async function createWorkItem(
    reason: string,
    firstName: string,
    lastName: string,
    userEmail: string,
    addressLine_1: string,
    addressLine_2: string,
    addressLine_3: string,
    townOrCity: string,
    postcode: string,
    contactNumber: string,
    description: string,
    accountId: string,
    customerId: string,
  ) {
    try {
      const params = {
        reason,
        description,
        accountId,
        customerId,
        itemContactInfo: {
          formofaddress: '',
          firstName,
          lastName,
          addressLine1: addressLine_1,
          addressLine2: addressLine_2,
          addressLine3: addressLine_3,
          city: townOrCity,
          state: '',
          country: 'ENG',
          zipcode: postcode,
          emailID: userEmail,
          mobileNumber: contactNumber,
        },
      }
      const {
        data,
      } = await client.post<{ data: any}>('work-item/v1/0.0/create', params)
      return data
    } catch (err) {
      return err
    }
  }

  async function customerCheck(
    userEmail: string,
    contactNumber: string,
  ) {
    try {
      const dateInteraction = new Date()
      const params = {
        interactionID: 'WEB-' + moment.utc(dateInteraction)
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          .replace(/[^\d]/g, ''),
        interactionDate: new Date()
          .toISOString(),
        sourceApplicationID: '',
        serviceName: '',
        triggeredBy: 'Digital',
        buID: 'DEFAULT',
        opID: 'CFL',
        lang: 'ENG',
        partnerID: '',
        channel: '',
        searchFilters: {
          accountEmail: userEmail,
          accountMobile: contactNumber,
          accountID: '',
          accountSPRN: '',
        },
      }
      const {
        data,
      } = await client.post<{ data: any}>('customer-check/v1/0.0/validate', params)
      return data
    } catch (err) {
      return err
    }
  }

  return {
    createWorkItem,
    customerCheck,
  }
}
