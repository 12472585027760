export const arraysEqual = (a: string[] | null, b: string[] | null) => {
  if (a === b) {
    return true
  }

  if (a == null || b == null) {
    return false
  }

  if (a.length !== b.length) {
    return false
  }

  for (const [
    i,
    element,
  ] of a.entries()) {
    if (element !== b[i]) {
      return false
    }
  }

  return true
}
