import { Hidden } from '@material-ui/core'
import { cx } from 'linaria'
import React from 'react'
import { CallMeBackModalProps } from '../CallMeBackModal/CallMeBackModal'
import classes from './ContactUs.styles'
import ContactUsInner from './ContactUsInner'

interface Time {
  title?: string;
  hours?: number;
  minutes?: number;
}

export interface ContactUsProps {
  title?: string;
  help_text?: string;
  phone_icon?: string;
  phone_number?: string;
  start_time?: Time;
  end_time?: Time;
  saturday_start_time: Time;
  saturday_end_time: Time;
  call_me_back_text?: string;
  call_me_back_modal?: CallMeBackModalProps;
  mobile?: boolean;
  visible_desktop?: boolean;
  visible_mobile?: boolean;
}

export default function ContactUs(props: ContactUsProps) {
  return (
    <>
      {props.visible_desktop && (
        <Hidden xsDown>
          <div className={cx(classes.contactWrapper, 'contactUsInner')}>
            <ContactUsInner {...props}/>
          </div>
        </Hidden>
      )}
      {props.visible_mobile && (
        <Hidden smUp>
          <div className={cx(classes.contactWrapper, 'contactUsInner')}>
            <ContactUsInner {...props}/>
          </div>
        </Hidden>
      )}
    </>
  )
}
