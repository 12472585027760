import {
  Box, IconButton, Typography,
} from '@material-ui/core'
import React from 'react'
import {
  formattedFreePeriod,
  getFormattedPrice, getPackageLabel, getPriceString, getProductTerm,
} from '../../../../../../utils/getProductDetails'
import classes from '../Basket.styles'
import { cx } from 'linaria'
import { useOrderContext } from '../../../../OrderContext'
import createCRMApiClient from '../../../../../../api/CRMApi'
import { HobsProduct } from '../../../../../../api/Packages'
import marketingEvents from '../../../../../../utils/marketing/marketingEvents'
import theme, {
  BORDERS, general,
} from '../../../../../../styles/theme'
import { DeleteOutline } from '@material-ui/icons'
import { productProps } from './BasketDetails'

interface AddOnBasketProps {
  product?: HobsProduct | null;
  isFree?: boolean;
  isMiniBasket?: boolean;
  setOption: (product?: HobsProduct | null) => void;
}

export const AddOnBasket = ({
  product, isFree, isMiniBasket, setOption,
}: AddOnBasketProps) => {
  const {
    options,
  } = useOrderContext()

  const {
    cartInstanceIdentifier,
    channel,
  } = options

  const handleDeleteButton = async () => {
    const client = createCRMApiClient()

    product && marketingEvents.engageWithCart('remove_from_cart', product, options)
    const removeCart: any = await client.cart.removeProductFromCart(cartInstanceIdentifier, product?.productOfferingId!, channel)
    if ('error' in removeCart) {
      return
    }

    setOption(null)
  }

  if (!product) {
    return null
  }

  return (
    <Box borderBottom={BORDERS.gray2} py={2}>
      <Box display="flex" gridGap={8}>
        <Box flexGrow={1}>
          <Typography {...productProps} gutterBottom>{product.display_name}</Typography>
          <Typography variant="body2" className={general.font.weight.book}>{getPackageLabel(getProductTerm(product))}</Typography>
        </Box>
        <Box display="flex">
          <Box flexGrow={1} textAlign="right">
            <Typography gutterBottom={isFree} className={cx(isFree && classes.strikeThrough)} {...productProps}>{getFormattedPrice(product)}</Typography>
            {isFree && <Box>FREE</Box>}
          </Box>
          {isMiniBasket &&
            <Box ml={1} position="relative" top={theme.spacing(-0.75)}>
              <IconButton size="small" color="primary" onClick={handleDeleteButton}><DeleteOutline/></IconButton>
            </Box>}
        </Box>
      </Box>
      {isFree &&
        <Typography variant="body1" style={{ marginTop: theme.spacing(0.5) }}>
          {`Free for ${formattedFreePeriod(product)}, then ${getPriceString(product)}`}
        </Typography>}
    </Box>
  )
}
