import React from 'react'
import {
  Product, Discount,
} from './commonInterfaces'
import { Typography } from '@material-ui/core'
import {
  HobsProduct, ProductOffering, ProductSpec, ProductSpecCharacteristicValue, ProductSpecCharacteristic, ProductSpecPrice, OfferPrice,
} from '../api/Packages'
import {
  toCurrencyString,
} from '.'
import { PackageProps } from '../components/Packages/Package'
import { ProductProps } from '../components/Products/Product'
import { HobsServiceType } from '../api/Products'

export const getDiscountPercent = (product: HobsProduct): { price: string; percent: number; amount: number } | null => {
  const discountProduct = product?.productOffering
    ?.find((e: ProductOffering) => e.productType === 'Discount')?.offerPrice[0]
  const valueAmount = Number(discountProduct?.discountSpecification.discountValue.amount)
  const valueUnits = discountProduct?.discountSpecification?.discountValue?.units
  const standardPrice = Number(getStandardPrice(product))
  const termUnits = discountProduct?.termPeriod?.termPeriod?.units
  const termAmount = discountProduct?.termPeriod?.termPeriod?.amount

  if (termUnits === 'Month' && valueUnits === '%') {
    return {
      price: (standardPrice / 100 * (100 - valueAmount)).toString(),
      percent: Number(valueAmount),
      amount: Number(termAmount),
    }
  }

  return null
}

export const getDiscountPrice = (product: any | null): string => {
  const discountProduct = product?.productOffering
  ?.find((e: ProductOffering) => e.productType === 'Discount')?.offerPrice[0]
  const discountValue = Number(discountProduct?.discountSpecification.discountValue.amount)
  const standardPrice = Number(getStandardPrice(product))
  const discountPercent = getDiscountPercent(product)

  if (discountPercent) {
    return discountPercent.price
  }

  if (discountValue && standardPrice) {
    return (standardPrice - discountValue).toFixed(2)
      .toString()
  }

  return ''
}

export const getHobsPrice = (speedProduct: ProductSpec[]) => {
  const price = speedProduct
    ?.find((spec: ProductSpec) => spec.productSpecPrice?.length > 0)
    ?.productSpecPrice.find((price: ProductSpecPrice) => price.priceType === 'RC')
    ?.price?.amount

  return price
}

export const getStandardPrice = (product: any | null): string => {
  if (product?.service_type === HobsServiceType.BUNDLE) {
    const filterBroadbandProducts = (product: HobsProduct) => {
      return product?.productOffering!.filter((offering: ProductOffering) => {
        if (offering.productCategory === 'BROADBAND' && offering.productType === 'PRODUCT') {
          return true
        }

        return false
      })
    }

    const productOfferings = filterBroadbandProducts(product)
    let bundleCost = 0

    if (productOfferings) {
      for (const productOffering of productOfferings) {
        const productPrice = Number(getHobsPrice(productOffering?.productSpec))
        bundleCost = productPrice ? Number(bundleCost) + productPrice : Number(bundleCost) + 0
      }
    }

    return bundleCost.toFixed(2)
      .toString()
  }

  if (product?.service_type === HobsServiceType.BROADBAND) {
    const packageOffering = product
      ?.productOffering
      ?.find((offering: ProductOffering) => offering.productCategory === 'BROADBAND' && offering.productType === 'PRODUCT' && offering.chargeType === 'PP')
      ?.productSpec

    return getHobsPrice(packageOffering) || '0.00'
  }

  if (
    (product?.service_type === HobsServiceType.TV) ||
    (product?.service_type === HobsServiceType.VOICE) ||
    (product?.service_type === HobsServiceType.WIER) ||
    (product?.service_type === HobsServiceType.VAS)
  ) {
    return getHobsPrice(product?.productSpec) || '0.00'
  }

  return ''
}

export const getOutOfContractPrice = (product: PackageProps | null): string => {
  let outOfContractPrice = product?.productOffering
      ?.find((item: ProductOffering) => item.productType === 'PRODUCT' && item.productCategory === 'BROADBAND')
      ?.offerPrice
      ?.find((item: OfferPrice) => item.termPeriod.termPeriod.amount === '100')
      ?.price.amount
  outOfContractPrice = outOfContractPrice?.replace(/\.00$/, '')
  return outOfContractPrice!
}

export function getProductDiscountPeriod(product: any): number | null {
  const discountProduct = product?.productOffering?.find((e: ProductOffering) => e.productType === 'Discount')?.offerPrice[0]

  if (!product || !discountProduct) {
    return null
  }

  return discountProduct?.termPeriod?.termPeriod?.amount
}

export function getDiscountedPeriod(product: any): string | null {
  const discountedProduct = getDiscountedPackage(product)

  if (!discountedProduct || !discountedProduct.termPeriod) {
    return null
  }

  const amountString = `${discountedProduct.termPeriod.termPeriod.amount}`
  const unitsString = discountedProduct.termPeriod.termPeriod.amount > 1 ?
    `${discountedProduct.termPeriod.termPeriod.units}s` :
    discountedProduct.termPeriod.termPeriod.units

  return `${amountString} ${unitsString}`
}

export function renderDiscountPeriod(product: any): string | null {
  let discountProduct
  if (product) {
    discountProduct = product.productSpec[0].productSpecPrice[0]
  }

  if (!discountProduct || !discountProduct.termPeriod) {
    return null
  }

  const amountString = `${discountProduct.termPeriod.termPeriod.amount}`
  const unitsString = discountProduct.termPeriod.termPeriod.amount > 1 ?
    'months' : 'month'

  return `${amountString} ${unitsString}`
}

export function getMonthlyCost({
  broadband, voice, tv, wier, vas, bundle, discount, promo,
}: {
  broadband?: HobsProduct;
  bundle?: HobsProduct | null;
  voice?: HobsProduct | null;
  tv?: HobsProduct | null;
  wier?: HobsProduct | null;
  vas?: HobsProduct | null;
  discount?: Discount | null;
  promo?: HobsProduct | null;
}): number {
  const broadbandCost = getProductPrice(broadband)
  const voiceCost = getProductPrice(voice)
  const tvCost = getProductPrice(tv)
  const wierCost = getProductPrice(wier)
  const vasCost = getProductPrice(vas)
  const bundleCost = getProductPrice(bundle)
  const totalCost = broadbandCost + bundleCost + voiceCost + tvCost + wierCost + vasCost

  if (discount && promo) {
    const discountPrice = Number(getDiscountPrice(broadband))
    const promoPrice = getPromoCodeDiscountPrice(discountPrice, promo)
    const fullTotalCost = promoPrice + voiceCost + tvCost + wierCost + vasCost
    return fullTotalCost
  }

  if (discount) {
    const discountPrice = Number(getDiscountPrice(broadband ? broadband : bundle))
    return discountPrice + voiceCost + tvCost + wierCost + vasCost
  }

  if (promo) {
    const promoPrice = getPromoCodeDiscountPrice(broadbandCost ? broadbandCost : bundleCost, promo)
    return promoPrice + voiceCost + tvCost + wierCost + vasCost
  }

  return totalCost
}

export function getStandardMonthlyCost({
  broadband, bundle, voice, tv, wier, vas,
}: {
  broadband?: HobsProduct;
  bundle?: HobsProduct | null;
  voice?: HobsProduct | null;
  tv?: HobsProduct | null;
  wier?: HobsProduct | null;
  vas?: HobsProduct | null;
}): string {
  const broadbandCost = broadband ? Number(getStandardPrice(broadband)) : 0
  const bundleCost = bundle ? Number(getStandardPrice(bundle)) : 0
  const voiceCost = voice ? Number(getStandardPrice(voice)) : 0
  const tvCost = tv ? Number(getStandardPrice(tv)) : 0
  const wierCost = wier ? Number(getStandardPrice(wier)) : 0
  const vasCost = vas ? Number(getStandardPrice(vas)) : 0
  const totalCost = broadbandCost + bundleCost + voiceCost + tvCost + wierCost + vasCost

  return `£${toCurrencyString(String(totalCost))}`
}

export function getFormattedMonthlyCost({
  broadband, voice, tv, wier, vas, bundle, discount, promo,
}: {broadband?: HobsProduct; bundle?: HobsProduct | null; voice?: HobsProduct | null; tv?: HobsProduct | null; wier?: HobsProduct | null; vas?: HobsProduct | null; discount?: Discount | null;promo?: HobsProduct | null}): string {
  return `£${toCurrencyString(String(getMonthlyCost({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
    discount,
    promo,
  })))}`
}

export function getDiscountedPackage(product: any | null) {
  if (!product) {
    return null
  }

  const productOffering = product.productOffering as ProductOffering
  const discountedProduct = Object.values(productOffering)?.find((e: any) => e.productType === 'Discount')?.offerPrice[0]

  if (!discountedProduct) {
    return null
  }

  return discountedProduct
}

export function getPackageLength(product: any | null) {
  if (!product) {
    return null
  }

  const packageLength = Number(product.productOfferingTerm[0].agreementTermOrCondition[0].termSLASHConditionNumber)
  return packageLength
}

export function getProductPrice(product?: HobsProduct | null): number {
  if (!product) {
    return 0
  }

  const discountedProduct = getPackageDiscount(product) as any

  if (!discountedProduct) {
    return Number(getBasePrice(product))
  }

  return Number(getDiscountPrice(product))
}

export function getProductFullPrice(product?: Product): number {
  if (!product) {
    return 0
  }

  const discountedProduct = getDiscountedPrice(product)

  if (!discountedProduct) {
    return Number(getStandardPrice(product))
  }

  return Number(getDiscountPrice(product))
}

/**
 * Promo code
 */
export const getPromoCodeDiscountPrice = (currentPrice: number, product: any | null): number => {
  const promoDiscountInfo = product.productSpec[0].productSpecPrice[0].discountSpecification.discountValue
  const discountValue = Number(promoDiscountInfo.amount)
  const termUnits = promoDiscountInfo.units

  if (discountValue) {
    if (termUnits === '%') {
      return (currentPrice / 100 * (100 - discountValue))
    }

    return (currentPrice - discountValue)
  }

  return 0
}

/**
 * Get readable price string for a product
 */
export function getPriceString(product: any): string {
  return `£${toCurrencyString(getBasePrice(product))}/month`
}

export function getStandartPriceString(product: any): string {
  return `£${toCurrencyString(getBaseStandartPrice(product))}/month`
}

export function getFormattedPrice(product: any): string {
  return `£${toCurrencyString(getBasePrice(product))}`
}

export function getBaseStandartPrice(product: any | null): string {
  if (!product) {
    return ''
  }

  return getStandardPrice(product)
}

export function getStandartPriceIfDiscounted(product: any | null): string | null {
  if (!product) {
    return null
  }

  if (getDiscountPrice(product) >= getStandardPrice(product)) {
    return null
  }

  if (getDiscountPrice(product) === '') {
    return null
  }

  return `£${toCurrencyString(getStandardPrice(product))}`
}

// ------------------------------------------------------------------------------------------
// ------  TO DO - The next functions should be investigated
// ------------------------------------------------------------------------------------------

export function getDiscountedPrice(product: any | null) {
  if (!product) {
    return null
  }

  const discountedProduct = getDiscountedPackage(product)

  if (!discountedProduct || !discountedProduct.discountSpecification) {
    return null
  }

  return !discountedProduct.discountSpecification
}

export function getDiscountedValue(product: any): string | null {
  const discountedProduct = getDiscountedPrice(product)

  if (!discountedProduct) {
    return null
  }

  const discountValue = getDiscountPrice(product)
  return `£${discountValue}`
}

export function getPackageLabel(term: number): string {
  switch (term) {
    case 12:
      return '12 month contract'

    case 24:
      return '24 month contract'

    case 18:
      return '18 month contract'
  }

  return ''
}

export const getProductTerm = (product: PackageProps | ProductProps | HobsProduct | undefined): number => {
  if (product?.contract_length) {
    return Number(product.contract_length)
  }

  if (product?.service_type === HobsServiceType.BROADBAND || product?.service_type === HobsServiceType.BUNDLE) {
    const termAmount = Number(product.productOffering
      ?.map((productOffering: ProductOffering) => {
        if (
          productOffering.productUsageGroup.length > 0 &&
          productOffering.serviceType === 'PRODUCT' &&
          productOffering.productOfferingId !== 'ONT' &&
          productOffering.productOfferingId !== 'Ont_Device' &&
          productOffering.productOfferingId !== 'Router'
        ) {
          return productOffering.productUsageGroup[0]
        }

        return null
      }).filter(Boolean)[0]?.productUsageTimePeriod?.termPeriod?.amount)

    return termAmount || 0
  }

  const offering = product?.productSpec as ProductSpec[]
  return offering ? Number(offering
    ?.find((productSpec: ProductSpec) => productSpec.productUsageGroup.length > 0)
    ?.productUsageGroup[0]?.productUsageTimePeriod?.termPeriod?.amount) : 0
}

export const getBBSpeed = (item: PackageProps | undefined) => {
  if (item?.speed?.includes('Mbps')) {
    const speedInMbps = parseFloat(item.speed)
    if (!isNaN(speedInMbps)) {
      return speedInMbps.toString()
    }
  } else if (item?.speed?.includes('Gbps')) {
    const speedInGbps = parseFloat(item.speed)
    if (!isNaN(speedInGbps)) {
      return (speedInGbps * 1024).toString()
    }
  }

  return ''
}

export const getSpeed = (product: PackageProps | ProductProps | undefined, type: string) => {
  const offering = product?.productOffering as ProductOffering[]
  return offering ? offering
    ?.find((product: ProductOffering) => product.serviceType === 'SPEED')
    ?.productSpec?.find((productSpec: ProductSpec) => productSpec.productType === 'SPEED')
    ?.productSpecCharacteristic?.find((productSpecChar: ProductSpecCharacteristic) => productSpecChar.name === type)
    ?.productSpecCharacteristicValue?.find((productSpecValue: ProductSpecCharacteristicValue) => productSpecValue.default)
    ?.value : undefined
}

export const getSpeedNumber = (product: PackageProps | ProductProps | undefined, type: string) => {
  const fullSpeed = getSpeed(product, type)
  return fullSpeed ? Number(fullSpeed.replace('Mbps', '')) : undefined
}

export const getProductsPrice = (product: any): string => {
  if (product?.productType !== 'BUNDLE') {
    const price = product?.productSpec[0]?.productSpecPrice
      .filter((e: ProductSpecPrice) => e.chargeFrequency === 'Monthly')[0]?.price?.amount
    return price ? price : '0.00'
  }

  return product.productOffering
    .filter((e: ProductOffering) => e.productType === 'PRODUCT')[0].productSpec
    .filter((e: ProductSpec) => e.productSubType === 'Billing')[0].productSpecPrice
    .filter((e: ProductSpecPrice) => e.chargeFrequency === 'Monthly')[0].price?.amount
}

export function renderDiscountDetails(product: any, className: any, total = getStandartPriceString(product)
  .split('/')[0]) {
  if (isProductDiscountedFullPeriod(product)) {
    return null
  }

  const type = productDiscountType(product)

  if (type === 'FREE') {
    return (
      <Typography variant="body2" className={className}>{getProductFreePeriod(product)} month{getProductFreePeriod(product) === 1 ? '' : 's'} free</Typography>
    )
  }

  if (type === 'PRICE') {
    return (
      <Typography variant="body2" className={className}>For {getProductDiscountPeriod(product)} month{getProductDiscountPeriod(product) === 1 ? '' : 's'}, then {total} thereafter
      </Typography>
    )
  }

  return null
}

export function isProductDiscountedFullPeriod(product: any) {
  if (product) {
    return false
  }

  return false
}

export function getBasePrice(product: any | null): string {
  if (product) {
    return getDiscountPrice(product) ? getDiscountPrice(product) : getStandardPrice(product)
  }

  return ''
}

export function getBasePriceNum(product: ProductProps | PackageProps | HobsProduct): number {
  if (product) {
    return getDiscountPrice(product) ? Number(getDiscountPrice(product)) : Number(getStandardPrice(product))
  }

  return 0
}

export function getProductFreePeriod(product: any): number | null {
  if (!product) {
    return null
  }

  return null
}

export function getPriceAfterContractString(product: any): string {
  return `£${toCurrencyString(getPriceAfterContract(product))}/month`
}

export function getPriceAfterContract(product: any): string {
  if (product) {
    return ''
  }

  return ''
}

export function getPackageDiscount(product: any): { discountPrice: string; standardPrice?: string } | null {
  const productDiscount = product?.productOffering
    ?.find((e: ProductOffering) => e.productType === 'Discount')?.offerPrice[0]?.discountSpecification

  if (!productDiscount) {
    return null
  }

  return {
    discountPrice: '', // Needs to be revised (we do not have discountAmount property anymore.)
    standardPrice: getStandardPrice(product),
  }
}

export function packageListHasDiscounts(products: Product[], discount: Discount | null) {
  if (!discount) {
    return false
  }

  const discountedProducts: { discountPrice: string; standardPrice?: string }[] = []
  products.forEach(product => {
    const productDiscount = getPackageDiscount(product)
    if (productDiscount) {
      discountedProducts.push(productDiscount)
    }
  })

  return discountedProducts.length > 0
}

export function productDiscountType(product: any): 'FREE' | 'PRICE' | null {
  if (!product) {
    return null
  }

  const productPrice = undefined

  if (!productPrice) {
    return null
  }

  return null
}

export const getInstallationObj = (product: HobsProduct, addon?: boolean) => {
  if (addon) {
    return product?.productOffering?.[0]?.offerPrice
  }

  return product?.productOffering?.find((item: ProductOffering) => item.serviceType === 'Installation')
}

export const getInstallationCostFromObj = (installation: any, addon?: boolean) => {
  if (addon) {
    return installation?.[0]?.price?.amount
  }

  return installation?.productSpec && installation
    ?.productSpec.find((item: ProductSpec) => item.productType === 'Installation')
    ?.productSpecPrice[0]
    ?.price.amount
}

export function getInstallationCost(product: any, addon?: boolean): string {
  const installationObj = getInstallationObj(product, addon)
  const installationPrice = getInstallationCostFromObj(installationObj, addon!)

  if (!installationPrice) {
    return '0'
  }

  return installationPrice
}

export function getTotalInstallationCost({
  broadband, voice, tv, wier, vas, bundle,
}: {broadband?: HobsProduct; bundle?: HobsProduct; voice?: HobsProduct; tv?: HobsProduct; wier?: HobsProduct; vas?: HobsProduct}): number {
  const installationBroadband = getInstallationCost(broadband)
  const installationBundle = bundle ? getInstallationCost(bundle) : 0
  const installationWIER = wier ? getInstallationCost(wier, true) : 0
  const installationVAS = vas ? getInstallationCost(vas, true) : 0
  const installationVoice = voice ? getInstallationCost(voice, true) : 0
  const installationTV = tv ? getInstallationCost(tv, true) : 0

  const totalInstallation =
    Number(installationBroadband) +
    Number(installationBundle) +
    Number(installationWIER) +
    Number(installationVAS) +
    Number(installationVoice) +
    Number(installationTV)

  return totalInstallation
}

export function getLongFormattedCost(cost: number): string {
  return `£${toCurrencyString(String(cost))}`
}

export function formattedFreePeriod(voice?: any): string {
  if (!voice) {
    return ''
  }

  const periodLength = voice.productPrices[0].freePeriodLength
  const periodType = periodLength === 1 ? voice.productPrices[0].freePeriodType.slice(0, -1) : voice.productPrices[0].freePeriodType

  return `${periodLength} ${periodType}`
}

export function getDiscountFreePeriod(product: any | null): number {
  if (!product) {
    return 0
  }

  return 0
}

export function getFreePeriod(product?: any | null): number {
  if (!product) {
    return 0
  }

  return 0
}

export function getProductWithLongestFreePeriod(products: Product[] | null): Product | null {
  if (!products) {
    return null
  }

  const sortedProducts = products.sort((a, b) => b.productPrices[0].freePeriodLength - a.productPrices[0].freePeriodLength)

  return sortedProducts[0]
}

export const getContractType = (product: HobsProduct) => {
  const productTerm = getProductTerm(product)

  switch (productTerm) {
    case 12:
      return 'ANNUAL'
    case 24:
      return 'ANNUAL_24'
    case 36:
      return 'ANNUAL_36'
    default:
      return 'null'
  }
}
