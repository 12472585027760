import React, {
  useEffect, useRef,
} from 'react'

const StudentBeans = () => {
  const studentBeansContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let append: HTMLScriptElement

    if (studentBeansContainerRef?.current) {
      const script = document.createElement('script')
      script.src = 'https://cdn.studentbeans.com/third-party/all.js'
      script.dataset.iframe = 'https://connect.studentbeans.com/v4/community-fibre/uk'
      script.dataset.load = 'connect'
      script.id = 'stb_root'
      script.async = true

      append = studentBeansContainerRef?.current?.appendChild(script)
    }

    return () => append.remove()
  }, [])

  return (<div ref={studentBeansContainerRef}/>)
}

export default StudentBeans
