import { useRouter } from 'next/router'
import React from 'react'
import getStepUrl, {
  BUNDLES_HASH, BUNDLES_PATH,
} from '../NewOrderFlow/getStepUrl'
import classes from './BroadbandTabs.styles'
import { TabComponent } from './TabComponent'

export interface BroadbandTabsProps {
  broadband_tab?: string;
  broadband_icon?: string;
  bundles_tab?: string;
  bundles_icon?: string;
}

const BroadbandTabs = ({
  broadband_tab, broadband_icon, bundles_tab, bundles_icon,
}: BroadbandTabsProps) => {
  const router = useRouter()
  const [
    isBundles,
    setIsBundles,
  ] = React.useState(router.asPath.includes(BUNDLES_PATH) || router.asPath.includes(BUNDLES_HASH))

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url.includes(BUNDLES_HASH) || url.includes(BUNDLES_PATH)) {
        setIsBundles(true)
      } else {
        setIsBundles(false)
      }
    }

    router.events.on('hashChangeStart', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('hashChangeStart', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteChange)
    }
  })

  const isHomePage = router.pathname === '/'

  const { sprn } = router.query
  const sprnNumber = isNaN(Number(sprn)) ? undefined : Number(sprn)

  const baseUrl = () => {
    const url = isHomePage ? '/' : `${getStepUrl('broadband', sprnNumber)}`
    return router.push(url, undefined, { shallow: true })
  }

  const bundlesUrl = () => {
    if (!isBundles) {
      const url = isHomePage ? `${router.pathname}${BUNDLES_HASH}` : `${getStepUrl('broadband-bundles', sprnNumber)}`
      return router.push(url, undefined, { shallow: true })
    }

    return null
  }

  return (
    <div className={classes.container}>
      <TabComponent isActive={isBundles} iconSrc={bundles_icon} tabText={bundles_tab} handleClick={bundlesUrl}/>
      <TabComponent isActive={!isBundles} iconSrc={broadband_icon} tabText={broadband_tab} handleClick={baseUrl}/>
    </div>
  )
}

export default BroadbandTabs
