import React from 'react'
import {
  Typography, Link, Box,
} from '@material-ui/core'
import Wysiwyg from '../../Wysiwyg/Wysiwyg'
import classes from './MediaPageBanner.styles'
import StyledButton from '../../StyledButton/StyledButton'
import { cx } from 'linaria'
import { CtaProps } from '../../../utils/commonProps'
import MediaImage, { MediaImageProps } from '../../Media/MediaImage'
import {
  BOX, GENERAL, ROUNDED,
} from '../../../styles/theme'
import Wrapper from '../../Wrapper/Wrapper'

export interface MediaPageBannerProps {
  readonly meta: {
    id: number;
  };
  title: string;
  body: string;
  image_src?: string;
  image?: MediaImageProps;
  button?: CtaProps;
  text_link_link?: string;
  text_link_text?: string;
  has_white_background?: boolean;
  has_top?: boolean;
  hide_me?: boolean;
}

export default function MediaPageBanner({
  meta, title, body, button, image_src, text_link_text, text_link_link, has_white_background, image, has_top, hide_me,
}: MediaPageBannerProps) {
  if (hide_me) {
    return null
  }

  return (
    <Wrapper id={`MediaPageBanner_${meta.id}`} verticalPaddings={has_top} center>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column-reverse',
          md: 'row',
        }}
        maxWidth={{
          xs: GENERAL.addOnWidthMobile,
          md: '100%',
        }}
        {...BOX}
      >
        <Box
          flexBasis="50%"
          boxSizing="border-box"
          py={{
            xs: 4,
            md: 8,
          }}
          px={{
            xs: 4,
            md: 6,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="h4" color="primary" className={classes.bannerTitle}>{title}</Typography>
          <Wysiwyg body={body} variant="body1"/>
          {button?.text &&
            <Box mt={1}>
              <StyledButton
                isModal={button.modal}
                url={button.url}
                order_flow_step={button.order_flow_step}
                color="secondary"
                variant="outlined"
              >
                {button.text}
              </StyledButton>
            </Box>}
          {text_link_text &&
            <Box mt={1}>
              <Link
                key={text_link_text}
                href={text_link_link}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography className={cx(classes.text, !has_white_background && classes.textPurple)} variant="body1">{text_link_text}</Typography>
              </Link>
            </Box>}
        </Box>
        <Box
          borderRadius={`0 ${ROUNDED.medium}px ${ROUNDED.medium}px 0`}
          flexBasis="50%"
          overflow="hidden"
          style={{ backgroundImage: `url(${image_src})` }}
        >
          {image?.src &&
          <MediaImage
            src={image.src}
            width={image.width || 651}
            height={image.height || 350}
            alt={image.alt}
            mobile_src={image.mobile_src}
            mobile_width={image.mobile_width}
            mobile_height={image.mobile_height}
            background
          />}
        </Box>
      </Box>
    </Wrapper>
  )
}

