import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Link,
  Collapse,
  List,
  ListItem,
} from '@material-ui/core'
import footerClasses from './Footer.styles'
import { FooterProps } from './Footer'
import BackToTopButton from '../BackToTopButton/BackToTopButton'
import { Add } from '@material-ui/icons'
import { cx } from 'linaria'
import MediaImage from '../Media/MediaImage'

const classes = footerClasses.mobile

export default function MobileFooter({
  company, help, social, address, logo, logo_alt_text, show_back_to_top, company_column_title, help_column_title, social_column_title,
}: FooterProps) {
  const [
    isCompanyMenuOpen,
    toggleCompanyMenu,
  ] = useState(false)

  const [
    isHelpMenuOpen,
    toggleHelpMenu,
  ] = useState(false)

  const companyClass = cx(
    classes.menuItem,
    isCompanyMenuOpen ? classes.openMenuItem : '',
  )

  const helpClass = cx(
    classes.menuItem,
    isHelpMenuOpen ? classes.openMenuItem : '',
  )

  return (
    <section className={classes.container}>
      <List component="nav">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          {show_back_to_top && (
            <Grid
              item
              container
              justify="flex-end"
            >
              <BackToTopButton/>
            </Grid>
          )}
          {/** Company */}
          <Grid
            item
            container
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              container
              className={companyClass}
            >
              <ListItem button onClick={() => toggleCompanyMenu(!isCompanyMenuOpen)}>
                <Typography variant="body1" color="primary">{company_column_title || 'Company'}</Typography>
                <Add color="primary"/>
              </ListItem>
            </Grid>
            <Collapse in={isCompanyMenuOpen} unmountOnExit>
              {company.map((link, index) => (
                <Grid item key={index}>
                  <Typography variant="body2">
                    <Link href={link.url}>{link.text}</Link>
                  </Typography>
                </Grid>
              ))}
            </Collapse>
          </Grid>
          {/** Help & Support */}
          <Grid
            item
            container
            direction="row"
            justify="space-between"
          >
            <Grid
              item
              container
              className={helpClass}
            >
              <ListItem button onClick={() => toggleHelpMenu(!isHelpMenuOpen)}>
                <Typography variant="body1" color="primary">{help_column_title || 'Help & Support'}</Typography>
                <Add color="primary"/>
              </ListItem>
            </Grid>
            <Collapse in={isHelpMenuOpen} unmountOnExit>
              {help.map((link, index) => (
                <Grid item key={index}>
                  <Typography variant="body2">
                    <Link href={link.url}>{link.text}</Link>
                  </Typography>
                </Grid>
              ))}
            </Collapse>
          </Grid>
          {/** Social */}
          <Grid
            item
            container
            className={classes.social}
          >
            <Grid item container>
              <Typography variant="body1" color="primary">{social_column_title || 'Connect'}</Typography>
            </Grid>
            {social.map((link, index) => (
              <Grid item key={index}>
                <a href={link.url}>
                  <MediaImage
                    src={link.src}
                    width={30}
                    height={30}
                    alt={link.image_alt_text}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/** Address */}
        <Grid
          item
          container
          direction="row"
        >
          <Grid
            item classes={{
              root: classes.logo,
            }}
            xs={12}
          >
            <MediaImage
              src={logo}
              width={288}
              height={76}
              alt={logo_alt_text}
            />
          </Grid>
          <Grid item xs={12} component="div" classes={{ root: classes.address }}>
            <Typography variant="caption" component="div" color="primary">{address.line1}</Typography>
            <Typography variant="caption" component="div" color="primary">{address.line2}</Typography>
            <Typography gutterBottom variant="caption" component="div">
              {address.phone && <Link href={`tel:${address.phone}`}>{address.phone}</Link>} {address.phone && address.email && <> | </>} {address.email && <Link href={`mailto:${address.email}`}>{address.email}</Link>}
            </Typography>
            <Typography variant="caption" color="primary">© Copyright Community Fibre Ltd. All Rights Reserved</Typography>
          </Grid>
        </Grid>
      </List>
    </section>
  )
}
