import { css } from 'linaria'
import theme from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'

export const GRID_WIDTH = 12

const classes = {
  cardLink: css`
    &:focus:not(.focus-visible) {
      outline: none;
    }
  `,
  selectedCardOuter: css`
    transform: scale(1.04);
    margin: 0 ${theme.spacing(1)}px;
    ${theme.breakpoints.up('md')} {
      transform: scale(1.08);
      margin: 0;
    }
  `,
  cardOuter: css`
    position: relative;
    transition: top .2s ease-in-out;
    top: 0;
    color: ${theme.palette.primary.main};
    margin-top: ${theme.spacing(6)}px;
    display: flex;
    padding: ${theme.spacing(0, 1, 0.5)};
    ${theme.breakpoints.up('md')} {
      height: 92%;
      padding: 0;
      margin-top: ${theme.spacing(7)}px;
    }
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 3.5, 2)};
      margin: 0;
    }
    .MuiLink-underlineHover {
      text-decoration: underline;
      cursor: pointer;
      font-weight: ${theme.typography.fontWeightMedium!} !important;
    }
  `,
  flipcard: {
    selectedCard: css`
      border: 3px solid ${theme.palette.primary.main};
      border-radius: ${commonStyling.roundedBorders.all};
      box-shadow: ${commonStyling.boxShadowLight};
    `,
    cardFlipped: css`
      transform: rotateY(180deg) !important;
      transition: transform 1s;
      transform-style: preserve-3d;
    `,
    inner: css`
      min-width: 100%;
      position: relative;
      text-align: center;
      transform: rotateY(0);
      transition: transform 1s;
      transform-style: preserve-3d;
      display: flex;
      justify-content: center;
    `,
    voiceFlippedCard: css`
      min-width: 98%;
    `,
  },
}

export default classes
