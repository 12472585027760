import { useContext } from 'react'
import {
  PremiseDetail, TechnologyType,
} from '../../api/Addresses'
import { OrderStep } from '../../pages/order/[...step]'
import { newOrderFlowStore } from '../../sessionStorage'
import productsHaveSameContract from '../../utils/productsHaveSameContract'
import { handleRedirect } from '../../utils/redirect'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import getStepUrl from './getStepUrl'
import {
  INITIAL_ORDER_OPTIONS,
  OrderOptions,
  THREE_GIGA_IDS,
} from './OrderContext'

export default function useValidateStep() {
  const { premise } = useContext(PremiseContext)

  async function validateStep(step: OrderStep) {
    const options = (await newOrderFlowStore.get()) ?? undefined
    if (JSON.stringify(options) === JSON.stringify(INITIAL_ORDER_OPTIONS) || JSON.stringify({
      ...options,
      reachedInstallationStep: false,
    }) === JSON.stringify(INITIAL_ORDER_OPTIONS)) {
      return
    }

    switch (step) {
      case 'broadband':
      case 'order-management':
      case 'order-management-new-number-confirmation':
        break
      case 'order-management-porting-confirmation':
        await validatePortedNumberConfirmation(options)
        break
      case 'checkout-details':
        await validateBroadband(options)
        await validatePackagesContractLength(options)
        await validate3Giga(options, premise)
        break
      case 'checkout-one-touch-switching':
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        break
      case 'checkout-installation':
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        break
      case 'checkout-payment':
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)

        break
      case 'checkout-order-summary':
      case 'checkout-order-processing':
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)
        await validatePayment(options, premise)

        break
      case 'checkout-order-confirmation':
        await validateBroadband(options)
        await validateEmail(options)
        await validateDetails(options)
        await validateInstallation(options)
        await validatePayment(options, premise)
        await validateOrderConfirmation(options)

        break
      default:
        await validateBroadband(options)

        break
    }
  }

  return {
    validateStep,
  }
}

async function validateOrderConfirmation(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (!options.accountId) {
    await handleRedirect({ to: getStepUrl('broadband') })
  }
}

async function validatePackagesContractLength(options?: OrderOptions) {
  if (options) {
    const {
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    } = options

    const haveSameContractLength = productsHaveSameContract({
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    })

    if (!haveSameContractLength) {
      await handleRedirect({ to: getStepUrl('broadband') })
    }
  }
}

async function validatePayment(options?: OrderOptions, premise?: PremiseDetail | null) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (premise?.type === 'Community') {
    return
  }

  if (!options.paymentDetails) {
    await handleRedirect({ to: getStepUrl('checkout-payment') })
  }
}

async function validateInstallation(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (options.timeslotError) {
    await handleRedirect({ to: getStepUrl('checkout-installation') })
  }
}

async function validateBroadband(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (!options.broadband && !options.bundle) {
    await handleRedirect({ to: getStepUrl('broadband') })
  }
}

async function validateDetails(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (!options.data.email) {
    await handleRedirect({ to: getStepUrl('checkout-details') })
    return
  }

  if (!options.data.firstName || !options.data.lastName || !options.data.phone) {
    await handleRedirect({ to: getStepUrl('checkout-details') })
  }
}

async function validateEmail(options?: OrderOptions) {
  if (!options) {
    await handleRedirect({ to: getStepUrl('broadband') })
    return
  }

  if (!options.data.email) {
    await handleRedirect({ to: getStepUrl('checkout-details') })
  }
}

async function validate3Giga(options?: OrderOptions, premise?: PremiseDetail | null) {
  if (options?.broadband && THREE_GIGA_IDS.includes(options.broadband._id) && premise && premise?.technology !== TechnologyType.XGSPON) {
    await handleRedirect({ to: getStepUrl('broadband') })
  }
}

async function validatePortedNumberConfirmation(options?: OrderOptions) {
  if (!options?.hasPortedNumber) {
    await handleRedirect({ to: getStepUrl('order-management') })
  }
}
