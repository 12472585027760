import React,
{
  useState, useEffect, useContext,
} from 'react'
import {
  Grid,
  Hidden,
  Slide,
  Typography,
} from '@material-ui/core'
import StyledButton from '../StyledButton/StyledButton'
import classes from './StickyCheckAvailability.styles'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { useGeneralContext } from '../GeneralContext/GeneralContext'
import { CtaProps } from '../../utils/commonProps'
import SearchBar, { SearchBarProps } from '../SearchBar/SearchBar'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { cx } from 'linaria'
import { fontWeights } from '../../styles/theme'

export interface StickyCheckAvailabilityProps {
  text?: string;
  bold_text?: boolean;
  show_searchbar: boolean;
  search_bar?: SearchBarProps;
  button?: CtaProps;
  secondary_button?: CtaProps;
}

const StickyCheckAvailability = ({
  text,
  bold_text,
  show_searchbar,
  search_bar,
  button,
  secondary_button,
}: StickyCheckAvailabilityProps) => {
  const [
    showBanner,
    setShowBanner,
  ] = useState(true)

  const {
    options: { broadband },
  } = useOrderContext()

  const { premise } = useContext(PremiseContext)

  const {
    generalOptions,
  } = useGeneralContext()

  useEffect(() => {
    if (premise && broadband) {
      setShowBanner(false)
      return
    }

    setShowBanner(generalOptions.showStickyCheckAvailability)
  }, [
    premise,
    broadband,
    generalOptions,
  ])

  const secondaryButtonClasses = cx(classes.secondaryButton, button && Boolean(Object.keys(button).length) ? 'hasPrimary' : '')
  return (
    <Slide direction="up" in={showBanner} mountOnEnter unmountOnExit>
      <div className={classes.stickyContainer}>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justify="space-between" className={classes.contentContainer}
        >
          <Hidden smDown>
            <Typography
              variant="body1" style={{
                fontWeight: bold_text ? fontWeights.semibold : fontWeights.book,
              }}
            >{text}
            </Typography>
          </Hidden>
          {search_bar && show_searchbar &&
          <SearchBar
            {...search_bar}
            className={classes.searchInSticky}
          />}
          {
            button && !show_searchbar && Boolean(Object.keys(button).length) && (
              <StyledButton url={button.url} isModal={button.modal} className={button.animated_icon ? 'animatedIconButton ' : ''} color="primary" stopPropagation>
                {button.text}
                <Hidden xsDown>
                  {button.icon && <img src={button.icon} alt=""/>}
                </Hidden>
              </StyledButton>
            )
          }
          {
            secondary_button && !show_searchbar && Boolean(Object.keys(secondary_button).length) && (
              <StyledButton
                url={secondary_button.url} isModal={secondary_button.modal} className={secondary_button.animated_icon ? 'animatedIconButton' : secondaryButtonClasses}
                color="secondary" stopPropagation
              >
                {secondary_button.text}
                <Hidden xsDown>
                  {secondary_button.icon && <img src={secondary_button.icon} alt=""/>}
                </Hidden>
              </StyledButton>
            )
          }
        </Grid>
      </div>
    </Slide>
  )
}

export default StickyCheckAvailability
