import React from 'react'
import {
  Box, Typography,
} from '@material-ui/core'
import theme from '../../styles/theme'

interface ComparisonFooterProps {
  last_updated: string;
  legal: {
    text: string;
    logo: string;
  };
}

const ComparisonFooter = ({
  last_updated,
  legal,
}: ComparisonFooterProps) => (
  <Box display="flex" alignItems="center" flexDirection="column" gridGap={theme.spacing(1.5)} mt={3} px={2}>
    <Typography variant="caption" color="textPrimary">
      Last updated on {last_updated}. {legal.text}
    </Typography>
    {legal.logo && <img src={legal.logo} alt={legal.text} height="25"/>}
  </Box>
)

export default ComparisonFooter
