export const fillArray = (value: number, len: number) => {
  if (len === 0) {
    return []
  }

  let a = [value]
  while (a.length * 2 <= len) {
    a = a.concat(a)
  }

  if (a.length < len) {
    a = a.concat(a.slice(0, len - a.length))
  }

  return a
}
