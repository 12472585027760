import React, {
  useEffect, useState,
} from 'react'
import {
  PostListResponse,
} from '../../../utils/blog'
import butter from '../../../butter'

import OverviewItems from '../OverviewItems/OverviewItems'
import { useRouter } from 'next/router'
import { toTitleCase } from '../../../utils'

export interface PressReleaseListProps {
  readonly press_release_data: PostListResponse;
  readonly link_text: string;
}

const PAGE_SIZE = 3
type CategoryFormatType = 1 | 2 ;

export default function PressReleaseList({
  press_release_data,
  link_text,
}: PressReleaseListProps) {
  // Get category from URL or default to 'press-release'
  const router = useRouter()
  const currentPage = Number(router.query.page) || 1

  const [
    filteredArticles,
    setFilteredArticles,
  ] = useState<PostListResponse>(press_release_data)

  const getArticlesByCategory = async (category: string, page = 1) => {
    const formattedCategory = formatCategory(category, 1)

    const response = await butter.post.list({
      page,
      page_size: PAGE_SIZE,
      exclude_body: true,
      category_slug: formattedCategory,
    })
    return response.data
  }

  const formatCategory = (input: string, type: CategoryFormatType = 1): string => {
    if (!input) {
      return 'press-release'
    }

    switch (type) {
      case 1:
        // Replace multiple whitespaces with a single hyphen, convert to lowercase
        return input.replace(/\s+/g, '-')
          .toLowerCase()

      case 2:
        // Convert hyphen-separated string to title case
        return toTitleCase(input.replace(/-/g, ' '))

      default:
        return 'press-release'
    }
  }

  // Handle category and page changes
  useEffect(() => {
    const fetchArticles = async () => {
      if (currentPage === 1) {
        setFilteredArticles(press_release_data)
      } else {
        const articles = await getArticlesByCategory('press-release', currentPage)
        setFilteredArticles(articles as PostListResponse)
      }
    }

    fetchArticles()
  }, [
    currentPage,
    press_release_data,
  ])
  return (
    <OverviewItems
      item_data={filteredArticles}
      link_text={link_text}
      type_items="press-release"
      page_size_items={PAGE_SIZE}
      currentPage={currentPage}
    />
  )
}

PressReleaseList.getInitialProps = async function () {
  const initialPosts = await butter.post.list({
    page: 1,
    page_size: PAGE_SIZE,
    exclude_body: true,
    category_slug: 'press-release',
  })

  return {
    press_release_data: initialPosts.data,
  }
}
