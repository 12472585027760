import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function NortonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill={COLORS.primary} d="M12.3876 22.8722C12.0181 22.8722 11.6485 22.7847 11.3147 22.6059C9.24449 21.5172 6.30004 19.4747 4.02713 15.9263C1.69859 12.2944 1.09062 8.67441 0.98731 6.27435C0.947574 5.3167 1.5158 4.41867 2.39794 4.04515L11.5015 0.178813C12.0658 -0.0596043 12.7095 -0.0596043 13.2737 0.178813L22.3773 4.04515C23.2595 4.42264 23.8277 5.3167 23.7879 6.27435C23.6886 8.67441 23.0806 12.2944 20.7521 15.9263C18.4792 19.4747 15.5347 21.5172 13.4645 22.6059C13.1267 22.7847 12.7611 22.8722 12.3916 22.8722H12.3876ZM12.3956 2.25304L3.28009 6.11938C3.25227 6.1313 3.24035 6.15514 3.24035 6.18295C3.32777 8.30487 3.86818 11.5036 5.92255 14.7143C7.9332 17.8495 10.5359 19.6535 12.3638 20.6151C12.3638 20.6151 12.3956 20.6231 12.4115 20.6151C14.2433 19.6535 16.846 17.8455 18.8527 14.7143C20.911 11.5036 21.4475 8.30487 21.5349 6.18295C21.5349 6.15514 21.519 6.1313 21.4952 6.11938L12.3956 2.25304Z"/>
      <path fill={COLORS.secondary} d="M11.3702 16.9396C11.108 16.9396 10.8536 16.8482 10.647 16.6773L7.05089 13.6733C6.57406 13.2759 6.51048 12.5646 6.90784 12.0878C7.3052 11.6109 8.01648 11.5474 8.49331 11.9447L11.1 14.1223L16.0472 6.22669C16.377 5.6982 17.0724 5.53926 17.6009 5.86907C18.1293 6.19888 18.2883 6.89426 17.9585 7.42275L12.3239 16.4151C12.149 16.6892 11.8669 16.88 11.5451 16.9277C11.4854 16.9356 11.4258 16.9435 11.3702 16.9435V16.9396Z"/>
    </SvgIcon>
  )
}
