import { OrderStep } from '../../pages/order/[...step]'

export const BUNDLES_HASH = '#bundles'
export const BUNDLES_PATH = '/bundles'
export const ADDONS_PATH = '/addons'

const ORDER_FLOW_BASE = '/order'
export default function getStepUrl(step: OrderStep, sprn?: number): string {
  const pathname: string = (() => {
    switch (step) {
      case 'broadband':
      case 'broadband-bundles':
        return `${ORDER_FLOW_BASE}/package${BUNDLES_PATH}`
      case 'addons':
        return `${ORDER_FLOW_BASE}/package${ADDONS_PATH}`
      case 'checkout':
      case 'checkout-details':
        return `${ORDER_FLOW_BASE}/checkout/details`
      case 'checkout-installation':
        return `${ORDER_FLOW_BASE}/checkout/installation`
      case 'checkout-one-touch-switching':
        return `${ORDER_FLOW_BASE}/checkout/switching`
      case 'checkout-payment':
        return `${ORDER_FLOW_BASE}/checkout/payment`
      case 'checkout-order-summary':
        return `${ORDER_FLOW_BASE}/checkout/summary`
      case 'checkout-order-processing':
        return `${ORDER_FLOW_BASE}/checkout/order-processing`
      case 'checkout-order-confirmation':
        return `${ORDER_FLOW_BASE}/checkout/confirmation`
      case 'order-management':
        return `${ORDER_FLOW_BASE}/management`
      case 'order-management-porting-confirmation':
        return `${ORDER_FLOW_BASE}/management/keep-number-confirmation`
      case 'order-management-new-number-confirmation':
        return `${ORDER_FLOW_BASE}/management/new-number-confirmation`
    }
  })()

  return sprn ? `${pathname}?sprn=${sprn}` : pathname
}
