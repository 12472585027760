import { css } from 'linaria'
import theme, { COLORS } from '../../../styles/theme'
import fontSizes from '../../../styles/fontSizes'

const classes = {
  bannerContainerWhite: css`
    background-color: white;
    color: ${theme.palette.primary.main};
    ${theme.breakpoints.up('md')} {
      ::after {
        background-color: white;
      }
    }

    ${theme.breakpoints.down('xs')} {
      ::before{
        background-color: white;
      }
    }
  `,
  bannerContainerPurple: css`
    background-color: ${theme.palette.primary.main};
    color: white;
    ${theme.breakpoints.up('md')} {
      ::after {
        background-color: ${theme.palette.primary.main};
      }
    }

    ${theme.breakpoints.down('xs')} {
      ::before{
        background-color: ${theme.palette.primary.main};
      }
    }
  `,
  bannerContainer: css`
    height: inherit;
    position: relative;
    z-index: 1;
  `,
  bannerTitle: css`
    ${theme.breakpoints.up('md')}{
      &.MuiTypography-h3 {
        font-size: ${fontSizes.h2};
      }
    }
  `,
  infoContainer: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(1, 4, 4)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top 70%;
    p {
      margin: 0;
    }
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(7)}px ${theme.spacing(6)}px;
    }
  `,
  bannerBodyPurple: css`
   &.MuiTypography-body1 {
      color: white !important;
      ${theme.breakpoints.up('md')} {
        font-weight: 300;
      }
   }
  `,
  bannerBody: css`
    padding-top: ${theme.spacing(2)}px;
    font-weight: ${theme.typography.fontWeightLight!};
    color: ${COLORS.bodyTextPrimary} !important;
    &.MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
      font-size: ${fontSizes.body1};
    }
  `,
  buttonContainer: css`
    align-items: center;
    width: 100% !important;
    ${theme.breakpoints.up('md')} {
      .MuiGrid-grid-md-6 {
        margin-right: ${theme.spacing(3)}px;
      }
    }
    .MuiButton-root {
      margin-top: ${theme.spacing(3)}px;
      width: 100%;
    }
  `,
  imageContainerPurple: css`
    &.MuiGrid-item{
      background-color: ${theme.palette.primary.main};
    }
  `,
  imageContainer: css`
    min-height: 350px;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    ${theme.breakpoints.down('xs')} {
      min-height: ${theme.spacing(23)}px;
    }
   `,
  imageCurveWrapper: css`
    position: relative;
  `,
  textPurple: css`
    &.MuiTypography-body1 {
      color: white
    }
  `,
  text: css`
    &.MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
      text-decoration: underline;
      width: 100%;
      text-align: center;
      margin-top: ${theme.spacing(2)}px;
      ${theme.breakpoints.up('md')} {
        text-align: left;
        margin-top: ${theme.spacing(3)}px;
      }
    }
  `,
  desktopCurve: css`
    height: auto !important;
    width: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
  `,
  mobileCurve: css`
    width: 100% !important;
    height: auto !important;
    bottom: -1px;
    left: 0;
    position: absolute;
  `,
}

export default classes
