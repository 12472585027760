import React from 'react'
import {
  Typography,
  Box,
  Button,
} from '@material-ui/core'
import classNames from 'classnames'
import {
  Day as DayType, Meridiem, calendarHeight,
} from './Calendar'
import CalendarDay from './CalendarDay'
import theme from '../../styles/theme'
import classes from './Calendar.styles'

interface DesktopCalendarProps {
    readonly selectedTimeIndex?: number;
    readonly selectedMeridiem?: Meridiem;
    readonly selectedDayIndex: number;
    readonly selectedWeek?: number;
    readonly currentWeek?: number;
    readonly days: DayType[];
    changeTimeslot({
      dayIndex, meridiem, timeIndex, onMobile,
    }: {
      dayIndex: number;
      meridiem?: Meridiem;
      timeIndex?: number;
      onMobile?: boolean;
  }): Promise<void>;
}

const DesktopCalendar = ({
  days,
  selectedTimeIndex,
  selectedDayIndex,
  selectedMeridiem,
  changeTimeslot,
  selectedWeek,
  currentWeek,
}: DesktopCalendarProps) => {
  const currentDate = new Date()
  const currentDay = currentDate.getDate()
    .toString()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const currentMonth = months[currentDate.getMonth()]

  return (
    <Box display="flex" gridGap={8} width="100%" minHeight={theme.spacing(calendarHeight)}>
      {days.map((day, i) => {
        const highlightedDay = day.date === currentDay && day.month === currentMonth
        return (
          <Box key={day.date} flex="1 1 0">
            <Button
              key={day.date}
              className={classNames(classes.weekDay, {
                selected: day.hasSelectedTimeslot,
                beforeSelected: i === selectedDayIndex - 1,
                last: i === days.length - 1,
                currentDate: highlightedDay,
              })}
            >
              <Typography variant="subtitle2" align="center">{day.dayOfWeek}</Typography>
              <Typography variant="body2" align="center">{day.date}</Typography>
            </Button>
            <CalendarDay
              selectedTimeIndex={selectedTimeIndex}
              selectedDayIndex={selectedDayIndex}
              selectedMeridiem={selectedMeridiem}
              selectedWeek={selectedWeek}
              currentWeek={currentWeek}
              currentDayIndex={i}
              changeTimeslot={changeTimeslot}
              day={day}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default DesktopCalendar
