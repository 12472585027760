import { AxiosInstance } from 'axios'
import logger from '../utils/logger'

export interface SearchPremise {
  readonly address: string;
  readonly sprn: number;
  readonly door_number: number | null;
}

export enum AcceptingType {
  order = 'order',
  none = 'none',
  register_interest = 'register_interest',
  register_interest_early = 'register_interest_early',
}

export enum PremiseType {
  Community = 'Community',
  Residential = 'Residential',
  Business = ' Business',
  Prime = 'Prime',
}

export enum TechnologyType {
  GPON = 'GPON',
  XGSPON = 'XGS-PON',
  COMBO = 'COMBO',
}

export interface PremiseDetail extends SearchPremise {
  is_in_london: boolean;
  status: number | null;
  bd_status: number | null;
  sales_status: number | null;
  pop: string | null;
  postcode: string | null;
  accepting: AcceptingType;
  type: PremiseType;
  technology: TechnologyType | null;
  borough?: string | null;
  install_type: number | null;
  installType: number | null;
  class_type?: string;
  declared_live_date?: string | null;
  device_name?: string;
  oltManufacturer?: string;
  contractor?: string;
  uprn?: string;
  build_project_name?: string;
  oltIP?: string;
  closestliveaddress_metrestocab?: number | null;
  coverage_indicator: string;
  box?: BoxPremiseDetails;
}

export interface BoxPremiseDetails{
  class_name: string;
  project_name: string;
  prp_number?: number | null;
  dcms_grant_flag: string;
  dcms_eligibility?: string | null;
  grant_name: string | null;
  property_type: string;
  status?: number | string | null;
  sales_status?: number | string | null;
  install_type?: number | null;
}

export default function Addresses(
  client: {
    addressSearchUrl: AxiosInstance;
    premiseCoverageUrl: AxiosInstance;
    phoenixAddressSearch: AxiosInstance;
    businessAdrressSearch: AxiosInstance;
  },
  sessionId: string,
) {
  async function search(term: string) {
    try {
      const {
        data: { premisesFiltered },
      } = await client.addressSearchUrl.post<{
        premisesFiltered: SearchPremise[];
      }>('/search', {
        filters: {
          search: term,
        },
        sessionID: sessionId,
      })

      return premisesFiltered
    } catch (err) {
      logger.error('post code lookup failed', err)
      return []
    }
  }

  async function get(sprn: number) {
    try {
      const { data } = await client.phoenixAddressSearch.post<PremiseDetail>(`/address-service/${sprn}`, {
        sessionID: sessionId,
      }, {
        headers: {
          'session-id': sessionId,
        },
      })

      return data
    } catch (err) {
      logger.error('post code lookup failed', err)
      return { error: err }
    }
  }

  async function getPremiseCoverage(sprn: number) {
    try {
      const { data } = await client.premiseCoverageUrl.get<PremiseDetail>(`${sprn}`)

      return data
    } catch (err) {
      logger.error('post code lookup failed', err)
      return { error: err }
    }
  }

  async function getBusiness(sprn: number) {
    try {
      const { data } = await client.businessAdrressSearch.get<PremiseDetail>(`?sprn=${sprn}`)

      return data
    } catch (err) {
      logger.error('post code lookup failed', err)
      return { error: err }
    }
  }

  async function getBusinessByPostcode(postcode: string) {
    try {
      const { data } = await client.businessAdrressSearch.get<PremiseDetail>(`?postcode=${postcode}`)

      return data
    } catch (err) {
      logger.error('post code lookup failed', err)
      return { error: err }
    }
  }

  return {
    search,
    get,
    getPremiseCoverage,
    getBusiness,
    getBusinessByPostcode,
  }
}
