import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import { getProducts } from '../../api/Products'
import {
  HobsProduct, ProductOffering,
} from '../../api/Packages'
import Product, { ProductProps } from './Product'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { useAuth } from '../../hooks/auth'
import { getProductTerm } from '../../utils/getProductDetails'

import ErrorMessage, { ErrorMessageProps } from '../ErrorMessage/ErrorMessage'
import {
  HobsAddonsInBundleType, checkIfAddonExists,
} from '../Bundles/Bundle'
import { ButtonPropsExtended } from '../Packages/Package'
import Heading, { HeadingProps } from '../Heading/Heading'
import { PremiseContext } from '../PremiseTracker/PremiseContext'

export interface ProductsProps {
  anchor?: string;
  products?: ProductProps[];
  contract_term_check: boolean;
  on_click_package?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string) => void;
  error_message: ErrorMessageProps;
  product_cta: ButtonPropsExtended;
  product_cta_secondary?: ButtonPropsExtended;
  title: HeadingProps;
}

export const checkIfContinueDisabled = (
  broadband?: HobsProduct,
  bundle?: HobsProduct,
  voice?: HobsProduct,
  altPhone?: boolean,
): boolean => {
  let voiceInBundle: boolean

  if (voice && !altPhone) {
    return true
  }

  if (broadband) {
    return false
  }

  if (bundle) {
    const addons = bundle?.productOffering as ProductOffering[]
    voiceInBundle = Boolean(checkIfAddonExists(addons, HobsAddonsInBundleType.VOICE))

    if (voiceInBundle && !altPhone) {
      return true
    }

    return false
  }

  return true
}

export default function Products({
  products,
  contract_term_check,
  on_click_package,
  error_message,
  product_cta,
  product_cta_secondary,
  title,
}: ProductsProps) {
  const {
    options,
  } = useOrderContext()
  const { isAuthenticated } = useAuth()

  const {
    showDifferentContractError,
  } = options

  const [
    productsFiltered,
    setProductsFiltered,
  ] = useState<ProductProps[]>()

  const [
    showErrorMessage,
    setShowErrorMessage,
  ] = useState<boolean>(false)

  const [
    disabledButton,
    setDisabledButton,
  ] = useState(false)

  useEffect(() => {
    setShowErrorMessage(showDifferentContractError ? showDifferentContractError : false)
  }, [
    options,
    showDifferentContractError,
  ])

  useEffect(() => {
    if (isAuthenticated && !productsFiltered) {
      (async () => {
        const productsData = await getProducts(products!) as ProductProps[]
        setProductsFiltered(productsData)
      })()
    }
  }, [
    isAuthenticated,
    productsFiltered,
    products,
  ])

  const setDisableButton = () => {
    setDisabledButton(true)
    setTimeout(() => {
      setDisabledButton(false)
    }
    , 3000)
  }

  const { premise } = useContext(PremiseContext)
  const partnerID = premise?.coverage_indicator! === 'B' ? 'box' : 'cfl'
  const isBoxPartner = partnerID === 'box'

  if (productsFiltered) {
    const activeTerm = getProductTerm(options.broadband || options.bundle)
    const productsWithActiveTerm = productsFiltered.filter((e: ProductProps) => getProductTerm(e) === activeTerm)
    const activeProducts = contract_term_check ? productsWithActiveTerm : productsFiltered
    // BoxProducts filter logic to include only products where hide_for_box is not true
    const boxProducts = activeProducts.filter((e: ProductProps) => !e.hide_for_box)

    return (
      <>
        {error_message && (
          <ErrorMessage
            id="contract-length-error"
            message={error_message.message}
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
          />
        )}
        {title && <Heading {...title}/>}
        {(isBoxPartner ? boxProducts : activeProducts).map((product: ProductProps, index: number) => {
          const addOns = [
            options.voice,
            options.tv,
            options.wier,
            options.vas,
          ]
          const selected = Boolean(addOns.find((e: ProductProps) => product?.productOfferingId === e?.productOfferingId))
          return (
            <Product
              key={index}
              product={product}
              bullets={product?.bullets}
              selected={selected!}
              on_click_package={on_click_package}
              title={product?.title}
              subtitle={product?.subtitle}
              image={product?.image}
              button={product.button && product.button.text ? product.button : product_cta}
              id={product?.id}
              button_secondary={product.button_secondary && product.button_secondary.text ? product.button_secondary : product_cta_secondary}
              display_image={product?.display_image}
              display_name={product?.display_name}
              hide_for_box={product?.hide_for_box}
              setDisableButton={setDisableButton}
              disabledButton={disabledButton}
              contract_length={product?.contract_length}
            />
          )
        })}
      </>
    )
  }

  return null
}
