import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  title: css`
        &.MuiTypography-root {
            margin: ${theme.spacing(5)}px 0;
            text-align: center;
        }
    `,
  tabs: css`
        &.MuiPaper-root {
            border: 2px solid ${theme.palette.primary.main} !important;
            border-radius: 1.5rem;
            flex-grow: 1;
            box-shadow: ${theme.shadows[8]};
        }
        .MuiTabs-indicator {
        display: none;
        }
    `,
  tab: css`
        &.MuiTab-root {
            border-radius: 1.5rem;
            text-transform: none;
            color: ${theme.palette.primary.main};
            background-color: none;
            font-weight: ${theme.typography.fontWeightBold!};
            opacity: 1;
            min-width: ${theme.spacing(12)}px;

            ${theme.breakpoints.up('lg')} {
                min-width: ${theme.spacing(20)}px;
            }

            :first-child{
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

        }
        &.Mui-selected {
            color: white;
            background-color: ${theme.palette.primary.main};
        }
    `,
  panel: css`
        &.MuiPaper-root {
            text-align: center;
            margin: 0 ${theme.spacing(5)}px;

            ${theme.breakpoints.up('md')} {
                border-radius: 30px 30px 0 0;
                margin: 0 ${theme.spacing(10)}px;
                text-align: left;
            }
        }
        .MuiGrid-container {
            ${theme.breakpoints.up('md')} {
                padding: ${theme.spacing(4)}px 0;
            }
        }
    `,
  tabHeading: css`
        &.MuiTypography-root{
            ${theme.breakpoints.up('md')} {
                margin-top: ${theme.spacing(5)}px;
            }
            font-size: ${theme.typography.pxToRem(42)};
        }
    `,
  text: css`
    &.MuiTypography-root{
            margin-bottom: ${theme.spacing(5)}px;
            ${theme.breakpoints.up('md')} {
            margin-bottom: 0;
            }
        padding-top: ${theme.spacing(2)}px;
            font-weight: ${theme.typography.fontWeightLight!};
    }
    `,
  indicators: css`
        &.MuiSvgIcon-root{
            border: solid 1px ${theme.palette.primary.main};
        }
    `,
  img: css`
        max-width: 100%;
    `,
}

export default classes
