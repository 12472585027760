import React, { useState } from 'react'
import {
  Grid, Typography, Box,
} from '@material-ui/core'
import classes from './MediaPageMobileSingleBundle.styles'
import { cx } from 'linaria'
import { ChevronRight } from '@material-ui/icons'
import { ProductProps } from '../../../Products/Product'
import { PackageProps } from '../../../Packages/Package'
import Features from '../../../Packages/Features'
import { getRouterName } from '../../../../utils'

interface MediaPageMobileSingleBundle {
  icon?: string;
  bundle?: PackageProps | ProductProps;
}

const MediaPageMobileSingleBundle = ({
  icon,
  bundle,
}: MediaPageMobileSingleBundle) => {
  const [
    infoOpen,
    setInfoOpen,
  ] = useState(false)

  const handleInfo = () => {
    setInfoOpen(Boolean(!infoOpen))
  }

  const routerName = getRouterName(bundle!)

  return (
    <Grid container className={cx(classes.mobilePackageWrapper, 'bundleWrapper')} onClick={() => handleInfo()}>
      <Grid item container className="mobilePackageShortDescription">
        <Grid item container xs={10} className={classes.mobilePackageDescriptionWrapper}>
          {
            icon && (
              <Grid item className={classes.mobilePackageIcon}>
                <img src={icon} alt="bundle"/>
              </Grid>
            )
          }
          <Grid item className={classes.mobilePackageDesciption}>
            {bundle?.display_name && <Typography variant="body1" gutterBottom>{bundle.display_name}</Typography>}
            {bundle?.usage_description && <Typography variant="body2">{bundle.usage_description}</Typography>}
            {routerName && <Typography variant="overline" color="primary">{routerName}</Typography>}
          </Grid>
        </Grid>
        <Grid item container xs={2} justify="flex-end" alignItems="center">
          <Grid item alignContent="flex-end">
            <ChevronRight className={cx(classes.mobilePackageDesktopArrow, 'bundleArrow', infoOpen ? 'open' : '')}/>
          </Grid>
        </Grid>
      </Grid>
      {
        infoOpen ? (
          <Box p={4} pt={0}>
            {bundle?.usage_description && <Typography variant="body1" className="mobileLongDescription">{bundle.usage_description}</Typography>}
            <Features
              size="large"
              simple_bullets={(bundle as PackageProps)?.simple_bullets}
              bullets_crm={bundle}
              bullets_with_icons={(bundle as PackageProps)?.bullets_with_icons}
            />
          </Box>
        ) : null
      }
    </Grid>
  )
}

export default MediaPageMobileSingleBundle
