import React from 'react'
import {
  ListItemText, ListItem, Typography, Collapse, List,
} from '@material-ui/core'
import { cx } from 'linaria'
import { MenuItem } from '../Header'
import {
  ExpandMore, ExpandLess,
} from '@material-ui/icons'
import { useRouter } from 'next/router'
import classes from './MobileHeader.styles'

interface MobileMenuItemProps {
  item: MenuItem;
  index: number;
  handleSubMenuClick: (i: number) => void;
  handleSubMenuChildClick: () => void;
  openSubMenuIndex: number | null;
}

export default function MobileMenuItem({
  item,
  index,
  handleSubMenuClick,
  handleSubMenuChildClick,
  openSubMenuIndex,
}: MobileMenuItemProps) {
  const submenuItems = getSubmenuItems(item)
  const hasItems = submenuItems.length > 0

  const router = useRouter()
  const currentPath = router.asPath
  const { slug } = router.query
  const currentSlug = slug ? `/${slug[0]}` : ''

  function handleMenuClick(url: string | undefined) {
    url && router.push(url)
  }

  const isMainActive = item.url === currentPath
  const isSubActive = item.sub_menu_items?.some(sub => sub.url === currentSlug)
  const currentSubMenuIsOpen = openSubMenuIndex === index

  const itemClass = cx(
    classes.item,
    !hasItems && isMainActive ? classes.activeItem : '',
    hasItems && (isMainActive || isSubActive) ? classes.activeItem : '',
  )

  const handleMainItemClick = () => {
    if (item.url === currentPath) {
      return handleSubMenuClick(index)
    }

    return handleMenuClick(item.url)
  }

  if (!hasItems) {
    return (
      <ListItem
        button
        className={itemClass}
        href={item.url ? item.url : '#'}
        component="a"
      >
        <ListItemText
          disableTypography primary={
            <>
              {item.icon && <img src={item.icon} alt=""/>}
              <Typography
                variant="body1"
                component="div"
              >
                {item.title}
              </Typography>
            </>
          }
        />
      </ListItem>
    )
  }

  return (
    <>
      <ListItem
        button
        className={itemClass}
        onClick={handleMainItemClick}
      >
        <ListItemText
          disableTypography primary={
            <>
              {item.icon && <img src={item.icon} alt=""/>}
              <Typography
                variant="body1"
                component="div"
              >
                {item.title}
              </Typography>
            </>
          }
        />
        <div className={classes.subMenuIcon}>
          {currentSubMenuIsOpen ?
            <ExpandLess
              onClick={(e) => {
                e.stopPropagation()
                handleSubMenuClick(index)
              }}
              fontSize="small"
            /> :
            <ExpandMore
              onClick={(e) => {
                e.stopPropagation()
                handleSubMenuClick(index)
              }}
              fontSize="small"
            />}
        </div>
      </ListItem>
      {hasItems &&
      <Collapse in={currentSubMenuIsOpen} unmountOnExit>
        <List className={classes.subMenuItem}>
          {submenuItems.map((subItem, subIndex) => {
            const isCurrentSlug = subItem.url === currentSlug
            const subItemClass = cx(
              classes.item, isCurrentSlug ? classes.activeSubItem : '')

            return (
              <ListItem
                key={subIndex}
                button
                href={subItem.url ? subItem.url : '#'}
                className={subItemClass}
                component="a"
                onClick={
                  subItem.modal ? (event: React.ChangeEvent<{}>) => {
                    event.stopPropagation()
                    router.push(`${router.asPath}${subItem?.url}`, undefined, { shallow: true })
                  } : handleSubMenuChildClick
                }
              >
                <ListItemText
                  disableTypography
                  primary={<Typography variant="overline" component="div">{subItem.title}</Typography>}
                />
              </ListItem>
            )
          })}
        </List>
      </Collapse>}
    </>
  )
}

function getSubmenuItems(item: MenuItem) {
  return item.sub_menu_items ?? []
}
