import { GeneralOptions } from './components/GeneralContext/GeneralContext'
import { ExperianOptions } from './components/ExperianValidation/ExperianValidation'
import { OrderOptions } from './components/NewOrderFlow/OrderContext'
import { ProductsOptions } from './components/ProductsContext/ProductsContext'

export function createSessionStore<T>(name: string, local?: boolean) {
  return {
    async set(value: T, expiration?: number): Promise<void> {
      try {
        if (local) {
          localStorage.setItem(name, JSON.stringify(value))
        } else if (expiration) {
          const expirationTime = new Date()
            .getTime() + (expiration * 60 * 1000)
          const item = {
            ...value,
            expirationTime,
          }
          sessionStorage.setItem(name, JSON.stringify(item))
        } else {
          sessionStorage.setItem(name, JSON.stringify(value))
        }
      } catch (err) {
        // Session storage not supported
      }
    },
    async get(expiration?: boolean): Promise<T | null> {
      let value
      if (local) {
        value = localStorage.getItem(name)
      } else if (expiration) {
        value = sessionStorage.getItem(name)

        if (value) {
          const { expirationTime } = JSON.parse(value)
          if (new Date()
            .getTime() > expirationTime) {
            sessionStorage.removeItem(name)
            return null
          }
        }
      } else {
        value = sessionStorage.getItem(name)
      }

      if (!value) {
        return null
      }

      try {
        return JSON.parse(value)
      } catch (err) {
        return null
      }
    },
    async delete(): Promise<void> {
      try {
        if (local) {
          localStorage.removeItem(name)
        } else {
          sessionStorage.removeItem(name)
        }
      } catch (err) {
        // Session storage not supported
      }
    },
  }
}

export const newOrderFlowStore = createSessionStore<OrderOptions>('new-order-flow', true)
export const generalStore = createSessionStore<GeneralOptions>('general-context')
export const experianStore = createSessionStore<ExperianOptions>('experian-context')
export const productsStore = (id: string) => createSessionStore<ProductsOptions>(id)
