import { OrderStep } from '../../pages/order/[...step]'

export default function getStepTitle(step: OrderStep): string {
  const base = (() => {
    switch (step) {
      case 'broadband':
      case 'broadband-bundles':
        return 'Choose your broadband'
      case 'addons':
        return 'Choose addons'
      case 'checkout':
      case 'checkout-details':
      case 'checkout-installation':
      case 'checkout-one-touch-switching':
      case 'checkout-payment':
        return 'Checkout'
      case 'checkout-order-summary':
      case 'checkout-order-processing':
      case 'checkout-order-confirmation':
        return 'Order Summary'
      case 'order-management':
        return 'Order Management'
      case 'order-management-porting-confirmation':
        return 'Porting request successful'
      case 'order-management-new-number-confirmation':
        return 'New porting request successful'
    }
  })()

  return `${base} | Community Fibre`
}
