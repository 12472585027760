import React from 'react'
import {
  Grid,
  Typography,
  Container,
} from '@material-ui/core'
import classnames from 'classnames'
import classes from './USPStrip.styles'
import USPItem, { USP } from '../USPItem/USPItem'
import { SectionWithMargin } from '../../styles/SectionWithMargin'

export interface USPStripProps {
  readonly meta: {
    id: number;
  };
  readonly title?: string;
  readonly usps: ReadonlyArray<USP>;
  readonly colour: 'blue' | 'grey' | 'white';
}

const colourClassMapping = {
  blue: classes.blueContainer,
  grey: classes.greyContainer,
  white: classes.whiteContainer,
}

export default function USPStrip({
  meta, colour, title, usps,
}: USPStripProps) {
  return (
    <SectionWithMargin className={classnames(classes.containerBase, colourClassMapping[colour])} id={`USPStrip_${meta.id}`}>
      <Container>
        {title && (
          <Typography variant="h3" align="center" classes={{ root: classes.title }}>
            {title}
          </Typography>
        )}
        <Grid container direction="row" wrap="wrap" justify="space-around">
          {usps.map(usp => (
            <Grid key={usp.body} item xs={4} md={usps.length <= 6 ? 2 : 3} container>
              <USPItem usp={usp}/>
            </Grid>
          ))}
        </Grid>
      </Container>
    </SectionWithMargin>
  )
}
