import React, {
  useEffect, useState,
} from 'react'
import {
  Grid, Typography, Card,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { classes } from './OrderMenu.styles'
import tool from '../../../../../public/icon-tool.svg'
import phone from '../../../../../public/phone.svg'
import RescheduleInstallation from './RescheduleInstallation/RescheduleInstallation'
import herobannerBackground from '../../../../../public/herobanner-bg.webp'
import { MenuOptions } from '../OrderManagement'
import CreateAppointment from './CreateAppointment/CreateAppointment'
import { IWorkorderProps } from '../../../../api/WorkOrder'
import PortingNumberOMP from './PortingNumber/PortingNumberOMP'
import { PremiseDetail } from '../../../../api/Addresses'
import { ScheduleType } from '../../../../api/BookingCalendar'

export interface OrderManagementProps {
  type: string;
  title: string;
  call_plan_btn_text: string;
  installation_btn_text: string;
  call_plan_new_number_checkbox_text: string;
  call_plan_existing_number_checkbox_text: string;
  call_plan_new_number_text: string;
  call_plan_existing_number_text: string;
  dcms_challenge_area: string;
}

const OrderMenu = ({
  hobsOrderInfo,
  workOrder,
  fullPremise,
  orderMenuProps,
  menuSelection,
  onMenuSelectionChange,
  scheduleType,
}: {
  hobsOrderInfo: any;
  workOrder: IWorkorderProps | null;
  fullPremise: PremiseDetail;
  orderMenuProps: OrderManagementProps;
  menuSelection: MenuOptions | null;
  onMenuSelectionChange: (id: string) => void;
  scheduleType: ScheduleType;
}) => {
  const [
    portingNumber,
    setPortingNumber,
  ] = useState<Boolean>(false)

  useEffect(() => {
    const uni1User = hobsOrderInfo.ViewOrderDetailsResponseMessage.order.OrderHeader.orderID.length > 8
    setPortingNumber(uni1User &&
        Boolean(hobsOrderInfo.ViewOrderDetailsResponseMessage.order.OrderLines.SubscriberOrderItem.Subscriber.SubscriberProducts.find(
          (item: any) => item.productSubType === 'VOICE')))
  }, [hobsOrderInfo])

  // For clients without appointments
  if (!workOrder) {
    return <CreateAppointment account={hobsOrderInfo} fullPremise={fullPremise}/>
  }

  // For clients with appointments who needs rescheduling
  if (!portingNumber) {
    return (
      <RescheduleInstallation
        account={workOrder}
        recentWorkorder={workOrder.workOrderTaskId}
        scheduleType={scheduleType}
        fullPremise={fullPremise}
      />
    )
  }

  // For uni1 clients with appointments who needs rescheduling or porting number (if they have bought voice product)
  switch (menuSelection) {
    case 'installation':
      return (
        <RescheduleInstallation
          account={workOrder}
          recentWorkorder={workOrder.workOrderTaskId}
          scheduleType={scheduleType}
          fullPremise={fullPremise}
        />
      )
    case 'port-number':
      return <PortingNumberOMP/>
    default:
      return (
        <>
          <div className={classes.container} style={{ backgroundImage: `url(${herobannerBackground})` }}>
            <Typography variant="h4" className={classes.pageTitle}>Welcome!<br/> Please select an option below</Typography>
          </div>
          <Grid className={classes.options} container alignItems="center" direction="column" justify="center">
            <MenuCard
              img={tool}
              alt="tool"
              handleClick={() => onMenuSelectionChange('installation')}
              text={orderMenuProps?.installation_btn_text}
            />
            <MenuCard
              img={phone}
              alt="phone"
              handleClick={() => onMenuSelectionChange('port-number')}
              text={orderMenuProps?.call_plan_btn_text}
            />
          </Grid>
        </>
      )
  }
}

const MenuCard = ({
  img, alt, text, handleClick,
}: {img: string; alt: string; text: string; handleClick: () => void }) => {
  return (
    <Grid xs={10} md={5} container item justify="center">
      <Card onClick={() => handleClick()} className={classes.card}>
        <span className={classes.menuInfo}>
          <img className={classes.icon} src={img} alt={alt}/>
          <Typography className={classes.cardTitle} variant="subtitle1" component="p">{text}</Typography>
        </span>
        <ArrowForwardIosIcon/>
      </Card>
    </Grid>
  )
}

export default OrderMenu
