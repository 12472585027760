import React, { useState } from 'react'
import {
  FormContext, useForm,
} from 'react-hook-form'
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Fab,
  Grid, Hidden, List, ListItem, ListItemText, ListSubheader, Tooltip, Typography,
} from '@material-ui/core'
import StyledButton from '../../../StyledButton/StyledButton'
import TextInput from '../../../TextInput/TextInput'
import { classes } from './LoginForm.styles'
import { cx } from 'linaria'
import CalendarIcon from '../CalendarIcon'
import InstallationIcon from '../InstallationIcon'
import { InfoOutlined } from '@material-ui/icons'
import { accountRegexCFL } from '../../../../utils'

export interface LoginFormValues {
  accountId: string;
}

interface LoginFormProps {
  onSubmit: (values: LoginFormValues) => Promise<void>;
  error: string;
  loading: boolean;
}

const LoginForm = ({
  onSubmit, error, loading,
}: LoginFormProps) => {
  const methods = useForm<LoginFormValues>()

  const {
    handleSubmit,
  } = methods

  const [
    openTip,
    setOpenTip,
  ] = useState(false)

  const handleTooltipClose = () => {
    setOpenTip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTip(true)
  }

  const toolTip = (
    <>
      <Hidden mdUp>
        <Grid item>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="You can find your account number in your confirmation email. It will begin with A**** or A-****"
                placement="top"
              >
                <Fab className={classes.fab} aria-label="tooltip" onClick={handleTooltipOpen} size="small">
                  <InfoOutlined fontSize="large" color="primary"/>
                </Fab>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            disableFocusListener title="You can find your account number on your confirmation email; the format is A**** or A-****"
            placement="top"
            arrow
          >
            <Fab className={classes.fab} aria-label="tooltip" onClick={handleTooltipOpen} size="small">
              <InfoOutlined fontSize="large" color="primary"/>
            </Fab>
          </Tooltip>
        </Grid>
      </Hidden>
    </>
  )

  return (
    <FormContext {...methods}>
      <Grid className={classes.login} item container direction="column" alignContent="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box py={4}>
            <Typography variant="h3" color="primary">Manage your appointments</Typography>
          </Box>
          <Grid justify="center" container className={classes.form}>
            <Grid className={classes.formBlock} item container sm={12} md={6} justify="flex-start">
              <Box display="flex" alignItems="start">
                <TextInput
                  className={cx(classes.label, error && classes.errorLabel)}
                  gridWidth={12}
                  label="Log in with your Account Number"
                  placeholder="e.g. A31359"
                  id="accountId"
                  type="text"
                  required
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // Use a regular expression to only allow alphanumeric characters
                    const sanitisedValue = e.target.value.replace(/[^a-zA-Z0-9_#-]/g, '')
                    e.target.value = sanitisedValue
                  }}
                  validation={{
                    pattern: {
                      value: accountRegexCFL,
                      message: 'Please use a valid Account Number',
                    },
                    minLength: {
                      value: 5,
                      message: 'Minimum length is 5 characters',
                    },
                    maxLength: {
                      value: 12,
                      message: 'Maximum length is 12 characters',
                    },
                    validate: (value) => value.trim().length > 0 || 'Account Number is required and cannot be just spaces',
                  }}
                />
                {toolTip}
              </Box>
              {error &&
              <Grid item container xs={12} justify="flex-start" className={classes.error}>
                <Typography color="error">{error}</Typography>
              </Grid>}
              <StyledButton type="submit" isFullWidth color="primary">
                Continue
                {loading && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
              </StyledButton>
            </Grid>
            <Grid className={cx(classes.formBlock, classes.right)} item container sm={12} md={6} justify="flex-start">
              <List>
                <ListSubheader component="div" disableSticky>
                  Use this service if you need to:
                </ListSubheader>
                <ListItem>
                  <ListItemText
                    disableTypography primary={
                      <>
                        <CalendarIcon/>
                        <Typography
                          variant="body1"
                          component="div"
                        >
                          Book your installation appointment
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    disableTypography primary={
                      <>
                        <InstallationIcon/>
                        <Typography
                          variant="body1"
                          component="div"
                        >
                          Reschedule an installation appointment or service call
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </FormContext>
  )
}

export default LoginForm
