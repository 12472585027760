import React, {
  useState,
} from 'react'
import {
  Input, Typography, Grid, Box,
} from '@material-ui/core'
import classes from './HeroWithInputs.styles'
import {
  useForm, Controller,
} from 'react-hook-form'
import { cx } from 'linaria'
import createCRMApiClient from '../../api/CRMApi'
import StyledButton from '../StyledButton/StyledButton'
import marketingEvents from '../../utils/marketing/marketingEvents'
import axios from 'axios'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { encryptString } from '../../utils'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
export interface HeroWithInputsProps {
  anchor_id: string;
  title_text: string;
  reward_text: string;
  reward_html_element?: 'h1' | 'h2' | 'h3'| 'div'| 'span' | 'p';
  link_text: string;
  button_text: string;
  desktop_image: string;
  mobile_image: string;
  image_alt_text?: string;
  email_placeholder: string;
  small_print: string;
  mail_icon: string;
  instructions_link: string;
  description: string;
  success_text: string;
  raf_agents: boolean;
  audience_id_uni1: string;
  audience_id_hobs: string;
}

interface IFormInputs {
  email: string;
  agent_code: string;
}

const client = createCRMApiClient()

export const validateReferral = async (customerId?: string, referralCode?: string) => {
  if (customerId) {
    const validatedID = await client.hobs.referralValidateId({ customer_id: customerId })
    return validatedID
  }

  if (referralCode) {
    const validatedCODE = await client.hobsReferral.referralValidateCode({ referral_code: referralCode })
    return validatedCODE
  }
}

export default function HeroWithInputs({
  anchor_id, instructions_link, title_text, reward_text, reward_html_element, link_text, button_text, mobile_image,
  desktop_image, image_alt_text, email_placeholder, small_print, description, success_text, raf_agents, audience_id_uni1, audience_id_hobs,
}: HeroWithInputsProps) {
  const [
    linkAvailable,
    setLinkAvailable,
  ] = useState(false)

  const {
    handleSubmit, control, register,
  } = useForm<IFormInputs>()

  const onSubmit = async ({
    email, agent_code,
  }: IFormInputs) => {
    const referral = await client.hobs.referralGenerate({ email })
    const referralCode = referral?.referralCode
    const customerId = referral?.customerId
    const isValid = await validateReferral(customerId, referralCode)
    const encryptedCustomerId = encryptString(customerId)

    if (!isValid && raf_agents) {
      return
    }

    if (customerId && email) {
      try {
        await axios.post('/api/Mailchimp', {
          email,
          merge_fields: {
            AGENT_CODE: agent_code,
            CUSTOMER_E: encryptedCustomerId,
          },
          AUDIENCE_ID: audience_id_hobs,
        })
      } catch (err) {}
    }

    if (referralCode && email) {
      try {
        await axios.post('/api/Mailchimp', {
          email,
          merge_fields: {
            AGENT_CODE: agent_code,
            CODE: referralCode,
          },
          AUDIENCE_ID: audience_id_uni1,
        })
      } catch (err) {}
    }

    marketingEvents.simpleEvent('refer_friend', options)
    referralCode && marketingEvents.generatedReferralCode(referralCode)
    customerId && marketingEvents.generatedReferralCustomer(customerId)

    setLinkAvailable(true)
  }

  const ReferAFriendTitle = (
    <>
      <Typography variant="h1" display="inline">
        {title_text}
      </Typography>
      <Typography
        variant="h1" display="inline" component={reward_html_element ? reward_html_element : 'span'}
        className={classes.highlightedTitle}
      >
        {reward_text}
      </Typography>
    </>
  )

  const {
    options,
  } = useOrderContext()

  return (
    <section className={cx(classes.parent)} id={anchor_id}>
      <div className={classes.imageContainer}>
        <picture>
          <source srcSet={`${mobile_image} 1x`} media="(max-width: 640px)"/>
          <img
            alt={image_alt_text}
            srcSet={`
              ${desktop_image} 2x
            `}
          />
        </picture>
        <div className={classes.curve}/>
      </div>
      <div className={classes.content}>
        <div className={classes.canvas}>
          <ContainerWithPadding>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={8} md={6}>
                <Box py={10} className={classes.wrapper}>
                  {ReferAFriendTitle}
                  {!linkAvailable &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {raf_agents &&
                      <Box my={4}>
                        <Controller
                          as={
                            <Input
                              disableUnderline
                              placeholder="Agent code"
                              className={classes.MuiFormControl}
                              fullWidth
                              inputProps={{
                                minLength: 6,
                                maxLength: 6,
                              }}
                            />
                          }
                          register={register}
                          required
                          control={control}
                          name="agent_code"
                          defaultValue=""
                        />
                      </Box>}
                      <Box my={4}>
                        <Controller
                          as={
                            <Input
                              disableUnderline
                              placeholder={email_placeholder}
                              className={classes.MuiFormControl}
                              fullWidth
                            />
                          }
                          register={register}
                          required
                          control={control}
                          name="email"
                          defaultValue=""
                        />
                      </Box>
                      <Box
                        className={classes.links}
                      >
                        <StyledButton
                          color="primary"
                          className={classes.MuiFormControl}
                          type="submit"
                        >
                          <Typography>
                            {button_text}
                          </Typography>
                        </StyledButton>
                        <div className={classes.linkedText}>
                          <a className={classes.MuiFormControl} href={instructions_link}>
                            <Typography
                              className={classes.MuiFormControl}
                              variant="h4"
                              display="inline"
                            >
                              {link_text}
                            </Typography>
                          </a>
                        </div>
                      </Box>
                      <Box mt={4} mb={3}>
                        <Typography display="inline" variant="body2">
                          { description }
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography display="inline" className={classes.MuiFormControl} variant="caption">
                          { /* eslint-disable-next-line react/no-danger */ }
                          <div dangerouslySetInnerHTML={{ __html: small_print }}/>
                        </Typography>
                      </Box>
                    </form>}
                  {linkAvailable &&
                    <Box mt={4}>
                      <Typography
                        variant="body1"
                        display="block"
                      >
                        { success_text }
                      </Typography>
                    </Box>}
                </Box>
              </Grid>
            </Grid>
          </ContainerWithPadding>
        </div>
      </div>
    </section>
  )
}
