import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'

const classes = {
  alert: css`
    justify-content: center;
    align-items: center;
    .MuiAlert-message {
      font-size: ${fontSizes.h4}
    }
    `
  ,
}

export default classes
