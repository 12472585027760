import React from 'react'
import {
  IconButton, Grid,
  Link, Typography, Slide, Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { HotBox } from './HotBoxes'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import classes from './HotBoxes.styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

interface HotBoxSlideProps {
  item: HotBox;
  isMobile: boolean;
  handleMobileClick?: () => void;
  isOpen?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  id?: string;
}

interface SlideBodyProps {
  title: string;
  body: string;
  url?: string;
  urlTitle?: string;
  handleMobileClick?: () => void;
  isMobile: boolean;
}

export const SlideBody = ({
  title, body, url, urlTitle, isMobile, handleMobileClick,
}: SlideBodyProps) => {
  return (
    <Grid item container direction="column" justify-content="space-between">
      {isMobile &&
      <IconButton
        className={classes.icon.close}
        onClick={handleMobileClick}
        disableRipple
      >
        <CloseIcon/>
      </IconButton>}
      <Grid item xs className={classes.slideText}>
        <Grid item xs>
          <Typography variant="h5">{title}</Typography>
          <Wysiwyg variant="body1" body={body}/>
        </Grid>
      </Grid>
      {url ?
        <Grid item xs container alignContent="flex-end" justify="flex-end" className={classes.slideLinkWrapper}>
          <Link color="secondary" href={url} underline="hover">
            <Typography variant="body1">
              {urlTitle}
            </Typography>
            <ArrowForwardIosIcon color="secondary"/>
          </Link>
        </Grid> :
        <Box pb={2}/>}
    </Grid>
  )
}

const HotBoxSlide = ({
  item, isMobile, handleMobileClick, isOpen, direction = 'left', id,
}: HotBoxSlideProps) => {
  const DesktopSlide = ({ children }: { children: JSX.Element }) => {
    if (isMobile) {
      return children
    }

    return (
      <Slide in={isOpen} direction={direction}>
        {children}
      </Slide>
    )
  }

  return (
    <DesktopSlide>
      <Grid container className={classes.slideContainer} id={id}>
        <SlideBody
          title={item.title}
          body={item.body}
          url={item.url}
          urlTitle={item.url_title}
          isMobile={isMobile}
          handleMobileClick={handleMobileClick}
        />
      </Grid>
    </DesktopSlide>
  )
}

export default HotBoxSlide
