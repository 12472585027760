import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react'
import { VoucherProps } from '../PaidMediaLandingPage/MediaPageHero/MediaPageVoucher'
import { TimeslotData } from '../../api/BookingCalendar'
import { newOrderFlowStore } from '../../sessionStorage'
import { PersonalDetailsFormData } from './steps/CheckoutStep/PersonalDetailsForm/PersonalDetailsForm'
import { PaymentData } from './steps/CheckoutStep/PaymentDetails/PaymentDetails'
import {
  ChannelType, PlatformType, SegmentType,
} from '../../utils/commonEnums'
import { useRouter } from 'next/router'
import { clearCart } from '../../pages/friends'
import { OTSFormProps } from '../../api/OTSForm'

export const THREE_GIGA_IDS = [
  '5f919991ec3439005733a8e2',
  '6051e3cd00d048002a168014',
]

export interface OrderOptions {
  broadband?: any;
  voice?: any | null;
  tv?: any | null;
  wier?: any | null;
  vas?: any | null;
  bundle?: any | null;
  callPackage?: number;
  router: boolean;
  connector: boolean;
  data: PersonalDetailsFormData;
  voucher_flags?: VoucherProps[];
  discount?: any | null;
  promo?: any | null;
  timeslot: TimeslotData | null;
  timeslotError: string;
  countDownTimerStart: number | null;
  basketExpiryTimer?: boolean;
  isDetailsStep: boolean;
  reachedInstallationStep: boolean;
  earliestTimeslot: TimeslotData | null;
  paymentDetails?: PaymentData;
  paymentError: string;
  placeOrderError: string;
  referralSource?: string;
  activeContractLength?: number;
  activeContractLengthTab: number;
  accountId?: string;
  hasSelectedNoVoice?: boolean;
  hasSelectedNoTV?: boolean;
  hasSelectedNoWIER?: boolean;
  hasSelectedNoVAS?: boolean;
  appliedReferralCode?: string;
  appliedProductId?: string;
  hasClosedAbandonedCartModal: boolean;
  showDifferentContractError?: boolean;
  hasPortedNumber?: boolean;
  portedNumber: string;
  previousProvider: string;
  error_5000_url: string;
  error_5001_url: string;
  error_5003_url: string;
  default_error_url: string;
  reservationId: string;
  alternativePhone?: boolean;
  cartInstanceIdentifier: string;
  defaultRafId?: string;
  channel: string;
  utmSource?: string;
  cks?: string;
  hide_strikethrough?: boolean;
  packagesTerm: {
    [SegmentType.RESIDENTIAL]: number | null;
    [SegmentType.BUSINESS]: number | null;
    [SegmentType.STUDENT]: number | null;
  };
  voucherProduct: any | null;
  offer: string | null;
  dcmsEligibility: boolean;
  platform: PlatformType;
  promoToApply?: string | null;
  oneTouchSwitching: boolean;
  switchingConfirmation: boolean;
  switchingConfirmationError: boolean;
  OTSPhone: boolean;
  OTSMatchRequestError: boolean;
  OTSFormProps?: OTSFormProps;
  OTSOptOut: boolean;
  OTSResubmitTriggered: boolean;
  butterCheckoutContent?: any;
}

export const INITIAL_ORDER_OPTIONS: OrderOptions = {
  router: false,
  connector: false,
  data: {
    sprn: '',
    address: '',
    firstName: '',
    lastName: '',
    phone: '',
    contactMarketing: false,
    email: '',
    contactEmail: false,
    contactPhone: false,
    contactSMS: false,
    contactPost: false,
    readTerms: false,
    promo: '',
  },
  voucher_flags: [],
  discount: null,
  promo: null,
  countDownTimerStart: null,
  basketExpiryTimer: false,
  timeslot: null,
  timeslotError: '',
  isDetailsStep: true,
  reachedInstallationStep: false,
  earliestTimeslot: null,
  paymentError: '',
  placeOrderError: '',
  hasSelectedNoVoice: false,
  hasSelectedNoTV: false,
  hasSelectedNoWIER: false,
  hasSelectedNoVAS: false,
  hasClosedAbandonedCartModal: false,
  hasPortedNumber: false,
  portedNumber: '',
  previousProvider: '',
  activeContractLength: 24,
  activeContractLengthTab: 0,
  error_5000_url: '/order-successful-act-account',
  error_5001_url: '/order-unsuccessful-salesstatus',
  error_5003_url: '/order-successful-installer',
  default_error_url: '/order-unsuccessful',
  reservationId: '',
  alternativePhone: true,
  cartInstanceIdentifier: '',
  defaultRafId: 'Refer_friend',
  channel: ChannelType.DIGITAL,
  utmSource: undefined,
  cks: undefined,
  packagesTerm: {
    [SegmentType.RESIDENTIAL]: null,
    [SegmentType.BUSINESS]: null,
    [SegmentType.STUDENT]: null,
  },
  voucherProduct: null,
  offer: null,
  dcmsEligibility: false,
  platform: PlatformType.CF,
  promoToApply: null,
  oneTouchSwitching: true,
  switchingConfirmation: false,
  switchingConfirmationError: false,
  OTSPhone: false,
  OTSMatchRequestError: false,
  OTSOptOut: false,
  OTSResubmitTriggered: false,
}

interface OrderContextType {
  options: OrderOptions;
  setOptions: React.Dispatch<React.SetStateAction<OrderOptions>>;
}

const OrderContext = React.createContext<OrderContextType | null>(null)

export function useOrderContext() {
  const context = useContext(OrderContext)

  if (!context) {
    throw new Error('Ensure you use inside OrderContext Provider')
  }

  return context
}

export function OrderContextProvider({ children }: { children: ReactNode }) {
  const { query } = useRouter()
  const router = useRouter()
  const [
    options,
    setOptions,
  ] = useState<OrderOptions>(INITIAL_ORDER_OPTIONS)

  const { cartInstanceIdentifier } = options

  useEffect(() => {
    async function fetchSavedOrder() {
      const { appliedReferralCode: initialAppliedReferralCode } = INITIAL_ORDER_OPTIONS
      const { appliedProductId: initialAppliedProductId } = INITIAL_ORDER_OPTIONS
      const savedOrder = await newOrderFlowStore.get()

      if (savedOrder) {
        setOptions(state => {
          const {
            appliedReferralCode, appliedProductId,
          } = state
          return ({
            ...state,
            ...savedOrder,
            appliedReferralCode: appliedReferralCode === initialAppliedReferralCode ? savedOrder.appliedReferralCode : appliedReferralCode,
            appliedProductId: appliedProductId === initialAppliedProductId ? savedOrder.appliedProductId : appliedProductId,
          })
        })
      }
    }

    fetchSavedOrder()
  }, [])
  const cartInactivityTimer = async (): Promise<void> => {
    const timeoutDuration = 30 * 60 * 1000
    setTimeout(() => {
      setOptions((state) => ({
        ...state,
        ...clearCart,
      }))
      router.push('/session-expired')
      newOrderFlowStore.delete()
    }, timeoutDuration)
  }

  useEffect(() => {
    if (cartInstanceIdentifier && cartInstanceIdentifier !== '') {
      cartInactivityTimer()
    }

    return undefined
  }, [cartInstanceIdentifier])

  useEffect(() => {
    async function persistOrderState() {
      if (JSON.stringify(options) !== JSON.stringify(INITIAL_ORDER_OPTIONS)) {
        const now = new Date()
          .getTime()
        const newOrderFlow = localStorage.getItem('new-order-flow')
        const basketExpiryTime = localStorage.getItem('basketExpiryTime')
        if (newOrderFlow) {
          if (!basketExpiryTime || JSON.stringify(options) !== newOrderFlow) {
            localStorage.setItem('basketExpiryTime', JSON.stringify(now))
          }
        }
      }

      await newOrderFlowStore.set({
        ...options,
      })
    }

    persistOrderState()
  }, [options])

  useEffect(() => {
    async function fetchAffilateGeneralContext() {
      const utmSource = query?.utm_source as string
      const cks = query?.awc as string
      if (utmSource && cks) {
        setOptions(state => {
          return ({
            ...state,
            utmSource,
            cks,
          })
        })
      }
    }

    fetchAffilateGeneralContext()
  }, [query])

  return (
    <OrderContext.Provider value={{
      options,
      setOptions,
    }}
    >
      {children}
    </OrderContext.Provider>
  )
}
