import React, {
  useEffect, useState,
} from 'react'
import {
  AppBar, Toolbar, IconButton, Box,
} from '@material-ui/core'
import logo from '../logoDesktop.svg'
import logoInverted from '../logoDesktopInverted.svg'
import { HeaderProps } from '../Header'
import MenuIcon from '../icons/MenuIcon'
import useMenu from '../useMenu'
import classes from './MobileHeader.styles'
import SecureIcon from '../icons/SecureIcon'
import { MiniBasket } from '../MiniBasket/MiniBasket'
import ContactUs from '../../ContactUs/ContactUs'
import TrustPilotBox from '../../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { useRouter } from 'next/router'
import MobileDialogMenu from './MobileDialogMenu'
import { useOrderContext } from '../../NewOrderFlow/OrderContext'
import NavigationTabs from '../../NavigationTabs/NavigationTabs'
import { cx } from 'linaria'

export default function MobileHeader({
  shared_menu_items,
  my_account_link,
  secure,
  mini_basket_error,
  contact_us,
  trustpilot_box,
  categories,
  mobile_button,
  inverted,
}: HeaderProps & {className?: string}) {
  const {
    toggleHamburgerMenu,
    isHamburgerMenuOpen,
    findActiveCategory,
    findRouterSlug,
  } = useMenu()

  const [
    activeCategoryIndex,
    setActiveCategory,
  ] = useState(0)

  const [
    openSubMenuIndex,
    setOpenSubMenuIndex,
  ] = useState<number | null>(null)

  const [
    mobileActiveNavTab,
    setMobileActiveNavTab,
  ] = useState(0)

  const [
    showMiniBasket,
    setShowMiniBasket,
  ] = useState(false)

  const { options: { broadband } } = useOrderContext()

  const router = useRouter()

  useEffect(() => {
    if (categories) {
      const currentCategoryIndex = findActiveCategory(categories, findRouterSlug())

      setActiveCategory(currentCategoryIndex)
      setShowMiniBasket(Boolean(categories?.[Number(currentCategoryIndex)]?.mini_basket))

      const categoryMobileTabs = categories?.[Number(currentCategoryIndex)]?.mobile_navigation_tabs

      if (categoryMobileTabs) {
        const currentUrl = router.asPath
        const findActiveMobileTabIndex = () => categoryMobileTabs.findIndex(tab => (tab.url === currentUrl))

        setMobileActiveNavTab(findActiveMobileTabIndex())
      }
    }
  }, [
    router.asPath,
    categories,
  ])

  const handleCategoryClick = (i: number) => {
    setOpenSubMenuIndex(null)
    setActiveCategory(i)
  }

  const handleSubMenuClick = (index: number) => {
    if (index === openSubMenuIndex) {
      return setOpenSubMenuIndex(null)
    }

    setOpenSubMenuIndex(index)
  }

  return (
    <div className={classes.mobileHeader}>
      <AppBar position="relative" elevation={0}>
        <Toolbar>
          <a href="/" className={classes.logoWrapper}>
            {inverted ?
              <img src={logoInverted} alt="CommunityFibre - better internet for everyone" width="151" height="40"/> :
              <img src={logo} alt="CommunityFibre - better internet for everyone" width="151" height="40"/>}
          </a>
          {secure && <SecureIcon color="secondary" className={classes.secureIcon}/>}
          {contact_us && Object.values(contact_us).length > 0 &&
            <div className={cx(classes.contactUsWrapper, 'mobileContactUsWrapper')}>
              <ContactUs
                help_text={contact_us.help_text}
                phone_icon={contact_us.phone_icon}
                phone_number={contact_us.phone_number}
                call_me_back_text={contact_us.call_me_back_text}
                start_time={contact_us.start_time}
                end_time={contact_us.end_time}
                saturday_start_time={contact_us.saturday_start_time}
                saturday_end_time={contact_us.saturday_end_time}
                call_me_back_modal={contact_us.call_me_back_modal}
                visible_desktop={contact_us.visible_desktop}
                visible_mobile={contact_us.visible_mobile}
                mobile
              />
            </div>}
          {showMiniBasket && <MiniBasket anchor="top" errorMessage={mini_basket_error}/>}
          {my_account_link?.icon &&
            <a href={my_account_link.url}>
              <Box display="flex" alignItems="center" p={1}>
                <img src={my_account_link.icon} alt={my_account_link.title} width={30}/>
              </Box>
            </a>}
          {shared_menu_items?.length && (
            <IconButton
              color="primary" aria-label="menu"
              onClick={toggleHamburgerMenu}
              className={classes.hamburgerButtonWrapper}
            >
              <MenuIcon/>
            </IconButton>
          )}
        </Toolbar>
        {trustpilot_box && trustpilot_box.widget &&
        <div className={classes.trustpilotWrapper}>
          <TrustPilotBox
            background_color={trustpilot_box.background_color}
            widget={trustpilot_box.widget}
            padding={trustpilot_box.padding}
            margin={trustpilot_box.margin}
            wrapped={trustpilot_box.wrapped}
            inverted={trustpilot_box.inverted}
            width={trustpilot_box.width}
            offset={trustpilot_box.offset}
            mobile_center={trustpilot_box.mobile_center}
          />
        </div>}
      </AppBar>
      {categories?.[Number(activeCategoryIndex)] &&
        <MobileDialogMenu
          isHamburgerMenuOpen={isHamburgerMenuOpen}
          toggleHamburgerMenu={toggleHamburgerMenu}
          categories={categories}
          activeCategoryIndex={activeCategoryIndex}
          handleCategoryClick={handleCategoryClick}
          handleSubMenuClick={handleSubMenuClick}
          openSubMenuIndex={openSubMenuIndex}
          shared_menu_items={shared_menu_items}
          my_account_link={my_account_link}
          button={broadband ? null : mobile_button}
        />}
      {categories?.[Number(activeCategoryIndex)] &&
      <NavigationTabs
        tabs={categories[Number(activeCategoryIndex)].mobile_navigation_tabs}
        activeTab={mobileActiveNavTab}
        className={classes.navigationTabs}
      />}
    </div>
  )
}
