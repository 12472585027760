import { AxiosInstance } from 'axios'

interface Identity {
  id: string;
  name: string;
}

interface Provider {
  listType: string;
  identity: Identity[];
}

export default function OTSAddEntities(client: AxiosInstance) {
  async function postOTSAddEntities({
    cartInstanceIdentifier,
    channelID,
    userTokenString = 'CFLHOBS',
    serviceType = 'BROADBAND',
    customerCategory = 'RES',
    corelationID,
    loosingProviderName = '',
    loosingProviderID = '',
    selection,
  }: {
    cartInstanceIdentifier: string;
    channelID: string;
    userTokenString?: string;
    serviceType?: string;
    customerCategory?: string;
    corelationID: string;
    loosingProviderName?: string;
    loosingProviderID?: string;
    selection?: string;
    loosingAccountID?: string;
    portNo?: string;
  }) {
    try {
      // Retrieve SOR value from session storage
      const sorID = sessionStorage.getItem('ots-sor-reference') || 'defaultSORValue'

      // Retrieve the 'new-order-flow' value from local storage
      const newOrderFlowStr = localStorage.getItem('new-order-flow')
      if (newOrderFlowStr) {
        const newOrderFlow = JSON.parse(newOrderFlowStr)
        loosingProviderID = newOrderFlow.OTSFormProps.partnerID || loosingProviderID
      }

      // Cross-reference loosingProviderID with OTSList to get loosingProviderName
      const OTSListStr = localStorage.getItem('OTSList')
      if (OTSListStr) {
        const OTSList: Provider[] = JSON.parse(OTSListStr)
        const provider = OTSList.find((provider) =>
          provider.identity.some((id) => id.id === loosingProviderID),
        )
        if (provider) {
          const identity = provider.identity.find((id) => id.id === loosingProviderID)
          loosingProviderName = identity?.name || loosingProviderName
        }
      }

      // Retrieve the selection value from session storage
      const storedSelection = sessionStorage.getItem('ots-services') || selection || ''

      // Prepare entity attributes
      const entityAttributes = [
        {
          attributeName: 'matchconfirmed',
          attributeValue: 'true',
        },
        {
          attributeName: 'corelation_id',
          attributeValue: corelationID,
        },
        {
          attributeName: 'loosing_provider_name',
          attributeValue: loosingProviderName,
        },
        {
          attributeName: 'loosing_provider_id',
          attributeValue: loosingProviderID,
        },
        {
          attributeName: 'SOR',
          attributeValue: sorID,
        },
        {
          attributeName: 'selection',
          attributeValue: storedSelection,
        },
      ]

      // Check for values in session storage for 'ots-porting-value' and 'ots-account-id'
      const storedPortNo = sessionStorage.getItem('ots-porting-value')
      const storedLoosingAccountID = sessionStorage.getItem('ots-account-id')

      // Add to entityAttributes only if the values exist in session storage
      if (storedPortNo) {
        entityAttributes.push({
          attributeName: 'PortNo',
          attributeValue: storedPortNo,
        })
      }

      if (storedLoosingAccountID) {
        entityAttributes.push({
          attributeName: 'loosing_account_id',
          attributeValue: storedLoosingAccountID,
        })
      }

      const params = {
        qualificationCriteria: {
          serviceType,
          customerCategory,
          channelID,
        },
        channelID,
        userTokenString,
        cartInstanceIdentifier,
        requestObject: {
          cartID: cartInstanceIdentifier,
          globalEntities: {
            OrderParameters: {
              '@type': 'AttributeList',
              entityValue: {
                attribute: entityAttributes,
              },
              id: cartInstanceIdentifier,
              name: 'OrderParameters',
            },
          },
        },
      }

      const { data } = await client.post<{ data: any }>(
        'cart/v1/0.0/addentities',
        params,
      )

      // Remove specific keys from session storage after successful submission
      sessionStorage.removeItem('ots-match-result')
      sessionStorage.removeItem('ots-sor-reference')
      sessionStorage.removeItem('ots-services')
      sessionStorage.removeItem('ots-user-confirmation')
      sessionStorage.removeItem('OTSCorrelationID')

      return data
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  return {
    postOTSAddEntities,
  }
}
