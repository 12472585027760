import React, { useRef } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  Done, Close,
} from '@material-ui/icons'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import classes from './ComparisonTable.styles'
import { cx } from 'linaria'

import { useSwipeable } from 'react-swipeable'

export interface ComparisonTableProps {
  columns: {[key: string]: string[]};
  rowNames: {[key: string]: string };
  currentComparison?: number;
  last_updated?: string;
  modal_display?: boolean;
  handleChangeComparison?: (previous?: boolean) => void;
}

export default function ComparisonTable({
  columns,
  rowNames,
  last_updated,
  currentComparison,
  handleChangeComparison,
}: ComparisonTableProps) {
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleChangeComparison && handleChangeComparison()
    },
    onSwipedRight: () => {
      handleChangeComparison && handleChangeComparison(true)
    },
  })
  const swipeContainerRef = useRef<HTMLDivElement>()

  const refPassthrough = (el: HTMLDivElement) => {
    handlers.ref(el)
    swipeContainerRef.current = el
  }

  const renderIcon = (
    isSameSpeed: boolean,
    satisfactionGuarantee: boolean,
    unlimitedData: boolean,
    contractPriceIncrease: boolean,
    cellValue: string,
    isYes: boolean,
  ) => {
    if (isSameSpeed || satisfactionGuarantee || unlimitedData || contractPriceIncrease) {
      if (isYes) {
        return (
          <span className={cx(classes.doneIncon)}>
            <Done/>
          </span>
        )
      }

      return (
        <span className={cx(classes.closeIcon)}>
          <Close/>
        </span>
      )
    }

    return cellValue
  }

  const body = () => {
    return (
      <TableBody>
        {Object.entries(columns)
          .map((keyValuePair: [string, string[]], i: number) => {
            const cellRows = Object.values(keyValuePair[1])

            if (keyValuePair[0] !== 'company' && keyValuePair[0] !== 'company_name') {
              return (
                <TableRow key={i}>
                  {rowNames[keyValuePair[0]] ?
                    <TableCell className={classes.tableCell}>
                      <Wysiwyg body={rowNames[keyValuePair[0]]} variant="body2"/>
                    </TableCell> :
                    <TableCell>
                      <div className={classes.emptyHeaderCell}/>
                    </TableCell>}
                  {cellRows
                    .map((
                      cellValue: string,
                      i: number,
                    ) => {
                      const mobileComparison = currentComparison ? (i < 1 || i === currentComparison || i === currentComparison + 1) : true
                      const companyName = columns.company_name[i]
                      const packageName = keyValuePair[0] === 'package'
                      const isTotalSavingsRow = keyValuePair[0] === 'total_saving'
                      const setUpFee = keyValuePair[0] === 'set_up_fee'
                      const isSameSpeed = keyValuePair[0] === 'same_speed'
                      const satisfactionGuarantee = keyValuePair[0] === 'satisfaction_guarantee'
                      const unlimitedData = keyValuePair[0] === 'unlimited_data'
                      const contractPriceIncrease = keyValuePair[0] === 'contract_price_increase'
                      const isCF = companyName === 'Community Fibre'
                      const isYes = cellValue.toLowerCase() === 'yes'
                      const setCompanyName = () => {
                        return companyName ? companyName : 'Product ' + (i + 1)
                      }

                      if (keyValuePair[0] === 'company_image') {
                        return (
                          mobileComparison &&
                          <TableCell scope="row" key={i} className={cx(setUpFee && isSameSpeed && contractPriceIncrease && satisfactionGuarantee && classes.primaryColourCell, classes.tableCell, classes.tableCellCompany)}>
                            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                              {cellValue &&
                                <img src={cellValue} alt={setCompanyName()}/>}
                              {!cellValue && !companyName &&
                                <div>{setCompanyName()}</div>}
                              {!cellValue && companyName &&
                                <div>{companyName}</div>}
                            </Box>
                          </TableCell>
                        )
                      }

                      return (
                        mobileComparison &&
                        <TableCell
                          scope="row"
                          key={i}
                          className={cx(
                            isTotalSavingsRow && classes.priceDiferenceColourCell,
                            isCF && !packageName && classes.secondaryColourCell,
                            classes.tableCell,
                          )}
                        >
                          <Typography variant="body2" color="textPrimary">
                            {renderIcon(isSameSpeed, satisfactionGuarantee, unlimitedData, contractPriceIncrease, cellValue, isYes)}
                          </Typography>
                        </TableCell>
                      )
                    })}
                </TableRow>
              )
            }

            return false
          })}
      </TableBody>
    )
  }

  return last_updated ?
    <div
      {...handlers}
      ref={refPassthrough}
    >
      <Typography className={classes.title} display="block" variant="h3" color="primary">How we compare</Typography>
      <Box
        overflow={{
          lg: 'auto',
        }}
      >
        <Box className={classes.root}>
          <Table className={classes.table}>
            {body()}
          </Table>
        </Box>
      </Box>
    </div> :
    <Box className={classes.wrapper}>
      <div
        className={classes.root}
        {...handlers}
        ref={refPassthrough}
      >
        <Table className={classes.table}>
          {body()}
        </Table>
      </div>
    </Box>
}
