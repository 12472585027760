import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  section: css`
    padding-top: ${theme.spacing(2)}px;
  `,
  title: css`
    color: ${theme.palette.primary.main};
    text-align: center;
    &.MuiTypography-root {
      font-size: ${fontSizes.h3};
    }
    &.MuiTypography-root {
      margin-bottom: ${theme.spacing(5)}px;
      ${theme.breakpoints.up('md')} {
        margin-bottom: ${theme.spacing(6)}px;
        font-size: ${fontSizes.h2}
      }
    }
  `,
  bgPrimary: css`
    background-color: ${theme.palette.primary.main};
  `,
  bgSecondary: css`
    background-color: ${theme.palette.secondary.main};
  `,
  mapLegend: css`
    padding: ${theme.spacing(2, 0)};
    background: ${theme.palette.warning.light};
    .MuiTypography-body2{
      font-weight: ${theme.typography.fontWeightLight!}
    }
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(3, 0, 2)};
    }
  `,
  mapWrapper: css`
    min-height: 580px;
    position:relative;
  `,
  mapLegendWrapper: css`
    display: flex;

    &.MuiGrid-item{

      &.rightSide{
        padding-left: ${theme.spacing(10)}px;
      }

      ${theme.breakpoints.down('sm')} {
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding: ${theme.spacing(0, 2)};
      }
    }
  `,
  mapLegendItemAvailable: css`
    background: ${theme.palette.primary.main};
  `,
  mapLegendItemUnavailable: css`
    background: ${theme.palette.secondary.main};
  `,
  mapLegendItem: css`
    width: 20px;
    height: 20px;
    margin: ${theme.spacing(1)}px;
    border-radius: ${commonStyling.smallRoundedBorders.all};
    display: block;
    ${theme.breakpoints.up('sm')} {
        margin: ${theme.spacing(2)}px;
    }
  `,
  zoomOutButton: css`
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: ${theme.spacing(1)}px;
    left: ${theme.spacing(1)}px;
    ${theme.breakpoints.up('sm')} {
      top: 30px;
      left: 30px;
    }
  `,
  infoCard: css`

    ${theme.breakpoints.up('md')} {
      box-shadow: 0px 3px 40px rgba(0,0,0,0.12);
      position: absolute;
      top: 151px;
      left: 110px;
      background: white;
      max-width: 320px;
      border-radius: 20px;
    }
  `,
  infoCardWrapper: css`
    display: flex;
    flex-direction: column;
    margin: 32px 18px;

    ${theme.breakpoints.up('md')} {
      margin: 36px 32px 38px;
    }
  `,
  infoCardSubtitle: css`
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
      color: ${COLORS.bodyTextPrimary};
      margin-bottom: 0;
      margin-top: ${theme.spacing(2)}px;

      ${theme.breakpoints.up('md')} {
        margin-bottom: ${theme.spacing(4)}px;
      }
    }
  `,
  infoCardButton: css`
    &.MuiButton-root {
      display: none;
      ${theme.breakpoints.up('md')} {
        display: flex;
      }
    }
  `,
}

export default classes
