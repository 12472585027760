import React, {
  useContext,
} from 'react'
import {
  HobsProduct,
} from '../../api/Packages'
import Bundle from './Bundle'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import {
  ButtonPropsExtended, PackageProps,
} from '../Packages/Package'
import { getProductTerm } from '../../utils/getProductDetails'
import { Box } from '@material-ui/core'
import { ProductsContext } from '../ProductsContext/ProductsContext'
import { getHybridPackages } from '../../utils'
export interface BundlesProps {
  anchor?: string;
  bundles?: PackageProps[];
  noContainer?: boolean;
  bundle_cta: ButtonPropsExtended;
  bundle_cta_secondary?: ButtonPropsExtended;
  can_be_selected?: boolean;
  on_click_package?: (product: HobsProduct, cartInstanceIdentifier: string, channel: string) => void;
  mt?: number;
  activeTerm?: number;
}

export default function Bundles({
  anchor,
  bundles,
  on_click_package,
  noContainer,
  bundle_cta,
  bundle_cta_secondary,
  can_be_selected,
  mt,
  activeTerm,
}: BundlesProps) {
  const {
    options,
  } = useOrderContext()

  const {
    bundle,
  } = options

  const { dataPackages } = useContext(ProductsContext)
  const hybridBundles = getHybridPackages(dataPackages!, bundles!)

  return (
    <Box id={anchor ? anchor : 'bundle_packages'} mt={mt}>
      {hybridBundles?.map((item: PackageProps, index: number) => {
        return (
          activeTerm === getProductTerm(item) &&
            <Bundle
              key={index}
              product={item}
              selected={can_be_selected && bundle?.anchor === item?.anchor}
              on_click_package={on_click_package}
              term={getProductTerm(item)}
              price_guidance={item?.price_guidance}
              flip={item?.flip}
              noContainer={noContainer}
              button={item?.button ? item.button : bundle_cta}
              button_secondary={item?.button_secondary ? item.button_secondary : bundle_cta_secondary}
              flag={item?.flag}
              title={item?.title}
              footnote={item?.footnote}
              display_image={item?.display_image}
              display_name={item?.display_name}
              simple_bullets={item?.simple_bullets}
              bullets_back={item?.bullets_back}
              anchor={item?.anchor}
              mt={mt}
            />
        )
      })}
    </Box>
  )
}
