import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  root: css`
    height: 100%;
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(12)}px;
      padding-bottom: ${theme.spacing(1)}px;
    }
  `,
  title: css`
    &.MuiTypography-root {
      padding: ${theme.spacing(2)}px;
      padding-bottom: 0;
    }
  `,
  lightText: css`
    &.MuiTypography-root {
      padding: ${theme.spacing(2)}px;
      padding-bottom: 0;
      color: white;
    }
  `,
  body: css`
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
      padding: ${theme.spacing(1)}px;
    }
  `,
  about: css`
    padding: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(3)}px;
    justify-content: center;
    ${theme.breakpoints.up('sm')} {
      justify-content: flex-start;
    }
  `,
  contact: css`
   max-height: 280px;
   min-height: 200px;
   text-align: center;
   a.MuiTypography-colorPrimary {
    color: white;
    font-weight: ${theme.typography.fontWeightLight!};
   }
   .MuiPaper-root {
    background-color: ${theme.palette.primary.main};
    border-radius: 0;
    box-shadow: none;
    ${theme.breakpoints.up('sm')} {
      border-radius: 20px;
      box-shadow: 10px 10px 5px -7px rgba(207,207,207,1);
    }
   }
 `,
  cardText: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 `,
  card: css`
    min-height: 100%;
    min-width: 100%;
    position: relative;
    .MuiCardMedia-root {
      max-height: 280px;
      height: 100%;
    }
  `,
}

export default classes
