import capitalize from 'lodash.capitalize'

const formatAddress = (address: string) => {
  const splitAddress = address.split(/(?<postcode>,[^,]*$)/)
  return (
    splitAddress[0].replace(/\w+/g, capitalize) + splitAddress[1]
  )
}

export default formatAddress
