import { useEffect } from 'react'
import { parse } from 'querystringify'

import {
  utmStore,
} from '../../storage'

export interface UTMState {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export default function UTMTracker() {
  useEffect(() => {
    async function persistUTMDetails() {
      const urlParams = parse(location.search) as { [key: string]: string }

      const newUTMParams: UTMState = {
        utm_source: urlParams.utm_source,
        utm_medium: urlParams.utm_medium,
        utm_campaign: urlParams.utm_campaign,
        utm_term: urlParams.utm_term,
        utm_content: urlParams.utm_content,
      }

      const hasUTMParam = Object.values(newUTMParams)
        .some((v) => Boolean(v))

      if (hasUTMParam) {
        const ttl = 2 * 3600
        await utmStore.set(newUTMParams, ttl)
      }
    }

    persistUTMDetails()
  }, [])

  return null
}

