import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  modal: css`
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 9999999 !important;
    background-color: rgba(0, 0, 0, 0.5);
    div[aria-hidden] {
      background-color: transparent !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  textContainer: css`
    outline: none;
    text-align: center;
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(5, 3)};
    margin: 0 ${theme.spacing(2)}px;
    img {
      padding-bottom: ${theme.spacing(3)}px;
    }
  `,
  warningText: css`
    color: ${COLORS.bodyTextPrimary};
    padding-bottom: ${theme.spacing(3)}px;
    &.MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
}

export default classes
