import React from 'react'
import {
  Typography,
} from '@material-ui/core'
import {
  HelpCategory as IHelpCategory,
} from './HelpCategoryList'
import { useRouter } from 'next/router'
import classes from './HelpCategories.styles'
import MediaImage from '../Media/MediaImage'

export default function HelpCategories({ categories }: {categories?: IHelpCategory[]}) {
  return (
    <div className={classes.list}>
      {categories?.length && categories.map((category, index) => (
        <HelpCategory category={category} key={index}/>
      ))}
    </div>
  )
}

function HelpCategory({ category }: {category: IHelpCategory}) {
  const router = useRouter()
  const handleClick = () => {
    router.push(category.button_url, undefined, { shallow: true })
  }

  return (
    <button type="button" className={classes.category} onClick={handleClick}>
      <div className={classes.imageWrapper}>
        <MediaImage
          src={category.image_src}
          width={50}
          height={50}
        />
      </div>
      <Typography variant="body1" color="primary">{category.title}</Typography>
    </button>
  )
}
