import React, {
  useRef,
} from 'react'
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import {
  Day as DayType, Meridiem, mobileCalendarHeight,
} from './Calendar'
import CalendarDay from './CalendarDay'
import { useSwipeable } from 'react-swipeable'
import classes from './Calendar.styles'
import theme from '../../styles/theme'

interface MobileCalendarProps {
  readonly selectedTimeIndex?: number;
  readonly selectedMeridiem?: Meridiem;
  readonly selectedDayIndex: number;
  readonly selectedWeek?: number;
  readonly currentWeek?: number;
  readonly selectedTimeslotDayIndex?: number;
  readonly nextWeek: () => void;
  readonly prevWeek: () => void;
  readonly days: DayType[];
    changeTimeslot({
      dayIndex, meridiem, timeIndex, onMobile,
    }: {
      dayIndex: number;
      meridiem?: Meridiem;
      timeIndex?: number;
      onMobile?: boolean;
  }): Promise<void>;
}

const MobileCalendar = ({
  days,
  selectedWeek,
  currentWeek,
  selectedTimeIndex,
  selectedDayIndex,
  selectedMeridiem,
  changeTimeslot,
  nextWeek,
  prevWeek,
}: MobileCalendarProps) => {
  const currentDate = new Date()
  const currentDay = currentDate.getDate()
    .toString()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const currentMonth = months[currentDate.getMonth()]
  const handlers = useSwipeable({
    onSwipedLeft: () => nextWeek(),
    onSwipedRight: () => prevWeek(),
  })
  const swipeContainerRef = useRef<HTMLDivElement>()

  const refPassthrough = (el: HTMLDivElement) => {
    // Call useSwipeable ref prop with el
    handlers.ref(el)

    // Set swipeContainerRef el so you can access it yourself
    swipeContainerRef.current = el
  }

  return (
    <div
      {...handlers}
      ref={refPassthrough}
      style={{
        minHeight: theme.spacing(mobileCalendarHeight),
      }}
    >
      <Box display="flex" gridGap={4}>
        {days.map((day, i) => {
          const highlightedDay = day.date === currentDay && day.month === currentMonth
          return (
            <Button
              type="button"
              key={day.date}
              onClick={() => changeTimeslot({
                dayIndex: i,
                onMobile: true,
              })}
              onKeyDown={(event) => event.key === 'Enter' && changeTimeslot({
                dayIndex: i,
                onMobile: true,
              })}
              className={classNames(classes.weekDay, {
                selected: i === selectedDayIndex,
                beforeSelected: i === selectedDayIndex - 1,
                last: i === days.length - 1,
                currentDate: highlightedDay,
              })}
              disabled={!day.isAvailable && !day.hasSelectedTimeslot}
              disableElevation
            >
              <Typography variant="subtitle2">{day.dayOfWeek}</Typography>
              <Typography variant="body2">{day.date}</Typography>
            </Button>
          )
        })}
      </Box>
      {days.length > 0 && (
        <CalendarDay
          selectedTimeIndex={selectedTimeIndex}
          selectedDayIndex={selectedDayIndex}
          changeTimeslot={changeTimeslot}
          day={days[Number(selectedDayIndex)]}
          currentDayIndex={selectedDayIndex}
          selectedMeridiem={selectedMeridiem}
          selectedWeek={selectedWeek}
          currentWeek={currentWeek}
        />
      )}
    </div>
  )
}

export default MobileCalendar
