import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  itemWrapper: css`
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: ${commonStyling.roundedBorders.all};
    text-align: center;
    .MuiTypography-root {
      z-index: 1;
      position: relative;
    }
  `,
  overlayWrapper: css`
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${theme.palette.primary.main};
      opacity: .6;
      border-radius: ${commonStyling.roundedBorders.all};
    }
  `,
  icon: {
    close: css`
    &.MuiButtonBase-root {
      cursor: pointer;
      position: absolute;
      top: ${theme.spacing(1)}px;
      right: ${theme.spacing(1)}px;
      svg {
        color: ${theme.palette.primary.main};
      }
    }
  `,
  },
  boxesWrapper: css`
    color: ${theme.palette.primary.main};
    position: relative;
  `,
  slideContainer: css`
    position: absolute;
    top: 7px;
    bottom: ${theme.spacing(1)}px;
    z-index: 100;
    background: white;
    box-shadow: ${commonStyling.boxShadowDark};
    border-radius: ${commonStyling.roundedBorders.all};
    width: 100%;
    text-align: left;
    ${theme.breakpoints.up('md')} {
      min-height: 100%;
      top: 0;
      bottom: auto;
    }
  `,
  slideText: css`
    padding: ${theme.spacing(7, 5, 2)};
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4, 5, 0)};
    }
    .MuiTypography-body1 {
      > div > p {
        color: ${COLORS.bodyTextPrimary};
      }
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiTypography-h5 {
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightMedium!};
    }
  `,
  slideLinkWrapper: css`
    .MuiLink-root {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${theme.spacing(0, 5, 7)};
      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(0, 5, 4)};
      }
    }

    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
    }

    .MuiSvgIcon-root {
      padding-top: 6px;
      width: ${theme.spacing(2)}px;
      height: ${theme.spacing(2)}px;
    }
  `,
  overlapText: css`
    background: white;
    box-shadow: ${commonStyling.boxShadowDark};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(2, 3)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(3, 7)};
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightMedium!};
    }
  `,
  primaryTitle: css`
    color: ${theme.palette.primary.main};
  `,
  whiteTitle: css`
    color: white;
  `,
}

export default classes
