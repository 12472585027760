import { css } from 'linaria'
import theme, { COLORS } from '../../../styles/theme'

const classes = {
  overlap: css`
    z-index: 1001;
  `,
  imageContainer: css`
    max-width: 136px;
    min-width: 136px;
    max-height: 136px;
    display: flex;
    justify-content: center;

    ${theme.breakpoints.up('sm')} {
      max-width: 232px;
      min-width: 232px;
      max-height: 232px;
    }

    img {
      width: auto;
      height: 100%;
    }
  `,
  title: css`
    &.MuiTypography-root{
      margin: ${theme.spacing(3, 0, 2)};
      max-height: 30px
      
      ${theme.breakpoints.up('sm')} {
        font-size: 30px;
        max-height: 36px;
      }
    }
  `,
  subtitle: css`
   &.MuiTypography-root {
     text-overflow: ellipsis;
     -webkit-line-clamp: 5;
     overflow: hidden;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     color: ${COLORS.bodyTextPrimary};
     font-weight: normal;
     height: 129px;

     ${theme.breakpoints.up('sm')} {
       height: 75px;
      -webkit-line-clamp: 3;
     }
   }
  `,
  container: css`
    &.MuiGrid-container {
      height: 399px;
      background-color: white;

      ${theme.breakpoints.up('sm')} {
        height: 510px;
      }
    }
  `,
}

export default classes
