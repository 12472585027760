import React from 'react'
import {
  Box, Typography, TypographyVariant,
} from '@material-ui/core'
import classes from './WarningMessage.styles'
import {
  Error, Info,
} from '@material-ui/icons'

import { cx } from 'linaria'

export interface WarningMessageProps {
  color: WarningMessageColor;
  text: string;
  icon?: WarningMessageIcon;
  variant?: TypographyVariant;
}

export enum WarningMessageColor {
  yellow='yellow',
  yellowLight='yellowLight',
  orange='orange',
  blue='blue',
}

export enum WarningMessageIcon {
  exclamation='exclamation',
  informational= 'informational',
}

const WarningMessage = ({
  color,
  text,
  icon,
  variant = 'body2',
}: WarningMessageProps) => {
  return (
    <Box className={cx(classes.messageWrapper,
      color === WarningMessageColor.yellow && classes.yellow,
      color === WarningMessageColor.yellowLight && classes.yellowLight,
      color === WarningMessageColor.orange && classes.orange,
      color === WarningMessageColor.blue && classes.blue,
    )}
    >
      {icon === WarningMessageIcon.informational ? <Info fontSize="large"/> : <Error fontSize="large"/>}
      <Typography variant={variant} component="div">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: text }}/>
      </Typography>
    </Box>
  )
}

export default WarningMessage
