import React, {
  useState, useRef, useEffect,
} from 'react'
import {
  Typography, Grid, Tooltip, ClickAwayListener,
  Box,
  Hidden,
} from '@material-ui/core'
import {
  Close, ErrorOutline, InfoOutlined,
} from '@material-ui/icons'
import Countdown, {
  CountdownRenderProps, zeroPad,
} from 'react-countdown'
import { cx } from 'linaria'

import { JsonObject } from 'type-fest'
import classes from './MediaPageHeroRevamped.styles'
import useIntersectionObserver from '../../EnquiryBottomBar.js/EnquiryHook'
import { parseISO } from 'date-fns'

export interface VoucherProps extends JsonObject {
  readonly title: string;
  readonly body?: string;
  readonly start_date?: string;
  readonly end_date?: string;
  readonly colour: 'secondary' | 'warning' | 'yellow' | 'default';
  readonly hide_timer: boolean;
  readonly type: 'warning' | 'notification'| 'default';
  readonly voucher_code?: string;
}
const MediaPageFlags = ({
  vouchers,
  hideCountdown,
}: {vouchers: VoucherProps[]; hideCountdown?: boolean }) => {
  const [
    clickedVoucherIndex,
    setClickedVoucherIndex,
  ] = useState<number | null>()

  const [
    hideVoucherIndex,
    setHideVoucherIndex,
  ] = useState<number | null>()

  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const [isTooltipVisible] = useIntersectionObserver({
    elementRef: tooltipRef,
  })

  const handleTooltipClose = () => {
    setClickedVoucherIndex(null)
  }

  const handleTooltipOpen = (i: number) => {
    setClickedVoucherIndex(i)
  }

  useEffect(() => {
    if (!isTooltipVisible) {
      handleTooltipClose()
    }
  }, [isTooltipVisible])

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box mb={2}>
        <Grid
          container
          spacing={1}
          ref={tooltipRef}
          className={classes.flagsContainer}
        >
          {
            vouchers.map((voucher, i) => {
              const currentDate = new Date()
              if ((voucher.start_date && currentDate < parseISO(voucher.start_date)) || (voucher.end_date && currentDate > parseISO(voucher.end_date)) || (i === hideVoucherIndex)) {
                return null
              }

              const renderer = ({
                days,
                hours,
                minutes,
                seconds,
                completed,
              }: CountdownRenderProps) => {
                if (completed) {
                  setHideVoucherIndex(i)
                  return null
                }

                if (!voucher.hide_timer && hideCountdown) {
                  setHideVoucherIndex(i)
                  return null
                }

                return (
                  voucher.hide_timer ? null :
                    (
                      <div>
                        <span>&nbsp;{zeroPad(days)}
                          <span>d :</span>
                        </span>
                        <span> {zeroPad(hours)}
                          <span>h :</span>
                        </span>
                        <span> {zeroPad(minutes)}
                          <span>m :</span>
                        </span>
                        <span> {zeroPad(seconds)}
                          <span>s</span>
                        </span>
                      </div>
                    )
                )
              }

              if (i === hideVoucherIndex) {
                return null
              }

              const mobileTooltipBody = (
                <>
                  {voucher.body &&
                  <Typography dangerouslySetInnerHTML={{ __html: voucher.body }}/>}
                  <Hidden mdUp>
                    <Close onClick={handleTooltipClose} className={classes.tooltipCloseIcon}/>
                  </Hidden>
                </>
              )
              let icon
              if (voucher.type === 'warning') {
                icon = <ErrorOutline/>
              } else if (voucher.type === 'notification') {
                icon = <InfoOutlined/>
              } else {
                icon = null
              }

              const voucherText = (
                <div>
                  <Typography
                    variant="body2"
                    className={cx(classes.flagTitle)}
                    onClick={() => voucher.body && handleTooltipOpen(i)}
                  >
                    {voucher.title}
                    {voucher.start_date && (
                      <span>
                        <Countdown date={voucher.end_date} renderer={renderer}/>
                      </span>
                    )}
                    {icon}
                  </Typography>
                </div>
              )

              return (
                <Grid
                  item key={i}
                  className={cx(classes.flag, voucher.colour === 'default' && classes.flagDefault, voucher.colour === 'warning' && classes.flagWarning, voucher.colour === 'yellow' && classes.flagYellow)}
                >
                  <Hidden smDown>
                    <Tooltip
                      title={voucher.body && mobileTooltipBody} arrow
                      interactive
                      placement="bottom"
                      classes={{
                        arrow: classes.customArrow,
                        tooltip: classes.customTooltip,
                      }}
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                          },
                          applyStyle: {
                            enabled: true,
                            // This help popper to render the tooltip in a correct position
                            onLoad: true,
                          },
                        },
                      }}
                    >
                      {voucherText}
                    </Tooltip>
                  </Hidden>
                  <Hidden mdUp>
                    <Tooltip
                      title={voucher.body && mobileTooltipBody} arrow
                      interactive
                      placement="bottom"
                      open={Boolean(clickedVoucherIndex === i)}
                      classes={{
                        arrow: classes.customArrow,
                        tooltip: classes.customTooltip,
                      }}
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                          },
                          applyStyle: {
                            enabled: true,
                            // This help popper to render the tooltip in a correct position
                            onLoad: true,
                          },
                        },
                      }}
                    >
                      {voucherText}
                    </Tooltip>
                  </Hidden>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </ClickAwayListener>
  )
}

export default MediaPageFlags
