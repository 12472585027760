import React from 'react'
import {
  Typography,
  Grid,
} from '@material-ui/core'
import classes from './DropdownWithImage.styles'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import Dropdown from './Dropdown'

export interface FAQ {
  title: string;
  body: string;
}

export interface DropdownWithImageProps {
  title: string;
  faqs: FAQ[];
  image: string;
}

const DropdownWithImage = ({
  title,
  faqs,
  image,
}: DropdownWithImageProps) => {
  return (
    <SectionWithMargin>
      <Grid container className={classes.mainContainer} spacing={5}>
        <Grid item xs={12} md={6} className={classes.dropdownContainer}>
          <Typography variant="h3" color="primary">{title}</Typography>
          {faqs.map(({
            title, body,
          }, index) => (
            <Dropdown key={index} title={title} body={body}/>
          ))}
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <img src={image} alt=""/>
        </Grid>
      </Grid>
    </SectionWithMargin>
  )
}

export default DropdownWithImage
