import moment from 'moment-business-days'

export function getDifferenceInBusinessDays(startDate: Date, endDate: Date): number {
  moment.updateLocale('us', {
    workingWeekdays: [
      1, // Monday
      2, // Tuesday
      3, // Wednesday
      4, // Thursday
      5, // Friday
      6, // Saturday
    ],
  })

  const totalBusinessDays = moment(endDate, 'dd-mm-yyyy')
    .businessDiff(moment(startDate, 'dd-mm-yyyy'))

  const totalBusinessDaysWithoutToday = totalBusinessDays - 1

  return totalBusinessDaysWithoutToday
}
