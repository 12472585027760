/* eslint complexity: ["error", 100] */
import React, { useState } from 'react'
import {
  Box, Link, Typography, useMediaQuery,
} from '@material-ui/core'
import {
  HobsProduct,
} from '../../api/Packages'
import TypographyCMS, {
  createTypographyProps, TypographyProps,
} from '../Typography/Typography'
import MediaImage, {
  createMediaImageProps, MediaImageProps,
} from '../Media/MediaImage'
import { ProductStyles } from './Products.styles'
import theme, { BOX } from '../../styles/theme'
import {
  getProductTerm, getSpeed,
} from '../../utils/getProductDetails'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import ModalBox from '../ModalBox/ModalBox'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import {
  ButtonPropsExtended,
  PackageProps,
} from '../Packages/Package'
import Features from '../Packages/Features'
import Price from '../Packages/Price'
import CallsRequirements from './CallsRequirements'
import { HobsServiceType } from '../../api/Products'
import {
  checkIfVasExists,
  checkIfVoiceExists, checkIfWierExists,
} from '../Bundles/Bundle'
import PackageCTA, { PackageCTAProps } from '../Packages/PackageCTA'
import { withStyles } from '@material-ui/styles'
import { LinkProps } from '../Packages/Packages'
import Wrapper from '../Wrapper/Wrapper'

export interface ProductProps extends HobsProduct {
  anchor?: string;
  bullets?: string;
  button?: ButtonPropsExtended;
  brand?: string;
  button_secondary?: ButtonPropsExtended;
  image?: MediaImageProps;
  display_name?: string;
  display_image?: MediaImageProps;
  title?: TypographyProps;
  subtitle?: TypographyProps;
  more_info_modal?: {
    image?: MediaImageProps;
    title?: string;
    subtitle?: string;
    bullets?: string;
    more_link?: LinkProps;
    footnote?: string;
  };
  selected?: boolean;
  product: ProductProps;
  on_click_package?: Function;
  usage_description?: string;
  classes?: any;
  hide_for_box?: boolean;
  setDisableButton?: Function;
  disabledButton?: boolean;
  price_guidance?: string;
  contract_length?: number;
}

const handleError = (product: ProductProps, broadband: PackageProps) => {
  const speedString = getSpeed(broadband, 'DOWNLOAD_SPEED')
  const speed = Number(speedString?.split('MBPS')[0])
  if ((product.service_type === HobsServiceType.WIER) && speed < 150 && speed) {
    return 'Available with speeds of 150 Mbps and above.'
  }

  return ''
}

const Product = withStyles(ProductStyles)(({
  selected,
  on_click_package,
  product,
  bullets,
  title,
  subtitle,
  image,
  button,
  button_secondary,
  classes,
  display_image,
  display_name,
  setDisableButton,
  disabledButton,
  price_guidance,
}: ProductProps) => {
  const [
    modalVisible,
    setModalVisible,
  ] = useState<string>()

  const {
    options,
  } = useOrderContext()

  const {
    broadband,
    voice,
    bundle,
    channel,
  } = options

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const wierExists = bundle && product.service_type === 'wier' && checkIfWierExists(bundle)
  const voiceExists = bundle && product.service_type === 'voice' && checkIfVoiceExists(bundle)
  const vasExists = bundle && product.service_type === 'vas' && checkIfVasExists(bundle)
  const sameContractTerm = getProductTerm(options.broadband) === getProductTerm(product) || getProductTerm(options.bundle) === getProductTerm(product)
  const disabledPrimaryCTA = (!broadband && !bundle) || !sameContractTerm || wierExists || voiceExists || vasExists || Boolean(handleError(product, broadband!)) || disabledButton
  const checkCallsRequirements = (product.service_type === HobsServiceType.VOICE) && voice && selected
  const priceGuidance = price_guidance || 'month*'

  const renderCardContent = (product: ProductProps, selected?: boolean, bullets?: string) => {
    const renderCTA = (product: any) => {
      const handleProductClick = async () => {
        setModalVisible(undefined)

        if (setDisableButton) {
          setDisableButton(true)
        }

        if (!options.broadband && !options.bundle) {
          return null
        }

        if (on_click_package) {
          return on_click_package(product, channel)
        }
      }

      const openModal = () => {
        if (modalVisible !== product.productOfferingId) {
          return setModalVisible(product?.productOfferingId)
        }

        return setModalVisible(undefined)
      }

      const CTAParams = (mt: number, secondary: boolean): PackageCTAProps => ({
        button,
        id: product?.productOfferingId,
        mt,
        disabled: disabledPrimaryCTA,
        selected: selected!,
        handleClick: handleProductClick,
        handleSecondaryClick: openModal,
        ...(secondary && { button_secondary }),
      })
      return (
        <Box
          width={{
            xs: '100%',
            md: 240,
          }}
        >
          <PackageCTA {...CTAParams(0, true)}/>
          {product?.more_info_modal &&
            <ModalBox
              visible={modalVisible === product?.productOfferingId}
              sync={openModal}
              maxWidth="720px"
            >
              <div>
                {product?.more_info_modal?.image && Object.keys(product?.more_info_modal?.image).length > 0 ?
                  <MediaImage
                    center
                    {...createMediaImageProps(product?.more_info_modal?.image, 640, 480)}
                  /> : product?.more_info_modal?.title &&
                  <Typography color="primary" variant="h2" align="center">{product?.more_info_modal.title}</Typography>}
                {product?.more_info_modal?.subtitle &&
                  <TypographyCMS text={product?.more_info_modal.subtitle} color="primary" variant="body1" margin_top={3}/>}
                {product?.more_info_modal?.bullets &&
                  <Features
                    simple_bullets={product?.more_info_modal?.bullets}
                    mt={3}
                    bullet_gap={1}
                    gap={1}
                    bullet_color="primary"
                  />}
                <Box maxWidth="240px" mx="auto" mt={2}>
                  <Price product={product} price_guidance={priceGuidance} term={getProductTerm(product)} showTerm/>
                  <PackageCTA {...CTAParams(2, false)}/>
                </Box>
                {product?.more_info_modal?.more_link &&
                  <Box textAlign="center" mt={2} pb={4}>
                    <Link color="primary" variant="body1" href={product?.more_info_modal?.more_link?.href} underline="always">{product?.more_info_modal?.more_link?.label}</Link>
                  </Box>}
                {product?.more_info_modal?.footnote &&
                <Box pb={4}>
                  <TypographyCMS text={product?.more_info_modal?.footnote} variant="caption"/>
                </Box>}
              </div>
            </ModalBox>}
        </Box>
      )
    }

    return (
      <div className={classes.layout}>
        {(display_name || display_image) &&
          <div className={classes.heading}>
            {display_image && display_image?.src &&
              <MediaImage {...createMediaImageProps(display_image, 330, 50)}/>}
            {display_name &&
              <Typography variant="h4" color="primary">
                { display_name }
              </Typography>}
          </div>}
        <div className={classes.cta}>
          <Price product={product} price_guidance={priceGuidance} term={getProductTerm(product)} showTerm/>
          {!isMobile && button && button.text &&
            <Box mt={2}>
              { renderCTA(product) }
            </Box>}
        </div>
        <Box
          className={classes.features}
          mt={2}
          mb={0}
          pl={0}
          gridArea="bullets"
        >
          <Features
            simple_bullets={bullets}
            bullet_gap={1}
            gap={1}
            bullet_color="primary"
          />
        </Box>
        {checkCallsRequirements && <CallsRequirements/>}
        {isMobile && button && button.text &&
          <Box mt={3}>
            { renderCTA(product) }
          </Box>}
      </div>
    )
  }

  return (
    <Wrapper id={product?.anchor} verticalPaddings gradient>
      {title && <TypographyCMS {...createTypographyProps(title)}/>}
      {subtitle && <TypographyCMS {...createTypographyProps(subtitle)}/>}
      <Box
        {...BOX}
        className={classes.product}
        id={product?.productOfferingId}
      >
        {image && image.src &&
          <Box
            className={classes.productImage}
            pr={image?.cover ? 0 : '20px'}
          >
            <MediaImage {...createMediaImageProps(image, 180, 180)}/>
          </Box>}
        { renderCardContent(product, selected, bullets) }
      </Box>
      {broadband && handleError(product, broadband) &&
        <ErrorMessage
          showErrorMessage
          message={handleError(product, broadband)}
        />}
    </Wrapper>
  )
})

export default Product

