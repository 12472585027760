/* eslint complexity: ["error", 100] */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react'
import {
  useForm, FormContext,
} from 'react-hook-form'
import {
  withScope, captureException,
  Severity,
} from '@sentry/browser'
import {
  Box,
  Button,
  Checkbox,
  Container, FormControlLabel, Grid, Typography,
  CircularProgress,
} from '@material-ui/core'
import { classes } from './InstallationSection.styles'
import {
  ChangeCollapsible,
} from '../CheckoutStep'
import Calendar from '../../../../Calendar/Calendar'
import {
  TimeslotData, isTimeslotError, Timeslot,
} from '../../../../../api/BookingCalendar'
// Old GET for timeslots
// import createCFOApiClient from '../../../../../api/CFO/CFOAuth'
import createCRMApiClient, { CRMApiClient } from '../../../../../api/CRMApi'
// Old GET for timeslots
// import { AppointmentProps } from '../../../../../api/CFO/Appointments'
import logger from '../../../../../utils/logger'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import Dropdown from '../../../../DropdownWithImage/Dropdown'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import {
  OrderOptions,
  useOrderContext,
} from '../../../OrderContext'
import OrderError from '../OrderError/OrderError'
import capitalize from 'lodash.capitalize'
import { formatTimeForTimeslotData } from '../../../../Calendar/calendarUtils'
import { format } from 'date-fns'
import { getDifferenceInBusinessDays } from '../../../../../utils/getDifferenceInBusinessDays'
import { getMonthlyCost } from '../../../../../utils/getProductDetails'
import PortingNumber from '../../../OrderManagement/OrderMenu/PortingNumber/PortingNumber'
import { ProductToCartRequest } from '../../../../../api/Cart'
import { useRouter } from 'next/router'
import { checkIfVoiceExists } from '../../../../Bundles/Bundle'
import theme from '../../../../../styles/theme'
import InstallationSectionDCMS from './InstallationSectionDCMS'
import { SettingsProps } from '../../../../App/App'
import { ProductProps } from '../../../../Products/Product'

function NextButtonText({
  options, dcms,
}: { options: OrderOptions; dcms?: boolean }) {
  const {
    broadband, voice, tv, wier, vas, discount, bundle,
  } = options

  if (dcms) {
    return (<Typography noWrap>Next</Typography>)
  }

  if (broadband && getMonthlyCost({
    broadband,
    voice,
    tv,
    wier,
    vas,
    bundle,
    discount,
  }) === 0) {
    return (<Typography noWrap>Confirm and proceed to order summary</Typography>)
  }

  return (<Typography noWrap>Confirm and proceed to payment</Typography>)
}

export default function InstallationSection({
  changeCollapsible,
  settings,
}: { changeCollapsible: ChangeCollapsible; settings: SettingsProps}) {
  const { premise: fullPremise } = useContext(PremiseContext)

  const {
    options, setOptions,
  } = useOrderContext()

  const methods = useForm<FormData>()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  // Booking installation later functionality---Start

  const [
    checked,
    setChecked,
  ] = useState(false)

  const [
    showCheckbox,
    setShowCheckbox,
  ] = useState(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setHeldTimeslot(null)
  }

  const [
    hideSkipAppointment,
    setHideSkipAppointment,
  ] = useState<boolean>(false)

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(true)

  useEffect(() => {
    if (checked) {
      marketingEvents.simpleEvent('no_slot_click', options)
      setOptions({
        ...options,
        countDownTimerStart: null,
        timeslot: null,
        timeslotError: '',
        reservationId: '',
      })
    }
  }, [checked])

  const passInstallation = () => {
    setChecked(true)
    setHeldTimeslot(null)
  }
  // Booking installation later functionality---finish

  const {
    timeslotError, timeslot, data, broadband, bundle, wier, channel, dcmsEligibility, butterCheckoutContent,
  } = options

  const productSpecItems: any = broadband || bundle
  const speedProduct = productSpecItems?.productOffering?.find(
    (item: any) => item.productType === 'SPEED',
  )
  const productSpec = speedProduct?.productSpec[0]
  const downloadSpeedObject = productSpec?.productSpecCharacteristic?.find(
    (item: any) => item.name === 'DOWNLOAD_SPEED',
  )
  const downloadSpeedValues = downloadSpeedObject?.productSpecCharacteristicValue
  const downloadSpeedValue = downloadSpeedValues?.find(
    (item: any) => item.valueType === 'String' && item.default
  )?.value

  const broadbandProduct = downloadSpeedValue && downloadSpeedValue

  const [
    heldTimeslot,
    setHeldTimeslot,
  ] = useState<TimeslotData | Timeslot | null>(timeslot)

  const [
    DCMSCondition,
    setDCMSCondition,
  ] = useState<boolean>(false)

  const router = useRouter()

  const {
    email, firstName, lastName, phone,
  } = data

  const serviceType = wier && wier?.productGroup ? 'Premium' : 'Standard'
  const address = data.address || fullPremise?.address

  useEffect(() => {
    if (dcmsEligibility) {
      setDCMSCondition(true)
      passInstallation()
    } else {
      setDCMSCondition(false)
    }
  }, [dcmsEligibility])

  useEffect(() => {
    const sendTimeslotWarning = () => {
      if (!timeslot || typeof timeslot === 'undefined') {
        withScope((scope) => {
          scope.setLevel(Severity.Warning)
          captureException('No timeslot found!')
        })
      }
    }

    sendTimeslotWarning()
  }, [timeslot])

  useEffect(() => {
    const saveEarliestTimeslot = () => {
      setOptions({
        ...options,
        earliestTimeslot: heldTimeslot as TimeslotData,
      })
    }

    saveEarliestTimeslot()
  }, [heldTimeslot])

  const handleZinierTimeslotChange = (timeslot: Timeslot) => {
    if (timeslotError) {
      setOptions({
        ...options,
        timeslotError: '',
      })
    }

    setHeldTimeslot(timeslot)
  }

  const reserveZinierTimeslot = async (client: CRMApiClient) => {
    const rawTimeslot: any | null = await (async () => {
      try {
        return await client.bookingCalendar.reserveTimeslot(
          heldTimeslot as Timeslot,
          fullPremise!,
          broadbandProduct,
          serviceType,
        )
      } catch (err) { }

      return null
    })()

    return rawTimeslot.error ?
      rawTimeslot.error :
      rawTimeslot
  }

  const onSubmit = handleSubmit(async () => {
    const client = createCRMApiClient()

    const ButtonSubmitText = NextButtonText({ options }).props.children
    marketingEvents.cta({
      name: ButtonSubmitText,
      router,
      options,
    })

    let refreshedTimeslot
    let reservationID
    if (heldTimeslot) {
      const getTime = (date: string) => date.split('T')[1].split(':00.')[0]
      const getFullTime = getTime(heldTimeslot.startDateTime) + ' - ' + getTime(heldTimeslot.endDateTime)
      marketingEvents.selectInstallation('select_installation', options, heldTimeslot?.date!, getFullTime)
      const rawRefreshedTimeslot = await reserveZinierTimeslot(client)
      reservationID = rawRefreshedTimeslot?.reservationId
      refreshedTimeslot = rawRefreshedTimeslot?.appointmentWindowData && rawRefreshedTimeslot?.appointmentWindowData[0]

      if (isTimeslotError(refreshedTimeslot)) {
        const errorMsg = 'This time slot is no longer available, please select another.'
        logger.error(refreshedTimeslot.message, refreshedTimeslot.error)
        marketingEvents.error(options, 'ERR-INSTALLATION-1', 'Installation section error', errorMsg)
        setOptions({
          ...options,
          timeslotError: errorMsg,
        })

        return
      }

      const countDownTimerStart = new Date()
        .getTime()

      setOptions({
        ...options,
        timeslot: refreshedTimeslot,
        reservationId: reservationID || '',
        timeslotError: '',
        countDownTimerStart,
      })
    }

    // <Porting number and current provider >
    // We should remove and add voice again.
    // Because we have to send somehow portedNumber and provider. But HOBS don't want to change their API's.
    if (options.hasPortedNumber && voiceExists) {
      await client.cart.removeProductFromCart(
        options.cartInstanceIdentifier,
        options.voice ? options.voice.productOfferingId : options.bundle.productOfferingId,
        channel,
      )
      const reqObj: ProductToCartRequest = {
        channel,
        cartInstanceIdentifier: options.cartInstanceIdentifier,
        phoneNumber: options?.portedNumber,
        provider: options?.previousProvider,
        isVoiceProduct: options.voice?.productOfferingId! && true,
        isBundledProduct: options.bundle?.productOfferingId! && true,
      }
      await client.cart.addPreConfiguredProductToCart(
        options.voice ? options.voice : options.bundle,
        reqObj,
      )
    }
    // <Porting number and current provider />

    marketingEvents.onCheckoutOption(4)
    marketingEvents.onCheckoutSection('payment')
    changeCollapsible('checkout-payment')
  })

  function useTrackDaysUntilTimeslot() {
    const isCalledRef = useRef(false)

    useEffect(() => {
      if (heldTimeslot && !isCalledRef.current) {
        isCalledRef.current = true
        const currentDate = new Date()
        const selectedDate = new Date(heldTimeslot.startDateTime)
        const numberOfBusinessDays = getDifferenceInBusinessDays(currentDate, selectedDate)
        marketingEvents.trackDaysUntilTimeslot(numberOfBusinessDays)
      }
    }, [heldTimeslot])
  }

  useTrackDaysUntilTimeslot()

  const selectedTimeslotDate = heldTimeslot && new Date(heldTimeslot.startDateTime)
  const selectedInstallationTimeZinier = heldTimeslot && formatTimeForTimeslotData(heldTimeslot)

  const renderCalendar = () => {
    if (fullPremise) {
      return (
        <Calendar
          fullPremise={fullPremise}
          onTimeslotChange={(t: Timeslot) => {
            handleZinierTimeslotChange(t)
          }}
          broadbandProduct={broadbandProduct}
        />
      )
    }

    return null
  }

  useEffect(() => {
    if (checked || selectedTimeslotDate || timeslot) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    checked,
    selectedTimeslotDate,
    timeslot,
  ])
  const packageComponents = settings.components[1]
  const bundleComponents = settings.components[2]
  const currentBundle = options.bundle?.name
  const currentBroadband = options.broadband?.name
  const checkNameInComponents = (
    {
      bundleComponents, packageComponents,
    }: { bundleComponents?: ProductProps; packageComponents?: ProductProps },
    currentProduct?: string,
  ): boolean => {
    const getNames = (components: ProductProps) => {
      return Object.values(components)
        .map((component) => component.name)
    }

    if (bundleComponents && packageComponents && currentProduct) {
      const bundleNames = getNames(bundleComponents)
      const packageNames = getNames(packageComponents)

      return bundleNames.includes(currentProduct) || packageNames.includes(currentProduct)
    }

    return false
  }

  const skipCondition = checkNameInComponents({
    bundleComponents,
    packageComponents,
  }, currentBundle || currentBroadband)

  useEffect(() => {
    if (butterCheckoutContent) {
      setHideSkipAppointment(JSON.parse(butterCheckoutContent).installation.hideSkipAppointment)
    }
  }, [butterCheckoutContent])

  useEffect(() => {
    if (selectedTimeslotDate || timeslot || skipCondition || hideSkipAppointment) {
      setShowCheckbox(false)
    }
  }, [
    timeslot,
    selectedTimeslotDate,
    currentBundle,
    currentBroadband,
    hideSkipAppointment,
  ])

  const voiceExists = options.voice || checkIfVoiceExists(bundle)

  return (
    <FormContext {...methods}>
      <Container>
        <form onSubmit={onSubmit}>
          <Box pt={6} pb={6}>
            <Grid direction="row" justify="space-between" spacing={2} container item>
              <Grid xs={12} sm={6} container item>
                <Grid className={classes.contactCard} direction="column" justify="flex-start" item container>
                  <Box mb={2}>
                    <Typography variant="h4" color="primary">Address</Typography>
                  </Box>
                  {address && formatInstallationAddress(address)
                    .split(',')
                    .map(line => <Typography key={line} variant="body1" color="primary">{line}</Typography>)}
                </Grid>
              </Grid>
              <Grid xs={12} sm={6} container item>
                <Grid className={classes.contactCard} direction="column" justify="flex-start" container item>
                  <Box mb={2}>
                    <Typography variant="h4" color="primary">Contact Details</Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{
                      display: 'flex',
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box minWidth={theme.spacing(9)}>Name:</Box>
                    <Box flexGrow={1}>{firstName} {lastName}</Box>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{
                      display: 'flex',
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box minWidth={theme.spacing(9)}>Email:</Box>
                    <Box
                      flexGrow={1}
                      style={{
                        overflowWrap: 'anywhere',
                      }}
                    >
                      {email}
                    </Box>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{
                      display: 'flex',
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box minWidth={theme.spacing(9)}>Mobile:</Box>
                    <Box flexGrow={1}>{phone}</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {DCMSCondition ?
              <InstallationSectionDCMS/> :
              <>
                <Box mt={4}>
                  <Grid>
                    <Box mb={1}>
                      <Typography variant="h4" color="primary">Installation Date & Time</Typography>
                    </Box>
                    <Typography variant="body1" color="primary">Choose a date and time slot for our engineer to visit. If something comes up, no sweat, you can rearrange online later. The engineer may arrive at any time during your chosen time slot, but will be there for less than two hours once they arrive.</Typography>
                    <Box mt={1}>
                      <Dropdown title="Read more here" body="Be aware that the engineer will need to drill a small hole through your wall to bring the fibre optic cable into your home. They’ll agree the positioning with you and clean up before they leave."/>
                    </Box>
                  </Grid>
                </Box>
                <Box pt={3}>
                  {timeslotError && <OrderError error={timeslotError}/>}
                  {fullPremise ?
                    renderCalendar() :
                    <Grid justify="center" container><Typography color="error">Please select an address before booking a timeslot.</Typography></Grid>}
                </Box>
                {heldTimeslot && selectedTimeslotDate &&
                  <Box mt={3} className={classes.selectedTimeslot}>
                    <Typography variant="body1" align="left">
                      <b>Selected installation date:</b>
                      <span> {format(selectedTimeslotDate, 'dd/MM/yyyy')} {' - '} {selectedInstallationTimeZinier}</span>
                    </Typography>
                  </Box>}
                {skipCondition && !selectedTimeslotDate &&
                  <Box py={2} className={classes.skipMessage}>
                    <Typography variant="caption">
                      If you don&apos;t see any slots, it might be because 3 Gbps is not available at this address, please choose another speed or call <a href="tel:0808 196 6262" rel="follow">0808 196 6262</a> for more info.
                    </Typography>
                  </Box>}
                {showCheckbox &&
                  <Box pt={3} className={classes.checkboxesContainer}>
                    <FormControlLabel
                      aria-labelledby="I'll book an installation slot later"
                      color="primary"
                      style={{ marginLeft: -5 }}
                      label={<Typography style={{ marginLeft: 5 }} variant="caption" component="p" color="primary">I’ll book my installation appointment later.</Typography>}
                      control={
                        <Checkbox
                          color="primary" checked={checked}
                          onChange={handleChange}
                          size="small"
                          style={{
                            width: 20,
                            height: 20,
                            padding: 3,
                          }}
                        />
                      }
                    />
                    <Typography variant="caption" component="p" color="primary">
                      Don&apos;t worry if you don&apos;t see any convenient slots right now. You can place your order and book your appointment later.{' '}
                      You will find the link to book the slot in your order confirmation email.
                    </Typography>
                  </Box>}
              </>}
            {voiceExists && !options.oneTouchSwitching &&
              <PortingNumber/>}
            <Box mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                id="step-2"
                disabled={disabled}
              >
                <NextButtonText options={options} dcms={DCMSCondition}/>
                {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </FormContext>
  )
}

export const formatInstallationAddress = (address: string) => {
  const splitAddress = address.split(/(?<postcode>,[^,]*$)/)

  return (
    splitAddress[0].replace(/\w+/g, capitalize)
      .replace(/(?<number>\d),/, '$1') +
    splitAddress[1]
  )
}
