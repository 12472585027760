import { AxiosInstance } from 'axios'
import { SearchPremise } from './Addresses'
import { Product } from '../utils/commonInterfaces'

export default function Leads(client: AxiosInstance) {
  async function register(lead: OnlineLead) {
    try {
      const { data } = await client.post<{status: 'Lead Processed'}>('v1/workflow/online-lead', {
        ...lead,
        requestSource: 'WEBSITE',
      })

      return data
    } catch (err) {
      return {
        error: err,
        message: 'failed to submit lead',
      }
    }
  }

  return {
    register,
  }
}

interface BaseLead {
  premise?: Partial<SearchPremise>;
  customer?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    emailValidated?: boolean;
    phone?: string;
    type: 'PERSON' | 'PARTNER' | 'LANDLORD';
    jobTitle?: string;
  };
  company?: {
    name: string;
    employees: string;
  };
  landlord?: unknown;
  premiseType: 'BUSINESS' | 'RESIDENTIAL';
  products: Product[];
  communication?: {
    authPrivacy: boolean;
    authTerms: boolean;
    authMarketing: boolean;
    authMarketingEmail: boolean;
    authMarketingSMS: boolean;
    authMarketingPhone: boolean;
    authMarketingPost: boolean;
  };
  contractEndDate?: string | null;
}

interface BusinessLead extends BaseLead {
  company: {
    name: string;
    employees: string;
  };
  premiseType: 'BUSINESS';
}

interface ResidentialLead extends Omit<BaseLead, 'company'> {
  premiseType: 'RESIDENTIAL';
  premise: Partial<SearchPremise>;
  communication: {
    referralSource?: string;
    authPrivacy: boolean;
    authTerms: boolean;
    authMarketing: boolean;
    authMarketingEmail: boolean;
    authMarketingSMS: boolean;
    authMarketingPhone: boolean;
    authMarketingPost: boolean;
  };
}

type OnlineLead = BusinessLead | ResidentialLead

