import { AxiosInstance } from 'axios'
import env from '../../utils/env'
export interface PostToken {
  access_token: string;
  accessTokenExpires: string | number;
  error?: string;
  message?: string;
}

export default function GetToken(client: AxiosInstance) {
  async function getAccessToken() {
    try {
      const urlencoded = new URLSearchParams()
      urlencoded.append('grant_type', 'client_credentials')
      urlencoded.append('scope', env.CFOAuthPostClientScope)
      urlencoded.append('client_id', env.CFOAuthPostClientId)
      urlencoded.append('client_secret', env.CFOAuthPostClientSecret)
      const { data } = await client.post<{ access_token: string; expires_in: string }>('cfoauthapi', urlencoded)
      const expiredTime = Number(data?.expires_in)
      return {
        access_token: data?.access_token,
        accessTokenExpires: expiredTime,
        error: '',
        message: 'success',
      }
    } catch (err) {
      return {
        access_token: 'undefined',
        accessTokenExpires: '',
        error: err,
        message: 'failed to submit lead',
      }
    }
  }

  return {
    getAccessToken,
  }
}
