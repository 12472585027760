import { AxiosInstance } from 'axios'
import { v4 as uuidv4 } from 'uuid'

export interface OTSFormProps {
  lastName: string;
  addressLines: string[];
  postTown: string;
  postCode: string;
  partnerID: string;
  account?: string;
  uprn?: string;
  phone?: boolean;
  landlineNumber?: string;
  provideNewNumberOption?: boolean;
}

interface BaseService {
  serviceType: string;
  action: string;
}

interface IASService extends BaseService {
  serviceType: 'IAS';
}

interface NBICSService extends BaseService {
  serviceType: 'NBICS';
}

interface NBICSServicePorting extends NBICSService {
  serviceIdentifier: string;
}

type Service = IASService | NBICSService | NBICSServicePorting;

export default function OTSForm(client: AxiosInstance) {
  async function post(props: OTSFormProps) {
    const {
      lastName,
      addressLines,
      postTown,
      postCode,
      partnerID,
      uprn,
      phone,
      landlineNumber,
      provideNewNumberOption,
      account,
    } = props

    // Generate a new session ID (correlationID)
    const sessionId = uuidv4()
    const correlationID = `CFL_OTS_${sessionId}`

    // Store the correlationID in session storage with the key OTSCorrelationID
    sessionStorage.setItem('OTSCorrelationID', correlationID)

    let servicesBB: Service[] = [{
      serviceType: 'IAS',
      action: 'cease',
    }]

    if (phone && !provideNewNumberOption && landlineNumber) {
      servicesBB = [
        {
          serviceType: 'IAS',
          action: 'cease',
        },
        {
          serviceType: 'NBICS',
          serviceIdentifier: landlineNumber,
          action: 'port',
        },
      ]
    }

    if (phone && provideNewNumberOption) {
      servicesBB = [
        {
          serviceType: 'IAS',
          action: 'cease',
        },
        {
          serviceType: 'NBICS',
          action: 'cease',
        },
      ]
    }

    try {
      const payload = {
        envelope: {
          source: {
            type: 'RCPID',
            identity: 'RCKW',
            correlationID, // Using the stored correlationID
          },
          destination: {
            type: 'RCPID',
            identity: partnerID,
          },
          routingID: 'residentialSwitchMatchRequest',
        },
        residentialSwitchMatchRequest: {
          grcpBrandName: 'Community Fibre Ltd',
          name: lastName,
          account,
          address: {
            uprn,
            addressLines,
            postTown,
            postCode,
          },
          services: servicesBB,
        },
      }

      const { data } = await client.post('v1/0.0/match', payload)
      return data
    } catch (err) {
      return { error: err }
    }
  }

  return {
    post,
  }
}
