import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme from '../../styles/theme'

const classes = {
  contactWrapper: css`
    padding-top: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(3)}px;
    margin-left: ${theme.spacing(3)}px;
  `,
  container: css`
    width: auto !important;
    text-align: right;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      .MuiBox-root{
        margin: 0;
      }
    }
  `,
  icon: css`
    height: 10px;
    margin-right: 5px !important;
    
    ${theme.breakpoints.up('sm')} {
      height: 14px !important;
      margin-right: ${theme.spacing(1)}px !important;
    }
    ${theme.breakpoints.up('md')} {
      position: relative;
      margin-right: 0;
      left: -6px;
    }
  `,
  phone: css`
    text-decoration: underline !important;
    text-decoration-color: ${theme.palette.secondary.main} !important;
    white-space: nowrap;

    .MuiTypography-body1 {
      font-size: ${fontSizes.body3};

      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.body2};
      }
      ${theme.breakpoints.up('md')} {
        font-size: 20px;
      }
    }
  `,
  helpText: css`
  .MuiTypography-body1 {
    font-size: ${fontSizes.body3};

    ${theme.breakpoints.up('md')} {
      font-size: 20px;
    }
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  }
  `,
  mobileWrapper: css`
    align-items: flex-end !important;
    flex-direction: column;
    position: relative;
    right: -5px;
  `,
  callMeBack: css`
    padding: 0px !important;

    &.MuiButton-root{
      &:hover{
        background: none;
      }
    }
    .MuiTypography-root {
      text-decoration: underline;
      white-space: nowrap;
      &:hover{
        text-decoration: none;
      }
    }
    .MuiTypography-body1 {
      font-size: ${fontSizes.body2} !important;
      ${theme.breakpoints.up('md')} {
        font-size: 20px !important;
      }
    }
  `,
  modal: css`
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 99999 !important;
  `,
  body: css`
    margin: auto;
    outline: none;
  `,
  checker: css`
    color: ${theme.palette.primary.main};
    background-color: white;
    border-radius: ${commonStyling.roundedBorders.all};
    border: 1px solid ${theme.palette.error.main};
    position: absolute;
    top: 2%;
    left: 4%;
    right: 4%;

    ${theme.breakpoints.up('md')} {
      left: 50%;
      right: 0;
      transform: translate(-50%, 35%);
    }

  .close-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: ${theme.palette.error.main};
    margin-left: auto;
    padding-right: ${theme.spacing(0.5)}px;

    .MuiSvgIcon-root {
      font-size: ${fontSizes.h2};
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
      margin-bottom: 3px;
    }
    ${theme.breakpoints.up('md')} {
      right: 25px;
    }
  }
`,
  checkerInner: css`
    padding: 0;

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px;
    }

    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  `,
  text: css`
    width: 100%;
    .MuiTypography-body1 {
      margin: 0 auto;
      margin-top: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!};
      padding: 0 ${theme.spacing(1)}px;

      ${theme.breakpoints.up('lg')} {
        padding: 0 ${theme.spacing(15)}px;
      }
    }
  `,
  phoneWrapper: css`
    display: flex;
    align-items: center;
    ${theme.breakpoints.up('md')} {
      position: relative;
      margin: 0 ${theme.spacing(1)}px;
    }
  `,
}

export default classes
