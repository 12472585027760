import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  overlap: css`
    background: #fff;
    position: absolute;
    z-index: 3;
    width: 100%;
    + section[class*=section] {
      margin-top: 0 !important;
      .MuiContainer-root.MuiContainer-maxWidthLg {
        padding-top: 160px;
        ${theme.breakpoints.down('md')} {
          padding-top: 130px;
        }
      }
    }
  `,
  inverted: css`
    background: none;
    color: #fff;
    [class*=headerTop] {
      box-shadow: 0 -2px 0 rgba(0,0,0,0.1);
      .MuiTab-root {
        &:before {
          background-color: #fff;
        }
        &:hover {
          background-color: rgba(255,255,255,0.1);
        }
        &.Mui-selected {
          color: #fff;
        }
      }
    }
    .MuiContainer-maxWidthLg {
      button[aria-selected=false], [class*=sharedItems] .MuiTypography-root {
        color: #fff;
      }
    }
    header.MuiAppBar-colorPrimary {
      background: none;
      border-top: 1px solid rgba(255,255,255, 0.1);
      .MuiGrid-container>ul:not([class*=submenu])>li>a>p {
        color: #fff;
      }
    }
  `,
}

export default classes
