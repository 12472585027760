import React from 'react'
import {
  Box,
  Grid, Typography,
} from '@material-ui/core'
import WrapperWithSection from '../../../../Wrapper/WrapperWithSection'
import { css } from 'linaria'
import fontSizes from '../../../../../styles/fontSizes'
import theme from '../../../../../styles/theme'

const classes = {
  portingNumber: css`
    max-width: ${theme.spacing(80)}px;
    text-align: center;
    h1 {
      font-size: ${fontSizes.h2};
    }
`,
}

const PortingNumberOMP = () => {
  return (
    <WrapperWithSection>
      <Grid container justify="center">
        <Box mt={4} mb={4} className={classes.portingNumber}>
          <Box mb={1}>
            <Typography variant="h1" color="primary">I want to keep my number</Typography>
          </Box>
          <Typography variant="body1" color="primary">Hi there! If you’d like to keep your existing phone number, please give us a call on 0800 082 0770 (Monday to Friday, 8am - 9pm and weekends from 9am - 7pm).</Typography>
        </Box>
      </Grid>
    </WrapperWithSection>
  )
}

export default PortingNumberOMP
