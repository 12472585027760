import React from 'react'
import { cx } from 'linaria'
import {
  Hidden, Typography,
} from '@material-ui/core'

import { CtaProps } from '../../utils/commonProps'
import StyledButton from '../StyledButton/StyledButton'
import classes from './PurpleBanner.styles'

export enum MediaType {
  image = 'image',
  video = 'video',
}

export interface PurpleBannerProps {
  readonly meta: {
    id: number;
  };
  title?: string;
  subtitle?: string;
  media_type: MediaType;
  media_src: string;
  mobile_media_src: string;
  has_overlay?: boolean;
  button?: CtaProps;
}

export default function PurpleBanner({
  meta,
  title,
  subtitle,
  media_type,
  media_src,
  mobile_media_src,
  has_overlay,
  button,
}: PurpleBannerProps) {
  const BannerMedia = () => {
    if (media_type === MediaType.image) {
      return (
        <div className={classes.absoluteContainer}>
          <Hidden smDown>
            <img width="100%" height="100%" src={media_src} alt="Banner"/>
          </Hidden>
          <Hidden mdUp>
            <img width="100%" height="100%" src={mobile_media_src} alt="Banner"/>
          </Hidden>
        </div>
      )
    }

    return (
      <div className={classes.absoluteContainer} id={`PurpleBanner_${meta.id}`}>
        <Hidden smDown>
          <video width="100%" height="100%" autoPlay controls={false} loop muted>
            <source src={media_src}/>
            <track kind="captions"/>
          </video>
        </Hidden>
        <Hidden mdUp>
          <video width="100%" height="100%" autoPlay controls={false} loop muted>
            <source src={mobile_media_src}/>
            <track kind="captions"/>
          </video>
        </Hidden>
      </div>
    )
  }

  return (
    <section className={classes.wrapper}>
      <div className={classes.parentContainer}>
        <BannerMedia/>
        {has_overlay && <div className={cx(classes.absoluteContainer, classes.overlay)}/>}
        <div className={cx(classes.absoluteContainer, classes.content)}>
          {title && <Typography variant="h3" className={classes.title}>{title}</Typography>}
          {subtitle && <Typography variant="body1" className={classes.subtitle}>{subtitle}</Typography>}
          {button && button.text &&
            <StyledButton isModal={button.modal} color="primary" url={button.url}>
              {button.text}
              {button.icon && <img src={button.icon} alt=""/>}
            </StyledButton>}
        </div>
      </div>
    </section>
  )
}
