import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill={COLORS.primary} d="M10.4,20.5c3,1.9,6.4,3.1,9.9,3.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4v-3.2
        c0-0.8-0.3-1.6-0.8-2.2c-0.5-0.6-1.3-1-2.1-1.1c-0.9-0.1-1.9-0.4-2.8-0.7c-0.6-0.2-1.3-0.3-1.9-0.1c-0.6,0.1-1.2,0.4-1.7,0.9
        L14,14.8c-1.9-1.2-3.5-2.9-4.8-4.8l0.7-0.7c0.5-0.5,0.8-1,0.9-1.7s0.1-1.3-0.1-1.9C10.4,4.8,10.1,3.9,10,3C9.9,2.2,9.5,1.4,8.9,0.9
        S7.5,0.1,6.7,0.1H3.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.2-1.3,0.4c-0.4,0.2-0.7,0.5-1,0.8S0.3,2,0.1,2.4C0,2.9,0,3.3,0,3.8
        c0.4,3.5,1.6,6.9,3.5,9.9C5.3,16.4,7.6,18.7,10.4,20.5z M2.5,3.2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2
        c0.1-0.1,0.2-0.1,0.4-0.1h3.3c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.4,0.3,0.6C7.7,4.4,8,5.5,8.4,6.6c0.1,0.2,0.1,0.4,0,0.5
        c0,0.2-0.1,0.3-0.2,0.5L6.8,9C6.6,9.1,6.5,9.4,6.5,9.7c0,0.3,0,0.5,0.1,0.8c1.7,2.9,4.1,5.3,7,6.9c0.2,0.1,0.5,0.2,0.8,0.1
        s0.5-0.2,0.7-0.3l1.4-1.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.5,0c1.1,0.4,2.2,0.7,3.3,0.8c0.2,0,0.4,0.1,0.6,0.3
        c0.2,0.2,0.2,0.4,0.2,0.6v3.3c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3h-0.1l0,0c-3.2-0.3-6.2-1.4-8.8-3.1
        c-2.5-1.6-4.6-3.7-6.1-6.1C3.9,9.7,2.8,6.7,2.4,3.5C2.4,3.4,2.4,3.3,2.5,3.2L2.5,3.2z"
      />
      <path fill={COLORS.secondary} d="M17.7,11.2l2.5,0c0-1.9-0.7-3.8-2.1-5.3c-1.4-1.5-3.3-2.2-5.2-2.2l0,2.5c1.3,0,2.5,0.5,3.5,1.5C17.2,8.7,17.7,9.9,17.7,11.2L17.7,11.2z"/>
      <path fill={COLORS.secondary} d="M21.4,11.3l2.5,0c0-2.8-1-5.7-3.1-7.9c-2.1-2.2-5-3.3-7.8-3.4l0,2.5c2.2,0,4.4,0.9,6.1,2.6S21.4,9,21.4,11.3L21.4,11.3z"/>
    </SvgIcon>
  )
}
