import React from 'react'
import {
  Box, Link, Typography,
} from '@material-ui/core'
import Image, { ImageProps } from '../Image/Image'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import classes from './CopyWithIcon.styles'
import { cx } from 'linaria'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

export interface CopyWithIconProps {
  body?: string;
  image: ImageProps;
  title: string;
  vertical?: boolean;
  hasHeader: boolean;
  link_text?: string;
  link_url?: string;
}

export default function CopyWithIcon({
  body, image, title, vertical,
  hasHeader, link_text, link_url,
}: CopyWithIconProps) {
  const IconPosition = vertical ? classes.verticalIcon : classes.horizontalIcon

  return (
    <Box className={cx(classes.root, IconPosition)}>
      <div className={cx(classes.image, hasHeader && classes.imageWithPadding)}>
        <Image alt={image.alt} src={image.src}/>
      </div>
      <div className={classes.text}>
        <Typography className={classes.title} color="primary" variant="h5">{title}</Typography>
        {body && <Wysiwyg body={body} className={classes.body} variant="body2"/>}
        <Box py={2} className={classes.link}>
          {link_text && link_url &&
            <Link underline="hover" href={link_url}>
              <div>
                <Typography color="secondary">{link_text}</Typography>
              </div>
              <div>
                <ArrowForwardIosIcon color="secondary"/>
              </div>
            </Link>}
        </Box>
      </div>
    </Box>
  )
}
