import React from 'react'
import {
  Container, Typography, Paper, InputBase, Box, FormLabel, List, ListItem, Grid, Link,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  useForm, FormContext,
} from 'react-hook-form'
import { css } from 'linaria'
import usePremiseSearch from '../../utils/hooks/usePremiseSearch'
import theme, { COLORS } from '../../styles/theme'
import FormHeader from '../FormHeader/FormHeader'
import TextInput from '../TextInput/TextInput'
import createCRMApiClient from '../../api/CRMApi'
import NumberOfEmployeesSelect from '../NumberOfEmployeesSelect/NumberOrEmployeesSelect'
import marketingEvents from '../../utils/marketing/marketingEvents'
import logger from '../../utils/logger'
import StyledButton from '../StyledButton/StyledButton'
import commonStyling from '../../styles/commonStyling'

const classes = {
  input: css`
    &.MuiInputBase-root {
      color: ${theme.palette.primary.main};
      flex: 1;
      padding: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!} !important;
    }

    input::placeholder {
      color: ${COLORS.gray2};
      font-style: italic;
    }
  `,
  searchWrapper: css`
    position: relative;
  `,
  searchRoot: css`
    &.MuiPaper-rounded {
      display: flex;
      align-items: center;
      height: 50px;
      border-radius: 25px !important;
      box-shadow: 0px 0px 6px #00000029;

      svg {
        padding-right: 10px;
      }
    }
  `,
  container: css`
    .MuiPaper-rounded {
      border-radius: 10px;
    }
  `,
  wrapper: css`
    border-radius: ${commonStyling.roundedBorders.all} !important;
  `,
  headingWrapper: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(4, 2)};
    background-color: ${theme.palette.primary.main};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      padding: ${theme.spacing(4, 8)};
    }
  `,
  title: css`
    flex: 1;
  `,
  subtitle: css`
    flex: 1;
    color: white;
    font-weight: ${theme.typography.fontWeightLight!} !important;
  `,
  heading: css`
    &.MuiTypography-root {
      font-size: ${theme.typography.h3.fontSize!}px;
      color: white;
      padding-bottom: 0;
    }
  `,
  boxContainer: css`
    padding-right: ${theme.spacing(2)}px;
    padding-left: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('md')} {
      padding-right: ${theme.spacing(8)}px;
      padding-left: ${theme.spacing(8)}px;
    }
  `,
  contentContainer: css`
    flex-wrap: nowrap !important;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `,
  contentItem: css`
    flex-wrap: nowrap;

    ${theme.breakpoints.up('md')} {
      padding-right: ${theme.spacing(8)}px;
    }
  `,
  label: css`
    transform: translate(0, 1.5px) scale(0.95);
    transform-origin: top left;
    margin-bottom: ${theme.spacing(2)}px;
    line-height: 1.2 !important;
  `,
  addressResult: css`
    &.MuiList-root {
      max-height: ${38 * 12}px;
      overflow: scroll;
      background-color: ${theme.palette.grey[50]};
      margin: 0;
      margin-top: 2px;
      z-index: ${theme.zIndex.appBar};
      position: absolute;
      max-width: 100%;
      width: 410px;
      border-radius: 4px;
    }
  `,
  addressResultItem: css`
    &.MuiListItem-root {
      padding-left: ${theme.spacing(1)}px;
      cursor: pointer;
      &:hover {
        background-color: ${theme.palette.grey[200]};
      }
      .MuiTypography-root {
        color: ${theme.palette.grey[900]};
      }
    }
  `,
  textContainer: css`
    padding: 0 ${theme.spacing(2)}px;
    ${theme.breakpoints.up('md')} {
      padding: 0 ${theme.spacing(8)}px;
    }
  `,
}

export interface FormWithAddressLookupProps {
  success_slug: string;
  error_slug: string;
  anchor_ref: string;
}

export default function FormWithAddressLookup({
  success_slug, error_slug, anchor_ref,
}: FormWithAddressLookupProps) {
  const methods = useForm()

  const {
    register, handleSubmit,
  } = methods

  const {
    setSelectedIndex, selectedPremise, setQuery, query, premises,
  } = usePremiseSearch()

  function handleListItemClick(
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) {
    setSelectedIndex(index)
  }

  const onSubmit = handleSubmit(async ({
    address, sprn, businessName, contactName, contactNumber, email, numberOfEmployees,
  }) => {
    const client = createCRMApiClient()
    const result = await client.leads.register({
      premise: {
        sprn: parseInt(sprn, 10),
        address,
      },
      company: {
        name: businessName,
        employees: numberOfEmployees,
      },
      premiseType: 'BUSINESS',
      customer: {
        firstName: contactName,
        lastName: contactName,
        email,
        phone: contactNumber,
        type: 'PERSON',
      },
      products: [],
    })

    if ('error' in result) {
      logger.error(result.message, result.error)
      return location.assign(error_slug)
    }

    marketingEvents.registerInterestBusiness()
    return location.assign(success_slug)
  })

  return (
    <FormContext {...methods}>
      <Container>
        <Box className={classes.container}>
          <Paper id={anchor_ref}>
            <Box component="form" onSubmit={onSubmit}>
              <Grid container className={classes.headingWrapper}>
                <Grid container item direction="column" className={classes.title}>
                  <FormHeader withLegend={false} title="Enquire now" className={classes.heading}/>
                </Grid>
                <Grid container item direction="column" className={classes.subtitle}>
                  <Typography component="p">To enquire about the packages on offer please enter the details of your business.</Typography>
                </Grid>
              </Grid>
              <Box className={classes.boxContainer}>
                <Grid container className={classes.contentContainer}>
                  <Grid container lg={6} item direction="column" className={classes.contentItem}>
                    <Box pt={6}>
                      <FormLabel component="legend" required className={classes.label}>Business address</FormLabel>
                      <div className={classes.searchWrapper}>
                        <Paper className={classes.searchRoot}>
                          <InputBase
                            className={classes.input}
                            placeholder="Enter your postcode here..."
                            value={selectedPremise ? selectedPremise.address : query}
                            onChange={(e) => setQuery(e.target.value)}
                            inputProps={{ 'aria-label': 'post code search' }}
                          />
                          <SearchIcon color="primary"/>
                        </Paper>
                        {
                          (premises.length > 0 && !selectedPremise) &&
                    (
                      <List classes={{ root: classes.addressResult }} disablePadding>
                        {premises.map((premise, index) => (
                          <ListItem key={premise.sprn} classes={{ root: classes.addressResultItem }} disableGutters onClick={e => handleListItemClick(e, index)}>
                            <Typography variant="body2" noWrap>{premise.address}</Typography>
                          </ListItem>
                        ))}
                      </List>
                    )
                        }
                        <input hidden name="sprn" value={selectedPremise?.sprn} ref={register({ required: true })}/>
                        <input hidden name="address" value={selectedPremise?.address} ref={register({ required: true })}/>
                      </div>
                    </Box>
                    <Box pt={6}>
                      <TextInput gridWidth={12} required label="Business Name"/>
                    </Box>
                    <Box pt={6}>
                      <TextInput gridWidth={12} required label="Email" placeholder="me@example.co.uk" type="email"/>
                    </Box>
                  </Grid>
                  <Grid container lg={6} item direction="column">
                    <Box pt={6}>
                      <NumberOfEmployeesSelect/>
                    </Box>
                    <Box pt={6}>
                      <TextInput gridWidth={12} required label="Contact Name"/>
                    </Box>
                    <Box py={6}>
                      <TextInput gridWidth={12} required label="Contact Number" type="tel"/>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container classes={{ root: classes.textContainer }}>
                <Box pb={6}>
                  <Typography variant="body2" component="p">I give permission for Community Fibre to contact me by email or telephone and agree to our{' '}
                    <a rel="noopener noreferrer" target="_blank" href="/legal-stuff#privacy">
                      <Link underline="always">Privacy Policy</Link>
                    </a>.
                    You can opt-out anytime by contacting us{' '}
                    <a rel="noopener noreferrer" target="_blank" href="https://communityfibre.zendesk.com/hc/en-gb/requests/new">
                      <Link underline="always">here</Link>
                    </a>.
                  </Typography>
                </Box>
              </Grid>
              <Box pb={6} px={8} display="flex" justifyContent="center">
                <StyledButton type="submit" color="primary">
                  <Typography noWrap>Submit</Typography>
                </StyledButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </FormContext>
  )
}
