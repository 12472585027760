import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'

export default function GiftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.992 30.489">
        <g id="noun_Alarm_Clock_361073" data-name="noun_Alarm Clock_361073" transform="translate(-14 -12.914)">
          <path id="Icon_metro-gift" data-name="Icon metro-gift" d="M25.813,11.568a12.1,12.1,0,0,0,1.55-1.3,6.649,6.649,0,0,0,1.9-3.486,3.874,3.874,0,0,0-.953-3.467,3.626,3.626,0,0,0-2.632-1.027,6.284,6.284,0,0,0-4.322,1.969,17.013,17.013,0,0,0-3.8,6.74A13.758,13.758,0,0,0,14.36,4.536,4.828,4.828,0,0,0,10.99,3.073,3.537,3.537,0,0,0,8.452,4.082c-1.506,1.506-1.3,4.151.454,5.908a9.709,9.709,0,0,0,2.13,1.579H4.5V19.28H6.427v13.5H29.563V19.28h1.928V11.568ZM22.885,5.791a4.18,4.18,0,0,1,2.788-1.334,1.538,1.538,0,0,1,1.1.392c.784.784.344,2.6-.942,3.886a13.582,13.582,0,0,1-4.652,2.834H19.65a14.776,14.776,0,0,1,3.235-5.778ZM9.618,6.775a1.479,1.479,0,0,1,.367-1.16,1.4,1.4,0,0,1,1-.373,2.661,2.661,0,0,1,1.836.828,10.716,10.716,0,0,1,2.43,4.7l.045.165-.165-.045a10.716,10.716,0,0,1-4.7-2.43,2.744,2.744,0,0,1-.822-1.681Zm6.449,24.074H8.355V18.316h7.712V30.849Zm0-13.5H6.427V13.5h9.64v3.856Zm11.568,13.5H19.923V18.316h7.712Zm1.928-13.5h-9.64V13.5h9.64v3.856Z" transform="translate(9.501 10.626)" fill="#fff"/>
        </g>
      </svg>
    </SvgIcon>
  )
}
