import { AxiosInstance } from 'axios'

export interface OtsDirectoryResponse {
  data: {
    code: string;
    message: string;
    messages: string[];
  };
  status: number;
}

export default function OtsDirectory(client: AxiosInstance) {
  async function getOtsDirectoryList({
    listType = 'RCPID',
    identity = 'all',
  }: { listType?: string; identity?: string } = {}) {
    try {
      const url = `v1/0.0/directory?listType=${listType}&identity=${identity}`
      const { data } = await client.get<OtsDirectoryResponse>(url)
      return data
    } catch (err) {
      return err.response
    }
  }

  return {
    getOtsDirectoryList,
  }
}
