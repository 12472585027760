import { css } from 'linaria'
import theme, { fontWeights } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  formContact: css`
    margin: ${theme.spacing(3)}px;
    .MuiSvgIcon-root {
      font-size: 2rem;
    }
    .MuiTypography-body1 {
      font-size: ${fontSizes.body3};
      color: ${theme.palette.primary.main};
      font-weight: normal;
      margin: ${theme.spacing(1, 0)};
    }

    ${theme.breakpoints.up('sm')} {
      .MuiTypography-body1 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .MuiFormControlLabel-root {
      .MuiTypography-body1 {
        font-size: ${fontSizes.body2};
      }
    }
    ${theme.breakpoints.up('sm')} {
      .MuiTypography-h4 {
        margin-bottom: ${theme.spacing(2)}px;
      }
      .MuiFormControlLabel-root {
        margin-top: 0;
        margin-bottom: 0;
        .MuiTypography-body1 {
          font-size: ${fontSizes.body1};
        }
      }
    }
  `,
  checkboxesContainer: css`
    margin-top: ${theme.spacing(3)}px;
    .MuiFormControlLabel-root > .MuiTypography-body1 {
      color: ${theme.palette.primary.main};
      margin-top: ${theme.spacing(1)}px;
      font-weight: ${fontWeights.semibold};
    }
    &.MuiFormGroup-root {
      margin-bottom: 3px;
    }
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.primary.main};
    }
    ${theme.breakpoints.up('sm')} {
      margin-top: 0;
      .MuiFormControlLabel-root > .MuiTypography-body1 {
        margin-top: 0;
      }
    }
  `,
  disabledField: css`
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.roundedBorders.allSmall};
    padding: ${theme.spacing(3, 0, 3, 3)} !important;
    max-width: 93%;
    .Mui-disabled {
      color: ${theme.palette.primary.main} !important;
      /* fill-color for Safari */
      -webkit-text-fill-color: ${theme.palette.primary.main};
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: none;
    }
    .MuiInputLabel-formControl {
      top: auto;
      left: auto;
    }
    .MuiInputBase-input {
      margin-bottom: 0;
      padding: 0;
    }
  `,
  privacyPolicy: css`
    .MuiTypography-body1 {
      font-size: ${fontSizes.caption1};
      margin-bottom: ${theme.spacing(2)}px;
      font-weight: normal;
    }
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(3, 0)};
    }
  `,
  consentText: css`
    p {
      font-size: ${fontSizes.body3};
      font-weight: normal;
      margin: 0;
      a {
        color: ${theme.palette.primary.main} !important;
      }
    }
  `,
  radioButton: css`
    &.MuiButtonBase-root {
      padding-top: 3px;
      padding-left: 0px;
    }
  `,
  oneTouchSwitching: css`
    padding: ${theme.spacing(1, 3, 0, 3)};
    #switchingLabel {
      color: ${theme.palette.primary.main};
      font-weight: normal;
      margin-bottom: ${theme.spacing(1)}px;
      line-height: 1.5;
      strong {
        display: block;
        margin-bottom: ${theme.spacing(1)}px;
        font-weight: 600;
      }
      b {
        font-weight: 600;
      }
    }

    a {
      font-weight: ${fontWeights.bold};
      margin-left: ${theme.spacing(0.5)}px;
      cursor: pointer;
      white-space: nowrap;
    }
  `,
}

export default classes
