import React from 'react'
import {
  Grid, Hidden, Typography, Box,
} from '@material-ui/core'
import classes from './MediaPageHeroRevamped.styles'
import MediaPageDesktopSingleBundle from './MediaPageSingleBundle/MediaPageDesktopSingleBundle'
import MediaPageMobileSingleBundle from './MediaPageSingleBundle/MediaPageMobileSingleBundle'
import { PackageProps } from '../../Packages/Package'
import { ProductProps } from '../../Products/Product'
import {
  renderDiscountDetails, getFormattedMonthlyCost, getProductTerm, getTotalInstallationCost,
} from '../../../utils/getProductDetails'

interface MediaPageBundleHero {
  broadbandProduct: PackageProps;
  voiceProduct?: ProductProps;
  tvProduct?: ProductProps;
  wierProduct?: ProductProps;
  broadbandIcon?: string;
  tvIcon?: string;
  voiceIcon?: string;
  wierIcon?: string;
  vasIcon?: string;
  vasProduct?: ProductProps;
  free_set_up_copy?: string;
}

const MediaPageBundleHero = ({
  broadbandProduct,
  voiceProduct,
  tvProduct,
  broadbandIcon,
  tvIcon,
  voiceIcon,
  wierProduct,
  wierIcon,
  vasIcon,
  vasProduct,
  free_set_up_copy,
}: MediaPageBundleHero) => {
  return (
    <>
      <Grid className={classes.bundlesWrapper}>
        <Hidden xsDown>
          {
            broadbandProduct && (
              <MediaPageDesktopSingleBundle
                icon={broadbandIcon}
                bundle={broadbandProduct}
                contractPeriod={getProductTerm(broadbandProduct)}
                freeSetUpCopy={free_set_up_copy}
              />
            )
          }
          {
            tvProduct && (
              <MediaPageDesktopSingleBundle
                icon={tvIcon}
                bundle={tvProduct}
                contractPeriod={getProductTerm(broadbandProduct)}
              />
            )
          }
          {
            voiceProduct && (
              <MediaPageDesktopSingleBundle
                icon={voiceIcon}
                bundle={voiceProduct}
                contractPeriod={getProductTerm(broadbandProduct)}
              />
            )
          }
          {
            wierProduct && (
              <MediaPageDesktopSingleBundle
                icon={wierIcon}
                bundle={wierProduct}
                contractPeriod={getProductTerm(broadbandProduct)}
              />
            )
          }
        </Hidden>
        <Hidden smUp>
          {
            broadbandProduct && (
              <MediaPageMobileSingleBundle
                icon={broadbandIcon}
                bundle={broadbandProduct}
              />
            )
          }
          {
            tvProduct && (
              <MediaPageMobileSingleBundle
                icon={tvIcon}
                bundle={tvProduct}
              />
            )
          }
          {
            voiceProduct && (
              <MediaPageMobileSingleBundle
                icon={voiceIcon}
                bundle={voiceProduct}
              />
            )
          }
          {
            wierProduct && (
              <MediaPageMobileSingleBundle
                icon={wierIcon}
                bundle={wierProduct}
              />
            )
          }
          {
            vasProduct && (
              <MediaPageMobileSingleBundle
                icon={vasIcon}
                bundle={vasProduct}
              />
            )
          }
        </Hidden>
      </Grid>
      <Grid item>
        <Grid item className={classes.contractBundlesContainer}>
          <Box
            display="flex"
            className={classes.priceContainer}
            justifyContent={{
              xs: 'center',
              md: 'flex-start',
            }}
          >
            <Typography variant="h4">{`Monthly: ${getFormattedMonthlyCost({
              broadband: broadbandProduct,
              voice: voiceProduct,
              tv: tvProduct,
              wier: wierProduct,
              vas: vasProduct,
            })}`}
            </Typography>
            <Typography variant="h4">|</Typography>
            <Typography variant="h4">{`Set up: £${getTotalInstallationCost({
              broadband: broadbandProduct,
              voice: voiceProduct,
              tv: tvProduct,
              wier: wierProduct,
              vas: vasProduct,
            })}`}
            </Typography>
          </Box>
          <div className={classes.bundleDetails}>
            {renderDiscountDetails(broadbandProduct, classes.discountDetails, getFormattedMonthlyCost({
              broadband: broadbandProduct,
              voice: voiceProduct,
              tv: tvProduct,
              wier: wierProduct,
              vas: vasProduct,
            }))}
            <Typography variant="body2" className={classes.monthContract}>{getProductTerm(broadbandProduct)} month contract</Typography>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default MediaPageBundleHero
