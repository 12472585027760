import React from 'react'
import {
  Container, Typography, Paper, Box, Grid, Button,
} from '@material-ui/core'
import {
  useForm, FormContext,
} from 'react-hook-form'
import { css } from 'linaria'

import theme from '../../styles/theme'
import FormHeader from '../FormHeader/FormHeader'
import TextInput from '../TextInput/TextInput'
import createCRMApiClient from '../../api/CRMApi'
import CopyWithIcon, { CopyWithIconProps } from '../CopyWithIcon/CopyWithIcon'
import NumberOfEmployeesSelect from '../NumberOfEmployeesSelect/NumberOrEmployeesSelect'
import marketingEvents from '../../utils/marketing/marketingEvents'
import logger from '../../utils/logger'

const classes = {
  input: css`
    &.MuiInputBase-root {
      color: ${theme.palette.grey[900]};
      flex: 1;
      margin-left: ${theme.spacing(1)}px;
    }
  `,
  wrapper: css`
    border: solid 1px ${theme.palette.primary.main};
  `,
  heading: css`
    &.MuiTypography-root {
      font-size: ${theme.typography.h3.fontSize!}px;
    }
  `,
  label: css`
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    margin-bottom: ${theme.spacing(1)}px;
  `,
  addressResult: css`
    &.MuiList-root {
      background-color: ${theme.palette.grey[50]};
      margin: 0;
      margin-top: 2px;
      z-index: ${theme.zIndex.appBar};
      position: absolute;
      max-width: 100%;
      width: 410px;
      border-radius: 4px;
    }
  `,
  addressResultItem: css`
    &.MuiListItem-root {
      padding-left: ${theme.spacing(1)}px;
      cursor: pointer;
      &:hover {
        background-color: ${theme.palette.grey[200]};
      }
      .MuiTypography-root {
        color: ${theme.palette.grey[900]};
      }
    }
  `,
  body: css`
    &.MuiTypography-root {
      margin-top: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  form: css`
    &.MuiGrid-root {
      max-width: 100%;
    }
  `,
}

export interface PartnersFormProps {
  success_slug: string;
  error_slug: string;
  copy_with_icon: CopyWithIconProps;
  title: string;
  subtitle: string;
}

export default function LandlordsForm({
  success_slug, error_slug, copy_with_icon, title, subtitle,
}: PartnersFormProps) {
  const methods = useForm()

  const {
    handleSubmit,
  } = methods

  const onSubmit = handleSubmit(async ({
    companyName, companyEmail, contactName, contactRole, numberOfEmployees, contactNumber,
  }) => {
    const client = createCRMApiClient()
    const result = await client.leads.register({
      company: {
        name: companyName,
        employees: numberOfEmployees,
      },
      premiseType: 'BUSINESS',
      customer: {
        firstName: contactName,
        lastName: contactName,
        email: companyEmail,
        phone: contactNumber,
        type: 'PARTNER',
        jobTitle: contactRole,
      },
      products: [],
    })

    if ('error' in result) {
      logger.error(result.message, result.error)
      return location.assign(error_slug)
    }

    marketingEvents.registerInterestPartner()
    return location.assign(success_slug)
  })

  return (
    <FormContext {...methods}>
      <Container id="enquire">
        <Box pt={6} pb={6}>
          <Grid container direction="row">
            <Grid item lg={6} sm={12}>
              <CopyWithIcon {...copy_with_icon}/>
            </Grid>
            <Grid item lg={6} sm={12} className={classes.form}>
              <Paper elevation={3} className={classes.wrapper}>
                <Box p={3} component="form" onSubmit={onSubmit}>
                  <Typography variant="h3" component="div" color="secondary">{title}</Typography>
                  <Typography variant="h5" component="div">{subtitle}</Typography>
                  <Box pt={3} pb={3}>
                    <FormHeader title="Company address"/>
                    <Grid container spacing={6}>
                      <TextInput required label="Company Name"/>
                      <TextInput required label="Company Email" placeholder="me@example.co.uk" type="email"/>
                      <TextInput required label="Contact Name"/>
                      <TextInput required label="Contact Role"/>
                      <TextInput required label="Contact Number" type="tel"/>
                      <TextInput required label="Company Website"/>
                    </Grid>
                    <NumberOfEmployeesSelect/>
                  </Box>
                  <Button type="submit" variant="contained" color="primary" disableElevation>
                    <Typography noWrap>Submit</Typography>
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </FormContext>
  )
}
