import React from 'react'
import { Typography } from '@material-ui/core'
import {
  css, cx,
} from 'linaria'
import styled from 'styled-components'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  heading: css`
    text-align: center;
    width: 100%;
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      max-width: ${theme.spacing(87.5)}px;
      margin: 0 auto;
    }

    div {
      margin-top: ${theme.spacing(4)}px;
      max-width: ${theme.spacing(80)}px;
      margin: 0 auto;
      padding: ${theme.spacing(0, 3)};
    }
  `,
  h1: css`
    h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h1};
    }
  `,
  h2: css`
     h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h2};
    }
  `,
  h3: css`
     h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h3};
    }
  `,
  h4: css`
     h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h4};
    }
  `,
  h5: css`
     h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h5};
    }
  `,
  h6: css`
     h1, h2, h3, h4, h5, h6 { 
      font-size: ${fontSizes.h6};
    }
  `,
}

export enum Variants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6'
}

export interface HeadingProps {
  readonly heading: string;
  readonly variant: Variants;
  readonly subheadline?: string;
  readonly background_color?: string;
  readonly padding_bottom?: number;
  readonly padding_top?: number;
  readonly variant_style?: string;
}
interface HeadingStylesrops {
  readonly backgroundColor?: string;
  readonly paddingBottom?: number;
  readonly paddingTop?: number;
}

const Background = styled.div<HeadingStylesrops>`
background-color: ${(props: HeadingStylesrops) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
padding-bottom: ${(props: HeadingStylesrops) =>
    props.paddingBottom ? `${theme.spacing(props.paddingBottom)}px` : `${theme.spacing(2)}px`};
padding-top: ${(props: HeadingStylesrops) =>
    props.paddingTop ? `${theme.spacing(props.paddingTop)}px` : `${theme.spacing(2)}px`};
`

export default function Heading({
  heading,
  variant,
  subheadline,
  background_color,
  padding_bottom,
  padding_top,
  variant_style,
}: HeadingProps) {
  let headingStyles

  switch (variant_style) {
    case 'h1':
      headingStyles = classes.h1
      break
    case 'h2':
      headingStyles = classes.h2
      break
    case 'h3':
      headingStyles = classes.h3
      break
    case 'h4':
      headingStyles = classes.h4
      break
    case 'h5':
      headingStyles = classes.h5
      break
    case 'h6':
      headingStyles = classes.h6
      break
  }

  return (
    <Background className={cx(classes.heading, headingStyles)} backgroundColor={background_color} paddingBottom={padding_bottom} paddingTop={padding_top}>
      <Typography variant={variant} color="primary">
        {heading}
      </Typography>
      {subheadline && <Wysiwyg body={subheadline} variant="body1"/>}
    </Background>
  )
}
