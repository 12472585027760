import React from 'react'
import {
  Typography, Grid, Button, Link, Card, CardMedia, CardContent,
} from '@material-ui/core'
import Wysiwyg from '../Wysiwyg/Wysiwyg'

import classes from './CopyWithContact.styles'

export interface CopyWithContactProps {
  readonly meta: {
    id: number;
  };
  title: string;
  body: string;
  button_text?: string;
  button_url?: string;
  contact_title: string;
  contact_subtitle: string;
  contact_email: string;
  contact_background?: string;
}

export default function CopyWithContact({
  meta, title, body, button_text, button_url, contact_title, contact_subtitle, contact_email, contact_background,
}: CopyWithContactProps) {
  return (
    <section className={classes.root} id={`CopyWithContact_${meta.id}`}>
      <Typography className={classes.title} color="primary" variant="h2">{title}</Typography>
      <Grid container>
        <Grid container item sm={12} md={8} lg={9} className={classes.about}>
          <Wysiwyg body={body} className={classes.body} variant="body1"/>
          {button_text && <Button href={button_url} variant="contained" color="primary" disableElevation><Typography noWrap>{button_text}</Typography></Button>}
        </Grid>
        <Grid className={classes.contact} container item xs={12} md={4} lg={3} justify="center">
          <Card className={classes.card}>
            {contact_background && <CardMedia src={contact_background} component="img"/>}
            <CardContent className={classes.cardText}>
              <Typography className={classes.lightText} variant="h3">{contact_title}</Typography>
              <Typography className={classes.lightText} variant="h5">{contact_subtitle}</Typography>
              <Typography className={classes.lightText} variant="body1"><Link href={`mailto:${contact_email}`}>{contact_email}</Link></Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  )
}
