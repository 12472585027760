import React from 'react'
import {
  PropTypes, Theme, Typography, TypographyVariant, useMediaQuery,
} from '@material-ui/core'
import {
  createStyles, withStyles,
} from '@material-ui/styles'

type TypographyColor = 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'error' | 'textSecondary' | undefined;
export interface TypographyProps {
  text?: string;
  variant: TypographyVariant;
  variant_mobile?: TypographyVariant;
  color?: TypographyColor;
  align?: PropTypes.Alignment;
  margin_top?: number;
  margin_bottom?: number;
  margin_left?: number;
  wrap_on_mobile?: boolean;
  lineHeight?: string;
  weight?: 400 | 500 | 600 | 700;
  classes?: any;
  children?: React.ReactNode;
}

export const createTypographyProps = (typography: TypographyProps) => ({
  text: typography?.text,
  weight: typography?.weight,
  variant: typography?.variant,
  color: typography?.color,
  align: typography?.align,
  margin_top: typography?.margin_top,
  margin_bottom: typography?.margin_bottom,
  margin_left: typography?.margin_left,
  wrap_on_mobile: typography?.wrap_on_mobile,
})

const breakText = (text: string) => {
  return text ? Object.values(text.split(/\r?\n/))
    .map((line: string, index: number) => {
      return index === 0 ? line : <span key={index}><br/>{line}</span>
    }) : null
}

const TypographyCMS = withStyles((theme: Theme) =>
  createStyles({
    root: ({
      margin_bottom, margin_top, margin_left, weight, lineHeight,
    }: TypographyProps) => ({
      '&.MuiTypography-root': {
        marginBottom: margin_bottom ? theme.spacing(margin_bottom) : 0,
        marginTop: margin_top ? theme.spacing(margin_top) : 0,
        marginLeft: margin_left ? theme.spacing(margin_left) : 0,
        ...(lineHeight && { lineHeight }),
        ...(weight && { fontWeight: weight }),
      },
    }),
  }),
)(({
  text,
  variant,
  variant_mobile,
  wrap_on_mobile,
  align,
  color,
  classes,
  children,
}: TypographyProps) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const mobileText = wrap_on_mobile ? breakText(text!) : text
  const mobileVariant = variant_mobile ? variant_mobile : variant
  const dynamicVariant = isMobile ? mobileVariant : variant
  const formattedText = isMobile ? mobileText : breakText(text!)
  const nonDivs = 'h1, h2, h3, h4, h5, h6, caption'
  const isDiv = nonDivs.split(', ')
    .some(e => e === dynamicVariant)

  const parseColor = (color: TypographyColor | 'textprimary' | 'textsecondary') => {
    switch (color) {
      case 'textprimary':
        return 'textPrimary'
      case 'textsecondary':
        return 'textSecondary'
      default:
        return color
    }
  }

  return (
    <Typography
      className={classes.root}
      variant={dynamicVariant}
      align={align}
      color={parseColor(color)}
      {...isDiv && { component: 'div' }}
    >
      {formattedText}
      {children}
    </Typography>
  )
})

export default TypographyCMS

