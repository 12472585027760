import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  container: css`
    background-image: linear-gradient(180deg, white 0%, ${theme.palette.warning.light} 100%);
    color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    /* padding: ${theme.spacing(0, 2, 0, 2)}; */
    padding: 0;

    button {
      color: white;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      cursor: pointer;

      .MuiTypography-body1 {
        color: ${theme.palette.primary.main};
        font-weight: ${theme.typography.fontWeightLight!} !important;
        margin-top: 0;
      }

      :hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    .MuiTypography-h2 {
      padding-top: ${theme.spacing(1)}px;
    }

    .MuiTypography-body1 {
      max-width: 800px;
      font-weight: 300;
      margin-top: ${theme.spacing(3)}px;
    }

    ${theme.breakpoints.up('sm')} {
      .MuiTypography-h2 {
        max-width: 800px;
        font-size: ${fontSizes.h1};
        line-height: 1.15;
      }
    }
  `,
  biggerContainer: css`
    min-height: 300px;
    justify-content: flex-start;
    .MuiTypography-h3 {
      margin-top: ${theme.spacing(5)}px;
    }
  `,
  content: css`
    width: 100%;
  `,
  icon: css`
    margin-right: ${theme.spacing(1)}px;
  `,
  address: css`
    padding: ${theme.spacing(0, 2, 0, 2)};
    .MuiTypography-h1 {
      font-weight: ${theme.typography.fontWeightBold!};
      font-size: ${fontSizes.body1};
      color: ${COLORS.bodyTextPrimary};
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.bodyTextPrimary};
    }

    a {
      display: inline-block;

      .MuiTypography-root {
        color: ${theme.palette.secondary.main};
        text-decoration: underline;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightBold!};
      }
    }
  `,
  tabsWrapper: css`
    padding-top: ${theme.spacing(6)}px !important;

    ${theme.breakpoints.up('sm')} {
      padding-top: ${theme.spacing(8)}px !important;
    }
  `,
}

export default classes
