import { AxiosInstance } from 'axios'
import logger from '../utils/logger'
import { PremiseDetail } from './Addresses'
import {
  TimeslotData, Timeslot, CreateTimeslotData, ResponseProps,
} from './BookingCalendar'

export interface IWorkorderProps {
  workOrderTaskAppointmentWindowStartDate: string;
  createdDate: string;
  workOrderTaskAppointmentWindow: [
    {
      start: string;
      end: string;
    }
  ];
  modifiedDate: string;
  workOrderTaskId: string;
  workOrderTaskModifiedDate: string;
  accountNumber: string;
  workOrderTaskAppointmentWindowEndDate: string;
  serviceType: string;
  voiceFlag: boolean;
  statusName: string;
  accountType: string;
  installationType: number;
  scheduleType: string;
  broadbandProduct: string;
  region: string;
}

export interface WorkOrderDetails {
  batchNumber: number;
  zRequestId: string;
  batchSize: string;
  data: IWorkorderProps[];
  status: boolean;
}

export default function WorkOrder(client: AxiosInstance) {
  async function getWorkOrder(id: string) {
    try {
      const params = {
        data: [{
          filter: `{$accountNumber} = '${id}' AND ({$installType} = 'INSTALL' OR {$installType} = 'SERVICE') AND {$taskTypeName} != 'UG PreWork Task'`,
          models: [
            'WorkOrder',
            'workOrderTask',
            'workOrderTaskAppointmentWindow',
            'crew',
            'User',
            'taskType',
            'ZStatus',
            'taskScheduleBreakdown',
          ],
          columns: {
            appointmentWindowId: 'WorkOrderTaskAppointmentWindow.appointmentWindowId',
            workOrderTaskAppointmentWindowStartDate: 'workOrderTaskAppointmentWindow.startDate',
            orderId: 'workOrderTask.cflOrderId',
            taskTypeName: 'taskType.name',
            workOrderTaskId: 'workOrderTask.id',
            statusName: 'ZStatus.name',
            taskIdTaskTypeName: 'CONCAT({$workOrderTask.id},\' - \',{$taskType.name})',
            workOrderTaskModifiedDate: 'workOrderTask.modifiedDate',
            workOrderTaskEstimatedDuration: 'Round(({$workOrderTask.estimatedDuration})/60,2)',
            workOrderTaskResult: 'workOrderTask.result',
            workOrderTaskScheduleBreakdownStartDate: 'IF({$taskScheduleBreakdown.startDate} IS NULL, \'-\', {$taskScheduleBreakdown.startDate})',
            workOrderTaskAppointmentWindowEndDate: 'workOrderTaskAppointmentWindow.endDate',
            workOrderTaskScheduleBreakdownEndDate: 'IF({$taskScheduleBreakdown.endDate} IS NULL, \'-\', {$taskScheduleBreakdown.endDate})',
            workOrderTaskAppointmentWindow: 'workOrderTaskAppointmentWindow.startEndJson',
            crewName: 'crew.name',
            accountNumber: 'WorkOrder.cflAccountNumber',
            statusNameColor: 'ZStatus.colorCode',
            workOrderTaskDueDate: 'IF({$workOrderTask.dueDate} IS NULL, \'-\', {$workOrderTask.dueDate})',
            createdDate: 'workOrderTask.createdDate',
            scheduleType: 'workOrderTask.cflScheduleType',
            installType: 'workOrderTask.cflScheduleType',
            modifiedDate: 'workOrderTask.modifiedDate',
            workOrderTaskScheduledDate: 'IF({$workOrderTask.scheduledDate} IS NULL, \'-\', {$workOrderTask.scheduledDate})',
            workOrderTaskScheduledDateMapView: 'IF({$workOrderTask.scheduledDate} IS NULL, \'-\', {$workOrderTask.scheduledDate})',
            assignee: 'IF({$taskType.crewTask}=true,{$crew.name},{$User.name})',
          },
          orderBy: 'workOrderTaskAppointmentWindowStartDate ASC',
          outerJoinOnModel: 'workOrderTask',
          distinct: true,
        }],
      }
      const data = await client.post<WorkOrderDetails>('/workorder/get', params)
      return data
    } catch (err) {
      return { error: err }
    }
  }

  async function createWorkOrder(
    fullPremise: PremiseDetail,
    timeslot: TimeslotData | Timeslot,
    payload: CreateTimeslotData): Promise<{ data: ResponseProps[] } | { error: any; message: string }> {
    try {
      const params = {
        data: [{
          eventId: 'cflWOCreateAPI',
          contextVar: {
            orderId: payload.orderId,
            scheduleType: 'INSTALL',
            accountType: fullPremise?.type?.toUpperCase(),
            broadbandProduct: payload?.broadbandProduct?.replace(/[aA-zZ]/g, ''),
            installationType: String(fullPremise?.install_type) || String(fullPremise?.installType),
            serviceType: payload.serviceType,
            accountNumber: payload?.accountNumber,
            customerName: payload.firstName + ' ' + payload.lastName,
            emailId: payload.email,
            customerId: payload?.customerId,
            siteAddress: fullPremise?.address,
            siteCity: fullPremise?.is_in_london ? 'London' : '',
            siteState: 'England',
            sitePostalCode: fullPremise?.postcode,
            siteUPRN: fullPremise?.uprn,
            siteborough: fullPremise?.borough,
            siteContactPersonPhone: payload.contactPhone,
            siteAlternativePhone: payload.alternativePhone,
            voiceFlag: payload.voiceFlag,
            tvFlag: payload.tvFlag,
            retailFlag: true,
            appointmentWindowId: timeslot.appointmentWindowId,
            appointmentWindowDay: timeslot.date,
            systemOfOrigin: 'website',
            cflCustomerType: 'CFL Customer',
            oltPop: fullPremise?.pop,
            oltIp: fullPremise?.oltIP || 'Olt Info',
            oltDeviceName: fullPremise?.device_name || 'Olt Info',
            oltManufacturer: fullPremise?.oltManufacturer,
            oltTechnology: fullPremise?.technology || 'Olt Info',
            projectName: fullPremise?.build_project_name,
            buildContractor: fullPremise?.contractor || null,
            liveDate: fullPremise?.declared_live_date,
          },
        }],
      }
      const {
        data,
      } = await client.post<{ data: ResponseProps[] }>('workorder/create', params)

      return data
    } catch (err) {
      logger.error('could not reserve timeslot', err, {
        requestUrl: err.config.url,
        baseUrl: err.config.baseUrl,
      })
      return {
        error: err,
        message: 'Could not reserve timeslot',
      }
    }
  }

  return {
    getWorkOrder,
    createWorkOrder,
  }
}
