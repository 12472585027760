import { css } from 'linaria'
import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  mainContainer: css`
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 ${theme.spacing(2)}px;
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      padding: 0 ${theme.spacing(10)}px;
    }
    ${theme.breakpoints.up('lg')} {
      align-items: flex-start;
    }
  `,
  dropdownContainer: css`
    .MuiTypography-h3 {
      padding-left: ${theme.spacing(2)}px;
      margin-bottom: ${theme.spacing(2)}px;
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
      }
    }
    .MuiTypography-h5 {
      line-height: 1.5;
    }
    .MuiExpansionPanel-root:before {
      background-color: transparent;
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  dropdownPanel: css`
    .Mui-expanded, p {
      padding-right: 0;
      margin: 0;
    }
    .MuiIconButton-root {
      padding-left: 0;
    }
    .Mui-expanded {
      .MuiTypography-h5 {
        color: ${theme.palette.secondary.main};
      }
    }
    .MuiExpansionPanelSummary-root {
      flex-direction: row-reverse;
      padding: 0 !important;
    }
    .MuiSvgIcon-root {
      font-size: ${commonStyling.svgFontSize};
    }
    &.MuiExpansionPanel-root:before {
      height: 0px;
    }
    .MuiExpansionPanelDetails-root {
      padding-bottom: 0;
      padding-left: ${theme.spacing(5)}px;
      img {
        padding-top: ${theme.spacing(3)}px;
      }
      a:first-child {
        padding-right: ${theme.spacing(1)}px;
        ${theme.breakpoints.up('sm')} {
          padding-right: ${theme.spacing(3)}px;
        }
      }
    }
  `,
  imageContainer: css`
    padding-bottom: ${theme.spacing(5)}px;
    img {
      width: 100%;
      object-fit: contain;
      ${theme.breakpoints.up('sm')} {
        max-height: 500px;
      }
    }
  `,
  transparent: css`
    background-color: transparent !important;

    .MuiTypography-h5 {
      display: flex;
      justify-content: center;
    }

    .MuiBox-root {
      display: flex;
      justify-content: center;
    }
  `,
}

export default classes
