import React from 'react'
import {
  Box, Link,
} from '@material-ui/core'
import TrustPilotBox, {
  TrustPilotBoxProps, createTrustPilotProps,
} from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import TypographyCMS, {
  TypographyProps, createTypographyProps,
} from '../Typography/Typography'
import { LinkProps } from './Packages'
import Wrapper from '../Wrapper/Wrapper'

interface PackagesFooterProps {
  trustpilot?: TrustPilotBoxProps;
  footnote?: TypographyProps;
  footnote_link?: LinkProps;
}

const PackagesFooter = ({
  trustpilot, footnote, footnote_link,
}: PackagesFooterProps) => {
  if (trustpilot || footnote) {
    return (
      <Wrapper>
        {trustpilot && <TrustPilotBox {...createTrustPilotProps(trustpilot)}/>}
        {footnote && <TypographyCMS {...createTypographyProps(footnote)}/>}
        {footnote_link && footnote_link?.label &&
          <Box textAlign="center" mt={6}>
            <Link color="inherit" variant="caption" href={footnote_link?.href} underline="always">{footnote_link?.label}</Link>
          </Box>}
      </Wrapper>
    )
  }

  return null
}

export default PackagesFooter
