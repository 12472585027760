import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function TVIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill={COLORS.primary} d="M0,3.6V18h24V2.4H0V3.6z M2.4,4.8h19.2v10.8H2.4V4.8L2.4,4.8z"/>
      <path fill={COLORS.secondary} d="M10.8,19.2H8.4c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h7.2c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-2.4V18h-2.4V19.2z"/>
      <path fill={COLORS.secondary} d="M9.4,6.4v7.3l6.4-3.6L9.4,6.4z"/>
    </SvgIcon>
  )
}
