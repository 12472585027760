import {
  useEffect,
} from 'react'
import { JsonObject } from 'type-fest'
import { experianStore } from '../../sessionStorage'

export interface ExperianOptions extends JsonObject, ExperianProps {}
export interface ExperianProps {
  email_validation: boolean;
  email_validation_cases: any;
  email_error: string;
  phone_validation: boolean;
  phone_validation_cases: any;
  phone_error: string;
}

export default function ExperianValidation({
  email_validation,
  email_validation_cases,
  email_error,
  phone_validation,
  phone_validation_cases,
  phone_error,
}: ExperianProps) {
  const data = {
    email_validation,
    email_validation_cases,
    email_error,
    phone_validation,
    phone_validation_cases,
    phone_error,
  } as ExperianProps

  experianStore.set({
    ...data,
  })

  useEffect(() => {
    return () => {
      experianStore.delete()
    }
  }, [])

  return true
}
