import { css } from 'linaria'
import theme, { COLORS } from '../../../../../styles/theme'

const classes = {
  wrapper: css`
    &.MuiPaper-root {
      color: ${theme.palette.error.main};
      margin: 0 auto;
      margin-top: ${theme.spacing(1)}px;
      margin-bottom: ${theme.spacing(6)}px;
      padding: ${theme.spacing(3)}px;
      border: 3px solid ${COLORS.gray2};
    }`
  ,
}

export default classes
