import React, {
  useEffect, useState,
} from 'react'
import { PackageProps } from '../Packages/Package'
import {
  HobsProduct, getPackagesWithStore,
} from '../../api/Packages'
import {
  ChannelType, SegmentType,
} from '../../utils/commonEnums'
import { useAuth } from '../../hooks/auth'

export interface ProductsOptions {
  expirationTime?: number;
  packages?: HobsProduct[];
}

export const ProductsContext = React.createContext<{
  dataPackages?: HobsProduct[] | null;
}>({
  dataPackages: null,
})

export function ProductsContextProvider({
  children, channel, customer_segment,
}: {children: React.ReactNode; channel?: ChannelType; customer_segment?: SegmentType}) {
  const [
    dataPackages,
    setDataPackages,
  ] = useState<HobsProduct[] | null>(null)

  const { isAuthenticated } = useAuth()

  useEffect(() => {
    const fetchDataPackages = async () => {
      try {
        if (isAuthenticated && !dataPackages) {
          const data = await getPackagesWithStore(channel || ChannelType.DIGITAL, customer_segment) as PackageProps[]
          setDataPackages(data)
        }
      } catch (err) {
        console.error('Error fetching data packages:', err)
      }
    }

    fetchDataPackages()
  }, [
    dataPackages,
    isAuthenticated,
    channel,
    customer_segment,
  ])

  return (
    <ProductsContext.Provider
      value={{
        dataPackages,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
