import React, {
  useState, useEffect,
} from 'react'
import {
  Modal, Typography, Slide,
} from '@material-ui/core'
import { useRouter } from 'next/router'
import CloseIcon from '@material-ui/icons/Close'
import classes from './HelpChooseModal.styles'
import StyledButton from '../StyledButton/StyledButton'

export interface HelpChooseModalProps {
  title: string;
  body: string;
  button_text: string;
}

export default function HelpChooseModal({
  title,
  body,
  button_text,
}: HelpChooseModalProps) {
  const [
    open,
    setOpen,
  ] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (router.asPath.includes('choose-modal')) {
      setOpen(true)
    }
  }, [router.asPath])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    // Add functionality of the postcode checker
    return undefined
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="choose-modal"
      aria-describedby="choose-a-package"
      closeAfterTransition
    >
      <Slide in={open} onExited={() => router.back()} direction="up" timeout={800}>
        <div className={classes.body}>
          <div className={classes.backDrop}/>
          <button type="button" onClick={handleClose} className="close-button">
            <Typography variant="body1">Close</Typography>
            <CloseIcon/>
          </button>
          <div className={classes.checker}>
            <div className={classes.text}>
              <Typography variant="h2">{title}</Typography>
              <Typography>{body}</Typography>
            </div>
            <StyledButton handleClick={handleClick} color="primary">{button_text}</StyledButton>
          </div>
        </div>
      </Slide>
    </Modal>
  )
}
