import { NextPageContext } from 'next'
import {
  ButterCMSComponent, getButterPage,
} from '../butter'
import { parseDateToUTC } from './parseDateToUTC'

export type Redirecter = {
  type: string;
  start_date: string;
  end_date: string;
  path: string;
}

const handleRedirect = (ctx: NextPageContext, redirectPath: string) => {
  if (ctx.res) {
    ctx.res.writeHead(301, {
      Location: redirectPath,
      'Cache-Control': 'no-store',
    })
    ctx.res.end()
  }
}

export const getSettings = async () => {
  try {
    return await getButterPage('general-settings', {})
  } catch (err) {
    return null
  }
}

export const checkIfRedirecterExists = (settings: any): boolean => {
  if (!settings) {
    return false
  }

  const redirecterData = settings.components.find((component: ButterCMSComponent) => component.type === 'redirecter')
  if (!redirecterData) {
    return false
  }

  const {
    start_date, end_date, enabled,
  } = redirecterData
  const currentDate = parseDateToUTC()
  const parsedStartDate = parseDateToUTC(start_date)
  const parsedEndDate = parseDateToUTC(end_date)

  return (
    currentDate > parsedStartDate &&
    currentDate < parsedEndDate &&
    enabled
  )
}

export const handleRedirectIfRequired = (ctx: NextPageContext, settings: any, currentPath: string) => {
  if (checkIfRedirecterExists(settings)) {
    const redirecterData = settings.components.find((component: ButterCMSComponent) => component.type === 'redirecter')
    const { path } = redirecterData
    path && currentPath !== path && handleRedirect(ctx, path)
  }
}
