import React from 'react'
import {
  Typography, Grid,
} from '@material-ui/core'
import { cx } from 'linaria'

import classes from './TimelineItem.styles'

export interface TimelineItemProps {
  image: string;
  title: string;
  subtitle: string;
  overlap_the_line?: string;
}

const TimelineItem = ({
  timeline: {
    title, image, subtitle, overlap_the_line,
  },
  className = '',
}: {timeline: TimelineItemProps; className?: string}) => {
  return (
    <Grid container direction="column" className={cx(overlap_the_line && classes.overlap, classes.container, className)}>
      <div className={classes.imageContainer}>
        <img src={image} alt={`Timeline ${title}`}/>
      </div>
      <Typography variant="h4" color="primary" className={classes.title}>{title}</Typography>
      <Typography variant="body1" className={classes.subtitle}>{subtitle}</Typography>
    </Grid>
  )
}

export default TimelineItem
