import React from 'react'

import {
  ButterCMSComponent, getButterComponentByType,
} from '../../butter'
import Head from 'next/head'
import { ThemeProvider } from '@material-ui/styles'
import theme from '../../styles/theme'
import UTMTracker from '../UTMTracker/UTMTracker'
import { PremiseDetail } from '../../api/Addresses'
import PremiseTracker from '../PremiseTracker/PremiseTracker'
import { PremiseContextProvider } from '../PremiseTracker/PremiseContext'
import { ContainerPreserveMargin } from '../../styles/ContainerPreserveMargin'
import AlertBar from '../AlertBar/AlertBar'
import { useRouter } from 'next/router'
import { ProductProps } from '../Products/Product'
import AffiliateBar from '../AffiliateBar/AffiliateBar'

export interface Props {
  components: ButterCMSComponent[];
  title: string;
  description?: string;
  premise?: PremiseDetail;
  keywords?: string;
  message?: string;
}
export interface SettingsProps {
  components: ProductProps[];
  title?: string;
  description?: string;
  keywords?: string;
  message?: string;
}

export default function App({
  components, keywords, title, description, premise, message,
}: Props) {
  const router = useRouter()
  const hostname = 'https://communityfibre.co.uk'
  const fullURL = (hostname + (router.asPath === '/' ? '' : router.asPath)).split('?')[0]

  return (
    <PremiseContextProvider premise={premise}>
      <Head>
        <title>{title}</title>
        {keywords && <meta name="keywords" content={keywords}/>}
        {description && <meta name="description" content={description}/>}
        <meta name="facebook-domain-verification" content="0zboaobpxtyptfdzy37o0pksjh4v13"/>
        <link rel="preconnect" href="https://cdn.buttercms.com"/>
        <link rel="preconnect" href="https://cdnjs.cloudflare.com"/>
        <link rel="preconnect" href="https://connect.facebook.net"/>
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com"/>
        <link rel="preconnect" href="https://dynamic.criteo.com"/>
        <link rel="preconnect" href="https://sslwidget.criteo.com"/>
        <link rel="preconnect" href="https://static.criteo.net"/>
        <link rel="dns-prefetch" href="https://script.hotjar.com"/>
        <link rel="preconnect" href="https://widget.trustpilot.com"/>
        <link rel="preconnect" href="https://dwin1.com"/>
        <link rel="preconnect" href="https://facebook.com"/>
        <link rel="dns-prefetch" href="https://google-analytics.com"/>
        <link rel="dns-prefetch" href="https://googletagmanager.com"/>
        <link rel="preconnect" href="https://cdn.studentbeans.com"/>
        <link rel="canonical" href={fullURL}/>
      </Head>
      <ThemeProvider theme={theme}>
        <UTMTracker/>
        <PremiseTracker/>
        <ContainerPreserveMargin>
          {message && <AlertBar message={message}/>}
          {components.map(({
            type, ...rest
          }, i) => {
            const C: any = getButterComponentByType(type)

            if (!C) {
              return null
            }

            return (
              <C key={'butterComponent_' + i} {...rest}/>
            )
          })}
          <AffiliateBar/>
        </ContainerPreserveMargin>
      </ThemeProvider>
    </PremiseContextProvider>
  )
}
