import {
  Dispatch, SetStateAction,
} from 'react'
import logger from '../../utils/logger'
import createCRMApiClient from '../../api/CRMApi'
import {
  format, parseISO,
} from 'date-fns'
import { Day } from './Calendar'
import {
  ScheduleType,
  Timeslot, TimeslotData,
} from '../../api/BookingCalendar'
import {
  getDayByIndex, isAmPmMorningTimeslot, transformTimeslot, isAmPmAfternoonTimeslot,
  AM_PM_EMPTY_SLOTS, formattedSlot,
} from './calendarUtils'

interface ChangeDaysOfWeekProps {
  fullPremise: any;
  year: number;
  week: number;
  productGroup?: string;
  setDays: Dispatch<SetStateAction<null | Day[]>>;
  selectedTimeslot?: Timeslot | TimeslotData | null;
  startDate: any;
  endDate: any;
  serviceType: string;
  broadbandProduct?: string;
  scheduleType?: ScheduleType;
}

export const changeDaysOfWeek = async ({
  fullPremise,
  year,
  week,
  setDays,
  selectedTimeslot,
  startDate,
  endDate,
  serviceType,
  broadbandProduct,
  scheduleType,
}: ChangeDaysOfWeekProps) => {
  const client = createCRMApiClient()
  const payload = {
    serviceType,
    broadbandProduct,
  }
  let timeslots = await client.bookingCalendar.getTimeslots(fullPremise, startDate, endDate, payload, scheduleType)
  if (timeslots) {
    timeslots.map(x => {
      x._id = {
        startDayOfWeek: x?.date ? parseInt(format(new Date(x?.date), 'd'), 10) : 1,
        startDayOfYear: x?.startDateTime ? parseInt(format(new Date(x?.startDateTime), 'D', { useAdditionalDayOfYearTokens: true }), 10) : 1,
        startWeek: x?.date ? parseInt(format(new Date(x?.date), 'I'), 10) : 1,
        startMonth: x?.date ? parseInt(format(new Date(x?.date), 'M'), 10) : 1,
        startYear: x?.date ? parseInt(format(new Date(x?.date), 'yyyy'), 10) : 2022,
        startHour: x?.startDateTime ? parseInt(format(parseISO(x?.startDateTime), 'k'), 10) : 11,
        startDateTime: x.startDateTime,
        endDateTime: x.endDateTime,
      }
      return x
    })
  }

  if (!timeslots) {
    logger.error('error', 'error')
    timeslots = []
    return
  }

  const days: Day[] = []

  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    const amStartTime = getDayByIndex({
      year,
      week,
      dayIndex,
    })

    const amPmTimes: Day['timeslots'] = {
      am: timeslots.filter((x) => isAmPmMorningTimeslot(x, amStartTime))
        .map(transformTimeslot),
      pm: timeslots.filter(x => isAmPmAfternoonTimeslot(x, amStartTime))
        .map(transformTimeslot),
    }

    // Set available timeslots
    const times = amPmTimes
    const emptySlots = AM_PM_EMPTY_SLOTS

    // Set unavailable timeslots

    emptySlots.am.forEach(([
      start,
      end,
    ]) => {
      const hasTimeslot = times.am.some(t => t.start === start && t.end === end)

      if (!hasTimeslot) {
        times.am.push({
          time: formattedSlot(start, end),
          start,
          end,
          isAvailable: false,
          timeslot: null,
        })
      }
    })

    emptySlots.pm.forEach(([
      start,
      end,
    ]) => {
      const hasTimeslot = times.pm.some(t => t.start === start && t.end === end)

      if (!hasTimeslot) {
        times.pm.push({
          time: formattedSlot(start, end),
          start,
          end,
          isAvailable: false,
          timeslot: null,
        })
      }
    })

    times.am.sort((a, b) => a.start - b.start)
    times.pm.sort((a, b) => a.start - b.start)

    const month = format(getDayByIndex({
      year,
      week,
      dayIndex,
    }), 'LLL')

    const date = format(getDayByIndex({
      year,
      week,
      dayIndex,
    }), 'd')

    const dayOfWeek = format(getDayByIndex({
      year,
      week,
      dayIndex,
    }), 'EEE')

    const selectedMonth = selectedTimeslot && format(new Date(selectedTimeslot?.endDateTime), 'LLL')
    const selectedDate = selectedTimeslot && format(new Date(selectedTimeslot?.endDateTime), 'd')
    const selectedDayOfWeek = selectedTimeslot && format(new Date(selectedTimeslot?.endDateTime), 'EEE')
    const hasSelectedTimeslot = month === selectedMonth && date === selectedDate && dayOfWeek === selectedDayOfWeek

    days.push({
      month,
      date,
      dayOfWeek,
      isAvailable: times.am.some(x => x.isAvailable) || times.pm.some(x => x.isAvailable),
      hasSelectedTimeslot,
      timeslots: times,
    })
  }

  setDays(days)
}
