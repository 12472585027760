import React from 'react'
import { css } from 'linaria'
import {
  Grid, Container, Typography, Box,
} from '@material-ui/core'

import theme from '../../styles/theme'
import Image, { ImageProps } from '../Image/Image'

const classes = {
  body: css`
    align-self: flex-start;
    &.MuiTypography-subtitle1 {
      strong, b {
        font-weight: ${theme.typography.fontWeightBold!};
      }
      a {
        color: ${theme.palette.secondary.main};
      }
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  image: css`
    text-align: center;
    img {
      border-radius: 8%;
      max-width: 100%;
    }
  `,
}

export interface TextWithImagesProps {
  title?: string;
  title_highlight?: string;
  body: string; /** Rich Text */
  images: ImageProps[];
}

export default function TextWithImages({
  title,
  images,
  title_highlight,
  body,
}: TextWithImagesProps) {
  return (
    <Container>
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        {title &&
        <Grid item xs={12}>
          <Typography variant="h2" color="primary">{title}{title_highlight && <Box component="span" color={theme.palette.secondary.main}>{' '}{title_highlight}</Box>}</Typography>
        </Grid>}
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary" component="div" className={classes.body}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: body }}/>
          </Typography>
        </Grid>
        {images.length > 0 &&
        <Grid
          item xs={12}
          container
          direction="row"
          justify="center"
          spacing={10}
          alignContent="space-around"
        >
          {images.map((image, index) => (
            <Grid item xs={12} md={4} classes={{ root: classes.image }} key={index}>
              <Image {...image}/>
            </Grid>
          ))}
        </Grid>}
      </Grid>
    </Container>
  )
}
