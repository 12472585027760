import {
  Box, Button, Drawer, Grid,
} from '@material-ui/core'
import React, {
  useContext, useState,
} from 'react'
import BasketHeading from '../../NewOrderFlow/steps/CheckoutStep/Basket/BasketHeading'
import BasketIcon from '../../NewOrderFlow/steps/CheckoutStep/Basket/BasketIcon'
import CloseIcon from '@material-ui/icons/Close'
import { useRouter } from 'next/router'
import BasketDetails from '../../NewOrderFlow/steps/CheckoutStep/Basket/BasketDetails/BasketDetails'
import StyledButton from '../../StyledButton/StyledButton'
import {
  THREE_GIGA_IDS, useOrderContext,
} from '../../NewOrderFlow/OrderContext'
import { getOrderProducts } from '../../../utils/getOrderProducts'
import getStepUrl from '../../NewOrderFlow/getStepUrl'
import miniBasket from '../../../../public/miniBasket.svg'
import desktopClasses from '../DesktopHeader/DesktopHeader.styles'
import mobileClasses from '../MobileHeader/MobileHeader.styles'
import { cx } from 'linaria'
import { PremiseContext } from '../../PremiseTracker/PremiseContext'
import productsHaveSameContract from '../../../utils/productsHaveSameContract'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import { TechnologyType } from '../../../api/Addresses'
import MediaImage from '../../Media/MediaImage'
import { checkIfContinueDisabled } from '../../Products/Products'
import {
  COLORS, general,
} from '../../../styles/theme'
import { ServiceType } from '../../../utils/commonEnums'
import { checkForVoucher } from '../../Packages/Helpers'

interface MiniBasketProps {
  desktop?: boolean;
  anchor?: 'top' | 'bottom' | 'left' | 'right';
  errorMessage?: string;
}

export const MiniBasket = ({
  desktop, anchor, errorMessage,
}: MiniBasketProps) => {
  const [
    isBasketOpen,
    setIsBasketOpen,
  ] = useState<boolean>(false)
  const [
    showErrorMessage,
    setShowErrorMessage,
  ] = useState<boolean>(false)
  const { premise } = useContext(PremiseContext)
  const router = useRouter()
  const isEligibleFor3Giga = premise?.technology === TechnologyType.XGSPON || premise?.technology === TechnologyType.COMBO

  const {
    options: {
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
      alternativePhone,
      channel,
      cartInstanceIdentifier,
    },
    options,
    setOptions,
  } = useOrderContext()

  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })

  const classes = desktop ? desktopClasses : mobileClasses

  const premiseCanOrder = premise?.accepting === 'order'
  const eligibleProducts = isEligibleFor3Giga ? orderProducts : orderProducts.filter((product: any) => !THREE_GIGA_IDS.includes(product._id))
  const showProductsBasket = premiseCanOrder && eligibleProducts.some((product: any) => product.serviceType === ServiceType.BROADBAND)

  const goToCheckout = async () => {
    const sameContractLength = productsHaveSameContract({
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    })

    if (!sameContractLength) {
      setShowErrorMessage(true)
      return
    }

    if (options.offer === 'accepted' && (broadband?.voucher! || options.voucher_flags!.length > 0 || options.voucherProduct)) {
      const addVoucher = await checkForVoucher(broadband, options, channel, cartInstanceIdentifier)
      setOptions(addVoucher)
    }

    router.push(getStepUrl('checkout', premise?.sprn), undefined, { shallow: true })
  }

  if (!showProductsBasket) {
    return null
  }

  return (
    <Box
      mr={2}
      className={cx(desktop && desktopClasses.inline, !desktop && mobileClasses.miniBasketIcon)}
      position="relative"
    >
      <Button onClick={() => setIsBasketOpen(true)}>
        <MediaImage
          src={miniBasket}
          width={desktop ? 32 : 20}
          height={desktop ? 25 : 16}
          alt="basket with products"
        />
        <span
          className={cx(classes.productsNumber, general.font.weight.bold)}
        >
          {eligibleProducts.length}
        </span>
      </Button>
      <Drawer
        className={cx(desktop && desktopClasses.basketContainer)}
        anchor={anchor}
        open={isBasketOpen}
        onClose={() => setIsBasketOpen(false)}
      >
        <div
          role="presentation"
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={classes.basketHeading} color={COLORS.white}>
            <Grid container>
              <BasketIcon/>
              <BasketHeading broadband={broadband} bundle={bundle}/>
            </Grid>
            <Button variant="text" color="inherit" onClick={() => setIsBasketOpen(false)}>
              <CloseIcon/>
            </Button>
          </Box>
          <Grid container className={cx(classes.miniBasketContent, showErrorMessage && desktop && desktopClasses.miniBasketContentError)}>
            {(broadband || bundle) && <BasketDetails isMiniBasket bundle={bundle} broadband={broadband} setShowErrorMiniBasket={setShowErrorMessage}/>}
            <Grid container classes={{ root: classes.bottomContainer }}>
              {showErrorMessage && errorMessage &&
              <ErrorMessage
                className={classes.errorMessage}
                message={errorMessage}
                showErrorMessage={showErrorMessage}
                setShowErrorMessage={setShowErrorMessage}
              />}
              <Box className={classes.orderButton}>
                <StyledButton isFullWidth handleClick={goToCheckout} color="primary" disabled={checkIfContinueDisabled(broadband, bundle, voice, alternativePhone)}>
                  Continue with order
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </Box>
  )
}
