import React from 'react'
import { formatTimeForTimeslotData } from '../../../../Calendar/calendarUtils'
import { format } from 'date-fns'
import {
  Typography, Grid,
} from '@material-ui/core'
import { TimeslotData } from '../../../../../api/BookingCalendar'
import classes from './Basket.styles'

const InstallationSlot = ({
  timeslot,
  reachedInstallationStep,
}: { timeslot: TimeslotData; reachedInstallationStep: Boolean}) => {
  const earliestInstallationDate = new Date(timeslot.startDateTime)
  const selectedInstallationTimeZinier = formatTimeForTimeslotData(timeslot)

  return (
    <Grid item md={12} className={classes.installationContainer}>
      {reachedInstallationStep &&
      <div className={classes.selectedSlotContainer}>
        <Typography variant="body2">Selected installation date:</Typography>
        <Typography variant="body2">
          {format(earliestInstallationDate!, 'dd/MM/yyyy')} {' - '}
          {timeslot.label} {' '}
          {selectedInstallationTimeZinier}
        </Typography>
      </div>}
    </Grid>
  )
}

export default InstallationSlot
