import React from 'react'
import classnames from 'classnames'
import {
  Box, Fab, Typography,
} from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import classes from './BackToTopButton.styles'
import { general } from '../../styles/theme'

function onClick() {
  document.body.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

interface BackToTopButtonProps {
  className?: string;
}

export default function BackToTopButton({ className }: BackToTopButtonProps) {
  return (
    <Box className={classnames(classes.root, className)} component="span">
      <Typography component="span" variant="body2" color="secondary" className={general.font.weight.semibold}>Back to top</Typography>
      <Fab aria-label="back-to-top" className={classes.fab} onClick={onClick} size="small">
        <KeyboardArrowUpIcon className={classes.icon}/>
      </Fab>
    </Box>
  )
}
