import React from 'react'
import classnames from 'classnames'
import {
  Typography, Box,
} from '@material-ui/core'
import { css } from 'linaria'
import theme from '../../styles/theme'

export interface USPTaglineProps {
  readonly text: string;
  readonly colour: 'blue' | 'grey';
}

const classes = {
  blue: css`
    background-color: #58789E;
  `,
  grey: css`
    background-color: ${theme.palette.warning.main};
  `,
  root: css`
    color: white;
    .MuiTypography-h2 {
        font-size: 1.125rem;
      }
    ${theme.breakpoints.up('sm')} {
      .MuiTypography-h2 {
        font-size: 1.3125rem;
      }
    }
  `,
}

const colourClassMapping = {
  blue: classes.blue,
  grey: classes.grey,
}

export default function USPTagline({
  colour, text,
}: USPTaglineProps) {
  return (
    <Box className={classnames(classes.root, colourClassMapping[colour])} p={2}>
      <Typography variant="h2" align="center">{text}</Typography>
    </Box>
  )
}
