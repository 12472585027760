import React, {
  useEffect, useState,
} from 'react'
import ClockIcon from '@material-ui/icons/AccessTime'
import {
  Grid, Typography, Button, Modal,
} from '@material-ui/core'
import {
  useOrderContext,
} from '../../../OrderContext'
import { classes } from './TimeoutModal.styles'
import {
  ChangeCollapsible,
} from '../CheckoutStep'
import { useRouter } from 'next/router'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'

const TIMEOUT_DURATION = 10

function getTimeoutDuration(start: number | null): number {
  const fullLength = TIMEOUT_DURATION * 60000

  if (!start) {
    return fullLength
  }

  const offset = new Date()
    .getTime() - start

  const timeLeft = fullLength - offset
  if (timeLeft < 0) {
    return 0
  }

  return timeLeft
}

export default function TimeoutModal({
  changeCollapsible,
}:
  { changeCollapsible: ChangeCollapsible}) {
  const {
    options, setOptions,
  } = useOrderContext()

  const [
    modalOpen,
    setModalOpen,
  ] = useState<boolean>(false)

  const router = useRouter()

  const { countDownTimerStart } = options

  const buttonLabel = 'Select another installation appointment'

  useEffect(() => {
    if (countDownTimerStart === null) {
      return
    }

    const timer = setTimeout(() => {
      setModalOpen(true)
      setOptions({
        ...options,
        timeslot: null,
        countDownTimerStart: null,
      })
    }, getTimeoutDuration(countDownTimerStart))

    return () => {
      clearTimeout(timer)
    }
  }, [countDownTimerStart])

  function onChangeInstallationClick() {
    changeCollapsible('checkout-installation')
    marketingEvents.cta({
      name: buttonLabel,
      router,
      options,
    })
    setOptions({
      ...options,
      timeslotError: 'Your slot expired, please select another slot',
    })
    setModalOpen(false)
  }

  function onCancelClick() {
    router.push('/')
  }

  function handleClose() {
    setModalOpen(false)
  }

  return (
    <Modal
      aria-labelledby="timeout-modal-title"
      aria-describedby="timeout-modal-body"
      open={modalOpen}
      onClose={handleClose}
      className={classes.backdrop}
    >
      <div className={classes.modal}>
        <Grid container alignItems="center" direction="column" spacing={3}>
          <Grid item>
            <ClockIcon fontSize="large" className={classes.icon} color="primary"/>
          </Grid>
          <Grid item>
            <Typography color="primary" variant="h3" id="timeout-modal-title">Time&apos;s up</Typography>
          </Grid>
          <Grid item>
            <Typography color="primary" className={classes.bodyText} id="timeout-modal-body">Your installation appointment has expired.</Typography>
          </Grid>
          <Grid item>
            <Typography color="primary" className={classes.bodyText}>Would you like to select another installation appointment?</Typography>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary" fullWidth onClick={onChangeInstallationClick}>
              <Typography>{buttonLabel}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="default" fullWidth onClick={onCancelClick}>
              <Typography>Back to homepage</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
