import { css } from 'linaria'
import theme, {
  COLORS, fontWeights,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  contactForm: css`
    width: 100%;
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }
    form {
      .MuiFormLabel-root{
        font-size: ${fontSizes.body1};
        font-weight: ${fontWeights.bold};
        line-height: 1.5;
      }
      .MuiTextField-root{
        margin-top: ${theme.spacing(2)}px;
      }
      .MuiInputLabel-formControl {
        position: relative;
      }
      .MuiFormGroup-root[class*=radioButon] {
        flex-direction: row;
      }
      [class*=radioButon] .MuiFormControlLabel-label{
        font-weight: ${fontWeights.semibold};
        color: ${COLORS.primary};
      }
    label + .MuiInput-formControl{
      margin-top: 0;
    }
  `,
  inputLabel: css`
    .MuiTypography-root {
      margin: ${theme.spacing(3, 0)};
      font-size: ${fontSizes.body1};
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary};
    }
    &.MuiTypography-root {
      margin: ${theme.spacing(2, 0)};
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  checkbox: css`
    .MuiRadio-root {
        color: ${COLORS.primary};
      }
  `,
  radioButon: css`
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${COLORS.primary};
    }
  `,
  selectContainer: css`
    &.MuiGrid-root {
      margin-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(-1)}px;
    }
  }
`,
  selectClass: css`
  border: 1px solid ${COLORS.gray3};
    &.MuiInput-root {
      border-radius: ${theme.spacing(3)}px;
      background: ${COLORS.white};
      overflow: hidden;
      width: 100%;

      .MuiSelect-select.MuiSelect-select {
        padding-left: ${theme.spacing(3)}px;
        padding-top: 12px;
        padding-bottom: 14px;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
  `,
  activeSelect: css`
    &.MuiInput-root {
      background: ${COLORS.white};
      color: ${COLORS.primary};
      .MuiSelect-select.MuiSelect-select {
        color: ${COLORS.primary};
      } 
    }
  `,
  icon: css`
  padding-right: ${theme.spacing(1)}px;
  &.MuiSelect-icon {
    color: ${COLORS.primary};
  }
`,
  errorLabel: css`
    color: red !important;
  `,
  errorInput: css`
    border: 1px solid red !important;
  `,
  submitClasses: css`
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  loading: css`
    margin-left: ${theme.spacing(1)}px;
  `,
}
export default classes
