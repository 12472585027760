import React, {
  useEffect, useState,
} from 'react'
import {
  Button, Card, CardMedia, Grid, Typography, Hidden,
} from '@material-ui/core'
import classnames from 'classnames'
import classes from './TextWithMedia.styles'
import InfoExpansion from './InfoExpansion'
import StyledButton from '../StyledButton/StyledButton'
import { cx } from 'linaria'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { ContainerWithCurve } from '../../styles/ContainerWithCurve'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import { Item } from '../IconsWithText/IconsWithText'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import MediaVideo, { MediaVideoProps } from '../Media/MediaVideo'
import {
  checkIfRedirecterExists, getSettings,
} from '../../utils/useRedirecter'
import { useRouter } from 'next/router'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

export interface TextWithMediaProps {
  readonly body: string;
  readonly small_text_info?: string;
  readonly content_icons?: Item[];
  readonly image_alt?: string;
  readonly media_type: 'image' | 'video';
  readonly desktop_media_src?: string;
  readonly mobile_media_src?: string;
  readonly media_image?: MediaImageProps;
  readonly media_video?: MediaVideoProps;
  readonly media_position: 'left' | 'right';
  readonly mobile_media_position: 'up' | 'down';
  readonly background?: 'grey' | 'white';
  readonly button_text?: string;
  readonly button_url?: string;
  readonly secondary_button_text?: string;
  readonly secondary_button_url?: string;
  readonly modal?: boolean;
  readonly expansion_title?: string;
  readonly expansion_text?: string;
  readonly cancellation_buttons?: ButtonProps[];
  readonly anchor_text?: string;
  readonly anchor_url?: string;
  readonly arrow_url?: string;
  readonly anchor_id?: string;
}

interface MediaProps {
  hasMedia: boolean;
  mediaWidth: any;
  media_type: 'image' | 'video';
  readonly media_desktop_src?: string;
  readonly media_mobile_src?: string;
  readonly image_alt?: string;
  readonly media_image?: MediaImageProps;
  readonly media_video?: MediaVideoProps;
}

interface ButtonProps {
  text: string;
  url: string;
  icon: string;
}

enum MediaType {
  image = 'image',
  video = 'video',
}

interface CancellationButtonProps {
  readonly cancellation_buttons?: ButtonProps[];
}

const getMediaPosition = ({
  media_position, mobile_media_position,
}: {media_position: 'left' | 'right'; mobile_media_position?: 'up' | 'down'}) => {
  if (media_position === 'left') {
    if (mobile_media_position === 'up') {
      return ''
    }

    if (mobile_media_position === 'down') {
      return classes.mediaLeftAndDown
    }
  } else {
    if (mobile_media_position === 'up') {
      return classes.mediaRightAndUp
    }

    if (mobile_media_position === 'down') {
      return classes.mediaRightAndDown
    }

    return classes.reverseContent
  }

  return ''
}

const Media = ({
  hasMedia, mediaWidth, media_type, image_alt, media_desktop_src,
  media_mobile_src, media_image, media_video,
}: MediaProps) => {
  if (!hasMedia || !media_type) {
    return null
  }

  return (
    <Grid
      item xs={12} md={mediaWidth}
      key="media"
      className={classnames(media_type === MediaType.video && media_desktop_src && classes.mediaWrapper, classes.minWidth)}
    >
      {media_video && media_type === MediaType.video && (
        <MediaVideo
          src={media_video.src}
          src_mobile={media_video.src_mobile}
          controls={media_video.controls}
          loop={media_video.loop}
          auto_play={media_video.auto_play}
          width={media_video.width}
          height={media_video.height}
          youtube_id={media_video.youtube_id}
          ratio_16to9={media_video.ratio_16to9}
          pause_on_leave={media_video.pause_on_leave}
        />
      )}
      {media_desktop_src && media_type === MediaType.image && (
        <>
          <Hidden smDown>
            <CardMedia
              alt={image_alt}
              component="img"
              src={media_desktop_src}
            />
          </Hidden>
          <Hidden mdUp>
            <CardMedia
              alt={image_alt}
              component="img"
              src={media_mobile_src || media_desktop_src}
            />
          </Hidden>
        </>
      )}
      {media_image?.src &&
        <MediaImage
          src={media_image.src}
          width={media_image.width || 576}
          height={media_image.height || 480}
          alt={media_image.alt}
          mobile_src={media_image.mobile_src}
          mobile_width={media_image.mobile_width}
          mobile_height={media_image.mobile_height}
          center
          full_width
        />}
    </Grid>
  )
}

const CancellationButton = ({ cancellation_buttons }: CancellationButtonProps) => {
  if (cancellation_buttons!.length <= 0) {
    return null
  }

  return (
    <div className={classes.buttonsContainer}>
      {cancellation_buttons?.map((item, i) => {
        return (
          <div key={i} className={classes.buttonsWrapper}>
            <Button target="_blank" className={classnames(classes.cancellationButton)} disableElevation href={item.url} variant="contained">
              <Typography noWrap>{item.text}</Typography>
              <img className={classes.image} src={item.icon} alt=""/>
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default function TextWithMedia({
  body,
  small_text_info,
  content_icons,
  button_text,
  button_url,
  secondary_button_text,
  secondary_button_url,
  modal,
  cancellation_buttons,
  image_alt,
  media_position,
  mobile_media_position,
  background = 'white',
  expansion_title,
  expansion_text,
  anchor_text,
  anchor_url,
  arrow_url,
  anchor_id,
  media_type,
  desktop_media_src,
  mobile_media_src,
  media_image,
  media_video,
}: TextWithMediaProps) {
  const hasMedia = Boolean(desktop_media_src) || Boolean(mobile_media_src) || Boolean(media_image)

  const mediaPosition = getMediaPosition({
    media_position,
    mobile_media_position,
  })

  const textWidth = hasMedia ? 6 : 12

  const router = useRouter()
  const {
    options,
  } = useOrderContext()

  const [
    RedicterCTAVisible,
    setRedicterCTAVisible,
  ] = useState(false)

  const [
    isRedirecterPath,
    setIsRedirecterPath,
  ] = useState(false)

  useEffect(() => {
    (async () => {
      const settings = await getSettings()
      const redirecter = settings?.components.find(component => component.type === 'redirecter') as any
      const redirecterPath = redirecter?.path
      const currentPath = router.asPath
      setIsRedirecterPath(currentPath === redirecterPath)

      if (currentPath === redirecterPath && !checkIfRedirecterExists(settings) && button_text && button_url) {
        setRedicterCTAVisible(true)
      }
    })()
  }, [
    button_text,
    button_url,
    router.asPath,
  ])

  return (
    <SectionWithMargin hasTop id={anchor_id}>
      <div className={cx(classes.containerGen, background === 'grey' ? classes.container.gradient : classes.container.white)}>
        <ContainerWithPadding>
          <Card elevation={0} square>
            <Grid
              alignItems="center"
              classes={{ root: mediaPosition }}
              container
              direction="row"
              wrap="wrap"
              justify="space-between"
            >
              <Media media_type={media_type} hasMedia={hasMedia} mediaWidth={6} image_alt={image_alt} media_desktop_src={desktop_media_src} media_mobile_src={mobile_media_src} media_image={media_image} media_video={media_video}/>
              <Grid
                item xs={12} md={textWidth}
                key="text"
                container direction="column"
                wrap="wrap" classes={{ root: cx(media_position === 'left' ? classes.bodyLeft : classes.bodyRight) }}
              >
                <Wysiwyg body={body} className={classes.content} variant="body1"/>
                {
                  content_icons && (
                    <Grid className={(content_icons.length > 0 ? classes.iconsWithTextCustomIcons : '')} container direction="row">
                      {content_icons.map((item, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          className={classes.iconItemContainer}
                        >
                          {item.icon_url ? (
                            <a href={item.icon_url} target="_blank" rel="noreferrer noopener">
                              <img alt={item.title} src={item.image}/>
                            </a>
                          ) : (
                            <img alt={item.title} src={item.image}/>
                          )}
                          <Grid className="iconTextWrapper">
                            {item.title &&
                            <Typography variant="body1" className={classes.iconItemTitle}>
                              {item.title}
                            </Typography>}
                            <Typography
                              className={classes.iconItemText}
                              variant="body1"
                              component="div"
                            >
                              {/* eslint-disable-next-line react/no-danger */}
                              <div dangerouslySetInnerHTML={{ __html: item.text }}/>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )
                }
                {
                  small_text_info && (
                    <Typography className={classes.smallText}>
                      {small_text_info}
                    </Typography>
                  )
                }
                <Grid container item justify="center" direction="column" className={classes.ctaRow}>
                  {(RedicterCTAVisible || (!isRedirecterPath && button_text)) && (
                    <Grid item className={classes.primaryButton}>
                      <StyledButton
                        isModal={modal}
                        color="primary"
                        url={button_url}
                        {...RedicterCTAVisible && {
                          handleClick: () => {
                            if (typeof window !== 'undefined') {
                              localStorage.clear()
                            }

                            if (typeof window !== 'undefined') {
                              sessionStorage.clear()
                            }

                            marketingEvents.simpleEvent('redirecter_cta_click', options)
                          },
                        }}
                      >
                        {button_text}
                      </StyledButton>
                      {anchor_text &&
                        <Button className={classes.anchorButton} href={anchor_url}>
                          {anchor_text}
                        </Button>}
                    </Grid>
                  )}
                  {secondary_button_text && (
                    <Grid item className={classes.primaryButton}>
                      <StyledButton isModal={modal} color="secondary" variant="outlined" url={secondary_button_url}>
                        {secondary_button_text}
                      </StyledButton>
                      {anchor_text &&
                        <Button className={classes.anchorButton} href={anchor_url}>
                          {anchor_text}
                        </Button>}
                    </Grid>
                  )}
                </Grid>
                {(expansion_title && expansion_text) && (
                  <InfoExpansion
                    title={expansion_title}
                    text={expansion_text}
                  />
                )}
              </Grid>
            </Grid>
            <CancellationButton cancellation_buttons={cancellation_buttons}/>
          </Card>
        </ContainerWithPadding>
      </div>
      {background === 'grey' && (
        <ContainerWithCurve link={arrow_url}/>
      )}
    </SectionWithMargin>
  )
}
