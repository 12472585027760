import Axios from 'axios'
import { utmStore } from '../../storage'
import { sleep } from '..'
import { OrderOptions } from '../../components/NewOrderFlow/OrderContext'
import { calculatePriceForProducts } from '../calculatePriceForProducts'
import { isProduct } from '../isProduct'
import { getOrderProducts } from '../getOrderProducts'
import { getOrderProductsServiceType } from '../getOrderProductsServiceType'
import {
  getBasePriceNum, getContractType, getProductTerm, getSpeedNumber,
} from '../getProductDetails'
import { HobsServiceType } from '../../api/Products'
import { HobsProduct } from '../../api/Packages'

const legacyComissionGroupMap: any = {
  'RES-TV-ANNUAL-12-STD': 'TV_12M',
  'RES-TV-ANNUAL-24-STD': 'TV_24M',
  'RES-VOICE-EVERYDAYLANDLINESMOBILE-ANNUAL-12-STD': 'CALLUKLANDLINE_12M',
  'RES-VOICE-EVERYDAYLANDLINESMOBILE-ANNUAL-24-STD': 'CALLUKLANDLINE_24M',
}

const parseProductName = ((product: HobsProduct) => {
  const term = getProductTerm(product)

  if (product.service_type === HobsServiceType.BROADBAND || product.service_type === HobsServiceType.BUNDLE) {
    const speed = getSpeedNumber(product, 'DOWNLOAD_SPEED')
    return speed + 'MBPS_' + term + 'M'
  }

  return product.service_type?.toUpperCase() + '_' + term + 'M'
})

export async function legacyAwinTrack(order: any, accountId: string) {
  const utmParams = await utmStore.get()

  if (utmParams?.utm_source !== 'awin') {
    return
  }

  const price = order.product!.productPrices.reduce((p: any, x: any) => p + Number(x.discountAmount || x.price), 0)
    .toFixed(2)

  const awin: Window['AWIN'] = {
    Tracking: {
      Sale: {
        amount: price,
        orderRef: accountId,
        parts: `${order.product?.displayName.replace(/\s+/g, '_')}:${price}`,
        voucher: order.discount?.code,
        currency: 'GBP',
        test: '0',
        channel: 'aw',
      },
    },
  }

  if (window.AWIN) {
    window.AWIN = {
      ...window.AWIN,
      ...awin,
      Tracking: {
        ...window.AWIN.Tracking,
        ...awin.Tracking,
      },
    }

    if (window.AWIN.Tracking.run) {
      window.AWIN.Tracking.run()
      // Give awin some time to track before redirecting
      await sleep(2000)
    }
  }

  await Axios.get('https://www.awin1.com/sread.img', {
    validateStatus: () => true,
    params: {
      tt: 'ns',
      tv: '2',
      merchant: 19595,
      amount: price,
      ch: 'aw',
      cr: 'GBP',
      parts: `${order.product?.displayName.replace(/\s+/g, '_')}:${price}`,
      ref: accountId,
      testmode: 0,
      vc: order.discount?.code,
    },
  })
}

const VOICE_AWIN_COMMISSION_GROUP = 'CALLUKLANDLINESANDMOBILES'
const TV_AWIN_COMMISSION_GROUP = 'TV'

const getCommissionGroup = ((product: HobsProduct) => {
  if (legacyComissionGroupMap[product.productOfferingId!]) {
    return legacyComissionGroupMap[product.productOfferingId!]
  }

  switch (product.service_type) {
    case HobsServiceType.VOICE:
      // See ticket https://communityfibre.atlassian.net/browse/NDB002-767
      // We need to find a better way to track commission groups for voice
      return VOICE_AWIN_COMMISSION_GROUP
    case HobsServiceType.TV:
      // See ticket https://communityfibre.atlassian.net/browse/NDB002-767
      // We need to find a better way to track commission groups for TV
      return TV_AWIN_COMMISSION_GROUP
    default:
      return parseProductName(product)
  }
})

function mapProductToPart(product: HobsProduct): string {
  const commissionGroup = getCommissionGroup(product)

  return `${commissionGroup}:${getBasePriceNum(product)
    .toFixed(2)}`
}

function getAwinPartsString(products: HobsProduct[]): string {
  return products.map(mapProductToPart)
    .join('|')
}

export async function awinTrack(order: OrderOptions, accountId: string) {
  const utmParams = await utmStore.get()

  if (utmParams?.utm_source !== 'awin') {
    return
  }

  const price = calculatePriceForProducts(order)

  const {
    broadband, bundle, voice, tv, wier, vas,
  } = order

  const products = [
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  ].filter(product => isProduct(product)) as HobsProduct[]

  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })

  const serviceType = getOrderProductsServiceType(orderProducts)
  const productNames = products.map((product: HobsProduct) => {
    return getCommissionGroup(product)
  })
    .join('|')

  const awin: Window['AWIN'] = {
    Tracking: {
      Sale: {
        amount: price,
        custom: [
          getContractType(broadband),
          serviceType.toString(),
          productNames,
        ],
        orderRef: accountId,
        parts: getAwinPartsString(products),
        voucher: order.promo?.productOfferingId || 'null',
        currency: 'GBP',
        test: '0',
        channel: 'aw',
      },
    },
  }

  if (window.AWIN) {
    window.AWIN = {
      ...window.AWIN,
      ...awin,
      Tracking: {
        ...window.AWIN.Tracking,
        ...awin.Tracking,
      },
    }

    if (window.AWIN.Tracking.run) {
      window.AWIN.Tracking.run()
      // Give awin some time to track before redirecting
      await sleep(2000)
    }
  }

  await Axios.get('https://www.awin1.com/sread.img', {
    validateStatus: () => true,
    params: {
      tt: 'ns',
      tv: '2',
      merchant: 19595,
      amount: price,
      ch: 'aw',
      cr: 'GBP',
      parts: getAwinPartsString(products),
      ref: accountId,
      testmode: 0,
      vc: order.promo?.productOfferingId || 'null',
      p1: getContractType(broadband),
      p2: serviceType,
    },
  })
}
