import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme from '../../styles/theme'

const classes = {
  dialogModal: css`
    .MuiPaper-root {
      border-radius: ${commonStyling.roundedBorders.all} !important;
    }
    .MuiDialog-paper  {
      background-color: transparent;
      ${theme.breakpoints.up('md')} {
        display: flex;
        align-items: center;
      }
    }
    .MuiExpansionPanelDetails-root {
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing(0, 2, 2)};
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(0, 7)};
      }
    }
    .MuiExpansionPanelSummary-content {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .MuiTypography-body1 {
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: auto;
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  `,
  wrapper: css`
    margin: ${theme.spacing(2)}px;
    border: 1px solid ${theme.palette.error.main};
    border-radius: ${commonStyling.roundedBorders.all};
    outline: none;
    color: ${theme.palette.primary.main};
    .close-button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: ${theme.palette.error.main};
      margin-left: auto;
      padding: ${theme.spacing(1, 0, 0)};
      position: relative;
      right: -10px;
      .MuiSvgIcon-root {
        font-size: ${fontSizes.h2};
      }
      .MuiTypography-body1 {
        font-weight: ${theme.typography.fontWeightLight!};
        margin-bottom: 3px;
      }
    }
    ${theme.breakpoints.up('lg')} {
      margin-top: ${theme.spacing(5)}px;
      width: 55vw;
    }
  `,
  formWrapper: css`
    text-align: center;
    .MuiGrid-grid-lg-6 {
      max-width: 100%;
    }
    .MuiTypography-body1, .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
      a {
        color: ${theme.palette.primary.main};
      }
    }
    .MuiGrid-container {
      margin-top: ${theme.spacing(3)}px;
    }
    .MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.95) !important;
    }
    .MuiFormControlLabel-root {
      margin-top: ${theme.spacing(2)}px;
      .MuiFormControlLabel-label {
        text-align: left;
        font-size: ${fontSizes.body2};
      }
    }
  `,
  buttonWrapper: css`
    button {
      margin: ${theme.spacing(1, 0, 6)};
      width: 100% !important;
      ${theme.breakpoints.up('md')} {
        width: auto !important;
        padding: 0 ${theme.spacing(8)}px !important;
      }
    }
  `,
  openingTimes: css`
    text-align: left;
    padding: 4px 0 0 ${theme.spacing(1)}px !important;
    p {
      margin: 0;
      margin-top: ${theme.spacing(2)}px;
    }
  `,
  privacyText: css`
    text-align: left;
    padding: 4px 0 4px ${theme.spacing(1)}px !important;
    p {
      margin: 0;
      margin-bottom: ${theme.spacing(2)}px;
    }

    a {
      color: ${theme.palette.primary.main} !important;
    }
  `,
}

export default classes
