import React from 'react'
import {
  Container, Typography, Paper, Box, FormLabel,
  Grid, Button, Popper, PopperProps, TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {
  useForm, FormContext,
} from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import usePremiseSearch from '../../utils/hooks/usePremiseSearch'
import FormHeader from '../FormHeader/FormHeader'
import TextInput from '../TextInput/TextInput'
import createCRMApiClient from '../../api/CRMApi'
import CopyWithIcon, { CopyWithIconProps } from '../CopyWithIcon/CopyWithIcon'
import marketingEvents from '../../utils/marketing/marketingEvents'
import logger from '../../utils/logger'
import { SearchPremise } from '../../api/Addresses'
import classes from './LandLordsForm.styles'
import { SectionWithMargin } from '../../styles/SectionWithMargin'

export interface LandlordsFormProps {
  success_slug: string;
  error_slug: string;
  copy_with_icon: CopyWithIconProps;
  title: string;
  subtitle: string;
  body: string;
  anchor_ref: string;
}

export default function LandlordsForm({
  success_slug, error_slug, copy_with_icon, title, subtitle, body, anchor_ref,
}: LandlordsFormProps) {
  const methods = useForm()

  const {
    register, handleSubmit, setValue,
  } = methods

  const {
    setQuery, premises,
  } = usePremiseSearch()

  function handleListItemClick(
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    premise: SearchPremise | null,
  ) {
    if (premise && premise.address && premise.sprn) {
      setValue('address', premise.address)
      setValue('sprn', String(premise.sprn))
    } else {
      setValue('address', '')
      setValue('sprn', '')
    }
  }

  const onSubmit = handleSubmit(async ({
    address, sprn, organisation, fullName, contactNumber, email,
  }) => {
    const client = createCRMApiClient()
    const result = await client.leads.register({
      premise: {
        sprn: parseInt(sprn, 10),
        address,
      },
      company: {
        name: organisation,
        // Confirm with frank
        employees: '1-50',
      },
      premiseType: 'BUSINESS',
      customer: {
        firstName: fullName,
        lastName: fullName,
        email,
        phone: contactNumber,
        type: 'LANDLORD',
      },
      products: [],
    })

    if ('error' in result) {
      logger.error(result.message, result.error)
      return location.assign(error_slug)
    }

    marketingEvents.registerInterestLandlord()
    return location.assign(success_slug)
  })

  const DropdownPopper = (props: PopperProps) => {
    if (!premises[0]) {
      return null
    }

    return premises[0].address === '' ? null : (
      <Popper
        {...props}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      />
    )
  }

  return (
    <FormContext {...methods}>
      <Container>
        <SectionWithMargin>
          <Grid container direction="row">
            <Grid item lg={6} sm={12}>
              <CopyWithIcon {...copy_with_icon}/>
            </Grid>
            <Grid id={anchor_ref} item lg={6} sm={12} className={classes.form}>
              <Paper elevation={3} className={classes.wrapper}>
                <Box p={3} component="form" onSubmit={onSubmit}>
                  <Typography variant="h3" component="div" color="secondary">{title}</Typography>
                  <Typography variant="h5" component="div">{subtitle}</Typography>
                  <Typography variant="h5" component="div" className={classes.body}>{body}</Typography>
                  <Box pt={3} pb={3}>
                    <FormHeader title="Install property details"/>
                    <FormLabel component="legend" required className={classes.label}>Property address</FormLabel>
                    <div className={classes.searchWrapper}>
                      <Paper className={classes.searchRoot}>
                        <input hidden name="sprn" ref={register({ required: true })}/>
                        <Autocomplete
                          options={premises}
                          filterOptions={(options) => options}
                          getOptionLabel={(option) => option.address}
                          popupIcon={null}
                          onChange={handleListItemClick}
                          PopperComponent={DropdownPopper}
                          renderInput={(params) => {
                            return (
                              <TextField
                                type="text"
                                inputRef={register({ required: true })}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Look up your address"
                                className={classes.input}
                                name="address"
                                {...params}
                              />
                            )
                          }}
                        />
                        <SearchIcon color="disabled"/>
                      </Paper>
                    </div>
                    <FormHeader title="Install property details" withLegend={false}/>
                    <Grid container spacing={6}>
                      <TextInput required label="Full Name"/>
                      <TextInput required label="Email" placeholder="me@example.co.uk" type="email"/>
                      <TextInput required label="Contact Number" type="tel"/>
                      <TextInput required label="Organisation"/>
                    </Grid>
                  </Box>
                  <Button type="submit" variant="contained" color="primary" disableElevation>
                    <Typography noWrap>Submit</Typography>
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </SectionWithMargin>
      </Container>
    </FormContext>
  )
}
