import Butter from 'buttercms'
import {
  ReactNode,
} from 'react'
import { Merge } from 'type-fest'
import dynamic from 'next/dynamic'
import Header, { HeaderProps } from './components/Header/Header'
import HeroWithInputs, { HeroWithInputsProps } from './components/HeroWithInputs/HeroWithInputs'
import USPTagline, { USPTaglineProps } from './components/USPTagline/USPTagline'
import HotBoxes, { HotBoxesProps } from './components/HotBoxes/HotBoxes'
import Footer, { FooterProps } from './components/Footer/Footer'
import ContactSupport, { ContactSupportProps } from './components/ContactSupport/ContactSupport'
import CopyWithCTA, { CopyWithCTAProps } from './components/CopyWithCTA/CopyWithCTA'
import FAQDropdown, { FAQDropdownProps } from './components/FAQDropdown/FAQDropdown'
import HelpCategoryList, { HelpCategoryListProps } from './components/HelpCategoryList/HelpCategoryList'
import StaticHero, { StaticHeroProps } from './components/StaticHero/StaticHero'
import TermsSectionList, { TermsSectionListProps } from './components/Terms/TermsSectionList/TermsSectionList'
import TextWithImages, { TextWithImagesProps } from './components/TextWithImages/TextWithImages'
import TextWithMedia, { TextWithMediaProps } from './components/TextWithMedia/TextWithMedia'
import USPStrip, { USPStripProps } from './components/USPStrip/USPStrip'
import SearchBar, { SearchBarProps } from './components/SearchBar/SearchBar'
import SiteMap, { SiteMapProps } from './components/SiteMap/SiteMap'
import RegisterInterestCTA, { RegisterInterestCTAProps } from './components/RegisterInterestCTA/RegisterInterestCTA'
import RegisterInterestForm, { RegisterInterestFormProps } from './components/RegisterInterestForm/RegisterInterestForm'
import FormWithAddressLookup, { FormWithAddressLookupProps } from './components/FormWithAddressLookup/FormWithAddressLookup'
import LandlordsForm, { LandlordsFormProps } from './components/LandlordsForm/LandlordsForm'
import PartnersForm, { PartnersFormProps } from './components/PartnersForm/PartnersForm'
import PremiseTracker from './components/PremiseTracker/PremiseTracker'
import TabbedCopies, { TabbedCopiesProps } from './components/TabbedCopies/TabbedCopies'
import CurrentVacancies, { CurrentVacanciesProps } from './components/CurrentVacancies/CurrentVacancies'
import InfoBar, { InfoBarProps } from './components/InfoBar/InfoBar'
import CopyWithContact, { CopyWithContactProps } from './components/CopyWithContact/CopyWithContact'
import PressReleaseList, { PressReleaseListProps } from './components/Blog/PressReleaseList/PressReleaseList'
import MediaPageHeroRevamped, { MediaPageHeroRevampedProps } from './components/PaidMediaLandingPage/MediaPageHero/MediaPageHeroRevamped'
import MediaPageBanner, { MediaPageBannerProps } from './components/PaidMediaLandingPage/MediaPageBanner/MediaPageBanner'
import HelpChooseModal, { HelpChooseModalProps } from './components/HelpChooseModal/HelpChooseModal'
import PostcodeSearchModal, { PostcodeSearchModalProps } from './components/PostcodeSearchModal/PostcodeSearchModal'
import ComparisonModal, { ComparisonModalProps } from './components/ComparisonModal/ComparisonModal'
import ComparisonWidget, { ComparisonWidgetProps } from './components/ComparisonWidget/ComparisonWidget'
import BannerWithLogo, { BannerWithLogoProps } from './components/BannerWithLogo/BannerWithLogo'
import DropdownWithImage, { DropdownWithImageProps } from './components/DropdownWithImage/DropdownWithImage'
import OrderBottomBar, { OrderBottomBarProps } from './components/OrderBottomBar/OrderBottomBar'
import EnquiryBottomBar, { EnquiryBottomBarProps } from './components/EnquiryBottomBar.js/EnquiryBottomBar'
import FullWidthBanner, { FullWidthBannerProps } from './components/FullWidthBanner/FullWidthBanner'
import GridGallery, { GridGalleryProps } from './components/GridGallery/GridGallery'
import ContactUs, { ContactUsProps } from './components/ContactUs/ContactUs'
import ErrorMessage, { ErrorMessageProps } from './components/ErrorMessage/ErrorMessage'
import AbandonedCartModal, { AbandonedCartModalProps } from './components/AbandonedCartModal/AbandonedCartModal'
import CallMeBackModal, { CallMeBackModalProps } from './components/CallMeBackModal/CallMeBackModal'
import PersonalisedUnit, { PersonalisedUnitProps } from './components/NewOrderFlow/steps/CheckoutStep/ConfirmationPage/PersonalisedUnit/PersonalisedUnit'
import { IconsWithTextProps } from './components/IconsWithText/IconsWithText'
import VoucherModal, { VoucherModalProps } from './components/VoucherModal/VoucherModal'
import ExistingCustomerModal, { ExistingCustomerModalProps } from './components/ExistingCustomerModal/ExistingCustomerModal'
import StrikethroughName, { StrikethroughNameProps } from './components/StrikethroughName/StrikethroughName'
const IconsWithText = dynamic(() => import('./components/IconsWithText/IconsWithText'), {
  ssr: false,
})
const ImageCard = dynamic(() => import('./components/ImageCard/ImageCard'), {
  ssr: false,
})
import { ImageCardProps } from './components/ImageCard/ImageCard'
const HeroHeader = dynamic(() => import('./components/HeroHeader/HeroHeader'), {
  ssr: false,
})
import { HeroHeaderProps } from './components/HeroHeader/HeroHeader'
import SpeedTabs, { SpeedTabsProps } from './components/SpeedTabs/SpeedTabs'
import WarningModalMessage, { WarningModalMessageProps } from './components/WarningModalMessage/WarningModalMessage'
import StickyCheckAvailability, { StickyCheckAvailabilityProps } from './components/StickyCheckAvailability/StickyCheckAvailability'
import PurpleBanner, { PurpleBannerProps } from './components/PurpleBanner/PurpleBanner'
import InfoCards, { InfoCardsProps } from './components/InfoCards/InfoCards'
import { ValuePropsProps } from './components/ValueProps/ValueProps'
const ValueProps = dynamic(() => import('./components/ValueProps/ValueProps'), {
  ssr: false,
})

import env from './utils/env'
import { isProd } from './env'
import TabsWithProgressBar, { TabsWithProgressBarProps } from './components/TabsWithProgressBar/TabsWithProgressBar'
import Timeline, { TimelineProps } from './components/Timeline/Timeline'
import PolygonMap from './components/PolygonMap/PolygonMap'
import { PolygonMapProps } from './components/PolygonMap/PolygonMapInterfaces'
import StudentBeans from './components/StudentBeansIframe/StudentBeansIframe'
import MsDynamicsUnsubscribe from './components/MsDynamics/MsDynamicsUnsubscribe'
import StillInContractForm, { StillIncontractFormProps } from './components/StillInContractForm/StillInContractForm'
import StillInContractNotInModal, { StillInContractNotInModalProps } from './components/StillInContractNotInModal/StillInContractNotInModal'
import WebForm, { WebFormProps } from './components/WebForm/WebForm'
import EmbedCode, { EmbedCodeProps } from './components/EmbedCode/EmbedCode'
import IframeHolder from './components/IframeHolder/IframeHolder'
import Heading, { HeadingProps } from './components/Heading/Heading'
import OfferBanner, { OfferBannerProps } from './components/OfferBanner/OfferBanner'
import HighlightingTag, { HighlightingTagProps } from './components/PaidMediaLandingPage/MediaPageHero/HighlightingTag'
import HeroSection, { HeroSectionProps } from './components/HeroSection/HeroSection'
import MetadataControl, { MetadataControlProps } from './components/MetadataControl/MetadataControl'
import ExperianValidation, { ExperianProps } from './components/ExperianValidation/ExperianValidation'
import MediaImage, { MediaImageProps } from './components/Media/MediaImage'
import Packages, { PackagesProps } from './components/Packages/Packages'
import Products, { ProductsProps } from './components/Products/Products'
import Bundles, { BundlesProps } from './components/Bundles/Bundles'
import MediaVideo, { MediaVideoProps } from './components/Media/MediaVideo'
import TrustPilotBox, { TrustPilotBoxProps } from './components/TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import ContactUsForm, { ContactUsFormProps } from './components/ContactUsForm/ContactUsForm'
import { Redirecter } from './utils/useRedirecter'
import ResidentialPackageBanner, { ResidentialPackageBannerProps } from './components/ResidentialPackageBanner/ResidentialPackageBanner'
import CustomTable, { CustomTableProps } from './components/CustomTable/CustomTable'

type MenuComponent = {
  type: 'menu';
} & HeaderProps

type HeroWithInputs = {
  type: 'hero_with_inputs';
} & HeroWithInputsProps

type UpsTagline = {
  type: 'ups_tagline';
} & USPTaglineProps

type HotBoxes = {
  type: 'hot_boxes';
} & HotBoxesProps

type Footer = {
  type: 'footer';
} & FooterProps

type ContactSupport = {
  type: 'contact_support';
} & ContactSupportProps

type CopyWithCTA = {
  type: 'copy_with_cta';
} & CopyWithCTAProps

type FAQDropdown = {
  type: 'faq_dropdown';
} & FAQDropdownProps

type HelpCategoryList = {
  type: 'help_category_list';
} & HelpCategoryListProps

type SiteMap = {
  type: 'site_map';
} & SiteMapProps

type StaticHero = {
  type: 'static_hero';
} & StaticHeroProps

type TermsSectionList = {
  type: 'terms_section';
} & TermsSectionListProps

type TextWithImages = {
  type: 'text_with_images';
} & TextWithImagesProps

type TextWithMedia = {
  type: 'text_with_media';
} & TextWithMediaProps

type USPStrip = {
  type: 'usp_strip';
} & USPStripProps

type SearchBar = {
  type: 'search_bar';
} & SearchBarProps

type RegisterInterestCTA = {
  type: 'register_interest_cta';
} & RegisterInterestCTAProps

type RegisterInterestForm = {
  type: 'register_interest_form';
} & RegisterInterestFormProps

type FormWithAddressLookup = {
  type: 'form_with_address_lookup';
} & FormWithAddressLookupProps

type LandlordsForm = {
  type: 'landlords_form';
} & LandlordsFormProps

type PartnersForm = {
  type: 'partners_form';
} & PartnersFormProps

type PremiseTracker = {
  type: 'premise_tracker';
}

type ICurrentVacancies = {
  type: 'current_vacancies';
} & CurrentVacanciesProps

type TabbedCopies = {
  type: 'tabbed_copies';
} & TabbedCopiesProps

type InfoBar = {
  type: 'info_bar';
} & InfoBarProps

type CopyWithContact = {
  type: 'copy_with_contact';
} & CopyWithContactProps

type PressReleaseList = {
  type: 'press_release_list';
} & PressReleaseListProps

type MediaPageHeroRevamped = {
  type: 'media_page_hero_revamped';
} & MediaPageHeroRevampedProps

type MediaPageBanner = {
  type: 'media_page_banner';
} & MediaPageBannerProps

type HelpChooseModal = {
  type: 'help_choose_modal';
} & HelpChooseModalProps

type PostcodeSearchModal = {
  type: 'postcode_search_modal';
} & PostcodeSearchModalProps

type ComparisonModal = {
  type: 'comparison_modal';
} & ComparisonModalProps

type ComparisonWidget = {
  type: 'comparison_widget';
} & ComparisonWidgetProps

type BannerWithLogo = {
  type: 'banner_with_logo';
} & BannerWithLogoProps

type DropdownWithImage = {
  type: 'dropdown_with_image';
} & DropdownWithImageProps

type OrderBottomBar = {
  type: 'order_bottom_bar';
} & OrderBottomBarProps

type EnquiryBottomBar = {
  type: 'enquiry_bottom_bar';
} & EnquiryBottomBarProps

type FullWidthBanner = {
  type: 'full_width_banner';
} & FullWidthBannerProps

type GridGallery = {
  type: 'grid_gallery';
} & GridGalleryProps

type ContactUs = {
  type: 'contact_us';
} & ContactUsProps
type ErrorMessage = {
  type: 'error_message';
} & ErrorMessageProps

type AbandonedCartModal = {
  type: 'abandoned_cart_modal';
} & AbandonedCartModalProps

type CallMeBackModal = {
  type: 'call_me_back_modal';
} & CallMeBackModalProps

type PersonalisedUnit = {
  type: 'personalised_unit';
} & PersonalisedUnitProps

type IconsWithText = {
  type: 'icons_with_text';
} & IconsWithTextProps

type ImageCard = {
  type: 'image_card';
} & ImageCardProps

type HeroHeader = {
  type: 'hero_header';
} & HeroHeaderProps

type PurpleBanner = {
  type: 'hero_header';
} & PurpleBannerProps

type WarningModalMessage = {
  type: 'warning_message';
} & WarningModalMessageProps

type StickyCheckAvailability = {
  type: 'sticky_check_availability';
} & StickyCheckAvailabilityProps

type SpeedTabs = {
  type: 'speed_tabs';
} & SpeedTabsProps

type TabsWithProgressBar = {
  type: 'tabs_with_progress_bar';
} & TabsWithProgressBarProps

type InfoCards = {
  type: 'info_cards';
} & InfoCardsProps

type StudentBeans = {
  type: 'student_beans';
}
type Timeline = {
  type: 'timeline';
} & TimelineProps

type MsDynamicsUnsubscribe = {
  type: 'unsubscribe_ms_dynamics';
}

type StilInContractForm = {
  type: 'still-in-contract-form';
} & StillIncontractFormProps

type StillInContractNotInModal = {
  type: 'still-in-contract-not-in-modal';
} & StillInContractNotInModalProps

type WebForm = {
  type: 'webForm';
} & WebFormProps

type EmbedCode = {
  type: 'embed_code';
} & EmbedCodeProps

type IframeHolder = {
  type: 'iframe_holder';
}

type OrderRedirect = {
  type: 'order_status_code_url_redirect';
}

type PolygonMap = {
  type: 'polygon_map';
} & PolygonMapProps

type Heading = {
  type: 'heading';
} & HeadingProps

type OfferBanner = {
  type: 'offer_banner';
} & OfferBannerProps

type HighlightingTag = {
  type: 'affiliates_highlighting_tag';
} & HighlightingTagProps

type HeroSection = {
  type: 'hero_section';
} & HeroSectionProps

type MetadataControl = {
  type: 'metadata_control';
} & MetadataControlProps

type ExperianValidation = {
  type: 'experian_validation';
} & ExperianProps

type MediaImage = {
  type: 'media_image';
} & MediaImageProps

type Packages = {
  type: 'packages';
} & PackagesProps

type Products = {
  type: 'products';
} & ProductsProps

type ValueProps = {
  type: 'value_props';
} & ValuePropsProps

type Bundles = {
  type: 'bundles';
} & BundlesProps

type MediaVideo = {
  type: 'media_video';
} & MediaVideoProps

type TrustPilotBox = {
  type: 'trustpilot_box';
} & TrustPilotBoxProps

type ContactUsForm = {
  type: 'contact_us_form';
} & ContactUsFormProps

type VoucherModal = {
  type: 'voucher_modal';
} & VoucherModalProps

type ExistingCustomerModal = {
  type: 'existing_customer_modal';
} & ExistingCustomerModalProps

type StrikethroughName ={
  type: 'strikethrough_name';
} & StrikethroughNameProps

type ResidentialPackageBanner = {
  type: 'pricing_package_banner';
} & ResidentialPackageBannerProps

type CustomTable = {
  type: 'custom_table';
} & CustomTableProps

export type ButterCMSComponent =
  | MenuComponent
  | HeroWithInputs
  | UpsTagline
  | HotBoxes
  | Footer
  | ContactSupport
  | CopyWithCTA
  | FAQDropdown
  | HelpCategoryList
  | SiteMap
  | StaticHero
  | TermsSectionList
  | TextWithImages
  | USPStrip
  | SearchBar
  | RegisterInterestCTA
  | RegisterInterestForm
  | LandlordsForm
  | PremiseTracker
  | TabbedCopies
  | ICurrentVacancies
  | InfoBar
  | CopyWithContact
  | PressReleaseList
  | MediaPageHeroRevamped
  | MediaPageBanner
  | HelpChooseModal
  | PostcodeSearchModal
  | ComparisonModal
  | BannerWithLogo
  | DropdownWithImage
  | OrderBottomBar
  | EnquiryBottomBar
  | FullWidthBanner
  | GridGallery
  | ContactUs
  | ErrorMessage
  | AbandonedCartModal
  | CallMeBackModal
  | PersonalisedUnit
  | IconsWithText
  | ImageCard
  | HeroHeader
  | PurpleBanner
  | WarningModalMessage
  | StickyCheckAvailability
  | SpeedTabs
  | TabsWithProgressBar
  | InfoCards
  | Timeline
  | PolygonMap
  | StudentBeans
  | Timeline
  | MsDynamicsUnsubscribe
  | ValueProps
  | StilInContractForm
  | StillInContractNotInModal
  | WebForm
  | EmbedCode
  | IframeHolder
  | OrderRedirect
  | Heading
  | OfferBanner
  | HighlightingTag
  | HeroSection
  | ComparisonWidget
  | MetadataControl
  | ExperianValidation
  | MediaImage
  | Packages
  | Products
  | Bundles
  | MediaVideo
  | TrustPilotBox
  | ContactUsForm
  | Redirecter
  | VoucherModal
  | ExistingCustomerModal
  | StrikethroughName
  | ResidentialPackageBanner
  | CustomTable

type ButterReactComponent = Merge<ReactNode, {
  getInitialProps?(props: any): Promise<any>;
  replaceComponents?(props: any, query: { [key: string]: string }): Promise<ButterCMSComponent[]>;
}>

const butter = Butter(env.butterCMSToken, undefined, 10000, (axios) => {
  axios.interceptors.request.use(config => ({
    ...config,
    params: {
      ...config.params,
      levels: 5,
    },
  }))
})

export interface ButterPage {
  components: ButterCMSComponent[];
  title: string;
  description?: string;
  keywords?: string;
}

export async function getButterPage(path: string, query: { [key: string]: string }): Promise<ButterPage> {
  const result = isProd ? await butter.page.retrieve('*', path) : await butter.page.retrieve('*', path, {
    preview: 1,
  })

  const fields = result.data.data.fields
  const keywords = fields.keywords || null
  const title = fields.title || 'Community Fibre'
  const description = fields.description || null

  let arr: {
    component: null | ButterReactComponent;
    props: ButterCMSComponent;
  }[] = []

  await Promise.all(Object.values(fields)
    .map((props: ButterCMSComponent) => ({
      component: getButterComponentByType(props.type),
      props,
    }))
    .map(async ({
      component,
      props,
    }) => {
      if (component && component.replaceComponents) {
        const results = await component.replaceComponents(props, query)

        arr = arr.concat(results.map(props => ({
          component: getButterComponentByType(props.type),
          props,
        })))
      } else {
        arr.push({
          component,
          props,
        })
      }
    }))

  const components = await Promise.all(
    arr.map(async ({
      component,
      props,
    }) => {
      return {
        ...props,
        ...(component && component.getInitialProps && await component.getInitialProps(props)),
      }
    }),
  )

  return {
    components,
    description,
    keywords,
    title,
  }
}

const butterCMSToReactComponentMap: { [key: string]: ButterReactComponent } = {
  menu: Header,
  hero_with_inputs: HeroWithInputs,
  usp_tagline: USPTagline,
  hot_boxes: HotBoxes,
  footer: Footer,
  contact_support: ContactSupport,
  copy_with_cta: CopyWithCTA,
  faq_dropdown: FAQDropdown,
  help_category_list: HelpCategoryList,
  static_hero: StaticHero,
  terms_section_list: TermsSectionList,
  text_with_images: TextWithImages,
  text_with_media: TextWithMedia,
  usp_strip: USPStrip,
  search_bar: SearchBar,
  site_map: SiteMap,
  register_interest_cta: RegisterInterestCTA,
  register_interest_form: RegisterInterestForm,
  form_with_address_lookup: FormWithAddressLookup,
  landlords_form: LandlordsForm,
  partners_form: PartnersForm,
  premise_tracker: PremiseTracker,
  tabbed_copies: TabbedCopies,
  current_vacancies: CurrentVacancies,
  info_bar: InfoBar,
  copy_with_contact: CopyWithContact,
  press_release_list: PressReleaseList,
  media_page_hero_revamped: MediaPageHeroRevamped,
  media_page_banner: MediaPageBanner,
  help_choose_modal: HelpChooseModal,
  postcode_search_modal: PostcodeSearchModal,
  comparison_modal: ComparisonModal,
  comparison_widget: ComparisonWidget,
  banner_with_logo: BannerWithLogo,
  dropdown_with_image: DropdownWithImage,
  order_bottom_bar: OrderBottomBar,
  enquiry_bottom_bar: EnquiryBottomBar,
  full_width_banner: FullWidthBanner,
  grid_gallery: GridGallery,
  contact_us: ContactUs,
  error_message: ErrorMessage,
  abandoned_cart_modal: AbandonedCartModal,
  call_me_back_modal: CallMeBackModal,
  personalised_unit: PersonalisedUnit,
  icons_with_text: IconsWithText,
  image_card: ImageCard,
  hero_header: HeroHeader,
  purple_banner: PurpleBanner,
  speed_tabs: SpeedTabs,
  warning_message: WarningModalMessage,
  sticky_check_availability: StickyCheckAvailability,
  tabs_with_progress_bar: TabsWithProgressBar,
  info_cards: InfoCards,
  value_props: ValueProps,
  timeline: Timeline,
  polygon_map: PolygonMap,
  student_beans: StudentBeans,
  unsubscribe_ms_dynamics: MsDynamicsUnsubscribe,
  still_in_contract_form: StillInContractForm,
  still_in_contract_not_in_modal: StillInContractNotInModal,
  webform: WebForm,
  embed_code: EmbedCode,
  iframe_holder: IframeHolder,
  heading: Heading,
  offer_banner: OfferBanner,
  affiliates_highlighting_tag: HighlightingTag,
  hero_section: HeroSection,
  metadata_control: MetadataControl,
  experian_validation: ExperianValidation,
  media_image: MediaImage,
  packages: Packages,
  products: Products,
  bundles: Bundles,
  media_video: MediaVideo,
  trustpilot_box: TrustPilotBox,
  contact_us_form: ContactUsForm,
  voucher_modal: VoucherModal,
  existing_customer_modal: ExistingCustomerModal,
  strikethrough_name: StrikethroughName,
  pricing_package_banner: ResidentialPackageBanner,
  custom_table: CustomTable,
}

export function getButterComponentByType(type: string): null | ButterReactComponent {
  const Component = butterCMSToReactComponentMap[type]

  if (!Component) {
    return null
  }

  return Component
}

export default butter
