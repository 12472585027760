import React from 'react'
import classes from './Basket.styles'
import {
  Typography, Grid, Link, Box,
} from '@material-ui/core'
import BasketIcon from './BasketIcon'
import BasketDetails from './BasketDetails/BasketDetails'
import { useRouter } from 'next/router'
import OrderButton from './OrderButton'
import BasketHeading from './BasketHeading'
import getStepUrl from '../../../getStepUrl'
import { TimeslotData } from '../../../../../api/BookingCalendar'
import InstallationSlot from './InstallationSlot'
import MediaImage from '../../../../Media/MediaImage'
import { HobsProduct } from '../../../../../api/Packages'
import { COLORS } from '../../../../../styles/theme'

const DesktopBasket = ({
  broadband, bundle, timeslot, reachedInstallationStep,
}: { broadband?: HobsProduct; bundle?: HobsProduct; timeslot: TimeslotData | null; reachedInstallationStep: Boolean}) => {
  const router = useRouter()
  const onOrderstep = router.asPath === getStepUrl('checkout-order-summary')

  return (
    <div className={classes.stickyDesktopContainer}>
      <div className={classes.container}>
        <Box
          className={classes.monthlyHeader}
          bgcolor={COLORS.primary}
        >
          <BasketHeading broadband={broadband} bundle={bundle}/>
          <BasketIcon/>
        </Box>
        <BasketDetails bundle={bundle} broadband={broadband} hasTimeslot={Boolean(timeslot)}/>
        {timeslot && <InstallationSlot timeslot={timeslot} reachedInstallationStep={reachedInstallationStep}/>}
      </div>
      <Grid container alignItems="center" justify="center" className={classes.desktopDirectDebit}>
        <Grid item md={12}>
          <Box py={2}>
            {onOrderstep && <OrderButton id="DesktopBasket_OrderButton"/>}
          </Box>
        </Grid>
        <Grid item lg={3}>
          <MediaImage
            src="/directDebitLogo.svg"
            width={87}
            height={28}
            alt="Direct debit"
          />
        </Grid>
        <Grid item lg={9}>
          <Typography variant="body2">Your payments are protected by the&nbsp;
            <Link underline="always" href="/legal-stuff#Direct-Debit-Guarantee" target="_blank" rel="noopener noreferrer">
              Direct Debit Guarantee
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default DesktopBasket
