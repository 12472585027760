import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  itemsWrapper: css`
    &.MuiGrid-container {
      max-width: 288px;
      margin: auto;

      &.MuiGrid-spacing-xs-2 > .MuiGrid-item {
          padding: 12px 8px;
        }
      
      ${theme.breakpoints.up('sm')} {
        max-width: 757px;
        padding-top: 19px;
        
        &.MuiGrid-spacing-xs-3 > .MuiGrid-item {
          padding: 0 10px;
        }
      }
    }
  `,
  title: css`
    &.MuiTypography-root {
      margin-bottom: ${theme.spacing(6)}px;
      text-align: center;

      ${theme.breakpoints.up('sm')} {
        font-size: 35px;
      }
    }
  `,
  justifyEnd: css`
    justify-content: flex-end;
  `,
  justifyCenter: css`
    justify-content: center;
  `,
  backgroundImage: css`
    position: absolute;
    top: 248px;
    left: 0;
    bottom: 128px;
    right: 0;
    background-repeat: repeat-y;
    max-width: 308px;
    margin: auto;
    
    ${theme.breakpoints.up('sm')} {
      max-width: 761px;
      top: 92px;
      bottom: 166px;
      background-repeat: no-repeat;
    }
  `,
  sectionClass: css`
    position: relative;
  `,
}

export default classes
