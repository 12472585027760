import env from '../../utils/env'
import Axios from 'axios'
import GetToken from './GetToken'
import Appointments from './Appointments'

class CFOAuthApi {
  public readonly getToken: ReturnType<typeof GetToken>
  public readonly appointments: ReturnType<typeof Appointments>
  constructor() {
    const axiosInstanceUrlencoded = Axios.create({
      baseURL: env.CFOAuthApiUrl,
      responseType: 'json',
      headers: {
        scope: env.CFOAuthClientScope,
        client_secret: env.CFOAuthClientSecret,
        client_id: env.CFOAuthClientSecret,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    const axiosInstanceZinier = Axios.create({
      baseURL: `${env.ZinierApiUrl}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    axiosInstanceZinier.interceptors.request.use(
      (config) => {
        const token = sessionStorage && sessionStorage.getItem('access_token')
        if (token) {
          config.headers.authorization = `Bearer ${token}`
        }

        return config
      },
      (error) => Promise.reject(error),
    )
    this.getToken = GetToken(axiosInstanceUrlencoded)
    this.appointments = Appointments(axiosInstanceZinier)
  }
}

export default function createCFOApiClient(): CFOAuthApi {
  return new CFOAuthApi()
}
