import React from 'react'
import {
  ExpandMore,
  InfoOutlined,
} from '@material-ui/icons'
import {
  Box,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import classes from './InfoExpansion.styles'
import Wysiwyg from '../Wysiwyg/Wysiwyg'

const InfoExpansion = ({
  title,
  text,
}: { title: string; text: string }) => {
  return (
    <div className={classes.expansionPanel}>
      <ExpansionPanel elevation={0} className={classes.mainPanel}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore color="secondary"/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box padding="0.5rem">
            <InfoOutlined color="secondary"/>
            <Typography color="secondary" variant="body1" component="span">{title}</Typography>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Wysiwyg body={text} variant="body2"/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default InfoExpansion
