import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid, Typography,
} from '@material-ui/core'
import classes, { imageSizes } from './IconsWithText.styles'
import { cx } from 'linaria'
import Slider from 'react-slick'
import { useWindowWidth } from '../../utils/useWindowWidth'
import StyledButton from '../StyledButton/StyledButton'
import { NextImage } from '../Image/NextImage'
import {
  ExpandLess, ExpandMore,
} from '@material-ui/icons'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import WrapperWithSection from '../Wrapper/WrapperWithSection'

export enum ButtonType {
  solid = 'solid',
  arrow = 'arrow'
}

export interface Item {
  readonly title?: string;
  readonly image?: string;
  readonly image_alt_text?: string;
  readonly text: string;
  readonly icon_url?: string;
  readonly media_image?: MediaImageProps;
}

export enum BackgroundColorTypes {
  white = 'white',
  grey = 'grey',
  primary = 'primary'
}

type ItemsPerRow = 2|3;

export interface IconsWithTextProps {
  readonly title?: string;
  readonly large_title?: string;
  readonly body?: string;
  readonly mobile_carousel: boolean;
  readonly paddings?: boolean;
  readonly remove_margin_top?: boolean;
  readonly anchor_ref: string;
  readonly items: Item[];
  readonly background_colour?: BackgroundColorTypes;
  readonly white_text?: boolean;
  readonly button_expand_text: string;
  readonly button_shrink_text: string;
  readonly button_type?: ButtonType;
  readonly button_url?: string;
  readonly show_all_icons: boolean;
  readonly grid_based?: boolean;
  items_per_row?: ItemsPerRow|null;
  readonly iconsize?: 'small'|'medium'|'large';
  hide_me?: boolean;
  noSection?: boolean;
}

export default function IconsWithText({
  title,
  large_title,
  body,
  anchor_ref,
  mobile_carousel,
  paddings,
  remove_margin_top,
  items,
  background_colour,
  white_text,
  button_expand_text,
  button_shrink_text,
  button_type,
  button_url,
  show_all_icons,
  items_per_row,
  iconsize,
  grid_based,
  hide_me,
  noSection,
}: IconsWithTextProps) {
  const defaultExpanded = (Boolean(items_per_row) || show_all_icons) || false
  const [
    expanded,
    setExpanded,
  ] = useState(defaultExpanded)

  const windowWidth = useWindowWidth()

  const toggleButton = () => {
    setExpanded(!expanded)
  }

  const sliderSettings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 400,
  }

  const itemToSlice = items_per_row ?? 3
  const itemsToShow = expanded ? items : items.slice(0, itemToSlice)
  const buttonText = expanded ? button_shrink_text : button_expand_text
  const isCarousel = mobile_carousel && windowWidth && windowWidth < 600
  const nextImageSize = (iconsize && imageSizes[iconsize]) || imageSizes.small

  let backgroundColor = ''
  if (background_colour === BackgroundColorTypes.grey) {
    backgroundColor = classes.greyGradientBackground
  } else if (background_colour === BackgroundColorTypes.primary) {
    backgroundColor = classes.primaryGradientBackground
  }

  if (hide_me) {
    return null
  }

  return (
    <Box
      component={noSection ? Box : WrapperWithSection}
      {...remove_margin_top && { pt: 0 }}
      className={background_colour && backgroundColor}
    >
      <div
        className={cx(
          !isCarousel && classes.section,
          white_text && classes.whiteText,
          paddings && classes.paddings,
        )}
        id={anchor_ref}
      >
        <div className={cx(!mobile_carousel && classes.fullWidth, 'iconsWithTextWrapper')}>
          {(body || title) &&
          <Box className={classes.titleWrapper} mb={6}>
            {
              title && (
                <Typography
                  className={cx(large_title && classes.largeTitle, classes.title)}
                  variant="h2"
                >
                  {title}
                </Typography>
              )
            }
            {
              body && (
                <Typography variant="body1">
                  {body}
                </Typography>
              )
            }
          </Box>}
          {isCarousel ? (
            <Slider {...sliderSettings} className={classes.slider}>
              {itemsToShow?.map((item, index) => (
                <div
                  key={index}
                >
                  {item.icon_url ? (
                    <a href={item.icon_url} target="_blank" rel="noreferrer noopener">
                      {item.image && <img alt={item.image_alt_text} src={item.image}/>}
                      {item.media_image?.src && (
                        <MediaImage
                          src={item.media_image.src}
                          width={item.media_image.width || 64}
                          height={item.media_image.height || 64}
                          alt={item.media_image.alt}
                          mobile_src={item.media_image.mobile_src}
                          mobile_width={item.media_image.mobile_width}
                          mobile_height={item.media_image.mobile_height}
                          use_img_for_seo={item.media_image.use_img_for_seo}
                          className={classes.mediaWrapper}
                          center
                        />
                      )}
                    </a>
                  ) : (
                    <>
                      {item.image && <img alt={item.image_alt_text} src={item.image}/>}
                      {item.media_image?.src && (
                        <MediaImage
                          src={item.media_image.src}
                          width={item.media_image.width || 64}
                          height={item.media_image.height || 64}
                          alt={item.media_image.alt}
                          mobile_src={item.media_image.mobile_src}
                          mobile_width={item.media_image.mobile_width}
                          mobile_height={item.media_image.mobile_height}
                          use_img_for_seo={item.media_image.use_img_for_seo}
                          className={classes.mediaWrapper}
                          center
                        />
                      )}
                    </>
                  )}
                  {item.title &&
                  <Typography variant="body1" className={classes.itemTitle}>
                    {item.title}
                  </Typography>}
                  <Typography
                    className={classes.itemText}
                    variant="body1"
                    component="div"
                  >
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: item.text }}/>
                  </Typography>
                </div>
              ))}
            </Slider>
          ) : (
            <Grid className={cx(classes.centerText, grid_based && classes.itemContainerGrid)} spacing={8} container>
              {itemsToShow?.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={itemToSlice === 2 ? 6 : 4}
                  className={cx(!title && !body && classes.itemContainerNoPadding, classes.itemContainer)}
                >
                  <Grid>
                    {item.icon_url ? (
                      <a href={item.icon_url} target="_blank" rel="noreferrer noopener">
                        {item.image && <NextImage alt={item.image_alt_text} src={item.image} image_sizes={nextImageSize}/>}
                        {item.media_image?.src && (
                          <MediaImage
                            src={item.media_image.src}
                            width={item.media_image.width || 64}
                            height={item.media_image.height || 64}
                            alt={item.media_image.alt}
                            mobile_src={item.media_image.mobile_src}
                            mobile_width={item.media_image.mobile_width}
                            mobile_height={item.media_image.mobile_height}
                            use_img_for_seo={item.media_image.use_img_for_seo}
                            className={classes.mediaWrapper}
                            center
                          />
                        )}
                      </a>
                    ) : (
                      <>
                        {item.image && <NextImage alt={item.image_alt_text} src={item.image} image_sizes={nextImageSize}/>}
                        {item.media_image?.src && (
                          <MediaImage
                            src={item.media_image.src}
                            width={item.media_image.width || 64}
                            height={item.media_image.height || 64}
                            alt={item.media_image.alt}
                            mobile_src={item.media_image.mobile_src}
                            mobile_width={item.media_image.mobile_width}
                            mobile_height={item.media_image.mobile_height}
                            use_img_for_seo={item.media_image.use_img_for_seo}
                            className={classes.mediaWrapper}
                            center
                          />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid className={classes.iconTextWrapper}>
                    {item.title &&
                    <Typography variant="body1" className={classes.itemTitle}>
                      {item.title}
                    </Typography>}
                    <Typography
                      className={classes.itemText}
                      variant="body1"
                      component="div"
                    >
                      {/* eslint-disable-next-line react/no-danger */}
                      <div dangerouslySetInnerHTML={{ __html: item.text }}/>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {!show_all_icons && buttonText &&
          <div className={classes.buttonDiv}>
            {button_type === ButtonType.solid && (
              <StyledButton
                color="secondary"
                variant="outlined"
                url={button_url}
              >
                {buttonText}
              </StyledButton>
            )}
            {button_type === ButtonType.arrow && (
              <Button
                onClick={toggleButton}
                className={classes.bottomArrowBtn}
              >
                {buttonText}
                {expanded ? <ExpandLess/> : <ExpandMore/>}
              </Button>
            )}
          </div>}
        </div>
      </div>
    </Box>
  )
}
