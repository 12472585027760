import React from 'react'
import MobileBasket from './MobileBasket'
import DesktopBasket from './DesktopBasket'
import { Hidden } from '@material-ui/core'
import { TimeslotData } from '../../../../../api/BookingCalendar'
import { HobsProduct } from '../../../../../api/Packages'

export default function Basket({
  broadband, bundle, timeslot, reachedInstallationStep,
}: {broadband?: HobsProduct; bundle?: HobsProduct; timeslot: TimeslotData | null; reachedInstallationStep: Boolean}) {
  return (
    (broadband || bundle) ?
      <>
        <Hidden mdUp>
          <MobileBasket bundle={bundle} broadband={broadband} timeslot={timeslot} reachedInstallationStep={reachedInstallationStep}/>
        </Hidden>
        <Hidden smDown>
          <DesktopBasket bundle={bundle} broadband={broadband} timeslot={timeslot} reachedInstallationStep={reachedInstallationStep}/>
        </Hidden>
      </> :
      null
  )
}
