import { OrderOptions } from '../components/NewOrderFlow/OrderContext'
import { getBasePriceNum } from './getProductDetails'

export function calculatePriceForProducts(order: OrderOptions) {
  const {
    broadband, voice, tv, wier, vas, bundle,
  } = order

  const broadbandAmount = broadband ? getBasePriceNum(broadband) : 0
  const voiceAmount = voice ? getBasePriceNum(voice) : 0
  const tvAmount = tv ? getBasePriceNum(tv) : 0
  const wierAmount = wier ? getBasePriceNum(wier) : 0
  const vasAmount = vas ? getBasePriceNum(vas) : 0
  const bundleAmount = bundle ? getBasePriceNum(bundle) : 0

  return (tvAmount + voiceAmount + wierAmount + vasAmount + bundleAmount + broadbandAmount).toFixed(2)
}
