import { HobsProduct } from '../api/Packages'
import {
  getBasePrice, getProductTerm,
} from './getProductDetails'

export const getProductDataForFacebook = (products: HobsProduct[]) => {
  return products.map(product => {
    return {
      name: product.productOfferingId,
      contractType: getProductTerm(product),
      price: getBasePrice(product),
    }
  })
}
