import moment from 'moment'
import { isDaylightSavings } from '../components/Calendar/calendarUtils'

export const parseDateToUTC = (date?: string) => {
  const dateCheck = date ?
    moment.utc(date)
      .local() :
    moment.utc()
      .local()
  return isDaylightSavings ?
    dateCheck
      .add(1, 'hour')
      .toISOString() :
    dateCheck
      .toISOString()
}
