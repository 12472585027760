import { AxiosInstance } from 'axios'
import moment from 'moment'

export default function HobsOrderInfo(client: AxiosInstance) {
  async function getId(accountId: string) {
    try {
      // This endpoint is temporary
      // further we will need to switch to /get-account?accountId=AU12874
      const { data } = await client.get<any>(`mission-control/v1/0.0/get-account?accountId=${accountId}`)
      return data.data[0]
    } catch (err) {
      return { error: err }
    }
  }

  async function get(id: string) {
    try {
      const dateInteraction = new Date()
      const { data } = await client.post<any>('order/v1/0.0/view-order', {
        interactionID: 'WEB-' + moment.utc(dateInteraction)
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          .replace(/[^\d]/g, ''),
        interactionDate: new Date()
          .toISOString(),
        triggeredBy: 'Website',
        channel: 'DIGITAL',
        sourceApplicationID: 'Website',
        orderID: id,
      })
      return data
    } catch (err) {
      return { error: err }
    }
  }

  return {
    getId,
    get,
  }
}
