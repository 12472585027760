import React from 'react'
import {
  SvgIcon, SvgIconProps,
} from '@material-ui/core'

export default function InstallationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 47">
      <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.7883 14.3479L39.7333 13.0307L42.3613 7.77483L39.7333 5.14062L34.4712 7.77483L33.1541 11.7199L35.7883 14.3479Z" fill="#17AB33"/>
        <path d="M11.556 42.3626C13.1977 42.3626 14.8394 41.7384 16.0879 40.4837L22.1303 34.4413C24.1777 32.3938 24.546 29.3102 23.2477 26.8882L35.7882 14.3477L33.154 11.7197L20.6134 24.254C19.6709 23.7484 18.6347 23.4987 17.5922 23.4987C15.9505 23.4987 14.3088 24.123 13.0604 25.3776L7.01796 31.4201C4.51485 33.9232 4.51485 37.9806 7.01796 40.4837C8.27264 41.7322 9.9081 42.3626 11.5498 42.3626H11.556ZM9.65217 34.0543L15.6946 28.0118C16.2002 27.5062 16.8806 27.2253 17.5922 27.2253C18.3038 27.2253 18.9842 27.5062 19.4961 28.0118C20.5448 29.0605 20.5448 30.7646 19.4961 31.8133L13.4537 37.8558C12.9418 38.3614 12.2676 38.6423 11.5498 38.6423C10.8319 38.6423 10.1578 38.3614 9.64592 37.8558C8.59724 36.8071 8.59724 35.103 9.64592 34.0543H9.65217Z" fill="#2E0F4F"/>
      </svg>
    </SvgIcon>
  )
}
