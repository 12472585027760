import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import memoize from 'fast-memoize'
import mixins from './mixins'
import { css } from 'linaria'
import fontSizes from './fontSizes'

export const fontWeights = {
  book: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

export const GENERAL = {
  addOnWidthMobile: 540,
  packageWidthMobile: 320,
  packageWidthDesktop: 362,
}

export const COLORS = {
  primary: '#2E0F4F',
  primary70: '#2E0F4FB3',
  secondary: '#17AB33',
  secondary70: '#17AB33D9',
  secondaryLight: '#FFF3E3',
  secondaryContrast: '#17AB33',
  secondaryContrastLight: 'rgba(236, 98, 37, 0.1)',
  tertiary: '#FCD900',
  info: '#58789E',
  infoLight: '#6183aa',
  error: '#FF3D3D',
  errorDark: '#800b3f',
  businessPrimary: '#3E6BA0',
  bodyTextPrimary: '#524658',
  rose: '#C81162',
  smoke: '#F8F8F8',
  green: '#0FA356',
  white: '#FFFFFF',
  gray1: '#FAFAFA',
  gray2: '#EBEBEB',
  gray3: '#C8C8C8',
  red: '#FF3D3D',
  blue: '#3869FF',
  yellow: '#FEBD17',
  yellowLight: '#FFE5A2',
  orange: '#EC6225',
}

export const SHADOWS = {
  medium: '0px 3px 25px rgba(0, 0, 0, 0.12)',
  large: '0px 3px 40px rgba(0, 0, 0, 0.08)',
}

export const ROUNDED = {
  small: 10,
  medium: 20,
  full: '100px',
}

export const GRADIENTS = {
  business: 'linear-gradient(90deg, #380A60 0%, #295D9A 100%)',
  businessButton: 'linear-gradient(270deg, #380A60 0%, #295D9A 100%)',
  businessButtonHover: 'linear-gradient(270deg, #20023b 0%, #084185 100%)',
  containedPrimary: 'linear-gradient(90deg, #C81162 30%, #F49E2F 90%)',
  containedPrimaryHover: 'linear-gradient(90deg, #800b3f 30%, #ab6e21 90%)',
  primaryPale: 'linear-gradient(90deg, #C8116226 0%, #CD205D26 8.33%, #D22F5826 15.62%, #D63D5326 26.04%, #DB4C4D26 35.94%, #DF5A4826 46.35%, #E4684326 57.29%, #E8763E26 68.23%, #EC843926 76.56%, #F1913426 87.5%, #F49E2F26 98.96%)',
  gray: `linear-gradient(180deg, ${COLORS.white} 0%, ${COLORS.gray1} 100%)`,
}

export const BOX = {
  boxSizing: 'border-box',
  borderRadius: ROUNDED.medium,
  bgcolor: COLORS.white,
  border: `1px solid ${COLORS.gray2}`,
}

export const createBorder = (color: string) => `1px solid ${color}`

export const BORDERS = {
  gray1: createBorder(COLORS.gray1),
  gray2: createBorder(COLORS.gray2),
  gray3: createBorder(COLORS.gray3),
}

const createTheme = memoize(createMuiTheme)
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 9999,
        '&': {
          fontWeight: `${fontWeights.bold} !important`,
          '&:not([class*=MuiButton-size])': {
            fontSize: fontSizes.body1,
          },
        },
        '&.gray:not(.selected)': {
          backgroundColor: COLORS.gray1,
          border: COLORS.gray1,
          color: COLORS.primary,
          '&:hover': {
            backgroundColor: COLORS.gray2,
            border: COLORS.gray2,
          },
        },
        '&.selected, &.selected.MuiButton-root:hover': {
          backgroundColor: COLORS.primary,
          border: COLORS.primary,
          color: COLORS.white,
          cursor: 'default',
        },
        '&.Mui-disabled': {
          color: COLORS.gray3,
        },
      },
      containedPrimary: {
        backgroundColor: COLORS.secondaryContrast,
        '&:hover': {
          backgroundColor: COLORS.secondaryContrast,
        },
        '&$disabled': {
          backgroundColor: COLORS.secondaryContrast,
          opacity: 0.2,
          color: COLORS.white,
        },
      },
      contained: {
        color: COLORS.white,
      },
      containedSecondary: {
        color: COLORS.secondaryContrast,
        backgroundColor: COLORS.gray1,
      },
      outlinedSecondary: {
        borderColor: COLORS.secondary,
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
      },
      primary: {
        background: 'linear-gradient(90deg, #C81162 30%, #F49E2F 90%)',
      },
      secondary: {
        backgroundColor: COLORS.secondaryContrast,
        '&:hover': {
          backgroundColor: COLORS.secondaryContrast,
          opacity: 0.9,
        },
      },
    },
    MuiAppBar: {
      root: { ...mixins.toolbar },
      colorPrimary: {
        backgroundColor: COLORS.white,
      },
    },
    MuiTextField: {
      root: {
        fontWeight: fontWeights.book,
      },
    },
    MuiInput: {
      input: {
        color: COLORS.primary,
        '&::placeholder': {
          fontWeight: fontWeights.book,
          color: COLORS.bodyTextPrimary,
        },
      },
      root: {
        '&.MuiInput-underline': {
          '&:before, &:after': {
            borderBottom: `1px solid ${COLORS.primary}`,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.primary,
        fontSize: fontSizes.body1,
        fontWeight: fontWeights.semibold,
      },
    },
    MuiToolbar: {
      regular: { ...mixins.toolbar },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: COLORS.primary,
      },
    },
    MuiIconButton: {
      colorPrimary: {
        '&:hover': {
          backgroundColor: COLORS.primary,
          borderColor: COLORS.primary,
          color: COLORS.white,
        },
        '&.Mui-disabled': {
          backgroundColor: COLORS.white,
          color: COLORS.gray3,
        },
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryLight,
      contrastText: COLORS.secondaryContrast,
    },
    info: {
      main: COLORS.info,
      light: COLORS.infoLight,
    },
    error: {
      main: COLORS.error,
      dark: COLORS.errorDark,
    },
    text: {
      primary: COLORS.bodyTextPrimary,
      secondary: COLORS.secondaryContrast,
    },
    warning: {
      main: '#848FA5',
      light: COLORS.smoke,
      contrastText: COLORS.secondaryContrast,
      dark: COLORS.secondaryContrastLight,
    },
  },
  typography: {
    fontFamily: [
      '"Poppins"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 45,
      lineHeight: 1.15,
      fontStyle: 'normal',
      fontWeight: fontWeights.bold,
    },
    h2: {
      fontSize: 35,
      lineHeight: 1.175,
      fontStyle: 'normal',
      fontWeight: fontWeights.semibold,
    },
    h3: {
      fontSize: 30,
      lineHeight: 1.2,
      fontStyle: 'normal',
      fontWeight: fontWeights.semibold,
    },
    h4: {
      fontSize: 25,
      lineHeight: 1.25,
      fontStyle: 'normal',
      fontWeight: fontWeights.semibold,
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.5,
      fontStyle: 'normal',
      fontWeight: fontWeights.medium,
    },
    h6: {
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: fontWeights.bold,
    },
    subtitle1: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: fontWeights.medium,
    },
    subtitle2: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: fontWeights.medium,
    },
    body1: {
      fontSize: 18,
      lineHeight: 1.5,
      fontStyle: 'normal',
      fontWeight: fontWeights.book,
    },
    body2: {
      fontSize: 15,
      lineHeight: 1.4,
      fontStyle: 'normal',
      fontWeight: fontWeights.medium,
    },
    overline: {
      fontSize: 15,
      fontWeight: fontWeights.book,
      fontStyle: 'normal',
      textTransform: 'unset',
      lineHeight: 'normal',
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.5,
      fontStyle: 'normal',
      fontWeight: fontWeights.book,
    },
    button: {
      textTransform: 'none',
    },
    fontWeightLight: fontWeights.book,
    fontWeightMedium: fontWeights.medium,
    fontWeightRegular: fontWeights.book,
    fontWeightBold: fontWeights.bold,
  },
})

export const general = {
  font: {
    size: {
      h2: css`
        &, &.MuiTypography-root, &.MuiSvgIcon-root {
          font-size: ${fontSizes.h2};
        }
      `,
    },
    weight: {
      book: css`
        &, &.MuiTypography-root {
          font-weight: ${fontWeights.book} !important;
        }
      `,
      medium: css`
        &, &.MuiTypography-root {
          font-weight: ${fontWeights.medium};
        }
      `,
      semibold: css`
        &, &.MuiTypography-root {
          font-weight: ${fontWeights.semibold};
        }
      `,
      bold: css`
        &, &.MuiTypography-root {
          font-weight: ${fontWeights.bold};
        }
      `,
    },
    baseline: {
      tight: css`
        &, &.MuiTypography-root {
          line-height: 1.2;
        }
      `,
    },
    margin: {
      bottom: {
        md: css`
          &, &.MuiTypography-root {
            margin-bottom: ${theme.spacing(2)}px;
          }
        `,
        lg: css`
          &, &.MuiTypography-root {
            margin-bottom: ${theme.spacing(3)}px;
          }
        `,
      },
      top: {
        lg: css`
          &, &.MuiTypography-root {
            margin-top: ${theme.spacing(3)}px;
          }
        `,
      },
    },
    wrap: {
      breakWord: css`
        & {
          overflow-wrap: break-word;
        }
      `,
    },
    link: {
      primary: css`
        &, &.MuiTypography-root {
          color: ${COLORS.secondary};
          font-weight: ${fontWeights.bold};
          text-decoration: underline;
          cursor: pointer;
          white-space: nowrap;
        }
      `,
    },
  },
  color: {
    primary: css`
      &, &.MuiTypography-root {
        color: ${COLORS.primary};
      }
    `,
  },
  transform: {
    none: css`
      &, &.MuiSkeleton-text {
        transform: none;
      }
    `,
    center: css`
      transform: translate(-50%, -50%);
    `,
  },
  fakeField: css`
    .MuiInputBase-input {
      padding-top: ${theme.spacing(1)}px;
      padding-bottom: 0;
    }
    .MuiInputBase-root{
      padding: 0;
    }
    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
      color: ${COLORS.primary};
    }
    .MuiInput-underline.Mui-disabled:before {
      border: none;
    }
  `,
}

export default theme
