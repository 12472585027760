import {
  Box, Container, Grid, Paper, Typography,
} from '@material-ui/core'
import React from 'react'
import Countdown, {
  CountdownRenderProps, zeroPad,
} from 'react-countdown'
import ClockIcon from '@material-ui/icons/AccessTime'
import classes from './CountdownBanner.styles'

interface CountdownBannerProps {
  countDownTimerStart?: number | null;
}

export default function CountdownBanner({ countDownTimerStart }: CountdownBannerProps) {
  const renderer = ({
    minutes, seconds,
  }: CountdownRenderProps) => {
    return (
      <div className={classes.countdownWrapper}>
        <span className={classes.countdown}>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
        <Typography className={classes.text}>Your installation appointment is reserved for 10 minutes</Typography>
      </div>
    )
  }

  return (
    <Paper elevation={0} className={classes.timeBanner}>
      <Box p={1} mb={3}>
        <Container maxWidth="lg">
          <Grid className={classes.timeContainer} component={Typography} align="left" variant="body1">
            <ClockIcon fontSize="large"/>
            {countDownTimerStart ?
              <Countdown date={countDownTimerStart + 600000} renderer={renderer}/> :
              <div className={classes.countdownWrapper}>
                <span className={classes.countdown}>
                  00:00
                </span>
                <Typography className={classes.text}>Your time&apos;s up. Your installation appointment has expired, please select another slot.</Typography>
              </div>}
          </Grid>
        </Container>
      </Box>
    </Paper>
  )
}
