import React from 'react'
import {
  Box, Typography,
} from '@material-ui/core'
import theme, {
  COLORS, fontWeights,
} from '../../styles/theme'

interface FlagProps {
  subtitle?: string;
  subtitle_color?: string;
  text?: string;
  text_color?: string;
  background_color?: string;
  children?: React.ReactNode;
  position?: 'left' | 'right';
}

export const Flag = ({
  subtitle,
  subtitle_color,
  text,
  background_color,
  text_color,
  children,
  position = 'left',
}: FlagProps) => {
  return (
    <Box
      color={background_color ? background_color : COLORS.tertiary}
      left={position === 'left' ? theme.spacing(3) : 'auto'}
      right={position === 'right' ? theme.spacing(3) : 'auto'}
      bgcolor="currentColor"
      borderRadius="9999px"
      padding={theme.spacing(0.5, 3)}
      display="flex"
      flexDirection="column"
      alignItems="start"
      position="absolute"
      top="1px"
      zIndex={1}
      style={{
        transform: 'translateY(-50%)',
      }}
    >
      <Typography
        variant="body2"
        style={{
          color: text_color ? text_color : COLORS.primary,
          fontWeight: fontWeights.semibold,
          fontSize: 14,
        }}
      >
        {children ? children : text}
      </Typography>
      {subtitle &&
      <Typography
        variant="subtitle2"
        component="p"
        style={{
          color: subtitle_color ? subtitle_color : COLORS.bodyTextPrimary,
          fontWeight: fontWeights.semibold,
          lineHeight: 1.2,
        }}
      >
        {subtitle}
      </Typography>}
    </Box>
  )
}
