import {
  css,
} from 'linaria'
import commonStyling from '../../../../../styles/commonStyling'
import fontSizes from '../../../../../styles/fontSizes'
import theme, { COLORS } from '../../../../../styles/theme'

export const classes = {
  contactCard: css`
    &.MuiGrid-root {
      background-color: ${theme.palette.warning.light};
      padding: ${theme.spacing(3)}px;
      border-radius: ${commonStyling.roundedBorders.allSmall};
    }
  `,
  selectedTimeslot: css`
    color: ${COLORS.bodyTextPrimary};
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(2)}px;
    .MuiTypography-root {
      display: flex;
      flex-direction: column;
      span:last-child {
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
    b {
      margin-right: ${theme.spacing(1)}px;
    }
    ${theme.breakpoints.up('md')} {
      text-align: left;
      .MuiTypography-root {
        display: block;
      }
    }
  `,
  skipMessage: css`
    a{
      color:${COLORS.secondary}
    }
  `,
  checkboxesContainer: css`
    .MuiFormControlLabel-root > .MuiTypography-body1 {
      font-size: ${fontSizes.body1};
      color: ${theme.palette.primary.main};
    }
    &.MuiFormGroup-root {
      flex-direction: row;
    }
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.primary.main};
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
  `,
}
