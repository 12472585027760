import { css } from 'linaria'
import theme, { COLORS } from '../../../../../styles/theme'
import fontSizes from '../../../../../styles/fontSizes'
import commonStyling from '../../../../../styles/commonStyling'

const classes = {
  wrapper: css`
    margin-top: ${theme.spacing(3)}px;
    .MuiTypography-caption {
      display: inline-block;
      margin-top: ${theme.spacing(2)}px;
    }
    .MuiInput-root.MuiInput-underline:before, .MuiInput-root.MuiInput-underline:after {
      max-width: ${theme.spacing(46)}px;
    }
    .MuiSelect-select {
      background: white;
      border: 1px solid ${COLORS.gray3};
      border-radius: 24px;
    }
    .MuiInputLabel-root  {
      font-weight: ${theme.typography.fontWeightBold!};
    }
    a {
      font-size: ${fontSizes.body3};
      font-weight: ${theme.typography.fontWeightBold!};
    }
    button {
      .MuiTypography-body1{
        font-weight: ${theme.typography.fontWeightBold!};
      }
    }
  `,
  modal: css`
    button {
      p {
        font-weight: ${theme.typography.fontWeightBold!};
      }
    }
  `,
  inputLabel: css`
    &.MuiTypography-root {
      margin: ${theme.spacing(1, 0)};
      font-size: ${fontSizes.h5};
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary};
    }
  `,
  fontWeightMedium: css`
  &.MuiTypography-root {
    font-weight: ${theme.typography.fontWeightMedium!};
  }
  `,
  selectClass: css`
    &.MuiInput-root {
      background: ${COLORS.smoke};
      border-radius: ${theme.spacing(3)}px;
      overflow: hidden;
      width: 100%;

      .MuiSelect-select.MuiSelect-select {
        padding-left: ${theme.spacing(3)}px;
        padding-top: 12px;
        padding-bottom: 14px;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightLight!};
        border-radius: ${theme.spacing(3)}px;
      }
    }
`,
  activeSelect: css`
    &.MuiInput-root {
      background: white !important;
      background-color: white !important;
      color: ${COLORS.bodyTextPrimary};
      .MuiSelect-select.MuiSelect-select {
        color: ${COLORS.bodyTextPrimary};
      } 
    }
`,
  errorLabel: css`
    color: ${COLORS.error} !important;
`,
  errorInput: css`
    div {
      color: ${COLORS.error} !important;
    }

    .MuiSelect-select {
      border: 1px solid ${COLORS.error};
    }

    .MuiSelect-icon {
      color: ${COLORS.error} !important;
    }
`,
  icon: css`
    padding-right: ${theme.spacing(1)}px;
    &.MuiSelect-icon {
      color: ${COLORS.primary};
    }
`,
  activeIcon: css`
    &.MuiSelect-icon {
      color: ${COLORS.primary};;
    }
`,
  option: css`
    border: none;
    outline: none;
    &.MuiListItem-root{
      padding-top: 0px;
      padding-bottom: 0px;
      font: normal normal normal 15px/21px Poppins;

      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(1)}px;
        padding-bottom: ${theme.spacing(1)}px;
      }
    }

    &.MuiMenuItem-root:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
      background: ${COLORS.gray2};
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightMedium!};
    }
`,
  contactCard: css`
    margin-bottom: ${theme.spacing(1)}px;
    margin-top: ${theme.spacing(2)}px;
    &.MuiGrid-root {
      background-color: ${theme.palette.warning.light};
      padding: ${theme.spacing(3)}px;
      border-radius: ${commonStyling.roundedBorders.allSmall};
    }
`,
  boldText: css`
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightBold!};
    }
`,
  checkbox: css`
    .MuiFormControlLabel-label {
      color: ${COLORS.bodyTextPrimary};
      font-size: ${fontSizes.h6};
      font-weight: ${theme.typography.fontWeightMedium!};
      margin-left: ${theme.spacing(-0.5)}px;
    }
`,
  selectContainer: css`
    &.MuiGrid-root {
      margin-bottom: ${theme.spacing(1.4)}px;
    }
  `,
  button: css`
    width: ${theme.spacing(26.5)}px;
    margin-top: ${theme.spacing(1)}px;
    font-weight: ${theme.typography.fontWeightBold!};
    ${theme.breakpoints.down('sm')} {
      max-width: ${theme.spacing(40.87)}px;
      width: 100% !important;
      margin: 0 auto !important;
    }
`,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
`,
  optOut: css`
    border-top: 1px solid ${COLORS.gray2};
    padding-top: ${theme.spacing(2)}px;
    button {
      margin-top: ${theme.spacing(3)}px;
      .MuiTypography-body1{
        font-weight: ${theme.typography.fontWeightBold!};
      }
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    }
`,
  radioContainer: css`
    margin-top: ${theme.spacing(1)}px !important;
    margin-left: -${theme.spacing(2.2)}px !important;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
    .MuiGrid-grid-xs-true:last-of-type {
      margin-top: ${theme.spacing(-0.5)}px;
    }
`,
  radio: css`
    align-items: center !important;
    &.MuiFormControlLabel-root {
      box-sizing: border-box;
      padding-right: ${theme.spacing(2)}px;
      margin: auto;
      width: 100%;
      align-items: flex-start;
      color: ${theme.palette.primary.main};
    }
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.primary.main};
    }
    .MuiTypography-body2{
      font-weight: ${theme.typography.fontWeightLight!}
    }
    .MuiTypography-h5 {
      white-space: nowrap;
    }
`,
  radioUnderLabel: css`
    padding-left: ${theme.spacing(5.2)}px;
    font-weight: ${theme.typography.fontWeightLight!} !important;
    margin-top: ${theme.spacing(-1)}px !important;
    a {
      cursor: pointer;
    }
    .MuiTypography-body2{
      font-weight: ${theme.typography.fontWeightLight!}
    }
`,
}
export default classes
