import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  panel: css`
        &.MuiPaper-root {
            margin: ${theme.spacing(5)}px;

            ${theme.breakpoints.up('md')} {
                margin: ${theme.spacing(10)}px ${theme.spacing(10)}px;
            }

            .BambooHR-ATS-board {
                color: ${theme.palette.primary.main};
                font-family: ${theme.typography.fontFamily!};
                padding: ${theme.spacing(2)}px ${theme.spacing(5)}px;

                li {
                    list-style: none;
                }
            }

            .BambooHR-ATS-Department-List {
                padding-left: 0;
            }

            .BambooHR-ATS-Department-Header {
                color: ${theme.palette.secondary.main};
                padding: ${theme.spacing(3)}px 0 0 0;
                font-size: ${theme.typography.h4.fontSize!}px;
                font-weight: ${theme.typography.h4.fontWeight!};
            }

            .BambooHR-ATS-Jobs-List {
                padding-left: 0;
            }

            .BambooHR-ATS-Jobs-Item {
                border-bottom: solid 1px ${theme.palette.divider};
                padding: ${theme.spacing(3)}px 0;

                a {
                    font-weight: ${theme.typography.h4.fontWeight!};
                    color: ${theme.palette.primary.main};
                }

                span {
                    padding-top: ${theme.spacing(3)}px;
                    display: block;

                    ${theme.breakpoints.up('sm')}{
                        float: right;
                        padding-top: 0;
                    }
                    color: ${theme.palette.primary.main};
                }
            }
        }
        .MuiGrid-container {
            ${theme.breakpoints.up('md')} {
                padding: ${theme.spacing(4)}px 0;
            }
        }
    `,
}

export default classes
