import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  desktop: {
    mainBox: css`
      border-top: 1px solid ${COLORS.gray3};
    `,
    gridContainer: css`
      padding: 1rem;
    `,
    menuTitle: css`
      padding-bottom: 0 !important;
    `,
    social: css`
      img {
        height: 30px;
      }
    `,
    address: css`
      text-align: right;
      .MuiTypography-gutterBottom {
        margin-bottom: ${theme.spacing(2)}px;
      }
    `,
    logo: css`
      flex-basis: 100%;
      text-align: right;
      padding-top: ${theme.spacing(2)}px !important;
    `,
    backToTop: css`
      margin-right: ${theme.spacing(1)}px;
      ${theme.breakpoints.up('lg')} {
        margin-right: ${theme.spacing(4)}px;
      }
    `,
  },
  mobile: {
    container: css`
      border-top: 1px solid ${theme.palette.primary.main};
      padding: ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(1)}px;
      .MuiCollapse-container {
        width: 100%;
        padding-left: ${theme.spacing(3)}px;
        .MuiTypography-root {
          padding: ${theme.spacing(1)}px 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .MuiListItem-root {
        padding: 0 !important;
        padding-bottom: ${theme.spacing(2)}px !important;
      }
    `,
    address: css`
      text-align: left;
      .MuiTypography-gutterBottom {
        margin-bottom: ${theme.spacing(2)}px;
      }
    `,
    logo: css`
      flex-basis: 100%;
      text-align: left;
      padding: ${theme.spacing(1)}px 0 ${theme.spacing(3)}px;
      max-width: 75vw !important;
    `,
    menuItem: css`
      position: relative;
      cursor: pointer;
      .MuiSvgIcon-root {
        transition: all 0.1s ease-in-out;
        position: absolute;
        right: 12px;
        bottom: 17px;
        height: 20px;
      }
      &.MuiGrid-container {
        border-bottom: 1px solid ${COLORS.gray2};
      }
    `,
    openMenuItem: css`
       &.MuiGrid-container {
        border-bottom: 1px solid white;
        .MuiSvgIcon-root {
          transition: all 0.1s ease-in-out;
          transform: rotate(45deg);
          height: 21px;
        }
      }
    `,
    social: css`
      &.MuiGrid-item {
        padding-bottom: ${theme.spacing(4)}px !important;
      }
      .MuiTypography-root {
        padding-bottom: ${theme.spacing(1)}px;
      }
      a {
        padding-right: ${theme.spacing(3)}px;
        display: inline-block;
      }
    `,
  },
}

export default classes
