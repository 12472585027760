import React from 'react'
import {
  ButterCMSComponent, getButterComponentByType,
} from '../../../butter'

export interface OrderManagementConfirmationProps {
  data: {
    components: ButterCMSComponent[];
  };
}

const OrderManagementConfirmation = ({ data }: OrderManagementConfirmationProps) => {
  return (
    <div>
      {data.components.map(({
        type, ...rest
      }, i) => {
        const C: any = getButterComponentByType(type)

        if (!C) {
          return null
        }

        return (
          <C key={i} {...rest}/>
        )
      })}
    </div>
  )
}

export default OrderManagementConfirmation
