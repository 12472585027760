import { css } from 'linaria'
import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4d12a0;
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 278px;
    ${theme.breakpoints.down('xs')} {
      background-color: transparent;
    }
    ${theme.breakpoints.up('sm')} {
      background-size: auto 100%;
      min-height: auto;
    }
    ${theme.breakpoints.up('lg')} {
      min-height: 220px;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: ${theme.spacing(6)}px ${theme.spacing(2)}px;
    max-width: 580px;
    ${theme.breakpoints.up('md')} {
     padding: ${theme.spacing(6)}px;
    }

    img {
      padding-bottom: ${theme.spacing(2)}px;
      align-self: center;
    }

    .MuiTypography-h2 {
      line-height: 1.025;
      font-size: ${fontSizes.h3};
      padding-bottom: ${theme.spacing(2)}px;
      ${theme.breakpoints.up('md')} {
       line-height: 2.188;
       font-size: ${fontSizes.h2};
      }
    }
    .MuiTypography-body1 {
      font-weight: 300;
    }
  `,
  title: css`
    color: #fff;
  `,
  purpleTitle: css`
    color: ${theme.palette.primary.main};
  `,
}

export default classes
