import muiCreateMixins from '@material-ui/core/styles/createMixins'
import memoize from 'fast-memoize'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const createTheme = memoize(createMuiTheme)
const createMixins = memoize(muiCreateMixins)
const baseTheme = createTheme()
const mixins = createMixins(baseTheme.breakpoints, baseTheme.spacing, {})

mixins.toolbar = {
  minHeight: 82,
  [baseTheme.breakpoints.down('sm')]: {
    minHeight: 48,
  },
  [baseTheme.breakpoints.up('md')]: {
    minHeight: 110,
  },
}

export default mixins
