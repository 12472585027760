import {
  css,
} from 'linaria'
import theme, {
  BORDERS, COLORS, ROUNDED, fontWeights,
} from '../../../../styles/theme'
import fontSizes from '../../../../styles/fontSizes'

export const classes = {
  label: css`
    label{
      line-height: ${theme.spacing(4)}px;
      position:relative;
      display:flex;
      align-items:start;
    }
    label [id="accountId"] {
      background-color: none;
    }
    label + .MuiInput-formControl{
      margin:0;
    }
  `,
  errorLabel: css`
  .MuiInput-root.MuiInput-underline:before, 
  .MuiInput-root.MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${COLORS.error};
  }
  `,
  error: css`
    width: 85%;
    padding-top: ${theme.spacing(3)}px;
  `,
  login: css`
    position: relative;
    gap: ${theme.spacing(1)}px;
    div[class*="messageWrapper"] {
      width: 100%;
      padding: ${theme.spacing(2)}px;
      align-items: center;
    ${theme.breakpoints.down('sm')}{
      align-items: flex-start;
    }
      justify-content:center;
    }
    p[class*='colorError'] {
      font-size: ${fontSizes.body3};
      font-weight: ${fontWeights.semibold};
    }
    form {
      padding: 0 ${theme.spacing(1.87)}px;
    }
    .MuiTooltip-popper {
      border-radius: ${theme.spacing(1)}px;
      box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.16);
      top: -${theme.spacing(0.5)}px !important;
      width: ${theme.spacing(35.62)}px;
      ${theme.breakpoints.down('sm')} {
        top: -${theme.spacing(9.5)}px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
    .MuiTooltip-tooltip {
      background-color: white;
      margin: 0;
      padding: ${theme.spacing(3)}px;
      line-height: ${theme.spacing(2.62)}px; 
      font-size: ${fontSizes.h6};
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${fontWeights.semibold};
    }
    .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before {
      border-color: white transparent transparent transparent;
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    }
  `,
  form: css`
    border-radius: ${ROUNDED.medium}px;
    border: ${BORDERS.gray3};
    width: 100%;
    max-width: ${theme.spacing(86.62)}px;
    height: 100%;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      max-height: unset;
    }
    .MuiFormLabel-asterisk {
      display: none;
    }
    .MuiInputBase-fullWidth {
      width: 90%;
      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }
    }
    .MuiButtonBase-root {
      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }
    }
  `,
  formBlock: css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: ${theme.spacing(4)}px ${theme.spacing(3)}px;
   button[type='submit'] {
    margin-top: ${theme.spacing(3)}px;
    width: ${theme.spacing(30.87)}px;
    height: ${theme.spacing(5.75)}px;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
   }
   label {
    font-size: ${theme.spacing(2.5)}px;
   }
   input {
    ::-webkit-input-placeholder {
    font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;  
    }
    ::-moz-placeholder {
      font-style: italic;  
    }
    :-ms-input-placeholder {  
      font-style: italic; 
    }
  }
`,
  right: css`
   border-radius:0 ${ROUNDED.medium}px ${ROUNDED.medium}px 0;
   border: 1px solid transparent;
   background-color: ${COLORS.gray1};
   border-left: ${BORDERS.gray3};
   align-items:center;
   ${theme.breakpoints.down('sm')} {
    border-top: ${BORDERS.gray3};
    border-left: 1px solid transparent;
    border-radius: 0 0 ${ROUNDED.medium}px ${ROUNDED.medium}px;
   }
   .MuiListSubheader-root, .MuiTypography-root {
      font-size: ${fontSizes.h5};
      color: ${COLORS.primary};
      font-weight: ${fontWeights.semibold};
      line-height: ${theme.spacing(3.25)}px;
      padding: 0;
      margin: 0;
   }
   .MuiListItemText-root {
      margin-top: ${theme.spacing(2)}px;
   }
   .MuiListItemText-root, .MuiTypography-body1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      font-weight: ${theme.typography.fontWeightRegular!};
      padding: 0;
   }
   .MuiListItem-gutters {
    padding: 0px;
   }
   svg {
    width: ${theme.spacing(4.77)}px;
    height: ${theme.spacing(4.53)}px;
    margin-right:  ${theme.spacing(1.55)}px;
   }
   svg:last-of-type {
    width: ${theme.spacing(5.87)}px;
    height: ${theme.spacing(5.87)}px;
   }
`,
  loading: css`
    position: absolute;
    right:  ${theme.spacing(4.53)}px;;
`,
  fab: css`
    background-color: transparent !important;
`,
}
