import { AxiosInstance } from 'axios'

export interface MatchResult {
  switchOrderReference: string;
  services: {
    serviceType: string;
    switchAction: string;
    serviceIdentifiers: {
      identifier: string;
      identifierType: string;
    }[];
  }[];
  // Other properties...
}

export interface ResidentialSwitchMatchConfirmation {
  implicationsSent: {
    sentMethod: string;
    sentTo?: string;
    sentBy: string;
  }[];
  matchResult: MatchResult;
  // Other properties...
}

export interface ResidentialSwitchMatchFailure {
  faultCode: string;
  faultText: string;
  // Other properties...
}

export interface ResidentialSwitchMatchRequest {
  name: string;
  grcpBrandName: string;
  address: {
    uprn: string;
    postCode: string;
    addressLines: string[];
    postTown: string;
  };
  services: {
    serviceType: string;
    action: string;
    serviceIdentifier?: string;
  }[];
  account: string;
  // Other properties...
}

export interface OtsRegistrationResponse {
  data: {
    type: string;
    residentialSwitchMatchConfirmation?: ResidentialSwitchMatchConfirmation;
    residentialSwitchMatchFailure?: ResidentialSwitchMatchFailure;
    residentialSwitchMatchRequest?: ResidentialSwitchMatchRequest;
    envelope: {
      routingID: string;
      // Other properties...
    };
    // Other properties...
  }[];
  status: number;
}

export default function OtsRegistration(client: AxiosInstance) {
  async function getOtsRegistrationResponse({
    correlationID,
  }: { correlationID?: string } = {}) {
    // Retrieve the OTSCorrelationID from session storage if not provided
    const storedCorrelationID = sessionStorage.getItem('OTSCorrelationID')

    // Use the stored correlationID if available, otherwise use the provided correlationID or default to ''
    const finalCorrelationID = storedCorrelationID || correlationID || ''

    try {
      const url = `v1/0.0/read?type=${finalCorrelationID}`
      const { data } = await client.get<OtsRegistrationResponse>(url)

      // Store just the routingID in session storage under the key 'OTS-Result'
      if (Array.isArray(data.data) && data.data.length > 0) {
        const routingID = data.data[0]?.envelope?.routingID
        if (routingID) {
          sessionStorage.setItem('ots-match-result', routingID)
        }
      }

      // Process the first item for match confirmation
      const matchConfirmation = data.data[0]?.residentialSwitchMatchConfirmation

      if (matchConfirmation && matchConfirmation.matchResult) {
        const { matchResult } = matchConfirmation
        const switchOrderReference = matchResult.switchOrderReference

        // Store the switchOrderReference in session storage with the key 'ots-sor-reference'
        if (switchOrderReference) {
          sessionStorage.setItem('ots-sor-reference', switchOrderReference)
        }

        const serviceTypes = new Set<string>()
        let optionToPortAdded = false

        // Iterate over services in matchResult to store the service types and check for "OptionToRetain" actions
        matchResult.services.forEach(service => {
          if (service.serviceType === 'NBICS' && service.switchAction === 'OptionToRetain') {
            if (!optionToPortAdded) {
              serviceTypes.add('NBICS')
              serviceTypes.add('Option to port')
              optionToPortAdded = true
            }
          } else {
            serviceTypes.add(service.serviceType)
          }
        })

        // Store the serviceType values in session storage under the key 'ots-services'
        if (serviceTypes.size > 0) {
          sessionStorage.setItem('ots-services', [...serviceTypes].join(','))
        }
      }

      // Store the account value as 'ots-account-id' and serviceIdentifier as 'ots-porting-value'
      const matchRequest = data.data[0]?.residentialSwitchMatchRequest
      if (!matchRequest) {
        console.error('No matchRequest found')
        return
      }

      const account = matchRequest.account
      if (account) {
        sessionStorage.setItem('ots-account-id', account)
      } else {
        console.error('No account found')
      }

      const portingService = matchRequest.services.find(service => service.action === 'port')
      if (portingService && portingService.serviceIdentifier) {
        sessionStorage.setItem('ots-porting-value', portingService.serviceIdentifier)
      } else {
        console.error('No portingService or serviceIdentifier found')
      }

      return data
    } catch (err) {
      // Store the error response data in session storage under the key 'OTS-Result'
      if (err.response) {
        sessionStorage.setItem('OTS-Result', JSON.stringify(err.response.data))
      } else {
        sessionStorage.setItem('OTS-Result', 'null')
      }

      return err.response
    }
  }

  return {
    getOtsRegistrationResponse,
  }
}
