import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  listContainer: css`
    color: ${theme.palette.primary.main};
    padding: ${theme.spacing(0, 1)};
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }
    &.MuiTabs-root {
      min-height: auto;
    }
    .MuiTab-root {
      font-size: ${fontSizes.body2};
      padding: ${theme.spacing(0, 1)};
      min-width: ${commonStyling.minSizeClickableTab};
      min-height: ${commonStyling.minSizeClickableTab};
      line-height: 1;
    }
    .MuiTab-textColorInherit[aria-selected='false'] {
      opacity: 1;
      color: ${COLORS.primary};
    }
    .MuiTabs-indicator {
      height: 0;
      background-color: ${theme.palette.primary.main};
      border-top: 1.5px solid ${theme.palette.primary.main};
      border-bottom: 1.5px solid ${theme.palette.primary.main};
      border-radius: 1.5px;
    }
  `,
}

export default classes
