import React from 'react'
import { Typography } from '@material-ui/core'
import { css } from 'linaria'
import classaNames from 'classnames'
import fontSizes from '../../styles/fontSizes'
import theme from '../../styles/theme'

const styles = css`
    &.MuiTypography-root {
    padding-bottom: ${theme.spacing(4)}px;
    display: inline-block;
    position: relative;
    ${theme.breakpoints.up('md')} {
      font-size: ${fontSizes.h2};
    }
    .MuiTypography-overline {
      font-style: italic;
      position: relative;
      bottom: 3px;
      left: ${theme.spacing(1)}px;
    }
  }
`

export default function FormHeader({
  title, className, withLegend = true,
}: {title: string; className?: string; withLegend?: boolean}) {
  return (
    <Typography variant="h3" color="primary" className={classaNames(styles, className)}>
      {title}
      {withLegend && <Typography variant="overline">*Required</Typography>}
    </Typography>
  )
}
