import React, { useState } from 'react'
import {
  Box, InputLabel, NativeSelect, InputBase,
} from '@material-ui/core'
import { css } from 'linaria'
import { useFormContext } from 'react-hook-form'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import theme from '../../styles/theme'

const classes = {
  label: css`
    transform: translate(0, 1.5px) scale(0.95);
    transform-origin: top left;
    margin-bottom: ${theme.spacing(2)}px;
    line-height: 1.2 !important;
  `,
  select: css`
    &.MuiNativeSelect-root {
      padding: ${theme.spacing(2)}px;
    }
  `,
  selectInput: css`
    &.MuiInputBase-root {
      height: 50px;
      border-radius: 25px;
      min-width: 100%;
      box-shadow: 0px 0px 6px #00000029;
      color: ${theme.palette.primary.main};
      flex: 1;

      svg {
        color: ${theme.palette.primary.main};
        padding-right:  ${theme.spacing(2)}px;
      }
    }
  `,

}
export default function NumberOfEmployeesSelect() {
  const { register } = useFormContext()
  const [
    numberOfEmployees,
    setNumberOfEmployees,
  ] = useState<string>('')

  function handleSelectChange(event: React.ChangeEvent<{value: string}>) {
    setNumberOfEmployees(event.target.value)
  }

  return (
    <Box>
      <InputLabel htmlFor="numberOfEmployees" className={classes.label} required>How many people are in your business</InputLabel>
      <NativeSelect
        id="numberOfEmployees"
        value={numberOfEmployees}
        inputRef={register({ required: true })}
        required
        IconComponent={KeyboardArrowDownIcon}
        name="numberOfEmployees"
        classes={{ select: classes.select }}
        input={
          <InputBase
            className={classes.selectInput}
            inputProps={{ 'aria-label': 'number of employees select' }}
            type="select"
            onChange={handleSelectChange}
          />
        }
      >
        <option value="1-50">1-50</option>
        <option value="50-200">50-200</option>
        <option value="200-400">200-400</option>
        <option value="400+">400+</option>
      </NativeSelect>
    </Box>
  )
}
