import React from 'react'
import { css } from 'linaria'
import {
  Container, Grid,
} from '@material-ui/core'
import TermsQuickLinks from '../TermsQuickLinks/TermsQuickLinks'
import TermsSection, { TermsSectionProps } from '../TermsSection/TermsSection'
import theme from '../../../styles/theme'

export interface TermsSectionListProps {
  sections: TermsSectionProps[];
}

const classes = {
  root: css`
    margin-top: ${theme.spacing(8)}px;
  `,
  quickLinksSide: css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
`,
  quickLinksTop: css`
    ${theme.breakpoints.up('md')} {
      display: none;
    }
`,
}

export default function TermsSectionList({ sections }: TermsSectionListProps) {
  const links = sections.map((section: TermsSectionProps) => ({
    text: section.title,
    anchorRef: section.anchor_ref,
  }))

  return (
    <Container className={classes.root}>
      <Grid container spacing={4}>
        <Grid className={classes.quickLinksTop} item xs={12}>
          <TermsQuickLinks links={links}/>
        </Grid>
        <Grid container item xs={12} md={8} spacing={4}>
          {sections.map((section: TermsSectionProps) => (
            <Grid key={section.title} container item>
              <TermsSection key={section.title} {...section}/>
            </Grid>
          ))}
        </Grid>
        <Grid className={classes.quickLinksSide} item xs={12} md={4}>
          <TermsQuickLinks links={links}/>
        </Grid>
      </Grid>
    </Container>
  )
}
