import { TimeslotData } from './BookingCalendar'
import moment from 'moment'
import { AxiosInstance } from 'axios'

export default function SubmitOrder(client: AxiosInstance) {
  async function createOrder(timeslot: TimeslotData | null, cartInstanceIdentifier: string, name: string, channel: string, partnerID: string, cks?: string, utmSource?: string, reservationID?: string) {
    try {
      const params = {
        cartInstanceIdentifier,
        interactionID: cartInstanceIdentifier,
        interactionDate: moment.utc(),
        description: 'wrapperDigitalService',
        serviceName: 'submitOrderService',
        triggeredBy: 'SALES',
        buID: 'DEFAULT',
        opID: 'CFL',
        lang: 'ENG',
        channelID: channel,
        userID: 'CFLHOBS',
        partnerID,
        requestObject: {
          appmtslot: {
            slotID: timeslot ? timeslot.appointmentWindowId : '',
            dateOfAppointment: timeslot ? moment.utc(timeslot.startDateTime)
              .add(1, 'hours') : '',
            endDateOfAppointment: timeslot ? moment.utc(timeslot.endDateTime)
              .add(1, 'hours') : '',
            slotExternalRefID: reservationID || '',
          },
          confirmedAppmtDate: timeslot ? timeslot.date : '',
          confirmedAppmtStartTime: timeslot ? moment.utc(timeslot.startDateTime)
            .add(1, 'hours')
            .format('HH:mm') : '',
          confirmedAppmtEndTime: timeslot ? moment.utc(timeslot.endDateTime)
            .add(1, 'hours')
            .format('HH:mm') : '',
          note: [{
            author: name,
            text: 'Customer available for 2 hours',
          }],
          relatedParty: [{
            name,
            role: 'Appointment Contact',
            atReferredType: 'individual',
          }],
          requestedCompletionDate: moment.utc(),
          utmSource,
          cks,
        },
      }
      const { data } = await client.post('order/v1/0.0/submitorder', params)
      return data
    } catch (err) {
      return { error: err }
    }
  }

  return {
    createOrder,
  }
}
