import React from 'react'
import {
  Typography,
  Collapse,
  Box,
} from '@material-ui/core'
import StyledButton, { ButtonProps } from '../StyledButton/StyledButton'
import { renderButtonProps } from './InfoBar'

export interface InfoBarDropdownProps {
  text?: string;
  button?: ButtonProps;
  visible?: boolean;
}

const InfoBarDropdown = ({
  text,
  button,
  visible = false,
}: InfoBarDropdownProps) => {
  return (
    <Collapse in={visible} unmountOnExit>
      <Box pt={3} pb={2} textAlign="center">
        <Typography variant="body1">{text}</Typography>
        {button && button?.text &&
          <Box mt={1}>
            <StyledButton
              {...renderButtonProps(button)}
            >
              {button.text}
            </StyledButton>
          </Box>}
      </Box>
    </Collapse>
  )
}

export default InfoBarDropdown
