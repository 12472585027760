import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'

const classes = {
  popper: css`
    border-radius: ${commonStyling.roundedBorders.all};
    transform: translateY(8px);
    .MuiAutocomplete-paper {
      background-color: ${COLORS.white};
      border: 1px solid ${COLORS.secondary};
      border-top: 1px solid ${COLORS.secondary} !important;
      box-shadow: none;
      ul li.MuiAutocomplete-option {
        padding: ${theme.spacing(0.75, 3)};
      }
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent !important;
        border-radius: inherit;
      }
    }
    
    .MuiAutocomplete-noOptions {
      color: ${COLORS.bodyTextPrimary}
      font-weight: ${theme.typography.fontWeightMedium!}
      text-align: left;
    }
  `,
  popperPrimary: css`
    background-color: ${theme.palette.grey[50]};
    .MuiAutocomplete-paper {
      border: none;
      background-color: white;
    }
  `,
  autocomplete: css`
    position: relative;
    z-index: 1;
    background-color: ${COLORS.white};
    border-radius: 30px;
    max-width: 100%;
    margin: auto;
    display: flex;
    .MuiAutocomplete-clearIndicator {
      background-color: white;
      ${theme.breakpoints.up('md')} {
        margin-right: 0px;
      }
    }
    .MuiInputBase-root {
      color: ${COLORS.bodyTextPrimary};
      font-size: ${theme.typography.body1.fontSize!};
      flex-basis: 100%;
      border: 0;
      z-index: ${theme.zIndex.appBar};
      &, &.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
        padding: 0px;
      }
      input {
        box-sizing: border-box;
        min-height: 46px;
        padding: ${theme.spacing(1, 6, 1, 3)} !important;
        margin-right: 0px;
        border-radius: 30px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  `,
  form: css`
    padding: ${theme.spacing(2.5)}px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: auto;
    .MuiTypography-h3 {
      color: white;
      font-size: ${theme.typography.h4.fontSize!}px;
      ${theme.breakpoints.up('md')} {
        font-size: ${theme.typography.h3.fontSize!}px;
        margin-right: ${theme.spacing(3)}px;
      }
    }
    .MuiExpansionPanel-root {
      box-shadow:none;
      background:transparent;
    }
    .MuiExpansionPanelDetails-root {
      padding:0;
    }
  `,
  formPrimaryBg: css`
    background-color: ${theme.palette.primary.main};
  `,
  fontContainerPrimary: css`
    background-color: white;
    border: 1px solid white;
  `,
  mobileButton: css`
    &.MuiFab-root, &.MuiFab-root:hover {
      height: 40px;
      width: 40px;
      color: white;
      background-color: ${COLORS.secondaryContrast};
      margin-right: 3px;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0%;
      .MuiSvgIcon-root {
        font-size: 1.3rem;
        margin-left: 3px;
      }
    }
  `,
  inputContainer: css`
    flex-basis: 100%;
    max-width: 100%;
    position: relative;
    z-index: 2;
  `,
  item: css`
    .MuiAutocomplete-option {
      color: ${COLORS.bodyTextPrimary};
      font-size: ${theme.typography.h6.fontSize!};
      padding: 0;
      text-align: left;
      &[data-focus=true] {
        background-color: ${COLORS.gray1};
        color: ${COLORS.primary};
        text-shadow: 0.25px 0.1px 0px ${COLORS.bodyTextPrimary}, -0.25px -0.1px 0px ${COLORS.bodyTextPrimary};
      }
    }
  `,
  searchbar: css`
    flex-grow: 1;
    .MuiFormControl-root {
      &, input {
        height: 100%;
      }
      .MuiInputBase-root {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  `,
  errorMessage: css`
      color: ${theme.palette.error.main};
      opacity: 100%;
  `,
}

export default classes
