import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function BundleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill={COLORS.secondary} d="M11.1,13H5v6.1h6.1V13z"/>
      <path fill={COLORS.secondary} d="M16.1,19.1c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1C14.4,13,13,14.3,13,16C13,17.7,14.4,19.1,16.1,19.1z"/>
      <path fill={COLORS.secondary} d="M16.1,5L13,11h6.1L16.1,5z"/>
      <path fill={COLORS.primary} d="M24,24H0V0h24V24z M2.6,21.4h18.7V2.6H2.6V21.4z"/>
    </SvgIcon>
  )
}
