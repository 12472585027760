import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { parseISO } from 'date-fns'
import {
  Box, Fade, Paper, Popper, Typography,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import classes from './VoucherModal.styles'
import StyledButton, { ButtonProps } from '../StyledButton/StyledButton'
import theme from '../../styles/theme'
import TypographyCMS from '../Typography/Typography'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { MediaImageProps } from '../Media/MediaImage'

enum Color {
  primary = 'primary',
  secondary = 'secondary',
  inherit = 'inherit',
  initial = 'initial',
  textPrimary = 'textPrimary',
  textSecondary = 'textSecondary',
  error = 'error',
}

export interface VoucherModalProps {
  modal_background_color: string;
  background_image: MediaImageProps;
  anchor_id: string;
  before_apply_copy: string;
  after_apply_copy: string;
  copy_color: Color;
  voucher_cta: ButtonProps;
  applied_cta_copy: string;
  start_date?: string;
  end_date?: string;
  footnote_copy: string;
  is_on_package_page: boolean;
}

export default function VoucherModal({
  modal_background_color,
  background_image,
  anchor_id,
  before_apply_copy,
  after_apply_copy,
  copy_color = Color.primary,
  voucher_cta,
  applied_cta_copy,
  footnote_copy,
  is_on_package_page,
  start_date,
  end_date,
}: VoucherModalProps) {
  const [
    showPopper,
    setShowPopper,
  ] = useState(false)
  const [
    offerExpired,
    setOfferExpired,
  ] = useState(false)
  const [
    open,
    setOpen,
  ] = useState(true)
  const [
    applied,
    setApplied,
  ] = useState(false)
  const [
    error,
    setError,
  ] = useState('')
  const {
    options, setOptions,
  } = useOrderContext()
  const {
    offer, utmSource,
  } = options

  const anchorRef = useRef(null)
  const noOffer = Boolean(offer === 'seen' || offer === 'declined')
  const isAffiliateUser = Boolean(utmSource && utmSource === 'awin')
  const offerRejected = Boolean(offer === 'rejected' || offer === 'accepted')
  const orderBottomBar = Boolean(is_on_package_page && (options.broadband?.productOfferingId || options.bundle?.productOfferingId))

  // Functionality to show/hide popper after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (is_on_package_page) {
        setShowPopper(Boolean(!offerExpired && noOffer && !isAffiliateUser && !offerRejected && open))
      } else {
        setShowPopper(Boolean(!offerExpired && !isAffiliateUser && !offerRejected && offer !== 'declined' && open))
        applyOfferSeenStatus()
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [
    offerExpired,
    noOffer,
    isAffiliateUser,
    offerRejected,
    offer,
    is_on_package_page,
  ])

  // UseEffect to check if the offer is expired
  useEffect(() => {
    const currentDate = new Date()
    if ((start_date && currentDate < parseISO(start_date)) || (end_date && currentDate > parseISO(end_date))) {
      return setOfferExpired(true)
    }

    return setOfferExpired(false)
  }, [
    start_date,
    end_date,
  ])

  const applyOfferSeenStatus = () => {
    if (!is_on_package_page && !offerRejected && !offerExpired && !isAffiliateUser && !noOffer && open) {
      setOptions(prevOptions => ({
        ...prevOptions,
        offer: 'seen',
      }))
    }
  }

  const handleClose = () => {
    setOpen(false)
    setShowPopper(false)
    setOptions(prevOptions => ({
      ...prevOptions,
      offer: is_on_package_page ? 'rejected' : 'declined',
    }))
  }

  const handleApply = () => {
    if (options.promo) {
      setError('This voucher can\'t be combined with promo code')
    } else {
      setApplied(true)
      setOptions(prevOptions => ({
        ...prevOptions,
        offer: 'accepted',
      }))
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  return (
    <Box>
      <div
        ref={anchorRef} style={{
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          visibility: 'hidden',
          height: '0',
          width: '0',
        }}
      >
        <Popper
          open={showPopper}
          anchorEl={anchorRef.current}
          style={{
            right: 16,
            bottom: orderBottomBar ? 80 : 16,
          }}
          aria-labelledby="unique voucher"
          aria-describedby="limited time voucher offer"
          id={anchor_id}
          transition
          className={classes.popper}
          disablePortal={false}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={1000}>
              <Paper
                style={{
                  paddingTop: 1,
                  backgroundColor: modal_background_color ? modal_background_color : theme.palette.primary.main,
                  backgroundImage: background_image?.src ? `url(${background_image.src})` : 'none',
                }}
              >
                <Box
                  style={{
                    color: copy_color ? copy_color : 'inherit',
                  }}
                >
                  <Box mt={2} mx={2.5} pr={2}>
                    {error ?
                      <TypographyCMS
                        variant="body1" margin_bottom={1.5}
                        text={error} align="center"
                      /> :
                      <Box>
                        {!applied && before_apply_copy &&
                          <TypographyCMS
                            variant="body1"
                            margin_bottom={1.5}
                            text={before_apply_copy}
                            align="center"
                          />}
                        {applied && after_apply_copy &&
                          <TypographyCMS
                            variant="body1"
                            margin_bottom={1.5}
                            text={after_apply_copy}
                            align="center"
                          />}
                      </Box>}
                  </Box>
                  <Box onClick={handleClose}>
                    <CloseIcon htmlColor={copy_color} className={classes.close} aria-label="Close offer"/>
                  </Box>
                  <Box
                    display="flex" flexDirection="column" alignItems="center"
                    justifyContent="center" mx={2}
                  >
                    <StyledButton
                      isFullWidth
                      handleClick={handleApply}
                      color={voucher_cta.color}
                    >
                      {applied ?
                        <Box display="flex" flexDirection="row" gridGap={12}>
                          <Check/>
                          <Typography variant="body1">{applied_cta_copy}</Typography>
                        </Box> :
                        <Typography variant="body1">{voucher_cta.text}</Typography>}
                    </StyledButton>
                    <Box mt={1} m={2} textAlign="center">
                      <Typography variant="caption">{footnote_copy}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </Box>
  )
}
