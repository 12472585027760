import { AxiosInstance } from 'axios'

interface AppointmenData {
  appointmentWindowId: string;
  startDateTime: string;
  appointmentWindowName: string;
  available: string;
  endDateTime: string;
}
export interface ResponseProps {
  appointmentWindowData: AppointmenData[] | null;
}
export interface AppointmentProps {
  data: ResponseProps[] | null;
  message: string;
}
export default function Appointments(client: AxiosInstance) {
  async function getAppointments() {
    try {
      const params = {
        data: [{
          processId: 'cflReturnAvailableAppointmentWindows',
          apiResponseFormat: true,
          contextVar: {
            startDate: '2022-05-17T13:38:02.708Z',
            endDate: '2022-05-28T13:38:02.708Z',
            scheduleType: 'INSTALL',
            broadbandProduct: '3000',
            serviceType: 'Premium',
            accountType: 'RESIDENTIAL',
            installationType: 1,
            region: 'Camden',
          },
        }],
      }
      const { data } = await client.post<{ data: ResponseProps[]; message: string }>('appointment/get', params)
      const appointmentData = data.data[0].appointmentWindowData
      return {
        data: appointmentData,
        message: 'success',
      }
    } catch (err) {
      return {
        data: null,
        message: err.message,
      }
    }
  }

  return {
    getAppointments,
  }
}
