import { css } from 'linaria'
import theme, { ROUNDED } from '../../styles/theme'

const classes = {
  popper: css`
    position: fixed!important;
    transform: none !important;
    top: unset ! important;
    left: 16px !important;
    border-radius: ${ROUNDED.medium}px;
    max-width: 343px;
    z-index: 1290;
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
    }
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
    }
    .MuiPaper-rounded {
      border-radius: ${ROUNDED.medium}px;
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  filler: css`
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  `,
  close: css`
    position: absolute;
    top:0;
    right:0;
    cursor: pointer;
    padding: ${theme.spacing(2)}px;
  `,
}

export default classes
