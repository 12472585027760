import { css } from 'linaria'
import theme, {
  ROUNDED, COLORS, SHADOWS,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  green: css`
    color: ${COLORS.green};
  `,
  regular: css`
    &.MuiTypography-body1, &.MuiTypography-body2 {
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightRegular!};
    }
  `,
  section: css`
    background-color: ${theme.palette.warning.light};
  `,
  wrapper: css`
    &, &.MuiGrid-item {
      margin-left: auto;
      margin-right: auto;
    }
    ${theme.breakpoints.down('sm')} {
      box-sizing: border-box;
      min-width: auto;
      padding: ${theme.spacing(0, 2)};
    }
  `,
  preselectedBox: css`
    background: #fff;
    border-radius: ${commonStyling.roundedBorders.all};
    box-shadow: ${SHADOWS.large};
  `,
  dropdownPill: css`
    background: #fff;
    box-sizing: border-box;
    box-shadow: ${SHADOWS.medium};
    position: relative;
    text-align: center;
    transform: none;
    .MuiInputLabel-formControl {
      transform: none;
    }
    &.MuiFormControl-root {
      display: block;
    }
    label + .MuiInput-formControl {
      margin-top: 0;
    }
    &.Mui-disabled .MuiInputLabel-formControl {
        color: ${COLORS.gray2}
      }
    &,
    .MuiInputLabel-formControl,
    .MuiSelect-select,
    .MuiSelect-select.MuiSelect-select,
    .MuiInputBase-formControl {
      border-radius: ${ROUNDED.full};
    }
    .MuiInputLabel-formControl,
    .MuiSelect-select,
    .MuiSelect-select.MuiSelect-select {
      box-sizing: content-box;
      font-size: ${fontSizes.body2};
      font-weight: ${theme.typography.fontWeightRegular!};
      height: ${theme.spacing(6)}px;
      padding: ${theme.spacing(0, 6.5, 0, 4)};
    }
    .MuiInputLabel-formControl {
      align-items: center;
      display: flex;
      position: static;
      z-index: 10;
      + .MuiInput-formControl {
        display: flex;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .MuiSelect-select {
          opacity: 0;
        }
        svg {
          font-size: 0.875em;
          position: absolute;
          right: ${theme.spacing(3.5)}px;
          transform: translate(0, -50%);
          top: 50%;
          path {
            fill: ${theme.palette.primary.main};
          }
        }
        &.Mui-disabled {
          color: ${COLORS.gray2};
          pointer-events: none;
          svg path {
            fill: ${COLORS.gray2};
          }
        }
      }
      &.MuiFormLabel-filled {
        display: none;
        + .MuiInput-formControl {
          position: relative;
          width: auto;
          .MuiSelect-select {
            background: ${theme.palette.primary.main};
            border-color: ${theme.palette.primary.main};
            color: #fff;
            opacity: 1;
            ~ svg path {
              fill: #fff;
            }
          }
        }
      }
    }
    .MuiInput-formControl {
      .MuiSelect-select {
        align-items: center;
        background: #fff;
        display: flex;
        justify-content: center;
        width: 100%;
        &:focus {
          background: none;
        }
      }
      &.Mui-focused .MuiSelect-select.MuiSelect-select {
        border-color: ${COLORS.primary};
      }
    }
    .MuiFormLabel-filled, .MuiInput-root:before, .MuiInput-root:after {
      display: none;
    }
  `,
  comparisonTable: css`
    background: #fff;
    box-shadow: ${SHADOWS.large};
    border-radius: ${commonStyling.roundedBorders.all};
    color: ${COLORS.bodyTextPrimary};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    text-align: center;
    width: 100%;
    > div {
      position: relative;
      &.colCF {
        &:after {
          background: linear-gradient(-90deg, white 0%, #000 100%);
          content: '';
          height: 100%;
          left: 0;
          opacity: 0.05;
          position: absolute;
          top: 0;
          width: 20px;
        }
      }
    }
  `,
  comparisonRow: css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 4em;
    padding: ${theme.spacing(0, 4)};
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 1)};
    }
    svg {
      margin-left: ${theme.spacing(0.5)}px;
      font-size: 0.875em;
      position: relative;
      top: ${theme.spacing(0.25)}px;
      ${theme.breakpoints.down('xs')} {
        display: none;
      }
    }
    img {
      height: ${theme.spacing(6)}px;
      margin: ${theme.spacing(2, 0)};
      max-width: ${theme.spacing(16)}px;
    }
    &:nth-child(even) {
      background: ${COLORS.smoke};
    }
  `,
  comparisonRowValue: css`
    align-items: baseline;
    display: flex;
    > .MuiTypography-root + .MuiTypography-root {
      margin-left: ${theme.spacing(0.5)}px;
    }
  `,
}

export default classes
