import React from 'react'
import {
  css, cx,
} from 'linaria'
import { Typography } from '@material-ui/core'
import theme, { COLORS } from './theme'

const classes = {
  container: css`
    height: 8px;
    width: 100%;
    background: ${theme.palette.warning.light};
    border-radius: 5px;
    position: relative;
    margin-top: ${theme.spacing(4)}px;

    div.start {
      position: absolute;
      left: 0;
      top: 0;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-name: progressBarAnimation;
      height: 8px;
      border-radius: 5px;

      @keyframes progressBarAnimation {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }
    }
  `,
  primaryContainer: css`
    div {
      background: ${theme.palette.primary.main};
    }
    .progressBarTitle, .progressBarRightTitle{
      color: ${theme.palette.primary.main};
    }
  `,
  secondaryContainer: css`
    div {
      background: ${COLORS.secondaryContrast};
    }
    .progressBarTitle, .progressBarRightTitle{
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  progressBarTitle: css`
    position: absolute;
    left: 0;
    top: -20px;
  `,
  progressBarRightTitle: css`
    position: absolute;
    right: 0;
    top: -20px;
  `,
}

export const AnimatedProgressBar = ({
  title,
  subtitle,
  speed,
  percentage,
  startSpeedAnimation,
  barColor,
  highlight,
}: {
  title?: string;
  subtitle?: string;
  speed?: string;
  percentage?: string;
  startSpeedAnimation?: boolean;
  barColor: 'primary'|'secondary';
  highlight?: boolean;
}) => {
  const duration = speed ?? String(4.4) // Default 4 seconds

  return (
    <div className={cx(classes.container, highlight ? 'highlightedProgressBar' : '', barColor === 'primary' ? classes.primaryContainer : classes.secondaryContainer)}>
      <div
        className={cx(startSpeedAnimation && 'start')}
        style={{
          animationDuration: `${duration}s`,
          maxWidth: percentage + '%',
        }}
      />
      {
        title && (
          <Typography variant="subtitle2" color="textPrimary" className={cx(classes.progressBarTitle, 'progressBarTitle')}>
            <span>{title}</span>
          </Typography>
        )
      }
      {
        subtitle && (
          <Typography variant="subtitle2" color="textPrimary" className={cx(classes.progressBarRightTitle, 'progressBarRightTitle')}>
            <span>{subtitle}</span>
          </Typography>
        )
      }
    </div>
  )
}
