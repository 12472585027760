import React from 'react'
import {
  Grid,
  Typography,
  Link,
  Container,
  Box,
} from '@material-ui/core'
import footerClasses from './Footer.styles'
import { FooterProps } from './Footer'
import BackToTopButton from '../BackToTopButton/BackToTopButton'
import MediaImage from '../Media/MediaImage'

const classes = footerClasses.desktop

export default function DesktopFooter({
  company, help, social, address, logo, logo_alt_text, show_back_to_top, company_column_title, help_column_title, social_column_title,
}: FooterProps) {
  return (
    <Box p={4} className={classes.mainBox}>
      <Container>
        {show_back_to_top && (
          <Grid container justify="flex-end">
            <BackToTopButton className={classes.backToTop}/>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          classes={{ root: classes.gridContainer }}
        >
          {/** Company */}
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            lg={6}
            spacing={2}
          >
            <Grid item className={classes.menuTitle}>
              <Typography variant="subtitle1" color="textPrimary" gutterBottom>{company_column_title || 'Company'}</Typography>
            </Grid>
            {company.map((link, index) => (
              <Grid item key={index}>
                <Typography variant="body2" color="textPrimary">
                  <Link href={link.url} color="textPrimary">{link.text}</Link>
                </Typography>
              </Grid>
            ))}
          </Grid>
          {/** Help & Support */}
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            lg={6}
          >
            <Grid
              item
              container
              direction="column"
              spacing={2}
            >
              <Grid item className={classes.menuTitle}>
                <Typography variant="subtitle1" color="textPrimary" gutterBottom>{help_column_title || 'Help & Support'}</Typography>
              </Grid>
              {help.map((link, index) => (
                <Grid item key={index}>
                  <Typography variant="body2">
                    <Link href={link.url} color="textPrimary">{link.text}</Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/** Social */}
          <Grid
            item
            container
            lg={6}
          >
            <Grid
              item
              container
              direction="column"
              spacing={2}
              className={classes.social}
            >
              <Grid item className={classes.menuTitle}>
                <Typography variant="subtitle1" color="textPrimary" gutterBottom>{social_column_title || 'Connect'}</Typography>
              </Grid>
              <Grid item container spacing={1}>
                {social.map((link, index) => (
                  <Grid item key={index}>
                    <a href={link.url} rel="noopener noreferrer" target="_blank">
                      <MediaImage
                        src={link.src}
                        width={30}
                        height={30}
                        alt={link.image_alt_text}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/** Address */}
          <Grid
            item
            container
            direction="row"
            lg={4}
            spacing={3}
          >
            <Grid item classes={{ root: classes.logo }} xs={12}>
              <MediaImage
                src={logo}
                width={288}
                height={76}
                alt={logo_alt_text}
              />
            </Grid>
            <Grid item xs={12} component="div" classes={{ root: classes.address }}>
              <Typography variant="caption" component="div" color="textPrimary">{address.line1}</Typography>
              <Typography variant="caption" component="div" color="textPrimary">{address.line2}</Typography>
              <Typography gutterBottom variant="caption" component="div" color="textPrimary">
                {address.phone && <Link href={`tel:${address.phone}`}>{address.phone}</Link>} {address.phone && address.email && <> | </>} {address.email && <Link href={`mailto:${address.email}`}>{address.email}</Link>}
              </Typography>
              <Typography variant="caption" color="textPrimary">© Copyright Community Fibre Ltd. All Rights Reserved</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
