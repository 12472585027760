import theme, { COLORS } from '../../../styles/theme'
import { css } from 'linaria'
import commonStyling from '../../../styles/commonStyling'
import fontSizes from '../../../styles/fontSizes'

const classes = {
  mobileHeader: css`
  .MuiToolbar-gutters {
    padding: ${theme.spacing(0.5, 2, 0)};
  `,
  contactUsWrapper: css`
    &.mobileContactUsWrapper{
      .contactUsInner{
        padding: 0;
      }
    }
  `,
  secureIcon: css`
    min-height: 38px;
    margin-right: ${theme.spacing(1)}px;
    margin-bottom: 4px;
  `,
  hamburgerButtonWrapper: css`
    #header[class*=inverted] & {
      svg path {
        fill: #fff;
      }
    }
    &.MuiIconButton-root {
      padding-right: 0;
      padding-left: ${theme.spacing(1)}px;
    }
  `,
  logoWrapper: css`
    display:flex;
    flex-grow: 1;
    padding: ${theme.spacing(0.5, 0)}
  `,
  submenu: css`
    position: relative;
    overflow: scroll;
    display: grid;
    direction: column;
    min-width: 100vw;
    .MuiGrid-container {
      min-height: 100vh;
    }
    .MuiGrid-item {
      min-width: 100%;
    }
    .MuiTabs-root {
      background-color: ${theme.palette.warning.light};
      position: relative;
      padding: ${theme.spacing(0, 2)};

      :before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: ${theme.spacing(2)}px;
        right: ${theme.spacing(2)}px;
        border-bottom: 1px solid ${COLORS.bodyTextPrimary};
        opacity: 0.16;
      }
    }
  `,
  miniBasketIcon: css`
    margin-right: 0 !important;
    min-width: auto !important;

    .MuiButton-root {
      min-width: 30px;
    }

    .MuiButtonBase-root {
      padding: 0 !important;
    }
  `,
  basketHeading: css`
    min-width: 100%;
    display: flex;
    align-items: center;
    background-color: ${theme.palette.secondary.main};
    box-shadow: ${commonStyling.boxShadowLight};
    color: ${COLORS.white};
    min-height: 64px;
    .MuiSvgIcon-root {
      width: 32px;
      height: 25px;
      padding-right: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(1)}px;
    }
    .MuiTypography-root {
      span {
        padding: 0 6px;
        line-height: 1.15;
      }
    }

    .MuiGrid-root {
      flex-wrap: nowrap;
      align-items: center;
    }
  `,
  miniBasketContent: css`
    height: 100%;
    max-width: 100%;
  `,
  bottomContainer: css`
    justify-content: center;
    flex-direction: column;
  `,
  productsNumber: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: ${theme.spacing(2.625)}px;
    height: ${theme.spacing(1.5)}px;
    width: ${theme.spacing(1.5)}px;
    border-radius: 50%;
    background-color: ${COLORS.tertiary};
    color: ${COLORS.primary};
    font-size: ${fontSizes.smallText};
  `,
  orderButton: css`
    padding: ${theme.spacing(2)}px;
    flex: 1;
  `,
  item: css`
    &.MuiListItem-root {
      padding: ${theme.spacing(1, 2, 1)};
      &:last-child {
        padding-bottom: 0;
      }
    }
    .MuiListItemText-root {
      display: flex;
      align-items: center;

      img {
        max-height: 18px;
        margin-right: ${theme.spacing(2)}px;
      }
    }
    .MuiTypography-body1 {
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightRegular!};
    }
  `,
  activeItem: css`
    .MuiTypography-body1 {
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightMedium!};
    }
  `,
  activeSubItem: css`
    .MuiListItemText-root {
      color: ${theme.palette.primary.main};
    }
  `,
  subMenuItem: css`
    &.MuiList-root {
      margin: ${theme.spacing(0, 0, 1, 9)};
      color: ${COLORS.bodyTextPrimary};

      .MuiListItem-root {
        padding: ${theme.spacing(0, 2, 1)};
        &:not(:first-child) {
          padding: ${theme.spacing(2, 2, 1)};
        }
      }
    }
  `,
  trustpilotWrapper: css`
    border-top: 1px solid ${theme.palette.grey[300]};
  `,
  errorMessage: css`
    background-color: white !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .MuiTypography-root {
      font-size: ${fontSizes.body2};
    }
  `,
  categoryNav: css`
    background-color: ${theme.palette.warning.light};

    &.MuiList-root {
      padding: ${theme.spacing(6, 0, 4)};
    }
  `,
  closeIcon: css`
    text-align: right;
    background-color: ${theme.palette.warning.light};
    padding-right: ${theme.spacing(2)}px;

    .MuiSvgIcon-root {
      padding: ${theme.spacing(2, 2, 1)};
    }
  `,
  sharedNav: css`
    .MuiTypography-root {
      font-size: ${fontSizes.body2};
    }
    .MuiListItem-root {
      padding: ${theme.spacing(1, 2, 0)};
    }
  `,
  primaryButtonWrapper: css`
    position: fixed;
    bottom: 0;
    background-color: white;
    border-top: 2px solid ${theme.palette.warning.light};
    width: 100%;
    text-align: center;
    padding: ${theme.spacing(2, 2)};
  `,
  subMenuIcon: css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${commonStyling.minSizeClickableTab};
    min-height: ${commonStyling.minSizeClickableTab};
  `,
  navigationTabs: css`
    background: white;
    #header[class*=inverted] & {
      background: none;
      button {
        color: #fff;
      }
    }
  `,
}

export default classes
