import React from 'react'
import {
  SvgIcon, SvgIconProps,
} from '@material-ui/core'

export default function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 47">
      <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1628 20.2341C19.5623 20.4276 20.0055 20.4963 20.4487 20.4338C20.8856 20.3714 21.2976 20.1779 21.6284 19.8845C21.8469 19.6848 22.0279 19.4476 22.1528 19.1792C22.2776 18.917 22.3525 18.6236 22.3713 18.3302C22.3838 18.0368 22.3401 17.7372 22.2464 17.4626C22.1465 17.1817 21.9905 16.932 21.797 16.7073C21.4974 16.3764 21.1103 16.1455 20.6796 16.0393C20.2552 15.9332 19.7995 15.952 19.3813 16.1018C18.963 16.2516 18.601 16.52 18.3388 16.8758C18.0766 17.2316 17.9206 17.6561 17.9019 18.0993C17.8769 18.5425 17.9893 18.9794 18.214 19.3602C18.4387 19.741 18.7695 20.0468 19.169 20.2341H19.1628Z" fill="#17AB33"/>
        <path d="M25.8794 20.2341C26.2789 20.4276 26.7221 20.4963 27.1653 20.4338C27.6023 20.3714 28.0143 20.1779 28.3451 19.8845C28.5698 19.6848 28.7446 19.4476 28.8757 19.1792C29.0005 18.917 29.0754 18.6236 29.0942 18.3302C29.1129 18.0368 29.0692 17.7372 28.9693 17.4626C28.8694 17.1817 28.7134 16.932 28.5199 16.7073C28.2265 16.3764 27.8332 16.1455 27.4088 16.0393C26.9843 15.9332 26.5286 15.952 26.1104 16.1018C25.6922 16.2516 25.3301 16.52 25.0679 16.8758C24.8058 17.2316 24.6497 17.6561 24.631 18.0993C24.606 18.5425 24.7184 18.9794 24.9431 19.3602C25.1678 19.741 25.5049 20.0468 25.8982 20.2341H25.8794Z" fill="#17AB33"/>
        <path d="M32.6023 20.2342C33.0018 20.4277 33.4512 20.4963 33.8882 20.4339C34.3251 20.3715 34.7371 20.178 35.0679 19.8846C35.2864 19.6848 35.4674 19.4476 35.5923 19.1792C35.7171 18.9171 35.792 18.6237 35.8107 18.3303C35.8295 18.0369 35.7858 17.7435 35.6859 17.4626C35.586 17.1817 35.43 16.9258 35.2365 16.7073C34.9368 16.3827 34.5498 16.1455 34.1191 16.0394C33.6946 15.9333 33.239 15.9583 32.8207 16.1018C32.4025 16.2516 32.0405 16.5201 31.7783 16.8759C31.5161 17.2317 31.3601 17.6561 31.3413 18.0993C31.3164 18.5425 31.4225 18.9795 31.6535 19.3602C31.8782 19.741 32.2152 20.0469 32.6085 20.2342H32.6023Z" fill="#17AB33"/>
        <path d="M18.2015 26.077C18.4262 26.4578 18.7571 26.7636 19.1566 26.9509C19.5561 27.1444 19.9993 27.2131 20.4424 27.1506C20.8794 27.0882 21.2914 26.8947 21.6222 26.6013C21.8407 26.4016 22.0217 26.1644 22.1466 25.896C22.2714 25.6338 22.3463 25.3404 22.365 25.047C22.3775 24.7536 22.3338 24.454 22.2402 24.1794C22.1403 23.8985 21.9843 23.6488 21.7908 23.4241C21.4911 23.0995 21.1041 22.8623 20.6734 22.7561C20.2489 22.65 19.7933 22.6688 19.375 22.8186C18.9568 22.9684 18.5948 23.2368 18.3326 23.5926C18.0704 23.9484 17.9144 24.3729 17.8956 24.8161C17.8707 25.2593 17.983 25.6962 18.2077 26.077H18.2015Z" fill="#17AB33"/>
        <path d="M24.9244 26.077C25.1491 26.4578 25.4862 26.7636 25.8794 26.9509C26.2789 27.1444 26.7221 27.2131 27.1653 27.1506C27.6023 27.0882 28.0143 26.8947 28.3451 26.6013C28.5698 26.4016 28.7446 26.1644 28.8757 25.896C29.0005 25.6338 29.0754 25.3404 29.0942 25.047C29.1129 24.7536 29.0692 24.454 28.9693 24.1794C28.8694 23.8985 28.7134 23.6488 28.5199 23.4241C28.2265 23.0995 27.8332 22.8623 27.4088 22.7561C26.9843 22.65 26.5286 22.6688 26.1104 22.8186C25.6922 22.9684 25.3301 23.2368 25.0679 23.5926C24.8058 23.9484 24.6497 24.3729 24.631 24.8161C24.606 25.2593 24.7184 25.6962 24.9431 26.077H24.9244Z" fill="#17AB33"/>
        <path d="M31.6472 26.077C31.8719 26.4578 32.2027 26.7636 32.6022 26.9509C33.0017 27.1444 33.4449 27.2131 33.8881 27.1506C34.3251 27.0882 34.7371 26.8947 35.0679 26.6013C35.2864 26.4016 35.4674 26.1644 35.5922 25.896C35.7171 25.6338 35.792 25.3404 35.8107 25.047C35.8232 24.7536 35.7795 24.454 35.6859 24.1794C35.586 23.8985 35.4299 23.6488 35.2364 23.4241C34.9368 23.0995 34.5498 22.8623 34.1191 22.7561C33.6946 22.65 33.2389 22.6688 32.8207 22.8186C32.4025 22.9684 32.0404 23.2368 31.7783 23.5926C31.5161 23.9484 31.36 24.3729 31.3413 24.8161C31.3163 25.2593 31.4287 25.6962 31.6534 26.077H31.6472Z" fill="#17AB33"/>
        <path d="M15.5235 24.1857C15.4236 23.9048 15.2676 23.6551 15.0741 23.4304C14.7745 23.1058 14.3874 22.8686 13.9567 22.7625C13.5323 22.6564 13.0766 22.6751 12.6584 22.8249C12.2401 22.9747 11.8781 23.2431 11.6159 23.5989C11.3537 23.9547 11.1977 24.3792 11.179 24.8224C11.154 25.2656 11.2664 25.7026 11.4911 26.0833C11.7158 26.4641 12.0466 26.77 12.4461 26.9572C12.8456 27.1507 13.2888 27.2194 13.732 27.157C14.169 27.0946 14.581 26.9011 14.9118 26.6077C15.1303 26.4079 15.3113 26.1707 15.4361 25.9023C15.561 25.6401 15.6359 25.3468 15.6546 25.0534C15.6671 24.76 15.6234 24.4604 15.5298 24.1857H15.5235Z" fill="#17AB33"/>
        <path d="M18.2015 32.7996C18.4262 33.1804 18.7571 33.4863 19.1566 33.6735C19.5561 33.8671 19.9993 33.9357 20.4424 33.8733C20.8794 33.8109 21.2914 33.6174 21.6222 33.324C21.8407 33.1242 22.0217 32.887 22.1466 32.6186C22.2714 32.3564 22.3463 32.0631 22.365 31.7697C22.3775 31.4763 22.3338 31.1767 22.2402 30.902C22.1403 30.6211 21.9843 30.3714 21.7908 30.1467C21.4911 29.8159 21.1041 29.5849 20.6734 29.4788C20.2489 29.3727 19.7933 29.3914 19.375 29.5412C18.9568 29.691 18.5948 29.9594 18.3326 30.3153C18.0704 30.6711 17.9144 31.0955 17.8956 31.5387C17.8707 31.9819 17.983 32.4189 18.2077 32.7996H18.2015Z" fill="#17AB33"/>
        <path d="M24.9244 32.7996C25.1491 33.1804 25.4862 33.4863 25.8794 33.6735C26.2789 33.8671 26.7221 33.9357 27.1653 33.8733C27.6023 33.8109 28.0143 33.6174 28.3451 33.324C28.5698 33.1242 28.7446 32.887 28.8757 32.6186C29.0005 32.3564 29.0754 32.0631 29.0942 31.7697C29.1129 31.4763 29.0692 31.1767 28.9693 30.902C28.8694 30.6211 28.7134 30.3714 28.5199 30.1467C28.2265 29.8159 27.8332 29.5849 27.4088 29.4788C26.9843 29.3727 26.5286 29.3914 26.1104 29.5412C25.6922 29.691 25.3301 29.9594 25.0679 30.3153C24.8058 30.6711 24.6497 31.0955 24.631 31.5387C24.606 31.9819 24.7184 32.4189 24.9431 32.7996H24.9244Z" fill="#17AB33"/>
        <path d="M13.9567 29.4851C13.5323 29.379 13.0766 29.3978 12.6584 29.5476C12.2401 29.6974 11.8781 29.9658 11.6159 30.3216C11.3537 30.6774 11.1977 31.1019 11.179 31.5451C11.154 31.9883 11.2664 32.4252 11.4911 32.806C11.7158 33.1868 12.0466 33.4926 12.4461 33.6799C12.8456 33.8734 13.2888 33.9421 13.732 33.8796C14.169 33.8172 14.581 33.6237 14.9118 33.3303C15.1303 33.1306 15.3113 32.8934 15.4361 32.625C15.561 32.3628 15.6359 32.0694 15.6546 31.776C15.6671 31.4826 15.6234 31.183 15.5298 30.9084C15.4299 30.6275 15.2738 30.3778 15.0803 30.1531C14.7807 29.8222 14.3937 29.5913 13.963 29.4851H13.9567Z" fill="#17AB33"/>
        <path d="M42.571 6.83838H35.1053V10.746L38.6821 10.7397V37.5187H8.38252V10.7772L11.6784 10.771V6.83838H4.40625V41.4139H42.571V6.83838Z" fill="#2E0F4F"/>
        <path d="M31.1978 6.83838H15.5798V10.7647L31.1978 10.7522V6.83838Z" fill="#2E0F4F"/>
        <path d="M15.5798 6.8385V5.14062H11.6785V6.8385V10.7711V12.7811H15.5798V10.7711V10.7648V6.8385Z" fill="#17AB33"/>
        <path d="M35.1054 6.8385V5.14062H31.1978V6.8385V10.7524V12.7811H35.1054V10.7461V6.8385Z" fill="#17AB33"/>
      </svg>
    </SvgIcon>
  )
}
