import { AxiosInstance } from 'axios'

export type ExperianEmail = {
  status: string;
  error: string;
  message: string;
  result: {
    email: string;
    confidence: string;
    verbose_output: string;
  };
}

export type ExperianPhone = {
  status: string;
  error: string;
  message: string;
  result: {
    number: string;
    confidence: string;
  };
}

export default function Experian(client: AxiosInstance) {
  async function validateEmail(email: string) {
    try {
      const params = {
        email,
      }
      const {
        data,
      } = await client.post<{ data: ExperianEmail }>('email/validate', params)
      return data
    } catch (err) {
      return err
    }
  }

  async function validatePhone(phone: string) {
    try {
      const params = {
        number: phone,
        country_iso: 'GBR',
      }
      const {
        data,
      } = await client.post<{ data: ExperianPhone }>('phone/validate', params)
      return data
    } catch (err) {
      return err
    }
  }

  return {
    validateEmail,
    validatePhone,
  }
}
