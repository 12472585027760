import React from 'react'
import {
  Grid, SvgIconProps,
} from '@material-ui/core'

import IndicatorIcon from './IndicatorIcon'
import ContainedIndicatorIcon from './ContainedIndicatorIcon'

interface IndicatorProps {
  length: number;
  activeIndex: number;
  handleClick: (i: number) => void;
  color: string;
  inactiveColor?: string;
  className?: string;
  size?: SvgIconProps['fontSize'];
  type?: string;
}

export default function Indicators({
  length, activeIndex, handleClick, color, className,
  size, type, inactiveColor,
}: IndicatorProps) {
  return (
    <Grid container justify="center" classes={{ root: className }}>
      {[...new Array(length)
        .keys()].map((index) =>
        (
          <Grid
            item
            component={type === 'contained' ? ContainedIndicatorIcon : IndicatorIcon}
            colorOverride={color}
            colorOverrideInactive={inactiveColor}
            key={index} active={index === activeIndex} onClick={() => handleClick(index)}
            fontSize={size || 'large'}
          />
        ),
      )}
    </Grid>
  )
}
