import React, {
  useContext, useEffect, useState,
} from 'react'
import {
  Box, Link, Typography,
  useMediaQuery,
} from '@material-ui/core'
import ModalBox from '../ModalBox/ModalBox'
import createCRMApiClient from '../../api/CRMApi'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import theme from '../../styles/theme'
import { CopyWithCTAProps } from '../CopyWithCTA/CopyWithCTA'
import classes from './ExistingCustomerModal.styles'
import MediaImage from '../Media/MediaImage'
import StyledButton, { ButtonProps } from '../StyledButton/StyledButton'
import { ArrowBack } from '@material-ui/icons'
import {
  OrderOptions, useOrderContext,
} from '../NewOrderFlow/OrderContext'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useRouter } from 'next/router'
import {
  CartProps, removeProductsFromCart,
} from '../Packages/Helpers'
import { SegmentType } from '../../utils/commonEnums'

export interface ExistingCustomerModalProps {
    anchor_id: string;
    title?: string;
    description?: string;
    copy_with_cta?: CopyWithCTAProps[];
    back_link?: ButtonProps;
  }

interface Response {
    data: {
      stage: {
        name: String;
      };
    };
    message: string;
    error: string;
  }

export default function ExistingCustomerModal({
  anchor_id,
  title,
  description,
  copy_with_cta,
  back_link,
}: ExistingCustomerModalProps) {
  const { premise } = useContext(PremiseContext)
  const [
    verifiedAddress,
    setVerifiedAddress,
  ] = useState<boolean>(false)

  const [
    verifiedAddressHobs,
    setVerifiedAddressHobs,
  ] = useState<boolean>(false)

  const {
    options,
    setOptions,
  } = useOrderContext()

  const [
    open,
    setOpen,
  ] = useState<boolean>(false)

  const [
    modalShowedEvent,
    setModalShowedEvent,
  ] = useState<boolean>(false)

  const router = useRouter()
  const client = createCRMApiClient()

  const verifyAddress = async (address: string) => {
    const { data } = await client.accounts.verifyAddress(address) as Response
    const stage = data?.stage.name
    if (data && stage !== 'INACTIVE') {
      setVerifiedAddress(true)
    }
  }

  const verifyAddressWithHobs = async (sprnNumber: string) => {
    const response = await client.payment.verifyAddressWithHobs({ sprn: `${sprnNumber}` }) as any
    const statusCode = response?.statusCode
    if (statusCode && statusCode === '2000') {
      setVerifiedAddressHobs(true)
    }
  }

  const handleClose = (_event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>, reason: string) => {
    // Prevent modal from closing when clicking on backdrop or pressing Escape key
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false)
    }
  }

  const ctaCopy = (copy: string): JSX.Element => (
    copy.includes('080') ? (
      <>
        <span>Call us on&nbsp;</span>
        <span className={classes.underline}>{copy}</span>
      </>
    ) : (
      <span>{copy}</span>
    )
  )

  const clickEvents = (copy: string) => {
    if (copy.includes('080')) {
      marketingEvents.simpleEventWithParams('Exist_customer_phone_click', options, premise!, SegmentType.RESIDENTIAL)
    } else {
      marketingEvents.simpleEventWithParams('Exist_customer_myAccount_click', options, premise!, SegmentType.RESIDENTIAL)
    }
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (premise?.address) {
      verifyAddress(premise!.address)
      verifyAddressWithHobs(premise!.sprn.toString())
    }
  }, [premise])

  useEffect(() => {
    const isVerified = verifiedAddress || verifiedAddressHobs
    setOpen(isVerified)

    const emptyCart = async () => {
      const productTypes = [
        'broadband',
        'bundle',
        'wier',
        'vas',
        'tv',
        'voice',
        'voucherProduct',
      ]

      const removalPromises = productTypes
        .map((type) => {
          const product = options[type as keyof OrderOptions]
          if (product) {
            const cartProps: CartProps = {
              product,
              client,
              channel: options.channel,
              options,
            }
            return removeProductsFromCart(cartProps)
          }

          return null
        })
        .filter(Boolean)

      await Promise.all(removalPromises)
      setOptions({
        ...options,
        broadband: null,
        bundle: null,
        tv: null,
        vas: null,
        voice: null,
        voucherProduct: null,
      })
    }

    if (isVerified) {
      emptyCart()

      if (!modalShowedEvent) {
        marketingEvents.simpleEventWithParams('Exist_customer_modal_shown', options, premise!, SegmentType.RESIDENTIAL)
        setModalShowedEvent(true)
      }
    }
  }, [
    verifiedAddress,
    verifiedAddressHobs,
  ])

  return (
    <Box id={anchor_id}>
      <ModalBox visible={open} onClose={handleClose} maxWidth="800px" px={isMobile ? 2 : 5} py={5} noCloseIcon>
        {title &&
        <Typography variant="h4" component="p" color="primary" align={isMobile ? 'left' : 'center'}>{title}</Typography>}
        {description &&
        <Box pt={2} pb={3}>
          <Typography variant="body1" color="textPrimary" align={isMobile ? 'left' : 'center'}>{description}</Typography>
        </Box>}
        {copy_with_cta && copy_with_cta.length > 0 &&
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" className={classes.container}>
          {Object.values(copy_with_cta)
            .map((item: CopyWithCTAProps, index: number) => {
              return (
                <Box
                  id="greybackground"
                  key={index}
                  p={2}
                  className={classes.greyContainer}
                >
                  {item.media_image && item.media_image?.src &&
                    <Box
                      pb={2}
                      display="flex" justifyContent="flex-start"
                    >
                      <MediaImage
                        src={item.media_image.src}
                        width={50}
                        height={50}
                        alt={item.media_image.alt}
                        mobile_src={item.media_image.mobile_src}
                        mobile_width={item.media_image.mobile_width}
                        mobile_height={item.media_image.mobile_height}
                        use_img_for_seo={item.media_image.use_img_for_seo}
                      />
                    </Box>}
                  <Box>
                    <Typography variant="body1" color="primary" className={classes.bold}>{item.title}</Typography>
                    {item.body &&
                    <Box pt={2} pb={3}>
                      <Typography variant="body1" color="textPrimary">{item.body}</Typography>
                    </Box>}
                    {item.styled_text && !item.body &&
                    // eslint-disable-next-line react/no-danger
                    <Box mt={3}><div dangerouslySetInnerHTML={{ __html: item.styled_text }}/>
                    </Box>}
                    {item.primary_button?.url && (
                      <StyledButton
                        color="primary"
                        isFullWidth
                        isModal={item.primary_button.modal}
                        handleClick={() => {
                          clickEvents(item.primary_button!.text)
                        }}
                        url={item.primary_button.url}
                      >
                        {ctaCopy(item.primary_button.text)}
                      </StyledButton>
                    )}
                  </Box>
                </Box>
              )
            })}
        </Box>}
        {back_link && back_link.text &&
          <Box pt={3}>
            <Link
              color="secondary" underline="always" href={back_link.url || '#'}
              onClick={(e: React.ChangeEvent<{}>) => {
                marketingEvents.simpleEventWithParams('Exist_customer_home_link', options, premise!, SegmentType.RESIDENTIAL)
                if (!back_link.url) {
                  e.preventDefault()
                  const previousUrl = document.referrer
                  const cleanUrl = previousUrl.split('#')[0]
                  router.push(cleanUrl)
                }
              }}
              className={classes.backLink}
            >
              <ArrowBack color="secondary"/>
              <Typography color="secondary" className={classes.bold}>{back_link.text}</Typography>
            </Link>
          </Box>}
      </ModalBox>
    </Box>
  )
}
