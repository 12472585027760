import React from 'react'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Box,
} from '@material-ui/core'
import { classes } from './CustomTable.styles'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'

 interface RowProps {
    meta: {
      id: number;
    };
    title: string;
    table_row: string;
  }

export interface CustomTableProps {
    columns: string;
    rows: RowProps[];
    margin_top: number;
    margin_bottom: number;
  }

export default function CustomTable({
  columns,
  rows,
  margin_top,
  margin_bottom,
}: CustomTableProps) {
  const columnsArray: string[] = columns.split('||')
  const displayedRows: string[][] = rows.map(item => item.table_row.split('||'))

  return (
    <ContainerWithPadding>
      <Box mt={margin_top} mb={margin_bottom}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {columnsArray.map((col, index) => (
                  <TableCell key={index} className={classes.tableCell}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} className={classes.tableCell}>
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ContainerWithPadding>
  )
}
