import { getMonthlyCost } from './getProductDetails'
import { fillArray } from './fillArray'
import { getOrderProducts } from './getOrderProducts'

export const getPriceBreakdown = ({
  broadband, voice, tv, wier, vas, bundle,
}: {broadband: any; bundle?: any | null; voice?: any | null; tv?: any | null; wier?: any | null; vas?: any | null}) => {
  const noFreePeriod = broadband.productPrices[0].freePeriodLength === 0 && voice?.productPrices[0].freePeriodLength === 0 && tv?.productPrices[0].freePeriodLength === 0 && wier?.productPrices[0].freePeriodLength === 0 && vas?.productPrices[0].freePeriodLength === 0
  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })
  const totalCost = getMonthlyCost({
    broadband,
    bundle,
    tv,
    voice,
    wier,
    vas,
  })

  if (noFreePeriod) {
    return null
  }

  const sortedProducts = orderProducts.sort((a: any, b: any) => b.productPrices[0].freePeriodLength - a.productPrices[0].freePeriodLength)

  if (sortedProducts && sortedProducts[0].productPrices[0].freePeriodLength > 0 && sortedProducts[1] && sortedProducts[1].productPrices[0].freePeriodLength > 0 && sortedProducts[2] && sortedProducts[2].productPrices[0].freePeriodLength > 0) {
    const firstBreakdown: number = totalCost - parseInt(sortedProducts[2].productPrices[0].price, 10) - parseInt(sortedProducts[1].productPrices[0].price, 10) - parseInt(sortedProducts[0].productPrices[0].price, 10)
    const secondBreakdown: number = totalCost - parseInt(sortedProducts[1].productPrices[0].price, 10) - parseInt(sortedProducts[0].productPrices[0].price, 10)
    const thirdBreakdown: number = totalCost - parseInt(sortedProducts[0].productPrices[0].price, 10)

    const result = fillArray(firstBreakdown, sortedProducts[2].productPrices[0].freePeriodLength)
      .concat(fillArray(secondBreakdown, sortedProducts[1].productPrices[0].freePeriodLength - sortedProducts[2].productPrices[0].freePeriodLength))
      .concat(fillArray(thirdBreakdown, sortedProducts[0].productPrices[0].freePeriodLength - sortedProducts[1].productPrices[0].freePeriodLength))

    return result
  }

  if (sortedProducts && sortedProducts[0].productPrices[0].freePeriodLength > 0 && sortedProducts[1] && sortedProducts[1].productPrices[0].freePeriodLength > 0) {
    const firstBreakdown: number = totalCost - parseInt(sortedProducts[1].productPrices[0].price, 10) - parseInt(sortedProducts[0].productPrices[0].price, 10)
    const secondBreakdown: number = totalCost - parseInt(sortedProducts[0].productPrices[0].price, 10)

    const result = fillArray(firstBreakdown, sortedProducts[1].productPrices[0].freePeriodLength)
      .concat(fillArray(secondBreakdown, sortedProducts[0].productPrices[0].freePeriodLength - sortedProducts[1].productPrices[0].freePeriodLength))

    return result
  }

  if (sortedProducts && sortedProducts[0].productPrices[0].freePeriodLength > 0) {
    const freePeriodLength = sortedProducts[0].productPrices[0].freePeriodLength
    const currentCost: number = totalCost - parseFloat(sortedProducts[0].productPrices[0].price)

    const result = fillArray(currentCost, freePeriodLength)

    return result
  }

  return null
}
