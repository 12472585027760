import { css } from 'linaria'

import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  bodyRight: css`
    ${theme.breakpoints.up('md')} {
      padding: 0;
      padding-right: ${theme.spacing(10)}px;
      margin: 0 auto;
    }
  `,
  bodyLeft: css`
    ${theme.breakpoints.up('md')} {
      padding: 0;
      padding-left: ${theme.spacing(10)}px;
      margin: 0 auto;
    }
  `,
  containerGen: css`
    .MuiCard-root{
      overflow: visible;
    }
  `,
  container: {
    white: css`
      color: ${COLORS.bodyTextPrimary};
      overflow: visible !important;
      padding: ${theme.spacing(5, 0)};
      background-color: white;
      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(8, 0)};
      }
      & .MuiPaper-root{
        background: transparent;
      }
    `,
    gradient: css`
      color: ${COLORS.bodyTextPrimary};
      overflow: visible !important;
      padding: ${theme.spacing(5, 0)};
      background-image: ${commonStyling.backgroundGradient};
      ${theme.breakpoints.up('md')} {
        padding:  ${theme.spacing(8, 0)};
      }
      & .MuiPaper-root{
        background: transparent;
      }
    `,
  },
  mediaWrapper: css`
    position: relative;
    overflow: hidden;
    width: 100%;
  `,
  content: css`
    font-weight: ${theme.typography.fontWeightLight!};
    h2 {
      font-size: ${fontSizes.h3};
      line-height: 1.15;
      color: ${theme.palette.primary.main};
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
      }
    }
    p, li {
      font-weight: ${theme.typography.fontWeightLight!};
      color: ${COLORS.bodyTextPrimary};
    }
    li::marker {
      color: ${COLORS.primary}
    }
    ul {
      list-style-image: url('/Icon_material-check.svg');
      padding-inline-start: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      h2, h3 {
        margin: ${theme.spacing(0, 0, 4, 0)};
      }
    }
  `,
  reverseContent: css`
    ${theme.breakpoints.up('md')} {
      flex-direction: row-reverse;
    }
  `,
  mediaLeftAndDown: css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;
    }
  `,
  mediaRightAndUp: css`
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row-reverse;
    }
  `,
  mediaRightAndDown: css`
    flex-direction: column-reverse;
    ${theme.breakpoints.up('md')} {
      flex-direction: row-reverse;
    }
  `,
  minWidth: css`
    min-width: 100%;
    ${theme.breakpoints.up('md')} {
      min-width: auto;
    }
  `,
  buttonsContainer: css`
    display: flex;
    align-items: center;
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 5px 0;
    ${theme.breakpoints.up('md')} {
      justify-content: center;
      overflow-x: inherit;
    }
  `,
  buttonsWrapper: css`
    padding: 0 ${theme.spacing(1)}px 0 5px;
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(0, 2)};
    }
`,
  cancellationButton: css`
    background-color: #fff !important;
    box-shadow: 0px 0px 6px #00000029 !important;
    color: ${theme.palette.primary.main} !important;
    padding: ${theme.spacing(0, 2)};
    width: 165px;
    height: 57px;
    transition: transform 1s;

    :hover {
      transform: scale(1.025, 1.025);
      ${theme.breakpoints.up('md')} {
        transform: scale(1.1, 1.1);
      }
    }
  `,
  image: css`
    width: 20px;
    height: 20px;
    margin-left: ${theme.spacing(1)}px;
  `,
  anchorButton: css`
    &.MuiButtonBase-root {
      text-decoration: underline;
      margin-top: ${theme.spacing(1)}px;
      font-size: ${fontSizes.body1};
      width: 100%;
    }
    &.MuiButton-root:hover {
      background-color: transparent;
      text-decoration: underline;
    }
    ${theme.breakpoints.up('sm')} {
      &.MuiButtonBase-root {
        margin-top: 0;
        margin-left: ${theme.spacing(3)}px;
        width: auto;
      }
    }
  `,
  primaryButton: css`
    .MuiButtonBase-root {
      width: 100%;
    }
    ${theme.breakpoints.up('sm')} {
      .MuiButtonBase-root {
        width: auto;
      }
    }
  `,
  ctaRow: css`
    .MuiGrid-root {
      margin: ${theme.spacing(4, 0)};
    }
  `,
  smallText: css`
    &.MuiTypography-body1{
      font-size: ${fontSizes.subtitle2};
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightRegular!};
    }
  `,
  iconsWithTextCustomIcons: css`
    margin-bottom: ${theme.spacing(4)}px;
  `,
  iconItemContainer: css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    &.MuiGrid-root{
      margin-top: ${theme.spacing(2)}px;
    }
    img{
      margin-right: ${theme.spacing(2)}px;
      ${theme.breakpoints.up('sm')} {
        margin-right: ${theme.spacing(3)}px;
      }
    }
 `,
  iconItemTitle: css`
    padding-bottom: ${theme.spacing(1)}px;
    &.MuiTypography-root {
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  iconItemText: css`
    p {
      margin-top: ${theme.spacing(1)}px;
      margin: 0;
      font-weight: ${theme.typography.fontWeightLight!};
      line-height: 27px;
      color: ${COLORS.bodyTextPrimary};
    }

    a {
      color: ${COLORS.secondaryContrast};
    }
  `,
}

export default classes
