import React from 'react'
import { Typography } from '@material-ui/core'
import { useRouter } from 'next/router'
import { MenuItem } from '../Header'
import Link from 'next/link'
import classes from './DesktopHeader.styles'
import { cx } from 'linaria'
import commonStyling from '../../../styles/commonStyling'

export interface DesktopSubHeaderProps {
  subMenuItems?: MenuItem[];
}

export default function DesktopSubHeader({
  subMenuItems,
}: DesktopSubHeaderProps) {
  const router = useRouter()
  const currentPath = router.asPath

  if (!subMenuItems) {
    return null
  }

  return (
    <ul className={classes.submenu}>
      {subMenuItems
        .map((item, i) => {
          const isCurrentPath = item.url === currentPath
          const activeItemClass = cx(isCurrentPath && classes.activeItem)

          if (item.modal) {
            return (
              <li key={i}>
                <a
                  className={activeItemClass}
                  href={item.url ?? ''}
                  onClick={(e) => {
                    e.stopPropagation()
                    router.push(`${router.asPath}${item.url}`, undefined, { shallow: true })
                  }}
                >
                  <Typography variant="body2">
                    {item.title}
                  </Typography>
                </a>
              </li>
            )
          }

          return (
            <li key={i}>
              <Link href={item.url ?? ''}>
                <a className={activeItemClass}>
                  <Typography variant="body2" data-text={item.title} className={commonStyling.differentFontWeightOnHover}>
                    {item.title}
                  </Typography>
                </a>
              </Link>
            </li>
          )
        })}
    </ul>
  )
}
