import React, { useEffect } from 'react'
import {
  Grid, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'

import { HotBox } from './HotBoxes'
import classes from './HotBoxes.styles'
import HotBoxSlide from './HotBoxSlide'

type HotBoxItemProps = {
  item: HotBox;
  isOpen: boolean;
  isMobile: boolean;
  index: number;
  hotBoxHeight: number;
  setHotBoxHeight: (height: number) => void;
}

export default function HotBoxItem({
  item,
  isOpen,
  isMobile,
  index,
  hotBoxHeight,
  setHotBoxHeight,
}: HotBoxItemProps): JSX.Element | null {
  const style: React.CSSProperties = {}
  if (item.image) {
    style.backgroundImage = `url("${item.image}")`
    style.minHeight = `${hotBoxHeight}px`
  }

  useEffect(() => {
    if (!window || isMobile) {
      return
    }

    const slideDesktopHeight = document.querySelector(`#slide-desktop-hotboxes-${index}`)?.clientHeight
    if (slideDesktopHeight && slideDesktopHeight > hotBoxHeight) {
      setHotBoxHeight(slideDesktopHeight)
    }
  }, [
    hotBoxHeight,
    isMobile,
  ])

  const titleColour = item.title_color === 'primary' ? classes.primaryTitle : classes.whiteTitle

  return (
    <Grid
      container direction="column" justify="center"
      alignItems="center"
      className={cx(classes.itemWrapper, item.add_overlay && classes.overlayWrapper)}
      style={style}
    >
      <Typography className={titleColour} variant="body1" component="h5">{item.title}</Typography>
      {!isMobile && (
        <HotBoxSlide id={`slide-desktop-hotboxes-${index}`} item={item} isMobile={false} isOpen={isOpen} direction={index % 2 ? 'left' : 'right'}/>
      )}
    </Grid>
  )
}
