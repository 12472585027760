import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  underline: css`
    border-top: 1px solid ${COLORS.gray2};
  `,
  blueOfferBox: css`
    background: ${COLORS.blue};
    border-radius: 4px;
    padding: ${theme.spacing(2, 3)};
    margin-top: ${theme.spacing(3)}px;
    .MuiTypography-root {
      color: ${COLORS.white};
    }
    .MuiTypography-button {
      font-weight: ${theme.typography.fontWeightBold!};
      text-decoration: underline;
    }
    .MuiButton-text {
      padding: ${theme.spacing(1, 2, 1, 0)};
  }
  `,
  contractForm: css`
    width: 100%;
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }
    form {
      .MuiFormLabel-root{
        font-size: ${fontSizes.body1};
        font-weight: ${theme.typography.fontWeightBold!};
      }
      .MuiTextField-root{
        margin-top: ${theme.spacing(2)}px;
      }
    }
  `,
  inputLabel: css`
    &.MuiTypography-root {
      margin: ${theme.spacing(3, 0)};
      font-size: ${fontSizes.body1};
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary};
    }
  `,
  errorLabel: css`
    color: red !important;
  `,
  selectClass: css`
    &.MuiInput-root {
      background-color: #F8F8F8;
      border-radius: 50px;
      overflow: hidden;
      width: 100%;

      .MuiSelect-select.MuiSelect-select {
        padding-left: ${theme.spacing(3)}px;
        padding-top: 12px;
        padding-bottom: 14px;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
  `,
  activeSelect: css`
    &.MuiInput-root {
      background: ${COLORS.smoke};
      color: ${COLORS.primary};
      .MuiSelect-select.MuiSelect-select {
        color: ${COLORS.primary};
      } 
    }
  `,
  errorInput: css`
    border: 1px solid red !important;
  `,
  icon: css`
    padding-right: ${theme.spacing(1)}px;
    &.MuiSelect-icon {
      color: ${COLORS.primary};
    }
  `,
  activeIcon: css`
    &.MuiSelect-icon {
      color: ${COLORS.primary};;
    }
  `,
  option: css`
    border: none;
    outline: none;

    &.MuiListItem-root{
      padding-top: 0px;
      padding-bottom: 0px;
      font: normal normal normal 15px/21px Poppins;

      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(1)}px;
        padding-bottom: ${theme.spacing(1)}px;
      }
    }

    &.MuiMenuItem-root:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
      color: white;
      background-color: ${COLORS.primary};
    }
 `,
  submitClasses: css`
    margin: ${theme.spacing(1, 0, 2)};
  `,
  loading: css`
    position: absolute;
    right:  ${theme.spacing(4.53)}px;;
  `,
}
export default classes
