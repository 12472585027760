import React, {
  useEffect, useState,
} from 'react'
import {
  ButterCMSComponent, getButterPage,
} from '../../butter'
import { useOrderContext } from '../../components/NewOrderFlow/OrderContext'
import App from '../../components/App/App'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { validateReferral } from '../../components/HeroWithInputs/HeroWithInputs'
import {
  decryptString,
} from '../../utils'
import { useAuth } from '../../hooks/auth'
interface Props {
  pageData: {
    components: ButterCMSComponent[];
    title: string;
    description?: string;
    keywords?: string;
  };
  customerId: string;
  referralCode: string;
}

export const clearCart = {
  cartInstanceIdentifier: '',
  broadband: null,
  bundle: null,
  wier: null,
  vas: null,
  tv: null,
  voice: null,
  discount: null,
}

export default function FriendsLandingPage({
  pageData, customerId, referralCode,
}: Props) {
  const {
    options,
    setOptions,
  } = useOrderContext()

  const [
    error,
    setError,
  ] = useState('')

  const [
    validFriend,
    setValidFriend,
  ] = useState<boolean>()

  const { isAuthenticated } = useAuth()

  const { defaultRafId } = options

  useEffect(() => {
    async function verifyReferralCode() {
      const errorName = 'RAF error'

      if (!customerId && !referralCode) {
        const errorMsg = 'No referral code in URL'
        setError(errorMsg)
        marketingEvents.error(options, 'ERR-RAF-1', errorName, errorMsg)
        return setValidFriend(false)
      }

      if (referralCode || customerId) {
        const decryptedCustomerId = decryptString(customerId)
        const response = await validateReferral(decryptedCustomerId, referralCode)
        const validResponse = response && (
          response?.message === 'Valid referral code' ||
          (referralCode && response?.data?.code === 'SUPPLIER_FAULT')) // RAF UNI1 FLOW APIGEE BUG

        if (validResponse) {
          marketingEvents.getReferralCode(options, referralCode || decryptedCustomerId)
          setOptions(state => ({
            ...state,
            ...clearCart,
            appliedReferralCode: referralCode || customerId,
            appliedProductId: defaultRafId,
          }))

          setError('')
          return setValidFriend(true)
        }
      }

      const errorMsg = 'You`ve entered an invalid referral code. Please double-check the link before completing your order to ensure you get your Amazon voucher.'
      setError(errorMsg)
      marketingEvents.error(options, 'ERR-RAF-2', errorName, errorMsg)
      return setValidFriend(false)
    }

    isAuthenticated && !validFriend && verifyReferralCode()
  }, [
    customerId,
    referralCode,
    isAuthenticated,
    validFriend,
    defaultRafId,
    setOptions,
  ])

  const {
    components,
    description,
    keywords,
    title,
  } = pageData

  return (
    <App components={components} description={description} keywords={keywords} title={title} message={error}/>
  )
}

export const getServerSideProps = async (context: any) => {
  const email = context.query.e || null
  const customerId = context.query.ci || null
  const referralCode = context.query.referral || null
  const pageData = await getButterPage('friends', {})
  return {
    props: {
      pageData,
      email,
      customerId,
      referralCode,
    },
  }
}
