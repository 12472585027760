import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  container: css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: ${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px;
    text-align: center;

    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  closeButton: css`
    button {
      color: ${COLORS.error};
      background-color: transparent;
      border: none;
      margin-top: 2px;
      cursor: pointer;
    }
`,
}

export default classes
