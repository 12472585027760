import React, { useRef } from 'react'
import {
  Typography, Box, Link,
} from '@material-ui/core'
import classes from './EnquiryBottomBar.styles'
import useIntersectionObserver from './EnquiryHook'
import StyledButton from '../StyledButton/StyledButton'
import { general } from '../../styles/theme'
import Wrapper from '../Wrapper/Wrapper'

export interface EnquiryBottomBarProps {
  promo_text1?: string;
  promo_text2?: string;
  promo_text3?: string;
  price_text: string;
  button_text?: string;
  form_anchor: string;
}

const EnquiryBottomBar = ({
  promo_text1, promo_text2, promo_text3, price_text, button_text, form_anchor,
}: EnquiryBottomBarProps) => {
  const componentRef = useRef<HTMLDivElement | null>(null)

  const [isVisible] = useIntersectionObserver({
    elementRef: componentRef,
  })
  return (
    <>
      <div ref={componentRef}/>
      {!isVisible &&
      <div className={classes.bar}>
        <Wrapper containerClasses={classes.wrapper}>
          <Box
            display="flex"
            flexDirection={{
              xs: 'column',
              lg: 'row',
            }}
            flexGrow="1"
          >
            <Box flexGrow="1">
              <Typography variant="h5" className={general.font.weight.semibold}>{promo_text1} | {promo_text2} | {promo_text3} </Typography>
            </Box>
            <Typography variant="h5" className={general.font.weight.semibold}>{price_text}</Typography>
          </Box>
          <Box ml={2}>
            <Link href={form_anchor}>
              <StyledButton
                color="primary"
              >
                {button_text}
              </StyledButton>
            </Link>
          </Box>
        </Wrapper>
      </div>}
      <div ref={componentRef}/>
    </>
  )
}

export default EnquiryBottomBar
