import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'
import theme, {
  COLORS, fontWeights,
} from '../../styles/theme'

const classes = {
  sectionWrapper: css`
    position: fixed;
    bottom: 0;
    z-index: 222;
    width: 100%;
    display: flex;
    padding: ${theme.spacing(1)}px 0;
    background-color: ${COLORS.primary};
  `,
  container: css`
    align-items: center;
    color: ${COLORS.white};
    display: flex;
    font-size: ${fontSizes.body3};
    font-weight: ${fontWeights.semibold};
  `,
  products: css`
    >*:last-child .line {
      display: none;
    }
  `,
  itemWrapperNoBorder: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: none;
    margin-left: ${theme.spacing(2)}px;
  `,
  icon: css`
    display: none;
    padding-top: 0 !important;
    ${theme.breakpoints.up('md')} {
      display: block;
      margin-right: ${theme.spacing(2)}px;
      height: 20px;
    }
  `,
  infoContainer: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `,
  button: css`
    &:focus {
      background-color: ${theme.palette.secondary.main} !important;
    }
  `,
}

export const productStyles = {
  alignItems: 'center',
  display: 'flex',
}

export const lineStyles = {
  className: 'line',
  bgcolor: COLORS.white,
  display: {
    xs: 'none',
    md: 'inline-block',
  },
  height: 44,
  mx: 2,
  width: 2,
}

export default classes
