import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  bar: css`
    background-color: ${COLORS.primary};
    bottom: 0;
    color: ${COLORS.white};
    position: fixed;
    width: 100%;
    z-index: 222;
  `,
  wrapper: css`
    display: flex;
    align-items: center;
    padding-bottom: ${theme.spacing(1)}px;
    padding-top: ${theme.spacing(1)}px;
  `,
}
export default classes
