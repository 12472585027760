import React, {
  useEffect, useState,
} from 'react'
import {
  Fade,
  Modal, Typography,
} from '@material-ui/core'
import classes from './WarningModalMessage.styles'
import StyledButton from '../StyledButton/StyledButton'
import { useGeneralContext } from '../GeneralContext/GeneralContext'

export enum WarningType {
  NOT_ELIGIBLE = 'not-eligible',
  ELIGIBLE_HIDDEN_OFFERS = 'warning-hidden-offers-eligible',
  HOMEPAGE_ELIGIBLE_HIDDEN_OFFERS = 'warning-homepage-check-offer',
  NOT_ELIGIBLE_3GIG_ELIGIBLE_HIDDEN_OFFERS = 'warning-not-eligible-3gig-hidden-offers-eligible',
}

export interface WarningModalMessageProps {
  warnings: {
    warning_type: string;
    message: string;
  }[];
  button_text: string;
}

const WarningModalMessage = ({
  warnings,
  button_text,
}: WarningModalMessageProps) => {
  const [
    open,
    setOpen,
  ] = useState<boolean>(false)

  const [
    warning,
    setWarning,
  ] = useState<{message: string; icon?: string}>()

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  const openWarningType = generalOptions.warningMessageType

  useEffect(() => {
    if (openWarningType && generalOptions.closedWarningMessageType !== openWarningType) {
      setOpen(true)

      warnings.forEach(warning => {
        if (warning.warning_type === openWarningType) {
          setWarning(warning)
        }
      })

      const warningTimeout = setTimeout(() => {
        setOpen(false)
        setGeneralOptions({
          ...generalOptions,
          warningMessageType: '',
          closedWarningMessageType: openWarningType,
        })
      }, 2500)

      return () => clearTimeout(warningTimeout)
    }

    return undefined
  }, [
    warnings,
    generalOptions,
  ])

  const handleClose = () => {
    setOpen(false)
    setGeneralOptions({
      ...generalOptions,
      warningMessageType: '',
      closedWarningMessageType: openWarningType,
    })
  }

  if (!warning) {
    return null
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="warning-message"
      aria-describedby="invalid selection"
      closeAfterTransition
    >
      <Fade in={open} timeout={{ exit: 400 }}>
        <div className={classes.textContainer}>
          {warning.icon && <img src={warning.icon} alt=""/>}
          <Typography variant="body1" className={classes.warningText}>
            {warning.message}
          </Typography>
          <StyledButton color="primary" handleClick={handleClose}>
            {button_text}
          </StyledButton>
        </div>
      </Fade>
    </Modal>
  )
}

export default WarningModalMessage
