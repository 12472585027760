import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme from '../../styles/theme'

const classes = {
  container: css`
    margin-bottom: ${theme.spacing(5)}px;
    .MuiTypography-h3 {
      margin-bottom: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      margin-bottom: ${theme.spacing(8)}px;
      .MuiTypography-h3 {
        margin-bottom: 0;
        font-size: ${fontSizes.h2};
      }
    }
  `,
  backgroundContainer: css`
    background-image: linear-gradient(180deg, white 0%, ${theme.palette.warning.light} 30%);
    .MuiTypography-h3 {
      margin-bottom: ${theme.spacing(5)}px;
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
        margin-bottom: ${theme.spacing(6)}px;
      }
    }
  `,
  categoryTab: css`
    &.MuiTab-root {
      padding: 0;
      ${theme.breakpoints.up('md')} {
        min-width: 197px;
        span {
          font-size: ${fontSizes.body1} !important;
          min-width: 197px;
        }
      }
    }
  `,
  selectedTab: css`
    box-shadow: ${commonStyling.boxShadowLight};
    border-radius: ${commonStyling.roundedBorders.top} !important;
    span {
      color: ${theme.palette.primary.main};
      z-index: 10;
    }
  `,
  categoryContainer: css`
    background: white;
  `,
}

export default classes
