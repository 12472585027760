import { AxiosInstance } from 'axios'

export default function Pinpoint(client: AxiosInstance) {
  async function sendEmailFromCF(
    templateName: string,
    emailAddress: string,
    userFirstName: string,
    userEmailAddress: string,
  ) {
    try {
      const params = {
        Addresses: {
          [userEmailAddress]: {
            ChannelType: 'EMAIL',
          },
        },
        MessageConfiguration: {
          EmailMessage: {
            FromAddress: emailAddress,
            Substitutions: {
              First_Name: [userFirstName],
            },
          },
        },
        TemplateConfiguration: {
          EmailTemplate: {
            Name: templateName,
            Version: '2',
          },
        },
      }
      const {
        data,
      } = await client.post<{ data: any}>('v1/0.0/send-message', params)
      return data
    } catch (err) {
      return err
    }
  }

  return {
    sendEmailFromCF,
  }
}
