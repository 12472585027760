import { css } from 'linaria'
import fontSizes from '../../../styles/fontSizes'
import commonStyling from '../../../styles/commonStyling'
import theme from '../../../styles/theme'

const classes = {
  main: css`
    position: fixed;
    background-color: ${theme.palette.primary.main};
    color: white;
    opacity: 0;
    top: 185px;
    width: 212px;
    max-height: 140px;
    right: 10px;
    z-index: 1150;
    box-shadow: ${theme.shadows[1]};
    border: 1px solid white;
    border-radius: ${commonStyling.roundedBorders.all};
    padding-left: ${theme.spacing(4)}px;
    padding-right: ${theme.spacing(4)}px;
    padding-bottom: ${theme.spacing(1)}px;
    padding-top: ${theme.spacing(1)}px;
    line-height: 1.4;
    animation-name: opacityOn;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    ${theme.breakpoints.down('md')} {
      right: -12px;
    }

    @keyframes opacityOn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  arrowIcon: css`
    position: absolute;
    right: 3px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    svg {
      font-size: 14px;
      color: ${theme.palette.secondary.main};
    }
  `,
  buttonNoBg: css`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      border: 0;
      outline: 0;
    }
  `,
  buttonClose: css`
    position: absolute;
    cursor: pointer;
    left: -15px;
    top: -15px;
    background: white;
    width: 32px;
    height: 32px;
    border-radius: 15px;
    border-width: 0px;
    color: ${theme.palette.primary.main};
    svg {
      height: 15px;
      width: 15px;
      color: ${theme.palette.primary.main};
      padding-top: 5px;
    }
  `,
  boxTop: css`
    color: ${theme.palette.secondary.main};
    width: 100%;
    display: flex;
    ${theme.breakpoints.up('sm')} {
      padding-bottom: ${theme.spacing(1)}px;
    }
  `,
  boxMiddle: css`
    color: white;
    text-align: left;
    p {
      font-size: ${fontSizes.subtitle2};
    }

    display: none;
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
  `,
  boxBottom: css`
    display: flex;
    justify-content: space-between;
    color: white;
    padding-top: ${theme.spacing(1)}px;
    min-width: 180px;
    p {
      font-size: ${fontSizes.body3};
    }
  `,
  separator: css`
    display: inline-block;
    background: white;
    width: 1px;
    margin-top: 4px;
    height: 12px;
  `,
  finishText: css`
    padding-left: ${theme.spacing(1)}px;
  `,
  arrow: css`
    position: absolute;
    right: 0;
    height: 100%;
  `,
}

export default classes
