import { css } from 'linaria'
import theme, { COLORS } from '../../../../styles/theme'
import fontSizes from '../../../../styles/fontSizes'
import commonStyling from '../../../../styles/commonStyling'

const classes = {
  packageWrapper: css`
    background: white;
    max-width: ${commonStyling.breakpoints.desktopSmall};
    padding: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(2)}px;
    border-radius: ${commonStyling.roundedBorders.all};
    box-shadow: ${commonStyling.boxShadowDark};
    align-items: center;
    &:hover{
      cursor: pointer;
      .bundleArrow{
        transform: translateX(10px);
      }
      .bundleDisplayName{
        text-decoration: underline;
      }
    }
  `,
  packageIcon: css`
    &.MuiGrid-root{
      margin-right: ${theme.spacing(3)}px;
    }
  `,
  packageDesciption: css`
    .packageShortDescription{
      max-width: 80%;
    }
    .MuiTypography-root{
      font-size: ${fontSizes.body2};
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.bodyTextPrimary};
      text-align: right;
    }
  `,
  packageDesktopArrow: css`
    &.bundleArrow{
      margin-left: ${theme.spacing(2)}px;
      display: flex;
      align-items: flex-end;
      transition: transform 0.3s linear;
    }
  `,
  body: css`
    margin: auto;
    outline: none;
  `,
}

export default classes
