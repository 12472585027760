import {
  css,
} from 'linaria'
import theme from '../../../../../styles/theme'

const classes = {
  submit: css`
    &.MuiGrid-item {
      margin: 0 auto;
    }
  `,
  loading: css`
  margin-left:${theme.spacing(3)}px;
  vertical-align: middle;
  `,
}

export default classes
