import React, { useEffect } from 'react'
import {
  Typography, Grid,
  Box,
} from '@material-ui/core'
import { ContainerWithCurve } from '../../../../../../styles/ContainerWithCurve'
import classes from './PersonalisedUnit.styles'
import { useOrderContext } from '../../../../OrderContext'
import { getOrderProducts } from '../../../../../../utils/getOrderProducts'
import ProductCopyWithText from './ProductCopyWithText'
import { ContainerWithPadding } from '../../../../../../styles/ContainerWithPadding'
import { HobsProduct } from '../../../../../../api/Packages'
import PhoneIcon from '../../SummarySection/icons/PhoneIcon'
import WifiIcon from '../../SummarySection/icons/WifiIcon'

export interface ProductInfoProps {
  icon: string;
  body: string;
  service_type: string;
}

export interface PersonalisedUnitProps {
  thanks_message: string;
  order_message: string;
  small_print_copy?: string;
  anchor_ref?: string;
  subtitle: string;
  icon_with_product_info: ProductInfoProps[];
  ots_icon: string;
  ots_title: string;
  ots_text: string;
}

const PersonalisedUnit = ({
  thanks_message,
  order_message,
  small_print_copy,
  anchor_ref,
  subtitle,
  icon_with_product_info,
  ots_icon,
  ots_title,
  ots_text,
}: PersonalisedUnitProps) => {
  const { options: {
    broadband, bundle, tv, voice, wier, vas, data, accountId, oneTouchSwitching, OTSOptOut, OTSPhone,
  } } = useOrderContext()

  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    tv,
    voice,
    wier,
    vas,
  })

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <section id={anchor_ref} className={classes.wrapper}>
        <ContainerWithCurve className={classes.curveContainer}>
          <ContainerWithPadding className={classes.wrapperInner}>
            <Typography align="center" variant="h1">{thanks_message} {data.firstName}</Typography>
            <Typography align="center" variant="h2">{order_message}<br/>{`Order No. ${accountId}`}</Typography>
            <Typography align="center" variant="h3">{small_print_copy}</Typography>
          </ContainerWithPadding>
        </ContainerWithCurve>
        <div className={classes.subTitle}>
          <div>
            <Typography variant="h4">{subtitle}</Typography>
          </div>
        </div>
        <Grid container justify="center" className={classes.orderInfo}>
          {orderProducts.map((product: HobsProduct, i: number) => {
            return (
              <ProductCopyWithText product={product} productsInfo={icon_with_product_info} key={i}/>
            )
          })}
        </Grid>
      </section>
      {(oneTouchSwitching && !OTSOptOut) &&
      <section className={classes.OTSwrapper}>
        <ContainerWithPadding className={classes.wrapperInner}>
          <Grid container direction="column" xs={12} className={classes.OTScontainer}>
            <Box mb={2}>
              <img src={ots_icon} alt=""/>
            </Box>
            <Box mb={2}>
              <Typography variant="body1" className={classes.OTStitle}>{ots_title}</Typography>
            </Box>
            <Grid container direction="column">
              <Typography variant="body1">
                {ots_text}
              </Typography>
              <Box pb={3} pt={2}>
                <Grid container>
                  <Box mr={1}>
                    <WifiIcon/>
                  </Box>
                  <Typography variant="body1">
                    <b>Broadband</b>
                  </Typography>
                </Grid>
                {OTSPhone &&
                <Box mt={2}>
                  <Grid container>
                    <Box mr={1}>
                      <PhoneIcon/>
                    </Box>
                    <Typography variant="body1">
                      <b>Voice</b>
                    </Typography>
                  </Grid>
                </Box>}
              </Box>
            </Grid>
          </Grid>
        </ContainerWithPadding>
      </section>}
    </>
  )
}

export default PersonalisedUnit

