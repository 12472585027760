import React from 'react'
import {
  Box, Grid,
  Typography,
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { PersonalDetailsFormData } from '../../PersonalDetailsForm/PersonalDetailsForm'

interface PersonalDetailsProps {
  data: Partial<PersonalDetailsFormData>;
  switchStep: ((e: string) => void);
}

const PersonalDetails = ({
  data, switchStep,
}: PersonalDetailsProps) => {
  const {
    firstName, lastName, email, phone,
  } = data

  return (
    <>
      <Box mb={2}>
        <Grid container justify="space-between">
          <Typography variant="h4">Your Details</Typography>
          <Create color="secondary" onClick={() => switchStep('checkout-details')}/>
        </Grid>
      </Box>
      <Typography variant="body1" color="primary">
        <div>Name: {firstName} {lastName}</div>
        <div>Email: {email}</div>
        <div>Mobile: {phone}</div>
      </Typography>
    </>
  )
}

export default PersonalDetails
