import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from '@material-ui/core'
import { css } from 'linaria'

import theme from '../../../styles/theme'

export interface TermsQuickLink {
  anchorRef: string;
  text: string;
}

export interface TermsQuickLinksProps {
  links: TermsQuickLink[];
}

const classes = {
  root: css`
    border: solid 1px ${theme.palette.primary.main};
  `,
}
export default function TermsQuickLinks({ links }: TermsQuickLinksProps) {
  return (
    <Card className={classes.root} elevation={2}>
      <CardHeader title={<Typography variant="h2">Quick Links</Typography>}/>
      <CardContent>
        {links.map((link: TermsQuickLink) => {
          const {
            anchorRef, text,
          } = link
          return (
            <Link key={text} href={`#${anchorRef}`} underline="always">
              <Typography variant="subtitle1">{text}</Typography>
            </Link>
          )
        })}
      </CardContent>
    </Card>
  )
}
