import {
  setISOWeek,
  setISOWeekYear,
  addWeeks,
  getISOWeekYear,
  getISOWeek,
  subWeeks,
  addMonths,
} from 'date-fns'
import { useState } from 'react'

const CALENDAR_MONTHS_WINDOW = 3

export default function useWeekWalker() {
  // Initialize date
  let d = new Date()

  // Calculate the minimum (start) week and year
  const minYear = getISOWeekYear(d)
  const minWeek = getISOWeek(d)

  // Calculate the maximum (end) week and year
  d = addMonths(d, CALENDAR_MONTHS_WINDOW)
  const maxYear = getISOWeekYear(d)
  const maxWeek = getISOWeek(d)

  // State variables
  const [
    year,
    setYear,
  ] = useState(minYear)
  const [
    week,
    setWeek,
  ] = useState(minWeek)
  const [
    weekCount,
    setWeekCount,
  ] = useState(1)

  const weekLimit = 5

  // Check if the current position is the start or end of the calendar
  const isCalendarStart = year === minYear && week === minWeek
  const isCalendarEnd =
    (year === maxYear && week === maxWeek) || // True calendar end
    weekCount >= weekLimit // User-defined week limit

  return {
    week,
    year,
    isCalendarStart,
    isCalendarEnd,
    weekCount,

    resetWeekCount() {
      setWeekCount(1)
    },

    prevWeek() {
      if (isCalendarStart) {
        return
      }

      let date = new Date()

      // Adjust date based on current week and year
      date = setISOWeek(date, week)
      date = setISOWeekYear(date, year)

      // Subtract one week
      date = subWeeks(date, 1)

      const newYear = getISOWeekYear(date)
      const newWeek = getISOWeek(date)

      // Update state
      setYear(newYear)
      setWeek(newWeek)

      // Update week count
      setWeekCount((prevCount) => Math.max(1, prevCount - 1))
    },

    nextWeek() {
      if (isCalendarEnd || weekCount >= weekLimit) {
        return
      }

      try {
        let date = new Date()
        date = setISOWeek(date, week)
        date = setISOWeekYear(date, year)
        date = addWeeks(date, 1)

        const newYear = getISOWeekYear(date)
        const newWeek = getISOWeek(date)

        // Check if we would exceed the calendar bounds
        if (newYear > maxYear || (newYear === maxYear && newWeek > maxWeek)) {
          return
        }

        setYear(newYear)
        setWeek(newWeek)
        setWeekCount(prev => prev + 1)
      } catch (err) {
        console.error('Error in nextWeek:', err)
      }
    },
  }
}
