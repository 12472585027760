import React from 'react'
import Slider from 'react-slick'
import {
  Box, Container, Grid, useMediaQuery,
} from '@material-ui/core'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import classes from './ValueProps.styles'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import theme from '../../styles/theme'

export interface ValuePropsProps {
  readonly anchor?: string;
  readonly background_color?: string;
  readonly value_props_items: Item[];
}

export interface Item {
  readonly prop_icon?: MediaImageProps;
  readonly prop_text: string;
}

export default function ValueProps({
  anchor,
  background_color,
  value_props_items,
}: ValuePropsProps) {
  const sliderSetings = {
    infinite: true,
    slidesToShow: 1,
    speed: 400,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const showValuePropsItems = (value_props_items: Item[]) => {
    return value_props_items?.map((item, index) => {
      return (
        <Box
          className={classes.valuePropItem}
          key={index}
        >
          {item.prop_icon?.src &&
          <MediaImage
            src={item.prop_icon.src}
            width={item.prop_icon.width || 24}
            height={item.prop_icon.height || 24}
            alt={item.prop_icon.alt}
            mobile_src={item.prop_icon.mobile_src}
            mobile_width={item.prop_icon.mobile_width}
            mobile_height={item.prop_icon.mobile_height}
            use_img_for_seo={item.prop_icon.use_img_for_seo}
          />}
          {item.prop_text &&
          <Wysiwyg body={item.prop_text} variant="body2"/>}
        </Box>
      )
    }
    )
  }

  return (
    <Grid id={anchor} style={{ backgroundColor: background_color }}>
      {isMobile ?
        <Slider {...sliderSetings}>
          {showValuePropsItems(value_props_items)}
        </Slider> :
        <Grid container>
          <Container>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            > {showValuePropsItems(value_props_items)}
            </Grid>
          </Container>
        </Grid>}
    </Grid>
  )
}
