import React from 'react'
import { css } from 'linaria'

const classes = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
}

export const ContainerPreserveMargin = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}
