import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  expansionPanel: css`
    width: 100%;
    position: relative;
    text-align: left;
    .MuiExpansionPanel-root:before {
      background-color: transparent;
    }
    .MuiExpansionPanel-rounded {
      border-radius: 10px !important;
    }
    .Mui-expanded {
      border-radius: 10px 10px 0 0 !important;
    }
  `,
  mainPanel: css`
    width: 100%;
    position: relative;
    background-color: ${theme.palette.secondary.light} !important;
    .MuiButtonBase-root {
      transition: transform ease-in-out .3s;
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
      transform: rotate(-180deg);
    }
    .MuiExpansionPanelSummary-root {
      padding: 0 ${theme.spacing(1)}px;
      min-height: 40px;
    }
    .MuiExpansionPanelSummary-root.Mui-expanded,
    .MuiExpansionPanelDetails-root {
      box-shadow: 0px 3px 3px #0000001A;
    }
    .MuiExpansionPanelSummary-expandIcon{
      .MuiSvgIcon-root{
        color: ${COLORS.secondaryContrast};
      }
    }
    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content.Mui-expanded,
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 40px;
      margin: 0 !important;
    }
    .MuiBox-root {
      display: flex;
      align-items: center;
      .MuiTypography-body1 {
        padding-left: ${theme.spacing(1)}px;
        font-weight: ${theme.typography.fontWeightBold!};
        color: ${COLORS.secondaryContrast};
      }
      .MuiSvgIcon-root{
        color: ${COLORS.secondaryContrast};
      }
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
      color: ${COLORS.primary};
    }
    .MuiExpansionPanelDetails-root {
      padding-top: 0;
      position: absolute;
      background-color: ${theme.palette.secondary.light};
      border-radius: 0 0 10px 10px;
      z-index: 10;
    }
  `,
}

export default classes
