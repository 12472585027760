import { css } from 'linaria'
import theme, {
  COLORS, ROUNDED,
} from '../../styles/theme'

const classes = {
  container: css`
    gap: ${theme.spacing(2)}px;
  `,
  greyContainer: css`
    background-color: ${COLORS.gray1};
    border-radius: ${ROUNDED.medium}px;
    ${theme.breakpoints.up('md')} {
      width: 50%;
    }
  `,
  bold: css`
    &.MuiTypography-root,.MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  underline: css`
    text-decoration: underline;
  `,
  backLink: css`
    display: flex;
    svg {
      position: relative;
      top: 2px;
      margin-right: ${theme.spacing(1)}px;
    }
  `,
}

export default classes
