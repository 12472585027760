import React from 'react'
import {
  Box,
  Grid, Hidden, Typography,
} from '@material-ui/core'
import Wysiwyg from '../../../../../Wysiwyg/Wysiwyg'
import classes from './PersonalisedUnit.styles'
import { ProductInfoProps } from './PersonalisedUnit'
import { getFormattedPrice } from '../../../../../../utils/getProductDetails'
import { HobsProduct } from '../../../../../../api/Packages'

const ProductCopyWithText = ({
  product, productsInfo,
}: {
  product: HobsProduct; productsInfo: ProductInfoProps[];
}) => {
  const productServiceType = product?.service_type?.toLowerCase()

  return (
    <Grid item xs={12} container className={classes.productInfoGrid}>
      {productsInfo.map(productInfo => {
        if (productInfo.service_type === productServiceType) {
          return (
            <>
              <Hidden mdUp>
                <Grid item xs={12} container>
                  <Grid item xs={1} container justify="center" alignItems="flex-start">
                    <img src={productInfo.icon} alt=""/>
                  </Grid>
                  <Grid item xs={11}>
                    <Box mx={2} mb={3}>
                      <Typography variant="h5">{product.display_name}</Typography>
                      <Typography variant="h5">{getFormattedPrice(product)}/month</Typography>
                      {productInfo.body && <Wysiwyg variant="body1" body={productInfo.body}/>}
                    </Box>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <img src={productInfo.icon} alt=""/>
                <Typography variant="h5">{product.display_name}</Typography>
                <Typography variant="h5">{getFormattedPrice(product)}/month</Typography>
                {productInfo.body && <Wysiwyg variant="body1" body={productInfo.body}/>}
              </Hidden>
            </>
          )
        }

        return null
      })}
    </Grid>
  )
}

export default ProductCopyWithText
