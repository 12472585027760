import { css } from 'linaria'
import commonStyling from '../../../styles/commonStyling'
import fontSizes from '../../../styles/fontSizes'
import theme, {
  COLORS, fontWeights,
} from '../../../styles/theme'

const classes = {
  secureIcon: css`
    min-height: 38px;
    margin-bottom: 3px;
  `,
  headerTop: css`
    box-shadow: 0 -1px 0 ${COLORS.gray2} inset;
  `,
  container: css`
    flex-grow: 1;
    position: relative;
    a {
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      color: ${theme.palette.primary.main};
    }
    .MuiToolbar-gutters {
      padding: 0 ${theme.spacing(4)}px;
    }
    .contactUsInner{
      padding: 0;
    }
    .MuiGrid-container{
      flex-wrap:nowrap;
    }
  `,
  navTabs: css`
    position: relative;
    left: -${theme.spacing(2)}px;
    z-index: 10;
    .MuiTabs-flexContainer {
      justify-content: flex-start;
      .MuiTab-root {
        padding: ${theme.spacing(2)}px;
        position: relative;
        &:before {
          background-color: ${theme.palette.primary.main};
          bottom: 0;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          transition: width 0.3s ease-in-out;
          width: 0%;
        }
        &:hover {
          background-color: ${theme.palette.warning.light};
          box-shadow: 0 -1px 0 ${COLORS.gray2} inset;
        }
        &.Mui-selected {
          color: ${theme.palette.primary.main};
          font-weight: ${theme.typography.fontWeightBold!};
        }
        &.Mui-selected, &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
  `,
  activeItem: css`
    p.MuiTypography-root {
      font-weight: ${fontWeights.semibold};
      color: ${theme.palette.primary.main};
      &:hover {
        color: ${theme.palette.primary.main};
      }
    }
  `,
  menuItem: css`
    .MuiTypography-root {
      display: flex;
      color: ${COLORS.primary};
      &:hover {
        color: ${theme.palette.primary.main};
        font-weight: ${fontWeights.semibold};
      }
    }
  `,
  line: css`
    border-right: solid 1px ${COLORS.primary};
    min-height: ${fontSizes.body2};
    margin: ${theme.spacing(0, 1)};
  `,
  sharedItems: css`
    button, a {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.primary};
    }
    img {
      margin-right: ${theme.spacing(1)}px;
    }
  `,
  menuButton: css`
    margin-right: ${theme.spacing(2)}px;
  `,
  title: css`
    flex-grow: 1;
  `,
  list: css`
    &.MuiGrid-container {
      list-style: none;
      margin-right: ${theme.spacing(1)}px;
      padding-left:${theme.spacing(1)}px;
      max-width:70%;
    }
    ${theme.breakpoints.up('lg')} {
      .MuiTypography-root {
        font-size: ${fontSizes.body1};
      }
    }
    li:last-child {
      padding-right: 0;
    }
  `,
  basketHeading: css`
    min-width: 100%;
    display: flex;
    align-items: center;
    background-color: ${COLORS.primary};
    box-shadow: ${commonStyling.boxShadowLight};
    color: ${COLORS.white};
    min-height: 64px;
    .MuiSvgIcon-root {
      width: 35px;
      height: 25px;
      padding-right: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(1)}px;
    }
    .MuiTypography-root {
      span {
        padding: 0 6px;
      }
    }
  `,
  basketContainer: css`
    .MuiPaper-root {
      max-width: 375px;
      border-top-left-radius: ${commonStyling.roundedBorders.all};
    }
    [class*=orderSummary] {
      border: none;
    }
  `,
  miniBasketContent: css`
    flex-direction: column;
    justify-content: space-between;
    max-height: 70%;
  `,
  miniBasketContentError: css`
    max-height: 60%;
  `,
  bottomContainer: css`
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    bottom: 0;
  `,
  closeButton: css`
    .MuiButton-label {
      margin-bottom: 0px !important;
    }

    .MuiSvgIcon-root {
      fill: ${COLORS.secondary} !important;
    }
  `,
  orderButton: css`
    padding: ${theme.spacing(2)}px;
    flex: 1;
  `,
  productsNumber: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 40px;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: ${COLORS.tertiary};
    color: ${COLORS.primary};
    font-size: ${fontSizes.smallText};
  `,
  inline: css`
    display: inline-block;
  `,
  buttonsContainer: css`
    display:flex;
    flex-wrap:nowrap;
    max-width:30%;
    padding-right: 0 !important;
    justify-content: flex-end;
    .MuiButton-root {
      color: white;
    }
    .MuiBox-root {
      margin-right: 0;
    }
    .MuiSvgIcon-root {
      color: ${COLORS.secondary} !important;
      height: 30px;
      width: 30px;
    }`,
  mainHeaderItem: css`
    position: relative;
  `,
  submenu: css`
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 100%;
    max-width: fit-content;
    background-color: white;
    border: 1px solid ${COLORS.secondary};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(3, 0)};
    white-space: nowrap;
    z-index: 10;
    li {
      list-style-type:none;
      a {
        text-decoration: none;
        color: ${COLORS.bodyTextPrimary};
        .MuiTypography-root {
          font-weight: ${fontWeights.book};
          text-align: left;
          padding: ${theme.spacing(1, 4)};
        }
      }
      &:hover a .MuiTypography-root {
        background: ${COLORS.gray1};
        color: ${COLORS.primary};
        font-weight: ${fontWeights.semibold};
      }
    }
    .MuiTypography-root:hover {
      color: ${COLORS.bodyTextPrimary};
    }

    &:after, &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
    }
    &:after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      top: -10px;
    }
    &:before {
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid ${COLORS.secondary};
      top: -11px;
    }
  `,
  trustpilotWrapper: css`
    display: flex;
    align-items: center;
    div {
      position: relative;
      right: 0;
      background-color: ${theme.palette.warning.light};
    }
    span {
      margin: ${theme.spacing(0, 1)};
      border-right: solid 2px ${theme.palette.primary.main};
      height: 21px;
    }
  `,
  errorMessage: css`
    background-color: white !important;
    padding-top: ${theme.spacing(1)}px !important;
  `,
  arrowIcon: css`
    height: 22px;
    transition: .25s ease-in-out transform;
  `,
  arrowExpandMoreIcon: css`
    transform: rotate(180deg);
  `,
  arrowExpandLessIcon: css`
    transform: rotate(0);
  `,
}

export default classes
