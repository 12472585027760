import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  section: css`
    display: flex;
    justify-content: center;
  `,
  wrapper: css`
    width: 100%;
    max-width: ${commonStyling.breakpoints.desktopLarge};
    padding: 0;
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(0, 2)};
    }
    .progressBarTabPanel{
      padding: ${theme.spacing(2, 0, 3)};
    }
    .MuiTabs-indicator{
      background: ${theme.palette.primary.main};
    }
  `,
  wrapperInner: css`
    display: flex;
    align-items: center;
  `,
  titleWrapper: css`
    text-align: center;
  `,
  tabsWrapper: css`
    .MuiTabs-root{
      position: relative;
      margin: ${theme.spacing(3, 0, 0)}
      ${theme.breakpoints.up('sm')} {
        margin: ${theme.spacing(4, 0, 3)};
      }
      &:after{
        content: " ";
        background: ${COLORS.bodyTextPrimary};
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        opacity: 16%;
      }
    }
    .MuiTabs-flexContainer{
      justify-content: space-around;
      .Mui-selected{
        p, .MuiTypography-subtitle-2{
          color: ${theme.palette.primary.main}
        }
      }
      button{
        min-width: auto;
        p{
          color: ${COLORS.bodyTextPrimary};
          font-size: ${fontSizes.body2};
          font-weight: ${theme.typography.fontWeightRegular!};
        }
        .MuiTypography-subtitle2{
          color: ${COLORS.bodyTextPrimary};
          font-weight: ${theme.typography.fontWeightRegular!};
        }
      }
    }
  `,
  title: css`
    &.MuiTypography-root{
      color: ${theme.palette.primary.main};
      text-align: center;
      padding-top: ${theme.spacing(1)}px;
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
      }
    }
  `,
  subtitle: css`
    &.MuiTypography-root{
      color: ${theme.palette.primary.main};
      margin-top: ${theme.spacing(4)}px;
      font-weight: ${theme.typography.fontWeightRegular!};
    }
  `,
}

export default classes
