import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  root: css`
    margin-right: 0;
    position: relative;
    color: ${theme.palette.secondary.main};
    align-items: center;
    bottom: 0;
    right: 0;
    display: flex;
    .MuiTypography-root {
      margin-right: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      right: 14px;
    }
  `,
  fab: css`
    .MuiSvgIcon-root {
      font-size: 35px;
      height: 35px;
      width: 35px;
    }
    &.MuiFab-root {
      background-color: ${theme.palette.secondary.main};
      &:hover {
        background-color: ${theme.palette.secondary.dark};
      }
    }
  `,
  icon: css`
    color: white;
  `,
}

export default classes
