import { AxiosInstance } from 'axios'
import { ProductProps } from '../components/Products/Product'
import { getHybridProducts } from '../utils'
import logger from '../utils/logger'
import createCRMApiClient from './CRMApi'
import { HobsProduct } from './Packages'
import { ChannelType } from '../utils/commonEnums'
import moment from 'moment'

export enum HobsServiceType {
  BROADBAND = 'broadband',
  VOICE = 'voice',
  TV = 'tv',
  WIER = 'wier',
  BUNDLE = 'bundle',
  VAS = 'vas'
}

export default function HobsProducts(client: AxiosInstance) {
  async function search() {
    try {
      const dateInteraction = new Date()
      const params = {
        interactionID: 'WEB-' + moment.utc(dateInteraction)
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          .replace(/[^\d]/g, ''),
        interactionDate: new Date()
          .toISOString(),
        sourceApplicationID: 'devtest',
        serviceName: 'getProducts',
        triggeredBy: ChannelType.DIGITAL,
        uuid: 'CFLHOBS',
        password: 'sadmpwd',
        // For products it doesn't matter the channel
        channel: ChannelType.DIGITAL,
        opID: 'CFL',
        buID: 'DEFAULT',
        pcFilter: {
          offerPolicy: {
            offerability: [{
              offerabilityCriteria: {
                attribute: [
                  {
                    attributeName: 'operatorID',
                    attributeValue: 'CFL',
                  },
                  {
                    attributeName: 'buID',
                    attributeValue: 'DEFAULT',
                  },
                  {
                    attributeName: 'serviceCatg',
                    attributeValue: 'BROADBAND',
                  },
                  {
                    attributeName: 'customerSegment',
                    attributeValue: 'RESIDENTIAL',
                  },
                  {
                    attributeName: 'customerCategory',
                    attributeValue: 'RES',
                  },
                  {
                    attributeName: 'channelID',
                    attributeValue: ChannelType.DIGITAL,
                  },
                  {
                    attributeName: 'dealerCode',
                    attributeValue: 'none',
                  },
                ],
              },
            }],
          },
        },
      }
      const { data } = await client.post<HobsProductResponse>('product/v1/0.0/getproducts', params)
      return data
    } catch (err) {
      logger.error('failed to get products', err)
      return []
    }
  }

  return {
    search,
  }
}

export interface HobsProductResponse {
  interactionID: string;
  products: HobsProduct[];
}

export const getProducts = async (cms?: ProductProps[]) => {
  const client = createCRMApiClient()

  try {
    const data = await client.hobsProducts.search() as HobsProductResponse
    const products = data.products as ProductProps[]

    if (cms) {
      if (Array.isArray(cms) === false) {
        const cmsArray = [cms].flat()
        return getHybridProducts(products, cmsArray)
      }

      return getHybridProducts(products, cms)
    }

    return products
  } catch (err) {
    return {
      message: err.message,
      error: err,
    }
  }
}
