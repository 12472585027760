import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'
import { COLORS } from '../../../../../../styles/theme'

export default function WIERIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill={COLORS.primary} d="M0,7.4l1.7,1.7C4.4,6.3,8.1,4.8,12,4.8s7.6,1.5,10.3,4.3L24,7.4c-3.2-3.2-7.5-5-12-5S3.2,4.2,0,7.4z"/>
      <path fill={COLORS.primary} d="M2.5,9.9l1.7,1.7C6.3,9.5,9,8.3,12,8.3s5.7,1.1,7.8,3.2l1.7-1.7C19,7.3,15.6,5.9,12,5.9S5,7.3,2.5,9.9L2.5,9.9z"/>
      <path fill={COLORS.primary} d="M5.1,12.4l1.7,1.7C8.2,12.7,10,12,12,12s3.8,0.8,5.2,2.2l1.7-1.7c-1.9-1.9-4.3-2.9-6.9-2.9S6.9,10.6,5.1,12.4z"/><path fill={COLORS.secondary} d="M12,14.4l-3.5,3.5c-0.1,0.1-0.1,0.4,0,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1c-0.1,0.1-0.2,0.1-0.2,0.1v2.4v0.7h6.5v-0.4h0v-2.7c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.4,0-0.5L12,14.4L12,14.4z"/>
    </SvgIcon>
  )
}
