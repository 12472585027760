import React, { ReactNode } from 'react'
import {
  Box,
} from '@material-ui/core'
import Wrapper from './Wrapper'

interface WrapperWithSectionProps {
  children?: ReactNode;
  id?: string;
  pt?: number | object;
  pb?: number | object;
  className?: string;
}

const WrapperWithSection = ({
  children,
  id,
  pt = {
    xs: 5,
    md: 8,
  },
  pb = {
    xs: 5,
    md: 8,
  },
  className,
}: WrapperWithSectionProps) => {
  return (
    <Box component="section" pt={pt} pb={pb} className={className}>
      <Wrapper
        id={id}
      >
        {children}
      </Wrapper>
    </Box>
  )
}

export default WrapperWithSection
