import React from 'react'
import {
  Link,
  Typography,
} from '@material-ui/core'
import { BlogPost } from '../../../utils/blog'
import Tile from '../Tile/Tile'
import theme from '../../../styles/theme'
import { css } from 'linaria'
const classes = {
  link: css`
    display: block;
    font-size: 1.125rem;
    font-weight: ${theme.typography.fontWeightBold!};
    position: relative;
    overflow: hidden;
    order:4;
  `,
}

export default function BlogTile({
  featured_image, slug, summary, title, color, published, author,
}: BlogPost) {
  return (
    <Tile featured_image={featured_image} title={title} date={published} author={author}>
      <Typography style={theme.typography.h4} color={color ? color : 'primary'} component="h4" variant="h1">
        <Link href={`/community-connect/blog/${slug}`}>{title}</Link>
      </Typography>
      <Typography variant="body1">{summary}</Typography>
      <Link underline="always" color="secondary" className={classes.link} href={`/community-connect/blog/${slug}`}>Read more</Link>
    </Tile>
  )
}
