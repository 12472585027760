import {
  useState, Dispatch, SetStateAction,
} from 'react'
import { MenuCategoryTab } from './Header'
import { useRouter } from 'next/router'

export default function useMenu(): {
  menuIndex: number | null;
  isHamburgerMenuOpen: boolean;
  setMenuIndex: Dispatch<SetStateAction<null | number>>;
  toggleHamburgerMenu(): void;
  clear(): void;
  findRouterSlug(): string;
  findActiveCategory(categories: MenuCategoryTab[], currentCategory: string): number;
  } {
  const router = useRouter()
  const [
    isHamburgerMenuOpen,
    setIsHamburgerMenuOpen,
  ] = useState(false)
  const [
    menuIndex,
    setMenuIndex,
  ] = useState(null)

  function toggleHamburgerMenu() {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen)
  }

  function findRouterSlug() {
    let currentCategory = router.asPath.split('/')[1]
    if (currentCategory.indexOf('#') > 0) {
      currentCategory = currentCategory.split('#')[0]
    }

    return currentCategory
  }

  function clear() {
    setIsHamburgerMenuOpen(false)
    setMenuIndex(null)
  }

  function findActiveCategory(categories: MenuCategoryTab[], currentCategory: string) {
    const activeCategory = categories.findIndex((category => currentCategory === category.title.toLowerCase()))
    const activeOrDefaultCategory = activeCategory < 0 ? 0 : activeCategory
    return activeOrDefaultCategory
  }

  return {
    menuIndex,
    isHamburgerMenuOpen,
    toggleHamburgerMenu,
    setMenuIndex,
    clear,
    findActiveCategory,
    findRouterSlug,
  }
}
