import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  banner: css`
      p, h2, h3 {
        margin: 0;
      }
      ${theme.breakpoints.down('sm')} {
        .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h5 {
          padding-right: ${theme.spacing(9)}px;
          padding-bottom: ${theme.spacing(1)}px;
        }
      }
    `,
}
export default classes
