import React, {
  useEffect, useState,
} from 'react'
import { css } from 'linaria'
import theme from '../../styles/theme'
import Head from 'next/head'
import { ContainerWithCurve } from '../../styles/ContainerWithCurve'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import {
  Grid,
} from '@material-ui/core'
import ModalBox from '../ModalBox/ModalBox'

export interface EmbedCodeProps {
  readonly header_script_url?: string;
  readonly stylesheet_url?: string;
  readonly curved_section?: boolean;
  readonly html?: string;
  readonly js?: string;
  readonly modal?: boolean;
  readonly anchor_id?: string;
}
const classes = {
  container: css`
    min-height: max-content;
  `,
  curvedContainer: css`
    padding: ${theme.spacing(8)}px 0;
  `,
  wyswig: css`
  flex-grow: 1;
`,
}

export default function EmbedCode({
  header_script_url,
  stylesheet_url,
  curved_section,
  html,
  js,
  modal,
  anchor_id,
}: EmbedCodeProps) {
  const runScript = () => {
    eval(js!) // eslint-disable-line no-eval
  }

  const router = useRouter()

  const [
    open,
    setOpen,
  ] = useState(false)

  const handleClose = () => {
    router.back()
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      setOpen(false)
    }

    if (anchor_id && router.asPath.includes(anchor_id)) {
      setOpen(true)
    }

    let script: HTMLScriptElement

    if (header_script_url) {
      script = document.createElement('script')
      document.head.append(script)
      script.src = header_script_url!
      script.async = true
      if (js) {
        script.addEventListener('load', () => runScript())
      }
    }

    return () => {
      setOpen(false)
      if (header_script_url) {
        script.remove()
      }
    }
  }, [router.asPath])

  return (
    <>
      <Head>
        {stylesheet_url && (
          <link href={stylesheet_url} rel="stylesheet" type="text/css"/>
        )}
      </Head>
      {html && !curved_section && !modal && (
        <section id={anchor_id}>
          { parse(html) }
        </section>
      )}
      {html && curved_section && !modal && (
        <ContainerWithCurve id={anchor_id}>
          <ContainerWithPadding>
            <Grid container className={classes.curvedContainer}>
              { parse(html) }
            </Grid>
          </ContainerWithPadding>
        </ContainerWithCurve>
      )}
      {html && modal && open && (
        <ModalBox
          visible={open}
          sync={handleClose}
          width="700px"
          maxWidth="750px"
        >
          { parse(html) }
        </ModalBox>
      )}
    </>
  )
}
