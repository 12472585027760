import { css } from 'linaria'

const classes = {
  modalScreen: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    div {
      outline: none;
    }
  `,
  close: css`
    cursor: pointer;
    width: 32px !important;
    height: 32px !important;
  `,
}

export default classes
