/* eslint complexity: ["error", 100] */
import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import {
  Typography,
  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { cx } from 'linaria'
import createCRMApiClient from '../../api/CRMApi'
import PersonalDetails from '../PersonalDetails/PersonalDetails'
import classes from './RegisterInterestForm.styles'
import marketingEvents from '../../utils/marketing/marketingEvents'
import logger from '../../utils/logger'
import { triggerValuableLeadEvent } from '../../utils/triggerValuableLeadEvent'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import {
  THREE_GIGA_IDS, useOrderContext,
} from '../NewOrderFlow/OrderContext'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import IconsWithText, { IconsWithTextProps } from '../IconsWithText/IconsWithText'
import moment from 'moment'
import { getFourYearsForward } from '../../utils/getFourYearsForward'
import RegisterInterestCTA, { RegisterInterestCTAProps } from '../RegisterInterestCTA/RegisterInterestCTA'
import { formatInstallationAddress } from '../NewOrderFlow/steps/CheckoutStep/InstallationSection/InstallationSection'
import { SegmentType } from '../../utils/commonEnums'
export interface RegisterInterestFormProps {
  success_slug: string;
  error_slug: string;
  overlap?: boolean;
  background_colour?: boolean;
  consent_text?: string;
  icons_with_text?: IconsWithTextProps;
  flag_title?: string;
  flag_subtitle?: string;
  show_confirm_email_field?: boolean;
  campaign_id?: string;
  providers: string;
  button_text: string;
  register_interest_cta: RegisterInterestCTAProps;
}

export interface FormValues {
  address: string;
  sprn: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  campaignId: string;
  month: string;
  year: string;
  provider: string;
}

export default function RegisterInterestForm({
  success_slug,
  error_slug,
  overlap,
  background_colour,
  consent_text,
  icons_with_text,
  flag_title,
  flag_subtitle,
  show_confirm_email_field,
  campaign_id,
  providers,
  button_text,
  register_interest_cta,
}: RegisterInterestFormProps) {
  const {
    options,
  } = useOrderContext()

  const {
    broadband,
  } = options

  const [
    currentMonth,
    setCurrentMonth,
  ] = useState('')

  const [
    currentYear,
    setCurrentYear,
  ] = useState('')

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false)

  const [
    monthError,
    setMonthError,
  ] = useState<boolean>(false)

  const [
    yearError,
    setYearError,
  ] = useState<boolean>(false)

  const [
    providerError,
    setProviderError,
  ] = useState<boolean>(false)

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { premise } = useContext(PremiseContext)

  const {
    handleSubmit, control, watch, register,
  } = methods

  const watchMonth = watch('month')
  const watchYear = watch('year')
  const watchProvider = watch('provider')

  useEffect(() => {
    setMonthError(false)
  }, [
    watchMonth,
    currentMonth,
  ])

  useEffect(() => {
    setYearError(false)
  }, [
    watchYear,
    currentYear,
  ])

  useEffect(() => {
    setProviderError(false)
  }, [watchProvider])

  const months = moment.months()
  const providersArray = providers ? providers.split('|') : []

  const getMonthFromString = (mon: string) => {
    const d = Date.parse(mon + '1, 2012')
    if (!isNaN(d)) {
      return new Date(d)
        .getMonth() + 1
    }

    return -1
  }

  const onSubmit = handleSubmit(async ({
    firstName, lastName, email,
    phone, month, year, provider,
  }) => {
    setLoading(true)

    if (!premise) {
      logger.error('premise not found', 'premise not found')
      return location.assign(error_slug)
    }

    if (provider === 'Please select your current provider') {
      setProviderError(true)
      setLoading(false)
      return
    }

    if (currentMonth === 'Month') {
      setMonthError(true)
      setLoading(false)
      return
    }

    if (currentYear === 'Year') {
      setYearError(true)
      setLoading(false)
      return
    }

    const client = createCRMApiClient()

    let contractEndDate = ''

    if (currentYear && currentYear !== 'Year') {
      let dateString = year

      if (currentMonth && currentMonth !== 'Month') {
        const monthNumber = getMonthFromString(month)
        const monthNumberString = ('0' + Number(monthNumber)).slice(-2)
        dateString = `${year}-${monthNumberString}-01T00:00:00.000Z`
      }

      contractEndDate = dateString.toString()
    }

    const addressSplited = formatInstallationAddress(premise.address)
      .split(',')

    const params = {
      address: {
        sprn: String(premise.sprn),
        pinCode: addressSplited.slice(-1)[0].trim(),
        addressLine1: addressSplited[0].trim(),
        addressLine2: addressSplited.length === 4 ? addressSplited[1].trim() : '',
        addressLine3: addressSplited.length === 5 ? addressSplited[2].trim() : '',
        town: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        city: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        stateOrProvince: premise.borough,
        totalAddress: premise.address,
      },
      firstName,
      lastName,
      contractEndDate,
      provider,
      phone,
      email,
    }

    const result = await client.hobsLeads.registerInterest(params)

    if (result.success === false || 'error' in result || result.code === 'SUPPLIER_FAULT') {
      logger.error(result.message, result.error)
      return location.assign(error_slug)
    }

    triggerValuableLeadEvent(premise)
    broadband && THREE_GIGA_IDS.includes(broadband.productOfferingId!) && marketingEvents.threeGigaRegisterInterestFormSubmitted()

    const RegisterInterestData = {
      premise: premise!,
      firstName: firstName?.toLocaleLowerCase(),
      lastName: lastName?.toLocaleLowerCase(),
      emailAddress: email?.toLocaleLowerCase(),
      mobileNumber: phone,
      segment: SegmentType.RESIDENTIAL,
    }

    marketingEvents.registerInterestFormSubmit({
      eventName: 'register_interest_submitted',
      options,
      ...RegisterInterestData,
    })

    setLoading(false)
    return location.assign(success_slug)
  })

  const contractFormBackground = background_colour ? classes.contractFormBackground : ''
  const iconsWithTextEmpty = icons_with_text && Object.keys(icons_with_text!).length === 0

  useEffect(() => {
    marketingEvents.registerInterestPageLoad(premise!, SegmentType.RESIDENTIAL, options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SectionWithMargin>
      <ContainerWithPadding>
        <Grid container>
          {register_interest_cta &&
          <Grid item md={6}>
            <RegisterInterestCTA
              {...register_interest_cta}
            />
          </Grid>}
          <Grid item md={6}>
            <FormContext {...methods}>
              <div className={classes.contractForm}>
                <Grid container>
                  {!iconsWithTextEmpty &&
                  <Grid item md={6}>
                    <div className={classes.iconsWithText}>
                      <IconsWithText {...icons_with_text!} items_per_row={2}/>
                    </div>
                  </Grid>}
                  <Grid item md={iconsWithTextEmpty ? 12 : 6}>
                    <Box pt={overlap ? 0 : 2} pb={overlap ? 0 : 2} className={contractFormBackground}>
                      {flag_title &&
                      <div className={classes.flagTag}>
                        <Typography variant="h6">
                          {flag_title}
                          {flag_subtitle &&
                          <>
                            <br/>
                            <span>{flag_subtitle}</span>
                          </>}
                        </Typography>
                      </div>}
                      <form onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                          <PersonalDetails
                            showConsent={false}
                            showLandlineField={false}
                            showConfirmEmailField={show_confirm_email_field ?? false}
                            campaignId={campaign_id}
                            consentText={consent_text}
                            gridClassName={classes.gridNoMargin}
                            savedDataClassName={classes.savedData}
                            consentInfoClassName={classes.consentClasses}
                            hideAddress
                          >
                            <Grid direction="column" container>
                              <Typography className={cx(classes.inputLabel, providerError && classes.errorLabel)}>Who is your existing service provider?*</Typography>
                              <Grid direction="row" container spacing={2} className={classes.selectContainer}>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="provider"
                                  defaultValue="Please select your current provider"
                                  renderValue={(selected: string) => {
                                    if (!selected) {
                                      return 'Provider'
                                    }

                                    return selected
                                  }}
                                  as={
                                    <Select
                                      className={cx(classes.selectClass, (watchProvider && watchProvider !== 'Provider') && classes.activeSelect, providerError && classes.errorInput)}
                                      IconComponent={KeyboardArrowDownIcon}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        },
                                        transformOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      disableUnderline
                                      inputProps={{
                                        classes: {
                                          icon: cx(classes.icon, (watchProvider && watchProvider !== 'Provider') && classes.activeIcon),
                                        },
                                      }}
                                    >
                                      {providersArray.map((provider) => (
                                        <MenuItem key={provider} value={provider} className={classes.option}>{provider}</MenuItem>
                                      ))}
                                    </Select>
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid direction="column" container>
                              <Typography className={cx(classes.inputLabel, (monthError || yearError) && classes.errorLabel)}>When do you think your contract ends?*</Typography>
                              <Grid direction="row" container spacing={2} className={classes.selectContainer}>
                                <Grid item xs={6}>
                                  <Controller
                                    control={control}
                                    name="month"
                                    rules={{ required: true }}
                                    defaultValue="Month"
                                    renderValue={(selected: string) => {
                                      if (!selected) {
                                        return 'Month'
                                      }

                                      setCurrentMonth(selected)
                                      return currentMonth
                                    }}
                                    as={
                                      <Select
                                        className={cx(classes.selectClass, (watchMonth && watchMonth !== 'Month') && classes.activeSelect, monthError && classes.errorInput)}
                                        IconComponent={KeyboardArrowDownIcon}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        disableUnderline
                                        inputProps={{
                                          classes: {
                                            icon: cx(classes.icon, (watchMonth && watchMonth !== 'Month') && classes.activeIcon),
                                          },
                                        }}
                                      >
                                        {months.map((month) => (
                                          <MenuItem key={month} value={month} className={classes.option}>{month}</MenuItem>
                                        ))}
                                      </Select>
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Controller
                                    control={control}
                                    name="year"
                                    rules={{ required: true }}
                                    defaultValue="Year"
                                    renderValue={(selected: string) => {
                                      if (!selected) {
                                        return 'Year'
                                      }

                                      setCurrentYear(selected)
                                      return currentYear
                                    }}
                                    as={
                                      <Select
                                        className={cx(classes.selectClass, (watchYear && watchYear !== 'Year') && classes.activeSelect, yearError && classes.errorInput)}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          getContentAnchorEl: null,
                                        }}

                                        IconComponent={KeyboardArrowDownIcon}
                                        disableUnderline
                                        inputProps={{
                                          classes: {
                                            icon: cx(classes.icon, (watchYear && watchYear !== 'Year') && classes.activeIcon),
                                          },
                                        }}
                                      >
                                        {getFourYearsForward()
                                          .map((year) => (
                                            <MenuItem key={year} {...register()} value={year} className={classes.option}>
                                              {year}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </PersonalDetails>
                          <Grid container className={classes.submitClasses}>
                            <Grid item lg={6} xs={12}>
                              <Button type="submit" fullWidth variant="contained" color="primary" disableElevation>
                                <Typography noWrap>{button_text}
                                  {(loading && !providerError) && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </FormContext>
          </Grid>
        </Grid>
      </ContainerWithPadding>
    </SectionWithMargin>
  )
}
