import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'

export default function AttentionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4044 50.0955C17.9319 53.623 22.4284 56.0285 27.3244 56.9975C32.2204 57.9665 37.295 57.4735 41.902 55.561C46.509 53.6485 50.453 50.4185 53.224 46.262C55.995 42.1055 57.474 37.235 57.474 32.2455C57.474 28.9305 56.8195 25.641 55.553 22.581C54.2865 19.521 52.425 16.733 50.079 14.387C47.733 12.041 44.9449 10.188 41.8764 8.92149C38.8164 7.64649 35.5354 7.00049 32.2119 7.00049C27.2224 7.00049 22.3434 8.48799 18.1954 11.2675C14.0474 14.047 10.8174 17.991 8.91345 22.598C7.00095 27.2135 6.51645 32.288 7.48545 37.1755C8.46295 42.0715 10.8684 46.568 14.3959 50.0955H14.4044ZM12.4409 28.3015C13.2229 24.383 15.1439 20.7875 17.9659 17.9655C20.7879 15.1435 24.3834 13.2225 28.3019 12.4405C32.2204 11.6585 36.2749 12.058 39.9639 13.588C43.653 15.118 46.8065 17.702 49.025 21.0255C51.2435 24.349 52.425 28.2505 52.425 32.2455C52.425 34.8975 51.9065 37.524 50.8865 39.972C49.8665 42.42 48.3875 44.647 46.509 46.5255C44.6305 48.404 42.4035 49.883 39.9555 50.903C37.5075 51.9145 34.8809 52.4415 32.2289 52.4415C28.2339 52.4415 24.3324 51.26 21.0089 49.0415C17.6854 46.823 15.1014 43.6695 13.5714 39.9805C12.0414 36.2915 11.6419 32.2285 12.4239 28.3185L12.4409 28.3015Z" fill="#2E0F4F"/>
        <path d="M20.2106 35.7557C22.1146 37.6597 24.0271 39.5722 25.9311 41.4762C26.4752 42.0117 27.2486 42.3007 28.0136 42.2582C28.7106 42.2242 29.3821 41.9182 29.8666 41.4082C34.6861 36.5887 39.5056 31.7692 44.3336 26.9412L40.4407 23.0142C36.2502 27.2047 32.0596 31.3952 27.8691 35.5857C26.6196 34.3277 25.3617 33.0697 24.1037 31.8202C23.4407 31.1572 22.4206 30.8767 21.5026 31.1147C20.8056 31.2847 20.1851 31.7352 19.8111 32.3472C19.4711 32.8827 19.3351 33.5457 19.4201 34.1662C19.4966 34.7612 19.7771 35.3307 20.2021 35.7557H20.2106Z" fill="#17AB33"/>
      </svg>
    </SvgIcon>
  )
}
