// NOTE: don't transform into ts as it's being used without ts

const isProd = process.env.NODE_ENV === 'production'
const isDev = !isProd
const isTest = process.env.NODE_ENV === 'test'

function returnForProd(forProd, notForProd) {
  return isProd ? forProd : notForProd
}

function returnForDev(forDev, notForDev) {
  return isDev ? forDev : notForDev
}

function returnForTest(forTest, notForTest) {
  return isTest ? forTest : notForTest
}

module.exports = {
  isProd,
  isDev,
  isTest,
  returnForProd,
  returnForDev,
  returnForTest,
}
