import { css } from 'linaria'

import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  absoluteContainer: css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  `,
  parentContainer: css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50px;
    left: 50px;
    ${theme.breakpoints.up('md')} {
      right: 100px;
      left: 100px;
    }
  `,
  wrapper: css`
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    margin: 0 -50px;
    border-bottom-left-radius: 600px 130px;
    border-bottom-right-radius: 600px 130px;
    ${theme.breakpoints.up('md')} {
      padding-top: 56.25%;
      margin: 0 -100px;
      border-bottom-left-radius: 800px 100px;
      border-bottom-right-radius: 800px 100px;
    }
  `,
  content: css`
    color: white;
    padding: ${theme.spacing(0, 2)};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${theme.breakpoints.up('md')} {
      padding: 0;
      max-width: ${commonStyling.breakpoints.desktopLarge};
      margin: auto;
    }
  `,
  subtitle: css`
    padding: ${theme.spacing(3, 0)};
    ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(4, 0)};
      &.MuiTypography-root {
        font-size: ${fontSizes.h3};
      }
    }
  `,
  title: css`
    ${theme.breakpoints.up('md')} {
      &.MuiTypography-root {
       font-size: ${fontSizes.h1};
      }
    }
  `,
  curveImage: css`
    position: absolute;
    bottom: 0;
    width: 100%;
  `,
  overlay: css`
    background-color: ${theme.palette.primary.main};
    opacity: 0.6;
  `,
}

export default classes
