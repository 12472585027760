import { Typography } from '@material-ui/core'
import React from 'react'
import { useOrderContext } from '../../../OrderContext'
import classes from './Basket.styles'
import {
  getStandardPrice, getDiscountPrice, getFormattedMonthlyCost, getStandardMonthlyCost,
} from '../../../../../utils/getProductDetails'
import { HobsProduct } from '../../../../../api/Packages'

const BasketHeading = ({
  broadband, bundle,
}: { broadband?: HobsProduct; bundle?: HobsProduct }) => {
  const {
    options: {
      voice,
      tv,
      wier,
      vas,
      discount,
      hide_strikethrough,
    },
  } = useOrderContext()

  const oldPrice = getStandardPrice(broadband)
  const currentPrice = getDiscountPrice(broadband)

  return (
    <Typography variant="body1">
      {(broadband || bundle) ? (
        <>
          <span>Monthly:</span>
          {oldPrice! > currentPrice! && currentPrice !== '' && !hide_strikethrough &&
          <span className={classes.strikeThrough}>
            {getStandardMonthlyCost({
              broadband,
              bundle,
              voice,
              tv,
              wier,
              vas,
            })}
          </span>}
          <span className={classes.actualPrice}>{
            getFormattedMonthlyCost({
              broadband,
              bundle,
              voice,
              tv,
              wier,
              vas,
              discount,
            })
          }
          </span>
        </>
      ) : (
        <>
          <span>Monthly: £0</span>
          <span>|</span>
          <span>Set up: £0</span>
        </>
      )}
    </Typography>
  )
}

export default BasketHeading
