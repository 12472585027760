import { css } from 'linaria'
import theme, { fontWeights } from '../../styles/theme'

const classes = {
  item: css`
    margin: 0 auto;
    padding: ${theme.spacing(2, 1, 2, 1)};
    > * {
      text-align: center;
      margin: 0 auto;
      overflow-wrap: anywhere;
    }
    .MuiTypography-caption {
      font-weight: ${fontWeights.semibold};
      line-height: 1.3;
      ${theme.breakpoints.up('sm')} {
        font-size: 1rem;
      }
    }
  `,
  imageContainer: css`
    &.MuiGrid-item {
      min-height: 70px;
      margin: 0 auto;
      position: relative;
    }
    +.MuiGrid-grid-xs-12 {
      max-width: 160px;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-bottom: ${theme.spacing(2)}px;
      max-width: 45px;
      max-height: 45px;

      ${theme.breakpoints.up('sm')} {
        max-width: 60px;
        max-height: 60px;
      }
    }
  `,
}

export default classes
