import { Product } from './commonInterfaces'
import { isProduct } from './isProduct'

interface Props {
  broadband?: Product | null;
  bundle?: Product | null;
  voice?: Product | null;
  tv?: Product | null;
  wier?: Product | null;
  vas?: Product | null;
}

export const getOrderProducts = ({
  broadband, bundle, voice, tv, wier, vas,
}: Props) => {
  const orderProducts = [
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  ].filter(product => isProduct(product)) as any

  return orderProducts
}
