import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  input: css`
    .MuiInputBase-root {
      margin-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(2)}px !important;

    }
    .MuiInput-underline {
      &:before, &:after, :hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
    }
    input {
      color: ${theme.palette.primary.main};
      max-width: 92%;
      &::placeholder {
         color: ${theme.palette.grey[900]};
         font-style: normal;
         font-weight: ${theme.typography.fontWeightMedium!};
      }
    }
  `,
  searchWrapper: css`
    position: relative;
    margin-bottom: ${theme.spacing(2)}px;
    .MuiAutocomplete-root {
      flex: 1;
    }
  `,
  searchRoot: css`
    &.MuiPaper-rounded {
      padding: 2px 4px;
      display: flex;
      align-items: center;
      width: 400px;
      max-width: 100%;
      border: solid 1px ${theme.palette.text.disabled};
      border-radius: 4px;
    }
  `,
  wrapper: css`
    border: solid 1px ${theme.palette.primary.main};
  `,
  label: css`
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    margin-bottom: ${theme.spacing(1)}px;
  `,
  body: css`
    &.MuiTypography-root {
      margin-top: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  form: css`
    &.MuiGrid-root {
      max-width: 100%;
    }
    label + .MuiInput-formControl {
      margin-top: ${theme.spacing(3)}px;
    }
    .MuiAutocomplete-clearIndicator {
      background-color: white;
      margin-right: ${theme.spacing(1)}px;
      :hover {
        background-color: ${theme.palette.warning.light};
      }
    }
  `,
}

export default classes
