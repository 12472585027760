import React from 'react'
import {
  SvgIcon, SvgIconProps,
} from '@material-ui/core'
import { css } from 'linaria'

interface Props {
  colorOverride: string;
  colorOverrideInactive?: string;
  active?: boolean;
}

const style = css`
  cursor: pointer;
`

export default function Indicator({
  colorOverride, colorOverrideInactive, active, ...svgProps
}: SvgIconProps & Props) {
  return (
    <SvgIcon {...svgProps} className={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g transform="translate(-205 -495.794)">
          <g transform="translate(205 495.794)" fill="none" stroke={colorOverrideInactive ? '' : colorOverride} strokeWidth="1">
            <circle cx="7" cy="7" r="7" stroke="none"/>
            <circle cx="7" cy="7" r="6.5" fill="none"/>
          </g>
          {!active && colorOverrideInactive &&
            <circle cx="7" cy="7" r="7" transform="translate(205 495.794)" fill={colorOverrideInactive}/> }
          {active && <circle cx="7" cy="7" r="7" transform="translate(205 495.794)" fill={colorOverride}/> }
        </g>
      </svg>
    </SvgIcon>
  )
}
