import { AxiosInstance } from 'axios'
import createCRMApiClient from './CRMApi'
import logger from '../utils/logger'
import { PackageProps } from '../components/Packages/Package'
import moment from 'moment'
import { productsStore } from '../sessionStorage'
import {
  ChannelType, ProductType, SegmentType,
} from '../utils/commonEnums'
import { getHybridPackages } from '../utils'

export default function HobsPackages(client: AxiosInstance) {
  async function search(channel: string, customer_segment?: string) {
    try {
      const dateInteraction = new Date()
      const params = {
        interactionID: 'WEB-' + moment.utc(dateInteraction)
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          .replace(/[^\d]/g, ''),
        interactionDate: new Date()
          .toISOString(),
        sourceApplicationID: 'devtest',
        serviceName: 'getPackages',
        triggeredBy: 'CFLHOBS',
        uuid: 'CFLHOBS',
        password: 'sadmpwd',
        channel,
        opID: 'CFL',
        buID: 'DEFAULT',
        pcFilter: {
          offerPolicy: {
            offerability: [{
              offerabilityCriteria: {
                attribute: [
                  {
                    attributeName: 'operatorID',
                    attributeValue: 'CFL',
                  },
                  {
                    attributeName: 'buID',
                    attributeValue: 'DEFAULT',
                  },
                  {
                    attributeName: 'serviceCatg',
                    attributeValue: 'BROADBAND',
                  },
                  {
                    attributeName: 'customerCategory',
                    attributeValue: 'RES',
                  },
                  {
                    attributeName: 'customerSegment',
                    attributeValue: customer_segment ? customer_segment : SegmentType.RESIDENTIAL,
                  },
                  {
                    attributeName: 'channelID',
                    attributeValue: channel,
                  },
                  {
                    attributeName: 'dealerCode',
                    attributeValue: 'none',
                  },
                  {
                    attributeName: 'actionID',
                    attributeValue: 'AddSubscription',
                  },
                ],
              },
            }],
          },
        },
      }

      const { data } = await client.post<HobsPackagesResponse>('package/v1/0.0/getpackages', params)
      return data
    } catch (err) {
      logger.error('failed to get products', err)
      return []
    }
  }

  return {
    search,
  }
}

export const getPackages = async (channel: string, cms?: PackageProps[], customer_segment?: string) => {
  const client = createCRMApiClient()

  try {
    const data = await client.hobsPackages.search(channel.toUpperCase(), customer_segment?.toUpperCase()) as HobsPackagesResponse
    const packages = data.packages as PackageProps[]

    if (cms) {
      if (Array.isArray(cms) === false) {
        const cmsArray = [cms].flat()
        return getHybridPackages(packages, cmsArray)
      }

      return getHybridPackages(packages, cms)
    }

    return packages
  } catch (err) {
    return {
      message: err.message,
      error: err,
    }
  }
}

export const getPackagesWithStore = async (channel: ChannelType, customer_segment?: SegmentType) => {
  const client = createCRMApiClient()

  try {
    const packagesKey = `${ProductType.PACKAGES}-${channel}-${customer_segment}`
    const store = await productsStore(packagesKey)
      .get(true)

    let packages: HobsProduct[]

    if (store) {
      packages = store.packages!
    } else {
      const data = await client.hobsPackages.search(channel.toUpperCase(), customer_segment?.toUpperCase()) as HobsPackagesResponse
      packages = data.packages
      await productsStore(packagesKey)
        .set({ packages }, 15)
    }

    return packages
  } catch (err) {
    return {
      message: err.message,
      error: err,
    }
  }
}

export interface HobsPackagesResponse {
  interactionID: string;
  packages: HobsProduct[];
  code?: string;
}
export interface HobsProduct {
  offerPrice?: OfferPrice[];
  productOffering?: ProductOffering[];
  productOfferingId?: string;
  productType?: string;
  serviceType?: string;
  productSpec?: ProductSpec[];
  productSpecID?: string;
  id?: string;
  type?: string;
  service_type?: string;
  display_name?: string;
  name_first_line?: string;
  name_second_line?: string;
  usp_description?: string;
  description?: string;
  label?: string;
  contract_length?: number;
}
export interface OfferPrice {
  billingComponentType: string;
  chargeFrequency: string;
  price: {
    currency: string;
    amount: string;
  };
  discountSpecification: {
    discountValue: {
      amount: string;
      units: string;
    };
  };
  termPeriod: {
    termPeriod: {
      amount: string;
      units: string;
    };
  };
}
export interface ProductOffering {
  offerPrice: OfferPrice[];
  productOfferingId: string;
  productSpec: ProductSpec[];
  productUsageGroup: ProductUsageGroup[];
  serviceType: string;
  productType: string;
  productSubType: string;
  productCategory: string;
  service_type: string;
  chargeType: string;
}

export interface ProductUsageGroup {
  productUsageTimePeriod: {
    termPeriod: {
      amount: string;
    };
  };
}

export interface ProductSpec {
  name: string;
  productCategory: string;
  productSpecCharacteristic: ProductSpecCharacteristic[];
  productSpecPrice: ProductSpecPrice[];
  productType: string;
  productSubType: string;
  productUsageGroup: ProductUsageGroup[];
  productSpecID: string;
}

export interface ProductSpecCharacteristic {
  name: string;
  productSpecCharID: string;
  productSpecCharacteristicValue: ProductSpecCharacteristicValue[];
}

export interface ProductSpecCharacteristicValue {
  default: boolean;
  value: string;
}

export interface ProductSpecPrice {
  chargeFrequency: string;
  priceType: string;
  price: {
    amount: string;
  };
}
