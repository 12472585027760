import React,
{
  useEffect, useState,
} from 'react'
import { useIdleTimer } from 'react-idle-timer'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import RegisterInterestModal from '../RegisterInterestModal/RegisterInterestModal'

export interface AbandonedCartModalProps {
  title: string;
  body: string;
  button_text: string;
  success_body: string;
  success_title: string;
  success_button_text: string;
  gdpr_text: string;
  privacy_text: string;
  error_title: string;
  error_body: string;
}

const AbandonedCartModal = ({
  title,
  body,
  button_text,
  success_title,
  success_body,
  success_button_text,
  gdpr_text,
  privacy_text,
  error_title,
  error_body,
}: AbandonedCartModalProps) => {
  const [
    showModal,
    setShowModal,
  ] = useState(false)

  const {
    options, setOptions,
  } = useOrderContext()

  const hasClosedModal = options.hasClosedAbandonedCartModal

  const handleOnIdleOrLeaving = () => {
    if (!hasClosedModal) {
      setShowModal(true)
    }
  }

  useIdleTimer({
    timeout: 1000 * 30,
    onIdle: () => {
      marketingEvents.openAbandonedCartModal('inactive')
      handleOnIdleOrLeaving()
    },
  })

  const handleMouseOut = (e: MouseEvent) => {
    const isLeavingPage = e.pageY <= 0
    if (isLeavingPage) {
      marketingEvents.openAbandonedCartModal('leaving')
      handleOnIdleOrLeaving()
    }
  }

  useEffect(() => {
    if (hasClosedModal) {
      window.addEventListener('mouseout', (e) => {
        e.stopPropagation()
      }, true)
    } else {
      window.addEventListener('mouseout', handleMouseOut)
    }
  }, [hasClosedModal])

  useEffect(() => {
    setShowModal(showModal)
    if (showModal) {
      setOptions({
        ...options,
        hasClosedAbandonedCartModal: true,
      })
    }
  }, [showModal])

  return (
    <RegisterInterestModal
      title={title}
      body={body}
      button_text={button_text}
      success_title={success_title}
      success_body={success_body}
      success_button_text={success_button_text}
      gdpr_text={gdpr_text}
      privacy_text={privacy_text}
      error_title={error_title}
      error_body={error_body}
      showModal={showModal}
      setShowModal={setShowModal}
      abandonedCart
    />
  )
}

export default AbandonedCartModal
