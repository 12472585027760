import { css } from 'linaria'
import theme from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  containerBase: css`
    color: white;
    .MuiContainer-root {
      padding: 0;
    }
    .MuiTypography-h3 {
      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.h2};
      }
    }
  `,
  blueContainer: css`
    background-color: #58789E;
  `,
  greyContainer: css`
    background-color: ${theme.palette.warning.main};
  `,
  whiteContainer: css`
    color: ${theme.palette.primary.main};
  `,
  title: css`
    display: block;
    padding: 0 ${theme.spacing(2, 3, 2)};
  `,
}

export default classes
