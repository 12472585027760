import {
  Box,
  Button,
  Grid, Tab, Tabs, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'
import { useRouter } from 'next/router'
import React, {
  ChangeEvent, useState,
} from 'react'
import { ContainerWithCurve } from '../../styles/ContainerWithCurve'
import StyledButton from '../StyledButton/StyledButton'
import classes from './GridGallery.styles'

interface Image {
  image_file: string;
  alt_text: string;
}

interface ImageGallery {
  fields: {
    image: Image[];
  };
}

interface Tab {
  title: string;
  images: ImageGallery;
}

export interface GridGalleryProps {
  title: string;
  subtitle: string;
  first_grid_title: string;
  first_grid_tabs: Tab[];
  second_grid_title: string;
  second_grid_tabs: Tab[];
  additional_info?: string;
  button_text: string;
  button_url: string;
  is_modal: boolean;
  arrow_url?: string;
}

const mapGalleryPage: (page?: ImageGallery) => Image[] = (page) => page ? page.fields.image : []

const GridGallery = ({
  title,
  subtitle,
  first_grid_title,
  first_grid_tabs,
  second_grid_title,
  second_grid_tabs,
  additional_info,
  button_text,
  button_url,
  is_modal,
  arrow_url,
}: GridGalleryProps) => {
  const [
    activeGallery,
    setActiveGallery,
  ] = useState(0)

  const [
    activeSubTab,
    setActiveSubTab,
  ] = React.useState(0)

  const router = useRouter()

  const grids = [
    {
      title: first_grid_title,
      tabs: first_grid_tabs,
    },
    {
      title: second_grid_title,
      tabs: second_grid_tabs,
    },
  ]

  const handleClick = () => {
    if (is_modal) {
      return router.push(`${router.asPath}${button_url}`, undefined, { shallow: true })
    }

    return router.push(button_url)
  }

  const handleSubTabChange = (_: ChangeEvent<{}>, newSubTabIndex: number): void => {
    setActiveSubTab(newSubTabIndex)
  }

  const TabGallery = ({ tabs }: { tabs: Tab[] }) => {
    const gallery = mapGalleryPage(tabs[Number(activeSubTab)].images)

    return (
      /* eslint-disable-next-line react/jsx-no-useless-fragment */
      <>
        {gallery.map(({
          image_file, alt_text,
        }, index) => (
          <Grid key={index} xs={6} sm={3} md item>
            <img className={classes.image} src={image_file} alt={alt_text}/>
          </Grid>
        ))}
      </>
    )
  }

  return (
    <ContainerWithCurve link={arrow_url}>
      <Grid id="grid-gallery" container direction="column" justify="center" alignItems="center" className={classes.container}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginY={3} marginX={2} className={classes.textWrapper}>
          <Typography align="center" variant="h2" color="primary">{title}</Typography>
          <Typography align="center" className={classes.subtitle} color="primary">{subtitle}</Typography>
        </Box>
        <Grid className={classes.buttonContainer}>
          {grids
            .map(({
              title,
            }, index) => (
              <StyledButton
                key={title}
                color="inherit"
                handleClick={() => {
                  setActiveGallery(index)
                  setActiveSubTab(0)
                }}
                className={cx(activeGallery === index && classes.active, classes.gridButton)}
              >
                <Typography component="span" variant="body2">
                  {title}
                </Typography>
              </StyledButton>
            ))}
        </Grid>
        <Tabs
          value={activeSubTab}
          onChange={handleSubTabChange}
          textColor="primary"
          variant="scrollable"
        >
          {grids.map(({
            tabs,
          }, index) => activeGallery === index &&
          tabs.map(({ title }, index) => (
            <Tab label={title} key={index}/>
          )))}
        </Tabs>
        <Grid className={classes.galleryContainer} container justify="center" alignItems="flex-start">
          <Grid className={classes.gallery} spacing={2} container justify="flex-start" alignItems="flex-start">
            {grids.map(({
              tabs,
            }, index) => activeGallery === index && <TabGallery key={index} tabs={tabs}/>)}
          </Grid>
        </Grid>
        {additional_info &&
        <Grid className={classes.info} container>
          <Typography variant="subtitle2" color="primary">{additional_info}</Typography>
        </Grid>}
        <Button disableElevation className={classes.ctaButton} onClick={handleClick} color="primary" variant="contained">
          <Typography variant="body1">{button_text}</Typography>
        </Button>
      </Grid>
    </ContainerWithCurve>
  )
}

export default GridGallery
