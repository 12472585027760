import React from 'react'
import {
  Typography, Box, Grid,
  Container,
} from '@material-ui/core'
import commonStyling from '../../../../../styles/commonStyling'

export default function OrderProcessing() {
  return (
    <Container>
      <Box mt={10} mb={20}>
        <Grid container direction="column" alignItems="center">
          <Loading/>
          <Typography align="center" variant="h3" color="primary">Your order is being processed.</Typography>
        </Grid>
      </Box>
    </Container>
  )
}

function Loading() {
  return (
    <Box mb={4} className={commonStyling.loadingSpinner}>
      <div/><div/><div/>
    </Box>
  )
}
