import React from 'react'
import {
  css, cx,
} from 'linaria'
import {
  Typography,
  Box,
} from '@material-ui/core'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  label: css`
    flex-grow: 1;
  `,
  bar: css`
    background: ${theme.palette.warning.light};
    border-radius: 9999px;
    height: 8px;
    overflow: hidden;
    width: 100%;
    &.start {
      > div {
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-name: progressBarAnimation;
      }
    }
    @keyframes progressBarAnimation {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
    }
  `,
}

const getDuration = (fileSize: number, speed?: string) => {
  const duration = speed ? fileSize / (parseInt(speed, 10) / 8) : 0
  return duration
}

export const AnimationLine = ({
  speed,
  startSpeedAnimation,
  fileSize = '500',
}: {
  speed?: string;
  startSpeedAnimation?: boolean;
  fileSize?: any;
}) => {
  const duration = getDuration(parseInt(fileSize, 10), speed)
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor((duration - (minutes * 60)) * 10) / 10
  const gradient = `linear-gradient(
      270deg,
      ${COLORS.secondary} 0%,
      ${COLORS.white} 100%
  )`

  return (
    <>
      <Box display="flex" mb={1}>
        <Typography
          color="textPrimary"
          variant="caption"
          className={classes.label}
        >
          Download/upload a video ({fileSize} MB)
        </Typography>
        <Typography
          color="textPrimary"
          variant="caption"
        >
          <span>{minutes > 0 && `${minutes}m`} </span>
          <span>{seconds > 0 && `${seconds}s`}</span>
        </Typography>
      </Box>
      <Box
        className={cx(classes.bar, startSpeedAnimation && 'start')}
      >
        <Box
          height="100%"
          style={{
            animationDuration: `${duration}s`,
            backgroundImage: gradient,
          }}
        />
      </Box>
    </>
  )
}
