import {
  useState, useEffect, useContext, useCallback,
} from 'react'
import isAddressQueryLongEnough from '../isAddressQueryLongEnough'
import { SearchPremise } from '../../api/Addresses'
import createCRMApiClient from '../../api/CRMApi'
import { PremiseContext } from '../../components/PremiseTracker/PremiseContext'
import debounce from 'debounce'

let latestQuery = ''

export default function usePremiseSearch() {
  const { premise: savedPremise } = useContext(PremiseContext)

  const [
    query,
    setQueryString,
  ] = useState<string>('')

  const [
    isFetchingQuery,
    setIsFetchingQuery,
  ] = useState<boolean>(false)

  const [
    premises,
    setPremises,
  ] = useState<SearchPremise[]>([])

  const [
    selectedIndex,
    setSelectedIndex,
  ] = useState<number | null>(null)

  async function getPremises(searchTerm: string) {
    return createCRMApiClient().addresses.search(`${searchTerm}`)
  }

  useEffect(() => {
    const defaultPremises = savedPremise ? [savedPremise] : []
    const defaultPremiseIndex = savedPremise ? 0 : null
    if (savedPremise) {
      setPremises(defaultPremises)
      setSelectedIndex(defaultPremiseIndex)
    }
  }, [savedPremise])

  const searchAddress = useCallback(
    async (query: string) => {
      setIsFetchingQuery(true)
      const queryPremises = await getPremises(query)

      // If these results are returned but a new query is in-flight, then ignore these results
      // and wait for the new results instead.
      if (query !== latestQuery) {
        return
      }

      setIsFetchingQuery(false)
      setSelectedIndex(null)
      setPremises(queryPremises)
    },
    [],
  )

  // Using a 'debounced' call to searchAddress so our API isn't called instantly on every keystroke.
  const [searchAddressDebounced] = useState<typeof searchAddress & {
      clear(): void;
        }>(() => debounce(searchAddress, 200))

  useEffect(() => {
    latestQuery = query

    if (isAddressQueryLongEnough(query)) {
      searchAddressDebounced(query)
    } else {
      reset()
    }

    return () => {
      searchAddressDebounced.clear()
    }
  }, [query])

  function getSelectedPremise() {
    if (selectedIndex === null || premises.length === 0) {
      return null
    }

    return premises[selectedIndex]
  }

  function setQuery(value: string) {
    setSelectedIndex(null)
    setQueryString(value)
  }

  function reset() {
    latestQuery = ''
    setIsFetchingQuery(false)
    setSelectedIndex(null)
    setPremises([])
  }

  function getPremise(sprn: number) {
    return premises.find((p) => sprn === Number(p.sprn))
  }

  const selectedPremise = getSelectedPremise()
  return {
    setSelectedIndex,
    selectedPremise,
    setQuery,
    premises,
    query,
    getPremise,
    isFetchingQuery,
  }
}
