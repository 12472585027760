import React, { useRef } from 'react'
import classes from './TabsWithProgressBar.styles'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import {
  Grid, Tabs as MuiTabs, Tab as MuiTab, Typography,
} from '@material-ui/core'
import { AnimatedProgressBar } from '../../styles/AnimatedProgressBar'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'

export interface TabsWithProgressBarProps {
  readonly title: string;
  readonly subtitle: string;
  readonly tabs: Array<SingleTabWithProgressBarProps>;
}

export interface SingleTabWithProgressBarProps {
  readonly tab_title?: string;
  readonly tab_subtitle?: string;
  readonly animated_progress_bars?: Array<ProgressBarProps>;
}

export interface ProgressBarProps {
  readonly title?: string;
  readonly right_title?: string;
  readonly percentage?: Number;
  readonly animation_duration?: Number;
  readonly type: 'primary'|'secondary';
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item>
          {children}
        </Grid>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function TabsWithProgressBar({
  title,
  subtitle,
  tabs,
}: TabsWithProgressBarProps) {
  const [
    activeTabIndex,
    setActiveTabIndex,
  ] = React.useState(0)

  const handleChange = (_: React.ChangeEvent<{}>, newIndex: number) => {
    setActiveTabIndex(newIndex)
  }

  const componentRef = useRef<HTMLDivElement | null>(null)
  const [isVisible] = useIntersectionObserver({
    elementRef: componentRef,
  })

  return (
    <SectionWithMargin>
      <ContainerWithPadding>
        <Grid container alignContent="center" justify="center">
          <div className={classes.wrapper}>
            <Grid className={classes.titleWrapper}>
              {title && (<Typography className={classes.title} variant="h3">{title}</Typography>)}
              {subtitle && (<Typography className={classes.subtitle}>{subtitle}</Typography>)}
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.tabsWrapper} ref={componentRef}>
                <MuiTabs
                  value={activeTabIndex}
                  onChange={handleChange}
                  variant="standard"
                >
                  {tabs?.map((item, index) => (
                    <MuiTab
                      key={index}
                      label={
                        <>
                          {item.tab_title && (
                            <Typography variant="body2">
                              {item.tab_title}
                            </Typography>
                          )}
                          {item.tab_subtitle && (
                            <Typography variant="subtitle2">
                              {item.tab_subtitle}
                            </Typography>
                          )}
                        </>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </MuiTabs>
                {tabs?.map((item, index) => (
                  <TabPanel value={activeTabIndex} key={index} index={index}>
                    <div className="progressBarTabPanel">
                      {
                        item.animated_progress_bars?.map((bar, barIndex) => (
                          <AnimatedProgressBar
                            key={barIndex}
                            speed={String(bar.animation_duration)}
                            startSpeedAnimation={isVisible}
                            title={bar.title}
                            subtitle={bar.right_title}
                            barColor={bar.type}
                            percentage={String(bar.percentage)}
                          />
                        ))
                      }
                    </div>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </ContainerWithPadding>
    </SectionWithMargin>
  )
}
