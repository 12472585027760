import React from 'react'
import {
  Box, Grid,
  Typography,
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { PaymentData } from '../../PaymentDetails/PaymentDetails'

interface PaymentDetailsProps {
  paymentDetails: PaymentData;
  switchStep: ((e: string) => void);
}

const PaymentDetails = ({
  paymentDetails,
  switchStep,
}: PaymentDetailsProps) => {
  return (
    <>
      <Box mb={2}>
        <Grid container justify="space-between">
          <Typography variant="h4">Direct Debit Payment</Typography>
          <Create color="secondary" onClick={() => switchStep('checkout-payment')}/>
        </Grid>
      </Box>
      <Typography variant="body1">Account Holder: {paymentDetails.accountName}</Typography>
      <Typography variant="body1">Sort Code: {paymentDetails.sortCode}</Typography>
      <Typography variant="body1">Account Number: {paymentDetails.accountNumber}</Typography>
      <Box mt={2}>
        <Typography variant="body2">
          Your first payment will be taken by Direct Debit 3-5 days after you have received your first bill.
        </Typography>
      </Box>
    </>
  )
}

export default PaymentDetails
