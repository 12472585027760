import React from 'react'
import {
  SvgIconProps, SvgIcon,
} from '@material-ui/core'

export default function SecureIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <svg xmlns="http://www.w3.org/2000/svg" width="30.4" height="38" viewBox="0 0 30.4 38"><g transform="translate(-4 -1)"><path className="a" d="M30.6,35.533H7.8a3.8,3.8,0,0,1-3.8-3.8V17.8A3.8,3.8,0,0,1,7.8,14H30.6a3.8,3.8,0,0,1,3.8,3.8V31.733a3.8,3.8,0,0,1-3.8,3.8Zm-22.8-19A1.267,1.267,0,0,0,6.533,17.8V31.733A1.267,1.267,0,0,0,7.8,33H30.6a1.267,1.267,0,0,0,1.267-1.267V17.8A1.267,1.267,0,0,0,30.6,16.533Z" transform="translate(0 3.467)"/><path className="a" d="M28.533,20H8.267A1.267,1.267,0,0,1,7,18.733v-7.6A10.133,10.133,0,0,1,17.133,1h2.533A10.133,10.133,0,0,1,29.8,11.133v7.6A1.267,1.267,0,0,1,28.533,20Zm-19-2.533H27.267V11.133a7.6,7.6,0,0,0-7.6-7.6H17.133a7.6,7.6,0,0,0-7.6,7.6Z" transform="translate(0.8 0)"/><path className="a" d="M16.533,24.067a2.533,2.533,0,1,1,2.533-2.533A2.533,2.533,0,0,1,16.533,24.067ZM16.533,21.533Z" transform="translate(2.667 4.8)"/><rect className="a" width="4" height="4" transform="translate(17 28)"/></g></svg>
    </SvgIcon>
  )
}
