import React from 'react'
import Head from 'next/head'
import parse from 'html-react-parser'

export interface MetadataControlProps {
  metadata: string;
}

export default function MetadataControl({
  metadata,
}: MetadataControlProps) {
  const parsedMetadata = parse(metadata)

  return (
    <Head>
      {parsedMetadata}
    </Head>
  )
}
