import {
  ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { cx } from 'linaria'
import React from 'react'
import { Product } from '../../../../../../utils/commonInterfaces'
import { addNumberSuffix } from '../../../../../../utils/addNumberSuffix'
import { getPriceBreakdown } from '../../../../../../utils/getPriceBreakdown'
import { PackageProps } from '../../../../../Packages/Package'
import classes from '../Basket.styles'

export const PriceBreakdown = ({
  broadband, bundle, voice, tv, wier, vas,
}: {broadband: any; bundle: PackageProps; voice?: Product | null; tv?: Product | null; wier?: Product | null; vas?: Product | null}) => {
  const priceBreakdownArray = getPriceBreakdown({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })
  return (
    <Grid container className={cx(classes.priceBreakdownExpansion)}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Price Breakdown</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {priceBreakdownArray?.map((cost, index) => {
            return (
              <Typography key={index} variant="body2" className={classes.breakdownText}>{`${addNumberSuffix(index + 1)} month payment £${cost}`}</Typography>
            )
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  )
}
