import React from 'react'
import {
  Button,
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core'
import classNames from 'classnames'

import {
  Day as DayType, Meridiem,
} from './Calendar'
import theme from '../../styles/theme'

interface DayProps {
  readonly selectedTimeIndex?: number;
  readonly selectedMeridiem?: Meridiem;
  readonly selectedDayIndex?: number;
  readonly currentDayIndex: number;
  readonly selectedWeek?: number;
  readonly currentWeek?: number;
  readonly day: DayType;
  changeTimeslot({
    dayIndex, meridiem, timeIndex,
  }: {
    dayIndex: number;
    meridiem?: Meridiem;
    timeIndex?: number;
}): Promise<void>;
}

export default function CalendarDay({
  selectedTimeIndex, selectedMeridiem, selectedDayIndex,
  changeTimeslot, day, currentDayIndex, selectedWeek, currentWeek,
}: DayProps) {
  const meridies = Object.keys(day.timeslots)
  const hasAvailableOrSelectedSlot = day.isAvailable || day.hasSelectedTimeslot
  const parseSpaces = (time: string) => time.replace(/\s+/g, '')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const calendarCellHeight = theme.spacing(4)
  const mobileCalendarCellHeight = theme.spacing(5)

  return (
    <Box display="flex" flexDirection="column" gridGap={8} mt={3}>
      {hasAvailableOrSelectedSlot ?
        meridies.map((meridiem: Meridiem) => {
          return day.timeslots[meridiem].map((timeslot, timeIndex) => {
            const selectedSlot =
              selectedMeridiem === meridiem &&
              selectedTimeIndex === timeIndex &&
              selectedDayIndex === currentDayIndex &&
              selectedWeek === currentWeek && day.hasSelectedTimeslot
            const fullyBooked = !timeslot.timeslot?.available && !selectedSlot
            return (
              <Button
                key={timeIndex}
                type="button"
                fullWidth
                disabled={fullyBooked}
                className={classNames({
                  gray: !fullyBooked,
                  selected: selectedSlot,
                  available: timeslot.isAvailable,
                })}
                onClick={() => {
                  changeTimeslot({
                    dayIndex: currentDayIndex,
                    meridiem,
                    timeIndex,
                  })
                }}
                onKeyDown={(event) => event.key === 'Enter' && changeTimeslot({
                  dayIndex: currentDayIndex,
                  meridiem,
                  timeIndex,
                })}
                style={{
                  minHeight: isMobile ? mobileCalendarCellHeight : calendarCellHeight,
                }}
              >
                {(timeslot.timeslot?.available || selectedSlot) &&
                  <Typography variant={isMobile ? 'body2' : 'caption'}>{parseSpaces(timeslot.time)}</Typography>}
                {fullyBooked &&
                <Typography variant={isMobile ? 'body2' : 'caption'}>
                  <span>Fully booked</span>
                </Typography>}
              </Button>
            )
          })
        },
        ) :
        <Button
          disabled
          style={{
            minHeight: calendarCellHeight,
          }}
        >
          <Typography variant={isMobile ? 'body2' : 'caption'}>
            Unavailable
          </Typography>
        </Button>}
    </Box>
  )
}
