import React, { useState } from 'react'
import {
  Typography, Box, Hidden,
} from '@material-ui/core'
import classes from './MediaPageDesktopSingleBundle.styles'
import { cx } from 'linaria'
import { ChevronRight } from '@material-ui/icons'
import { PackageProps } from '../../../Packages/Package'
import { ProductProps } from '../../../Products/Product'
import ModalBox from '../../../ModalBox/ModalBox'
import Features from '../../../Packages/Features'
import { getRouterName } from '../../../../utils'

interface MediaPageDesktopSingleBundle {
  icon?: string;
  bundle?: PackageProps | ProductProps;
  contractPeriod?: number;
  freeSetUpCopy?: string;
}

const MediaPageDesktopSingleBundle = ({
  icon,
  bundle,
  contractPeriod,
  freeSetUpCopy,
}: MediaPageDesktopSingleBundle) => {
  const [
    modalOpen,
    setModalOpen,
  ] = useState(false)

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const contractString = contractPeriod + ' month contract' as string
  const routerName = getRouterName(bundle!)

  return (
    <>
      <Box display="flex" className={cx(classes.packageWrapper, 'bundleWrapper')} onClick={() => handleModalOpen()}>
        <Box display="flex" alignItems="center" flexGrow="1">
          {
            icon && (
              <Box className={classes.packageIcon} mr={2}>
                <img src={icon} alt="bundle"/>
              </Box>
            )
          }
          <Box className="bundleDisplayName">
            {bundle?.display_name && <Typography variant="body1" color="primary">{bundle.display_name}</Typography>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Hidden mdDown>
            <Box className="packageShortDescription" width={120}>
              {routerName && <Typography component="div" variant="overline" color="primary">{routerName}</Typography>}
              {/* {bundle?.descriptionShort && <Typography variant="body2">{bundle.descriptionShort}</Typography>} */}
            </Box>
          </Hidden>
          <ChevronRight className={cx(classes.packageDesktopArrow, 'bundleArrow')}/>
        </Box>
      </Box>
      <ModalBox
        visible={modalOpen}
        sync={handleModalClose}
      >
        <Box mb={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
          {bundle?.display_name && <Typography variant="h4" color="primary">{bundle.display_name}</Typography>}
          {contractPeriod && <Typography variant="overline" color="primary">{contractString}</Typography>}
          {bundle?.usage_description && <Typography variant="body1" className="bundleLongDescription">{bundle?.usage_description}</Typography>}
        </Box>
        {
          (bundle as PackageProps)?.simple_bullets && (
            <Features
              size="large"
              columns={2}
              simple_bullets={(bundle as PackageProps)?.simple_bullets}
              bullets_crm={bundle}
              free_set_up_copy={freeSetUpCopy}
              bullets_with_icons={(bundle as PackageProps)?.bullets_with_icons}
            />
          )
        }
      </ModalBox>
    </>
  )
}

export default MediaPageDesktopSingleBundle
