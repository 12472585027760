import React from 'react'
import {
  Box, Button,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import StyledButton from '../StyledButton/StyledButton'
import { ButtonPropsExtended } from './Package'
import theme from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'
import {
  css, cx,
} from 'linaria'
import { HobsProduct } from '../../api/Packages'

export interface PackageCTAProps {
  button?: ButtonPropsExtended;
  id?: string;
  button_secondary?: ButtonPropsExtended;
  mt?: any;
  disabled?: boolean;
  selected: boolean;
  handleClick: (e?: React.ChangeEvent<{}>) => void;
  handleSecondaryClick: (e?: React.ChangeEvent<{}>) => void;
  product?: HobsProduct;
}

const classes = {
  btnIcon: css`
    margin-right: ${theme.spacing(0.5)}px;
    position: relative;
    top: ${theme.spacing(0.5)}px;
  `,
  binIcon: css`
    &.MuiButton-root {
      background-color: #f8f8f8;
      margin-left: 8px !important;
      &:hover {
        background-color:#0000004E;
      }
    }
    &.MuiButtonBase-root {
      border-radius: ${commonStyling.smallRoundedBorders.allSmall};
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      height: 46px;
      min-width: auto;
    }
    img {
      width: 13.5px;
      height: 15px;
      margin: 0;
    }
  `,
  selected: css`
    background-color: ${theme.palette.secondary.main} !important;
    color: white;
    opacity: unset !important;
  `,
}

const PackageCTA = ({
  button, id, button_secondary, mt, disabled, selected, handleClick, handleSecondaryClick, product,
}: PackageCTAProps) => {
  const stopParentEventTrigger = (b: React.ChangeEvent<{}>) => b.stopPropagation()

  if (!button) {
    return null
  }

  const modifiedId = id ? id.replace(/ /g, '_') : ''
  return (
    <Box gridArea="cta" mt={mt}>
      <Box display="grid" gridGap={10}>
        <Box display="flex">
          <StyledButton
            color="primary"
            isModal={button.isModal}
            handleClick={selected ? stopParentEventTrigger : handleClick}
            url={button.url}
            id={modifiedId && `${modifiedId}_selectBTN`}
            disabled={selected || disabled}
            isFullWidth
            className={cx(selected && classes.selected)}
          >
            {selected && <Check className={classes.btnIcon}/>}
            <span>{selected ? 'Added' : button?.text}</span>
          </StyledButton>
          {selected && product?.service_type !== 'bundle' && (
            <Button id={`${modifiedId}_deleteBTN`} type="button" onClick={handleClick} className={classes.binIcon}>
              <img src="/Icon-feather-trash-2.svg" alt=""/>
            </Button>
          )}
        </Box>
        {button_secondary &&
          <StyledButton
            color="secondary"
            variant="outlined"
            handleClick={handleSecondaryClick}
            isFullWidth
            id={modifiedId && `${modifiedId}_secondaryBTN`}
          >
            {button_secondary?.text}
          </StyledButton>}
      </Box>
    </Box>
  )
}

export default PackageCTA
