import React from 'react'
import {
  Typography, Link, Box,
} from '@material-ui/core'
import { general } from '../../styles/theme'
import Wrapper from '../Wrapper/Wrapper'

export interface ContactSupportProps {
  readonly title: string;
  readonly anchor_ref?: string;
  readonly body: string;
  readonly phone_number?: string;
  readonly email_address?: string;
}

export default function ContactSupport({
  title, anchor_ref, body, phone_number, email_address,
}: ContactSupportProps) {
  return (
    <Wrapper id={anchor_ref} verticalPaddings>
      <Typography variant="h3" color="primary" component="header" align="center">{title}</Typography>
      <Box display="flex" flexDirection="column" alignItems="center" my={3}>
        <Link variant="body1" color="secondary" href={`tel:${phone_number}`} className={general.font.weight.semibold}>{phone_number}</Link>
        <Link variant="body1" color="secondary" href={`mailto:${email_address}`} className={general.font.weight.semibold}>{email_address}</Link>
      </Box>
      <Typography variant="body2" color="primary" align="center" className={general.font.weight.book}>{body}</Typography>
    </Wrapper>
  )
}
