import {
  Hidden, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'
import React from 'react'
import classes from './FullWidthBanner.styles'

export interface FullWidthBannerProps {
  icon?: string;
  title: string;
  paragraph: string;
  background_image: string;
  mobile_background_image: string;
  background_color?: string;
  text_color: 'white' | 'purple';
}

interface BannerInnerProps {
  icon?: string;
  title: string;
  paragraph: string;
}

const FullWidthBanner = ({
  icon, title, paragraph, background_image, mobile_background_image, background_color, text_color,
}: FullWidthBannerProps) => {
  const textColor = cx(classes.title, text_color === 'purple' && classes.purpleTitle)

  const BannerInner = ({
    icon, title, paragraph,
  }: BannerInnerProps) => {
    return (
      <div className={classes.content}>
        {icon && <img src={icon} alt="Amazon Prime Logo"/>}
        <Typography variant="h2" className={textColor}>
          {title}
        </Typography>
        <Typography variant="body1" className={textColor}>{paragraph}</Typography>
      </div>
    )
  }

  return (
    <>
      <Hidden smUp>
        <div
          className={classes.container} style={mobile_background_image ? {
            backgroundImage: `url('${mobile_background_image}')`,
            backgroundColor: background_color ?? '',
          } : {}}
        >
          <BannerInner icon={icon} title={title} paragraph={paragraph}/>
        </div>
      </Hidden>
      <Hidden xsDown>
        <div
          className={classes.container} style={background_image ? {
            backgroundImage: `url('${background_image}')`,
            backgroundColor: background_color ?? '',
          } : {}}
        >
          <BannerInner icon={icon} title={title} paragraph={paragraph}/>
        </div>
      </Hidden>
    </>
  )
}

export default FullWidthBanner
