import React from 'react'
import {
  Typography, Link, Box,
} from '@material-ui/core'
import classes from './ComparisonModal.styles'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import { ArrowForwardIos } from '@material-ui/icons'
import { TextWithImageProps } from './ComparisonModal'

const TextWithImage = ({
  textWithImageTab,
}: {textWithImageTab: TextWithImageProps}) => {
  return (
    <Box
      className={classes.textwithImageContainer}
      px={4}
    >
      <img src={textWithImageTab.image} alt=""/>
      <Typography variant="h3">{textWithImageTab.title}</Typography>
      <Wysiwyg variant="body1" body={textWithImageTab.body}/>
      {textWithImageTab?.button?.url &&
      <Link href={textWithImageTab.button.url}>
        <Box display="flex" width="min-content" whiteSpace="nowrap" mx="auto" alignItems="center" gridGap={8}>
          <Typography variant="body1">{textWithImageTab.button.text}</Typography>
          <ArrowForwardIos/>
        </Box>
      </Link>}
    </Box>
  )
}

export default TextWithImage
