import React from 'react'
import classes from './ModalBox.styles'
import {
  Box, Modal,

} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  COLORS, ROUNDED,
} from '../../styles/theme'
import { cx } from 'linaria'

export interface ModalBoxProps {
  children: React.ReactNode;
  visible: boolean;
  trigger_label?: string;
  sync?: () => void;
  onClose?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, reason: string) => void;
  bgcolor?: string;
  maxWidth?: string;
  width?: string;
  maxHeight?: string;
  height?: string;
  px?: any;
  py?: any;
  my?: any;
  mx?: any;
  fullScreen?: boolean;
  noCloseIcon?: boolean;
  className?: string;
  id?: string;
}

export default function ModalBox({
  bgcolor = COLORS.white,
  children,
  visible,
  sync,
  onClose,
  px = {
    xs: 5,
    md: 7,
  },
  py = 0,
  width = 'auto',
  maxWidth = '640px',
  maxHeight,
  height,
  my = 4,
  mx = 2,
  fullScreen,
  noCloseIcon = false,
  className,
  id,
}: ModalBoxProps) {
  const handleClose = () => {
    return sync && sync()
  }

  return (
    <Modal
      open={visible}
      onClose={onClose || sync}
      className={cx(classes.modalScreen, className)}
      id={id}
    >
      <Box py={fullScreen ? 0 : my} px={fullScreen ? 0 : mx} width="100%" boxSizing="border-box">
        <Box
          maxWidth={fullScreen ? '100%' : maxWidth}
          width={fullScreen ? '100vw' : width}
          maxHeight={fullScreen ? '100%' : maxHeight}
          height={fullScreen ? '100vh' : height}
          position="relative"
          {...!fullScreen && { borderRadius: ROUNDED.medium }}
          bgcolor={bgcolor}
          overflow={fullScreen && 'auto'}
        >
          {!noCloseIcon &&
          <Box display="flex" justifyContent="flex-end" px={2} pt={3} pb={2}>
            <CloseIcon color="primary" className={classes.close} onClick={handleClose}/>
          </Box>}
          <Box
            py={py}
            px={px}
          >
            { children }
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
