import { css } from 'linaria'
import {
  COLORS,
  fontWeights,
} from '../../styles/theme'

const classes = {
  container: css`
    max-width: 345px;
    margin: 0 auto;
  `,
  checkBox: css`
  .MuiCheckbox-root{
    color: ${COLORS.primary};
    padding:0;
    margin-right:8px;
  }
  `,
  tabsWrapper: css`
    display:flex;
    justify-content: center;
    gap:6px;
    .MuiTab-root {
      padding-right: 0;
      padding-left: 0;
      max-width: 52px;
      min-width: 52px;
    }

    .MuiTab-wrapper {
      max-width: 10%;
    }

    .MuiFormControlLabel-root {
      margin-right:12px;
    }
  `,
  speedText: css`
    display:flex;
    gap:3px;
    .MuiTypography-root {
      font-weight: ${fontWeights.semibold};
    }
  `,
}

export default classes
