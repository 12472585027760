import React from 'react'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
} from '@material-ui/core'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import { ExpandMore } from '@material-ui/icons'
import classes from './DropdownWithImage.styles'
import { cx } from 'linaria'

export interface DropdownProps {
  title?: string;
  body: string;
  transparent?: boolean;
}

const Dropdown = ({
  title,
  body,
  transparent,
}: DropdownProps) => {
  return (
    <ExpansionPanel square elevation={0} className={cx(transparent ? cx(classes.dropdownPanel, classes.transparent) : classes.dropdownPanel)}>
      <Box>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore color="secondary"/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" variant="h5" component="span">{title}</Typography>
        </ExpansionPanelSummary>
      </Box>
      <ExpansionPanelDetails>
        <Wysiwyg body={body} variant="body1"/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default Dropdown
