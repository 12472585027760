import React from 'react'
import {
  useFormContext, ValidationOptions,
} from 'react-hook-form'
import { camelCase } from 'change-case'
import {
  css, cx,
} from 'linaria'
import {
  TextField, Grid, InputBaseComponentProps,
} from '@material-ui/core'
import theme from '../../styles/theme'

interface InputProps {
  label: string;
  type?: 'text' | 'date' | 'tel' | 'email' | 'number' | 'password';
  gridWidth?: 12 | 4 | 6 | 3;
  id?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  customInputComponent?: any;
  validation?: ValidationOptions;
  handleChange?: (e?: any) => void;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  multiline?: boolean;
  inputProps?: InputBaseComponentProps;
  onCopy?: (e: any) => void;
  onCut?: (e: any) => void;
  onPaste?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

const classes = {
  fieldContainer: css`
    .MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.95) !important;
      z-index: 2;
    }
  `,
  input: css`
    &.MuiInputBase-input {
      margin-top: ${theme.spacing(1)}px;
      padding: ${theme.spacing(2, 0, 2)};
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
}

/**
 * Text input designed to be nested within a grid and used as part of a form
 * Relies pull values from form context. see react-hook-form
 *
 */
export default function TextInput({
  label, required = false, id = camelCase(label),
  placeholder = label, gridWidth = 6, type = 'text',
  value,
  customInputComponent, validation = {}, handleChange,
  disabled, className, multiline, inputProps = { }, onCopy, onCut, onPaste, onKeyDown,
  containerClassName,
}: InputProps) {
  const {
    register, errors,
  } = useFormContext()

  const error = getErrorMessage(errors, label, id)

  return (
    <Grid item xs={12} lg={gridWidth} className={cx(classes.fieldContainer, containerClassName)}>
      <TextField
        className={className}
        fullWidth
        id={id}
        type={type}
        error={Boolean(error)}
        inputRef={register({
          ...validation,
          required,
        })}
        name={id}
        required={required}
        label={label}
        value={value}
        placeholder={placeholder}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps,
          classes: { input: classes.input },
          inputComponent: customInputComponent,
        }}
        helperText={error}
        onChange={handleChange}
        disabled={disabled}
        multiline={multiline}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
      />
    </Grid>
  )
}

function getErrorMessage(errors: any, label: string, id: string): string | null {
  const error = errors[id]

  if (!error) {
    return null
  }

  if (error.type === 'required') {
    return `${label} is required`
  }

  if (error.type === 'validate' && error.ref.name === 'confirmEmail') {
    return 'Email address does not match'
  }

  return error.message
}
