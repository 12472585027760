import React, {
  useEffect, useState,
} from 'react'
import {
  GetServerSidePropsContext, GetServerSidePropsResult, Redirect,
} from 'next'
import { ThemeProvider } from '@material-ui/styles'
import Head from 'next/head'
import { useRouter } from 'next/router'
import is from '@sindresorhus/is'

import butter, {
  ButterCMSComponent, getButterPage,
} from '../../butter'
import {
  checkOutPageSteps, StepType,
} from '../../components/NewOrderFlow/steps/CheckoutStep/CheckoutStep'
import {
  PremiseContextProvider,
} from '../../components/PremiseTracker/PremiseContext'
import { TrustPilotHeadScript } from '../../components/TrustPilotWidget/TrustPilotHeadScript'
import theme from '../../styles/theme'
import OrderFlow from '../../components/NewOrderFlow/OrderFlow'
import getStepTitle from '../../components/NewOrderFlow/getStepTitle'
import { handleRedirect } from '../../utils/redirect'
import { premiseStore } from '../../storage'
import { SearchBarResultProps } from '../premise-result/[sprn]'
import createCRMApiClient from '../../api/CRMApi'
import { PremiseDetail } from '../../api/Addresses'
import getStepUrl from '../../components/NewOrderFlow/getStepUrl'
import PremiseTracker from '../../components/PremiseTracker/PremiseTracker'
import { useOrderContext } from '../../components/NewOrderFlow/OrderContext'
import { getOrderProducts } from '../../utils/getOrderProducts'

interface Props {
  bundlesData: {
    components: ButterCMSComponent[];
  };
  checkoutData: {
    components: ButterCMSComponent[];
  };
  orderManagementData: {
    components: ButterCMSComponent[];
  };
  step: OrderStep;
  orderManagementPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderManagementNewPortingConfirmation: {
    components: ButterCMSComponent[];
  };
  orderConfirmationData: {
    components: ButterCMSComponent[];
  };
  generalSettings: {
    components: ButterCMSComponent[];
  };
}

export default function NewOrderFlowPage({
  step: serverSideStep, bundlesData, checkoutData,
  orderManagementData, orderManagementPortingConfirmation, orderConfirmationData, orderManagementNewPortingConfirmation, generalSettings
}: Props) {
  const router = useRouter()

  const {
    options: {
      broadband,
      bundle,
      voice,
      tv,
      wier,
      vas,
    },
  } = useOrderContext()

  const orderProducts = getOrderProducts({
    broadband,
    bundle,
    voice,
    tv,
    wier,
    vas,
  })

  const [
    step,
    setStep,
  ] = useState<OrderStep>(serverSideStep)

  useEffect(() => {
    function handleRouteChange(url: string) {
      const parts = url.split('?')[0].split('/')

      const pathStep = parts[2]
      const pathSubStep = parts[3]

      const newStep = mapParamsToStep({
        step: pathStep,
        subStep: pathSubStep,
      })

      if (newStep) {
        setStep(newStep)
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    async function validatePremise() {
      const premise = await premiseStore.get()

      if (!premise && !router.asPath.includes(getStepUrl('order-management'))) {
        handleRedirect({ to: '/' })
      }
    }

    validatePremise()
  }, [router])

  useEffect(() => {
    const isAtOrderSummary = step.includes('summary')
    const isAtCheckoutAndNotAtOrderSummary = step.includes('checkout') && !isAtOrderSummary
    const atBottomOfPage = isAtCheckoutAndNotAtOrderSummary || orderProducts.length === 0
    const hasThreeProducts = orderProducts.length === 3
    const getMobileOffsetBottom = () => {
      if (atBottomOfPage) {
        return 0
      }

      if (isAtOrderSummary) {
        return 70
      }

      if (hasThreeProducts) {
        return 104
      }

      return 78
    }

    window.zE && window.zE(() => {
      window.$zopim.livechat.button.setOffsetHorizontal(-8)
      window.$zopim.livechat.button.setOffsetHorizontalMobile(-8)
      window.$zopim.livechat.button.setOffsetVerticalMobile(getMobileOffsetBottom())
      window.$zopim.livechat.button.setOffsetVertical(atBottomOfPage ? 0 : 78)
    })
  }, [
    router,
    orderProducts,
  ])

  const stepTitle = checkOutPageSteps.includes(step) ? `${getStepTitle(step)}` : getStepTitle(step)

  return (
    <PremiseContextProvider>
      <Head>
        <title>{stepTitle}</title>
        <TrustPilotHeadScript/>
      </Head>
      <ThemeProvider theme={theme}>
        <PremiseTracker/>
        <OrderFlow
          bundlesData={bundlesData}
          checkoutData={checkoutData}
          orderManagementData={orderManagementData}
          step={step}
          orderManagementPortingConfirmation={orderManagementPortingConfirmation}
          orderManagementNewPortingConfirmation={orderManagementNewPortingConfirmation}
          orderConfirmationData={orderConfirmationData}
          generalSettings={generalSettings}
        />
      </ThemeProvider>
    </PremiseContextProvider>
  )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext<{ step: [string] | [string, string] }>): Promise<GetServerSidePropsResult<Props> | { notFound: true }> {
  if (!ctx.params) {
    return {
      notFound: true,
    }
  }

  const [
    urlStep,
    urlSubStep,
  ] = ctx.params.step

  const step = mapParamsToStep({
    step: urlStep,
    subStep: urlSubStep,
  })

  if (!step) {
    return {
      notFound: true,
    }
  }

  const { data: { data } } = await butter.content.retrieve(['search_bar_result'], {
    'fields.name': 'Main',
  })

  let premise: PremiseDetail | null = null
  if (step === 'broadband') {
    const sprn = ctx.query.sprn

    if (!is.string(sprn)) {
      return {
        redirect: homePageRedirect,
      }
    }

    const searchResult: SearchBarResultProps = data.search_bar_result[0]
    premise = await getPremise(sprn)

    if (!premise) {
      return {
        redirect: homePageRedirect,
      }
    }

    switch (premise.accepting) {
      case 'order':
        break
      case 'register_interest':
        return {
          redirect: {
            permanent: false,
            destination: searchResult.register_interest_slug,
          },
        }
      case 'register_interest_early':
        return {
          redirect: {
            permanent: false,
            destination: searchResult.register_interest_early_slug,
          },
        }

      case 'none': {
        return {
          redirect: {
            permanent: false,
            destination: searchResult.not_in_your_area_slug,
          },
        }
      }

      default:
        break
    }
  }

  const bundlesData = await getButterPage('order-flow-bundles', {})
  const checkoutData = await getButterPage('order-flow-checkout', {})
  const orderManagementPortingConfirmation = await getButterPage('order-management-porting-confirmation', {})
  const orderManagementNewPortingConfirmation = await getButterPage('order-management-new-number-confirmation', {})
  const orderManagementData = await getButterPage('order-management', {})
  const orderConfirmationData = await getButterPage('order-flow-confirmation', {})
  const generalSettings = await getButterPage('general-settings', {})

  return {
    props: {
      bundlesData,
      checkoutData,
      orderManagementData,
      step,
      orderManagementPortingConfirmation,
      orderManagementNewPortingConfirmation,
      orderConfirmationData,
      generalSettings,
    }
  }
}

export type OrderStep =
  | StepType
  | 'broadband' | 'broadband-bundles' | 'addons' | 'order-management' | 'order-management-porting-confirmation' | 'order-management-new-number-confirmation'

export function mapParamsToStep({
  step, subStep,
}: {
  step: string;
  subStep?: string;
}): OrderStep | null {
  switch (step) {
    case 'package': {
      switch (subStep) {
        case 'bundles':
          return 'broadband'
        case 'addons':
          return 'addons'
        default:
          return 'broadband'
      }
    }

    case 'management':
      switch (subStep) {
        case 'keep-number-confirmation':
          return 'order-management-porting-confirmation'
        case 'new-number-confirmation':
          return 'order-management-new-number-confirmation'
        default:
          return 'order-management'
      }

    case 'checkout':
      switch (subStep) {
        case 'switching':
          return 'checkout-one-touch-switching'
        case 'installation':
          return 'checkout-installation'
        case 'payment':
          return 'checkout-payment'
        case 'summary':
          return 'checkout-order-summary'
        case 'order-processing':
          return 'checkout-order-processing'
        case 'confirmation':
          return 'checkout-order-confirmation'
        case 'details':
        default:
          return 'checkout-details'
      }

    default:
      return null
  }
}

async function getPremise(
  sprn: string,
): Promise<PremiseDetail | null> {
  const client = createCRMApiClient()

  const premise = await client.addresses.get(parseInt(sprn, 10))

  if (!premise || 'error' in premise) {
    return null
  }

  return premise
}

const homePageRedirect: Redirect = {
  permanent: false,
  destination: '/',
}
