import {
  useContext, useEffect,
} from 'react'

import {
  PremiseDetail,
} from '../../api/Addresses'
import { PremiseContext } from '../../components/PremiseTracker/PremiseContext'

export default function useSavedPremise(callback: (premise: PremiseDetail) => void) {
  const { premise } = useContext(PremiseContext)
  useEffect(() => {
    if (premise) {
      callback(premise)
    }
  }, [
    premise,
    callback,
  ])
}
