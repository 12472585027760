import React, { ReactNode } from 'react'
import {
  withStyles, createStyles, Theme, WithStyles,
} from '@material-ui/core/styles'
import {
  Box, Container,
} from '@material-ui/core'
import { cx } from 'linaria'
import { GRADIENTS } from '../../styles/theme'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    horizontalPaddings: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    verticalPaddings: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    centeredContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    gradient: {
      backgroundImage: GRADIENTS.gray,
    },
  })

interface WrapperProps extends WithStyles<typeof styles> {
  children?: ReactNode;
  className?: string;
  containerClasses?: any;
  id?: string;
  center?: boolean;
  horizontalPaddings?: boolean;
  verticalPaddings?: boolean;
  gradient?: boolean;
  container?: boolean;
}

const Wrapper = ({
  classes, children, className, id, center = true,
  horizontalPaddings = true, verticalPaddings,
  gradient, container = true, containerClasses,
}: WrapperProps) => {
  return (
    <Box
      className={cx(
        classes.root, className,
        center && classes.centeredContainer,
        gradient && classes.gradient,
      )}
      id={id}
    >
      <Box
        component={container ? Container : 'div'}
        className={cx(
          containerClasses,
          horizontalPaddings && classes.horizontalPaddings,
          verticalPaddings && classes.verticalPaddings,
        )}
        boxSizing="border-box"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  )
}

export default withStyles(styles)(Wrapper)
