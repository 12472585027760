import React, {
  useState, useEffect,
} from 'react'
import Image from 'next/image'
import classes from './Image.styles'
import { cx } from 'linaria'

type ScreenSize = {
    width: number;
    height: number;
}

export type MediaScreen = {
    desktop: ScreenSize;
    tablet: ScreenSize;
    mobile: ScreenSize;
}

interface NextImageInterface {
    src: string;
    alt?: string;
    image_sizes: MediaScreen;
}

const useImageSize = (image_sizes: MediaScreen): ScreenSize => {
  const [
    screenWidth,
    setScreenWidth,
  ] = useState<number>(0)
  const [
    width,
    setWidth,
  ] = useState<number>(0)
  const [
    height,
    setHeight,
  ] = useState<number>(0)

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    handleWindowSizeChange()
  }, [])

  useEffect(() => {
    if (screenWidth >= 1140) {
      setWidth(image_sizes.desktop.width)
      setHeight(image_sizes.desktop.height)
    }

    if (screenWidth >= 768 && screenWidth < 1140) {
      setWidth(image_sizes.tablet.width)
      setHeight(image_sizes.tablet.height)
    }

    if (screenWidth < 768) {
      setWidth(image_sizes.tablet.width)
      setHeight(image_sizes.tablet.height)
    }
  }, [screenWidth])

  return {
    width,
    height,
  }
}

export const NextImage = ({
  src, alt, image_sizes,
}: NextImageInterface) => {
  const {
    width, height,
  } = useImageSize(image_sizes)
  return (
    <div
      className={cx(classes.wrapper, 'nextImageWrapper')} style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <Image alt={alt} src={src} layout="responsive" width={width} height={height}/>
    </div>
  )
}
