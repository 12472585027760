import React, {
  RefObject, useRef,
} from 'react'
import {
  Grid, Typography, Box,
} from '@material-ui/core'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import classes from './InfoCards.styles'
import InfoCard from './InfoCard'
import Slider from 'react-slick'
import { useWindowWidth } from '../../utils/useWindowWidth'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { CtaProps } from '../../utils/commonProps'

export interface InfoCardProps {
  front_title?: string;
  front_button_text?: string;
  back_title?: string;
  back_body?: string;
  card_body?: string;
  cta_button?: CtaProps;
  read_more_text?: string;
  read_less_text?: string;
  enable_cta_area?: boolean;
  back_button_text?: string;
  back_button_url?: string;
  front_icon?: string;
  back_icon?: string;
  image_overlay?: boolean;
  image?: string;
}

export interface InfoCardsProps {
  title: string;
  body: string;
  anchor_ref?: string;
  cards: InfoCardProps[];
}

const InfoCards = ({
  title,
  body,
  anchor_ref,
  cards,
}: InfoCardsProps) => {
  const sliderRef: RefObject<Slider> = useRef(null)

  const windowWidth = useWindowWidth()
  const showMultipleCards = windowWidth && windowWidth > (280 * 3)
  const maxVisibleCards = windowWidth && windowWidth / 300
  const getSlidesToShow = maxVisibleCards && maxVisibleCards > cards.length ? cards.length : maxVisibleCards

  const sliderSettings = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: showMultipleCards ? getSlidesToShow : 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: !showMultipleCards,
  }

  return (
    <SectionWithMargin id={anchor_ref}>
      <Box px={2}>
        <Grid container direction="column" alignItems="center">
          <Typography color="primary" variant="h3" align="center" className={classes.componentTitle}>{title}</Typography>
          <Wysiwyg variant="body1" body={body} className={classes.bodyText}/>
        </Grid>
      </Box>
      {cards.length &&
      <Box mt={4}>
        <ContainerWithPadding>
          <Slider
            ref={sliderRef}
            {...sliderSettings}
            className={classes.sliderContainer}
          >
            {cards.map((card, i) => {
              return (
                <InfoCard card={card} key={i}/>
              )
            })}
          </Slider>
        </ContainerWithPadding>
      </Box>}
    </SectionWithMargin>
  )
}

export default InfoCards
