import theme, { COLORS } from '../../styles/theme'
import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  mainBox: css`
    img {
      height: auto;
      max-width: 100%;
    }
    .MuiContainer-root {
      max-width: 800px;
      padding: 0;
    }
    .MuiExpansionPanel-root:before {
      background-color: transparent;
    }
    .MuiTypography-h3 {
      text-align: center;
      margin-bottom: ${theme.spacing(5)}px;
      font-size: ${fontSizes.h3};
      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.h2};
      }
      ${theme.breakpoints.down('xs')} {
        margin-bottom: ${theme.spacing(2)}px;
      }
    }
  `,
  wrapperPanel: css`
    .MuiExpansionPanelSummary-content {
      flex-grow: 0;

      .MuiTypography-h3 {
        margin-bottom: 0;
      }
    }
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
    }
    .MuiExpansionPanel-root.Mui-disabled {
      background-color: transparent !important;
    }
  `,
  panel: css`
    border-radius: ${commonStyling.roundedBorders.all};
    margin-top: ${theme.spacing(2)}px;
    padding: ${theme.spacing(3, 0)};
    .MuiCollapse-container .MuiTypography-colorPrimary {
      color: ${COLORS.bodyTextPrimary};
    }
    .Mui-expanded, p {
      margin: 0;
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiExpansionPanelSummary-root {
      align-items: flex-start;
      min-height: 0;
    }
    .MuiExpansionPanelSummary-content {
      flex-grow: 1;
      margin: 0;
    }
    &.MuiExpansionPanel-root.Mui-expanded:first-child {
      margin-top: ${theme.spacing(2)}px;
    }
    .MuiExpansionPanelDetails-root {
      padding: ${theme.spacing(0, 3)};
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height:0;
    }
    ul {
      margin: 0;
      padding-left: ${theme.spacing(2.5)}px;
    }
    .MuiTypography-body2 {
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightLight!};
      line-height: 1.4;
      ${theme.breakpoints.up('md')} {
        line-height: 1.5;
        font-size: ${fontSizes.body1};
      }
      a {
        color: ${theme.palette.secondary.contrastText};
      }
    }
  `,
  panelWithBackground: css`
    background-color: ${theme.palette.warning.light}!important;
  `,
  icon: css`
    svg {
      width: 30px;
      height: 30px;
    }
    &.MuiExpansionPanelSummary-expandIcon {
      margin: 0;
      transition: all .2s ease-in-out;
      padding: 0;
    }
    .MuiTouchRipple-root {
      display: none;
    }
    .MuiExpansionPanelSummary-expandIcon&.Mui-expanded {
      transform: rotate(45deg);
      transition: all .2s ease-in-out;
    }
  `,
  button: {
    wrapper: css`
      display: flex;
      justify-content: center;
      margin: 0 auto !important;
      padding-top: ${theme.spacing(4)}px;
      .MuiButtonBase-root{
        width: 100%;
      }
      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(5)}px;
      }
    `,
  }
  ,
}

export default classes
