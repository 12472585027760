import { PremiseDetail } from '../api/Addresses'
import marketingEvents from './marketing/marketingEvents'

export const triggerValuableLeadEvent = (premise: PremiseDetail | null) => {
  const premiseStatusValues: number[] = [
    20,
    30,
    40,
    45,
    50,
    60,
  ]
  if (premise?.status) {
    if (premiseStatusValues.includes(premise.status)) {
      marketingEvents.createValuableLead()
    }

    if (premise.status === 80 && (premise?.sales_status === 1 || premise?.sales_status === 2)) {
      marketingEvents.createValuableLead()
    }
  }

  return null
}
