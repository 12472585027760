import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import {
  Button,
} from '@material-ui/core'
import classes from './StyledButton.styles'
import { cx } from 'linaria'
import getStepUrl from '../NewOrderFlow/getStepUrl'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { OrderStep } from '../../pages/order/[...step]'
import marketingEvents from '../../utils/marketing/marketingEvents'
import theme from '../../styles/theme'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

export interface ButtonProps {
  children: React.ReactNode;
  url?: string;
  id?: string;
  buttonId?: string;
  order_flow_step?: OrderStep;
  color: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;
  handleClick?: (e?: React.ChangeEvent<{}>) => void;
  isModal?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  blueGradient?: boolean;
  stopPropagation?: boolean;
  isMoreInfo?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  disable_padding?: boolean;
  text?: string;
  modal?: boolean;
  minWidth?: number;
  target?: 'self' | 'blank' | 'parent' | 'top';
}
export default function StyledButton({
  children, url, order_flow_step, color, handleClick, isModal, isFullWidth, disabled,
  className, type = 'button', blueGradient, stopPropagation, isMoreInfo, target, id,
  variant = 'contained', size, disable_padding, minWidth,
}: ButtonProps) {
  const router = useRouter()
  const buttonClass = cx(classes.button, disable_padding && 'disablePadding', isFullWidth && classes.fullWidth, className, blueGradient && classes.blueGradient, isMoreInfo && classes.moreInfoBtn)
  const { premise } = useContext(PremiseContext)
  const { options } = useOrderContext()

  const buttonProps = {
    className: buttonClass,
    color,
    id,
    variant,
    disabled,
    type,
    size,
    disableElevation: true,
    disableRipple: disable_padding,
    ...target && {
      target: `_${target}`,
    },
    ...minWidth &&
      {
        style: {
          minWidth: `${theme.spacing(minWidth)}px`,
        },
      },
  }
  if (handleClick && !isModal) {
    return (
      <Button
        onClick={handleClick}
        {...url && {
          href: url,
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  }

  if (isModal) {
    return (
      <Button
        onClick={(e) => {
          stopPropagation && e.stopPropagation()
          marketingEvents.cta({
            name: children?.toString(),
            url,
            router,
            modal: isModal,
            options,
          })
          router.push(`${router.asPath}${url}`, undefined, { shallow: true })
          if (handleClick) {
            handleClick(e)
          }
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  }

  return (
    <Button
      href={order_flow_step ? getStepUrl(order_flow_step, premise?.sprn) : url}
      onClick={(e) => {
        stopPropagation && e.stopPropagation()
        marketingEvents.cta({
          name: children?.toString(),
          url,
          router,
          modal: isModal,
          options,
        })
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}
