import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  wrapper: css`
    position: relative;
    width: 100%;
  `,
  image: css`
    margin-right: ${theme.spacing(2)}px;
  `,
  closeButton: css`
    cursor: pointer;
    position: absolute;
    top: ${theme.spacing(1)}px;
    right: ${theme.spacing(1)}px;
    &.MuiSvgIcon-root {
      height: ${theme.spacing(2)}px;
      width: ${theme.spacing(2)}px;
    }
  `,
}

export default classes
