import React from 'react'
import { css } from 'linaria'
import {
  Link, List, ListItem, Typography,
} from '@material-ui/core'
import theme from '../../../styles/theme'

interface SiteMapLinkProps {
  text: string;
  url: string;
}

export interface SiteMapSectionProps {
  links: SiteMapLinkProps[];
  title: string;
}

const classes = {
  link: css`
    &.MuiListItem-root {
      padding-top: 0;
    }
  `,
  linkText: css`
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightMedium!};
    }
  `,
}

export default function SiteMapSection({
  links, title,
}: SiteMapSectionProps) {
  return (
    <>
      <Typography color="secondary" variant="h3">{title}</Typography>
      <List>
        {links.map((link: SiteMapLinkProps, i: number) => (
          <ListItem className={classes.link} disableGutters key={i}>
            <Link href={link.url} underline="always">
              <Typography className={classes.linkText} color="primary" component="h4" variant="h3">{link.text}</Typography>
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  )
}
