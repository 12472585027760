import { css } from 'linaria'
import theme, { COLORS } from '../../../../styles/theme'
import fontSizes from '../../../../styles/fontSizes'
import commonStyling from '../../../../styles/commonStyling'

const classes = {
  mobilePackageWrapper: css`
    background: white;
    max-width: ${commonStyling.breakpoints.desktopSmall};
    margin-bottom: ${theme.spacing(2)}px;
    border-radius: ${commonStyling.roundedBorders.all};
    box-shadow: ${commonStyling.boxShadowDark};
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    .mobilePackageShortDescription{
      padding: ${theme.spacing(2, 3, 2, 3)};
    }
    .mobilePackageDetailedDescription{
      padding: ${theme.spacing(2, 2, 3, 2)};
      .mobileLongDescription{
        color: ${COLORS.bodyTextPrimary};
        font-weight: ${theme.typography.fontWeightRegular!};
        text-align: center;
        margin-bottom: ${theme.spacing(4)}px;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      li {
        break-inside: avoid-column;
        display: flex;
        align-items: flex-start;
        color: ${COLORS.bodyTextPrimary};
        padding: 4px 0 4px;
        .MuiTypography-root {
          font-weight: ${theme.typography.fontWeightRegular!};
          padding-left: ${theme.spacing(2)}px;
        }
        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
          position: relative;
          top: 8px;
        }
      }
    }
  `,
  mobilePackageIcon: css`
    &.MuiGrid-root{
      margin-right: ${theme.spacing(3)}px;
    }
  `,
  mobilePackageDescriptionWrapper: css`
    align-items: center;
  `,
  mobilePackageDesciption: css`
      max-width: 75%;
    .MuiTypography-body2{
      font-size: ${fontSizes.body2};
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  mobilePackageDesktopArrow: css`
    &.bundleArrow{
      margin-left: ${theme.spacing(2)}px;
      display: flex;
      align-items: flex-end;
      transform: rotate(90deg);
      transition: transform 0.3s linear;
    }
    &.open{
      transform: rotate(-90deg);
    }
  `,
}

export default classes
