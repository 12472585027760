import { css } from 'linaria'
import theme, {
  COLORS, fontWeights,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

export const classes = {
  tableContainer: css`
    // Using !important because of MuiPaper-elevation1
    margin: ${theme.spacing(1, 1, 0, 0)};
    border-radius: ${theme.spacing(2.5)}px !important;
    border: 1px solid ${COLORS.gray2};
    box-shadow: none !important;
    width: 100% !important;
    overflow-x: auto!important;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(2)}px;
    }

    tbody tr:nth-child(odd) {
      background-color: ${COLORS.gray1};
    }

    th {
      color:  ${COLORS.primary};
      font-size: ${fontSizes.body1};
      font-weight: ${fontWeights.bold};
      line-height: ${theme.spacing(2.6)}px;
      border: 0px;
    }

    td {
      color: ${COLORS.bodyTextPrimary};
      font-size: ${fontSizes.body2};
      font-weight: ${fontWeights.book};
      line-height: ${theme.spacing(2.6)}px; 
      border: 0px;

      &:first-of-type {
        font-weight: ${fontWeights.semibold};
      }
    }

    tr:last-of-type {
      td {
        border-bottom: none;
      }
    }
  `,

  tableCell: css`
    padding: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(0.5, 1.2)}px;
    }
  `,
}
