import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function BasketIcon() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="32.143" height="25" viewBox="0 0 37 25">
        <path className="a" d="M32.143,42.265v.893A1.339,1.339,0,0,1,30.8,44.5h-.446L28.9,54.7A2.679,2.679,0,0,1,26.248,57H5.894a2.679,2.679,0,0,1-2.652-2.3L1.786,44.5H1.339A1.339,1.339,0,0,1,0,43.158v-.893a1.339,1.339,0,0,1,1.339-1.339H5.1l5.959-8.193a1.786,1.786,0,1,1,2.888,2.1L9.513,40.926H22.629L18.2,34.833a1.786,1.786,0,0,1,2.888-2.1l5.959,8.193H30.8A1.339,1.339,0,0,1,32.143,42.265ZM17.411,52.086v-6.25a1.339,1.339,0,0,0-2.679,0v6.25a1.339,1.339,0,1,0,2.679,0Zm6.25,0v-6.25a1.339,1.339,0,0,0-2.679,0v6.25a1.339,1.339,0,1,0,2.679,0Zm-12.5,0v-6.25a1.339,1.339,0,0,0-2.679,0v6.25a1.339,1.339,0,1,0,2.679,0Z" transform="translate(0 -31.997)"/>
      </svg>
    </SvgIcon>
  )
}
