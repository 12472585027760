import React, { useEffect } from 'react'
import RegisterInterestModal from '../RegisterInterestModal/RegisterInterestModal'

export interface CallMeBackModalProps {
  title: string;
  body: string;
  button_text: string;
  success_body: string;
  success_title: string;
  success_button_text: string;
  gdpr_text: string;
  opening_times: string;
  privacy_text: string;
  error_title: string;
  error_body: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  callMeBack?: boolean;
}

const CallMeBackModal = ({
  title,
  body,
  button_text,
  success_title,
  success_body,
  success_button_text,
  gdpr_text,
  opening_times,
  privacy_text,
  error_title,
  error_body,
  showModal,
  setShowModal,
  callMeBack,
}: CallMeBackModalProps) => {
  useEffect(() => {
    setShowModal(showModal)
  }, [showModal])

  return (
    <RegisterInterestModal
      title={title}
      body={body}
      button_text={button_text}
      success_title={success_title}
      success_body={success_body}
      success_button_text={success_button_text}
      gdpr_text={gdpr_text}
      privacy_text={privacy_text}
      error_title={error_title}
      error_body={error_body}
      showModal={showModal}
      setShowModal={setShowModal}
      opening_times={opening_times}
      callMeBack={callMeBack}
    />
  )
}

export default CallMeBackModal

