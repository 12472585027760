import theme from '../../../styles/theme'
import { css } from 'linaria'

const classes = {
  card: css`
    min-height: 470px;
    display: flex;
    flex-direction: column;
    border: none !important;
    cursor: default;
    box-shadow: none !important;
    ${theme.breakpoints.up('md')} {
      max-height: 687px;
      max-width: auto;
    }
  `,
  image: css`
    width: 100%;
    height: 100%;
    aspect-ratio:16/9;
  `,
  text: css`
    *:not(a){
      margin-bottom: 24px;
    }
    p{
      order: 3;
    }
    padding-top: ${theme.spacing(4)}px;
    display: flex;
    padding: 32px 5px 5px 5px !important;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    .MuiLink-root {
      margin-top: auto;  
      padding-top: ${theme.spacing(3)}px;
    }
    .MuiTypography-h1 {
      margin-bottom:  ${theme.spacing(3)}px; 
      order: 1;
    }
  `,
  date: css`
    display:flex;
    order: 2;
  `,
  reverseOnMobile: css`
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column-reverse;
    }
  `,
}

export default classes
