import React from 'react'
import { css } from 'linaria'
import classnames from 'classnames'
import {
  Typography, TypographyVariant,
} from '@material-ui/core'
import theme from '../../styles/theme'

export enum Color {
  inherit= 'inherit',
  initial = 'initial',
  primary ='primary',
  secondary = 'secondary',
  textPrimary = 'textPrimary',
  textSecondary = 'textSecondary',
  error = 'error'
}

export enum Align {
  inherit= 'inherit',
  justify= 'justify',
  center= 'center',
  left= 'left',
  right= 'right'
}

export interface WysiwygProps {
  body: string;
  className?: string;
  variant: TypographyVariant;
  color?: Color;
  contentAlignment?: Align;
  id?: string;
  removeMargins?: boolean;
}

const styles = css`
  &.MuiTypography-root {
    strong, b {
      font-weight: ${theme.typography.fontWeightBold!};
    }
    a {
      color: ${theme.palette.secondary.main};
      font-weight: ${theme.typography.fontWeightBold!}
    }
  }
  p {
    font-weight: ${theme.typography.fontWeightRegular!};
  }
  &.removeMargins {
    p {
      margin: 0;
    }
  }
`

export default function Wysiwyg({
  body, className = '', variant, color = Color.primary, contentAlignment = Align.inherit, id, removeMargins,
}: WysiwygProps) {
  return (
    <Typography className={classnames(styles, className, removeMargins && 'removeMargins')} variant={variant} color={color} component="div" id={id} align={contentAlignment}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: body }}/>
    </Typography>
  )
}
