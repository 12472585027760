import React from 'react'
import { css } from 'linaria'
import {
  Box, Container, Grid, Typography,
} from '@material-ui/core'
import theme from '../../styles/theme'
import SiteMapSection, { SiteMapSectionProps } from './SiteMapSection/SiteMapSection'

export interface SiteMapProps {
  sections: SiteMapSectionProps[];
  title: string;
}

const classes = {
  root: css`
    margin: ${theme.spacing(5)}px 0;
    ${theme.breakpoints.up('md')} {
      padding: 0 ${theme.spacing(2)}px;
    }
  `,
  title: css`
    &.MuiTypography-root {
      font-size: 45px;
      margin-bottom: ${theme.spacing(5)}px;
    }
  `,
}

export default function SiteMap({
  sections, title,
}: SiteMapProps) {
  return (
    <Container>
      <Box className={classes.root}>
        <Typography className={classes.title} color="primary" component="h1" variant="h1">{title}</Typography>
        <Grid container spacing={10}>
          {sections.map((section: SiteMapSectionProps, i: number) => (
            <Grid key={i} item sm={4} xs={12}>
              <SiteMapSection {...section}/>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}
