import { css } from 'linaria'
import theme from '../../../styles/theme'

const classes = {
  trustpilot: css`
    position: relative;
    z-index: 2;
    &.mobileCenter iframe {
      ${theme.breakpoints.down('sm')} {      
        margin-left: auto;
        margin-right: auto;
      }
    }
  `,
}

export default classes
