import React, {
  useEffect, useState, useContext,
} from 'react'
import { Grid } from '@material-ui/core'
import { cx } from 'linaria'
import { useRouter } from 'next/router'
import useMenu from '../useMenu'
import {
  HeaderProps, MenuItem,
} from '../Header'
import classes from './DesktopHeader.styles'
import NavigationTabs from '../../NavigationTabs/NavigationTabs'
import { ContainerWithPadding } from '../../../styles/ContainerWithPadding'
import DesktopMainHeader from './DesktopMainHeader'
import {
  useOrderContext, THREE_GIGA_IDS,
} from '../../NewOrderFlow/OrderContext'
import DesktopSharedHeader from './DesktopSharedHeader'
import { PremiseContext } from '../../PremiseTracker/PremiseContext'
import { TechnologyType } from '../../../api/Addresses'
import { CtaProps } from '../../../utils/commonProps'

export default function DesktopHeader({
  categories,
  shared_menu_items,
  my_account_link,
  className,
  secure,
  mini_basket_error,
  contact_us,
  trustpilot_box,
  inverted,
}: HeaderProps & {className?: string}) {
  const {
    menuIndex,
    setMenuIndex,
    findActiveCategory,
  } = useMenu()

  const [
    activeCategoryIndex,
    setActiveCategoryIndex,
  ] = useState(0)

  const [
    activeMenuItems,
    setActiveMenuItems,
  ] = useState<MenuItem[] | undefined>()

  const [
    activeButton,
    setActiveButton,
  ] = useState<CtaProps | null>()

  const [
    openSubMenu,
    setOpenSubMenu,
  ] = useState(false)

  const [
    hasMiniBasket,
    setHasMiniBasket,
  ] = useState(false)

  const router = useRouter()
  const { options: {
    broadband, bundle,
  } } = useOrderContext()
  const {
    premise, isGettingPremise,
  } = useContext(PremiseContext)

  const isEligibleFor3Giga = premise?.technology === TechnologyType.XGSPON || premise?.technology === TechnologyType.COMBO
  const premiseCanOrder = premise?.accepting === 'order'
  const hideMenuButton = THREE_GIGA_IDS.includes(broadband?._id!) ? premiseCanOrder && broadband && isEligibleFor3Giga : premiseCanOrder && broadband

  const handleCategoryClick = (i: number, goToCategoryPage = false) => {
    setActiveCategoryIndex(i)

    const clickedOnCategory = categories?.[i]
    const clickedOnCategoryUrl = clickedOnCategory?.url

    if (goToCategoryPage && clickedOnCategoryUrl) {
      router.push(clickedOnCategoryUrl)
      return
    }

    const categoryHasMiniBasket = Boolean(clickedOnCategory?.mini_basket)
    const categoryMenuItems: MenuItem[] | undefined = clickedOnCategory?.menu_items

    setHasMiniBasket(categoryHasMiniBasket)

    if (!categoryHasMiniBasket) {
      setActiveButton(clickedOnCategory?.button)
    }

    return categoryMenuItems && setActiveMenuItems(categoryMenuItems)
  }

  const handleSharedMenuClick = (navItem: MenuItem) => {
    if (navItem.url) {
      return navItem.open_new_window ? window.open(navItem.url, '_blank', 'noreferrer') : router.push(navItem.url)
    }

    return null
  }

  useEffect(() => {
    const categoryHasMiniBasket = categories?.[activeCategoryIndex]?.mini_basket

    if (!isGettingPremise && categoryHasMiniBasket) {
      const categoryButton = hideMenuButton || bundle ? null : categories?.[activeCategoryIndex]?.button
      setActiveButton(categoryButton)
    }
  }, [isGettingPremise])

  useEffect(() => {
    const currentCategory = router.asPath.split('/')[1].split('#')[0]

    if (shared_menu_items) {
      const activeSharedMenuItem = shared_menu_items.find(sharedMenuItem => {
        const sharedMenuItemUrl = sharedMenuItem?.url?.replace('/', '')
        return sharedMenuItemUrl === currentCategory
      })

      if (activeSharedMenuItem) {
        setActiveButton(null)
        setHasMiniBasket(false)
      }

      if (activeSharedMenuItem?.sub_menu_items?.length) {
        setActiveMenuItems(activeSharedMenuItem.sub_menu_items)
        return setActiveCategoryIndex(-1)
      }
    }

    if (categories) {
      const currentCategoryIndex = findActiveCategory(categories, currentCategory)
      const clickedOnCategory = categories?.[currentCategoryIndex]

      if (clickedOnCategory && clickedOnCategory.title === currentCategory) {
        return
      }

      return handleCategoryClick(currentCategoryIndex)
    }
  }, [
    router.asPath,
    categories,
    shared_menu_items,
  ])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.headerTop}>
        <ContainerWithPadding>
          <Grid container justify="space-between">
            <Grid item md={4}>
              {categories &&
                <NavigationTabs
                  className={classes.navTabs}
                  tabs={categories}
                  activeTab={activeCategoryIndex}
                  handleClick={handleCategoryClick}
                  tabsVariant="fullWidth"
                />}
            </Grid>
            {shared_menu_items &&
            <DesktopSharedHeader
              sharedMenuItems={shared_menu_items}
              my_account_link={my_account_link}
              handleSharedMenuClick={handleSharedMenuClick}
            />}
          </Grid>
        </ContainerWithPadding>
      </div>
      <DesktopMainHeader
        secure={secure}
        activeMenuItems={activeMenuItems}
        setMenuIndex={setMenuIndex}
        menuIndex={menuIndex}
        trustpilot_box={trustpilot_box}
        contactUs={contact_us}
        button={activeButton}
        miniBasket={hasMiniBasket}
        miniBasketError={mini_basket_error}
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
        inverted={inverted}
      />
    </div>
  )
}
