import {
  useEffect, useContext,
} from 'react'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { PremiseDetail } from '../../api/Addresses'
import { PremiseContext } from './PremiseContext'

export default function PremiseTracker() {
  const { premise } = useContext(PremiseContext)

  const resolvedPremise = premise ? resultFromPremise(premise) : null

  useEffect(() => {
    async function trackPremise() {
      if (resolvedPremise) {
        marketingEvents.foundAddress(resolvedPremise)
      }
    }

    trackPremise()
  }, [resolvedPremise])

  return null
}

function resultFromPremise(premise: PremiseDetail) {
  if (!premise.is_in_london) {
    return 'not_in_london'
  }

  return premise.accepting
}
