import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  wrapper: css`
      max-width: 100%;
      margin: auto;
      ${theme.breakpoints.down('xs')} {
        height: auto !important;
      }
  `,
}

export default classes
