import { css } from 'linaria'
import theme, {
  COLORS, fontWeights,
} from '../../../styles/theme'
import fontSizes from '../../../styles/fontSizes'
import commonStyling from '../../../styles/commonStyling'

const classes = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `,
  alignImageLeft: css`
    [class*="heroContainer"] {
      order: 2;
      justify-content: flex-start;

      ${theme.breakpoints.up('md')} {
        order: 2;
      }
    }
    [class*="imagesContainer"] {
      order: 1;

      ${theme.breakpoints.up('md')} {
        order: 1;
      }
    }
    [class*="textImage"] {
      left: 10px;
      right: initial;
      ${theme.breakpoints.up('sm')} {
        left: 30px;
        right: initial;
      }
    }
  `,
  heroTextContainer: css`
    &.MuiGrid-item {
      margin: 0 auto;
      left: initial;
      padding-top: 20px;

      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(3, 2, 3, 2)};
      }
    }
  `,
  heroBundlesContainer: css`
    position: relative;
    @media only screen and (min-width: 960px) {
      left: ${theme.spacing(4)}px;
    }
    @media only screen and (min-width: 1270px) {
      left: auto;
    }
  `,
  heroContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    padding: ${theme.spacing(1, 2, 5)};
    background-color: ${theme.palette.warning.light};
    color: ${theme.palette.primary.main};
    position: relative;

    ${theme.breakpoints.up('md')} {
      order: 1;
      flex: 1;
      margin: 0 auto;
      justify-content: flex-end;
      min-height: 715px;
    }
  `,
  heroInfo: css`
    position: absolute;
    color: white
  `,
  heroHeading: css`
    padding-top: ${theme.spacing(2)}px;
    text-align: center;
    margin: 0 auto;
    span {
      display: block;
    }
    ${theme.breakpoints.up('md')} {
      padding-top: 0;
      font-size: ${fontSizes.h1} !important;
    }
  `,
  heroAddress: css`
    padding-top: ${theme.spacing(1)}px;
    font-weight: 200;
    &.MuiTypography-body1 {
      font-weight: 200;
      font-size: ${fontSizes.body3};
      padding-right: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.up('xs')} {
      &.MuiTypography-body1 {
      font-size: ${fontSizes.body1};
      padding-right: 0;
      }
    }
  `,
  packageName: css`
    &.MuiTypography-h2 {
      font-size: ${fontSizes.h4};
      font-weight: 300 !important;
      padding-top: ${theme.spacing(3)}px;
      margin-bottom: ${theme.spacing(2)}px;
      span {
        font-size: ${fontSizes.h4};
        font-weight: ${theme.typography.fontWeightBold!};
        text-transform: uppercase;
      }
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h3};
        span {
          font-size: ${fontSizes.h3};
        }
      }
    }
  `,
  productNamePrice: css`
    display: flex;
    flex-direction: column;
  `,
  priceContainer: css`
    > .MuiTypography-root + .MuiTypography-root {
      margin-left: ${theme.spacing(2)}px;
    }
  `,
  priceContainerBundles: css`
    img {
      max-width: 140px;
    }
    ${theme.breakpoints.up('md')} {
      img {
        display: none;
      }
    }
  `,
  contractContainer: css`
    font-family: ${theme.typography.fontFamily!};
    padding-left: ${theme.spacing(1)}px;
    text-align: center;

    ${theme.breakpoints.up('md')} {
      flex: 1;
    }
  `,
  prices: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 80px;
  `,
  oldPrice: css`
    text-decoration: line-through;
    font-size: ${fontSizes.body1};
    font-weight: ${theme.typography.fontWeightBold!};
  `,
  currentPrice: css`
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: ${theme.typography.h2.fontSize!};
    font-weight: ${theme.typography.fontWeightBold!};
    color: ${COLORS.rose};

    span {
      font-size: 24px;
      font-weight: 400;
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  monthContract: css`
    color: ${COLORS.bodyTextPrimary};
    &.MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  description: css`
    &.MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightRegular!};
      color: ${COLORS.bodyTextPrimary};
      width: 95%;
      margin-top: ${theme.spacing(3)}px;
      margin-bottom: ${theme.spacing(1)}px;
      text-align: left;
    }
  `,
  featureList: css`
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      position: relative;
      break-inside: avoid-column;
      display: flex;
      align-items: center;
      color: ${COLORS.bodyTextPrimary};
      padding: 4px 0 4px;
      .MuiTypography-root {
        font-weight: ${theme.typography.fontWeightRegular!};
        padding-left: ${theme.spacing(3.7)}px;
        font-size: ${fontSizes.body2};
      }
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
        position: absolute;
        top: 9px;
        fill: ${COLORS.rose};
      }
    }
    ${theme.breakpoints.up('md')} {
      ul {
        column-count: 2;
        padding: 0;
      }
    }
  `,
  devicesImage: css`
    width: 50%;
    filter: brightness(0) invert(1);
    ${theme.breakpoints.up('xs')} {
      margin: ${theme.spacing(1)}px 0 0 ${theme.spacing(2)}px;
    }
  `,
  button: css`
    width: 100%;
    &.MuiButton-root {
      margin-top: ${theme.spacing(4)}px;
    }
  `,
  buttonContainer: css`
    align-items: center;
  `,
  bottomButtonContainer: css`
    padding-top: ${theme.spacing(3)}px;
    .MuiButton-root {
      ${theme.breakpoints.down('xs')} {
        margin: 0 auto;
        display: flex;
      }
      .MuiTypography-noWrap {
        white-space: nowrap !important;
      }
    }
    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacing(4)}px;
      flex-direction: row;
    }
    ${theme.breakpoints.down('sm')} {
      justify-content: center;
    }
  `,
  tooltip: css`
    pointer-events: auto;
    .MuiTooltip-arrow {
      color: white !important;
      left: 10px !important;
    }
    &.MuiTooltip-tooltip {
      max-width: 85vw;
      box-shadow: ${commonStyling.boxShadowLight};
      background-color: white;
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightLight!};
      font-size: ${fontSizes.body3};
      position: relative;
      border-radius: ${commonStyling.roundedBorders.all};
      margin: ${theme.spacing(1)}px 0;
      ${theme.breakpoints.up('sm')} {
        max-width: 300px;
      }
      ${theme.breakpoints.up('md')} {
        margin: 0 ${theme.spacing(2)}px;
        .MuiTooltip-arrow {
          left: .2px !important;
        }
      }
    }
  `,
  tooltipText: css`
    color: ${theme.palette.primary.main};
    padding: ${theme.spacing(1, 2)};
    .MuiTypography-h5 {
      margin-bottom: ${theme.spacing(1)}px;
    }
    .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
      height: 22px;
      width: 22px;
      position: absolute;
      right: ${theme.spacing(1)}px;
      top: 5px;
      cursor: pointer;
    }
    ul {
      list-style: none;
      padding: 0 !important;
      margin-top: 0;
      .MuiTypography-body2 {
        margin-top: 4px;
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
  `,
  infoIcon: css`
    position: relative;
    top: 4px;
    cursor: pointer;
    margin-left: ${theme.spacing(1)}px;
    &.MuiSvgIcon-root {
      height: 24px;
      width: 24px;
      font-size: ${fontSizes.h2};
    }
    ${theme.breakpoints.up('md')} {
      top: 0;
    }
  `,
  broadbandWrapper: css`
    flex-direction: column;
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      align-items: center;
    }
  `,
  broadbandPackageInfo: css`
    img {
      display: none;
    }
    .MuiTypography-body2 {
      margin-top: ${theme.spacing(1)}px;
      font-weight: ${theme.typography.fontWeightLight!} !important;
    }
    .MuiGrid-container {
      width: auto;
    }
    ${theme.breakpoints.up('md')} {
      padding-left: ${theme.spacing(2)}px;
      .MuiTypography-h4 {
        font-size: ${fontSizes.h3};
      }
      img {
        text-align: left;
        margin: ${theme.spacing(1, 0, 0)};
        margin-right: ${theme.spacing(2)}px;
        display: block;
        max-width: 140px;
      }
    }
  `,
  addOnInfo: css`
    margin-top: ${theme.spacing(3)}px;
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiSvgIcon-root {
      font-size: ${commonStyling.svgFontSize};
    }
    .MuiGrid-grid-md-2 > .MuiSvgIcon-root:first-child {
      height: 20px;
      width: 20px;
      margin-right: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up('md')} {
      .MuiGrid-grid-md-2 {
        max-width: 20%;
        flex-basis: 20%;
      }
      .MuiSvgIcon-root:first-child {
        margin: ${theme.spacing(0, 2, 0, 1)};
      }
    }
    ${theme.breakpoints.up('lg')} {
      .MuiGrid-grid-md-2 {
        max-width: 15%;
      }
    }
  `,
  addOnText: css`
  ${theme.breakpoints.up('md')} {
    padding-left: ${theme.spacing(2)}px;
  }
  `,
  contractBundlesContainer: css`
    padding-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(1)}px;
    .MuiGrid-container {
      border-top: 1px solid white;
      border-top: 1px solid rgba(255, 255, 255, .33);
      ${theme.breakpoints.up('md')} {
        max-width: 70%;
      }
    }
  `,
  mobileImageContainer: css`
    width: 100%;
    position: relative;
    height: 210px;
    img:not(:last-of-type) {
      top: 120px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (min-width: 426px) {
      height: 300px;
    }
    ${theme.breakpoints.up('sm')} {
      height: 400px;
    }
  `,
  image: css`
    order: 1;
    position: relative;
    width: 100%;
    min-height: 100%;

    ${theme.breakpoints.up('md')} {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      order: 2;
      min-width: 100% !important;
      height: 100% !important;
      min-height: 100% !important;
    }
  `,
  imagesContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    min-height: 100%;

    ${theme.breakpoints.up('md')} {
      order: 2;
      flex-direction: row;
      flex: 1;
    }
  `,
  textImage: css`
    position: absolute;
    bottom: 5%;
    right: 10px;
    width: auto;
    max-height: 115px;
    ${theme.breakpoints.up('sm')} {
      max-height: 150px;
      right: 30px;
    }
    ${theme.breakpoints.up('md')} {
      max-height: 70%;
    }
  `,
  animatedButtonIcon: css`
    .MuiButton-label {
      img {
        margin-left: 11px;
        position: relative;
        top: 1px;
        animation: bounce-icon .4s infinite alternate;
      }
      @keyframes bounce-icon {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(-5px);
        }
      }
    }
  `,
  flagTitle: css`
  display:flex;
  align-items:center;
  cursor:pointer;
  gap: 3px;
  svg{
    padding-bottom: 3px;
  }
  `,
  flagsContainer: css`
    justify-content: center;
  `,
  flag: css`
    .MuiTypography-root {
      background: #F7DDBB;
      border-radius: ${commonStyling.roundedBorders.all};
      height: fit-content;
      padding: ${theme.spacing(1, 2)};
      width: fit-content;
      font-weight: ${fontWeights.semibold}
      margin: 0 auto;
      span {
        display: inline-block;
      }
    }
    .MuiTooltip-popper {
      margin-right: 20%;
      ${theme.breakpoints.up('sm')} {
      margin-right: ${theme.spacing(4)}px;
    }
    }
    .MuiTooltip-touch{
      padding:4px 8px;
    }
  `,
  flagDefault: css`
  .MuiTypography-root {
    background: ${COLORS.white};
    font-weight: ${theme.typography.fontWeightBold!}
    p{
      font-weight: ${theme.typography.fontWeightBold!}
    }
  }
  `,

  flagWarning: css`
    .MuiTypography-root {
      background: ${COLORS.error};
      color: ${COLORS.white}
    }
  `,
  flagYellow: css`
    .MuiTypography-root {
      background: ${COLORS.tertiary};
      color: ${COLORS.primary};
    }
  `,
  customTooltip: css`
    background: white !important;
    box-shadow: ${commonStyling.boxShadowDark};
    border-radius: ${commonStyling.roundedBorders.all} !important;
    top: -15px;
    .MuiTypography-root {
      color: ${COLORS.bodyTextPrimary} !important;
      background: white !important;
      font-weight: ${fontWeights.semibold};
      font-size: ${fontSizes.body2};
      padding: ${theme.spacing(2, 3)};
    }
    p{
      max-width: 80%;
    }
    a{
      display:inline-block;
      margin-top:10px;
      font-size:  ${fontSizes.body3};
      color: ${COLORS.bodyTextPrimary}
      font-weight: ${fontWeights.book}
    }
    ${theme.breakpoints.up('md')} {
      top: 0;
    }
  `,
  customArrow: css`
    color: white !important;
  `,
  tooltipCloseIcon: css`
    position: absolute;
    top: 12px;
    right: 12px;
    color: ${theme.palette.primary.main};
  `,
  bundlesWrapper: css`
    margin-top: ${theme.spacing(4)}px;
  `,
  discountDetails: css`
    &.MuiTypography-root {
      color: ${COLORS.bodyTextPrimary};
      font-size: 15px;
      font-weight: normal;
    }
  `,
  bundleDetails: css`
    padding-top:  ${theme.spacing(1)}px;
    text-align: center;
    
    ${theme.breakpoints.up('md')} {
      text-align: left;
    }
  `,
  alignTextLeft: css`
    .MuiTypography-root {
      text-align: left;
    }
 `,
  tag: css`
    min-height: max-content;
    display: block;
    margin: 1.5rem auto;
    max-width: 87vw;
    width: 100%;
    padding: 5px 6px 7px;
    text-align: center;

    ${theme.breakpoints.up('md')} {
      max-width: 420px;
    }

    ${theme.breakpoints.up('lg')} {
      max-width: 100%;
    }

    p {
      margin: 0;
    }

    [class*='MuiTypography-'] {
      color: white;
      font-weight: 500;
    }
  `,
}

export default classes
