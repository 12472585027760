import { AxiosInstance } from 'axios'
export interface ReferralGenerateResponse {
  referralCode: string;
  productId: string;
  customerId: string;
  message: string;
}

export interface ReferralValidateResponse {
  data: {
    code: string;
    message: string;
    messages: string[];
  };
  status: number;
}

export default function Hobs(client: AxiosInstance) {
  async function referralGenerate({
    email,
  }: {email: string}) {
    try {
      const { data } = await client.post<ReferralGenerateResponse>('referral/v1/0.0/generate', {
        email,
      })
      return data
    } catch (err) {
      return err
    }
  }

  async function referralValidateId({
    customer_id,
  }: {customer_id: string}) {
    try {
      const { data } = await client.get<ReferralValidateResponse>('referral/v1/0.0/validate?customerId=' + customer_id)
      return data
    } catch (err) {
      return err.response
    }
  }

  return {
    referralGenerate,
    referralValidateId,
  }
}
