import React from 'react'
import { MsDynamicsHeaderScripts } from './MsDynamicsHeadScripts'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import { Container } from '@material-ui/core'

function MsDynamicsUnsubscribe() {
  return (
    <SectionWithMargin>
      <Container>
        <MsDynamicsHeaderScripts/>
      </Container>
    </SectionWithMargin>
  )
}

export default MsDynamicsUnsubscribe
