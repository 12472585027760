import React, {
  useEffect,
  useState,
} from 'react'
import {
  Box, CircularProgress, Grid, Typography,
} from '@material-ui/core'
import Calendar from '../../../../Calendar/Calendar'
import {
  Timeslot, TimeslotData,
} from '../../../../../api/BookingCalendar'
import createCRMApiClient from '../../../../../api/CRMApi'
import logger from '../../../../../utils/logger'
import StyledButton from '../../../../StyledButton/StyledButton'
import { formatTimeForTimeslotData } from '../../../../Calendar/calendarUtils'
import marketingEvents from '../../../../../utils/marketing/marketingEvents'
import { useForm } from 'react-hook-form'
import WrapperWithSection from '../../../../Wrapper/WrapperWithSection'
import { classes } from '../RescheduleInstallation/RescheduleInstallation.styles'
import { PremiseDetail } from '../../../../../api/Addresses'
import { useOrderContext } from '../../../OrderContext'

interface CreateAppointmentProps {
  account: any;
  fullPremise: PremiseDetail;
}
export interface CalendarPremiseDetailsProps {
  installType: null | number;
  type: string;
  borough: string;
}

const CreateAppointment = ({
  account, fullPremise,
}: CreateAppointmentProps) => {
  const [
    timeslot,
    setTimeslot,
  ] = useState<Timeslot | null>(null)

  const [
    error,
    setError,
  ] = useState('')

  const [
    success,
    setSuccess,
  ] = useState(false)

  const [
    disableButton,
    setDisableButton,
  ] = useState(false)

  const {
    options,
  } = useOrderContext()

  useEffect(() => {
    setDisableButton(false)
    setError('')
  }, [timeslot])

  useEffect(() => {
    marketingEvents.appointmentEvent({
      eventName: 'create_appointment_login',
      options,
      accountNumber: payload?.accountNumber,
      borough: fullPremise?.borough,
      broadbandProduct: payload?.broadbandProduct,
      sprn: fullPremise?.sprn,
      orderId: payload?.orderId,
    })
  }, [])

  const methods = useForm()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  const calendarPremiseDetails: CalendarPremiseDetailsProps = {
    installType: fullPremise.install_type || fullPremise.installType,
    type: fullPremise.type || account.ViewOrderDetailsResponseMessage.order.OrderLines.SubscriberOrderItem.Subscriber.SubscriberProducts[0].productCategory,
    borough: fullPremise.borough || account.ViewOrderDetailsResponseMessage.order.CommonInformation.Customer.CustomerContact.ContactMedium.Address.stateOrProvince,
  }

  const subscriberProducts = account.ViewOrderDetailsResponseMessage.order.OrderLines.SubscriberOrderItem.Subscriber.SubscriberProducts
  const commonInformation = account.ViewOrderDetailsResponseMessage.order.CommonInformation
  const tv = subscriberProducts.find(
    (item: any) => item.productSubType === 'TV')
  const voice = subscriberProducts.find(
    (item: any) => item.productSubType === 'VOICE')
  const wier = subscriberProducts.find(
    (item: any) => item.productSubType === 'WIER')
  const serviceType = wier ? 'Premium' : 'Standard'
  const broadbandProduct = subscriberProducts.find(
    (item: any) => item.productType === 'SPEED')
  const broadbandProductSpeed = parseFloat(broadbandProduct.ProductCharacteristicValue[0].ProductSpecCharacteristic.ProductSpecCharacteristicValue.value)
    .toString()

  const payload = {
    customerId: commonInformation.Customer.CustomerID,
    orderId: account.ViewOrderDetailsResponseMessage.order.OrderHeader.orderID,
    firstName: commonInformation.Customer.Party.IndividualName.givenName,
    lastName: commonInformation.Customer.Party.IndividualName.familyName,
    email: commonInformation.Customer.CustomerContact.ContactMedium.primaryEmailID,
    contactPhone: commonInformation.Customer.CustomerContact.ContactMedium.telephoneNumber,
    alternativePhone: commonInformation.Customer.CustomerContact.ContactMedium.eveningContactNumber,
    accountNumber: commonInformation.CustomerAccount.CustomerAccountID,
    tvFlag: Boolean(tv),
    voiceFlag: Boolean(voice),
    serviceType,
    broadbandProduct: broadbandProductSpeed,
  }

  const onSubmit = handleSubmit(async () => {
    const client = createCRMApiClient()
    const errorName = 'Installation error'

    if (!timeslot) {
      const errorMsg = 'Please select an appointment.'
      marketingEvents.error(options, 'ERR-INSTALLATION-1', errorName, errorMsg)
      setError(errorMsg)
      setDisableButton(true)
      return
    }

    const getTime = (date: string) => date.split('T')[1].split(':00.')[0]
    const getFullTime = getTime(timeslot.startDateTime) + ' - ' + getTime(timeslot.endDateTime)
    marketingEvents.selectInstallation('select_installation', options, timeslot?.date!, getFullTime)

    const result = await client.workOrder.createWorkOrder(fullPremise,
      timeslot,
      payload)

    if ('error' in result) {
      const errorMsg = 'Unable to reserve the appointment, please contact customer service on 08000820770'
      logger.error(result.message, result.error)
      marketingEvents.error(options, 'ERR-INSTALLATION-1', errorName, errorMsg)
      setError(errorMsg)
      setDisableButton(true)
      return
    }

    marketingEvents.appointmentEvent({
      eventName: 'create_appointment_submit',
      options,
      accountNumber: payload?.accountNumber,
      appointmentWindowId: timeslot?.appointmentWindowId,
      date: timeslot?.date,
      borough: fullPremise?.borough,
      broadbandProduct: payload?.broadbandProduct,
      sprn: fullPremise?.sprn,
      orderId: payload?.orderId,
    })
    setSuccess(true)
  })

  const formatTimeslotDetails = (timeslot: Timeslot | TimeslotData) => {
    const {
      startDateTime,
    } = timeslot
    const start = new Date(startDateTime)
    const time = formatTimeForTimeslotData(timeslot)
    return `${start.toLocaleDateString('en-GB')} - ${time}`
  }

  const FormSuccessZinier = ({ timeslot }: { timeslot: TimeslotData | Timeslot }) => (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={2} height={300}>
      <Typography color="primary" align="center" variant="h3">Appointment Scheduled</Typography>
      <Box mt={3}>
        <Typography color="primary" align="center" variant="body1">Your appointment has successfully been scheduled for:</Typography>
        <br/>
        <Typography color="primary" variant="body1" align="center">{formatTimeslotDetails(timeslot)}</Typography>
      </Box>
    </Box>
  )
  const FormRejectZinier = () => (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={2} height={300}>
      <Typography color="primary" align="center" variant="h3">There&#39;s an issue with creating your appointment</Typography>
      <Box mt={3}>
        <Typography color="primary" align="center" variant="body1">Please retry later; if you still have issues please get in touch with our frieandly team valling 0800 082 0770 Monday to Friday, 8am - 9pm, weekends and bank holidays, 9am - 7pm.</Typography>
      </Box>
    </Box>
  )

  const renderCalendar = () => {
    return (
      <Calendar
        fullPremise={calendarPremiseDetails}
        onTimeslotChange={(t: Timeslot) => {
          setTimeslot(t)
        }}
        broadbandProduct={broadbandProductSpeed}
      />
    )
  }

  const renderInstallationLayout = () => {
    if (error) {
      return (
        <FormRejectZinier/>
      )
    }

    if (timeslot && success) {
      return (
        <FormSuccessZinier timeslot={timeslot}/>
      )
    }

    return (
      <Box component="form" onSubmit={onSubmit} mb={6}>
        <Box display="flex" justifyContent="center" textAlign="center" py={2} mb={8} className={classes.title}>
          <Typography variant="h1" color="primary">Book your installation appointment</Typography>
        </Box>
        {renderCalendar()}
        {error && <Box mt={3}><Typography align="center" color="error">{error}</Typography></Box>}
        {timeslot && formatTimeslotDetails &&
        <Box mt={3} className={classes.selectedTimeslot}>
          <Typography variant="body1" align="left">
            <b>Selected installation date:</b>
            <span>{timeslot && formatTimeslotDetails(timeslot)}</span>
          </Typography>
        </Box>}
        <StyledButton className={classes.button} color="primary" type="submit" disabled={isSubmitting || disableButton}>
          Confirm
          {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
        </StyledButton>
      </Box>
    )
  }

  return (
    <WrapperWithSection>
      <Grid container justify="center">
        <Grid item xs={12} md={10} lg={8}>
          {renderInstallationLayout()}
        </Grid>
      </Grid>
    </WrapperWithSection>
  )
}

export default CreateAppointment
