export const fontSizes = {
  /* 45px */
  h1: '2.813rem',
  /* 35px */
  h2: '2.188rem',
  /* 30px */
  h3: '1.875rem',
  /* 25px */
  h4: '1.563rem',
  /* 18px */
  h5: '1.125rem',
  /* 16px */
  h6: '1rem',
  /* 18px */
  body1: '1.125rem',
  /* 15px */
  body2: '0.9375rem',
  /* 14px */
  body3: '0.875rem',
  /* 12px */
  subtitle2: '0.75rem',
  /* 17px */
  subtitle3: '1.0625rem',
  /* 45px */
  title: '2.813rem',
  /* 10px */
  smallText: '0.625rem',
  /* 12px */
  caption1: '0.75rem',
  /* 8px */
  extraSmallText: '0.5rem',
}
export default fontSizes
