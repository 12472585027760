import {
  captureException, withScope,
} from '@sentry/browser'

function createLogger() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function errorLogger(message: string, err: any, data?: any, tags?: { [key: string]: string }) {
    withScope(scope => {
      if (data) {
        scope.setExtra('context', data)
      }

      scope.setExtra('error', err)

      if (tags) {
        scope.setTags(tags)
      }

      scope.setFingerprint([
        message,
        '{{ default }}',
      ])
      captureException(err)
    })
  }

  return {
    error: errorLogger,
  }
}

const logger = createLogger()

export default logger
