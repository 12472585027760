import React, {
  useEffect, useRef,
} from 'react'
import { Box } from '@material-ui/core'
import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'
import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'
import { useGeneralContext } from '../GeneralContext/GeneralContext'
import { encryptString } from '../../utils'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

interface FooterLink {
    url: string;
    text: string;
 }

interface SocialLink {
    url: string;
    src: string;
    image_alt_text: string;
 }

 interface Address {
   line1: string;
   line2: string;
   email?: string;
   phone?: string;
 }

export interface FooterProps {
  company: FooterLink[];
  help: FooterLink[];
  social: SocialLink[];
  address: Address;
  logo: string;
  logo_alt_text: string;
  show_back_to_top: boolean;
  company_column_title?: string;
  help_column_title?: string;
  social_column_title?: string;
  installation_boroughs?: string;
  reschedule_boroughs?: string;
  trustpilot_widgets?: string;
  providers?: string;
  trustpilot_id?: string;
  default_raf_id?: string;
}

export default function Footer(props: FooterProps) {
  const footerRef = useRef<HTMLDivElement | null>(null)

  const [isVisible] = useIntersectionObserver({
    elementRef: footerRef,
  })

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  const {
    options, setOptions,
  } = useOrderContext()

  useEffect(() => {
    setGeneralOptions({
      ...generalOptions,
      showStickyCheckAvailability: !isVisible,
    })
  }, [isVisible])

  useEffect(() => {
    setOptions({
      ...options,
      defaultRafId: encryptString(props.default_raf_id!),
    })
  }, [])

  useEffect(() => {
    setGeneralOptions({
      ...generalOptions,
      installationBoroughs: props.installation_boroughs?.toLowerCase().replace(/\s/g, '').split('|'),
      rescheduleBoroughs: props.reschedule_boroughs?.toLowerCase().replace(/\s/g, '').split('|'),
      providers: props.providers?.split('|'),
      trustpilotWidgets: props.trustpilot_widgets?.split(/\r?\n/).map((l: any) => l.split('|')),
      trustpilotId: props.trustpilot_id,
    })
  }, [props])

  return (
    <footer ref={footerRef} id="footer">
      <Box
        mt={8}
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        <DesktopFooter {...props}/>
      </Box>
      <Box
        mt={4}
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <MobileFooter {...props}/>
      </Box>
    </footer>
  )
}
