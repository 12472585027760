import React from 'react'
import {
  css, cx,
} from 'linaria'
import theme from './theme'
import classnames from 'classnames'
import { Container } from '@material-ui/core'

const classes = {
  container: css`
    ${theme.breakpoints.up('md')} {
      padding: 0 ${theme.spacing(8)}px !important;
    }
  `,
}

export const ContainerWithPadding = ({
  children, className, id,
}: { children: React.ReactNode; className?: string; id?: string }) => {
  return (
    <Container id={id} className={classnames(cx(classes.container, className))}>
      {children}
    </Container>
  )
}
