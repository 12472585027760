import React from 'react'
import {
  Container, Grid, Link,
} from '@material-ui/core'
import { PostListResponse } from '../../../utils/blog'
import LayoutItems from '../LayoutItems/LayoutItems'
import CustomPagination from '../CustomPagination/CustomPagination'
import { css } from 'linaria'
import theme from '../../../styles/theme'
import fontSizes from '../../../styles/fontSizes'

export interface ItemOverviewProps {
  readonly item_data: PostListResponse;
  readonly link_text?: string;
  type_items: string;
  page_size_items: number;
  currentPage?: number;
  selectedCategory?: string;
}

const classes = {
  container: css`
    padding: ${theme.spacing(6, 0)};
    .MuiGrid-item {
      padding: ${theme.spacing(2)}px;
      display: flex;
      justify-content: center;
    }
  `,
  link: css`
    display: block !important;
    font-size: ${fontSizes.body1};
    font-weight: ${theme.typography.fontWeightBold!};
    margin: ${theme.spacing(1)}px auto !important;
  `,
}

export default function ItemOverview({
  item_data,
  link_text,
  type_items,
  page_size_items,
  currentPage,
  selectedCategory,
}: ItemOverviewProps) {
  const {
    data, meta,
  } = item_data
  const totalPages = Math.ceil(meta.count / page_size_items)

  if (data.length === 0) {
    return null
  }

  return (
    <Container classes={{ root: classes.container }} id="blog-articles">
      <Grid container direction="column">
        <Grid container>
          <LayoutItems post_items={data} type_items={type_items}/>
        </Grid>
        {totalPages > 1 && (
          <Grid item xs>
            <CustomPagination
              currentPage={currentPage || 1}
              totalPages={totalPages}
              selectedCategory={selectedCategory || 'blog-post'}
            />
          </Grid>
        )}
        <Link
          underline="always"
          color="secondary"
          className={classes.link}
          href="/community-connect/blog/all-articles"
        >
          {link_text}
        </Link>
      </Grid>
    </Container>
  )
}
