import React from 'react'
import Wysiwyg from '../../Wysiwyg/Wysiwyg'
import styled from 'styled-components'
import classes from './MediaPageHeroRevamped.styles'

export interface HighlightingTagProps {
  readonly wysiwyg: string;
  readonly background_color?: string;
}

interface HighlightingTagStylesProps {
  backgroundColor?: string;
}

export default function HighlightingTag({
  wysiwyg,
  background_color,
}: HighlightingTagProps) {
  const Tag = styled.div<HighlightingTagStylesProps>`
    background-color: ${(props: HighlightingTagStylesProps) => (props.backgroundColor ? props.backgroundColor : '#370050')};
  `
  return (
    <Tag className={classes.tag} backgroundColor={background_color}>
      <Wysiwyg body={wysiwyg} variant="body1"/>
    </Tag>
  )
}
