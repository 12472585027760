import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  wrapper: css`
    text-align: left;
    margin-top: ${theme.spacing(4.25)}px;
    max-width: ${theme.spacing(64.37)}px;
    margin-left: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('md')}{
      margin-top: 0;
      margin-left: 0;
    }
    h1 {
      margin-bottom: ${theme.spacing(5)}px;
      max-width: 100%;
      font-size: ${fontSizes.h2};
    }
    div[class*='mediaWrapper'] {
      margin-bottom: 1.5rem;
    }
    p {
      font-size: ${fontSizes.body1};
      font-weight: ${theme.typography.fontWeightRegular!};
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  phrasesWrapper: css`
    padding: ${theme.spacing(1)}px;
    list-style-type: none;

    svg {
      color: ${COLORS.secondary};
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  phrase: css`
    font-size: ${fontSizes.body1};
    font-weight: ${theme.typography.fontWeightBold!};
    color: ${COLORS.primary};
    padding: ${theme.spacing(1)}px;
    padding-left: ${theme.spacing(4)}px;
    align-items: left;
    text-align: left;
    position: relative;
  `,
  imageWrapper: css`
    img {
      max-height: 50px;
    }
  `,
  body: css`
  display:flex;
  flex-direction:column;
  gap: ${theme.spacing(2)}px;
  a{
    color:${COLORS.secondary};
    font-weight:${theme.typography.fontWeightBold!};
  }
`,
}

export default classes
