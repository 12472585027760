import React from 'react'
import { css } from 'linaria'
import theme from './theme'
import classnames from 'classnames'

const classes = {
  wrapper: css`
    margin: ${theme.spacing(5)}px 0;
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(7)}px 0;
    }
  `,
  topMargin: css`
    margin-top: ${theme.spacing(5)}px;
    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(7)}px;
    }
  `,
  noMargins: css`
    margin: 0;
    ${theme.breakpoints.up('md')} {
      margin:0;
    }
  `,
}

export const SectionWithMargin = ({
  children, className, noMargins, id, hasTop,
}: {
  children: React.ReactNode;
  className?: string;
  noMargins?: boolean;
  id?: string;
  hasTop?: boolean; }) => {
  const wrapperClassName = () => {
    if (noMargins) {
      return classnames(classes.noMargins, className)
    }

    if (hasTop) {
      return classnames(classes.topMargin, className)
    }

    return classnames(classes.wrapper, className)
  }

  return (
    <section className={wrapperClassName()} id={id}>
      {children}
    </section>
  )
}
