import React, {
  useEffect, useState,
} from 'react'
import {
  Typography,
  IconButton,
  Hidden,
  Box,
} from '@material-ui/core'
import {
  ArrowBack,
  ArrowForward,
  NavigateBefore, NavigateNext,
} from '@material-ui/icons'
import {
  Timeslot as BackendTimeslot, ScheduleType, TimeslotData,
} from '../../api/BookingCalendar'
import useWeekWalker from '../../hooks/useWeekWalker'
import classes from './Calendar.styles'
import MobileCalendar from './MobileCalendar'
import { changeDaysOfWeek } from './changeDaysOfWeek'
import DesktopCalendar from './DesktopCalendar'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import moment from 'moment'
import { Skeleton } from '@material-ui/lab'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { getDifferenceInBusinessDays } from '../../utils/getDifferenceInBusinessDays'
import theme, {
  BORDERS, COLORS, ROUNDED, general,
} from '../../styles/theme'
import { cx } from 'linaria'
import WarningMessage, { WarningMessageColor } from '../WarningMessage/WarningMessage'
import LoadingSpinner from '../SearchBar/LoadingSpinner'

type CalendarProps = {
  fullPremise: any;
  onTimeslotChange?: (timeslot: BackendTimeslot) => any;
  broadbandProduct?: string | undefined;
  scheduleType?: ScheduleType;
  type?: 'reschedule' | 'installation' | undefined;
}

type Timeslot = {
  time: string;
  start: number;
  end: number;
} & ({
  isAvailable: true;
  timeslot: BackendTimeslot;
} | {
  isAvailable: false;
  timeslot: null;
  endDayOfWeek?: number;
})

export type Day = {
  month: string;
  dayOfWeek: string;
  date: string;
  isAvailable: boolean;
  timeslots: {
    am: Timeslot[];
    pm: Timeslot[];
  };
  hasSelectedTimeslot: boolean;
}

export type Meridiem = 'am' | 'pm'

export type ProductGroup = 'GROUP_1' | 'HOME_WIFI' | '3GBPS'

export const calendarHeight = 13.5
export const mobileCalendarHeight = 20.5

export default function Calendar({
  onTimeslotChange,
  fullPremise,
  broadbandProduct,
  scheduleType,
}: CalendarProps) {
  const {
    week,
    year,
    isCalendarStart,
    isCalendarEnd,
    nextWeek,
    prevWeek,
  } = useWeekWalker()

  const {
    options,
  } = useOrderContext()

  const {
    wier, broadband,
  } = options

  const serviceType = wier && wier?.productGroup ? 'Premium' : 'Standard'

  const [
    days,
    setDays,
  ] = useState<Day[]>([])
  const [
    productGroup,
    setProductGroup,
  ] = useState<ProductGroup>('GROUP_1')
  const [
    selectedDayIndex,
    setSelectedDayIndex,
  ] = useState(0)
  const [
    selectedMeridiem,
    setSelectedMeridiem,
  ] = useState<Meridiem | undefined>(undefined)
  const [
    selectedTimeIndex,
    setSelectedTimeIndex,
  ] = useState<number | undefined>(undefined)
  const [
    selectedWeek,
    setSelectedWeek,
  ] = useState<number | undefined>(undefined)
  const [
    selectedTimeslot,
    setSelectedTimeslot,
  ] = useState<BackendTimeslot | TimeslotData | null>()
  const [
    loadingTimeslots,
    setLoadingTimeslots,
  ] = useState(false)
  const [
    skipUnavailableWeek,
    setSkipUnavailableWeek,
  ] = useState(false)
  const [
    firstAvailableTimeslot,
    setFirstAvailableTimeslot,
  ] = useState<Timeslot>()
  const [
    errorMessage,
    setErrorMessage,
  ] = useState(false)

  async function changeTimeslot({
    dayIndex,
    meridiem,
    timeIndex,
    onMobile,
  }: {
    dayIndex: number;
    meridiem?: Meridiem;
    timeIndex?: number;
    onMobile?: boolean;
  }): Promise<void> {
    if (onMobile) {
      setSelectedDayIndex(dayIndex)
      return
    }

    if (meridiem && (timeIndex !== undefined)) {
      const timeslot = days[dayIndex].timeslots[meridiem][timeIndex]
      if (timeslot && timeslot.isAvailable) {
        setSelectedDayIndex(dayIndex)
        setSelectedMeridiem(meridiem)
        setSelectedTimeIndex(timeIndex)
        setSelectedWeek(week)
        setSelectedTimeslot(timeslot.timeslot)
        onTimeslotChange && onTimeslotChange(timeslot.timeslot)
        return
      }
    }

    setSelectedMeridiem(undefined)
    setSelectedTimeIndex(undefined)
  }

  const firstDayWeek = days && days[0]
  const lastDayWeek = days && days[days.length - 1]

  // Create moment objects for the first and last day of the week
  const firstDayMoment = moment()
    .year(year)
    .isoWeek(week)
    .startOf('isoWeek')
  const lastDayMoment = moment()
    .year(year)
    .isoWeek(week)
    .endOf('isoWeek')

  // Format dates in ISO format for the API
  const startDate = firstDayMoment.format('YYYY-MM-DD')
  const endDate = lastDayMoment.format('YYYY-MM-DD')

  useEffect(() => {
    changeDaysOfWeek({
      fullPremise,
      week,
      year,
      setDays,
      selectedTimeslot,
      productGroup,
      startDate,
      endDate,
      serviceType,
      broadbandProduct,
      scheduleType,
    })
  }, [
    fullPremise,
    week,
    selectedTimeslot,
    year,
    productGroup,
    startDate,
    endDate,
    serviceType,
    broadbandProduct,
  ])

  const getEndOfWeek = () => {
    if (!selectedTimeslot) {
      return undefined
    }

    if ('endDayOfWeek' in selectedTimeslot) {
      return (Number(selectedTimeslot.endDayOfWeek) - 1)
    }

    if (typeof selectedTimeslot._id !== 'string') {
      return (Number(selectedTimeslot._id?.endDayOfWeek) - 1)
    }

    return undefined
  }

  useEffect(() => {
    if (broadband && wier?.productGroup === 'HOME_WIFI') {
      setProductGroup('HOME_WIFI')
    } else if (broadband?.productGroup === '3GBPS') {
      setProductGroup('3GBPS')
    } else {
      setProductGroup('GROUP_1')
    }
  },

  [
    wier,
    broadband,
  ])

  // The following code in this useEffect is for showing skeleton while slots are loading,
  // and for sliding to next week if all slots are booked/unavailable
  useEffect(() => {
    const allWeekTimeslots: boolean[] = []

    if (days.length === 0) {
      setLoadingTimeslots(true)
      return
    }

    // Check for API error response
    const hasApiError = days.some(day =>
      Object.values(day.timeslots)
        .some(slots =>
          slots.some(slot =>
          slot.timeslot?.appointmentWindowId?.length === 0 ||
          (slot.timeslot?.data && slot.timeslot.data.some(d => d._status === true)),
          ),
        ),
    )

    // If we hit an error, allow navigation but don't try to advance
    if (hasApiError) {
      setLoadingTimeslots(false)
      setSkipUnavailableWeek(false)
      return
    }

    days.forEach((day) => {
      const meridies = Object.keys(day.timeslots)
      meridies.forEach((meridiem: Meridiem) => {
        day.timeslots[meridiem].forEach((timeslot) => {
          if (timeslot.timeslot?.available !== undefined) {
            allWeekTimeslots.push(timeslot.timeslot.available)
          }
        })
      })
    })

    // Handle loading and first available timeslot
    if (allWeekTimeslots.length > 0) {
      setLoadingTimeslots(false)
      if (!firstAvailableTimeslot) {
        const firstAvailable = days.flatMap(day =>
          Object.values(day.timeslots)
            .flatMap(slots =>
              slots.filter(slot => slot.timeslot?.available === true),
            ),
        )[0]
        if (firstAvailable) {
          setFirstAvailableTimeslot(firstAvailable)
        }
      }
    }

    // Only try to advance if we have timeslots and none are available
    const shouldAdvance = !isCalendarEnd &&
                         allWeekTimeslots.length > 0 &&
                         !allWeekTimeslots.includes(true) &&
                         !skipUnavailableWeek &&
                         !hasApiError

    if (shouldAdvance) {
      const navigationTimeout = setTimeout(() => {
        setLoadingTimeslots(true)
        nextWeek()
        setSkipUnavailableWeek(true)
      }, 3000)

      return () => clearTimeout(navigationTimeout)
    }

    const errorTimeout = setTimeout(() => {
      setErrorMessage(days.length === 0)
    }, 30000)

    return () => clearTimeout(errorTimeout)
  }, [
    days,
    nextWeek,
    skipUnavailableWeek,
    isCalendarEnd,
    firstAvailableTimeslot,
  ])

  useEffect(() => {
    if (firstAvailableTimeslot) {
      const currentDate = new Date()
      const selectedDate = new Date(firstAvailableTimeslot.timeslot?.startDateTime!)
      const numberOfBusinessDays = getDifferenceInBusinessDays(currentDate, selectedDate)
      marketingEvents.selectInstallation('first_installation', options, firstAvailableTimeslot.timeslot?.date!, firstAvailableTimeslot.time, numberOfBusinessDays)
    }
  }
  , [firstAvailableTimeslot])

  const statusComponent = (
    <Box>
      {errorMessage === true ?
        <WarningMessage
          color={WarningMessageColor.yellow}
          text="<strong>Sorry, we are having some trouble loading the calendar.</strong></br>
          Please try again later or choose the option below to proceed with the order without booking an appointment now."
        /> :
        <LoadingSpinner/>}
    </Box>
  )

  return (
    <Box
      px={{
        xs: 2,
        md: 4,
      }}
      pb={{
        xs: 2,
        md: 4,
      }}
      pt={{
        xs: 6,
        md: 7,
      }}
      mt={4}
      border={BORDERS.gray3}
      borderRadius={ROUNDED.medium}
      position="relative"
      display="flex"
      flexDirection="column"
      className={classes.calendarWrapper}
    >
      <IconButton
        className={cx(classes.arrowButton, 'left')}
        color="primary"
        disableRipple
        disabled={isCalendarStart}
        onClick={() => {
          setLoadingTimeslots(true)
          setSkipUnavailableWeek(false)
          prevWeek()
        }}
        onKeyDown={(event) => event.key === 'Enter' && prevWeek()}
      >
        <ArrowBack/>
      </IconButton>
      <IconButton
        className={cx(classes.arrowButton, 'right')}
        color="primary"
        disableRipple
        disabled={isCalendarEnd || loadingTimeslots}
        onClick={nextWeek}
        onKeyDown={(event) => event.key === 'Enter' && nextWeek()}
      >
        <ArrowForward/>
      </IconButton>
      <Box
        alignItems="center"
        display="flex"
        bgcolor={COLORS.white}
        border={BORDERS.gray3}
        borderRadius={ROUNDED.small}
        boxSizing="content-box"
        left="50%"
        px={{
          xs: 1,
          md: 2,
        }}
        py={1}
        position="absolute"
        top="0"
        minHeight={theme.spacing(6)}
        gridGap={8}
        className={general.transform.center}
      >
        <IconButton
          size="small"
          color="primary"
          disableRipple
          disabled={isCalendarStart}
          onClick={prevWeek}
          onKeyDown={(event) => event.key === 'Enter' && prevWeek()}
        >
          <NavigateBefore className={general.font.size.h2}/>
        </IconButton>
        {(firstDayWeek && lastDayWeek) ?
          <Box
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.dateRange}
          >
            <Typography variant="h4" component="span" color="primary">
              <span>{firstDayWeek.date}{' '}</span>
              <span>{firstDayWeek.month}{' '}</span>
              <span>{' '}-{' '}</span>
              <span>{lastDayWeek.date}{' '}</span>
              <span>{lastDayWeek.month}</span>
            </Typography>
          </Box> :
          <Box
            component={Skeleton}
            borderRadius={ROUNDED.small}
            minHeight={theme.spacing(4)}
            className={cx(classes.dateRange, general.transform.none)}
          />}
        <IconButton
          size="small"
          color="primary"
          disableRipple
          disabled={isCalendarEnd}
          onClick={nextWeek}
          onKeyDown={(event) => event.key === 'Enter' && nextWeek()}
        >
          <NavigateNext className={general.font.size.h2}/>
        </IconButton>
      </Box>
      <Hidden smDown>
        {days.length > 0 && loadingTimeslots === false ?
          <DesktopCalendar
            days={days}
            selectedTimeIndex={selectedTimeIndex}
            selectedDayIndex={selectedDayIndex}
            selectedMeridiem={selectedMeridiem}
            selectedWeek={selectedWeek}
            currentWeek={week}
            changeTimeslot={changeTimeslot}
          /> :
          statusComponent}
      </Hidden>
      <Hidden mdUp>
        {days.length > 0 && loadingTimeslots === false ?
          <MobileCalendar
            days={days}
            selectedTimeIndex={selectedTimeIndex}
            selectedTimeslotDayIndex={getEndOfWeek()}
            selectedDayIndex={selectedDayIndex}
            selectedMeridiem={selectedMeridiem}
            selectedWeek={selectedWeek}
            nextWeek={nextWeek}
            prevWeek={prevWeek}
            currentWeek={week}
            changeTimeslot={changeTimeslot}
          /> :
          statusComponent}
      </Hidden>
    </Box>
  )
}
