import React from 'react'
import { css } from 'linaria'
import {
  Grid, Typography,
} from '@material-ui/core'
import theme from '../../../styles/theme'
import { toFormattedDate } from '../../../utils'

export interface TermsSectionProps {
  readonly anchor_ref: string;
  readonly body: string;
  readonly last_updated: string;
  readonly title: string;
}

const classes = {
  title: css`
    margin-bottom: ${theme.spacing(4)}px;
  `,
  content: css`
    p, li {
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
}

export default function TermsSection({
  anchor_ref,
  body,
  last_updated,
  title,
}: TermsSectionProps) {
  return (
    <Grid component="section" container id={anchor_ref} spacing={8}>
      <Grid container item spacing={2}>
        <Grid className={classes.title} container direction="column" item>
          <Typography color="primary" component="h2" variant="h1">
            {title}
          </Typography>
          <Typography color="primary" variant="body1">
            Last updated: {toFormattedDate(last_updated)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="primary"
            component="div"
            variant="body1"
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: body }}/>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
