import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import {
  Typography, Grid,
} from '@material-ui/core'
import {
  GoogleMap, useLoadScript, HeatmapLayer, Polygon, Marker,
} from '@react-google-maps/api'
import classes from './PolygonMap.styles'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import { ContainerWithCurve } from '../../styles/ContainerWithCurve'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { cx } from 'linaria'
import env from '../../utils/env'
import {
  PolygonMapProps, BoroughData, MapType, BoroughDictionary,
} from './PolygonMapInterfaces'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import StyledButton from '../StyledButton/StyledButton'
import { COLORS } from '../../styles/theme'

const liveHeatmapGradient = [
  'rgba(255, 255, 255, 0)',
  COLORS.primary,
  COLORS.primary,
  COLORS.secondary,
  COLORS.primary,
]

const containerStyle = {
  height: '580px',
}

const center = {
  lat: 51.50853,
  lng: -0.12574,
}

const libraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = ['visualization']

export default function PolygonMap({
  title, anchor_ref, availability_text, unavailable_text, show_premise_heatmap, info_card_title,
  info_card_subtitle, info_card_button, map_id,
}: PolygonMapProps) {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: env.googleMapKey,
    libraries,
  })

  const mapDefaultOptions = {
    maxZoom: 15,
    minZoom: 10,
    fullscreenControl: false,
    mapTypeControl: false,
    mapId: map_id,
  }

  const mapRef = useRef<GoogleMap>(null)

  const { premise } = useContext(PremiseContext)

  const [
    mapType,
    setMapType,
  ] = useState<MapType>(MapType.borough)

  const [
    selectedBoroughId,
    setSelectedBoroughId,
  ] = useState<string | null>(null)

  const [
    boroughsData,
    setBoroughs,
  ] = useState<BoroughDictionary<BoroughData> | null>(null)

  const [
    hoverBorough,
    setHoverBorough,
  ] = useState('')

  const [
    hideMarkers,
    setHideMarkers,
  ] = useState(true)

  const [
    position,
    setPosition,
  ] = useState(center)

  const [
    boroughPos,
    setBoroughPos,
  ] = useState('')

  const onLoad = () => {
    getBoroughData()
    const simplerAddress = premise?.borough + ', ' + premise?.postcode
    addressToCoordinates(simplerAddress)
  }

  const addressToCoordinates = async (address: string) => {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const coords = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        }
        setPosition(coords)
      }
    })
  }

  /*
    Borough
  */
  const getBoroughData = async () => {
    const data: BoroughDictionary<BoroughData> = await fetch('/formated_boroughs.json')
      .then((res) => res.json())

    Object.entries(data)
      .forEach(([
        key,
        item,
      ]) => {
        if (key === 'heatmapPoints') {
          return
        }

        const borough_bounds = new google.maps.LatLngBounds()

        item.coordinates.forEach((coord) => {
          coord.forEach(({
            lat, lng,
          }) => {
            borough_bounds.extend({
              lat,
              lng,
            })
          })
        })

        data[key].borough_center = borough_bounds.getCenter()
      })

    setBoroughs(data)
  }

  useEffect(() => {
    if (show_premise_heatmap && premise?.borough && boroughsData) {
      setMapType(MapType.heatmap)
      setHideMarkers(true)
      setSelectedBoroughId(premise.borough)
    }
  }, [
    show_premise_heatmap,
    boroughsData,
    premise,
  ])

  const onPolygonClick = (name: string) => {
    setMapType(MapType.heatmap)
    setSelectedBoroughId(name)
    setHideMarkers(true)
    setHoverBorough('')
    setBoroughPos(name)
  }

  const renderHeatMap = (name: string) => {
    if (!boroughsData) {
      return
    }

    const boroughCenter = boroughsData[name].borough_center
    const heatMapData = boroughsData.heatmapPoints.map(([
      lng,
      lat,
    ]) => new google.maps.LatLng(lat, lng))
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={boroughPos === name ? boroughCenter : position}
        zoom={boroughPos === name ? 13 : 15}
        onLoad={onLoad}
        options={{
          ...mapDefaultOptions,
          scrollwheel: false,
          streetViewControl: false,
          gestureHandling: 'greedy',
          maxZoom: 18,
        }}
      >
        {boroughCenter && (
          <Marker
            icon="/map-pin.svg"
            position={boroughPos === name ? boroughCenter : position}
            key={'marker_' + boroughsData[name].code}
          />
        )}
        {heatMapData &&
          <HeatmapLayer
            data={heatMapData!}
            options={{
              data: heatMapData!,
              maxIntensity: 1,
              radius: 60,
              gradient: liveHeatmapGradient,
            }}
          />}
      </GoogleMap>
    )
  }

  const renderBoroughMap = (data: BoroughDictionary<BoroughData>) => {
    function handleZoomChanged() {
      if (!mapRef?.current) {
        return
      }

      const mapState = mapRef.current?.state.map
      if (mapState) {
        setHideMarkers(mapState.getZoom() <= mapDefaultOptions.minZoom)
      }
    }

    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onZoomChanged={handleZoomChanged}
        onMouseOut={() => setHoverBorough('')}
        options={{
          ...mapDefaultOptions,
          scrollwheel: false,
          streetViewControl: false,
          gestureHandling: 'greedy',
        }}
        onLoad={onLoad}
      >
        {data && Object.values(data)
          .map(({
            code, coordinates, borough_available, borough_name, borough_center,
          }, index: number) => {
            return (
              <React.Fragment key={'fragment_' + index}>
                <Polygon
                  key={code} paths={coordinates} options={{
                    fillColor: borough_available ? COLORS.primary70 : COLORS.secondary70,
                    fillOpacity: hoverBorough === code ? 1 : 0.5,
                    strokeColor: borough_available ? COLORS.primary : COLORS.secondary,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: true,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 1,
                  }}
                  onMouseOver={() => setHoverBorough(borough_available ? code : '')}
                  onClick={() => {
                    if (borough_available) {
                      onPolygonClick(borough_name)
                    }
                  }}
                />
                {
                  (borough_center && (!hideMarkers || hoverBorough === code)) && (
                    <Marker
                      key={'marker_' + code}
                      label={{
                        text: borough_name,
                        fontSize: '15px',
                        fontWeight: '500',
                        color: borough_available || hideMarkers ? COLORS.white : COLORS.primary,
                      }}
                      icon="/marker_transparent_icon.png"
                      position={{
                        lat: borough_center.lat(),
                        lng: borough_center.lng(),
                      }}
                    />
                  )
                }
              </React.Fragment>
            )
          })}
      </GoogleMap>
    )
  }

  return (
    <SectionWithMargin id={anchor_ref}>
      <section className={classes.section}>
        {
          title && (
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
          )
        }
        <div className={classes.mapWrapper}>
          {isLoaded && mapType === MapType.borough ? renderBoroughMap(boroughsData!) : null}
          {isLoaded && mapType === MapType.heatmap && selectedBoroughId ? (
            <>
              {!show_premise_heatmap && (
                <a role="button" title="Zoom out" tabIndex={-42} className={classes.zoomOutButton} onClick={() => setMapType(MapType.borough)} onKeyDown={() => setMapType(MapType.borough)}>
                  <img src="/our_coverage_map_zoom_out_map.svg" alt="Zoom out" title="Zoom out"/>
                </a>
              )}
              {renderHeatMap(selectedBoroughId)}
            </>
          ) : null }
          <div className={classes.mapLegend}>
            <ContainerWithPadding>
              <Grid container justify="center" wrap="nowrap">
                {
                  availability_text && (
                    <Grid item alignItems="center" className={classes.mapLegendWrapper}>
                      <span className={cx(classes.mapLegendItem, classes.mapLegendItemAvailable)}/>
                      <Typography variant="body2" align="center">{availability_text}</Typography>
                    </Grid>
                  )
                }
                {
                  unavailable_text && (
                    <Grid item alignItems="center" className={cx(classes.mapLegendWrapper, 'rightSide')}>
                      <span className={cx(classes.mapLegendItem, classes.mapLegendItemUnavailable)}/>
                      <Typography variant="body2" align="center">{unavailable_text}</Typography>
                    </Grid>
                  )
                }
              </Grid>
            </ContainerWithPadding>
          </div>
          <ContainerWithCurve/>
          {show_premise_heatmap && (info_card_title || info_card_subtitle) && (
            <div className={classes.infoCard}>
              <div className={classes.infoCardWrapper}>
                {info_card_title && <Typography variant="h4" color="primary">{info_card_title}</Typography>}
                {info_card_subtitle && <Typography variant="body1" className={classes.infoCardSubtitle}>{info_card_subtitle}</Typography>}
                {info_card_button && <StyledButton url={info_card_button?.url} color="primary" className={classes.infoCardButton}>{info_card_button?.text}</StyledButton>}
              </div>
            </div>
          )}
        </div>
      </section>
    </SectionWithMargin>
  )
}
