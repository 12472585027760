import React from 'react'
import {
  Grid, Typography,
} from '@material-ui/core'
import { MenuItem } from '../Header'
import classes from './DesktopHeader.styles'

interface DesktopSharedHeaderProps {
  sharedMenuItems: MenuItem[];
  my_account_link?: MenuItem;
  handleSharedMenuClick: (navItem: MenuItem) => void;
}

const DesktopSharedHeader = ({
  sharedMenuItems,
  my_account_link,
  handleSharedMenuClick,
}: DesktopSharedHeaderProps) => {
  return (
    <Grid item md={5} container alignItems="center" justify="flex-end" className={classes.sharedItems}>
      {my_account_link?.title && (
        <button type="button" onClick={() => handleSharedMenuClick(my_account_link)}>
          {my_account_link.icon &&
            <img src={my_account_link.icon} alt={my_account_link.title} width={30} height={30}/>}
          <Typography variant="body2">
            {my_account_link.title}
          </Typography>
          <span className={classes.line}/>
        </button>
      )}
      {sharedMenuItems.map((navItem, i) => {
        const lastMenuItem = i === (sharedMenuItems.length - 1)

        return (
          <button type="button" key={i} onClick={() => handleSharedMenuClick(navItem)}>
            {navItem.icon &&
            <img src={navItem.icon} alt={navItem.title} width={25} height={25}/>}
            <Typography variant="body2">
              {navItem.title}
            </Typography>
            {!lastMenuItem && <span className={classes.line}/>}
          </button>
        )
      })}
    </Grid>
  )
}

export default DesktopSharedHeader
