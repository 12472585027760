import { css } from 'linaria'
import theme, {
  GRADIENTS, fontWeights,
} from '../../styles/theme'

const classes = {
  button: css`
    text-align: center;
    &:not(.disablePadding) {
      &.MuiButton-root {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }
    &.disablePadding {
      line-height: 1.5;
      padding: 0;
      .MuiButton-label {
        margin: 0;
        font-weight: ${fontWeights.semibold};
      }
    }
  `,
  fullWidth: css`
    width: 100%;
  `,
  blueGradient: css`
    &.MuiButton-root {
      background: ${GRADIENTS.businessButton};
      :hover {
          background: ${GRADIENTS.businessButtonHover};
      }
    }
  `,
  moreInfoBtn: css`
     max-width: 80%;
     &.MuiButtonBase-root {
      margin-top: ${theme.spacing(1)}px;
     }
     ${theme.breakpoints.down('sm')} {
      &.MuiButtonBase-root {
        padding: ${theme.spacing(1, 0, 5)};
     }
  `,
}

export default classes
