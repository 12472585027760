import React, {
  useState, useEffect,
} from 'react'
import {
  Box, Hidden, Typography,
} from '@material-ui/core'
import { cx } from 'linaria'
import { useRouter } from 'next/router'
import classes from './AffiliateBar.styles'
import { CloseOutlined } from '@material-ui/icons'
import topCashbackLogo from '../../../public/TopCashback_WhiteText.svg'
import quidcoLogo from '../../../public/quidco-logo.svg'

interface CommonQuery {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  awc?: string;
  sn?: string;
}

const AffiliateBar = () => {
  const router = useRouter()
  const { query } = router
  const {
    utm_campaign, utm_content,
  }: CommonQuery = query
  const typeCondition = utm_content?.includes('Quidco')
  const [
    isBarVisible,
    setIsBarVisible,
  ] = useState(true)

  useEffect(() => {
    const isCashbackSite = utm_campaign === 'Cashback'

    const isBarDisplayed = sessionStorage.getItem('isAffiliateBarDisplayed')

    if (isCashbackSite && !isBarDisplayed) {
      setIsBarVisible(true)
      sessionStorage.setItem('isAffiliateBarDisplayed', 'true')
    } else {
      setIsBarVisible(false)
    }
  }, [utm_campaign])

  const handleCloseIconClick = () => {
    setIsBarVisible(false)
  }

  const BarContent = () => {
    if (!isBarVisible) {
      return null
    }

    return (
      <Hidden mdDown>
        <Box className={cx(classes.wholeBar)} bgcolor={typeCondition ? '#1D7BCE' : '#2D6A4A'}>
          <Box display="flex" justifyContent="center">
            <Box>
              <Box className={cx(classes.content)}>
                <img
                  className={typeCondition ? cx(classes.QidcoLogo) : cx(classes.TopCashBackLogo)}
                  alt={typeCondition ? 'quidco-logo' : 'topCashback-logo'}
                  src={typeCondition ? quidcoLogo : topCashbackLogo}
                />
                <Typography>Visit tracked!</Typography>
                <Typography>Complete your order to receive your cashback</Typography>
              </Box>
              <Box className={cx(classes.closeIcon)} onClick={handleCloseIconClick}>
                <CloseOutlined/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
    )
  }

  return <BarContent/>
}

export default AffiliateBar
