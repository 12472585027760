import React from 'react'
import {
  Typography, CardMedia, Box,
} from '@material-ui/core'
import StyledButton from '../StyledButton/StyledButton'
import TrustPilotBox, {
  createTrustPilotProps, TrustPilotBoxProps,
} from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import SearchBar, { SearchBarProps } from '../SearchBar/SearchBar'
import MediaImage, {
  createMediaImageProps, MediaImageProps,
} from '../Media/MediaImage'
import MediaVideo, {
  createMediaVideoProps, MediaVideoProps,
} from '../Media/MediaVideo'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import InfoExpansion from '../TextWithMedia/InfoExpansion'
import { withStyles } from '@material-ui/core/styles'
import { HeroSectionStyles } from './HeroSection.styles'
import Wrapper from '../Wrapper/Wrapper'
import { Variant } from '@material-ui/core/styles/createTypography'
import { ROUNDED } from '../../styles/theme'
export interface ButtonProps {
  modal?: boolean;
  url: string;
  title: string;
  text: string;
}

export enum ButtonColorType {
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
}
export interface HeroSectionProps {
  readonly title?: string;
  readonly title_component?: Variant;
  readonly title_color?: string;
  readonly captionAuthor?: string;
  readonly captionDate?: string;
  readonly subtitle?: string;
  readonly subtitle_color?: string;
  readonly wysiwyg?: string;
  readonly text_alignment?: 'left' | 'center' | 'right';
  readonly content_alignment?: string;
  readonly background_color?: string;
  readonly background_image?: string;
  readonly background_media_image?: MediaImageProps;
  readonly inverted_text_color?: boolean;
  readonly button?: ButtonProps;
  readonly button_colour?: ButtonColorType;
  readonly aside_media?: string;
  readonly aside_media_image?: MediaImageProps;
  readonly aside_media_video?: MediaVideoProps;
  readonly trustpilot?: boolean;
  readonly search_bar?: SearchBarProps;
  readonly anchor_id?: string;
  readonly background_video?: MediaVideoProps;
  readonly expansion_text?: string;
  readonly expansion_title?: string;
  readonly trustpilot_box?: TrustPilotBoxProps;
  readonly gap?: number;
  readonly padding_top?: number;
  readonly padding_bottom?: number;
  readonly classes?: any;
  readonly mobile_background_position_x?: number;
  readonly mobile_background_position_y?: number;
}

const renderMedia = (media: string, media_image: MediaImageProps, media_video: MediaVideoProps, alignment: string, captionAuthor: string) => {
  return (
    <Box
      order={{
        xs: '1',
        lg: alignment !== 'left' && '1',
      }}
      maxWidth={{
        xs: '100%',
        lg: alignment === 'center' ? '50%' : '100%',
      }}
      zIndex={2}
      borderRadius={captionAuthor ? `${ROUNDED.medium}px` : '0'}
      overflow={captionAuthor ? 'hidden' : 'visible'}
    >
      {media &&
        <CardMedia
          alt="image"
          component="img"
          src={media}
          title="image"
        />}
      {media_image?.src && <MediaImage center {...createMediaImageProps(media_image, 640, 480)}/>}
      {media_video?.src && <MediaVideo {...createMediaVideoProps(media_video)}/>}
    </Box>
  )
}

const HeroSection = withStyles(HeroSectionStyles)(({
  title,
  title_component,
  subtitle,
  captionAuthor,
  captionDate,
  wysiwyg,
  text_alignment = 'left',
  content_alignment,
  background_video,
  background_media_image,
  button,
  button_colour,
  aside_media,
  aside_media_image,
  aside_media_video,
  trustpilot_box,
  search_bar,
  anchor_id,
  expansion_text,
  expansion_title,
  classes,
}: HeroSectionProps) => {
  const renderContent = () => {
    return (
      <Box
        order={{
          xs: '2',
          lg: content_alignment === 'left' && '1',
        }}
        maxWidth={{
          xs: '100%',
          sm: '80vw',
          md: '60vw',
          lg: '100%',
        }}
        textAlign={text_alignment}
      >
        <Box
          position="relative"
          zIndex={2}
        >
          {wysiwyg ?
            <Wysiwyg body={wysiwyg} variant="body1"/> :
            <>
              {title_component ?
                <Typography variant={title_component} className={classes.title}>{ title }</Typography> :
                <Typography variant="h1" className={classes.title}>{ title }</Typography>}
              {captionAuthor &&
              <Box my={3}>
                <Typography component="p" variant="caption">{ captionAuthor }</Typography>
                {captionDate && <Typography component="p" variant="caption">{ captionDate }</Typography>}
              </Box>}
              <Box mt={captionAuthor ? 3 : 4}>
                <Typography variant="body1" className={classes.subtitle}>{ subtitle }</Typography>
              </Box>
            </>}
        </Box>
        <Box
          className={classes.content}
          maxWidth={{
            xs: '100%',
            sm: '100%',
            lg: content_alignment === 'center' ? '480px' : '100%',
          }}
          mx={{
            xs: 'auto',
            lg: content_alignment === 'center' ? 'auto' : '0',
          }}
        >
          {search_bar && Object.keys(search_bar).length > 0 &&
            <Box mt={2}>
              <SearchBar
                {...search_bar}
                id="searchbarWrapper"
                className={classes.search}
              />
            </Box>}
          {(expansion_text && expansion_title) &&
            <Box mt={2}>
              <InfoExpansion text={expansion_text} title={expansion_title}/>
            </Box>}
          {button && button.text &&
            <Box
              mt={4}
              position="relative"
              zIndex={2}
            >
              <StyledButton
                isModal={button?.modal}
                color={button_colour}
                url={button?.url}
              >
                {button.text}
              </StyledButton>
            </Box>}
        </Box>
        {trustpilot_box && trustpilot_box.widget &&
          <TrustPilotBox
            styles={content_alignment === 'center' ? 'centered' : 'left'}
            {...createTrustPilotProps(trustpilot_box)}
          />}
      </Box>
    )
  }

  return (
    <Box
      component={Wrapper}
      id={anchor_id}
      className={classes.section}
    >
      {content_alignment &&
        <div className={classes.container}>
          { renderContent() }
          { (aside_media || aside_media_image || aside_media_video) && renderMedia(aside_media!, aside_media_image!, aside_media_video!, content_alignment, captionAuthor!) }
        </div>}
      {background_video && background_video.src &&
        <MediaVideo
          className={classes.filler}
          {...createMediaVideoProps(background_video)}
        />}
      {background_media_image?.src &&
        <MediaImage
          className={classes.filler}
          background
          {...createMediaImageProps(background_media_image, 1903, 592)}
        />}
    </Box>
  )
})

export default HeroSection
