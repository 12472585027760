import React, {
  useRef, RefObject, useEffect, useState,
} from 'react'
import Slider, { CustomArrowProps } from 'react-slick'
import { useWindowWidth } from '../../utils/useWindowWidth'
import classesOld from './Packages.styles'
import { cx } from 'linaria'
import { GENERAL } from '../../styles/theme'

interface SliderCarouselProps {
  children?: any;
  parentCallback: (val: any) => void;
}

const SliderCarousel = ({
  children,
  parentCallback,
}: SliderCarouselProps) => {
  const sliderRef: RefObject<Slider> = useRef(null)
  const windowWidth = useWindowWidth()
  const showsMultipleBroadbandProducts = windowWidth && windowWidth > (360 * 3)
  const childrenProps = children.map((child: any) => child.props)
  const hideValues = childrenProps.map((child: any) => {
    if (child !== undefined) {
      return child.hide
    }

    return undefined
  })
  const visibleChildren = children?.filter((child: any, index: number) => {
    return child !== false && hideValues[index] !== true
  })
  const evenNumberOfProducts = visibleChildren?.length % 2 === 0
  const initialSlide = evenNumberOfProducts ? 1 : 2
  const slidesToShow = showsMultipleBroadbandProducts ? 2 : 1
  const [
    hideCarousel,
    setHideCarousel,
  ] = useState(false)

  const SliderArrow = (props: CustomArrowProps) => {
    const {
      currentSlide, className, onClick,
    } = props

    const isNextButton = className && className.includes('next')

    const handleFirstSlide = () => {
      if (sliderRef && sliderRef.current) {
        const smallScreen = windowWidth && windowWidth / 360 < 3
        const showSpaceBeforeFirstCard = currentSlide !== 1 || evenNumberOfProducts || smallScreen

        if (isNextButton) {
          const nextSlide = currentSlide ? currentSlide + 1 : initialSlide + 1
          sliderRef.current.slickGoTo(nextSlide, false)
        } else if (showSpaceBeforeFirstCard) {
          const nextSlide = currentSlide ? currentSlide - 1 : initialSlide - 1
          sliderRef.current.slickGoTo(nextSlide, false)
        }
      }
    }

    return (
      currentSlide === initialSlide || currentSlide === 1 ?
        <button type="button" className={className} onClick={handleFirstSlide}/> :
        <button type="button" className={className} onClick={onClick}/>
    )
  }

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    unslick: hideCarousel,
    initialSlide,
    slidesToShow,
    prevArrow: <SliderArrow/>,
    nextArrow: <SliderArrow/>,
  }

  useEffect(() => {
    if (windowWidth) {
      const broadbandProductsFit = (windowWidth - (visibleChildren?.length * GENERAL.packageWidthDesktop)) > 200
      setHideCarousel(broadbandProductsFit)
    }
  }, [
    windowWidth,
    visibleChildren?.length,
  ])

  useEffect(() => {
    parentCallback(true)
  }, [])

  return (
    <Slider
      ref={sliderRef}
      className={cx(classesOld.slider, evenNumberOfProducts && classesOld.sliderOffCentre, hideCarousel && classesOld.bigScreenCards)}
      {...sliderSettings}
      beforeChange={() => {
        parentCallback(false)
      }}
      onReInit={() => {
        parentCallback(true)
      }}
    >
      {children}
    </Slider>
  )
}

export default SliderCarousel
