import { AxiosInstance } from 'axios'

export default function Payment(client: AxiosInstance) {
  async function verifySortCode(sortcode: string, account: string) {
    try {
      const params = {
        account: {
          sortcode: String(sortcode.split('-')?.join('')),
          account: String(account),
        },
      }
      const { data } = await client.post('payment/v1/0.0/sort-code-verify', params)
      return data
    } catch (err) {
      return { error: err }
    }
  }

  async function verifyAddressWithHobs({
    sprn,
  }: { sprn: string }) {
    try {
      const params = {
        sprn,
      }
      const { data } = await client.post<any>('customer-check/v1/0.0/search-by-sprn', params)
      return data
    } catch (err) {
      return err.response
    }
  }

  return {
    verifySortCode,
    verifyAddressWithHobs,
  }
}
