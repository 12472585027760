import { AxiosInstance } from 'axios'
import uuid from 'uuid'

const sessionId = uuid.v4()

export default function Webform(client: AxiosInstance) {
  async function post(name: string, params: Record<string, any>) {
    try {
      const requestBody = {
        type: name,
        subType: sessionId,
        data: {
          ...params,
        },
      }
      const response = await client.post('/v1/0.0/store', requestBody)
      return response.data
    } catch (err) {
      return err
    }
  }

  return {
    post,
  }
}
