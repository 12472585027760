import React from 'react'
import {
  List, Grid, Dialog, Slide, SlideProps, Box,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import MobileMenuItem from './MobileMenuItem'
import classes from './MobileHeader.styles'
import useMenu from '../useMenu'
import NavigationTabs from '../../NavigationTabs/NavigationTabs'
import StyledButton from '../../StyledButton/StyledButton'
import { ContainerWithCurve } from '../../../styles/ContainerWithCurve'
import {
  MenuCategoryTab, MenuItem,
} from '../Header'
import { CtaProps } from '../../../utils/commonProps'

interface MobileDialogMenuProps {
  isHamburgerMenuOpen: boolean;
  toggleHamburgerMenu: () => void;
  categories: MenuCategoryTab[];
  activeCategoryIndex: number;
  handleCategoryClick: (index: number) => void;
  handleSubMenuClick: (index: number) => void;
  openSubMenuIndex: number | null;
  shared_menu_items?: MenuItem[];
  my_account_link?: MenuItem;
  button?: CtaProps | null;
}

function DialogTransition(props: SlideProps) {
  return <Slide {...props} direction="left"/>
}

const MobileDialogMenu = ({
  isHamburgerMenuOpen,
  toggleHamburgerMenu,
  categories,
  activeCategoryIndex,
  handleCategoryClick,
  handleSubMenuClick,
  openSubMenuIndex,
  shared_menu_items,
  my_account_link,
  button,
}: MobileDialogMenuProps) => {
  const activeCategoryMenuItemsLength = categories[Number(activeCategoryIndex)].menu_items.length
  const {
    findActiveCategory,
    findRouterSlug,
  } = useMenu()

  const routerCategoryIndex = findActiveCategory(categories, findRouterSlug())
  return (
    <Dialog
      fullScreen
      open={isHamburgerMenuOpen}
      TransitionComponent={DialogTransition}
      className={classes.submenu}
    >
      <Grid container direction="column" justify="space-between">
        <Grid item>
          <div className={classes.closeIcon}>
            <ClearIcon
              aria-label="menu"
              onClick={() => {
                toggleHamburgerMenu()
                handleCategoryClick(routerCategoryIndex)
              }}
            />
          </div>
          <NavigationTabs tabs={categories} activeTab={activeCategoryIndex} handleClick={handleCategoryClick}/>
          <ContainerWithCurve>
            <List className={classes.categoryNav}>
              {categories[Number(activeCategoryIndex)].menu_items.map((item, index) => {
                return (
                  <MobileMenuItem
                    item={item}
                    index={index}
                    key={index}
                    handleSubMenuClick={handleSubMenuClick}
                    openSubMenuIndex={openSubMenuIndex}
                    handleSubMenuChildClick={toggleHamburgerMenu}
                  />
                )
              })}
            </List>
          </ContainerWithCurve>
          <List className={classes.sharedNav}>
            {my_account_link?.title &&
            <MobileMenuItem
              item={my_account_link}
              index={0}
              handleSubMenuClick={handleSubMenuClick}
              handleSubMenuChildClick={toggleHamburgerMenu}
              openSubMenuIndex={openSubMenuIndex}
            />}
            {shared_menu_items && shared_menu_items.map((item, index) => {
              const sharedNavItemIndex = index + activeCategoryMenuItemsLength

              return (
                <MobileMenuItem
                  item={item}
                  index={sharedNavItemIndex}
                  key={index}
                  handleSubMenuClick={handleSubMenuClick}
                  handleSubMenuChildClick={toggleHamburgerMenu}
                  openSubMenuIndex={openSubMenuIndex}
                />
              )
            })}
          </List>
        </Grid>
        {button && activeCategoryIndex === 0 &&
        <Box mt={10}>
          <Grid item className={classes.primaryButtonWrapper}>
            <StyledButton
              color="primary"
              url={button.url}
              isModal={button?.modal}
            >
              {button.text}
            </StyledButton>
          </Grid>
        </Box>}
      </Grid>
    </Dialog>
  )
}

export default MobileDialogMenu
