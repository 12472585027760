/* eslint-disable security/detect-object-injection */
/* eslint complexity: ["error", 100] */
import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  useForm, FormContext,
  Controller,
} from 'react-hook-form'
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  Hidden,
  Select,
  RadioGroup,
  Checkbox,
  MenuItem,
  FormControlLabel,
  Radio,
  FormGroup,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import createCRMApiClient from '../../api/CRMApi'
import classes from './WebForm.style'
import { cx } from 'linaria'
import logger from '../../utils/logger'
import { CtaProps } from '../../utils/commonProps'
import {
  specialCharactersSanitizer, handleKeyDown, handlePhoneChange,
  emailRegex,
  phoneRegex,
  nameRegex,
} from '../../utils'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import theme from '../../styles/theme'
import TextInput from '../TextInput/TextInput'
import StyledButton from '../StyledButton/StyledButton'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import {
  ExperianEmail, ExperianPhone,
} from '../../api/Experian'
import { experianStore } from '../../sessionStorage'
import { ExperianOptions } from '../ExperianValidation/ExperianValidation'
import marketingEvents from '../../utils/marketing/marketingEvents'
import {
  OrderOptions, useOrderContext,
} from '../NewOrderFlow/OrderContext'

export interface WebFormProps {
  form_name: string;
  anchor_id?: string;
  title?: string;
  subtitle?: string;
  subtitle_mobile_only?: string;
  show_address?: boolean;
  first_name?: boolean;
  is_first_name_required?: boolean;
  last_name?: boolean;
  is_last_name_required?: boolean;
  email?: boolean;
  is_email_required?: boolean;
  phone_number?: boolean;
  is_phone_number_required?: boolean;
  free_fields?: CustomInputsForWebform[];
  fields_in_columns?: number;
  cta?: CtaProps;
  success_submit_message_title?: string;
  success_submit_message_subtitle?: string;
  success_submit_message_aligment?: 'left' | 'center';
  consent_text?: string;
  checkbox_for_consent?: boolean;
}
interface CustomInputsForWebform {
  name: string;
  label: string;
  field_type: string;
  options?: string;
  placeholder: string;
  required: boolean;
}

interface FormValues {
  [key: string]: string | boolean | Record<string, boolean> | undefined;
  address: string;
  sprn: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export default function WebForm({
  form_name,
  anchor_id,
  title,
  subtitle,
  subtitle_mobile_only,
  first_name,
  is_first_name_required,
  last_name,
  is_last_name_required,
  email,
  is_email_required,
  phone_number,
  is_phone_number_required,
  free_fields,
  fields_in_columns = 2,
  cta,
  success_submit_message_title,
  success_submit_message_subtitle,
  success_submit_message_aligment = 'left',
  consent_text,
  checkbox_for_consent,
}: WebFormProps) {
  const [
    showSuccessSubmitMessage,
    setShowSuccessSubmitMessage,
  ] = useState(false)

  const [
    error,
    setError,
  ] = useState<boolean>(false)

  const [
    consent,
    setConsent,
  ] = useState<boolean>(false)

  const [
    selectValue,
    setSelectValue,
  ] = useState<string>('')

  const [
    experianData,
    setExperianData,
  ] = useState<ExperianOptions | null>()

  const {
    options,
  } = useOrderContext()

  useEffect(() => {
    if (selectValue === 'Please select from list below') {
      setError(true)
    }

    setError(false)
  }, [selectValue])

  useEffect(() => {
    (async () => {
      const response = await experianStore.get()
      if (response) {
        setExperianData(response)
      }
    })()
  }, [])

  const { premise } = useContext(PremiseContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const formName = form_name.replace(/\s+/g, '_')

  const validateExperianEmail = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.email_validation) {
      marketingEvents.simpleEvent(`${formName}_experian_validation_disabled_email`, options)
      return
    }

    const validationResponse: ExperianEmail = await (async () => {
      try {
        return await client.experian.validateEmail(input)
      } catch (err) {
        return err.message
      }
    })()

    for (const el of experianData?.email_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        marketingEvents.simpleEvent(`${formName}_experian_email_validation_successful`, options)
        return
      }
    }

    marketingEvents.simpleEvent(`${formName}_experian_email_validation_fails`, options)
    return experianData?.email_error
  }

  const validateExperianPhone = async (input: string) => {
    const client = createCRMApiClient()

    if (!experianData?.phone_validation) {
      marketingEvents.simpleEvent(`${formName}_experian_validation_disabled_phone`, options)
      return
    }

    const validationResponse: ExperianPhone = await (async () => {
      try {
        return await client.experian.validatePhone(input)
      } catch (err) {
        return err.message
      }
    })()

    for (const el of experianData?.phone_validation_cases) {
      if (validationResponse?.result?.confidence === el.confidence) {
        marketingEvents.simpleEvent(`${formName}_experian_phone_validation_successful`, options)
        return
      }
    }

    marketingEvents.simpleEvent(`${formName}_experian_phone_validation_fail`, options)

    return experianData?.phone_error
  }

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const {
    handleSubmit, control, formState,
  } = methods

  const { isSubmitting } = formState
  const scrollToElement = () => {
    const element = document.querySelector('#success_message')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleInputBlur = (eventName: string, options: OrderOptions, validator: (value: string) => boolean) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value
    let isValid = false

    if (value && value.trim().length > 0) {
      if (eventName === `${formName}_Email`) {
        isValid = emailRegex.test(value)
      } else if (eventName === `${formName}_PhoneNumber`) {
        isValid = value.length === 11 && phoneRegex.test(value)
      } else {
        isValid = validator(value)
      }
    }

    if (isValid) {
      // Field is valid and complete, trigger GA event
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    const client = createCRMApiClient()

    // Extract dynamic fields from `data`
    const dynamicFields: { [key: string]: string } = {}
    if (free_fields) {
      free_fields.forEach(field => {
        const fieldName = field.name.toLowerCase()
          .replace(/\s+/g, '_')

        if (field.field_type === 'checkbox') {
          const checkedOptions = Object.entries(data[fieldName] as Record<string, boolean> || {})
            .filter(([
              , value,
            ]) => value === true)
            .map(([key]) => key)
          if (checkedOptions.length > 0) {
            dynamicFields[fieldName] = checkedOptions.join(', ')
          }
        } else if (field.field_type === 'radiobutton') {
          if (data[fieldName] !== undefined && data[fieldName] !== '') {
            dynamicFields[fieldName] = String(data[fieldName])
          }
        } else if (data[fieldName] !== undefined && data[fieldName] !== '') {
          dynamicFields[fieldName] = String(data[fieldName])
        }
      })
    }

    const params = {
      time: new Date()
        .toUTCString(),
      address: premise?.address,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      ...dynamicFields,
    }

    const result = await client.webform.post(formName, params)
    if (result.message.toLowerCase()
      .includes('success')) {
      setShowSuccessSubmitMessage(true)
      scrollToElement()
      marketingEvents.simpleEvent(`${formName}_Submit_success`, options)
    } else {
      setError(true)
      logger.error(result.message, result.error)
    }
  },
  )

  return (
    <ContainerWithPadding>
      <Grid container>
        <Grid item xs={12}>
          <Box
            pt={5}
            pb={showSuccessSubmitMessage ? 0 : 5}
          >
            {title && !showSuccessSubmitMessage &&
            <Typography variant="h3" color="primary">{title}</Typography>}
            {subtitle && !showSuccessSubmitMessage &&
              <Box pt={{
                xs: 1,
                sm: 4,
              }}
              >
                <Typography variant="body1" color="textPrimary">{subtitle}</Typography>
              </Box>}
            {showSuccessSubmitMessage && success_submit_message_title &&
              <Box
                id="success_message"
                style={{ textAlign: success_submit_message_aligment }}
              >
                <Typography variant="h3" color="primary">{success_submit_message_title}</Typography>
                {success_submit_message_subtitle &&
                <Box pt={{
                  xs: 1,
                  sm: 4,
                }}
                >
                  <Typography variant="body1" color="textPrimary">
                    {success_submit_message_subtitle}
                  </Typography>
                </Box>}
              </Box>}
            {subtitle_mobile_only && !showSuccessSubmitMessage && (
              <Hidden mdUp>
                <Box pt={1}>
                  <Typography variant="body1" color="textPrimary">
                    {subtitle_mobile_only}
                  </Typography>
                </Box>
              </Hidden>
            )}
          </Box>
        </Grid>
        {!showSuccessSubmitMessage && (
          <Grid item xs={12} id={anchor_id}>
            <FormContext {...methods}>
              <Box className={classes.contactForm}>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2}>
                    {first_name && (
                      <TextInput
                        gridWidth={fields_in_columns === 1 ? 12 : 6}
                        required={is_first_name_required}
                        label="First name"
                        placeholder="First name"
                        type="text"
                        handleChange={specialCharactersSanitizer}
                        inputProps={{
                          onBlur: handleInputBlur(`${formName}_FirstName`, options, (value) => nameRegex.test(value)),
                        }}
                        validation={{
                          pattern: {
                            value: /^(?!.*(?<character>[a-zA-Z\s-])\k<character>{2})(?!.*\s{2})[a-zA-Z\s-]+$/,
                            message: 'Please use a valid name. Insert alphabetic characters only',
                          },
                          minLength: {
                            value: 1,
                            message: 'Minimum length is 3 characters',
                          },
                          maxLength: {
                            value: 25,
                            message: 'Maximum length is 25 characters',
                          },
                          validate: (value) => value.trim().length > 0 || 'First Name is required and cannot be just spaces',
                        }}
                      />
                    )}
                    {last_name && (
                      <TextInput
                        gridWidth={fields_in_columns === 1 ? 12 : 6}
                        required={is_last_name_required}
                        label="Last name"
                        placeholder="Last name"
                        type="text"
                        handleChange={specialCharactersSanitizer}
                        inputProps={{
                          onBlur: handleInputBlur(`${formName}_LastName`, options, (value) => nameRegex.test(value)),
                        }}
                        validation={{
                          pattern: {
                            value: /^(?!.*(?<character>[a-zA-Z\s-])\k<character>{2})(?!.*\s{2})[a-zA-Z\s-]+$/,
                            message: 'Please use a valid name. Insert alphabetic characters only',
                          },
                          minLength: {
                            value: 1,
                            message: 'Minimum length is 3 characters',
                          },
                          maxLength: {
                            value: 25,
                            message: 'Maximum length is 25 characters',
                          },
                          validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
                        }}
                      />
                    )}
                    {email && (
                      <TextInput
                        gridWidth={fields_in_columns === 1 ? 12 : 6}
                        required={is_email_required}
                        label="Email"
                        placeholder="me@example.co.uk"
                        type="email"
                        inputProps={{
                          onBlur: handleInputBlur(`${formName}_Email`, options, (value) => emailRegex.test(value)),
                        }}
                        validation={{
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Please enter a valid email address (e.g., me@example.co.uk).',
                          },
                          validate: validateExperianEmail,
                        }}
                      />
                    )}
                    {phone_number && (
                      <TextInput
                        gridWidth={fields_in_columns === 1 ? 12 : 6}
                        required={is_phone_number_required}
                        label="Contact Number"
                        id="phone"
                        type="tel"
                        inputProps={{
                          maxLength: 11,
                          autoComplete: 'off',
                          onBlur: handleInputBlur(`${formName}_PhoneNumber`, options, (value) => value.length === 11 && phoneRegex.test(value)),
                        }}
                        onKeyDown={handleKeyDown}
                        handleChange={handlePhoneChange}
                        validation={{
                          maxLength: {
                            value: 11,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                          minLength: {
                            value: 11,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                          pattern: {
                            value: /^[0]\S(?!000000000)\d{9}$/,
                            message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                          },
                          validate: validateExperianPhone,
                        }}
                      />
                    )}
                    {free_fields &&
                        free_fields.map((field, index) => {
                          const id = field.name.toLowerCase()
                            .replace(/\s+/g, '_')
                          const commonProps = {
                            key: index,
                            id,
                            required: field.required,
                            label: field.label,
                            fullWidth: true,
                          }
                          const optionsArray = field.options ? field.options.split(',') : []

                          switch (field.field_type) {
                            case 'dropdown':
                              return (
                                <Grid item xs={12} md={fields_in_columns === 1 ? 12 : 6} key={`${id}-${index}`}>
                                  <Typography variant="body1" color={methods.errors[id] ? 'error' : 'primary'} className={classes.inputLabel}>{field.label}{field.required && ' *'}</Typography>
                                  <Controller
                                    control={control}
                                    rules={{ required: field.required }}
                                    name={id}
                                    defaultValue=""
                                    renderValue={(selected: string) => {
                                      if (!selected) {
                                        return 'Please select from list below'
                                      }

                                      setSelectValue(selected)
                                      return selected
                                    }}
                                    as={
                                      <Select
                                        {...commonProps}
                                        displayEmpty
                                        className={cx(classes.selectClass, classes.activeSelect)}
                                        IconComponent={KeyboardArrowDownIcon}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        disableUnderline
                                        inputProps={{
                                          classes: {
                                            icon: classes.icon,
                                          },
                                        }}
                                      >
                                        {optionsArray.map((option, optionIndex) => (
                                          <MenuItem key={optionIndex} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                  />
                                </Grid>
                              )
                            case 'radiobutton':
                              return (
                                <Grid item xs={12} md={fields_in_columns === 1 ? 12 : 6} key={`${id}-${index}`}>
                                  <Typography variant="body1" color={methods.errors[id] ? 'error' : 'primary'} className={classes.inputLabel}>{field.label}{field.required && ' *'}</Typography>
                                  <Controller
                                    className={classes.radioButon}
                                    name={id}
                                    control={methods.control}
                                    defaultValue=""
                                    rules={{ required: field.required }}
                                    as={
                                      <RadioGroup>
                                        {optionsArray.map((option, optionIndex) => (
                                          <FormControlLabel
                                            key={optionIndex}
                                            value={option.toLowerCase()}
                                            control={<Radio/>}
                                            label={option}
                                            onChange={() => methods.setValue(id, option.toLowerCase())}
                                          />
                                        ))}
                                      </RadioGroup>
                                    }
                                  />{methods.errors[id] && (
                                    <Typography color="error" variant="caption">
                                      This field is required
                                    </Typography>
                                  )}
                                </Grid>
                              )
                            case 'checkbox':
                              return (
                                <Grid item xs={12} md={fields_in_columns === 1 ? 12 : 6} key={`${id}-${index}`}>
                                  <Typography variant="body1" color={methods.errors[id] ? 'error' : 'primary'} className={classes.inputLabel}>
                                    {field.label}
                                    {field.required && ' *'}
                                  </Typography>
                                  <FormGroup className={classes.checkbox}>
                                    {optionsArray.map((option) => (
                                      <Controller
                                        key={`${id}.${option}`}
                                        name={`${id}.${option}`}
                                        control={methods.control}
                                        defaultValue={false}
                                        rules={{
                                          validate: () => {
                                            if (field.required) {
                                              const formValues = methods.getValues() as Record<string, any>
                                              const values = optionsArray.map(opt => formValues[id] && formValues[id][opt])
                                              const isAnyChecked = values.some(value => value === true)
                                              return isAnyChecked || 'Please select at least one option'
                                            }

                                            return true
                                          },
                                        }}
                                        as={
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                color="primary"
                                                onChange={e => {
                                                  methods.setValue(`${id}.${option}`, e.target.checked)
                                                }}
                                              />
                                            }
                                            label={option}
                                          />
                                        }
                                      />
                                    ))}
                                  </FormGroup>
                                  {methods.errors[id] && (
                                    <Typography color="error" variant="caption">
                                      {methods.errors[id]?.message}
                                    </Typography>
                                  )}
                                </Grid>
                              )
                            case 'text':
                            default:
                              return (
                                <TextInput
                                  {...commonProps}
                                  gridWidth={fields_in_columns === 1 ? 12 : 6}
                                  placeholder={field.placeholder}
                                  type="text"
                                  handleChange={specialCharactersSanitizer}
                                  inputProps={{
                                    onBlur: handleInputBlur(`${formName}_${field.name}`, options, (value) => nameRegex.test(value)),
                                  }}
                                  validation={{
                                    minLength: {
                                      value: 1,
                                      message: 'Minimum length is 3 characters',
                                    },
                                    maxLength: {
                                      value: 50,
                                      message: 'Maximum length is 50 characters',
                                    },
                                    validate: (value) => value.trim().length > 0 || 'This field is required and cannot be just spaces',
                                  }}
                                />
                              )
                          }
                        }) }
                    <Grid item xs={12}>
                      {checkbox_for_consent && consent_text &&
                        <FormControlLabel
                          label={<Wysiwyg body={consent_text} variant="caption"/>}
                          control={
                            <Checkbox
                              color="secondary"
                              checked={consent}
                              onChange={() => {
                                setConsent(!consent)
                              }}
                            />
                          }
                        />}
                      {consent_text && !checkbox_for_consent &&
                      <Wysiwyg body={consent_text} variant="caption"/>}
                    </Grid>
                    <Grid item xs={12}>
                      <StyledButton
                        type="submit" color="primary"
                        className={classes.submitClasses}
                        isFullWidth={isMobile}
                        disabled={checkbox_for_consent && !consent}
                        handleClick={() => marketingEvents.simpleEvent(`${formName}_Submit_CTA_Click`, options)}
                      >
                        <Typography>{cta!.text ? cta!.text : 'Submit'}</Typography>
                        {isSubmitting && !error && (
                          <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>
                        )}
                      </StyledButton>
                      {error && (
                        <Box pt={4} id="message-error">
                          <Typography variant="body2" color="error">
                            Something went wrong. Please refresh the page and try again later.
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </FormContext>
          </Grid>
        )}
      </Grid>
    </ContainerWithPadding>
  )
}
