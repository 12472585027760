import { AxiosInstance } from 'axios'
import { PremiseDetail } from './Addresses'
import { Product } from '../utils/commonInterfaces'

export interface AccountDetails {
  _id: string;
  name: string;
  profileID: string;
  leadID: string;
  accountNumber: string;
  premise: PremiseDetail;
  stage: {
    name: 'SOLD' | 'INSTALL';
  };
  products: Product[];
}

export default function Accounts(client: AxiosInstance) {
  async function verifyAddress(address: string) {
    try {
      return await client.get<{ message: string }>(`/v1/account/search-by-name/${address}`)
    } catch (err) {
      return {
        message: err.message,
        error: err,
      }
    }
  }

  return {
    verifyAddress,
  }
}
