import localforage from 'localforage'
import {
  PremiseDetail,
} from './api/Addresses'
import { OrderState } from './components/OrderFlow/OrderContext'
import { UTMState } from './components/UTMTracker/UTMTracker'

export function createStore<T>(name: string, validator: (x: unknown) => boolean = () => true) {
  return {
    async get(): Promise<T | null> {
      const item = await localforage.getItem<{ value: any; expiresAt: number | null } | null>(name)
      if (item === null) {
        return null
      }

      const {
        value,
        expiresAt,
      } = item

      const isExpired = expiresAt !== null && expiresAt < new Date()
        .getTime()

      const isValid = !validator || validator(value)
      if (isExpired || !isValid) {
        return null
      }

      return value
    },
    async set(value: T, ttl?: number): Promise<void> {
      if (validator && !validator(value)) {
        return
      }

      const expiresAt = ttl ? new Date()
        .getTime() + (ttl * 1000) : null

      await localforage.setItem(name, {
        value,
        expiresAt,
      })
    },
    async delete(): Promise<void> {
      localforage.removeItem(name)
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidPremise(p: any): p is PremiseDetail {
  if (!p || typeof p.sprn !== 'number' || typeof p.address !== 'string') {
    return false
  }

  return true
}

export const premiseStore = createStore<PremiseDetail>('premise', isValidPremise)

export const orderStore = createStore<OrderState>('order')

export const utmStore = createStore<UTMState>('utm')
