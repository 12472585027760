import { css } from 'linaria'
import fontSizes from '../../../../../styles/fontSizes'
import theme, { COLORS } from '../../../../../styles/theme'

const classes = {
  input: css`
    &.MuiInputBase-input {
      height: 39px;
      border: 1px solid ${theme.palette.text.disabled};
      border-radius: 10px;
      padding: 0 ${theme.spacing(2)}px;
      font-size: ${fontSizes.body3};
      :hover, :focus {
        border: 2px solid ${theme.palette.primary.main};
      }
    }
  `,
  discountWrapper: css`
    .MuiInputBase-root.Mui-disabled {
      min-width: 135%;
      min-height: 42px;
      border-radius: 10px !important;
      background-color: #E6F4ED;
      .MuiInputBase-input {
        border: none;
        margin: 0;
        &:hover {
          border: none;
          margin: 0;
        }
      }
    }
    .MuiFormLabel-root.Mui-disabled {
      color: ${theme.palette.primary.main};
    }
    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      bottom: -20px;
    }
    .MuiInputAdornment-root, .MuiInputBase-inputAdornedStart {
      color: #00912D;
      font-weight: ${theme.typography.fontWeightLight!}
    }
    .MuiInputAdornment-root {
      position: relative;
      left: 15px;
    }
  `,
  buttonWrapper: css`
    flex-basis: 28%;
    align-self: flex-end;
    padding: ${theme.spacing(0, 0, 0, 1)};
    margin-left: 2px;
    .MuiButtonBase-root {
      height: 42px;
      width: 100%;
      max-width: 153px;
    }
  `,
  discountText: css`
    flex-basis: 75%;
    max-width: 410px;
    ${theme.breakpoints.up('md')} {
      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.95) !important;
      }
    }
  `,
  submit: css`
    p {
      font-weight: ${theme.typography.fontWeightBold!};
    }
    &.MuiGrid-item {
      margin-top: ${theme.spacing(5)}px;
    }
  `,
  submitting: css`
    >button.MuiButton-containedPrimary.Mui-disabled,
    >button.MuiButton-container:hover.Mui-disabled {
      background-color: ${COLORS.secondaryContrast};
      opacity: 0.8;
    }
  `,
  gridNoMargin: css`
    &.MuiGrid-container {
      margin: 0;
    }
  `,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
  `,
}

export default classes
