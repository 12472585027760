import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  wholeBar: css`
    width:100%;
    z-index:222;
    bottom:0;
    position:fixed;
    height:70px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    `,
  content: css`
    display:flex;
    align-items:center;
    gap:5px;
    color:white;
    `,
  QidcoLogo: css`
    height:30px;
    padding-bottom:5px;
    `,
  TopCashBackLogo: css`
    padding-bottom: 19px;
    height: 40px;
    `,
  closeIcon: css`
    cursor:pointer;
    position:absolute;
    right:0; 
    top:0;
    color:white;
    padding:${theme.spacing(1, 1)}
    `,

}
export default classes
