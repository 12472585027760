import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  mobileArrow: css`
    position: absolute;
    top: ${theme.spacing(2) + 1}px;
    z-index: 1000;
    cursor: pointer;
    color: ${theme.palette.primary.main};
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 50%;
    }
  `,
  mobileArrowForward: css`
  right: 5vw;
`,
  mobileArrowBack: css`
    right: 35vw;
    margin-right: -32px;
    svg {
      position: relative;
      left: 6px; // MUI icon bug
    }
 `,
  indicatorArrowContainer: css`
    display: flex;
    width: 95%;
    align-items: center;
    position: relative;
    top: -10px;
    padding-bottom: ${theme.spacing(1)}px;
    button {
      cursor: pointer;
      color: ${theme.palette.primary.main};
      background: none;
      border: none;
      position: relative;
      top: -2px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiSvgIcon-root {
        width: 15px;
      }
    }
    button:first-child {
      top: 1px;
    }
  `,
  mobileIndicators: css`
    .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
      height: 8px;
      width: 8px;
      padding: 8px;
    }
 `,
  textwithImageContainer: css`
    color: ${theme.palette.primary.main};
    text-align: center;
    img {
      margin: ${theme.spacing(4, 0, 3)};
      max-width: 100%;
    }
    .MuiTypography-h3 {
      max-width: 550px;
      margin: 0 auto ${theme.spacing(2)}px auto;
      ${theme.breakpoints.up('md')} {
        font-size: ${fontSizes.h2};
      }
    }
    .MuiTypography-body1 {
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightLight!};
      p.footnote {
        line-height: 1;
      }
      sub {
        font-size: ${fontSizes.subtitle2!};
      }
    }
    + button {
      margin-top: ${theme.spacing(2)}px;
    }
  `,
}

export default classes
