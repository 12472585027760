import { css } from 'linaria'
import theme, {
  COLORS, ROUNDED, fontWeights,
} from '../../../../../styles/theme'
import commonStyling from '../../../../../styles/commonStyling'
import fontSizes from '../../../../../styles/fontSizes'

const classes = {
  importantInfo: css`
    color: ${COLORS.error};
    margin-top: ${theme.spacing(1)}px;
    .MuiTypography-body1 {
      font-size: 14px;
      &.italicfont{
        font-style: italic;
      }
    }
    .MuiTypography-body2 {
      font-size: 16px;
      padding: 0 !important;
    }
  `,
  expansionPanelDetails: css`
    margin-bottom: ${theme.spacing(2)}px;
    flex-direction: column;
    gap: ${theme.spacing(0.5)}px;
  `,
  expansionPanelTight: css`
    &, &.MuiExpansionPanelSummary-root, &.MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 0px;
    }
    + .MuiCollapse-container .MuiCollapse-wrapperInner {
      padding-bottom: ${theme.spacing(2)}px;
    }
  `,
  productLabel: css`
    &.MuiTypography-body2 {
      line-height: 1.2;
      font-weight: ${fontWeights.semibold};
    }
  `,
  basketTitle: css`
    flex-grow: 1;
    &.MuiTypography-h4 {
      font-size: 21px !important;
    }
  `,
  stickyDesktopContainer: css`
    position: sticky;
    top: ${theme.spacing(2)}px;
    .MuiButton-root {
      min-width: 100%;
    }
    ${theme.breakpoints.up('md')} {
      max-width: 325px;
    }
    button[type=submit] {
      width: 100%;
    }
  `,
  stickyMobileContainer: css`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    .MuiExpansionPanelSummary-root {
      padding: 0 ${theme.spacing(2)}px;
    }
    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content.Mui-expanded,
    .MuiExpansionPanelSummary-root.Mui-expanded {
      margin: 0;
    }
    .MuiExpansionPanelDetails-root {
      background-color: ${COLORS.white};
      margin: 0 ${theme.spacing(2)}px;
      padding: 0;
      border-radius: ${commonStyling.roundedBorders.bottom};
      display: flex;
      flex-direction: column;
      justify-content: center;
      > div {
        width: auto;
      }
    }
    .MuiPaper-root {
      box-shadow: none;
      background-color: transparent;
      min-width: 100%;
    }
  `,
  container: css`
    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(2)}px;
      .MuiTypography-h4 {
        font-size: ${fontSizes.h3};
      }
    }
  `,
  backdropContainer: css`
    background-color: rgba(0,0,0,0.5);
    min-height: 100%;
  `,
  monthlyHeader: css`
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${ROUNDED.medium}px;
    color: white;
    min-height: 64px;
    .MuiSvgIcon-root {
      width: 35px;
      height: 25px;
      padding-right: ${theme.spacing(2)}px;
    }
    .MuiTypography-root {
      padding: 0 ${theme.spacing(1)}px;
      span {
        padding: 0 6px;
      }
    }
    ${theme.breakpoints.up('md')} {
      border-radius: ${ROUNDED.medium}px ${ROUNDED.medium}px 0 0;
    }
  `,
  openMonthlyHeader: css`
    border-radius: ${ROUNDED.medium}px ${ROUNDED.medium}px 0 0;
    .MuiSvgIcon-root {
      height: 32px;
      position: relative;
      right: -5px;
    }
  `,
  orderSummary: css`
    border: 1px solid ${COLORS.gray2};
    color: ${COLORS.primary};
    padding: ${theme.spacing(3, 2, 0, 2)};
    width: 100%;
    &:not(.hasTimeslot) {
      border-radius: 0 0 ${ROUNDED.medium}px ${ROUNDED.medium}px;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${COLORS.gray2};
      border-radius: 40px;
    }
    ${theme.breakpoints.up('md')} {
      width: auto;
    }
    ${theme.breakpoints.down('sm')} {
      max-height: 80vh;
      overflow-y: scroll;
    }
    a {
      cursor: pointer;
    }
    [class*=flagsContainer] {
      justify-content: flex-start;
    }
  `,
  noMaxHeight: css`
    max-height: 100%;
  `,
  strikeThrough: css`
    text-decoration-line: line-through;
    text-decoration-thickness: 1.5px;
  `,
  priceStrikeThrough: css`
    line-height: 1.2;
    text-decoration: line-through;
    font-weight:  ${fontWeights.medium};
    color: ${COLORS.error};
  `,
  actualPrice: css`
    font-weight: ${fontWeights.bold};
  `,
  basketExpansion: css`
  .MuiExpansionPanelSummary-content,
  .MuiExpansionPanelSummary-content.Mui-expanded,
  .MuiExpansionPanelSummary-root.Mui-expanded {
    margin: 0;
  }
  .MuiExpansionPanelSummary-root {
    align-items: flex-start;
    padding: 0;
  }
  .MuiPaper-root {
    box-shadow: none;
    border-radius: 0px !important;
    position: relative;
    min-width: 100%;
  }
  .MuiButtonBase-root .MuiExpansionPanelSummary-content {
    padding: ${theme.spacing(2, 0)};
  }
    .MuiGrid-grid-xs-3 {
      text-align: right;
    }
    .Mui-expanded > .MuiExpansionPanelSummary-expandIcon,
    .MuiExpansionPanelSummary-expandIcon {
      color: ${COLORS.primary};
      top: ${theme.spacing(1)}px;
      padding: ${theme.spacing(0, 0, 0, 1)};
    svg {
      font-size: ${fontSizes.h2};
    }
  }
  .Mui-expanded > .MuiExpansionPanelSummary-expandIcon {
    padding: ${theme.spacing(0, 1, 0, 0)};
  }
  .MuiExpansionPanelDetails-root {
    padding: 0;
  }
`,
  priceBreakdownExpansion: css`
    .MuiExpansionPanelSummary-root {
      justify-content: flex-start;
      min-height: auto;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: auto;
    }

    .MuiExpansionPanelDetails-root {
      flex-direction: column;
      margin-bottom: 15px;

      .MuiTypography-root {
        padding-top: ${theme.spacing(1)}px;
      }
    }

    .MuiExpansionPanelSummary-content {
      flex-direction: column;
      flex-grow: 0;
      padding: 0;
    }

    .MuiExpansionPanelSummary-expandIcon {
      bottom: 0 !important;
    }

    .MuiIconButton-edgeEnd {
      margin-right: 0;
    }

    .MuiTypography-root {
      font-size: ${fontSizes.subtitle2};
      text-decoration: underline;
      font-weight: ${theme.typography.fontWeightLight!} !important;
    }

    .MuiSvgIcon-root {
      height: 25px;
      width: 25px;
    }
  `,
  breakdownText: css`
    font-size: ${fontSizes.body2} !important;
    line-height: 1.2 !important;
    text-decoration: none !important;
  `,
  desktopDirectDebit: css`
    display: flex;
    max-width: 325px;
    color: #707070;
    .MuiGrid-root:first-child {
      align-self: flex-end;
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
      text-align: center;
      ${theme.breakpoints.up('lg')} {
        text-align: left;
        padding-left: ${theme.spacing(3)}px;
      }
    }
    .MuiLink-root {
      color: #707070;
      padding: 0;
    }
  `,
  installationContainer: css`
    color: white;
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    div {
      border-radius: ${commonStyling.roundedBorders.bottom};
      padding: ${theme.spacing(1)}px 0;
      text-align: center;
    }
  `,
  selectedSlotContainer: css`
    background-color: ${theme.palette.primary.main};
    div > .MuiTypography-root:nth-child(2) {
      text-transform: uppercase;
    }
  `,
  promoCode: css`
  border-top: 1px solid ${COLORS.gray2};
  .Mui-expanded > .MuiExpansionPanelSummary-expandIcon,
    .MuiExpansionPanelSummary-expandIcon {
      bottom: -5px;
    }
  .MuiButton-label > .MuiTypography-body1 {
    font-weight: ${theme.typography.fontWeightLight!};
  }
  `,
}

export default classes
