import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'
import theme, {
  BORDERS, COLORS, ROUNDED,
} from '../../styles/theme'

const classes = {
  calendarWrapper: css`
    min-height: ${theme.spacing(13.5)}px;
    div[class*='messageWrapper'] {
      margin-top: ${theme.spacing(3.75)}px;
      max-width: 86%;
      ${theme.breakpoints.down('sm')} {
        max-width: 100%;
      }
    }
    div[class*='spinner'] {
      position: relative;
      width: ${theme.spacing(7.5)}px;
      height: ${theme.spacing(7.5)}px;
      display: block;
      margin: 0 auto;
      margin-top: ${theme.spacing(11.25)}px;
      div {
        width: ${theme.spacing(7.5)}px;
        height: ${theme.spacing(7.5)}px;
      }
    }
  `,
  arrowButton: css`
    &.MuiButtonBase-root {
      background: ${COLORS.white};
      border-radius: ${ROUNDED.full};
      border: ${BORDERS.gray3};
      height: ${theme.spacing(6)}px;
      position: absolute;
      top: 50%;
      width: ${theme.spacing(6)}px;
      .MuiSvgIcon-root {
        font-size: ${fontSizes.h4};
      }
    }
    &.left {
      left: 0;
      transform: translate(-50%, -50%);
    }
    &.right {
      right: 0;
      transform: translate(50%, -50%);
    }
    ${theme.breakpoints.down('sm')} {
      &.MuiButtonBase-root {
        display: none;
      }
    }
  `,
  weekDay: css`
    &.MuiButtonBase-root {
      min-height: ${theme.spacing(6)}px;
      width: 100%;
    }
    .MuiButton-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &.currentDate:after {
      content: '';
      background-color: ${COLORS.primary};
      border-radius: 50%;
      height: ${theme.spacing(0.5)}px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, ${theme.spacing(0.5)}px);
      width: ${theme.spacing(0.5)}px;
    }
    ${theme.breakpoints.up('md')} {
      pointer-events: none;
    }
    ${theme.breakpoints.down('sm')} {
      &.MuiButton-root.MuiButton-text {
        border-radius: ${ROUNDED.small}px;
        min-width: 0px;
        padding-left: ${theme.spacing(0.5)}px;
        padding-right: ${theme.spacing(0.5)}px;
      }
    }
  `,
  dateRange: css`
    min-width: ${theme.spacing(27)}px;
    ${theme.breakpoints.down('sm')} {
      min-width: ${theme.spacing(26)}px;
    }
  `,
}

export default classes
