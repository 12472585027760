import { PackageProps } from '../components/Packages/Package'
import { ProductProps } from '../components/Products/Product'
import { getProductTerm } from './getProductDetails'

const productsHaveSameContract = ({
  broadband,
  bundle,
  voice,
  tv,
  wier,
  vas,
}: {broadband?: any; bundle?: PackageProps | null; voice?: ProductProps | null; tv?: ProductProps | null; wier?: ProductProps | null; vas?: ProductProps | null}) => {
  if (!voice && !tv && !wier && !vas && !bundle) {
    return true
  }

  const addOns = [
    voice,
    tv,
    wier,
    vas,
  ]
  const sameContractChecks: Array<boolean> = []
  const broadbandTerm = getProductTerm(broadband)
  const bundleTerm = getProductTerm(bundle!)

  addOns.forEach(addOn => {
    const addonTerm = getProductTerm(addOn!)
    if (addOn && (broadbandTerm === addonTerm || bundleTerm === addonTerm)) {
      sameContractChecks.push(true)
    } else if (addOn && (broadbandTerm !== addonTerm || bundleTerm === addonTerm)) {
      sameContractChecks.push(false)
    }
  })

  return sameContractChecks.every(check => check === true)
}

export default productsHaveSameContract
