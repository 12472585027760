import React from 'react'
import {
  Typography, Paper, Container,
} from '@material-ui/core'
import classes from './OrderError.styles'

const OrderError = ({ error }: {error: string}) => {
  return (
    <Container maxWidth="md">
      <Paper square className={classes.wrapper} id="payment-error">
        <Typography variant="h5">{error}</Typography>
      </Paper>
    </Container>
  )
}

export default OrderError
