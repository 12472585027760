import { Typography } from '@material-ui/core'
import { cx } from 'linaria'
import React from 'react'
import classes from './BroadbandTabs.styles'

interface TabComponentProps {
  iconSrc?: string;
  tabText?: string;
  handleClick: () => void;
  isActive: boolean;
}

export const TabComponent = ({
  iconSrc, tabText, handleClick, isActive,
}: TabComponentProps) => {
  return (
    <Typography component="div">
      <button className={cx(classes.container, !isActive && classes.notActive)} type="button" onClick={handleClick}>
        {iconSrc && <img alt={`${tabText}-icon`} src={iconSrc}/>}
        {tabText}
      </button>
    </Typography>
  )
}
