import React, {
  useState,
} from 'react'
import {
  Box, Typography, FormControl, InputLabel, Select, MenuItem, Grid, Container,
} from '@material-ui/core'
import {
  ArrowUpward, ArrowDownward, KeyboardArrowDown,
} from '@material-ui/icons'
import TrustPilotBox, { TrustPilotBoxProps } from '../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import { SpeedColumn } from '../ComparisonModal/ComparisonModal'
import { CtaProps } from '../../utils/commonProps'
import { cx } from 'linaria'
import classes from './ComparisonWidget.styles'
import StyledButton from '../StyledButton/StyledButton'

export interface ComparisonWidgetProps {
  anchor: string;
  title: string;
  subtitle: string;
  footnote: string;
  trustpilot_box: TrustPilotBoxProps;
  cf_plans: SpeedColumn[];
  preselected_range: CompetitorRange;
  competitors_plans: Competitor[];
  cta: CtaProps;
  preselected_box: boolean;
  box_title: string;
  box_text: string;
  box_cta: CtaProps;
}
export interface Competitor {
  name: string;
  ranges: CompetitorRange[];
}
export interface CompetitorRange {
  price: string;
  package: SpeedColumn;
}

export default function ComparisonWidget({
  anchor,
  title,
  subtitle,
  footnote,
  cf_plans,
  competitors_plans,
  cta,
  preselected_range,
  preselected_box,
  box_title,
  box_text,
  box_cta,
  trustpilot_box,
}: ComparisonWidgetProps) {
  const [
    preselectedBoxVisible,
    setPreselectedBoxVisible,
  ] = useState(preselected_box)

  const [
    brandSelected,
    setBrandSelected,
  ] = useState(!preselected_box)

  const competitorNameDefault = () => preselected_range.package && !preselected_box ? preselected_range.package.company_name : competitors_plans[0]?.name

  const [
    competitorName,
    setCompetitorName,
  ] = useState(competitorNameDefault)

  const competitorPriceDefault = () => preselected_range.package && !preselected_box ? preselected_range.price : competitors_plans[0]?.ranges[0].price

  const [
    competitorPrice,
    setCompetitorPrice,
  ] = useState(competitorPriceDefault)

  let nextCFIndex: number

  const competitorObj = competitors_plans?.filter((plan) => plan.name === competitorName)[0] as Competitor
  const competitorRange = competitorObj?.ranges.filter((range: CompetitorRange) => range.price === competitorPrice)[0]
  const competitorPackage = competitorRange?.package as SpeedColumn

  const cfPackage = cf_plans?.filter((x: SpeedColumn, index: number) => {
    const cfDownload = parseFloat(x?.avg_download)
    const competitorDownload = parseFloat(competitorPackage?.avg_download)
    if (cfDownload > competitorDownload && !nextCFIndex) {
      nextCFIndex = index + 1
      return x
    }

    return false
  })[0]

  const competitorNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const name = event.target.value
    const firstRange = competitors_plans?.filter((plan) => plan.name === name)[0]?.ranges[0].price as string
    setCompetitorName(name)
    setCompetitorPrice(firstRange)
    setBrandSelected(true)
  }

  const competitorPriceChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const price = event.target.value
    setCompetitorPrice(price)
    setPreselectedBoxVisible(false)
  }

  const createColumn = (data: SpeedColumn) => {
    if (data) {
      return (
        <div className={data?.company_name === 'Community Fibre' ? 'colCF' : 'colCompetitor'}>
          <Typography component="div" variant="overline" className={classes.comparisonRow}>
            <img src={data?.company_image} alt={data?.company_name}/>
          </Typography>
          <Typography component="div" variant="overline" className={classes.comparisonRow}>
            {data?.package}
          </Typography>
          <div className={classes.comparisonRow}>
            <div className={classes.comparisonRowValue}>
              <Typography variant="overline" component="span">Download:</Typography>
              {data.company_name === 'Community Fibre' ?
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.green}
                >
                  {data?.avg_download}
                  <ArrowUpward/>
                </Typography> :
                <Typography
                  variant="body2"
                  component="span"
                >
                  {data?.avg_download}
                </Typography>}
            </div>
            <Typography variant="caption" component="div">
              Avg. speed
            </Typography>
          </div>
          <div className={classes.comparisonRow}>
            <div className={classes.comparisonRowValue}>
              <Typography variant="overline" component="span">Upload:</Typography>
              {data.company_name === 'Community Fibre' ?
                <Typography
                  variant="body2"
                  className={classes.green}
                  component="span"
                >
                  {data?.avg_upload}
                  <ArrowUpward/>
                </Typography> :
                <Typography
                  variant="body2"
                  component="span"
                >
                  {data?.avg_upload}
                </Typography>}
            </div>
            <Typography variant="caption" component="div">
              Avg. speed
            </Typography>
          </div>
          <Typography component="div" variant="body2" className={classes.comparisonRow}>
            {data.company_name === 'Community Fibre' ?
              <div className={classes.green}>{data?.monthly_price}/month <ArrowDownward/></div> :
              <div>{data?.monthly_price}/month</div>}
          </Typography>
        </div>
      )
    }

    return false
  }

  const disabledSelect = cx(classes.dropdownPill, brandSelected ? '' : 'Mui-disabled')

  return (
    <Box py={10} id={anchor} className={classes.section}>
      <Container>
        <Grid container direction="column" justify="center" alignItems="center" spacing={5}>
          {title &&
            <Grid item sm={10} md={6}>
              <Typography color="primary" variant="h2" align="center">
                {title}
              </Typography>
            </Grid>}
          {subtitle &&
            <Grid item sm={10} md={6}>
              <Typography color="primary" variant="body1" className={classes.regular} align="center">
                {subtitle}
              </Typography>
            </Grid>}
        </Grid>
        <Box pt={7}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <FormControl className={classes.dropdownPill}>
                <InputLabel>Provider</InputLabel>
                <Select value={preselectedBoxVisible && !brandSelected ? null : competitorName} onChange={competitorNameChange} IconComponent={KeyboardArrowDown}>
                  {competitors_plans?.map((competitor: Competitor, index) => {
                    return (
                      <MenuItem key={index} value={competitor.name}>{competitor.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={disabledSelect}>
                <InputLabel>Range</InputLabel>
                <Select
                  onChange={competitorPriceChange}
                  IconComponent={KeyboardArrowDown}
                  value={preselectedBoxVisible ? null : competitorPrice}
                  className={brandSelected ? '' : 'Mui-disabled'}
                >
                  {competitorObj?.ranges.map((range: CompetitorRange, index) => {
                    return (
                      <MenuItem key={index} value={range.price}>
                        {range.price}
                      </MenuItem>
                    )
                  },
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {preselectedBoxVisible &&
          <Box mt={7}>
            <Grid container alignItems="center" justify="center">
              <Grid item md={6}>
                <Box
                  className={classes.preselectedBox}
                  p={{
                    xs: 4,
                    sm: 5,
                    md: 7,
                  }}
                >
                  {box_title &&
                    <Grid container spacing={3}>
                      {box_title &&
                        <Grid item>
                          <Typography color="primary" variant="h4" align="center">
                            {box_title}
                          </Typography>
                        </Grid>}
                      {box_text &&
                        <Grid item>
                          <Typography color="primary" variant="body2" align="center" className={classes.regular}>
                            {box_text}
                          </Typography>
                        </Grid>}
                    </Grid>}
                  {box_cta &&
                    <Box pt={5} display="flex" alignItems="center" justifyContent="center">
                      <StyledButton
                        color="primary"
                        isModal={box_cta.modal}
                        url={box_cta.url}
                      >
                        {box_cta.text}
                      </StyledButton>
                    </Box>}
                </Box>
              </Grid>
            </Grid>
          </Box>}
        {!preselectedBoxVisible &&
          <Box mt={7}>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.wrapper}
            >
              <Grid
                item
                xs={12} sm={10} lg={7}
                className={cx(classes.comparisonTable)}
              >
                {competitorPackage && cfPackage &&
                  <>
                    { createColumn(competitorPackage) }
                    { createColumn(cfPackage) }
                  </>}
              </Grid>
            </Grid>
          </Box>}
        {cta &&
          <Box pt={5} display="flex" alignItems="center" justifyContent="center">
            <StyledButton
              color="primary"
              isModal={cta.modal}
              url={cta.url}
            >
              {cta.text}
            </StyledButton>
          </Box>}
        {footnote &&
          <Grid
            container
            alignItems="center"
            justify="center"
          >
            <Grid sm={10} md={8} item>
              <Box pt={5}>
                <Typography variant="caption" component="div" align="center" className={classes.regular}>{footnote}</Typography>
              </Box>
            </Grid>
          </Grid>}
        {trustpilot_box && trustpilot_box.widget &&
          <TrustPilotBox
            background_color={trustpilot_box.background_color}
            widget={trustpilot_box.widget}
            padding={trustpilot_box.padding}
            margin={trustpilot_box.margin}
            wrapped={trustpilot_box.wrapped}
            inverted={trustpilot_box.inverted}
            width={trustpilot_box.width}
            offset={trustpilot_box.offset}
            mobile_center={trustpilot_box.mobile_center}
          />}
      </Container>
    </Box>
  )
}
