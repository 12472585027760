import { css } from 'linaria'

const classes = {
  mediaWrapper: css`
    max-width: 100%;
    div {
      height: 100%;
    }
  `,
  video: css`
    &.cover {
      object-fit: cover;
    }
  `,
  disabled: css`
    pointer-events: none;
  `,
  processing: css`
    opacity: 0.5;
  `,
  ratio_16to9: css`
    aspect-ratio: 16/9;
  `,
  centered: css`
    margin-left: auto;
    margin-right: auto;
  `,
}

export default classes
