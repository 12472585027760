
import React from 'react'
import {
  Box,
  Typography,
} from '@material-ui/core'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'
import { css } from 'linaria'

export interface StrikethroughNameProps {
    copy?: string;
    color?: string;
    name_image? : MediaImageProps;
}

const classes = {
  icon: css`
    height: 27px !important;
  `,
}

export default function StrikethroughName({
  copy,
  name_image,
}: StrikethroughNameProps) {
  return (
    <Box display="flex" mr={1}>
      {name_image && name_image?.src &&
      <Box mr={1}>
        <MediaImage
          src={name_image.src}
          alt={name_image.alt}
          width={name_image.width || 13}
          height={name_image.height || 27}
          className={`${name_image.height === 27 ? classes.icon : ''}`}
        />
      </Box>}
      <Typography
        variant="h4" color="primary" style={{
          textDecorationLine: 'line-through',
          textDecorationThickness: '2px',
        }}
      >{copy}
      </Typography>
    </Box>
  )
}
