import React, { useState } from 'react'
import {
  Tabs as MuiTabs, Tab as MuiTab, Typography, Grid,
} from '@material-ui/core'
import HelpCategories from './HelpCategories'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'
import { useWindowWidth } from '../../utils/useWindowWidth'
import commonStyling from '../../styles/commonStyling'
import classes from './HelpCategoryList.styles'
import { CtaProps } from '../../utils/commonProps'
import StyledButton from '../StyledButton/StyledButton'
import { cx } from 'linaria'

export interface HelpCategory {
  image_src: string;
  title: string;
  button_text: string;
  button_url: string;
}

export interface HelpCategoryListProps {
  title?: string;
  help_categories?: HelpCategory[];
  tabs?: {
    title: string;
    categories: HelpCategory[];
  }[];
  button?: CtaProps;
  button_colour?: 'primary' | 'secondary';
  anchor_ref?: string;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function HelpCategoryList({
  title, help_categories, tabs, anchor_ref, button, button_colour,
}: HelpCategoryListProps) {
  const [
    activeTab,
    setActiveTab,
  ] = useState(0)

  const onChange = (_event: React.ChangeEvent<{}>, newActiveIndex: number) => {
    setActiveTab(newActiveIndex)
  }

  const categories = tabs?.length ? tabs[Number(activeTab)].categories : help_categories
  const windowWidth = useWindowWidth()

  return (
    <div className={cx(classes.container, tabs?.length && classes.backgroundContainer)} id={anchor_ref}>
      <SectionWithMargin hasTop>
        <Typography variant="h3" color="primary" align="center">{title}</Typography>
        {tabs && tabs?.length > 0 &&
        <MuiTabs
          value={activeTab}
          variant={windowWidth && windowWidth > 960 ? 'standard' : 'fullWidth'}
          centered
          onChange={onChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'white',
              height: '100%',
              zIndex: 0,
              borderRadius: commonStyling.roundedBorders.top,
            },
          }}
        >
          {tabs.map((tab: { title: string}, i: number) => (
            <MuiTab
              label={tab.title} key={tab.title} {...a11yProps(i)}
              classes={{
                root: classes.categoryTab,
                selected: classes.selectedTab,
              }}
            />
          ))}
        </MuiTabs>}
        <div className={classes.categoryContainer}>
          <ContainerWithPadding>
            <HelpCategories categories={categories}/>
            {button?.text &&
            <Grid container justify="center">
              <StyledButton url={button.url} color={button_colour ?? 'primary'}>
                {button.text}
              </StyledButton>
            </Grid>}
          </ContainerWithPadding>
        </div>
      </SectionWithMargin>
    </div>
  )
}
