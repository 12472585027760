import { css } from 'linaria'
import theme, {
  COLORS, ROUNDED, fontWeights,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'

const classes = {
  messageWrapper: css`
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    color: ${COLORS.white};
    border-radius: ${ROUNDED.medium}px;
    padding: ${theme.spacing(3)}px;
    gap: ${theme.spacing(2)}px;
    max-width: 100%;
    .MuiTypography-body2 {
      font-size: ${theme.spacing(2)}px;
      font-weight: ${fontWeights.medium};
    }
    a {
      font-weight: ${fontWeights.bold};
    }
    strong {
      b {
        font-size: ${fontSizes.h4};
        font-weight: ${fontWeights.semibold};
      }
    }
  `,
  blue: css`
     background-color: ${COLORS.blue};
     color: ${COLORS.white};
  `,
  yellow: css`
    background-color: ${COLORS.yellow};
    color: ${COLORS.bodyTextPrimary};
    a {
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  yellowLight: css`
    background-color: ${COLORS.yellowLight};
    color: ${COLORS.bodyTextPrimary};
    a {
      color: ${COLORS.bodyTextPrimary};
    }
  `,
  orange: css`
    background-color: ${COLORS.orange};
  `,
}

export default classes
