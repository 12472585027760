import React from 'react'
import { Grid } from '@material-ui/core'
import BlogTile from '../BlogTile/BlogTile'
import PressReleaseTile from '../PressReleaseTile/PressReleaseTile'
import { BlogPost } from '../../../utils/blog'

interface LayoutItemProps {
  type_items: string;
  post_items: BlogPost[];
}

export default function LayoutItem({
  type_items, post_items,
}: LayoutItemProps) {
  if (post_items.length === 0) {
    return null
  }

  return (
    <Grid container>
      {post_items.map(item => (
        <Grid item key={item.title} xs={12} sm={6} lg={4}>
          {type_items === 'press-release' ? <PressReleaseTile {...item}/> : <BlogTile {...item}/>}
        </Grid>
      ))}
    </Grid>
  )
}
