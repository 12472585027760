import React from 'react'
import {
  AppBar, Container, Toolbar, Grid, useMediaQuery,
} from '@material-ui/core'
import ContactUs, { ContactUsProps } from '../../ContactUs/ContactUs'
import StyledButton from '../../StyledButton/StyledButton'
import TrustPilotBox, { TrustPilotBoxProps } from '../../TrustPilotWidget/TrustPilotBox/TrustPilotBox'
import SecureIcon from '../icons/SecureIcon'
import { MiniBasket } from '../MiniBasket/MiniBasket'
import DesktopMainHeaderItem from './DesktopMainHeaderItem'
import classes from './DesktopHeader.styles'
import logo from '../logoDesktop.svg'
import logoInverted from '../logoDesktopInverted.svg'
import { MenuItem } from '../Header'
import { CtaProps } from '../../../utils/commonProps'
import theme from '../../../styles/theme'

interface DesktopMainHeaderProps {
  secure?: boolean;
  activeMenuItems?: MenuItem[];
  setMenuIndex: (index: number | null) => void;
  menuIndex: number | null;
  trustpilot_box?: TrustPilotBoxProps;
  contactUs?: ContactUsProps;
  button?: CtaProps | null;
  miniBasket?: boolean;
  miniBasketError?: string;
  openSubMenu: boolean;
  inverted?: boolean;
  setOpenSubMenu: (open: boolean) => void;
}

const DesktopMainHeader = ({
  secure,
  activeMenuItems,
  setMenuIndex,
  menuIndex,
  trustpilot_box,
  contactUs,
  button,
  miniBasket,
  miniBasketError,
  openSubMenu,
  setOpenSubMenu,
  inverted,
}: DesktopMainHeaderProps) => {
  const closeSubMenu = () => {
    setOpenSubMenu(false)
  }

  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <AppBar position="relative" elevation={0}>
      <Container>
        <Toolbar>
          <a
            href="/"
            onFocus={closeSubMenu}
          >
            {inverted ?
              <img src={logoInverted} alt="CommunityFibre - better internet for everyone" height="54" width="220"/> :
              <img src={logo} alt="CommunityFibre - better internet for everyone" height="54" width="220"/>}
          </a>
          <Grid container justify="flex-end" alignItems="center">
            <Grid
              component="ul" container direction="row"
              wrap="nowrap" classes={{ root: classes.list }}
              spacing={isMedium ? 3 : 4}
              justify="flex-end"
              alignItems="center"
              onMouseLeave={closeSubMenu}
            >
              {activeMenuItems && activeMenuItems.map((navItem, i) => (
                <DesktopMainHeaderItem
                  item={navItem}
                  index={i}
                  setMenuIndex={setMenuIndex}
                  key={i}
                  menuIndex={menuIndex}
                  openSubMenu={openSubMenu}
                  setOpenSubMenu={setOpenSubMenu}
                />
              ))}
              {trustpilot_box && trustpilot_box.widget &&
                <div className={classes.trustpilotWrapper}>
                  <TrustPilotBox
                    background_color={trustpilot_box.background_color}
                    widget={trustpilot_box.widget}
                    padding={trustpilot_box.padding}
                    margin={trustpilot_box.margin}
                    wrapped={trustpilot_box.wrapped}
                    inverted={trustpilot_box.inverted}
                    width={trustpilot_box.width}
                    offset={trustpilot_box.offset}
                    mobile_center={trustpilot_box.mobile_center}
                  />
                </div>}
              {secure && <SecureIcon color="secondary" className={classes.secureIcon}/>}
              {contactUs && Object.values(contactUs).length > 0 &&
                <ContactUs
                  help_text={contactUs.help_text}
                  phone_icon={contactUs.phone_icon}
                  phone_number={contactUs.phone_number}
                  call_me_back_text={contactUs.call_me_back_text}
                  start_time={contactUs.start_time}
                  end_time={contactUs.end_time}
                  saturday_start_time={contactUs.saturday_start_time}
                  saturday_end_time={contactUs.saturday_end_time}
                  call_me_back_modal={contactUs.call_me_back_modal}
                  visible_desktop={contactUs.visible_desktop}
                  visible_mobile={contactUs.visible_mobile}
                />}
            </Grid>
            <Grid
              className={classes.buttonsContainer}
              onFocus={closeSubMenu}
            >
              {button?.text &&
              <StyledButton
                color="primary"
                url={button.url}
                isModal={button?.modal}
              >
                {button.text}
              </StyledButton>}

              {miniBasket && <MiniBasket desktop anchor="right" errorMessage={miniBasketError}/>}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default DesktopMainHeader
