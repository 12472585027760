import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  componentTitle: css`
    ${theme.breakpoints.up('md')} {
      &.MuiTypography-h3 {
        font-size: ${fontSizes.h2};
      }
    }
  `,
  bodyText: css`
    &.MuiTypography-body1 {
      text-align: center;
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${theme.typography.fontWeightLight!};
      max-width: ${commonStyling.breakpoints.desktopSmall};
    }
  `,
  cardOuter: css`
    background: none;
    border: none;
    min-width: 100%;
    padding: 0;
    cursor: pointer;
    position: relative;
    transition: top .2s ease-in-out;
    top: 0;
    color: ${theme.palette.primary.main};
    ${theme.breakpoints.up('md')} {
      &:hover {
        position: relative;
        top: -8px;
      }
   }
  `,
  overlayWrapper: css`
    &:after {
      content: "";
      position: absolute;
      z-index: -10;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${theme.palette.primary.main};
      opacity: .6;
      border-radius: ${commonStyling.roundedBorders.all};
    }
  `,
  flipcard: {
    cardFlipped: css`
      transform: rotateY(180deg) !important;
      transition: transform 1s;
      transform-style: preserve-3d;
    `,
    inner: css`
      min-height: 450px;
      box-shadow: ${commonStyling.boxShadowDark};
      border-radius: ${commonStyling.roundedBorders.all};
      min-width: 100%;
      position: relative;
      text-align: center;
      transform: rotateY(0);
      transition: transform 1s;
      transform-style: preserve-3d;
      display: flex;
      justify-content: center;
    `,
    cardSide: css`
      min-height: 450px;
      position: relative;
      backface-visibility: hidden;
      overflow: hidden;
      border-radius: ${commonStyling.roundedBorders.all};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: transform .2s ease-in-out !important;
      padding: ${theme.spacing(0, 3)};
    `,
    cardShortSide: css`
      min-height: 330px;
      max-height: 330px;
      border-radius: ${commonStyling.roundedBorders.top};
      &::after{
        border-radius: ${commonStyling.roundedBorders.top};
      }
    `,
    front: css`
      transform: rotateY(0);
      overflow: visible;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .MuiTypography-root {
        color: white;
      }
      ${theme.breakpoints.up('md')} {
        .MuiTypography-h3 {
          font-size: ${fontSizes.h2};
        }
      }
    `,
    back: css`
      background-color: white;
      position: absolute;
      transform: rotateY(180deg);
      min-height: 100%;
      overflow: visible;
      text-align: left;
      .MuiTypography-body2 {
        margin-bottom: ${theme.spacing(2)}px;
      }
      .MuiTypography-caption {
        color: ${COLORS.bodyTextPrimary};
        font-size: ${fontSizes.body2};
      }
    `,
    features: css`
      font-weight: ${theme.typography.fontWeightLight!};
      .MuiTypography-root {
        color: ${COLORS.bodyTextPrimary};
        text-align: left;
        font-weight: ${theme.typography.fontWeightLight!};
      }
      p{
        color: ${COLORS.bodyTextPrimary};
        font-weight: ${theme.typography.fontWeightLight!};
      }
      ul {
        list-style: none;
        margin-top: ${theme.spacing(1)}px;
        margin-left: ${theme.spacing(2)}px;
        padding: 0;
        color: ${COLORS.bodyTextPrimary};
        font-weight: ${theme.typography.fontWeightLight!};
        li {
          padding: 6px 0;
          display: flex;
          :before {
            margin-top: 2px;
            content: url('/icon-check-primary.svg');
            position: relative;
            left: -11px;
          }
        }
      }
    `,
  },
  flipCardInner: css`
    width: 100%;
  `,
  readMoreOverlay: css`
    &.MuiTypography-root{
      color: white;
    }
  `,
  frontButton: css`
    color: white;
    position: absolute;
    bottom: ${theme.spacing(4)}px;
    right: ${theme.spacing(4)}px;
    background: none;
    display: flex;
    border: none;
    align-items: center;
    cursor: pointer;
    img {
      margin-left: ${theme.spacing(1)}px;
    }
  `,
  frontReadButton: css`
    color: white;
    position: absolute;
    bottom: ${theme.spacing(2)}px;
    right: 0;
    left: 0;
    margin: ${theme.spacing(0, 3)};
    background: none;
    border: none;
    align-items: center;
    cursor: pointer;
    text-align: center;
    .MuiTypography-body1.MuiTypography-colorPrimary{
      margin-top: ${theme.spacing(1)}px;
      text-decoration: underline;
    }
    .MuiTypography-root{
      img{
        display: inline-flex;
        margin-left: ${theme.spacing(1)}px;
      }
    }
  `,
  sliderContainer: css`
    .slick-slide > div {
      margin: 0 4px;
      width: 75vw;
    }
    .slick-slide {
      padding-bottom: ${theme.spacing(4)}px;
    }
    .slick-list {
      overflow: visible !important;
      transition: all ease-in-out .5s;
      ${theme.breakpoints.up('md')} {
        .slick-slide > div {
          width: auto;
        }
      }
    }
  `,
  backButtonContainer: css`
    margin-bottom: ${theme.spacing(4)}px;
    button {
      background: none;
      border: none;
      display: flex;
      cursor: pointer;
      align-items: center;
      text-decoration: none;
      color: ${theme.palette.primary.main};
      svg {
        color: ${theme.palette.primary.main};
        height: 12px;
        position: relative;
        top: 2px;
      }
    }
    img {
      cursor: pointer;
      margin-left: auto;
    }
  `,
}

export default classes
