import React, { useContext } from 'react'
import {
  Box, Grid, Link, Typography,
} from '@material-ui/core'
import classes from './BannerWithLogo.styles'
import { cx } from 'linaria'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import formatAddress from '../../utils/formatAddress'
import BroadbandTabs, {
  BroadbandTabsProps,
} from '../BroadbandTabs/BroadbandTabs'
import BannerWithIcon, {
  BannerWithIconProps,
} from '../BannerWithIcon/BannerWithIcon'
import router from 'next/router'
import checkCircle from '../../../public/check-circle.svg'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useGeneralContext } from '../GeneralContext/GeneralContext'

export interface Button {
  text: string;
  url: string;
  modal: boolean;
}

export interface BannerWithLogoProps {
  readonly meta: {
    id: number;
  };
  title: string;
  body?: string;
  extra_height?: boolean;
  address?: boolean;
  button?: Button;
  tabs?: BroadbandTabsProps;
  banner_with_icon?: BannerWithIconProps;
  social_proof_boroughs?: string;
  social_proof_keyword?: string;
  componentType?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' ;
}

export default function BannerWithLogo({
  meta,
  title,
  body,
  extra_height,
  address,
  button,
  tabs,
  banner_with_icon,
  social_proof_boroughs,
  social_proof_keyword,
  componentType,
}: BannerWithLogoProps) {
  const bannerStyling = extra_height ?
    cx(classes.container, classes.biggerContainer) :
    classes.container
  const { premise } = useContext(PremiseContext)
  const formattedAddress = premise ? formatAddress(premise.address) : null

  const {
    generalOptions, setGeneralOptions,
  } = useGeneralContext()

  const { closedBannerWithIcon } = generalOptions

  function setClosedBannerWithIcon(closedBannerWithIcon: boolean) {
    setGeneralOptions({
      ...generalOptions,
      closedBannerWithIcon,
    })
  }

  const handleClose = () => {
    setClosedBannerWithIcon(true)
    marketingEvents.closedSocialProofBanner()
  }

  let formattedBody = banner_with_icon?.body

  if (social_proof_boroughs && social_proof_keyword) {
    const socialProofBoroughs = social_proof_boroughs
      .split(/\r?\n/)
      .map((a) => a)
    const currentBorough = socialProofBoroughs.filter((e) => premise?.borough ? e.includes(premise?.borough) : null)[0]
    if (currentBorough) {
      const currentBoroughAmount = currentBorough.split(' | ')[1]
      formattedBody = banner_with_icon?.body.replace(social_proof_keyword, currentBoroughAmount)
    } else {
      formattedBody = null
    }
  }

  return (
    <Box component="section" className={bannerStyling} id={`BannerWithLogo_${meta.id}`}>
      <Box className={classes.content} alignItems="center" justifyContent="center" flexDirection="column" display="flex">
        {title && title !== ' ' && <Typography variant="h2">{title}</Typography>}
        <Grid container alignItems="center" justify="center">
          <Box my={2} className={classes.address}>
            <Box mb={1}>
              <Typography component={componentType ? componentType : 'p'} variant="h1">
                <img
                  src={checkCircle}
                  className={classes.icon}
                  alt="check circle"
                />{body ? body : 'Great news! We are available at'}
              </Typography>
            </Box>
            {address && formattedAddress && (
              <address>
                <Typography variant="body2" component="div">
                  {formattedAddress}
                  {'. '}
                  {button &&
                  <Link
                    onClick={() => {
                      router.push(`${router.asPath}${button?.url}`, undefined, {
                        shallow: true,
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body2">
                      {button?.text}
                    </Typography>
                  </Link>}
                </Typography>
              </address>)}
          </Box>
        </Grid>
        {formattedBody && banner_with_icon?.body && !closedBannerWithIcon && (
          <BannerWithIcon
            {...banner_with_icon}
            body={formattedBody}
            closedBannerWithIcon={closedBannerWithIcon}
            handleClose={handleClose}
          />
        )}
      </Box>
      {tabs && Object.values(tabs).length > 0 && (
        <Box pt={3} className={cx(closedBannerWithIcon && classes.tabsWrapper)}>
          <BroadbandTabs
            broadband_icon={tabs?.broadband_icon}
            broadband_tab={tabs?.broadband_tab}
            bundles_tab={tabs?.bundles_tab}
            bundles_icon={tabs?.bundles_icon}
          />
        </Box>)}
    </Box>
  )
}
