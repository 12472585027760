import React from 'react'
import {
  css, cx,
} from 'linaria'
import bottomCurveLight from '../../public/bottomCurveLight.svg'
import bottomCurveLightMobile from '../../public/bottomCurveLightMobile.svg'
import {
  Hidden, IconButton, Box,
} from '@material-ui/core'
import theme from './theme'
import { ExpandMore } from '@material-ui/icons'

const classes = {
  container: css`
    background-color: ${theme.palette.warning.light};
  `,
  gradient: css`
    background-image: linear-gradient(180deg, white 0%, ${theme.palette.warning.light} 100%);
  `,
  containerWithPadding: css`
    padding-top: ${theme.spacing(5)}px;
    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacing(8)}px;
    }
  `,
  buttonContainer: css`
    text-align: center;
    position: relative;
    img {
      max-width: 100%;
      margin: auto;
      height: auto;
    }
  `,
  curveImageMobile: css`
    width: 100%;
    height: 100%;
  `,
  curveImage: css`
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  `,
  curveButton: css`
    position: absolute !important;
    margin: auto !important;
    bottom: -20px;
    left: 0;
    right: 0;
    background-color: white !important;
    padding: 0px !important;
    height: 48px;
    width: 48px;
    .MuiSvgIcon-root {
      fill: ${theme.palette.primary.main};
    }
  `,
}

export const ContainerWithCurve = ({
  sectionClassName, className, children, link, withPadding, id, gradient, py, pb,
}: {
  sectionClassName?: string;
  className?: string;
  children?: React.ReactNode;
  link?: string;
  withPadding?: boolean;
  id?: string;
  gradient?: boolean;
  py?: number;
  pb?: number;
}) => {
  return (
    <section className={sectionClassName} id={id}>
      <div>
        <Box
          className={cx(gradient ? classes.gradient : classes.container, withPadding && classes.containerWithPadding, className)}
          py={py}
          {...pb && { pb }}
        >
          {children}
        </Box>
        <Hidden smDown>
          <img src={bottomCurveLight} alt="" className={classes.curveImage}/>
        </Hidden>
        <Hidden mdUp>
          <img src={bottomCurveLightMobile} alt="" className={classes.curveImageMobile}/>
        </Hidden>
        <div className={classes.buttonContainer}>
          {link &&
            (
              <IconButton
                aria-label="href"
                href={link}
                className={classes.curveButton}
              >
                <ExpandMore fontSize="inherit"/>
              </IconButton>
            )}
        </div>
      </div>
    </section>
  )
}
