import {
  css,
} from 'linaria'
import fontSizes from '../../../../../styles/fontSizes'
import theme from '../../../../../styles/theme'

const classes = {
  timeContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${theme.palette.warning.light};
    &.MuiTypography-body1 {
        font-size: ${fontSizes.body2};
        color: ${theme.palette.primary.main};
    }
    ${theme.breakpoints.up('md')} {
      &.MuiTypography-body1 {
        font-size: ${fontSizes.body1};
      }
    }
  `,
  timeBanner: css`
    &.MuiPaper-root {
      border-radius: 15px;
      color: white;
      text-align: center;
      background-color: ${theme.palette.warning.light};
      .MuiSvgIcon-root {
        margin: 0 ${theme.spacing(1)}px;
      }
    }
  `,
  countdownWrapper: css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      align-items: center;
    }
  `,
  countdown: css`
    font-size: ${fontSizes.h3};
    font-weight: ${theme.typography.fontWeightMedium!} !important;
    margin-right: ${theme.spacing(1)}px;
  `,
  text: css`
    font-size: ${fontSizes.body2} !important;

    ${theme.breakpoints.up('md')} {
      font-size: ${fontSizes.body1} !important;
    }
  `,
}

export default classes
