import {
  css,
} from 'linaria'
import theme, {
  COLORS, fontWeights,
} from '../../../../../styles/theme'
import fontSizes from '../../../../../styles/fontSizes'
import commonStyling from '../../../../../styles/commonStyling'

export const classes = {
  header: css`
    background-color: ${theme.palette.primary.main};
    min-height: 77px;
    position: relative;
    padding-top: ${theme.spacing(2)}px;
    .MuiTypography-root {
      color: white;
    }
  `,
  backButton: css`
    &.MuiButton-root {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 3%;
      color: white;
      min-width: min-content;
    }
    &.MuiTypography-root {
      text-decoration: underline;
      font-weight: ${theme.typography.fontWeightLight!}
    }
  `,
  subHeading: css`
    padding: ${theme.spacing(1)}px 0 ${theme.spacing(4)}px 0;
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!}
    }
  `,
  button: css`
    &.MuiButton-root {
      min-width: ${theme.spacing(30.87)}px;
      margin-top: ${theme.spacing(4)}px;
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    }
  `,
  selectedTimeslot: css`
    color: ${COLORS.bodyTextPrimary};
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(2)}px;
    .MuiTypography-root {
      display: flex;
      flex-direction: column;
      span:last-child {
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
    b {
      margin-right: ${theme.spacing(1)}px;
    }
    ${theme.breakpoints.up('md')} {
      text-align: left;
      .MuiTypography-root {
        display: block;
      }
    }
  `,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
    `,
  title: css`
    h1 {
      font-size: ${fontSizes.h2};
      font-weight: ${fontWeights.semibold};
      margin-bottom:${theme.spacing(2)}px;
      ${theme.breakpoints.down('xs')} {
          text-align: left;
      }
    }
    p:last-of-type {
      margin-top:${theme.spacing(3)}px;
    }
`,
}
