import {
  css,
} from 'linaria'
import commonStyling from '../../../../../styles/commonStyling'
import fontSizes from '../../../../../styles/fontSizes'
import theme, {
  COLORS, fontWeights,
} from '../../../../../styles/theme'

export const classes = {
  hidenProducts: css`
    display:none;
  `,
  container: css`
    color: ${theme.palette.primary.main};
    .MuiSvgIcon-colorSecondary {
      cursor: pointer;
    }
    #payment-error {
      margin-top: ${theme.spacing(5)}px;
    }
  `,
  detailContainer: css`
    &.MuiGrid-root {
      background-color: ${theme.palette.warning.light};
      padding: ${theme.spacing(3)}px;
      border-radius: ${commonStyling.roundedBorders.all};
      margin: ${theme.spacing(1)}px 0;
      .MuiTypography-body2 {
        font-weight: ${theme.typography.fontWeightLight!};
      }
      ${theme.breakpoints.up('md')} {
          flex-basis: 49%;
          margin: ${theme.spacing(2)}px 0;
        }
      }
  `,
  infoBanner: css`
    display: flex;
    align-items: center;
    color: white;
    background-color: ${theme.palette.warning.main};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(2)}px;
    box-shadow: 0px 3px 6px #00000054;
    .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
      margin-right: ${theme.spacing(1)}px;
    }
  `,
  infoBannerPrimary: css`
    background-color: ${theme.palette.primary.main};
  `,
  productsContainer: css`
    &.MuiGrid-container {
      margin: ${theme.spacing(1)}px 0;
      padding: ${theme.spacing(3)}px;
      background-color: ${theme.palette.warning.light};
      border-radius: ${commonStyling.roundedBorders.all};
    }
    .MuiGrid-grid-md-6:nth-child(2) {
      border-bottom: 1px solid ${COLORS.gray2};
    }
    .MuiGrid-grid-md-6:nth-child(2) {
      .MuiBox-root > div {
        display: flex;
        padding-top: ${theme.spacing(1)}px;
        .MuiSvgIcon-root {
          margin-right: 5px;
          position: relative;
          top: 5px;
        }
      }
      ${theme.breakpoints.up('md')} {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        .MuiBox-root > div {
          flex-direction: row-reverse;
          .MuiSvgIcon-root {
            margin-left: ${theme.spacing(1)}px;
          }
        }
      }
      ${theme.breakpoints.up('lg')} {
        .MuiBox-root > div {
          align-items: baseline;
        }
        .MuiSvgIcon-root {
          top: 2px;
        }
      }
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiTypography-colorSecondary {
      color: ${theme.palette.secondary.contrastText};
      span:nth-child(2) {
        font-size: ${fontSizes.body1};
      }
    }
    ${theme.breakpoints.up('md')} {
      &.MuiGrid-container {
        margin: ${theme.spacing(2)}px 0;
      }
      .MuiGrid-grid-md-6:first-child {
        border-bottom: 1px solid ${COLORS.gray2};
        border-radius: 0% !important;
      }
      .MuiGrid-grid-md-6:last-child {
        text-align: right;
        padding-top: ${theme.spacing(3)}px !important;
      }
    }
  `,
  otsContainer: css`
    color: ${COLORS.bodyTextPrimary};
    h4 {
      color: ${theme.palette.primary.main};
    }
    &.MuiGrid-container {
      margin: ${theme.spacing(1)}px 0;
      padding: ${theme.spacing(3)}px;
      background-color: ${theme.palette.warning.light};
      border-radius: ${commonStyling.roundedBorders.all};
    }
    .MuiTypography-body1 {
      font-weight: ${theme.typography.fontWeightLight!};
    }
    .MuiTypography-body2 {
      font-weight: ${theme.typography.fontWeightRegular!};
    }
    a {
      margin-left: ${theme.spacing(0.5)}px;
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.secondary};
      white-space: nowrap;
      &:hover {
        cursor: pointer;
      }
    }
    strong, b {
      color: ${COLORS.bodyTextPrimary};
      font-weight: 600;
    }
    .MuiTooltip-popper {
      border-radius: ${theme.spacing(1)}px;
      box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.16);
      top: -${theme.spacing(0.5)}px !important;
      width: ${theme.spacing(35.62)}px;
      ${theme.breakpoints.down('sm')} {
        top: -${theme.spacing(9.5)}px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
    .MuiTooltip-tooltip {
      background-color: white;
      margin: 0;
      padding: ${theme.spacing(3)}px;
      line-height: ${theme.spacing(2.62)}px; 
      font-size: ${fontSizes.h6};
      color: ${COLORS.bodyTextPrimary};
      font-weight: ${fontWeights.semibold};
    }
    .MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before {
      border-color: white transparent transparent transparent;
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    }
    #accountId-label {
      padding-right: ${theme.spacing(3)}px;
    }
    .MuiCircularProgress-root {
      width: ${theme.spacing(10.87)}px;
      height: ${theme.spacing(10.87)}px;
    }
  `,
  toolTipWrapper: css`
    position: relative;
    z-index: 2;
    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      ${theme.breakpoints.down(460)} {
        top: ${theme.spacing(1)}px;
      }
    }
 `,
  toolTipModal: css`
    b {
      font-weight: ${fontWeights.semibold} !important;
    }
    button {
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    }
 `,
  progressScreen: css`
    .MuiCircularProgress-root {
      display: block;
      margin: 0 auto;
      width: ${theme.spacing(10.87)}px;
      height: ${theme.spacing(10.87)}px;
    }
  `,
  mainIcon: css`
    width: ${theme.spacing(6.25)}px !important;
    height: ${theme.spacing(6.25)}px !important;
  `,
  loading: css`
    margin-left:${theme.spacing(3)}px;
    vertical-align: middle;
  `,
  errorLabel: css`
    .MuiInput-root.MuiInput-underline:before, 
    .MuiInput-root.MuiInput-underline:after,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid ${COLORS.error};
    }
  `,
  checkbox: css`
    position: relative;
    .MuiFormControlLabel-root {
      position: relative;
      margin-top: ${theme.spacing(2)}px;
    }
    .MuiCheckbox-root {
      position: absolute;
      top: ${theme.spacing(-1.37)}px;
    }
    .MuiFormControlLabel-label {
      color: ${COLORS.bodyTextPrimary};
      padding-left: ${theme.spacing(5)}px;
      font-size: ${theme.typography.body2.fontSize!};
    }
  `,
  errorCheckbox: css`
    span {
      color: ${COLORS.error};
    }
  `,
  otsConfirmationError: css`
    padding: ${theme.spacing(3)}px;
    border-radius: 20px;
    border: 1px solid ${COLORS.error};
    svg {
      display: block;
      width: ${theme.spacing(4.5)}px;
      height: ${theme.spacing(4.5)}px;
      margin-bottom: ${theme.spacing(1)}px;
    }
`,
  monthlyCost: css`
    color: ${theme.palette.secondary.contrastText};
  `,
  discount: css`
    color: ${theme.palette.secondary.contrastText};

    &.MuiTypography-root {
      font-size: 15px;
      font-weight: normal;
    }
  `,
  vulnerableMention: css`
    padding-top: ${theme.spacing(4)}px;
    
    .MuiTypography-root {
      font-size: ${theme.typography.body2.fontSize!}px;
      font-weight: normal;
    }
  `,
  wier: css`
    display: flex;
    align-items: flex-end;

    svg {
      font-size: 1.8rem;
      margin-right: 2px !important;
      ${theme.breakpoints.down('sm')} {
        left: -2px;
      }
    }
  `,
  summaryPrice: css`
    color: ${COLORS.bodyTextPrimary};
    padding-top: ${theme.spacing(2)}px;
  `,
}
