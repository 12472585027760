import {
  Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import {
  css, cx,
} from 'linaria'
import React, { useState } from 'react'
import commonStyling from '../../styles/commonStyling'
import theme, { COLORS } from '../../styles/theme'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

const classes = {
  dropdownPanel: css`
  margin: 0 !important;
  min-height: 7px;
  .Mui-expanded, p {
    padding-right: 0;
    margin: 0;
  }
  .MuiIconButton-root {
    padding-left: 0;
  }
  .MuiExpansionPanelSummary-root, .MuiExpansionPanelSummary-root.Mui-expanded {
    height: 35px !important;
    min-height: 0px;
  }
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 !important;
  } 
  .MuiExpansionPanelSummary-root {
    flex-direction: row;
    padding: 0 !important;
  }
  .MuiSvgIcon-root {
    font-size: ${commonStyling.svgFontSizeSmall};
  }
  &.MuiExpansionPanel-root:before {
    height: 0px;
  }
  .MuiExpansionPanelDetails-root {
    display:grid;
    padding-bottom: 0;
    padding-left: ${theme.spacing(1)}px;
    img {
      padding-top: ${theme.spacing(1)}px;
    }
    span {
      padding-bottom:${theme.spacing(3)}px;
      color: ${COLORS.bodyTextPrimary};
    }
    a:first-child {
      padding-right: ${theme.spacing(1)}px;
      ${theme.breakpoints.up('sm')} {
        padding-right: ${theme.spacing(1)}px;
      }
    }
  }
`,
  transparent: css`
    background-color: transparent !important;
    .MuiTypography-h6 {
      display: flex;
      justify-content: center;
    }
    .MuiBox-root {
      display: flex;
      justify-content: flex-start;
    }
  `,
  checkboxesContainer: css`
    margin-top: ${theme.spacing(3)}px;
    .MuiFormControlLabel-root > .MuiTypography-body1 {
      margin-top: ${theme.spacing(1)}px;
      ${theme.breakpoints.down('xs')} {
        margin-top: 0px;
      }
    }
    &.MuiFormGroup-root {
      flex-direction: row;
      margin-bottom: 3px;
    }
    .MuiRadio-root, .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.primary.main};
    }
    ${theme.breakpoints.up('sm')} {
      margin-top: 0;
      .MuiFormControlLabel-root > .MuiTypography-body1 {
        margin-top: 0;
      }
    }
  `,
  additionalMargins: css`
    margin: ${theme.spacing(5)}px !important;
  `,
}

export default function CallsRequirements({
  margins,
}: {margins?: boolean}) {
  const [
    radioValue,
    setRadioValue,
  ] = useState<string>()

  const {
    options, setOptions,
  } = useOrderContext()

  const {
    alternativePhone,
  } = options

  const handleRadioChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setRadioValue(value)

    if (value === 'yes') {
      setOptions({
        ...options,
        alternativePhone: true,
      })
    } else {
      setOptions({
        ...options,
        alternativePhone: false,
      })
    }
  }

  return (
    <Box
      color={COLORS.bodyTextPrimary}
      gridArea="info"
      bgcolor={COLORS.smoke}
      borderRadius={10}
      p={3}
      mt={5}
      className={cx(margins && classes.additionalMargins)}
    >
      <Box>
        <Typography variant="body2">Our Calls service requires a working broadband connection and electricity to power the phone adaptor.</Typography>
        <ExpansionPanel square elevation={0} className={cx(classes.dropdownPanel, classes.transparent)}>
          <Box>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore color="secondary"/>}
            >
              <Typography color="secondary" variant="body2">More information</Typography>
            </ExpansionPanelSummary>
          </Box>
          <ExpansionPanelDetails>
            <Typography variant="overline">If there is a broadband failure or power cut, your Calls service will not work. In case of an emergency, you must always have another means of making calls, such as a mobile phone, to contact Emergency Services.</Typography>
            <Typography variant="overline">If you have a healthcare or medical alarm, you will need to check with your alarm provider that it will work with your internet connection.</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
      <Typography variant="body2">To add calls, please select “Yes” if both of the following statements are true</Typography>
      <Box component="ol" fontFamily={theme.typography.fontFamily} pl={3}>
        <li><Typography variant="overline">I have another way to contact emergency services, such as a mobile phone with signal</Typography></li>
        <li><Typography variant="overline">I do not have a healthcare or medical alarm that connects to my telephone line</Typography></li>
      </Box>
      <RadioGroup
        className={classes.checkboxesContainer}
        aria-labelledby="voice-addon-card"
        onChange={handleRadioChange}
        value={radioValue}
      >
        <Box display="flex">
          <FormControlLabel
            value="yes"
            control={<Radio required color="primary"/>}
            label="Yes"
            checked={alternativePhone}
          />
          <FormControlLabel
            value="no"
            control={<Radio required color="primary"/>}
            label="No"
            checked={!alternativePhone}
          />
        </Box>
      </RadioGroup>
      {radioValue === 'no' &&
        <Box mt={1}>
          <Typography variant="body2">Let’s chat!</Typography>
          <Typography variant="overline">
            In order for us to assess your needs and make sure we are offering you the right services, please call our sales team on +44 808 175 0509 from 9am - 8pm Monday to Friday and from 9am – 5pm on Saturday.
          </Typography>
        </Box>}
    </Box>
  )
}
