import { css } from 'linaria'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'
import theme, { COLORS } from '../../styles/theme'

const classes = {
  container: css`
    background-color: ${theme.palette.warning.light};
    padding: ${theme.spacing(4)}px 0;
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(8)}px 0;
    }
    .MuiTabs-indicator {
      height: 4px;
      border-radius: 2px;
      background: ${theme.palette.primary.main}
    }
    .MuiTabs-flexContainer {
      max-width: 100vw;
      .MuiTab-root {
        margin: 0 ${theme.spacing(2)}px;
        min-width: auto;
        max-width: 100%;
        color: ${COLORS.bodyTextPrimary};
        font-size: ${fontSizes.body1};
        font-weight: ${theme.typography.fontWeightRegular!};
      }
      ${theme.breakpoints.up('sm')} {
        max-width: 75vw;
      }
      ${theme.breakpoints.up('md')} {
        max-width: 55vw;
      }
    }
  `,
  textWrapper: css`
    max-width: 580px;
  `,
  subtitle: css`
    &.MuiTypography-root {
      margin-top: ${theme.spacing(2)}px;
      font-weight: ${theme.typography.fontWeightLight!};
    }
  `,
  galleryContainer: css`
    &.MuiGrid-root {
      width: 90%;
      height: 500px;
      ${theme.breakpoints.up('sm')} {
        height: 250px;
      }
      ${theme.breakpoints.up('md')} {
        height: 400px;
      }
      ${theme.breakpoints.up('lg')} {
        width: 60%;
        height: 500px;
      }
    }
  `,
  gallery: css`
  &.MuiGrid-root {
    margin: ${theme.spacing(2)}px 0;
    max-height: 90%;
    overflow-y: auto;
  }
  ${theme.breakpoints.up('md')} {
    justify-content: center;
    .MuiGrid-grid-md-true {
      flex-grow: 0;
      justify-content: center
    }
  }
`,
  buttonContainer: css`
    margin: ${theme.spacing(2)}px 0;
    background-color: white;
    border-radius: 100px;
    box-shadow: ${commonStyling.boxShadowDark}
  `,
  gridButton: css`
    &.MuiButton-root {
      border-radius: 100px;
      width: 150px;
      ${theme.breakpoints.up('sm')} {
        width: 172px;
      }
    }
    &.MuiButton-contained{
      background:none;
      color: ${COLORS.bodyTextPrimary};
      &:hover{
        background: none
      }
    }
    & p{
      font-size: ${fontSizes.body2};
      span{
        font-weight: ${theme.typography.fontWeightRegular!}
      }
    }
  `,
  active: css`
    &.MuiButtonBase-root, &.MuiButton-root {
      background-color: ${theme.palette.primary.main};
      color: white;
      :hover {
        background-color: ${theme.palette.primary.light}
      }
    }
  `,
  image: css`
    width: 100%;
    object-fit: fill;
    border-radius: ${commonStyling.smallRoundedBorders.all}
    ${theme.breakpoints.up('md')} {
      width: auto;
      max-height: 100px;
    }
  `,
  info: css`
    justify-content: center;
    ${theme.breakpoints.up('sm')} {
      justify-content: flex-end;
    }
    .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightLight!}
    }
    &.MuiGrid-root {
      width: 70%;
    }
  `,
  ctaButton: css`
    &.MuiButton-root {
      margin: ${theme.spacing(2)}px;
      width: 90%;
      ${theme.breakpoints.up('sm')} {
        width: auto;
        padding: ${theme.spacing(1)}px ${theme.spacing(5)}px;
      }
    }
  `,
}

export default classes
