import React, { useEffect } from 'react'
import ComparisonTable from './ComparisonTable'
import {
  TextWithImageProps,
} from './ComparisonModal'
import StyledButton from '../StyledButton/StyledButton'
import TextWithImage from './TextWithImage'
import { useGeneralContext } from '../GeneralContext/GeneralContext'
import ComparisonFooter from './ComparisonFooter'
import {
  Box,
} from '@material-ui/core'

export interface DesktopComparisonModalProps {
  columns: {[key: string]: string[] };
  rowNames: {};
  last_updated: string;
  button: {
    modal?: boolean;
    url: string;
    title: string;
    text: string;
  };
  legal: {
    text: string;
    logo: string;
  };
  textWithImageTab?: TextWithImageProps | false;
}

export default function DesktopComparisonModal({
  columns,
  rowNames,
  last_updated,
  button,
  legal,
  textWithImageTab,
}: DesktopComparisonModalProps) {
  const hasTextWithImage = textWithImageTab && Object.keys(textWithImageTab).length > 0

  const {
    generalOptions,
    setGeneralOptions,
  } = useGeneralContext()

  useEffect(() => {
    setGeneralOptions({
      ...generalOptions,
      activeSpeedTabMobile: undefined,
    })
  }, [])

  return (
    <>
      {textWithImageTab && hasTextWithImage ?
        <TextWithImage textWithImageTab={textWithImageTab}/> :
        <ComparisonTable
          columns={columns}
          rowNames={rowNames}
        />}
      {!hasTextWithImage && button.text &&
        <Box display="flex" justifyContent="center" mt={4}>
          <StyledButton
            isModal={button?.modal}
            color="primary"
            url={button?.url}
          >
            {button.text}
          </StyledButton>
        </Box>}
      {!hasTextWithImage && <ComparisonFooter legal={legal} last_updated={last_updated}/>}
    </>
  )
}
