import React from 'react'
import classes from './InfoCards.styles'
import {
  Grid, Typography, Box,
} from '@material-ui/core'
import { InfoCardProps } from './InfoCards'
import StyledButton from '../StyledButton/StyledButton'
import { cx } from 'linaria'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import router from 'next/router'
import Wysiwyg from '../Wysiwyg/Wysiwyg'

const InfoCard = ({
  card,
  key,
}: { card: InfoCardProps; key: number}) => {
  const [
    open,
    setOpen,
  ] = React.useState<boolean>(false)

  const flipCard = open ?
    cx(classes.flipcard.inner, classes.flipcard.cardFlipped) :
    cx(classes.flipcard.inner)

  return (
    <div
      className={classes.cardOuter}
      key={key}
      onClick={() => setOpen(!open)}
      onKeyDown={(e) => e.key === 'Enter' && setOpen(!open)}
      role="button"
      tabIndex={0}
    >
      <div className={flipCard}>
        <div className={classes.flipCardInner}>
          <Grid
            container direction="column" justify="center"
            style={{ backgroundImage: `url(${card.image})` }}
            className={cx(classes.flipcard.cardSide, classes.flipcard.front, card.image_overlay && classes.overlayWrapper, card.enable_cta_area && classes.flipcard.cardShortSide)}
          >
            {card.front_title && <Typography variant="h3">{card.front_title}</Typography>}
            {card.front_button_text &&
              <div className={classes.frontButton}>
                <Typography color="primary" variant="body1">{card.front_button_text}</Typography>
                <img src={card.front_icon} alt=""/>
              </div>}
          </Grid>
          {!open && card.read_more_text && card.cta_button &&
            <div className={classes.frontReadButton}>
              {
                card.cta_button?.url && (
                  <StyledButton
                    color="primary"
                    isModal={card.cta_button.modal}
                    url={card.cta_button.url}
                    isFullWidth
                  >
                    {card.cta_button.text}
                    {card.cta_button.icon && <img src={card.cta_button.icon} alt=""/>}
                  </StyledButton>
                )
              }
              <Typography color="primary" variant="body1" className={cx(!card.enable_cta_area && classes.readMoreOverlay)}>{card.read_more_text}</Typography>
            </div>}
        </div>
        <Grid container className={cx(classes.flipcard.cardSide, classes.flipcard.back)}>
          <Box mt={8}>
            {card.back_title && <Typography variant="body2">{card.back_title}</Typography>}
            {card.back_body && <Typography variant="caption">{card.back_body}</Typography>}
            {card.card_body && <Wysiwyg className={classes.flipcard.features} body={card.card_body} variant="body2"/>}
          </Box>
          <Grid container justify="space-between" className={classes.backButtonContainer}>
            {card.back_button_text &&
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation()
                card.back_button_url && router.push(card.back_button_url)
              }}
            >
              <Typography variant="body1">{card.back_button_text}</Typography>
              <ArrowForwardIosIcon/>
            </button>}
            {card.back_icon &&
              <img src={card.back_icon} alt={card.front_button_text}/>}
            {open && card.read_less_text && card.cta_button &&
            <div className={classes.frontReadButton}>
              {
                card.cta_button?.url && (
                  <StyledButton
                    color="primary"
                    isModal={card.cta_button.modal}
                    url={card.cta_button.url}
                    isFullWidth
                  >
                    {card.cta_button.text}
                    {card.cta_button.icon && <img src={card.cta_button.icon} alt=""/>}
                  </StyledButton>
                )
              }
              <Typography color="primary" variant="body1">{card.read_less_text}</Typography>
            </div>}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default InfoCard
