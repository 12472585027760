import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'

export const GRID_WIDTH = 12

const bannerClasses = {
  wrapperSpacing: css`
    ${theme.breakpoints.up('md')} {
      margin: ${theme.spacing(8)}px auto !important;
    }
  `,
  hide: css`
  display:none;
  `,
  flipcard: {
    cardSide: css`
      backface-visibility: hidden;
      overflow: hidden;
      border-radius: ${commonStyling.roundedBorders.allSmall};
      border: 2px solid ${COLORS.gray2};
      height: 100%;
      width: 100%;
      .MuiTypography-body1{
        font-weight: ${theme.typography.fontWeightLight!};
      }
      .MuiTypography-caption{
        color: ${COLORS.bodyTextPrimary};
      }
      li, .MuiLink-root{
        color: ${COLORS.bodyTextPrimary};
      }
      ${theme.breakpoints.down('sm')} {
        flex-direction: column;
      }
    `,
  },
  onBorderText: css`
    position: absolute;
    top: -15px;
    left: ${theme.spacing(3)}px;
    background-color: ${COLORS.primary};
    border-radius: ${commonStyling.roundedBorders.all};
    padding: ${theme.spacing(0.5, 1)};
    &.MuiTypography-root {
      color: white;
      font-weight: ${theme.typography.fontWeightBold!};
    }
  `,
  iconWithText: css`
    text-align: center;
    p:first-child {
      margin-top: 0;
    }
  `,
  bottomContainer: css`
    gap: ${theme.spacing(1)}px;
    padding-top: 0;
    height: auto;
    [class*=button]{
      width: 100%;
    }
    ${theme.breakpoints.up('md')} {
    background-color: ${theme.palette.warning.light};
    padding-top: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.down('md')} {
      .MuiBox-root > .MuiBox-root {
        flex-wrap: wrap;
      }
    }
  `,
}

export default bannerClasses
