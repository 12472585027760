import React,
{
  BaseSyntheticEvent,
  useContext,
  useEffect, useState,
} from 'react'
import {
  Grid, Typography, FormControlLabel,
  Checkbox, Dialog, ExpansionPanel,
  ExpansionPanelDetails, ExpansionPanelSummary,
  ClickAwayListener,
  Box,
} from '@material-ui/core'
import classes from './RegisterInterestModal.styles'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import TextInput from '../TextInput/TextInput'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import StyledButton from '../StyledButton/StyledButton'
import CloseIcon from '@material-ui/icons/Close'
import useSavedPremise from '../../utils/hooks/useSavedPremise'
import createCRMApiClient from '../../api/CRMApi'
import logger from '../../utils/logger'
import commonStyling from '../../styles/commonStyling'
import { triggerValuableLeadEvent } from '../../utils/triggerValuableLeadEvent'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import marketingEvents from '../../utils/marketing/marketingEvents'

export interface RegisterInterestModalProps {
  title: string;
  body: string;
  button_text: string;
  success_body: string;
  success_title: string;
  success_button_text: string;
  gdpr_text?: string;
  opening_times?: string;
  privacy_text: string;
  error_title: string;
  error_body: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  callMeBack?: boolean;
  abandonedCart?: boolean;
}

interface FormValues {
  address: string;
  sprn: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gdprCheckbox?: boolean;
}

const RegisterInterestModal = ({
  title,
  body,
  button_text,
  success_title,
  success_body,
  success_button_text,
  gdpr_text,
  privacy_text,
  opening_times,
  error_title,
  error_body,
  showModal,
  setShowModal,
  callMeBack,
  abandonedCart,
}: RegisterInterestModalProps) => {
  const { premise } = useContext(PremiseContext)

  const [
    hasSubmitted,
    setHasSubmitted,
  ] = useState(false)

  const [
    isSubmitting,
    setIsSubmitting,
  ] = useState(false)

  const [
    showError,
    setShowError,
  ] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
    callMeBack && marketingEvents.closeCallMeBackModal()
    abandonedCart && marketingEvents.closeAbandonedCartModal()
  }

  useEffect(() => {
    setShowModal(showModal)
  }, [showModal])

  const methods = useForm<FormValues>()

  const {
    handleSubmit,
    control,
    register,
    setValue,
  } = methods

  useSavedPremise((premise) => {
    setValue('sprn', String(premise.sprn))
    setValue('address', premise.address)
  })

  const onSubmit = (e: BaseSyntheticEvent) => {
    setIsSubmitting(true)
    submitForm(e)
  }

  const submitForm = handleSubmit(async ({
    address, sprn, firstName, lastName, phone, email, gdprCheckbox,
  }) => {
    const client = createCRMApiClient()
    const authMarketing = Boolean(gdprCheckbox)

    const result = await client.leads.register({
      premise: {
        sprn: parseInt(sprn, 10),
        address,
      },
      premiseType: 'RESIDENTIAL',
      customer: {
        firstName,
        lastName,
        email,
        phone,
        type: 'PERSON',
      },
      products: [],
      communication: {
        authPrivacy: true,
        authTerms: true,
        authMarketing,
        authMarketingEmail: authMarketing,
        authMarketingPhone: authMarketing,
        authMarketingSMS: authMarketing,
        authMarketingPost: authMarketing,
      },
    })

    setHasSubmitted(true)
    callMeBack && marketingEvents.callMeBackFormSubmitted()
    abandonedCart && marketingEvents.abandonedCartFormSubmitted()
    setIsSubmitting(false)
    triggerValuableLeadEvent(premise)

    if ('error' in result) {
      logger.error(result.message, result.error)
      setShowError(true)
    }
  })

  const SuccessOrErrorMessage = () => (
    showError ?
      <>
        <Typography variant="h3">{error_title}</Typography>
        <Wysiwyg body={error_body} variant="body1"/>
      </> :
      <>
        <Typography variant="h3">{hasSubmitted ? success_title : title}</Typography>
        <Wysiwyg body={hasSubmitted ? success_body : body} variant="body1"/>
        {hasSubmitted &&
        <div className={classes.buttonWrapper}>
          <StyledButton handleClick={handleCloseModal} color="primary">
            {success_button_text}
          </StyledButton>
        </div>}
      </>
  )

  return (
    <Dialog
      className={classes.dialogModal}
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="register-interest"
      aria-describedby="register-interest"
      closeAfterTransition
      fullScreen
    >
      <ClickAwayListener onClickAway={handleCloseModal}>
        <div className={classes.wrapper}>
          <FormContext {...methods}>
            <form onSubmit={onSubmit} className={classes.formWrapper}>
              <ExpansionPanel
                defaultExpanded
                expanded={!isSubmitting && !hasSubmitted}
              >
                <ExpansionPanelSummary>
                  <button type="button" onClick={handleCloseModal} className="close-button" aria-label="Close modal">
                    <CloseIcon/>
                  </button>
                  {isSubmitting ?
                    <Loading/> :
                    <SuccessOrErrorMessage/>}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <input hidden name="sprn" ref={register({ required: true })}/>
                  <input hidden name="address" ref={register({ required: true })}/>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextInput required label="First Name"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput required label="Last Name"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput required label="Mobile Number" id="phone" type="tel"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        required
                        label="Email"
                        placeholder="me@example.co.uk"
                        type="email"
                      />
                    </Grid>
                    {gdpr_text &&
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        defaultValue={false}
                        name="gdprCheckbox"
                        as={
                          <FormControlLabel
                            label={gdpr_text}
                            control={<Checkbox color="primary"/>}
                          />
                        }
                      />
                    </Grid>}
                    {opening_times &&
                    <Grid item xs={12} className={classes.openingTimes}>
                      <Typography variant="body2">{opening_times}</Typography>
                    </Grid>}
                    <Grid item xs={12} className={classes.privacyText}>
                      <Wysiwyg body={privacy_text} variant="body2"/>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonWrapper}>
                      <StyledButton
                        type="submit"
                        color="primary"
                      >
                        {button_text}
                      </StyledButton>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </form>
          </FormContext>
        </div>
      </ClickAwayListener>
    </Dialog>
  )
}

export default RegisterInterestModal

const Loading = () => (
  <Box mb={4} className={commonStyling.loadingSpinner}>
    <div/><div/><div/>
  </Box>
)
