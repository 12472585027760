import React, { useState } from 'react'
import {
  Grid, Typography, Hidden, Box,
} from '@material-ui/core'
import classes from './HotBoxes.styles'
import HotBoxItem from './HotBoxItem'
import { SectionWithMargin } from '../../styles/SectionWithMargin'
import HotBoxSlide from './HotBoxSlide'
import { ContainerWithPadding } from '../../styles/ContainerWithPadding'

export type HotBox = {
  title: string;
  title_color: 'primary' | 'white';
  body: string;
  image?: string;
  url?: string;
  url_title?: string;
  add_overlay?: boolean;
}

export type HotBoxesProps = {
  title: string;
  subtitle: string;
  boxes: HotBox[];
}

export default function HotBoxes({
  title, subtitle, boxes,
}: HotBoxesProps): JSX.Element {
  return (
    <SectionWithMargin>
      <ContainerWithPadding>
        <Hidden smDown>
          <Box mb={4}>
            <Typography variant="h2" color="primary" align="center">{title}</Typography>
          </Box>
          <HotBoxItems
            items={boxes}
            subtitle={subtitle}
          />
        </Hidden>
        <Hidden mdUp>
          <Box mb={4}>
            <Typography variant="h3" color="primary" align="center">{title}</Typography>
          </Box>
          <HotBoxItems
            items={boxes}
            subtitle={subtitle}
            isMobile
          />
        </Hidden>
      </ContainerWithPadding>
    </SectionWithMargin>
  )
}

type HotBoxRowProps = {
  items: HotBox[];
  isMobile?: boolean;
  subtitle: string;
};

export function HotBoxItems({
  items, isMobile, subtitle,
}: HotBoxRowProps): JSX.Element {
  const [
    openedIndex,
    setOpenedIndex,
  ] = useState<null | number>(null)

  const [
    hotBoxHeight,
    setHotBoxHeight,
  ] = useState(170)

  const isMobileOpen = isMobile && (openedIndex || openedIndex === 0)
  const clickedOnMobileItem: HotBox | null = isMobileOpen ? items[Number(openedIndex)] : null

  return (
    <Grid container className={classes.boxesWrapper} spacing={isMobile ? 2 : 3}>
      {
        items.map((item, i) => (
          <Grid
            key={i} item xs={6}
            onMouseOver={() => !isMobile && setOpenedIndex(i)}
            onMouseLeave={() => !isMobile && setOpenedIndex(null)}
            onClick={() => setOpenedIndex(i)}
          >
            <HotBoxItem
              key={item.title}
              item={item}
              index={i}
              isOpen={openedIndex === i}
              isMobile={Boolean(isMobile)}
              hotBoxHeight={hotBoxHeight}
              setHotBoxHeight={setHotBoxHeight}
            />
          </Grid>
        ))
      }
      <div className={classes.overlapText}>
        <Typography variant={isMobile ? 'body1' : 'h4'}>{subtitle}</Typography>
      </div>
      {clickedOnMobileItem &&
      <HotBoxSlide item={clickedOnMobileItem} isMobile handleMobileClick={() => setOpenedIndex(null)}/>}
    </Grid>
  )
}
