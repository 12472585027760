import { css } from 'linaria'
import theme from '../../styles/theme'

const classes = {
  valuePropItem: css`
    display: flex!important;
    gap:${theme.spacing(1)}px;
    justify-content: center;
    .MuiTypography-root a {
        text-decoration: none;
    }
    [class*="mediaWrapper"] {
    margin-bottom:  auto;
    margin-top: auto;
    }
    `,
  slider: css`
    display: flex;
    justify-content: center;
    text-align: center;
  `,
}

export default classes
