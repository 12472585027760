import React, {
  useEffect, useState,
} from 'react'
import { PremiseDetail } from '../../api/Addresses'
import { premiseStore } from '../../storage'

export const PremiseContext = React.createContext<{ isGettingPremise: boolean; premise: PremiseDetail | null }>({
  isGettingPremise: false,
  premise: null,
})

export function PremiseContextProvider({
  premise: remotePremise, children,
}: {children: React.ReactNode; premise?: PremiseDetail}) {
  const [
    premise,
    setPremise,
  ] = useState<PremiseDetail | null>(remotePremise ?? null)

  const [
    isGettingPremise,
    setIsGettingPremise,
  ] = useState(false)

  useEffect(() => {
    async function persistPremise() {
      setIsGettingPremise(true)
      const savedPremise = await premiseStore.get()
      setPremise(savedPremise)
      setIsGettingPremise(false)
      if (savedPremise?.sprn === remotePremise?.sprn) {
        return
      }

      // If no saved premise or premise doesn't match update premise
      if (remotePremise) {
        const ttl = 2 * 3600
        await premiseStore.set(remotePremise, ttl)
        setPremise(remotePremise)
      }
    }

    persistPremise()
  }, [remotePremise])
  return (
    <PremiseContext.Provider value={{
      isGettingPremise,
      premise,
    }}
    >
      {children}
    </PremiseContext.Provider>
  )
}
