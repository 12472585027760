import { css } from 'linaria'
import theme, { COLORS } from '../../styles/theme'
import commonStyling from '../../styles/commonStyling'
import fontSizes from '../../styles/fontSizes'

const classes = {
  contractForm: css`
    width: 100%;
    form {
      position: relative;
      margin: 0 auto;
      background-color: white;
      border-radius: ${commonStyling.roundedBorders.all};
      ${theme.breakpoints.up('md')}{
        margin-right: ${theme.spacing(4)}px;
      }
      .MuiFormLabel-root{
        font-size: ${theme.spacing(2.62)}px;
        font-weight: ${theme.typography.fontWeightBold!};
      }
      .MuiTextField-root{
        margin-top: ${theme.spacing(2)}px;
      }
    }
    label + .MuiInput-formControl{
      margin-top: ${theme.spacing(1)}px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }
  `,
  title: css`
    max-width: 100%;
    font-size: ${fontSizes.h2} !important;
  `,
  iconsWithText: css`
      ${theme.breakpoints.up('md')}{
        padding-right: ${theme.spacing(8)}px;
      }
      .iconsWithTextWrapper{
        ${theme.breakpoints.down('xs')}{
          padding: 0;
          .iconTextWrapper{
            margin-left: ${theme.spacing(2)}px;
          }
        }
      }
      section{
        ${theme.breakpoints.down('xs')}{
          margin-top: 0;
        }
      }
      h3{
        ${theme.breakpoints.up('md')}{
          text-align: left;
          padding: ${theme.spacing(0, 2)};
          font-size: ${fontSizes.h1} !important;
        }
      }
      .MuiGrid-grid-sm-6{
        ${theme.breakpoints.up('md')}{
          align-items: flex-start;
          text-align: left;
        }
      }
      .MuiGrid-container{
        ${theme.breakpoints.down('xs')}{
          padding-top: ${theme.spacing(4)}px;

          .MuiGrid-item{
            padding-top: ${theme.spacing(3)}px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
          }
        }
      }
  `,
  flagTag: css`
    color: white;
    position: absolute;
    z-index: 100;
    top: -8px;
    right: -8px;
    background-color: ${theme.palette.error.main};
    border-radius: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiTypography-h6 {
      text-align: center;
      transition: transform 1s;
      transform: rotateY(0);
      padding: 11px ${theme.spacing(3)}px;
      line-height: 1;
    }
    span {
      font-size: ${fontSizes.subtitle2};
      font-weight: ${theme.typography.fontWeightLight!};
    }
    :after {
      content: '';
      height: 0;
      width: 0;
      border-top: 8px solid #80043B;
      border-right: 8px solid transparent;
      position: absolute;
      bottom: -8px;
      right: 0;
    }
  `,
  consentClasses: css`
    margin: ${theme.spacing(3, 1, 0, 1)};

    p{
      color: ${COLORS.bodyTextPrimary};
      font-size: ${fontSizes.subtitle2};
    }
    .privacyWrapper{
      padding: ${theme.spacing(1, 0, 3)};
      ${theme.breakpoints.down('xs')}{
        padding: ${theme.spacing(1, 0)};
      }
      p{
        font-size: ${fontSizes.subtitle2};
        color: ${COLORS.bodyTextPrimary};
      }
    }
  `,
  savedData: css`
    background-color: ${theme.palette.warning.light};
    border-radius: ${commonStyling.smallRoundedBorders.all};
    
    &.MuiFormControl-root{
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: flex-start;
    }
    &.MuiTextField-root{
      padding: ${theme.spacing(2)}px;
      width: auto;
    }

    label + .MuiInput-formControl{
      margin: 0;
      padding: 0;
    }

    .Mui-disabled {
      color: ${theme.palette.primary.main} !important;
      /* fill-color for Safari */
      -webkit-text-fill-color: ${theme.palette.primary.main};
      font-size: ${fontSizes.body1};
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: none;
    }
    .MuiInputLabel-formControl {
      position: relative;
      margin-bottom: ${theme.spacing(1)}px;
    }
    .MuiInputBase-input {
      padding: ${theme.spacing(0, 1, 0, 0)};
      margin-top: 0;
      color: ${COLORS.bodyTextPrimary} !important;
    }

  `,
  contractFormBackground: css`
    position: relative;
  `,
  submitClasses: css`
    margin: ${theme.spacing(1, 0, 2)};
  `,
  gridNoMargin: css`
    &.MuiGrid-container {
      margin: 0;
    }
  `,
  inputLabel: css`
    &.MuiTypography-root {
      margin: ${theme.spacing(3, 1)};
      font-size: ${fontSizes.h5};
      font-weight: ${theme.typography.fontWeightBold!};
      color: ${COLORS.primary};
      padding-top: ${theme.spacing(4)}px;

      ${theme.breakpoints.up('md')} {
        margin: ${theme.spacing(2, 1)};
      }
    }
  `,
  selectContainer: css`
    &.MuiGrid-root {
      margin-bottom: ${theme.spacing(2)}px;

      ${theme.breakpoints.up('md')} {
        margin-bottom: ${theme.spacing(-1)}px;
      }
    }
  `,
  selectClass: css`
    &.MuiInput-root {
      background: ${COLORS.smoke};
      border-radius: 50px;
      overflow: hidden;
      width: 100%;

      .MuiSelect-select.MuiSelect-select {
        padding-left: ${theme.spacing(3)}px;
        padding-top: 12px;
        padding-bottom: 14px;
        font-size: ${fontSizes.body2};
        font-weight: ${theme.typography.fontWeightLight!};
      }
    }
  `,
  activeSelect: css`
    &.MuiInput-root {
      background: ${COLORS.smoke};
      color: ${COLORS.primary};
      .MuiSelect-select.MuiSelect-select {
        color: ${COLORS.primary};
      } 
    }
  `,
  icon: css`
    padding-right: ${theme.spacing(1)}px;
    &.MuiSelect-icon {
      color: ${COLORS.primary};
    }
  `,
  activeIcon: css`
    &.MuiSelect-icon {
      color: ${COLORS.primary};;
    }
  `,
  option: css`
    border: none;
    outline: none;

    &.MuiListItem-root{
      padding-top: 0px;
      padding-bottom: 0px;
      font: normal normal normal 15px/21px Poppins;

      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(1)}px;
        padding-bottom: ${theme.spacing(1)}px;
      }
    }

    &.MuiMenuItem-root:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
      background: ${COLORS.gray2};
      color: ${COLORS.primary};
      font-weight: ${theme.typography.fontWeightMedium!};
    }
 `,
  loading: css`
    position: absolute;
    right:  ${theme.spacing(4.53)}px;;
  `,

  errorInput: css`
    border: 1px solid red !important;
  `,
  errorLabel: css`
    color: red !important;
  `,
}

export default classes
