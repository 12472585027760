import React, {
  useEffect, useState,
} from 'react'
import { Box } from '@material-ui/core'
import classes from './ComparisonModal.styles'
import ComparisonTable from './ComparisonTable'
import {
  TextWithImageProps,
} from './ComparisonModal'
import StyledButton from '../StyledButton/StyledButton'
import {
  ArrowForwardIos, ArrowBackIos,
} from '@material-ui/icons'
import TextWithImage from './TextWithImage'
import { cx } from 'linaria'
import ComparisonFooter from './ComparisonFooter'

export interface MobileComparisonModalProps {
  columns: {[key: string]: string[] };
  rowNames: {};
  last_updated: string;
  button: {
    modal?: boolean;
    url: string;
    title: string;
    text: string;
  };
  legal: {
    text: string;
    logo: string;
  };
  textWithImageTab?: TextWithImageProps | false;
  activeSpeedTab?: number;
}

export default function MobileComparisonModal({
  columns,
  rowNames,
  last_updated,
  button,
  legal,
  textWithImageTab,
  activeSpeedTab,
}: MobileComparisonModalProps) {
  const [
    currentComparison,
    setCurrentComparison,
  ] = useState(1)

  const hasTextWithImage = textWithImageTab && Object.keys(textWithImageTab).length > 0

  const firstRowName = Object.keys(columns)[1]
  const firstRowLength = columns[String(firstRowName)] ? columns[String(firstRowName)].length : 1

  const handleChangeComparison = (previous?: boolean) => {
    if (previous) {
      if (currentComparison - 1 > 0) {
        setCurrentComparison(currentComparison - 1)
      }
    } else if (currentComparison < firstRowLength - 1) {
      setCurrentComparison(currentComparison + 1)
    }
  }

  const handleIndicatorBackClick = () => {
    setCurrentComparison(currentComparison - 1)
  }

  const notLastColumn = currentComparison + 1 !== firstRowLength

  useEffect(() => {
    setCurrentComparison(1)
  }, [activeSpeedTab])

  return (
    <Box position="relative" overflow="hidden">
      {currentComparison - 1 > 0 && !hasTextWithImage &&
      <button
        className={cx(classes.mobileArrowBack, classes.mobileArrow)}
        type="button"
        onClick={() => handleIndicatorBackClick()}
      >
        <ArrowBackIos/>
      </button>}
      {notLastColumn && !hasTextWithImage &&
      <button
        className={cx(classes.mobileArrowForward, classes.mobileArrow)}
        type="button"
        onClick={() => handleChangeComparison()}
      >
        <ArrowForwardIos/>
      </button>}
      {textWithImageTab && hasTextWithImage ?
        <TextWithImage textWithImageTab={textWithImageTab}/> :
        <ComparisonTable
          columns={columns}
          rowNames={rowNames}
          currentComparison={currentComparison}
          handleChangeComparison={handleChangeComparison}
        />}
      {!hasTextWithImage && button.text &&
        <Box display="flex" justifyContent="center" mt={4}>
          <StyledButton
            isModal={button?.modal}
            color="primary"
            url={button?.url}
          >
            {button.text}
          </StyledButton>
        </Box>}
      {!hasTextWithImage && <ComparisonFooter legal={legal} last_updated={last_updated}/>}
    </Box>
  )
}
