import React, {
  useEffect, useState, useRef,
} from 'react'
import {
  Grid,
  Typography,
  Box,
  InputLabel,
  NativeSelect,
  InputBase,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import {
  Controller, useFormContext,
} from 'react-hook-form'
import { ExpandMore } from '@material-ui/icons'
import { cx } from 'linaria'

import TextInput from '../../../../TextInput/TextInput'
import { classes } from './PortingNumber.styles'
import { useOrderContext } from '../../../OrderContext'
import { useGeneralContext } from '../../../../GeneralContext/GeneralContext'
import {
  handleCopyPasteEvents, handleKeyDown,
  phoneRegex,
} from '../../../../../utils'

enum KeepExistingNumber {
  YES = 'yes',
  NO = 'no',
}

const PortingNumber = () => {
  const [
    sortedProviders,
    setSortedProviders,
  ] = useState<string[]>([])

  const [
    keepExistingNumber,
    setKeepExistingNumber,
  ] = useState<KeepExistingNumber>(KeepExistingNumber.NO)

  const [
    additionalInput,
    setAdditionalInput,
  ] = useState<Boolean>(false)

  const {
    options, setOptions,
  } = useOrderContext()

  const {
    generalOptions,
  } = useGeneralContext()

  const {
    providers,
  } = generalOptions

  const {
    control, setValue, watch,
  } = useFormContext()

  const phone = useRef({})

  phone.current = watch('phone', '')

  useEffect(() => {
    if (providers && providers.length > 0) {
      const sortedProviders = providers.sort((a, b) => (a > b) ? 1 : -1)
      const index = sortedProviders.indexOf('Other')
      const other = sortedProviders.splice(index, 1)
      sortedProviders.push(other[0])
      setSortedProviders(sortedProviders)
    }
  }, [providers])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeepExistingNumber(event.target.value as KeepExistingNumber)
  }

  const handleChange = () => {
    const provider = options.previousProvider
    if (provider.length > 0) {
      setProvider(provider)
    }
  }

  const setProvider = (provider: string) => {
    if (provider === 'Other') {
      setAdditionalInput(true)
    } else {
      setValue('provider', provider)
      setOptions({
        ...options,
        hasPortedNumber: true,
        portedNumber: String(phone.current),
        previousProvider: provider,
      })
    }
  }

  const onChangeSelector = (provider: string) => {
    if (additionalInput === true) {
      setAdditionalInput(false)
    }

    setProvider(provider)
  }

  return (
    <Grid container item>
      <Grid xs md={12} spacing={4} item>
        <Grid item>
          <Box mt={2} display="flex" justifyContent="start">
            <FormLabel component="legend">
              <Typography id="portingLabel" align="left" variant="h5">
                Do you want to keep your existing home phone number?
              </Typography>
            </FormLabel>
          </Box>
        </Grid>
        <Grid justify="center">
          <RadioGroup
            name="keepExistingNumber"
            aria-labelledby="keepExistingNumberLabel"
            value={keepExistingNumber}
            onChange={handleRadioChange}
            defaultValue={KeepExistingNumber.NO}
          >
            <Grid spacing={2} className={classes.radioGroup}>
              <Grid xs md={12} item>
                <FormControlLabel
                  className={classes.radio}
                  value={KeepExistingNumber.NO}
                  defaultChecked
                  control={<Radio required color="primary"/>}
                  label={
                    <>
                      <Typography variant="h5" color="primary">No, give me a new number</Typography>
                      <Typography variant="body2">
                        We&apos;ll confirm this by email. This will be after installation. No need to remain with your current provider.
                      </Typography>
                    </>
                  }
                />
              </Grid>
              <Grid xs md={12} item>
                <FormControlLabel
                  className={classes.radio}
                  value={KeepExistingNumber.YES}
                  control={<Radio required color="primary"/>}
                  label={
                    <>
                      <Typography variant="h5" color="primary">Yes, keep my current number</Typography>
                      <Typography variant="body2">
                        You&apos;ll need to remain with your current provider until we confirm by SMS your port has completed. This will take 12 working days from installation.
                      </Typography>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        {keepExistingNumber === 'yes' &&
        <>
          <Box mb={4} display="flex" justifyContent="space-evenly" className={classes.inputsContainer}>
            <TextInput
              className={classes.inputTitle}
              containerClassName={cx(classes.noXPaddingOnMobile, classes.firstInputTopPadding)}
              label="Current landline number"
              placeholder="E.g. 08878775858"
              id="phone"
              type="tel"
              required
              onCopy={handleCopyPasteEvents}
              onCut={handleCopyPasteEvents}
              onPaste={handleCopyPasteEvents}
              onKeyDown={handleKeyDown}
              handleChange={handleChange}
              inputProps={{
                maxLength: 11,
                autoComplete: 'off',
              }}
              validation={{
                maxLength: {
                  value: 11,
                  message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                },
                minLength: {
                  value: 11,
                  message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                },
                pattern: {
                  value: phoneRegex,
                  message: 'Please use a valid UK phone number. Number should be 11 digits and start with "0" (I.e 08878775858).',
                },
              }}
            />
            <TextInput
              className={classes.inputTitle}
              containerClassName={cx(classes.noXPaddingOnMobile, classes.secondInputTopPadding)}
              label="Confirm current landline number"
              placeholder="E.g. 08878775858"
              id="phoneConfirm"
              type="tel"
              required
              onCopy={handleCopyPasteEvents}
              onCut={handleCopyPasteEvents}
              onPaste={handleCopyPasteEvents}
              onKeyDown={handleKeyDown}
              handleChange={handleChange}
              inputProps={{
                maxLength: 11,
                autoComplete: 'off',
              }}
              validation={{ validate: (value) =>
                value === phone.current || 'Phone number don\'t match' }}
            />
          </Box>
          <Grid item xs={12} className={classes.noXPaddingOnMobile}>
            <Grid item xs={12} sm={12} className={classes.prevProvContainer}>
              {sortedProviders && sortedProviders.length > 0 &&
              <Controller
                rules={{ required: true }}
                control={control}
                options={sortedProviders}
                name="provider"
                as={
                  <>
                    <InputLabel htmlFor="provider" className={classes.selectLabel} required>Who is your existing service provider?</InputLabel>
                    <NativeSelect
                      id="provider"
                      name="provider"
                      required
                      onChange={(e) => onChangeSelector(e.target.value)}
                      className={classes.select}
                      IconComponent={ExpandMore}
                      defaultValue=""
                      input={
                        <InputBase
                          inputProps={{ 'aria-label': 'provider' }}
                          type="select"
                        />
                      }
                    >
                      <option aria-label="Please select your provider" value="" disabled>Please select your provider</option>
                      {sortedProviders.map((provider) => <option aria-label={provider} value={provider} key={provider}>{provider}</option>)}
                    </NativeSelect>
                  </>
                }
              />}
              {additionalInput &&
              <TextInput
                className={classes.inputTitle}
                containerClassName={cx(classes.noXPaddingOnMobile, classes.secondInputTopPadding)}
                label="Current service provider name"
                placeholder="e.g. Plusnet"
                id="otherProvider"
                type="text"
                required
                onCopy={handleCopyPasteEvents}
                onCut={handleCopyPasteEvents}
                onPaste={handleCopyPasteEvents}
                handleChange={(e) => setProvider(e.target.value)}
                gridWidth={12}
              />}
            </Grid>
          </Grid>
        </>}
      </Grid>
    </Grid>
  )
}

export default PortingNumber
