/* eslint complexity: ["error", 100] */
import React, {
  useEffect, useState, useContext,
} from 'react'
import { useRouter } from 'next/router'
import {
  useForm, FormContext, Controller,
} from 'react-hook-form'
import {
  Dialog, Typography, Hidden, Grid, Select, MenuItem, Button, CircularProgress, Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import moment from 'moment'
import { cx } from 'linaria'
import classes from './StillInContractForm.styles'
import PersonalDetails, { GridConfig } from '../PersonalDetails/PersonalDetails'
import createCRMApiClient from '../../api/CRMApi'
import logger from '../../utils/logger'
import { triggerValuableLeadEvent } from '../../utils/triggerValuableLeadEvent'
import { PremiseContext } from '../PremiseTracker/PremiseContext'
import { getFourYearsForward } from '../../utils/getFourYearsForward'
import marketingEvents from '../../utils/marketing/marketingEvents'
import {
  THREE_GIGA_IDS, useOrderContext,
} from '../NewOrderFlow/OrderContext'
import { formatInstallationAddress } from '../NewOrderFlow/steps/CheckoutStep/InstallationSection/InstallationSection'
import { SegmentType } from '../../utils/commonEnums'

export interface StillIncontractFormProps {
  anchor_ref: string;
  title?: string;
  mobile_image?: string;
  desktop_image?: string;
  button_text?: string;
  success_submit_message?: string;
  campaign_id: string;
  providers: string;
  consent_text?: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  address: string;
  sprn: string;
  email: string;
  phone: string;
  month: string;
  year: string;
  provider: string;
}

export default function StillInContractForm({
  anchor_ref,
  button_text,
  desktop_image,
  mobile_image,
  success_submit_message,
  title,
  campaign_id,
  providers,
  consent_text,
}: StillIncontractFormProps) {
  const {
    options,
  } = useOrderContext()

  const {
    broadband,
  } = options

  const [
    open,
    setOpen,
  ] = useState(false)

  const [
    showSuccessSubmitMessage,
    setShowSuccessSubmitMessage,
  ] = useState(false)

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false)

  const [
    providerError,
    setProviderError,
  ] = useState<boolean>(false)

  const [
    currentMonth,
    setCurrentMonth,
  ] = useState('')

  const [
    currentYear,
    setCurrentYear,
  ] = useState('')
  const [
    monthError,
    setMonthError,
  ] = useState<boolean>(false)

  const [
    yearError,
    setYearError,
  ] = useState<boolean>(false)

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const { premise } = useContext(PremiseContext)

  const router = useRouter()

  const gridConfig: GridConfig = {
    firstName: { xs: 6 },
    lastName: { xs: 6 },
    spacing: 1,
  }

  const handleClose = () => {
    setOpen(false)
    marketingEvents.endOfContractClose()
    router.back()
    setShowSuccessSubmitMessage(false)
  }

  const months = moment.months()
  const providersArray = providers ? providers.split('|') : []

  const {
    handleSubmit, control, register, watch,
  } = methods

  const watchMonth = watch('month')
  const watchYear = watch('year')
  const watchProvider = watch('provider')

  useEffect(() => {
    setMonthError(false)
  }, [
    watchMonth,
    currentMonth,
  ])

  useEffect(() => {
    setYearError(false)
  }, [
    watchYear,
    currentYear,
  ])

  useEffect(() => {
    setProviderError(false)
  }, [watchProvider])

  const getMonthFromString = (mon: string) => {
    const d = Date.parse(mon + '1, 2012')
    if (!isNaN(d)) {
      return new Date(d)
        .getMonth() + 1
    }

    return -1
  }

  const onSubmit = handleSubmit(async ({
    firstName, lastName, email,
    phone, month, year, provider,
  }) => {
    setLoading(true)

    if (!premise) {
      logger.error('premise not found', 'premise not found')
      setLoading(false)
      return
    }

    if (provider === 'Please select your current provider') {
      setProviderError(true)
      setLoading(false)
      return
    }

    if (currentMonth === 'Month') {
      setMonthError(true)
      setLoading(false)
      return
    }

    if (currentYear === 'Year') {
      setYearError(true)
      setLoading(false)
      return
    }

    const client = createCRMApiClient()

    let contractEndDate = ''
    if (currentYear && currentYear !== 'Year') {
      let dateString = year

      if (currentMonth && currentMonth !== 'Month') {
        const monthNumber = getMonthFromString(month)
        const monthNumberString = ('0' + Number(monthNumber)).slice(-2)
        dateString = `${year}-${monthNumberString}-01T00:00:00.000Z`
      }

      contractEndDate = dateString.toString()
    }

    const addressSplited = formatInstallationAddress(premise.address)
      .split(',')

    const params = {
      address: {
        sprn: String(premise.sprn),
        pinCode: addressSplited.slice(-1)[0].trim(),
        addressLine1: addressSplited[0].trim(),
        addressLine2: addressSplited.length === 4 ? addressSplited[1].trim() : '',
        addressLine3: addressSplited.length === 5 ? addressSplited[2].trim() : '',
        town: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        city: addressSplited.length === 4 ? addressSplited[2].trim() : addressSplited[1].trim(),
        stateOrProvince: premise.borough,
        totalAddress: premise.address,
      },
      firstName,
      lastName,
      contractEndDate,
      provider,
      phone,
      email,
    }

    const result = await client.hobsLeads.registerInterest(params)

    if (result.success === false || 'error' in result || result.code === 'SUPPLIER_FAULT') {
      logger.error(result.message, result.error)
      return
    }

    triggerValuableLeadEvent(premise)
    marketingEvents.endOfContractSubmit()

    broadband && THREE_GIGA_IDS.includes(broadband.productOfferingId!) && marketingEvents.threeGigaRegisterInterestFormSubmitted()

    const RegisterInterestData = {
      premise: premise!,
      firstName: firstName?.toLocaleLowerCase(),
      lastName: lastName?.toLocaleLowerCase(),
      emailAddress: email?.toLocaleLowerCase(),
      mobileNumber: phone,
      segment: SegmentType.RESIDENTIAL,
    }

    marketingEvents.registerInterestFormSubmit({
      eventName: 'register_interest_submitted',
      options,
      ...RegisterInterestData,
    })

    setLoading(false)
    setShowSuccessSubmitMessage(true)

    setTimeout(() => {
      handleClose()
    }, 3 * 1000)
  })

  useEffect(() => {
    if (router.asPath.includes('still-in-contract-form')) {
      setOpen(true)
    }
  }, [router.asPath])

  return (
    <Dialog id={anchor_ref} fullWidth={showSuccessSubmitMessage} maxWidth="md" open={open} onClose={handleClose} className={cx(classes.dialog, showSuccessSubmitMessage && classes.dialogSuccess)}>
      {showSuccessSubmitMessage === false ? (
        <FormContext {...methods}>
          <Grid container>
            <CloseIcon className={classes.closeIcon} onClick={handleClose}/>
            <Grid container item sm={12} md={6} className={classes.imageContainer}>
              <Hidden mdUp>
                <img src={mobile_image} alt="background1" className={classes.imageClass}/>
              </Hidden>
              <Hidden smDown>
                <img src={desktop_image} alt="background2" className={classes.imageClass}/>
              </Hidden>
            </Grid>
            <Grid container justify="center" item xs={12} md={6} className={classes.contentContainer}>
              <Typography variant="h3" color="primary" className={classes.title}>{title}</Typography>
              <form onSubmit={onSubmit}>
                <PersonalDetails
                  showConsent={false}
                  showConsentText
                  consentText={consent_text}
                  showLandlineField={false}
                  showConfirmEmailField={false}
                  campaignId={campaign_id}
                  gridConfig={gridConfig}
                  hideAddress
                >
                  <Grid direction="column" container>
                    <Typography className={cx(classes.inputLabel, providerError && classes.errorLabel)}>Who is your existing service provider?*</Typography>
                    <Grid direction="row" container spacing={2} className={classes.selectContainer}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="provider"
                        defaultValue="Please select your current provider"
                        renderValue={(selected: string) => {
                          if (!selected) {
                            return 'Provider'
                          }

                          return selected
                        }}
                        as={
                          <Select
                            className={cx(classes.selectClass, (watchProvider && watchProvider !== 'Provider') && classes.activeSelect, providerError && classes.errorInput)}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              transformOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                              },
                              getContentAnchorEl: null,
                            }}
                            disableUnderline
                            inputProps={{
                              classes: {
                                icon: cx(classes.icon, (watchProvider && watchProvider !== 'Provider') && classes.activeIcon),
                              },
                            }}
                          >
                            {providersArray.map((provider) => (
                              <MenuItem key={provider} value={provider} className={classes.option}>{provider}</MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid direction="column" container>
                    <Typography className={cx(classes.inputLabel, (monthError || yearError) && classes.errorLabel)}>When do you think your contract ends?*</Typography>
                    <Grid direction="row" container spacing={2} className={classes.selectContainer}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name="month"
                          defaultValue="Month"
                          renderValue={(selected: string) => {
                            if (!selected) {
                              return 'Month'
                            }

                            setCurrentMonth(selected)
                            return currentMonth
                          }}
                          as={
                            <Select
                              className={cx(classes.selectClass, (watchMonth && watchMonth !== 'Month') && classes.activeSelect, monthError && classes.errorInput)}
                              IconComponent={KeyboardArrowDownIcon}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                },
                                transformOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                              }}
                              disableUnderline
                              inputProps={{
                                classes: {
                                  icon: cx(classes.icon, (watchMonth && watchMonth !== 'Month') && classes.activeIcon),
                                },
                              }}
                            >
                              {months.map((month) => (
                                <MenuItem key={month} value={month} className={classes.option}>{month}</MenuItem>
                              ))}
                            </Select>
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name="year"
                          rules={{ required: true }}
                          defaultValue="Year"
                          renderValue={(selected: string) => {
                            if (!selected) {
                              return 'Year'
                            }

                            setCurrentYear(selected)
                            return currentYear
                          }}
                          as={
                            <Select
                              className={cx(classes.selectClass, (watchYear && watchYear !== 'Year') && classes.activeSelect, yearError && classes.errorInput)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                },
                                transformOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                },
                                getContentAnchorEl: null,
                              }}

                              IconComponent={KeyboardArrowDownIcon}
                              disableUnderline
                              inputProps={{
                                classes: {
                                  icon: cx(classes.icon, (watchYear && watchYear !== 'Year') && classes.activeIcon),
                                },
                              }}
                            >
                              {getFourYearsForward()
                                .map((year) => (
                                  <MenuItem key={year} {...register()} value={year} className={classes.option}>
                                    {year}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </PersonalDetails>
                <Box mt={2}>
                  <Grid item lg={6} xs={12}>
                    <Button type="submit" fullWidth variant="contained" color="primary" disableElevation>
                      <Typography noWrap>
                        {button_text}
                        {(loading && !providerError) && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                      </Typography>
                    </Button>
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
        </FormContext>
      ) : (
        <Grid container direction="column" alignItems="center">
          <Typography variant="h3">Thank you!</Typography>
          <Typography variant="body1" className={classes.successMessage}>{success_submit_message}</Typography>
        </Grid>
      )}
    </Dialog>
  )
}
