import { Alert } from '@material-ui/lab'
import React from 'react'
import classes from './AlertBar.styles'

const AlertBar = ({ message }: {message: string}) => {
  return (
    <Alert className={classes.alert} variant="filled" severity="error" style={{ borderRadius: 0 }}>{message}</Alert>
  )
}

export default AlertBar
