import React, { useContext } from 'react'
import classes from './Stepper.styles'
import { cx } from 'linaria'
import getStepUrl from '../../../getStepUrl'
import {
  Grid, useMediaQuery,
} from '@material-ui/core'
import Link from 'next/link'
import { StepType } from '../CheckoutStep'
import { OrderStep } from '../../../../../pages/order/[...step]'
import { PremiseContext } from '../../../../PremiseTracker/PremiseContext'
import theme from '../../../../../styles/theme'

interface StepProps {
  number: number;
  slug: OrderStep;
  text: string;
}

const Stepper = ({
  currentStep, lessMargin,
}: {currentStep: StepType | OrderStep; lessMargin?: boolean}) => {
  const { premise } = useContext(PremiseContext)
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  let currentStepClass
  let currentStepNumber: number

  const steps: StepProps[] = [
    {
      number: 1,
      slug: 'broadband',
      text: 'Packages',
    },
    {
      number: 2,
      slug: 'addons',
      text: 'Extras',
    },
    {
      number: 3,
      slug: 'checkout-details',
      text: 'Details',
    },
    {
      number: 4,
      slug: 'checkout-installation',
      text: 'Installation',
    },
    {
      number: 5,
      slug: 'checkout-payment',
      text: 'Payment',
    },
    {
      number: 6,
      slug: 'checkout-order-summary',
      text: 'Review',
    },
  ]

  switch (currentStep) {
    case 'broadband':
      currentStepClass = classes.step1
      currentStepNumber = 1
      break
    case 'addons':
      currentStepClass = classes.step2
      currentStepNumber = 2
      break
    case 'checkout-details':
      currentStepClass = classes.step3
      currentStepNumber = 3
      break
    case 'checkout-one-touch-switching':
      currentStepClass = classes.step3
      currentStepNumber = 3
      break
    case 'checkout-installation':
      currentStepClass = classes.step4
      currentStepNumber = 4
      break
    case 'checkout-payment':
      currentStepClass = classes.step5
      currentStepNumber = 5
      break
    case 'checkout-order-summary':
      currentStepClass = classes.step6
      currentStepNumber = 6
      break
    default:
      break
  }

  return (
    <nav aria-label="checkout steps" id="stepper">
      <Grid container justify="center">
        <ul className={cx(classes.steps, currentStepClass, lessMargin && classes.lessMargin)}>
          {steps.map((step, i) => {
            const stepRender = mobile ? step.number.toString() : step.text
            if (step.number < currentStepNumber && step.number <= 2) {
              return (
                <li key={i} className={classes.link}><Link href={getStepUrl(step.slug, premise?.sprn)}>{stepRender}</Link></li>
              )
            }

            if (step.number < currentStepNumber && step.number > 2) {
              return (
                <li key={i} className={classes.link}><Link href={getStepUrl(step.slug)}>{stepRender}</Link></li>
              )
            }

            return (
              <li key={i} className={cx(step.number === currentStepNumber && classes.activeStep)}>{stepRender}</li>
            )
          })}
        </ul>
      </Grid>
    </nav>
  )
}

export default Stepper
