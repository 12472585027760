import React from 'react'
import { css } from 'linaria'
import {
  Typography, Card, CardMedia,
} from '@material-ui/core'
import classNames from 'classnames'
import theme from '../../styles/theme'
import MediaImage, { MediaImageProps } from '../Media/MediaImage'

const classes = {
  container: css`
    min-width: 100%;
    min-height: 100%;
    position: relative;
    text-align: center;

    .MuiTypography-h2 {
      font-size: 72px;

      ${theme.breakpoints.down('sm')} {
        font-size: ${theme.typography.h2.fontSize!}px;
      }
      ${theme.breakpoints.down('xs')} {
        font-size: 45px;
      }
    }
  `,
  text: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${theme.palette.primary.main};
  `,
  desktopImage: css`
    &.MuiCardMedia-media {
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    }
  `,
  mobileImage: css`
    &.MuiCardMedia-media {
      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }
  `,
}

export interface StaticHeroProps {
  readonly meta: {
    id: number;
  };
  image_src?: string;
  image_alt?: string;
  mobile_image_src?: string;
  image?: MediaImageProps;
  text?: string;
}

export default function StaticHero({
  meta, image_alt, image_src, image, text, mobile_image_src,
}: StaticHeroProps) {
  const hasMobileImage = Boolean(mobile_image_src)
  return (
    <Card classes={{ root: classes.container }} elevation={0} square id={`StaticHero_${meta.id}`}>
      {image_src &&
      <CardMedia
        component="img"
        alt={image_alt}
        image={image_src}
        title={text}
        className={classNames({ [classes.desktopImage]: hasMobileImage })}
      />}
      {hasMobileImage &&
      <CardMedia
        component="img"
        alt={image_alt}
        image={mobile_image_src}
        title={text}
        className={classNames({ [classes.mobileImage]: hasMobileImage })}
      />}
      {image?.src &&
      <MediaImage
        src={image.src}
        width={image.width || 1903}
        height={image.height || 494}
        alt={image.alt}
        mobile_src={image.mobile_src}
        mobile_width={image.mobile_width || 768}
        mobile_height={image.mobile_height || 403}
        full_width
      />}
      {text && <Typography variant="h2" component="span" noWrap classes={{ root: classes.text }}>{text}</Typography> }
    </Card>
  )
}
