import React, { useState } from 'react'
import { cx } from 'linaria'
import classes from './Basket.styles'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ClickAwayListener,
  Box,
} from '@material-ui/core'
import BasketIcon from './BasketIcon'
import BasketDetails from './BasketDetails/BasketDetails'
import BasketHeading from './BasketHeading'
import CloseIcon from '@material-ui/icons/Close'
import InstallationSlot from './InstallationSlot'
import { TimeslotData } from '../../../../../api/BookingCalendar'
import { HobsProduct } from '../../../../../api/Packages'
import { COLORS } from '../../../../../styles/theme'

const MobileBasket = ({
  broadband, bundle, timeslot, reachedInstallationStep,
}: { broadband?: HobsProduct; bundle?: HobsProduct; timeslot: TimeslotData | null; reachedInstallationStep: Boolean}) => {
  const [
    showBasket,
    setShowBasket,
  ] = useState(false)

  const basketMobile = cx(showBasket && classes.backdropContainer, classes.container, classes.stickyMobileContainer)
  const monthlyHeader = cx(showBasket && classes.openMonthlyHeader, classes.monthlyHeader)

  return (
    <div className={basketMobile}>
      <ClickAwayListener onClickAway={() => setShowBasket(false)}>
        <ExpansionPanel expanded={showBasket}>
          <ExpansionPanelSummary
            onClick={() => setShowBasket(!showBasket)}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className={monthlyHeader}
              bgcolor={COLORS.primary}
            >
              <BasketHeading broadband={broadband} bundle={bundle}/>
              {showBasket ? <CloseIcon/> : <BasketIcon/>}
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BasketDetails broadband={broadband} bundle={bundle}/>
            {timeslot && <InstallationSlot timeslot={timeslot} reachedInstallationStep={reachedInstallationStep}/>}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ClickAwayListener>
    </div>
  )
}

export default MobileBasket
