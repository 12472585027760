import React from 'react'
import Head from 'next/head'

export interface ImageProps {
  alt?: string;
  className?: string;
  href?: string;
  src: string;
  use_for_seo?: boolean;
}

function BaseImage({
  alt, className, href, src, ...rest
}: Omit<ImageProps, 'use_for_seo'>) {
  if (href) {
    return (
      <a className={className} href={href}>
        <img src={src} alt={alt} {...rest}/>
      </a>
    )
  }

  return <img className={className} src={src} alt={alt} {...rest}/>
}

export default function Image({
  alt, className, href, src, use_for_seo = false, ...rest
}: ImageProps) {
  const image = <BaseImage alt={alt} className={className} href={href} src={src} {...rest}/>
  if (use_for_seo) {
    return (
      <>
        <Head>
          <meta property="og:image" content={src}/>
        </Head>
        {image}
      </>
    )
  }

  return image
}
