import {
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import { COLORS } from '../../styles/theme'
import { HeroSectionProps } from './HeroSection'

const setColumns = (alignment: string | undefined) => {
  switch (alignment) {
    case 'left':
      return '1fr 1fr'
    case 'right':
      return '1fr 1fr'
    default:
      return '1fr'
  }
}

const createTextColor = (type: string | undefined, color: string, inverted_text_color: boolean) => {
  if (inverted_text_color && !type) {
    return
  }

  if (type) {
    return type
  }

  return color
}

export const HeroSectionStyles = (theme: Theme) =>
  createStyles({
    container: ({
      content_alignment, gap, padding_bottom, padding_top,
    }: HeroSectionProps) => ({
      alignItems: 'center',
      gridTemplateColumns: setColumns(content_alignment),
      flexDirection: 'column',
      position: 'relative',
      zIndex: 'auto',
      [theme.breakpoints.up('xs')]: {
        display: 'flex',
        gridGap: gap ? theme.spacing(gap) : theme.spacing(5),
        paddingBottom: theme.spacing(padding_bottom || 6),
        paddingTop: theme.spacing(padding_top || 6),
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(padding_bottom || 8),
        paddingTop: theme.spacing(padding_top || 8),
      },
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        ...(content_alignment === 'center' && { justifyItems: 'center' }),
        gridGap: gap ? theme.spacing(gap) : theme.spacing(content_alignment === 'center' ? 5 : 12),
        textAlign: content_alignment === 'center' ? 'center' : 'left',
      },
    }),
    title: ({
      title_color, inverted_text_color,
    }: HeroSectionProps) => ({
      color: createTextColor(title_color, COLORS.primary, inverted_text_color!),
      letterSpacing: '-0.01562em',
      marginTop: 0,
    }),
    subtitle: ({
      subtitle_color, inverted_text_color,
    }: HeroSectionProps) => ({
      color: createTextColor(subtitle_color, COLORS.bodyTextPrimary, inverted_text_color!),
    }),
    section: ({
      background_image, background_color, inverted_text_color, mobile_background_position_x, mobile_background_position_y,
    }: HeroSectionProps) => ({
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundImage: background_image ? `url(${background_image})` : 'none',
      backgroundColor: background_color === '' ? '#fff' : background_color,
      color: inverted_text_color ? COLORS.white : 'inherit',
      '& .centered iframe': {
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: mobile_background_position_x && mobile_background_position_y ? `${mobile_background_position_x}% ${mobile_background_position_y}%` : '50% 50%',
      },
      [theme.breakpoints.down('md')]: {
        '& [data-template-id] iframe': {
          margin: '0 auto',
        },
      },
    }),
    content: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiButton-root': {
          width: '100%',
        },
      },
    },
    trustpilot: {
      position: 'relative',
    },
    filler: {
      height: '100%',
      left: 0,
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      width: '100vw',
    },
    search: {
      '& [class*=inputContainer]': {
        position: 'static',
        zIndex: 'auto',
      },
      '& [class*=mobileButton] .MuiFab-root': {
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
      '& .MuiAutocomplete-popper': {
        background: 'none',
        '& .MuiAutocomplete-paper': {
          position: 'relative',
          zIndex: '4',
          borderTop: 'none',
          margin: 0,
          padding: 0,
          width: '100%',
          '& ul li': {
            padding: theme.spacing(0.75, 3),
            textAlign: 'left',
          },
        },
      },
      '& > .MuiGrid-root': {
        minWidth: '100%',
      },
      '& [class*=fontContainer]': {
        maxWidth: '100%',
      },
    },
  })
