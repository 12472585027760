import { css } from 'linaria'
import fontSizes from '../../styles/fontSizes'
import theme from '../../styles/theme'

const classes = {
  root: css`
    height: 100%;
    display: flex;
    padding: 0 !important;
  `,
  verticalIcon: css`
    div {
      padding: 0 !important;
      text-align: left;
    }
    ${theme.breakpoints.up('sm')} {
      align-items: center;
    }
  `,
  horizontalIcon: css`
    align-items: center;
    ${theme.breakpoints.up('sm')} {
      flex-direction: column;
    }
  `,
  image: css`
    img {
      max-width: 100px;
      ${theme.breakpoints.up('sm')} {
        min-width: 120px;
        max-width: 100%;
      }
    }
  `,
  imageWithPadding: css`
    img {
      ${theme.breakpoints.up('sm')} {
        padding-top: ${theme.spacing(5)}px;
      }
    }
  `,
  text: css`
    margin: 0 ${theme.spacing(1)}px 0 ${theme.spacing(2)}px;
    text-align: left;
    ${theme.breakpoints.up('sm')} {
      text-align: center;
    }
  `,
  title: css`
    &.MuiTypography-root {
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0;
      }
    }
  `,
  body: css`
    &.MuiTypography-root {
      padding-top: ${theme.spacing(1)}px;
      font-weight: ${theme.typography.fontWeightLight!};
      ${theme.breakpoints.up('sm')} {
        padding: 0 ${theme.spacing(2)}px;
      }
    }
  `,
  link: css`
    .MuiLink-underlineHover {
      display: flex;
      align-items: center;
      cursor: pointer;

      ${theme.breakpoints.up('sm')} {
        justify-content: center;
      }

      &:hover {
        text-decoration-color: ${theme.palette.secondary.main};
      }
    }

    .MuiTypography-root {
      font-size: ${fontSizes.body2};

      ${theme.breakpoints.up('sm')} {
        font-size: ${fontSizes.body1} !important;
      }
    }

    svg {
      margin-top: ${theme.spacing(1)}px;
      margin-left: 6px;
      width: 12px;
      height: 12px;

      ${theme.breakpoints.up('sm')} {
        height: 15px;
        width: 15px;
      }
    }
  `,
}

export default classes
