import React, { useEffect } from 'react'
import {
  Typography, Box,
} from '@material-ui/core'
import classes from './ErrorMessage.styles'
import CloseIcon from '@material-ui/icons/Close'
import { cx } from 'linaria'
import marketingEvents from '../../utils/marketing/marketingEvents'
import { useOrderContext } from '../NewOrderFlow/OrderContext'

export interface ErrorMessageProps {
  title?: string;
  message?: string;
  showErrorMessage: boolean;
  setShowErrorMessage?: (showErrorMessage: boolean) => void;
  id?: string;
  className?: string;
  container?: boolean;
}

export default function ErrorMessage({
  message, showErrorMessage, setShowErrorMessage,
  id, className, container = true,
}: ErrorMessageProps) {
  const {
    options,
  } = useOrderContext()

  useEffect(() => {
    (showErrorMessage && message) && marketingEvents.error(options, `ERR-MSG-${id}`, '<ErrorMessage/>', message)
  }, [])

  if (!showErrorMessage) {
    return null
  }

  return (
    <Box display={!container && 'flex'} justifyContent={!container && 'center'} alignContent={!container && 'center'} className={cx(container && classes.container, className)} id={id}>
      <div>
        <Typography variant="body1" color="error">{message}</Typography>
      </div>
      {setShowErrorMessage &&
      <div className={classes.closeButton}>
        <button type="button" onClick={() => setShowErrorMessage(false)} className="close-button">
          <CloseIcon/>
        </button>
      </div>}
    </Box>
  )
}
