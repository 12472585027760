import {
  Theme, createStyles,
} from '@material-ui/core/styles'
import {
  COLORS, GENERAL, GRADIENTS,
} from '../../styles/theme'
import { checkIfVoiceExists } from '../Bundles/Bundle'
import { PackageProps } from './Package'

export const PackageStyles = (theme: Theme) =>
  createStyles({
    package: ({
      selected,
    }: PackageProps) => ({
      borderColor: selected ? `${COLORS.secondary}` : `${COLORS.gray2}`,
      borderWidth: '2px',
      color: COLORS.primary,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: GENERAL.packageWidthDesktop,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(6),
      height: '100%',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: GENERAL.packageWidthMobile,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: GENERAL.packageWidthDesktop,
      },
    }),
    commonPackage: {
      backfaceVisibility: 'hidden',
      transition: 'transform .2s ease-in-out',
      '& nav .MuiButtonBase-root svg': {
        marginRight: theme.spacing(0.75),
        position: 'relative',
      },
    },
    bundle: ({ selected }: PackageProps) => ({
      borderColor: selected ? `${COLORS.secondary}` : `${COLORS.gray2}`,
      borderWidth: '2px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column-reverse',
        maxWidth: GENERAL.addOnWidthMobile,
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        maxWidth: '100%',
      },
    }),
    displayImage: () => ({
      [theme.breakpoints.up('md')]: {
        borderRadius: '0px 20px 20px 0px',
      },
      [theme.breakpoints.down('md')]: {
        borderRadius: '20px 20px 0px 0px',
      },
    }),
    bundleContent: (product) => {
      const boxLayout = checkIfVoiceExists(product!) ?
        `'heading heading'
        'price features'
        'cta features'
        'cta info info'` :
        `'heading heading'
        'price features'
        'cta features'`

      return {
        gridTemplateAreas: boxLayout,
        gridTemplateRows: 'auto auto 1fr auto',
        [theme.breakpoints.down('lg')]: {
          display: 'block',
          padding: theme.spacing(4),
        },
        [theme.breakpoints.up('lg')]: {
          display: 'grid',
          padding: theme.spacing(6),
        },
      }
    },
    businessHeader: {
      borderRadius: '16px 16px 0 0',
      display: 'flex',
      padding: theme.spacing(3),
      paddingBottom: 0,
      width: '100%',
      left: theme.spacing(-3),
      marginBottom: theme.spacing(3),
      overflow: 'hidden',
      position: 'relative',
      '&:after': {
        background: COLORS.businessPrimary,
        bottom: 0,
        content: '""',
        height: '100%',
        left: '50%',
        position: 'absolute',
        transform: 'rotate(-5deg) translate(-50%, 0)',
        transformOrigin: '-100% 0',
        width: '120%',
      },
    },
    businessCircle: {
      borderRadius: '50%',
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      minWidth: '92px',
      width: '92px',
      height: '92px',
      zIndex: 2,
    },
    businessCircleInner: {
      backgroundColor: COLORS.white,
      borderRadius: '50%',
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1.5),
    },
    businessGradient: {
      background: GRADIENTS.businessButton,
    },
  })
