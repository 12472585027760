import React, {
  useEffect, useState, useRef,
} from 'react'
import { useMediaQuery } from '@material-ui/core'
import { cx } from 'linaria'
import classes from './Media.styles'
import theme from '../../styles/theme'

import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'
export interface MediaVideoProps {
  readonly src?: string;
  readonly src_mobile?: string;
  readonly width?: string;
  readonly height?: string;
  readonly auto_play?: boolean;
  readonly controls?: boolean;
  readonly ratio_16to9?: boolean;
  readonly pause_on_leave?: boolean;
  readonly loop?: boolean;
  readonly cover?: boolean;
  readonly youtube_id?: string;
  readonly poster?: string;
  readonly className?: string;
}

export const createMediaVideoProps = (video: MediaVideoProps) => ({
  src: video.src,
  src_mobile: video.src_mobile,
  youtube_id: video.youtube_id,
  controls: video.controls,
  auto_play: video.auto_play,
  loop: video.loop,
  cover: video.cover,
  ratio_16to9: video.ratio_16to9,
  pause_on_leave: video.pause_on_leave,
})

export default function MediaVideo({
  src,
  width,
  height,
  src_mobile,
  auto_play,
  controls,
  youtube_id,
  poster,
  className,
  cover,
  loop,
  ratio_16to9,
  pause_on_leave,
}: MediaVideoProps) {
  const [
    srcMobile,
    setSrcMobile,
  ] = useState<string>()
  const [
    videoProcessing,
    setVideoProcessing,
  ] = useState<boolean>()

  const dynamicWidth = width ? width : '100%'
  const dynamicHeight = height ? height : '100%'
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [
    videoLoaded,
    sedVideoLoaded,
  ] = useState(false)

  const videoRef = useRef<HTMLVideoElement>(null)

  const onLoadedData = () => {
    sedVideoLoaded(true)
  }

  const [isVisible] = useIntersectionObserver({ elementRef: videoRef })

  useEffect(() => {
    if (videoRef?.current && !isVisible && pause_on_leave) {
      videoRef.current.pause()
    }

    const getSrcMobile = async (src: String) => {
      const fileID = src.split('.com/')[1]
      const baseURL = src.split('/' + fileID)[0]
      const requestURL = baseURL + '/video_convert=width:' + theme.breakpoints.down('sm') + '/' + fileID
      await fetch(requestURL)
        .then(async (res) => {
          if (res.ok) {
            const filestackResponse = await res.json()
            if (filestackResponse.status === 'completed') {
              const { data: { url } } = filestackResponse
              setSrcMobile(url)
            }

            if (filestackResponse.status === 'pending') {
              setVideoProcessing(true)
            }
          }
        })
    }

    if (!youtube_id && src_mobile) {
      setSrcMobile(src_mobile)
    }

    if (!youtube_id && isMobile && !src_mobile && src) {
      getSrcMobile(src)
    }
  }, [
    pause_on_leave,
    isMobile,
    src_mobile,
    src,
    youtube_id,
    isVisible,
    controls,
  ])

  if (!youtube_id && !src) {
    return null
  }

  return (
    <div className={cx(className, !controls && classes.disabled)}>
      {!youtube_id &&
        <video
          poster={poster}
          width={dynamicWidth}
          height={dynamicHeight}
          preload="metadata"
          autoPlay={auto_play}
          muted={auto_play}
          playsInline={auto_play}
          controls={controls}
          loop={loop}
          onLoadedData={onLoadedData}
          src={isMobile ? srcMobile : src}
          ref={videoRef}
          className={cx(classes.centered, classes.video, videoProcessing && classes.processing, videoLoaded && 'loaded', cover && 'cover', ratio_16to9 && classes.ratio_16to9)}
        >
          <track kind="captions"/>
        </video>}
      {youtube_id &&
        <iframe
          width={dynamicWidth}
          height={dynamicHeight}
          src={`https://www.youtube.com/embed/${youtube_id}?autoplay=${auto_play ? 1 : 0}&mute=${auto_play ? 1 : 0}&controls=${controls ? 1 : 0}&loop=${loop ? '1&playlist=' + youtube_id : 0}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          className={cx(classes.centered, ratio_16to9 && classes.ratio_16to9)}
        />}
    </div>
  )
}
